import React from 'react'

const SimpleListItemButtons = props => {
  const { children } = props

  return (
    <div className='ta-simple-list-item__buttons' data-testid='simple-list-item-buttons'>{children}</div>
  )
}

export default SimpleListItemButtons
