import React from 'react'
import {
  Title,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  Loader,
  FontAwesome5,
  t
} from '../../Common'
import { AccountDetails } from '../../Beauties'
import { AccountSecurityWrapper } from '../../Beasts'

import './Account.css'

const Account = props => {
  const {
    account,
    language,
    onAccountChange,
    pendingAccountSelect,
    onResendEmailClick,
    hash
  } = props

  const availableTabs = ['user', 'userSecurity']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  return (
    <div className='ta-account'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={t('user.preview.headingPersonalAccount')} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <SimpleTabs active={activeTab}>
            <SimpleTabsHeader>
              <SimpleTabsHeaderItem name='user' label={t('user.preview.tabDetails.label')} />
              <SimpleTabsHeaderItem name='userSecurity' label={<><FontAwesome5 icon='lock' type='solid' />{t('global.accountSecurity')}</>} />
            </SimpleTabsHeader>
            <SimpleTabsBody>
              <SimpleTabsBodyItem name='user'>
                <Loader active={pendingAccountSelect} />
                <AccountDetails
                  account={account}
                  language={language}
                  onAccountChange={onAccountChange}
                  onResendEmailClick={onResendEmailClick}
                />
              </SimpleTabsBodyItem>
              <SimpleTabsBodyItem name='userSecurity'>
                <AccountSecurityWrapper />
              </SimpleTabsBodyItem>
            </SimpleTabsBody>
          </SimpleTabs>
          {/* <SectionTitle label={'Delete user'} icon='trash-alt' />
          <Row>
            <Col>
              <FormText>
                There's no going back! If you delete your user, all your owner accounts and all your data will be gone forever.
              </FormText>
            </Col>
          </Row>
          {!canDeleteUser &&
            <Alert label={t('You cannot delete your user yet.')} theme='alert'>
              {dangerousHTML(t('To do that you should have: <br /> - No booker account <br /> - No developer account <br /> - No Key accounts that you are owner<br /> - Only 1 company account that you are owner <br /><br />This last company account will be deleted automatically and all accounts that you are linked to unlinked automatically. <br /><br /> Please delete all other accounts listed above to be able to delete your user.'))}
            </Alert>
          }
          {canDeleteUser &&
            <PopupTriggerWrapper
              name='user-delete'
              extraClassName='ta-delete-user'
              position='top'
            >
              <Link className='ta-btn ta-btn-ghost' external>Delete user</Link>
            </PopupTriggerWrapper>
          } */}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Account
