const reducer = {}

reducer.initialState = {
  pending: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  oAuthReset: () => dispatch(actions.OAUTH_RESET),

  // One
  oAuthGet: () => dispatch(actions.OAUTH_GET),
  oAuthAccountGet: () => dispatch(actions.OAUTH_ACCOUNT_GET),
  oAuthPopulate: payload => dispatch(actions.OAUTH_POPULATE, payload),
  oAuthAccessGrant: id => dispatch(actions.OAUTH_ACCESS_GRANT, id),
  oAuthAccessDenied: error => dispatch(actions.OAUTH_ACCESS_DENIED, error)
})

// Reset

reducer.OAUTH_RESET = state => reducer.initialState

reducer.OAUTH_GET = state => ({
  ...state,
  pending: true
})

reducer.OAUTH_ACCOUNT_GET = state => ({
  ...state,
  pending: true
})

reducer.OAUTH_POPULATE = (state, payload) => ({
  ...state,
  ...payload,
  pending: false
})

reducer.OAUTH_ACCESS_GRANT = state => ({
  ...state,
  pending: true
})

reducer.OAUTH_ACCESS_DENIED = state => ({
  ...state,
  pending: true
})

export default reducer
