import React, { useEffect } from 'react'
import { BookingCancelForm } from '../../Beauties'
import { handlers, selectors, connect, globalActions } from '../../Store'

const extractNumericValue = (nextStep, progress) => !nextStep && nextStep !== 0 ? progress + 1 : nextStep

const BookingCancelWrapper = ({
  customerStore,
  color,
  accordionRefs,
  selectedBookingsForDeletion,
  account,
  branches,
  form,
  areCustomerFieldsFetched,
  ...props
}) => {
  const { progress, bookingCustomer, isCustomerAdd, selectedBranchAddress, isCustomerEdit } = customerStore || {}

  useEffect(() => {
    globalActions.populateCustomerFieldsAndCategories(true)

    return () => handlers.customersListPopulate()
  }, [])

  useEffect(() => {
    handlers.customerFormPopulate({ customer: null, branches: [] })
    handlers.customerPreviewPopulate(null)
    handlers.formSet('customersFilters', {})
    if (areCustomerFieldsFetched) handlers.bookingFormGet('cancel')
    return () => {
      handlers.bookingFormGet('cancel')
    }
  }, [areCustomerFieldsFetched])

  const onGoToNext = (nextStep = undefined) => {
    if (progress === 1 && !nextStep) {
      form.type = 'singleBooking'
      handlers.bookingFormCancel(form, errors => {
        if (!errors) {
          handlers.formFieldsUpdate('booking', { progress: { value: progress + 1 } })
        }
      })
    } else {
      handlers.formFieldsUpdate('booking', { progress: { value: extractNumericValue(nextStep, progress) } })
    }
  }
  const setAccordionRef = (ref, index) => {
    handlers.formFieldsUpdate('booking', {
      accordionRefs: { value: { ...accordionRefs, [index]: ref } }
    })
    // dont do form discard if form is empty
    if (index === 0 && !bookingCustomer) {
      handlers.formDiscardPopupReset('booking')
    }
  }
  const showHeader = bookingCustomer && !isCustomerAdd && !isCustomerEdit
  let { isAdmin, isOwner, rules } = account || {}
  rules = rules || {}
  isOwner = !!isOwner
  const globalCustomersRead = !!isAdmin || rules.customersRead
  const globalCustomersWrite = !!isAdmin || rules.customersWrite
  const { bookingsDelete } = rules
  const globalBookingsCancel = !!isAdmin || bookingsDelete
  return (
    <BookingCancelForm
      showHeader={showHeader}
      onSubmit={onGoToNext}
      customerStore={customerStore}
      selectedBranchAddress={selectedBranchAddress}
      color={color}
      setAccordionRef={setAccordionRef}
      customersRead={globalCustomersRead}
      bookingsCancel={globalBookingsCancel}
      customersWrite={globalCustomersWrite}
      selectedBookingsForDeletion={selectedBookingsForDeletion}
      isOwner={isOwner}
      isAdmin={isAdmin}
      branches={branches}
      {...props}
    />
  )
}

const maps = state => ({
  branches: state.branches.list,
  accordionRefs: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'accordionRefs', property: 'value' }),
  selectedBookingsForDeletion: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'selectedBookingsForDeletion', property: 'values' }),
  account: state.account,
  form: selectors.formSelector(state, { formName: 'booking' }),
  areCustomerFieldsFetched: selectors.customerFieldsFieldSelector(state, { field: 'areFetched' }),
  customerStore: {
    progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
    bookingCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }),
    isCustomerAdd: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerAdd', property: 'value' }),
    isCustomerEdit: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerEdit', property: 'value' }),
    selectedBranchAddress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'selectedBranchAddress', property: 'value' })
  }
})

export default connect(maps)(BookingCancelWrapper)
