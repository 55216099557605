import { PopupTriggerWrapper } from '../../Beasts'
import { Link } from '../../Common'

const UrlDetectableReplacer = ({ url, className, position, renderChildren }) => {
  position = position || 'top'

  return (
    <PopupTriggerWrapper name='url-open-warning' position={position} extras={{ url }}>
      <Link className={className} external>
        {renderChildren(url)}
      </Link>
    </PopupTriggerWrapper>
  )
}

export default UrlDetectableReplacer
