const drawCircle = (point, radius, dir) => {
  let start, end
  const d2r = Math.PI / 180
  const r2d = 180 / Math.PI
  const earthsradius = 3963

  const points = 32

  const calcRadius = radius / 1609.344
  const rlat = (calcRadius / earthsradius) * r2d
  const rlng = rlat / Math.cos(point.lat() * d2r)

  const extp = []
  if (dir === 1) {
    start = 0
    end = points + 1
  } else {
    start = points + 1
    end = 0
  }
  for (let i = start; (dir === 1 ? i < end : i > end); i = i + dir) {
    const theta = Math.PI * (i / (points / 2))
    const ey = point.lng() + (rlng * Math.cos(theta))
    const ex = point.lat() + (rlat * Math.sin(theta))
    extp.push(new window.google.maps.LatLng(ex, ey))
  }
  return extp
}

export default drawCircle
