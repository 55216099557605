import React from 'react'
import { connect, handlers, selectors } from '../../Store'
import { NavigationAccount } from '../../Beauties'

const NavigationAccountWrapper = props => {
  let { name, routerName, avatarUrl } = props
  name = name || 'User'
  avatarUrl = avatarUrl || ''
  const ROUTES_ACCOUNT = ['account', 'accountEdit', 'accountPasswordChange', 'accountEmailChange']
  const isActive = ROUTES_ACCOUNT.includes(routerName)

  const onClick = to => {
    let { router, formsWithUnsavedChanges } = props
    router = router || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    let { props: routerProps } = router || {}
    routerProps = routerProps || {}
    const { formDiscard } = routerProps || {}
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, to)
    }
    handlers.navigateToPath(to)
  }

  return (
    <NavigationAccount
      name={name}
      avatarUrl={avatarUrl}
      isActive={isActive}
      onClick={onClick}
    />
  )
}

const maps = state => ({
  account: state.account,
  name: selectors.accountFieldSelector(state, { field: 'name' }),
  avatarUrl: selectors.accountFieldSelector(state, { field: 'avatarUrl' }),
  routerName: selectors.routerFieldSelector(state, { field: 'name' }),
  router: selectors.routerSelector(state),
  formsWithUnsavedChanges: selectors.formSelector(state, { formName: 'formsWithUnsavedChanges' })
})

export default connect(maps)(NavigationAccountWrapper)
