import React from 'react'
import { iconAppStore, iconGooglePlay } from '../../Assets/images'
import {
  BlueTemplate,
  FontAwesome5,
  Link,
  Image,
  Spinner,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'

import './SmallScreenOverlay.css'

const SmallScreenOverlay = props => {
  const { isActive, pendingLanguage } = props
  const classNames = ['ta-small-screen-overlay']
  if (isActive) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      <BlueTemplate logoUrl='/'>
        <div className='ta-small-screen-overlay__message'>
          {(pendingLanguage &&
            <Spinner />
          )}
          {(!pendingLanguage &&
            <>
              <div className='ta-small-screen-overlay__message__head'>
                <FontAwesome5 icon='mobile-android' type='solid' />
                <strong>{t('smallScreenOverlay.title')}</strong>
              </div>
              <div className='ta-small-screen-overlay__message__body'>
                {dangerousHTML(t('smallScreenOverlay.text'))}
              </div>
              <div className='ta-small-screen-overlay__message__links'>
                <Link to='https://apps.apple.com/developer/terminapp-gmbh/id693997147' external>
                  <Image src={iconAppStore} alt='App Store' />
                </Link>
                <Link to='https://play.google.com/store/apps/developer?id=TerminApp+GmbH' external>
                  <Image src={iconGooglePlay} alt='Google Play' />
                </Link>
              </div>
            </>
          )}
        </div>
      </BlueTemplate>
    </div>
  )
}

export default SmallScreenOverlay
