import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Navigation } from '../../Beauties'

class NavigationWrapper extends PureComponent {
  constructor (props) {
    super(props)

    this.changePosition = this.changePosition.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = { isHover: false }
  }

  changePosition (position) {
    handlers.navigationPositionSet(position)
  }

  onMouseEnter () {
    this.setState({ isHover: true })
  }

  onMouseLeave () {
    this.setState({ isHover: false })
  }

  render () {
    const { isHover } = this.state
    let {
      position,
      route,
      sidebar,
      pending,
      accounts
    } = this.props
    sidebar = sidebar || {}
    pending = !!pending
    accounts = accounts || []
    const disabled = !([
      'home',
      'bookingAdd',
      'bookingWidget',
      'bookingEdit',
      'bookingParticipants',
      'shiftPlanAdd',
      'shiftPlanEdit'
    ].includes(route))
    const accountsCount = accounts.length
    const notOwnerAccountsCount = accounts.filter(item => !item.isOwner).length

    return (
      <Navigation
        pending={pending}
        accountsCount={accountsCount}
        notOwnerAccountsCount={notOwnerAccountsCount}
        position={position}
        disabled={disabled}
        negativeLevel={sidebar && sidebar.negative && sidebar.level}
        isHover={isHover}
        expand={this.expand}
        collapse={this.collapse}
        changePosition={this.changePosition}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      />
    )
  }
}

PropTypes.propTypes = {
  status: PropTypes.bool.isRequired,
  route: PropTypes.string
}

const maps = state => ({
  position: selectors.navigationPositionSelector(state),
  sidebar: selectors.routerPropsFieldSelector(state, { field: 'sidebar' }),
  route: selectors.routerFieldSelector(state, { field: 'name' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingAccountSelect' }),
  accounts: selectors.accountFieldSelector(state, { field: 'accounts' })
})

export default connect(maps)(NavigationWrapper)
