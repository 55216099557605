import React, { useEffect } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { AccountEnforcePasswordChangeForm } from '../../Beauties'

const AccountEnforcePasswordChangeWrapper = props => {
  let {
    form,
    pending,
    oldPasswordFieldType,
    newPasswordFieldType,
    message
  } = props
  pending = !!pending
  form = form || {}
  oldPasswordFieldType = oldPasswordFieldType || 'password'
  newPasswordFieldType = newPasswordFieldType || 'password'

  useEffect(() => {
    handlers.accountFormGet()
  }, [])

  const onSubmit = (cb) => {
    handlers.accountFormPasswordChangeSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const onRedirect = () => {
    handlers.accountFormPasswordChangeRedirect()
  }

  const toggleOldPasswordFieldType = () => {
    const oldPassword = {
      value: form.oldPassword.value,
      type: form.oldPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { oldPassword })

    return oldPassword.type
  }

  const toggleNewPasswordFieldType = () => {
    const newPassword = {
      value: form.newPassword.value,
      type: form.newPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { newPassword })

    return newPassword.type
  }

  return (
    <AccountEnforcePasswordChangeForm
      onSubmit={onSubmit}
      onRedirect={onRedirect}
      pending={pending}
      oldPasswordFieldType={oldPasswordFieldType}
      newPasswordFieldType={newPasswordFieldType}
      toggleOldPasswordFieldType={toggleOldPasswordFieldType}
      toggleNewPasswordFieldType={toggleNewPasswordFieldType}
      message={message}
    />
  )
}

const maps = (state) => ({
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingForm' }),
  form: selectors.formSelector(state, { formName: 'account' }),
  oldPasswordFieldType: selectors.formFieldPropertySelector(state, { formName: 'account', name: 'oldPassword', property: 'type' }),
  newPasswordFieldType: selectors.formFieldPropertySelector(state, { formName: 'account', name: 'newPassword', property: 'type' }),
  message: selectors.accountFieldSelector(state, { field: 'messagePreview' })
})

export default connect(maps)(AccountEnforcePasswordChangeWrapper)
