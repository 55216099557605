import React from 'react'
import {
  SectionTitle,
  FormSection,
  t,
  SingleSelect,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'
import { AgentFormCustomPermissionsWrapper } from '../../../Beasts'
import { dangerousHTML } from '../../../Utils'

const AgentFormPermissions = props => {
  let {
    isCustom,
    isOwner,
    isEditingOwnAgent,
    isPermissionUpdateAllowedByEnterpriseAgentIssuer,
    permissionType,
    permissionTypesOptions
  } = props
  permissionType = permissionType || {}
  permissionTypesOptions = permissionTypesOptions || []
  const isPermissionInOptions = !!permissionTypesOptions.find(item => item.value === permissionType)

  return (
    <div className='ta-agents__form__permissions'>
      <FormSection>
        <SectionTitle label={t('agents.form.permissions.title')} icon='lock' />
        <HoverPopup
          className='ta-agents__form__permissions__select'
          disabled={!isOwner && !isEditingOwnAgent && isPermissionUpdateAllowedByEnterpriseAgentIssuer}
        >
          <HoverPopupContent position='top' autoSize>
            {isOwner && dangerousHTML(t('users.form.permissions.cannotEditOwner.tooltip'))}
            {isEditingOwnAgent && !isOwner && t('users.form.permissions.cannotEditOwn.tooltip')}
            {!isEditingOwnAgent && !isOwner && !isPermissionUpdateAllowedByEnterpriseAgentIssuer && t('users.form.permissions.issuerCannotEdit.tooltip')}
          </HoverPopupContent>
          <HoverPopupTrigger>
            <SingleSelect
              name='permissions'
              label={t('global.permissionType')}
              disabled={isOwner || isEditingOwnAgent || !isPermissionUpdateAllowedByEnterpriseAgentIssuer}
              value={isPermissionInOptions ? permissionType : ''}
              customizedValue={isPermissionInOptions ? undefined : t('global.admin')}
            />
          </HoverPopupTrigger>
        </HoverPopup>

        {isCustom && !(isOwner || isEditingOwnAgent) && (
          <AgentFormCustomPermissionsWrapper />
        )}
      </FormSection>
    </div>
  )
}

export default AgentFormPermissions
