import React from 'react'
import {
  Title,
  SectionTitle,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  t,
  Error,
  Online,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'
import {
  AgentFormDetailsWrapper,
  AgentFormPermissionsWrapper
} from '../../../Beasts'
import { AgentFormAvatar } from '../..'

import './AgentForm.css'

const AgentForm = props => {
  const {
    id,
    title,
    onSubmit,
    availableAgents,
    cancelLink,
    type
  } = props

  return (
    <div className='ta-agents__form'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-agents__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <Form onSubmit={onSubmit} name='agents'>
            <FormSection className='ta-cleaner'>
              <SectionTitle label={t('agents.form.section.details.heading')} icon='user' />
              <AgentFormAvatar name='avatar' />
              <AgentFormDetailsWrapper type={type} />
            </FormSection>
            <AgentFormPermissionsWrapper id={id} />
            <FormSection>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>
                {t('buttons.cancel.label')}
              </Link>
              {(availableAgents <= 0 && type !== 'edit') && (
                <HoverPopup autoSize>
                  <HoverPopupContent position='top'>
                    {t('agents.add.notAvailable')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Online>
                      <Link className='ta-btn ta-btn-primary disabled'>
                        {t('buttons.save.label')}
                      </Link>
                    </Online>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
              {(availableAgents > 0 || type === 'edit') && (
                <Online>
                  <button type='submit' className='ta-btn ta-btn-primary'>
                    {t('buttons.save.label')}
                  </button>
                </Online>
              )}
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default AgentForm
