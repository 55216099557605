import { createSelector } from '../../../Utils'

export const tagsSelector = state => state.tags

export const globalTagsSelector = createSelector(
  tagsSelector,
  tags => tags?.globalTags || []
)

export const tagsListSelector = createSelector(
  tagsSelector,
  tags => (tags || {}).list || []
)

export const tagsSelectedSelector = createSelector(
  tagsSelector,
  tagsListSelector,
  (tags, tagsList) => {
    const { selected } = tags || {}
    return tagsList.find(tag => tag.id === selected) || {}
  }
)

export const tagsFieldSelector = createSelector(
  tagsSelector,
  (_, props) => props.field,
  (tags, field) => (tags || {})[field]
)
