import React from 'react'
import { connect, selectors } from '../../Store'
import { AgentFormPermissions } from '../../Beauties'

const AgentFormPermissionsWrapper = props => {
  let {
    permissions,
    email,
    isOwner,
    accountEmail,
    permissionTypesOptions,
    isPermissionUpdateAllowedByEnterpriseAgentIssuer
  } = props
  email = email || ''
  permissions = permissions || 'CUSTOM'
  const isEditingOwnAgent = accountEmail === email

  return (
    <AgentFormPermissions
      isOwner={isOwner}
      isCustom={permissions === 'CUSTOM'}
      isEditingOwnAgent={isEditingOwnAgent}
      permissionType={permissions}
      permissionTypesOptions={permissionTypesOptions}
      isPermissionUpdateAllowedByEnterpriseAgentIssuer={isPermissionUpdateAllowedByEnterpriseAgentIssuer}
    />
  )
}

// Get the date you need and filter it in the component
const maps = (state, { id }) => {
  const agent = id ? selectors.agentByIdSelector(state, { id }) || {} : {}
  return {
    isOwner: agent.isOwner,
    email: agent.email,
    permissions: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'permissions', property: 'value' }),
    permissionTypesOptions: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'permissions', property: 'options' }),
    isPermissionUpdateAllowedByEnterpriseAgentIssuer: selectors.formFieldSelector(state, { formName: 'agents', name: 'isPermissionUpdateAllowedByEnterpriseAgentIssuer' }),
    accountEmail: selectors.accountFieldSelector(state, { field: 'email' })
  }
}

export default connect(maps)(AgentFormPermissionsWrapper)
