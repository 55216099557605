import React, { PureComponent } from 'react'
import { format } from '../../../Common'

class BookingFormSlotHeader extends PureComponent {
  render () {
    const { slots, hide } = this.props
    let { values } = slots || {}
    values = values || []
    values.sort()

    if (hide) return <></>

    return (
      <div
        className='ta-booking-form__widget-availability'
        ref={wrapper => { this.wrapper = wrapper }}
      >
        {(values.length > 0 &&
          values.map(item => (
            <div key={`selected-${item}`} className='ta-booking-form__widget-availability__day__slots__selected'>
              {format(item, 'longWithShortWeekDay', { isUTC: true })} {format(item, 'time', { isUTC: true, format: 'YYYY-MM-DD HH:mm' })}
            </div>
          ))
        )}
      </div>
    )
  }
}

export default BookingFormSlotHeader
