import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FontAwesome5
} from '../../Common'

import './InnerAccordion.css'

class InnerAccordion extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: (props.isOpen) || false
    }

    this.onClickToggle = this.onClickToggle.bind(this)
  }

  onClickToggle () {
    const { children = null } = this.props

    if (children) this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  render () {
    const { iconStyle = 'red', icon = null, title = null, children = null, iconWrapStyle = {} } = this.props
    const { isOpen = false } = this.state
    const iconClasses = ['ta-inner-accordion-icon-wrapper']
    const bodyWrapperClasses = ['ta-inner-accordion-body']

    if (iconStyle === 'blue') iconClasses.push('ta-color-blue')
    if (iconStyle === 'red') iconClasses.push('ta-color-red')
    if (iconStyle === 'green') iconClasses.push('ta-color-green')
    if (iconStyle === 'grey') iconClasses.push('ta-color-grey')

    if (isOpen) bodyWrapperClasses.push('open')

    return (
      <div className='ta-inner-accordion' data-testid='innerAccordion'>
        <div
          className='ta-inner-accordion-header ta-cleaner no-select'
          onClick={this.onClickToggle}
          style={(children && { cursor: 'pointer' }) || {}}
          data-testid='innerAccordion-header'
        >
          {icon && (
            <div className={iconClasses.join(' ')} style={iconWrapStyle} data-testid='innerAccordion-icon' >
              <FontAwesome5 icon={icon} type='solid' />
            </div>
          )}

          {title && <div className='ta-inner-accordion-title' data-testid='innerAccordion-title'>{title}</div>}

          {children && (
            <div className='ta-inner-accordion-btn' data-testid='innerAccordion-btn-icon' >
              {isOpen && <FontAwesome5 icon='angle-up' type='solid' />}
              {!isOpen && <FontAwesome5 icon='angle-down' type='solid' />}
            </div>
          )}
        </div>

        <div className={bodyWrapperClasses.join(' ')} data-testid='innerAccordion-body'>{children && children}</div>
      </div>
    )
  }
}

InnerAccordion.propTypes = {
  title: PropTypes.any,
  icon: PropTypes.string,
  iconWrapStyle: PropTypes.object
}

export default InnerAccordion
