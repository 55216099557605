import { DEFAULT_LOCALE, AVAILABLE_LANGUAGES } from '../../../Settings'
import { PASSWORD_MAX_LENGTH } from '../../../Settings/settings'
import { store } from '../../../Store'
import { validator, translateServerCode } from '../../../Utils'

// Validation
export const userFormValidate = user => {
  if (!user) return

  const rules = [{
    'name.value': ['required', 'max:255']
  }, {
    'phone.phone': ['required', 'max:255', 'phone']
  }, {
    'language.value': ['required', 'max:255']
  }]

  const messages = {
    'name': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'phone': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'language': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'phone': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.phone.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: ''
      }
    },
    'language': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.language.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    }
  }

  const errors = validator(user, rules, messages, replaces)

  if (user.newPassword && user.newPasswordRepeat && user.newPassword.value && user.newPasswordRepeat.value && user.newPassword.value !== user.newPasswordRepeat.value) {
    errors.push({ key: 'newPassword', value: 'errors.password.notSame' })
    errors.push({ key: 'newPasswordRepeat', value: 'errors.password.notSame' })
  }

  return errors.length && errors
}

export const userFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// FORM
export const userFormTransform = user => {
  const locale = store.getState().app.locale
  const result = {
    name: {
      value: (user && user.name) || ''
    },
    phone: (user && user.phone && user.phone.number) && {
      phone: user.phone.number || '',
      phoneCountry: user.phone.country || 'DE'
    },
    avatar: {
      avatarUrl: {
        value: user && user.avatarUrl
      }
    },
    language: {
      value: locale || DEFAULT_LOCALE,
      options: AVAILABLE_LANGUAGES.map(language => ({
        label: language.name,
        value: language.locale
      }))
    }
  }
  return result
}

export const userSaveTransform = user => {
  return {
    locale: user.language.value || ''
  }
}

// Password change
export const userPasswordChangeFormTransform = () => {
  const result = {
    currentPassword: {
      value: '',
      type: 'password'
    },
    newPassword: {
      value: '',
      type: 'password'
    },
    newPasswordRepeat: {
      value: '',
      type: 'password'
    }
  }
  return result
}

export const userFormPasswordChangeValidate = user => {
  if (!user) return

  const passwordMaxLength = PASSWORD_MAX_LENGTH

  const rules = [{
    'newPassword.value': ['required', `max:${passwordMaxLength}`]
  }, {
    'newPasswordRepeat.value': ['required', `max:${passwordMaxLength}`]
  }, {
    'currentPassword.value': ['required', `max:${passwordMaxLength}`]
  }]

  const messages = {
    'newPassword': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'newPasswordRepeat': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'currentPassword': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    'newPassword': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.password',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: `${passwordMaxLength}`
      }
    },
    'newPasswordRepeat': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.password',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: ''
      }
    },
    'currentPassword': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.password',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: `${passwordMaxLength}`
      }
    }
  }

  const errors = validator(user, rules, messages, replaces)

  if (user.newPassword && user.newPasswordRepeat && user.newPassword.value && user.newPasswordRepeat.value && user.newPassword.value !== user.newPasswordRepeat.value) {
    errors.push({ key: 'newPassword', value: 'errors.password.notSame' })
    errors.push({ key: 'newPasswordRepeat', value: 'errors.password.notSame' })
  }

  return errors.length && errors
}

export const userSavePasswordChangeTransform = (user) => {
  return {
    userData: {
      oldPassword: user.currentPassword.value || '',
      password: user.newPassword.value || ''
    }
  }
}

// Language change
export const userLanguageChangeFormTransform = (user) => {
  const locale = store.getState().app.locale
  const result = {
    language: {
      value: locale || DEFAULT_LOCALE,
      options: AVAILABLE_LANGUAGES.map(language => ({
        label: language.name,
        value: language.locale
      }))
    }
  }
  return result
}

export const userSecurityFormTransform = (user) => {
  const { auth2FAs } = user || {}
  let is2FAEnabled = false

  if (auth2FAs && auth2FAs.length && auth2FAs[0]) {
    is2FAEnabled = auth2FAs[0].isActive && auth2FAs[0].type === 'OTP'
  }

  return {
    enabled2FA: {
      value: is2FAEnabled
    },
    isAlreadyEnabled2FA: {
      value: is2FAEnabled
    }
  }
}
