import React from 'react'
import { t, Spinner } from '../../Common'
import { dangerousHTML } from '../../Utils'

import './Login.css'

const MigrationOverlay = props => {
  const { active } = props
  const classNames = ['ta-migration-overlay']
  if (active) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-subscription-overlay__message'>
        <Spinner />
        {dangerousHTML(t('login.migrationSplashscreen.text'))}
      </div>
    </div>
  )
}

export default MigrationOverlay
