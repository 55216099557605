import React from 'react'
import { connect, handlers, selectors } from '../../Store'
import { BookingFormRepeat } from '../../Beauties'

const BookingFormRepeatWrapper = props => {
  let {
    formType,
    hasRepeat,
    repeatType,
    repeatUntil,
    repeatCustomType,
    recurringType,
    areSingleEvents
  } = props
  hasRepeat = !!hasRepeat
  repeatType = repeatType || null
  repeatUntil = repeatUntil || null
  areSingleEvents = !!areSingleEvents
  recurringType = recurringType || null
  repeatCustomType = repeatCustomType || null

  const onAreSingleEventsClick = () => {
    handlers.formFieldsUpdate('booking', { repeatUntil: { value: 'times' } })
  }

  return (
    <BookingFormRepeat
      formType={formType}
      hasRepeat={hasRepeat}
      repeatType={repeatType}
      repeatUntil={repeatUntil}
      repeatCustomType={repeatCustomType}
      recurringType={recurringType}
      areSingleEvents={areSingleEvents}
      toggleLocalStorageSection={handlers.toggleLocalStorageSection}
      onAreSingleEventsClick={onAreSingleEventsClick}
    />
  )
}

const maps = state => ({
  hasRepeat: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'hasRepeat', property: 'value' }),
  repeatType: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'repeatType', property: 'value' }),
  repeatUntil: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'repeatUntil', property: 'value' }),
  repeatCustomType: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'repeatCustomType', property: 'value' }),
  areSingleEvents: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'areSingleEvents', property: 'value' }) === 'chained',
  recurringType: selectors.formFieldSelector(state, { formName: 'booking', name: 'recurringType' })
})

export default connect(maps)(BookingFormRepeatWrapper)
