import React from 'react'
import { AgentsListItem, AgentsAddBtn } from '../../Beauties'
import { t } from '../../Common'

const AgentsList = props => {
  const {
    agents,
    search,
    available,
    total,
    agentsWrite
  } = props

  return (
    <div className='ta-agents__list'>
      <div className='ta-agents__list__total-agents'>
        <div>{t('agents.total.title')}</div>
        <div className='numbers'>{total - available} / {total}</div>
      </div>
      {agents.length > 0 && agents.map(agent => (
        <AgentsListItem
          search={search}
          key={agent.id}
          agent={agent}
        />
      ))}
      {(agentsWrite &&
        <AgentsAddBtn available={available} />
      )}
    </div>
  )
}

export default AgentsList
