import React from 'react'
import {
  BookingCustomerLinkWrapper,
  BookingCustomerLinkHeaderWrapper,
  BookingDataWrapper,
  BookingSuccessWrapper
} from '../../../Beasts'
import { BookingFormSection } from '../../../Beauties'
import {
  Title,
  Form,
  FormSection,
  Error,
  t,
  Blur
} from '../../../Common'
import './BookingForm.css'

const BookingCancelForm = ({
  customerStore,
  onSubmit,
  showHeader,
  setAccordionRef,
  bookingsCancel,
  customersRead,
  customersWrite,
  branches,
  selectedBookingsForDeletion,
  ...props
}) => {
  const { progress, bookingCustomer, isCustomerEdit } = customerStore || {}
  return (
    <Blur extraClassName={bookingsCancel ? 'inactive' : ''} active={!bookingsCancel} icon='ban' label={t('global.accessDenied')}>
      <div className='ta-booking__title'>
        <Title label={t('booking.cancelBooking.header')} />
      </div>
      {progress >= 2
        ? (
          <div className='ta-booking'>
            <BookingSuccessWrapper />
          </div>
          )
        : (
          <div className='ta-booking'>
            {progress >= 0 && (
              <BookingFormSection
                title={t('customerFields.category.basic')}
                className='ta-booking__high-zindex-section'
                header={showHeader && (
                  <BookingCustomerLinkHeaderWrapper
                    key='customer-link-header'
                    noHover
                    stacked
                    customer={bookingCustomer}
                    noListButtons
                    selectedBookings={selectedBookingsForDeletion}
                    branches={branches}
                  />
                )}
                index={0}
                setAccordionRef={setAccordionRef}
                accordionProps={{
                  hideAccordionToggle: true
                }}
              >
                <BookingCustomerLinkWrapper key='customer-link' />
              </BookingFormSection>
            )}
            {!isCustomerEdit && (
              <Form
                name='booking'
                onSubmit={(e) => onSubmit()}
                scrollWrapper='ta-booking-form__scrollable-content'
              >
                {progress >= 1 && (
                  <BookingFormSection
                    title={t('scheduleBooking.bookingData.cancel.title')}
                    setAccordionRef={setAccordionRef}
                    index={1}
                  >
                    <BookingDataWrapper key='booking-data' />
                  </BookingFormSection>
                )}
                <FormSection className='ta-booking-form-section_error'>
                  <Error name='globalErrors' noTopOffset />
                </FormSection>
              </Form>
            )}
          </div>
          )}
    </Blur>
  )
}

export default BookingCancelForm
