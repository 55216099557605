import React from 'react'
import { Link, FontAwesome5 } from '../../Common'

import './Blur.css'

const Blur = props => {
  const {
    children,
    active,
    icon,
    activateIcon = icon,
    link,
    label,
    activateLabel,
    text,
    button,
    iconType
  } = props

  const classNames = ['ta-blur']
  if (active) classNames.push('active')

  return (
    <div className={classNames.join(' ')} data-testid='blur'>
      <div className={active ? 'blur' : undefined} data-testid='blur-children'>
        {children}
      </div>
      <div className='ta-blur__overlay'>
        <div className='ta-blur__content' data-testid='blur-link'>
          {link
            ? (
              <Link className='ta-blur__content__link' to={link}>
                {icon && (
                  <div className='ta-blur__content__icon' data-testid='blur-link-icon'>
                    <FontAwesome5 className='ta-blur__content__icon__normal' icon={icon} type={iconType} />
                    {activateIcon && <FontAwesome5 className='ta-blur__content__icon__active' icon={activateIcon} />}
                  </div>
                )}
                {label && <div className='ta-blur__content__label' data-testid='blur-link-label'>{label}</div>}
                {activateLabel && <div className='ta-blur__content__activate-label' data-testid='blur-link-activate-label'>{activateLabel}</div>}
              </Link>
            )
            : (
              <>
                {icon && (
                  <div className='ta-blur__content__icon' data-testid='blur-icon'>
                    <FontAwesome5 icon={icon} />
                  </div>
                )}
                {label && <div className='ta-blur__content__label' data-testid='blur-label'>{label}</div>}
              </>
            )}
          <>
            {text && <div className='ta-blur__content__text' data-testid='blur-text'>{text}</div>}
            {(button && button.link && button.label) && <div data-testid='blur-button'><Link className='ta-blur__content__button ta-btn ta-btn-primary' to={button.link}>{button.label}</Link></div>}
          </>
        </div>
      </div>
    </div>
  )
}

export default Blur
