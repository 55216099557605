import React, { useEffect } from 'react'
import { BookingScheduleForm } from '../../Beauties'
import { handlers, selectors, connect, globalActions } from '../../Store'

const extractNumericValue = (nextStep, progress) => !nextStep && nextStep !== 0 ? progress + 1 : nextStep

const BookingScheduleWrapper = ({
  customerStore,
  color,
  accordionRefs,
  account,
  pendingTransformation,
  areCustomerFieldsFetched,
  ...props
}) => {
  const { progress, bookingCustomer, isCustomerAdd, selectedBranchAddress, isCustomerEdit } = customerStore || {}

  useEffect(() => {
    globalActions.populateCustomerFieldsAndCategories(true)

    return () => handlers.customersListPopulate()
  }, [])

  useEffect(() => {
    handlers.customerFormPopulate({ customer: null, branches: [] })
    handlers.customerPreviewPopulate(null)
    handlers.formSet('customersFilters', {})
    if (areCustomerFieldsFetched) handlers.bookingFormGet()

    return () => {
      handlers.bookingFormGet()
    }
  }, [areCustomerFieldsFetched])

  const onGoToNext = (nextStep = undefined) => {
    handlers.formFieldsUpdate('booking', { progress: { value: extractNumericValue(nextStep, progress) } })
  }

  const setAccordionRef = (ref, index) => {
    handlers.formFieldsUpdate('booking', {
      accordionRefs: { value: { ...accordionRefs, [index]: ref } }
    })

    // dont do form discard if form is empty
    if (index === 0 && !bookingCustomer) {
      handlers.formDiscardPopupReset('booking')
    }
  }

  const showHeader = bookingCustomer && !isCustomerAdd && !isCustomerEdit

  let { isAdmin, isOwner, rules } = account || {}
  rules = rules || {}
  isOwner = !!isOwner
  const globalCustomersRead = !!isAdmin || rules.customersRead
  const globalCustomersWrite = !!isAdmin || rules.customersWrite
  const { bookingsSchedule } = rules
  const globalBookingsSchedule = !!isAdmin || bookingsSchedule

  return (
    <BookingScheduleForm
      showHeader={showHeader}
      onSubmit={onGoToNext}
      customerStore={customerStore}
      selectedBranchAddress={selectedBranchAddress}
      color={color}
      setAccordionRef={setAccordionRef}
      customersRead={globalCustomersRead}
      bookingsSchedule={globalBookingsSchedule}
      customersWrite={globalCustomersWrite}
      isOwner={isOwner}
      isAdmin={isAdmin}
      pendingTransformation={pendingTransformation}
      {...props}
    />
  )
}

const maps = state => ({
  areCustomerFieldsFetched: selectors.customerFieldsFieldSelector(state, { field: 'areFetched' }),
  accordionRefs: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'accordionRefs', property: 'value' }),
  pendingTransformation: selectors.bookingsFieldSelector(state, { field: 'pendingTransformation' }),
  account: state.account,
  customerStore: {
    progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
    bookingCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }),
    isCustomerAdd: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerAdd', property: 'value' }),
    isCustomerEdit: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerEdit', property: 'value' }),
    selectedBranchAddress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'selectedBranchAddress', property: 'value' })
  }
})

export default connect(maps)(BookingScheduleWrapper)
