export const fileFields = `{
  acceptRanges
  lastModified
  content
  etag
  contentType
  contentEncoding
  metadata
}`

export const customerFieldDownloadFileFields = `{
  content
}`
