import React, { useEffect } from 'react'
import { connect, globalActions, handlers, selectors } from '../../../Store'
import { Customers } from '../../../Beauties'
import { t } from '../../../Common'
import { goToNextStep } from '../../../Utils'

const BookingCustomerLinkWrapper = props => {
  let {
    customers,
    total,
    showResults,
    search,
    message,
    hash,
    account,
    bookingCustomer,
    isCustomerEdit,
    isCustomerAdd,
    customersStore,
    progress,
    pendingList,
    pendingBranches,
    router,
    isScheduleForm,
    fieldsToBeDeleted,
    formsWithUnsavedChanges,
    page,
    lastPage,
    shouldUseMongoCustomerSearch,
    tags,
    services
  } = props
  services = services || []
  tags = tags || []
  customers = customers || []
  account = account || {}
  total = total || null
  message = message || null
  router = router || {}
  formsWithUnsavedChanges = formsWithUnsavedChanges || []
  let { props: routerProps } = router || {}
  routerProps = routerProps || {}
  const { formDiscard } = routerProps || {}
  let { isAdmin, isOwner, rules } = account || {}
  rules = rules || {}
  isOwner = !!isOwner
  const globalCustomersRead = !!isAdmin || rules.customersRead
  const globalCustomersWrite = !!isAdmin || rules.customersWrite

  useEffect(() => {
    if (isScheduleForm) {
      handlers.servicesListGet({ allowedBranchIds: [] })
    }
    handlers.globalTagsGet()
  }, [isScheduleForm])

  const getCustomers = () => {
    handlers.formErrorsSet('booking')
    if ((isCustomerAdd || isCustomerEdit) && formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, null, () => handlers.bookingFormCustomerSearch())
    }
    handlers.customerBookingsGet()
    handlers.bookingFormCustomerSearch()
  }

  const getCustomerForm = (type) => {
    handlers.formFieldsUpdate('booking', { [type]: { value: true } })
    handlers.customerFormGet({ id: bookingCustomer ? (bookingCustomer.internalId || bookingCustomer.id) : undefined })
  }

  const onSelectCustomer = (item, nextStep = undefined) => {
    handlers.customerUpdate(item)
    handlers.customerPreviewPopulate(item)
    handlers.formFieldsUpdate('booking', {
      bookingCustomer: { value: item },
      progress: { value: isScheduleForm ? goToNextStep(nextStep, progress) : 3 },
      transformedCustomer: { value: {} }
    })
    globalActions.populateTags(item ? item.tags : [])
  }

  const onPreviewCustomer = (customer, isOpen) => {
    fieldsToBeDeleted.forEach(field => {
      handlers.formFieldsUpdate('booking', { [field]: { value: false } })
    })
    if (isOpen) {
      handlers.customerBookingsGet()
    } else {
      handlers.customerEventFormGet()
      handlers.customerBookingsGet(customer.id)
    }
  }

  const onCreateCustomer = (customer) => {
    onSelectCustomer(customer)
  }

  const onLoadMore = () => handlers.customersListGet(page + 1, true)

  const label = t('customers.list.all')

  return (
    <Customers
      onSelectCustomer={onSelectCustomer}
      onPreviewCustomer={onPreviewCustomer}
      isCustomerEdit={isCustomerEdit}
      isCustomerAdd={isCustomerAdd}
      bookingCustomer={bookingCustomer}
      customers={customers}
      label={label}
      total={total}
      showResults={showResults}
      getCustomers={getCustomers}
      customersStore={customersStore}
      onCreateCustomer={onCreateCustomer}
      getCustomerForm={getCustomerForm}
      search={search}
      services={services}
      isOwner={isOwner}
      customersRead={globalCustomersRead}
      customersWrite={globalCustomersWrite}
      hash={hash}
      isScheduleForm={isScheduleForm}
      message={message}
      pendingList={pendingList}
      pendingBranches={pendingBranches}
      lastPage={lastPage}
      onLoadMore={onLoadMore}
      shouldUseMongoCustomerSearch={shouldUseMongoCustomerSearch}
      tags={tags}
    />
  )
}

const maps = state => ({
  hash: state.router.hash,
  search: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'search', property: 'value' }),
  customers: state.customers.list,
  showResults: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'showResults', property: 'value' }),
  customersStore: state.customers,
  form: selectors.formSelector(state, { formName: 'customers' }),
  total: state.customers.total,
  message: state.customers.messageList,
  account: state.account,
  progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
  bookingCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }),
  isCustomerAdd: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerAdd', property: 'value' }),
  isCustomerEdit: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'isCustomerEdit', property: 'value' }),
  pendingList: selectors.customersPropertySelector(state, { property: 'pendingList' }),
  pendingBranches: selectors.customersPropertySelector(state, { property: 'pendingBranches' }),
  router: selectors.routerSelector(state),
  formsWithUnsavedChanges: selectors.formSelector(state, { formName: 'formsWithUnsavedChanges' }),
  fieldsToBeDeleted: Object.keys(state.forms.booking).filter(key => key.includes('bookingForDeletion') && !!state.forms.booking[key].value),
  page: state.customers.page,
  lastPage: state.customers.lastPage,
  shouldUseMongoCustomerSearch: selectors.companySettingsFieldSelector(state, { field: 'shouldUseMongoCustomerSearch' }),
  tags: selectors.globalTagsSelector(state),
  services: selectors.servicesListSelector(state)
})

export default connect(maps)(BookingCustomerLinkWrapper)
