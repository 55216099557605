import React from 'react'

import './List.css'

const ListItem = props => {
  const { children, className = '', isBold } = props
  const classes = ['ta-list']
  if (className !== '') classes.push(className)
  if (isBold) classes.push('bold')

  return (
    <div className={classes.join(' ')} data-testid='list-item'>
      {children}
    </div>
  )
}

export default ListItem
