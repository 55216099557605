import React from 'react'
import {
  FontAwesome5,
  Link,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  t
} from '../../Common'

const NavigationLinks = props => {
  const { hideSupportLink, onClick, active } = props
  const classNames = ['ta-navigation__links']
  if (active) classNames.push('active')

  return (
    <div className={classNames.join(' ')}>
      {(!hideSupportLink &&
        <HoverPopup className='ta-navigation__links__link'>
          <HoverPopupContent position='right' autoSize>{t('navigation.support.tooltip')}</HoverPopupContent>
          <HoverPopupTrigger>
            <Link to='https://support.timify.com/' target='_blank' external>
              <FontAwesome5 icon='life-ring' type='solid' />
            </Link>
          </HoverPopupTrigger>
        </HoverPopup>
      )}
      {/* <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('navigation.appcues.tooltip')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Link>
            <FontAwesome5 icon='question-circle' type='regular' />
          </Link>
        </HoverPopupTrigger>
      </HoverPopup> */}
      <HoverPopup className='ta-navigation__links__link'>
        <HoverPopupContent position='right' autoSize>{t('navigation.logout.tooltip')}</HoverPopupContent>
        <HoverPopupTrigger>
          <Link onClick={() => onClick('/logout')}>
            <FontAwesome5 icon='sign-out-alt' type='solid' data-testid='logout' />
          </Link>
        </HoverPopupTrigger>
      </HoverPopup>
    </div>
  )
}

export default NavigationLinks
