import { createSelector } from '../../../Utils'

export const accountSelector = state => state.account

export const accountFieldSelector = createSelector(
  accountSelector,
  (_, props) => props.field,
  (account, field) => (account || {})[field]
)

export const accountEnterpriseSelector = createSelector(
  accountSelector,
  (account) => (account || {}).enterprise
)

export const accountCallCentreSettingsSelector = createSelector(
  accountSelector,
  (account) => {
    const { enterprise } = account || {}
    const { callCentreSettings } = enterprise || {}
    return callCentreSettings
  }
)

export const accountCallCentreSettingsPropertySelector = createSelector(
  accountCallCentreSettingsSelector,
  (_, props) => props.property,
  (settings, property) => (settings || {})[property]
)

export const accountEnterprisePropertySelector = createSelector(
  accountEnterpriseSelector,
  (_, props) => props.property,
  (enterprise, property) => (enterprise || {})[property]
)

export const enterpriseSettingsFieldSelector = createSelector(
  state => accountEnterprisePropertySelector(state, { property: 'settings' }),
  (_, props) => props.field,
  (settings, field) => (settings || {})[field]
)

// could use accountFieldSelector for isAdmin and permissions
export const accountPermissionSelector = createSelector(
  accountSelector,
  (_, props) => props.permission,
  (account, permission) => {
    let { isAdmin, permissions } = account || {}
    isAdmin = !!isAdmin
    permissions = permissions || {}
    return {
      [`${[permission]}Read`]: isAdmin || permissions[`${permission}Read`],
      [`${[permission]}Write`]: isAdmin || permissions[`${permission}Write`],
      [`${[permission]}Delete`]: isAdmin || permissions[`${permission}Delete`]
    }
  }
)
