import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../Store'

const Link = props => {
  const {
    to,
    children,
    style,
    action,
    external,
    onClick,
    disabled,
    ...rest
  } = props
  const _onClick = e => {
    if (action) action()
    if (external) return null
    e.preventDefault()
    e.stopPropagation()
    if (!to || to.length === 0) return null
    handlers.navigateToPath(to)
  }
  if (!disabled) {
    return (
      <a data-testid='link' {...rest} href={to} onClick={onClick || _onClick} style={style} >
        {children}
      </a>
    )
  } else {
    return (
      <div data-testid='link-disabled' {...rest} style={style} >
        {children}
      </div>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string,
  style: PropTypes.object,
  action: PropTypes.func,
  external: PropTypes.bool,
  onClick: PropTypes.func
}

export default Link
