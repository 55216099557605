const reducer = {}

reducer.initialState = {
  pendingSettings: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  settingsReset: () => dispatch(actions.SETTINGS_RESET),
  settingsGet: () => dispatch(actions.SETTINGS_GET),
  settingsReady: () => dispatch(actions.SETTINGS_READY),
  settingsFormSave: (form) => dispatch(actions.SETTINGS_FORM_SAVE, form),
  settingsFormGet: () => dispatch(actions.SETTINGS_FORM_GET),
  settingsFormReady: () => dispatch(actions.SETTINGS_FORM_READY),
  settingsMessageSet: (message) => dispatch(actions.SETTINGS_MESSAGE_SET, message)
})

reducer.SETTINGS_RESET = state => reducer.initialState

reducer.SETTINGS_GET = state => ({
  ...state,
  pendingSettings: true
})

reducer.SETTINGS_READY = (state) => ({
  ...state,
  pendingSettings: false
})

reducer.SETTINGS_FORM_GET = (state) => ({
  ...state
})

reducer.SETTINGS_FORM_READY = (state) => ({
  ...state
})

reducer.SETTINGS_FORM_SAVE = (state) => ({
  ...state
})

reducer.SETTINGS_MESSAGE_SET = (state, message) => ({
  ...state,
  messagePreviewSettings: message
})

export default reducer
