import gql from 'graphql-tag'
import { ApolloLink, concat, execute, makePromise } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { store, handlers, selectors } from '../../Store'
import {
  APP_VERSION,
  APP_VERSION_SUFFIX,
  CALL_CENTRE_URL,
  FILE_DOWNLOAD_URL
} from '../../Settings'
import { isObject, decodeHtml } from '../../Utils'
import * as account from './AccountsEngine'
import * as callCentreEnterprise from './CallCentreEnterprise'
import * as agents from './Agents'
import * as services from './Services'
import * as fileDownload from './FileDownload'
import * as resources from './Resources'

const endpoints = {
  AccountsEngine: {
    url: CALL_CENTRE_URL,
    definitions: account
  },
  CallCentre: {
    url: CALL_CENTRE_URL,
    definitions: callCentreEnterprise
  },
  Agents: {
    url: CALL_CENTRE_URL,
    definitions: agents
  },
  Services: {
    url: CALL_CENTRE_URL,
    definitions: services
  },
  FileDownload: {
    url: FILE_DOWNLOAD_URL,
    definitions: fileDownload
  },
  Resources: {
    url: CALL_CENTRE_URL,
    definitions: resources
  }
}

// Debuging only on development and staging
const VERBOSE_QUERIES = !!['development', 'staging'].includes(process.env.REACT_APP_ENV)

const getDefinition = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const definition = endpoint && endpoint.definitions[name]
  if (!definition) throw new Error(`Please define the ${name} query definition`)
  return definition
}

const getEndpointUrl = name => {
  const endpoint = Object
    .keys(endpoints)
    .map(name => endpoints[name])
    .find(endpoint => !!endpoint.definitions[name])

  const endpointUrl = endpoint && endpoint.url
  if (!endpointUrl) throw new Error(`Please define the ${name} query definition`)
  return endpointUrl
}

const link = (name, ignoreAccessToken, headersArray, url) => {
  const httpLink = new HttpLink({ uri: url || getEndpointUrl(name) })
  const authTokenLink = new ApolloLink((operation, forward) => {
    const headers = {}
    const state = store.getState()
    const accessToken = (state.auth && state.auth.tokens && state.auth.tokens.accessToken) || ''
    if (headersArray) {
      headersArray.forEach(header => { headers[header.key] = header.value })
      operation.setContext({ headers })
    }
    if (!accessToken) return forward(operation)
    if (!ignoreAccessToken) {
      headers.authorization = 'Bearer ' + accessToken
      operation.setContext({ headers })
    }
    return forward(operation)
  })

  return concat(authTokenLink, httpLink)
}

let isRefreshRunning = false

// run refresh tokens
const runRefreshTokens = async () => {
  // for 5 seconds only one refresh is running
  // this is for subscriptions becuase they are launcing millions of refresh in same time :)
  if (isRefreshRunning) return
  isRefreshRunning = true
  setTimeout(() => { isRefreshRunning = false }, 5000)
  const state = store.getState()
  const { refreshToken } = state.auth.tokens || {}
  // see if current access token expired then refresh the tokens
  // with access token expired and no refresh token we log out
  if (!refreshToken) return handlers.logout()
  // get new access token if error here logout
  let tokens = {}
  try {
    tokens = await q('refreshTokens', { refreshToken }, true)
  } catch (err) {
    // in case of error here then logout
    handlers.logout()
  }
  // not access token or duration then logout
  if (!tokens.accessToken || !tokens.sessionDuration) return handlers.logout()
  // consider five minute earlier the refresh
  const expires = (new Date()).getTime() + parseInt(tokens.sessionDuration, 10)
  const sessionExpires = (new Date()).getTime() + parseInt(tokens.refreshDuration, 10)
  handlers.authTokensPopulate({ ...tokens, expires, sessionExpires })
}

// checks if has to refresh tokens
const checkRefresh = async queryName => {
  const state = store.getState()
  const tokens = state.auth.tokens || {}
  const { accessToken, expires } = tokens
  // see if current access token expired then refresh the tokens
  const expired = expires && expires < new Date().getTime() + (5 * 1000 * 60)
  // console.warn('=====111', queryName, expires, expired)
  if (queryName !== 'refreshTokens' && accessToken && expired) {
    await runRefreshTokens()
  }
}

// Decode all encoded chars to prevent double decoding from JSX
const decodeResponse = response => {
  if (!isObject(response)) return response
  if (Array.isArray(response)) {
    return Object
      .keys(response)
      .map(key => {
        const item = response[key]
        if (isObject(item)) return decodeResponse(item)
        return typeof item !== 'string' ? item : decodeHtml(item)
      })
  }
  return Object
    .keys(response)
    .reduce((acc, key) => {
      const item = response[key]
      if (typeof item === 'string') return { ...acc, [key]: decodeHtml(item) }
      return isObject(item) ? { ...acc, [key]: decodeResponse(item) } : { ...acc, [key]: item }
    }, {})
}

// runs a query or mutation
export const q = async (queryName, variables, ignoreAccessToken, headers, url) => {
  headers = headers || []
  const query = getDefinition(queryName)
  // if query is not defined then throw
  if (!query) return console.error(`Define query or mutation with name: ${queryName}`)
  await checkRefresh(queryName)
  const operation = {
    query: gql(query),
    variables
  }
  let result = {}
  try {
    headers.push({ key: 'pi', value: window.btoa(JSON.stringify({ p_n: APP_VERSION_SUFFIX, p_v: APP_VERSION })) })
    result = await makePromise(execute(link(queryName, ignoreAccessToken, headers, url), operation))
    // if success then connection is still online
    const state = store.getState()
    const isConnected = selectors.appFieldSelector(state, { field: 'isConnected' })
    if (!isConnected) handlers.connectionChange(true)
  } catch (err) {
    // special error when jwt expires then need to refresh automatically the tokens
    if (((err.result && err.result.errors) || []).some(({ extensions: { code } = {} }) => code === 'InvalidAccessToken')) {
      await runRefreshTokens()
      // rerun itself
      return q(queryName, variables, ignoreAccessToken, null, url)
    }
    // special error when jwt invalidates then need to logout
    if (((err.result && err.result.errors) || []).some(({ extensions: { code } = {} }) => code === 'JwtInvalidated')) {
      handlers.logout()
    }
    if (VERBOSE_QUERIES) console.warn(`------------------------------- \nQuery: ${queryName} \nError:`, err, '\n---------------------------------')

    // connection become offline
    handlers.connectionChange(false)
    return {
      error: {
        code: 'ServerDown',
        message: 'Server down'
      }
    }
  }
  // to review this, seems not ok
  if (!result) {
    return { error: { text: 'Not found ' } }
  }
  // deal with errors
  if (result.errors) {
    const error = result.errors[0]
    const { extensions: { code, exception = {} }, message } = error
    delete exception.stacktrace
    if (code === 'JwtInvalidated') handlers.logout()
    const err = {
      error: { code, message, data: { ...exception } },
      errors: result.errors.map(e => {
        const { extensions: { code, exception = {} }, message } = e
        delete exception.stacktrace
        return { code, message, data: { ...exception } }
      })
    }
    if (VERBOSE_QUERIES) console.warn(`------------------------------- \nQuery: ${queryName} \nError:`, err, '\n---------------------------------')
    return err
  }

  // one query at a time usually
  const queryNames = Object.keys(result.data)
  const specific = queryNames.length > 1
  const finalResult = specific ? result.data : result.data[queryNames[0]]
  if (VERBOSE_QUERIES) console.warn(`------------------------------- \nQuery: ${queryName} \nResult:`, finalResult, '\n---------------------------------')
  return decodeResponse(finalResult)
}

// keep here until final refactor
export const runMutation = null
export const runQuery = null
export const runCustomQuery = null
