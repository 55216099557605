import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  GoogleMaps,
  FormGroup,
  Error,
  BorderedBox,
  ListItem,
  Row,
  Col,
  FontAwesome5,
  t
} from '../../../Common'

import './AddressInput.css'

export class AddressInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.onReset = this.onReset.bind(this)
    this.state = {
      focused: false,
      isDirty: false, // to control wether the user is typing or not
      inputValue: '' // input value when the user is typing so the cursor won't jump to the end
    }
    this.firstSuggestionPlaceId = React.createRef()
  }

  componentDidMount () {
    const { addRef, data, formName, form, name, value } = this.props
    addRef && addRef(this)

    if (data) {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value, data } })
    }
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (value) {
    let { name, form, formName, onChangeAddon } = this.props
    form = form || { value: '' }
    const data = value ? form.data : null
    this.setState({ inputValue: value, isDirty: true }, () => {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value, data } })

      !data && handlers.formFieldsUpdate(formName, { [`${name}Secondary`]: { value: '' } })
      onChangeAddon && onChangeAddon(value, name)
    })
  }

  async onSelect (address, placeId) {
    let { name, form, formName, onSelect } = this.props
    form = form || { value: '' }
    // Get detailed address
    const currentPlaceId = placeId || this.firstSuggestionPlaceId.current
    if (!currentPlaceId) return
    const results = await geocodeByPlaceId(currentPlaceId)
    // TODO: @Yanko maybe display message that result not found?

    if (!results || !results[0]) return
    const latLng = await getLatLng(results[0])
    const data = { ...this.extractAddressData(results[0]), ...latLng }

    this.setState({ isDirty: false }, () => {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value: data.formattedAddress, data } })
    })
    if (onSelect) onSelect(data)
  }

  onReset () {
    let { name, form, formName } = this.props
    form = form || { value: '' }

    this.setState({ isDirty: false, inputValue: '' })

    handlers.formFieldsUpdate(formName, {
      [name]: { ...form, value: '', data: null },
      addressSecondary: { value: '' }
    })
  }

  extractAddressData (geoResult) {
    const {
      address_components: addressComponents,
      formatted_address: formattedAddress,
      place_id: placeId
    } = geoResult
    const initials = {
      formattedAddress,
      placeId,
      streetName: '',
      streetNumber: '',
      neighborhood: '',
      city: '',
      country: '',
      postalCode: ''
    }

    return addressComponents.reduce((acc, component) => {
      component.types && component.types.map(type => {
        const {
          short_name: shortName,
          long_name: longName
        } = component

        if (type === 'street_number') acc.streetNumber = longName
        else if (type === 'route') acc.streetName = shortName
        else if (type === 'locality') acc.city = longName
        else if (type === 'neighborhood') acc.neighborhood = longName
        else if (type === 'postal_code') acc.postalCode = longName
        else if (type === 'country') {
          acc.countryCode = shortName
          acc.country = longName
        } else if (!acc.city && type === 'postal_town') acc.city = longName
        else if (!acc.city && type === 'sublocality_level_1') acc.city = longName
        else if (!acc.city && type === 'administrative_area_level_1') acc.city = longName

        return type
      })

      return acc
    }, initials)
  }

  renderAutocompleteInput ({ suggestions, getInputProps }) {
    let { name, placeholder, disabled, form, clearable } = this.props
    form = form || { value: '' }
    const classNames = ['ta-form-control']
    if (suggestions && suggestions.length > 0) classNames.push('has-suggestions')

    return (
      <>
        <input
          {...getInputProps()}
          className={classNames.join(' ')}
          type='text'
          name={name}
          placeholder={placeholder || form.placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled || form.disabled}
          autoComplete='off'
        />
        {clearable && form.value &&
          <div className='ta-form-control__btn-clear' onClick={this.onReset}>
            <FontAwesome5 icon='times' type='solid' />
          </div>
        }
      </>
    )
  }

  renderAutocompleteDropdown ({ suggestions, getSuggestionItemProps }) {
    if (suggestions && suggestions.length > 0) {
      this.firstSuggestionPlaceId.current = suggestions[0].placeId
    }
    return (
      <>
        {suggestions && suggestions.length > 0 &&
          <div className='ta-address-input__autocomplete-dropdown'>
            {suggestions.map((suggestion, index) => {
              const classNames = ['ta-address-input__suggestion-item']
              if (suggestion.active) classNames.push('active')
              return (
                <div className={classNames.join(' ')} {...getSuggestionItemProps(suggestion)} key={index}>
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        }
      </>
    )
  }

  renderPlacesAutocomplete () {
    const { inputValue, isDirty, focused } = this.state
    let {
      value,
      form,
      hideError,
      hintText,
      addon,
      name,
      label,
      mandatory,
      disabled
    } = this.props
    form = form || { value: '' }
    const searchOptions = { types: ['address'] }

    return (
      <FormGroup
        focused={focused}
        filled={!!value || !!form.value}
        disabled={disabled || form.disabled}
        labelText={label || form.label}
        labelMandatory={mandatory || form.mandatory}
      >
        <PlacesAutocomplete
          value={value || (isDirty ? inputValue : form.value) || ''}
          searchOptions={searchOptions}
          onSelect={this.onSelect}
          onChange={this.onChange}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <>
              {this.renderAutocompleteInput({ suggestions, getInputProps })}
              {form.value !== '' && this.renderAutocompleteDropdown({ suggestions, getSuggestionItemProps })}
            </>
          )}
        </PlacesAutocomplete>
        {addon &&
          <div className='ta-form-control__addon'>{addon}</div>
        }
        {hintText &&
          <div className='ta-form-control__hint'>{hintText}</div>
        }
        {!hideError &&
          <Error name={name} />
        }
      </FormGroup>
    )
  }

  render () {
    let {
      className,
      radiusInput,
      withMap, // Renders a google map and connects with the autocomplete input
      draggable, // Google map pin will be draggable
      form,
      customerAddressRadius
    } = this.props
    form = form || { value: '' }
    customerAddressRadius = parseInt(customerAddressRadius, 10)
    const { lat, lng } = form.data || ''
    const classNames = ['ta-address-input']
    const hasRadiusInput = !!radiusInput
    if (className) classNames.push(className)

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        <Row>
          <Col size={hasRadiusInput ? 70 : 100} className='ta-location__autocomplete'>
            {this.renderPlacesAutocomplete()}
          </Col>
          {hasRadiusInput && (
            <Col className='ta-location__radius' size={30}>
              {radiusInput}
            </Col>
          )}
        </Row>
        {form.data &&
          <>
            <BorderedBox>
              {withMap &&
                <Row>
                  <Col>
                    <div className='ta-address-input__map'>
                      <GoogleMaps
                        lat={lat}
                        lng={lng}
                        draggable={draggable}
                        customerAddressRadius={customerAddressRadius}
                      />
                    </div>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <ListItem>
                    <strong className={form.data.city ? '' : 'required'}>{t('address.city')}:</strong> {form.data.city ? form.data.city : '-'}
                  </ListItem>
                  <ListItem>
                    <strong className={form.data.postalCode ? '' : 'required'}>{t('address.zip')}:</strong> {form.data.postalCode ? form.data.postalCode : '-'}
                  </ListItem>
                  <ListItem>
                    <strong className={form.data.streetName ? '' : 'required'}>{t('address.streetName')}:</strong> {form.data.streetName ? form.data.streetName : '-'}
                  </ListItem>
                  <ListItem>
                    <strong className={form.data.streetNumber ? '' : 'required'}>{t('address.streetNumber')}:</strong> {form.data.streetNumber ? form.data.streetNumber : '-'}
                  </ListItem>
                  {form.data.neighborhood &&
                    <ListItem>
                      <strong>{t('address.neighborhood')}:</strong> {form.data.neighborhood}
                    </ListItem>
                  }
                  <ListItem>
                    <strong className={form.data.formattedAddress ? '' : 'required'}>{t('address.label')}:</strong> {form.data.formattedAddress}
                  </ListItem>
                </Col>
              </Row>
              {/* <Input
                name={`${name}Secondary`}
                label={t('address.secondary.label')}
                placeholder={t('address.secondary.placeholder')}
                hintText={t('address.secondary.hint')}
                onChangeAddon={onChangeAddon}
              /> */}
            </BorderedBox>
          </>
        }
      </div>
    )
  }
}

AddressInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

export const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  customerAddressRadius: selectors.formFieldPropertySelector(state, { formName: props.formName, name: 'customerAddressRadius', property: 'value' })
})

export default feedContextInProps(connect(maps)(AddressInput), FormContext)
