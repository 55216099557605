import {
  loginFormInitialValues,
  loginSSOFormInitialValues,
  loginSaveTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingForm: false,
  pendingOneTimeToken: false,
  emailWarningStatus: false,
  userTemporaryBlockedStatus: false,
  doRedirect: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  logout: (locale, oneTimeLoginToken, skipInvalidateSession) => dispatch(actions.LOGOUT, { locale, oneTimeLoginToken, skipInvalidateSession }),
  tokensReset: () => dispatch(actions.TOKENS_RESET),
  loginWithSSO: (form, scrollToError = () => { }) => dispatch(actions.LOGIN_WITH_SSO, { form, scrollToError }),
  loginWithEmailAndPassword: (form, scrollToError = () => { }) => dispatch(actions.LOGIN_WITH_EMAIL_AND_PASSWORD, { fields: loginSaveTransform(form), scrollToError }),
  loginWithOneTimeToken: token => dispatch(actions.LOGIN_WITH_ONE_TIME_TOKEN, token),
  loginWithOneTimeTokenErrorSet: () => dispatch(actions.LOGIN_WITH_ONE_TIME_TOKEN_ERROR_SET),
  loginFormReady: () => dispatch(actions.LOGIN_FORM_READY),
  loginFormPopulate: (locale) => {
    handlers.formSet('login', loginFormInitialValues(locale))
    handlers.loginFormReady()
  },
  loginFormEmailWarningSet: status => dispatch(actions.LOGIN_FORM_EMAIL_WARNING_SET, status),
  loginFormUserTemporaryBlockedWithResetSet: status => dispatch(actions.LOGIN_FORM_USER_TEMPORARY_BLOCKED_WITH_RESET_SET, status),
  loginSSOFormPopulate: () => {
    handlers.formSet('sso', loginSSOFormInitialValues())
    handlers.loginFormReady()
  },
  authTokensChange: (tokens, validateTwoFA) => dispatch(actions.AUTH_TOKENS_CHANGE, { ...tokens, validateTwoFA }),
  authTokensPopulate: ({ accessToken, refreshToken, expires, sessionExpires }) => dispatch(actions.AUTH_TOKENS_POPULATE, { accessToken, refreshToken, expires, sessionExpires }),
  authEmailVerificationSend: email => dispatch(actions.AUTH_EMAIL_VERIFICATION_SEND, email),

  // Migration
  migrationRedirect: () => dispatch(actions.MIGRATION_REDIRECT),

  // 2FA
  loginWith2FA: (code, callback) => dispatch(actions.AUTH_TWO_FA, { code, callback }),
  loggedInWith2FA: () => dispatch(actions.AUTH_LOGGED_IN_WITH_TWO_FA),
  loginPendingDefaultSet: () => dispatch(actions.LOGIN_PENDING_DEFAULT_SET)
})

reducer.LOGOUT = (state) => ({
  ...state,
  pendingForm: false
})

reducer.TOKENS_RESET = state => ({
  ...state,
  tokens: null
})

reducer.LOGIN_WITH_SSO = state => ({
  ...state,
  pendingForm: true
})

reducer.LOGIN_WITH_EMAIL_AND_PASSWORD = state => ({
  ...state,
  pendingForm: true,
  userTemporaryBlockedStatus: null
})

reducer.LOGIN_WITH_ONE_TIME_TOKEN = state => ({
  ...state,
  pendingOneTimeToken: true,
  userTemporaryBlockedStatus: null
})

reducer.LOGIN_WITH_ONE_TIME_TOKEN_ERROR_SET = state => ({
  ...state,
  pendingOneTimeToken: false
})

reducer.LOGIN_FORM_READY = state => ({
  ...state,
  pendingForm: false,
  userTemporaryBlockedStatus: null
})

reducer.LOGIN_FORM_EMAIL_WARNING_SET = (state, status) => ({
  ...state,
  pendingForm: false,
  emailWarningStatus: status || null
})

reducer.LOGIN_FORM_USER_TEMPORARY_BLOCKED_WITH_RESET_SET = (state, status) => ({
  ...state,
  pendingForm: false,
  userTemporaryBlockedStatus: status || null
})

reducer.LOGIN_PENDING_DEFAULT_SET = state => ({
  ...state,
  pendingForm: false,
  pendingOneTimeToken: false
})

reducer.AUTH_TOKENS_CHANGE = state => ({
  ...state,
  pendingOneTimeToken: false
})

reducer.AUTH_TOKENS_POPULATE = (state, tokens) => ({
  ...state,
  tokens
})

reducer.AUTH_EMAIL_VERIFICATION_SEND = state => state

// Migration

reducer.MIGRATION_REDIRECT = state => ({
  ...state,
  doRedirect: true
})

// 2FA
reducer.AUTH_TWO_FA = state => ({
  ...state,
  pendingForm: true
})

reducer.AUTH_LOGGED_IN_WITH_TWO_FA = state => state

export default reducer
