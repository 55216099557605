import React from 'react'
import { PermissionTypesPermissionsForm } from '../../Beauties'
import { connect, selectors } from '../../Store'

const AgentFormCustomPermissionsWrapper = ({ bookingsSchedule, bookingsReschedule, bookingsDelete, customersRead, usersRead, usersWrite }) => {
  return (
    <PermissionTypesPermissionsForm
      bookingsSchedule={bookingsSchedule}
      bookingsReschedule={bookingsReschedule}
      bookingsDelete={bookingsDelete}
      customersRead={customersRead}
      usersRead={usersRead}
      usersWrite={usersWrite}
    />
  )
}

const maps = state => ({
  bookingsSchedule: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'bookingsSchedule', property: 'value' }),
  bookingsDelete: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'bookingsDelete', property: 'value' }),
  bookingsReschedule: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'bookingsReschedule', property: 'value' }),
  customersRead: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'customersRead', property: 'value' }),
  usersRead: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'usersRead', property: 'value' }),
  usersWrite: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'usersWrite', property: 'value' })
})

export default connect(maps)(AgentFormCustomPermissionsWrapper)
