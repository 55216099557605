import React from 'react'
import PropTypes from 'prop-types'
import { Button, Alert, Spinner, t } from '../../Common'
import { CustomersListItem } from '../../Beauties'

const CustomersList = props => {
  let {
    customers,
    search,
    onSelectCustomer,
    onPreviewCustomer,
    customersStore,
    showResults,
    enterpriseId,
    isScheduleForm,
    pendingBranches,
    lastPage,
    pendingList,
    onLoadMore,
    services
  } = props
  customers = customers || []
  const customersLength = customers.length
  const classNames = ['ta-customers__list']
  if (customersLength === 0 && !showResults) classNames.push('no-margin')

  return (
    <div className={classNames.join(' ')}>
      <>
        {customersLength > 0 && (
          <>
            <div className='search_result__total'>{customersLength} {t('global.results')}</div>
            {customers.map((customer, index) => (
              <CustomersListItem
                isFirst={index === 0}
                key={index}
                customer={customer}
                customers={customersStore}
                search={search}
                services={services}
                blurTotalBookings={false}
                enterpriseId={enterpriseId}
                onSelectCustomer={onSelectCustomer}
                isScheduleForm={isScheduleForm}
                onPreviewCustomer={onPreviewCustomer}
                pendingBranches={pendingBranches}
              />
            ))}
            {(!lastPage &&
              <Button
                primary
                greyGhost
                block
                className='ta-customers__booking-load-more-button'
                onClick={onLoadMore}
              >
                {pendingList && customersLength ? <Spinner /> : t('bookings.customerPreview.customerDetails.label')}
              </Button>
            )}
          </>
        )}
        {(!pendingList && !customersLength && search !== '' && showResults &&
          <Alert noOffset label={t('global.noResults')} theme='no-content' />
        )}
      </>
    </div>
  )
}

CustomersList.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CustomersList
