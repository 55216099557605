import { store } from '../../../Store'
import { validator, translateServerCode } from '../../../Utils'

// LIST

export const usersListTransform = users => {
  if (!users) return
  return users.map(item => ({
    ...item
  }))
}

// FORM

export const usersFormTransform = (user = {}) => {
  const result = {
    email: {
      value: user.email || '',
      disabled: !!user.id
    },
    name: {
      value: user.name || ''
    },
    phone: {
      phoneCountry: (user.phone && user.phone.country) || 'DE',
      phone: (user.phone && user.phone.number) || ''
    },
    roles: {
      value: (user.roles && user.roles[0]) || 'ADMIN'
    }
  }
  if (user.id) result.id = user.id
  return result
}

export const usersFormValidate = ({ user, userEmails }) => {
  if (!user) return

  const rules = [{
    'name.value': ['required', 'max:255']
  }, {
    'email.value': ['required', 'email', 'max:255']
  }, {
    'phone.phone': ['required', 'phone', 'max:32']
  }, {
    'roles.value': ['required', 'max:255']
  }]

  const messages = {
    'name': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    },
    'email': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength',
      'email': 'errors.email.invalid'
    },
    'phone': {
      'required': 'errors.required',
      'phone': 'errors.phone.invalid',
      'max': 'errors.invalidMaxLength'
    },
    'roles': {
      'required': 'errors.required',
      'max': 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'email': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.email.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    },
    'phone': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.phone.label',
        translateReplace: true
      },
      'max': {
        key: 'MAX',
        value: '32'
      }
    },
    'roles': {
      'required': {
        key: 'FIELD_NAME',
        value: 'User group'

      },
      'max': {
        key: 'MAX',
        value: '255'
      }
    }
  }

  const form = store.getState().forms.users
  const { email } = form || {}
  const { value: emailValue } = email || {}
  const errors = validator(form, rules, messages, replaces)
  if (userEmails.includes(emailValue)) errors.push({ key: 'email', value: 'errors.email.exists' })

  return errors.length && errors
}

export const usersFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

export const usersSaveTransform = (user = {}) => {
  return {
    id: user.id,
    name: user.name.value || '',
    email: user.email.value || '',
    phone: (user.phone && user.phone.phoneCountry && user.phone.phone) && {
      country: (user.phone && user.phone.phoneCountry) || '',
      number: (user.phone && user.phone.phone) || ''
    },
    role: (user.roles && user.roles.value) || ''
  }
}

// UPDATE

export const usersTransform = user => {
  if (!user) return
  return {
    ...user,
    isBatch: (user.durationsPattern || []).length > 1
  }
}
