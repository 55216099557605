import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupSettingsHelpBox = props => {
  return (
    <div className='ta-popup__booking-settings-help-box'>
      <Alert noOffset noBorder label={t('settings.heading.helpTooltip.title')} theme='alert'>
        {dangerousHTML(t('settings.heading.helpTooltip.text'))}
      </Alert>
    </div>
  )
}

export default PopupSettingsHelpBox
