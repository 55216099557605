import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, FontAwesome5, Select, t } from '../../../Common'

import './MultipleSelect.css'

class MultipleSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onToggle = this.onToggle.bind(this)
    this.state = { focused: false, active: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onToggle (active) {
    const { onToggle } = this.props
    onToggle && onToggle(active)
    this.setState({ active })
  }

  onChange (values) {
    let { form, formName, name, onChangeAddon } = this.props
    form = form || { values: [], options: [] }
    const newValues = values.map(value => value.value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
    this.setState({ filled: values.length > 0 })
    onChangeAddon && onChangeAddon(newValues, name)
  }

  onDelete (value) {
    let { form, formName, name } = this.props
    form = form || { values: [], options: [] }
    const newValues = form.values.filter(v => v !== value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues } })
    this.setState({ filled: newValues.length > 0 })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const { focused, active } = this.state
    let {
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      options,
      searchable,
      inline,
      inlineTags,
      form,
      renderItem,
      renderSelectedItem,
      filter,
      pending,
      onReset,
      position,
      onSearch,
      hideArrow,
      hideNoResults,
      renderOptionsHeader,
      renderOptionsFooter,
      hasCategories,
      hasAll,
      allLabel,
      getOptions,
      hideSelected,
      searchPlaceholder,
      hasSearchInside
    } = this.props
    form = form || { values: [], options: [] }
    const _options = options || form.options
    const hasError = form.errors && form.errors.length > 0
    const classNames = ['ta-multiple-select']
    if (inline) classNames.push('inline')

    return (
      <div>
        <FormGroup
          focused={(hasSearchInside || !searchable) ? undefined : focused}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          hasError={hasError}
          isActive={active}
        >
          <Select
            className={classNames.join(' ')}
            noResultsText={t('global.noResults')}
            name={name}
            value={form.values}
            arrowRenderer={this.renderArrows}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onDelete={this.onDelete}
            multi
            searchable={searchable || false}
            options={_options || []}
            disabled={disabled || form.disabled}
            autoComplete='off'
            inlineTags={inlineTags}
            renderItem={renderItem}
            renderSelectedItem={renderSelectedItem}
            filter={filter}
            pending={pending}
            onReset={onReset}
            position={position}
            onSearch={onSearch}
            hideArrow={hideArrow}
            hideNoResults={hideNoResults}
            renderOptionsHeader={renderOptionsHeader}
            renderOptionsFooter={renderOptionsFooter}
            hasCategories={hasCategories}
            hasAll={hasAll}
            allLabel={allLabel}
            getOptions={getOptions}
            hideSelected={hideSelected}
            hasError={hasError}
            noLabel={!label && !form.label}
            searchPlaceholder={searchPlaceholder}
            hasSearchInside={hasSearchInside}
            onToggle={this.onToggle}
          />
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>}
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

MultipleSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  searchable: PropTypes.bool,
  inline: PropTypes.bool,
  form: PropTypes.object,
  inlineTags: PropTypes.bool,
  onToggle: PropTypes.func
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(MultipleSelect), FormContext)
