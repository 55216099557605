import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FontAwesome5, FormGroup, Error, Select, t } from '../../../Common'

import './SingleSelect.css'

class SingleSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onToggle = this.onToggle.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = { focused: false, active: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onToggle (active) {
    const { onToggle } = this.props
    onToggle && onToggle(active)
    this.setState({ active })
  }

  onChange (selectValue) {
    let { mandatory, options, form, name, onChange, onChangeAddon } = this.props
    form = form || { value: '' }
    const _options = options || form.options
    let value = selectValue ? selectValue.value : ''
    if (mandatory && !value && _options.length > 0) value = _options[0].value
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(this.props.formName, { [this.props.name]: { ...this.props.form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  renderArrows () {
    const { icon, iconType } = this.props

    return (
      <div>
        <FontAwesome5 icon={icon || 'sort'} type={iconType || 'solid'} />
      </div>
    )
  }

  render () {
    const { focused, active } = this.state
    let {
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      value,
      options,
      clearable,
      searchable,
      width,
      form,
      renderItem,
      filter,
      pending,
      onReset,
      position,
      onSearch,
      hideArrow,
      hideNoResults,
      renderOptionsHeader,
      renderOptionsFooter,
      hasCategories,
      hasAll,
      allLabel,
      getOptions,
      customizedValue,
      searchPlaceholder,
      hasSearchInside
    } = this.props
    form = form || { value: '' }
    const styles = {
      width: width && width
    }
    const hasError = form.errors && form.errors.length > 0
    const hasColor = !!(options || form.options)?.find(item => !!item.color)

    return (
      <div style={styles}>
        <FormGroup
          focused={(hasSearchInside || !searchable) ? undefined : focused}
          filled={!!value || !!form.value}
          disabled={disabled !== undefined ? disabled : form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory !== undefined ? mandatory : form.mandatory}
          hasError={hasError}
          isActive={active}
          hasPrefix={hasColor}
        >
          <Select
            className='ta-single-select'
            noResultsText={t('global.noResults')}
            name={name}
            value={value !== undefined ? value : form.value}
            customizedValue={customizedValue}
            arrowRenderer={this.renderArrows}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            onToggle={this.onToggle}
            options={options || form.options}
            clearable={!mandatory && clearable}
            searchable={searchable || false}
            disabled={disabled !== undefined ? disabled : form.disabled}
            autoComplete='off'
            renderItem={renderItem}
            filter={filter}
            pending={pending}
            onReset={onReset}
            position={position}
            onSearch={onSearch}
            hideArrow={hideArrow}
            hideNoResults={hideNoResults}
            renderOptionsHeader={renderOptionsHeader}
            renderOptionsFooter={renderOptionsFooter}
            hasCategories={hasCategories}
            hasAll={hasAll}
            allLabel={allLabel}
            getOptions={getOptions}
            hasError={hasError}
            noLabel={!label && !form.label}
            searchPlaceholder={searchPlaceholder}
            hasSearchInside={hasSearchInside}
          />
          {hintText && (
            <div className='ta-form-control__hint'>{hintText}</div>
          )}
          {!hideError && (
            <Error name={name} />
          )}
        </FormGroup>
      </div>
    )
  }
}

SingleSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  form: PropTypes.object,
  onToggle: PropTypes.func
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(SingleSelect), FormContext)
