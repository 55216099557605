export default (mapsCircle) => {
  const lineSymbol = {
    path: mapsCircle,
    fillOpacity: 1,
    scale: 1
  }
  return {
    strokeOpacity: 0,
    icons: [{
      icon: lineSymbol,
      offset: '0',
      repeat: '5px'
    }],
    strokeWeight: 1,
    strokeColor: '#385f87',
    fillColor: '#385f87',
    fillOpacity: 0.1
  }
}
