import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Agents } from '../../Beauties'
import { MAX_NO_OF_AGENTS } from '../../Settings/settings'

class AgentsListWrapper extends Component {
  componentDidMount () {
    handlers.agentsListGet()
  }

  render () {
    let {
      pending,
      account,
      agents,
      filters
    } = this.props
    pending = !!pending
    account = account || {}
    filters = filters || {}
    agents = agents || []

    const { isCustom, rules } = account
    const agentsRead = (!isCustom || (isCustom && rules.usersRead))
    const agentsWrite = (!isCustom || (isCustom && rules.usersWrite))
    const { search } = filters
    const { value: searchValue } = search || {}
    const filteredAgents = agents
      .filter(agent => {
        if (searchValue) {
          return agent.name.toLowerCase().includes(searchValue.toLowerCase()) || agent.id === searchValue
        }
        return agent
      })

    return (
      <Agents
        available={MAX_NO_OF_AGENTS - agents.length}
        total={MAX_NO_OF_AGENTS}
        account={account}
        pending={pending}
        search={searchValue}
        agents={filteredAgents}
        agentsRead={agentsRead}
        agentsWrite={agentsWrite}
      />
    )
  }
}

AgentsListWrapper.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object)
}

const maps = state => ({
  pending: selectors.agentsPendingListSelector(state),
  account: state.account,
  agents: selectors.agentsListSelector(state),
  filters: selectors.formSelector(state, { formName: 'agentsFilters' }),
  isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
  isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
  isCustom: selectors.accountFieldSelector(state, { field: 'isCustom' }),
  rules: selectors.accountFieldSelector(state, { field: 'rules' })
})

export default connect(maps)(AgentsListWrapper)
