import React from 'react'

const IconListItemButtons = props => {
  const { children } = props

  return (
    <div className='ta-icon-list__buttons'>
      {children}
    </div>
  )
}

export default IconListItemButtons
