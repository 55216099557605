import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { AccountsSwitch } from '../../Beauties'

const AccountsSwitchWrapper = props => {
  let { route, companyName, negativeLevel } = props
  companyName = companyName || ''
  const isActive = route === 'accounts'
  const onClick = to => {
    let { router, formsWithUnsavedChanges } = props
    router = router || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    let { props: routerProps } = router || {}
    routerProps = routerProps || {}
    const { formDiscard } = routerProps || {}
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, to)
    }
    handlers.navigateToPath(to)
  }

  return (
    <AccountsSwitch
      negativeLevel={negativeLevel}
      isActive={isActive}
      companyName={companyName}
      onClick={onClick}
    />
  )
}

AccountsSwitchWrapper.propTypes = {
  route: PropTypes.string,
  accountsCount: PropTypes.number
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' }),
  companyName: selectors.companyFieldSelector(state, { field: 'name' }),
  router: selectors.routerSelector(state),
  formsWithUnsavedChanges: selectors.formSelector(state, { formName: 'formsWithUnsavedChanges' })
})

export default connect(maps)(AccountsSwitchWrapper)
