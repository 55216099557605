import React from 'react'
import {
  Input,
  ColorSelect,
  Row,
  Col,
  Alert,
  t
} from '../../../Common'

const AgentFormDetails = props => {
  const { hasDuplicateEmails } = props
  const agentAbbreviationLimit = 4

  return (
    <div className='ta-agents__form__details'>
      <div className='ta-agents__form__details-fields'>
        <Row>
          <Col>
            <ColorSelect name='color' disabled={false}>
              <Input
                name='name'
                label={t('global.name.label')}
                placeholder={t('global.name.placeholder')}
                mandatory
              />
            </ColorSelect>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name='abbreviation'
              limit={agentAbbreviationLimit}
              label={t('global.abbreviation.label')}
              placeholder={t('global.abbreviation.placeholder')}
              className='ta-resource-abbreviation__field'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Input
              name='email'
              label={t('global.email.label')}
              placeholder={t('global.email.placeholder')}
              mandatory
            />
            {hasDuplicateEmails && (
              <Alert noOffset theme='alert'>
                {t('agents.form.section.details.InviteUser.noteText')}
              </Alert>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AgentFormDetails
