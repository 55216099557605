import { sortByOrderIndex } from '../../../Utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  // reorderList: null,
  // reorderCategoriesList: null,
  pendingList: true,
  pendingListCategory: false,
  // pendingForm: true,
  // pendingPreview: true,
  // pendingCategory: null,
  // pendingCategoryForm: true,
  selected: null,
  // dragging: {},
  areFetched: false
  // messageList: null,
  // messagePreview: null,
  // hasOrderChanged: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // // Reset
  // customerFieldsReset: () => dispatch(actions.CUSTOMER_FIELDS_RESET),

  // // Subscription
  // customerFieldsSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.CUSTOMER_FIELDS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // // List
  // customerFieldsListGet: () => dispatch(actions.CUSTOMER_FIELDS_LIST_GET),
  customerFieldsListPopulate: ({ customerFields, customerFieldCategories }) => dispatch(actions.CUSTOMER_FIELDS_LIST_POPULATE, { customerFields, customerFieldCategories }),
  // customerFieldsListReady: () => dispatch(actions.CUSTOMER_FIELDS_LIST_READY),
  // customerFieldsListOrderChange: ({ id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => dispatch(actions.CUSTOMER_FIELDS_LIST_ORDER_CHANGE, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }),
  // customerFieldsListOrderChanged: (updatedCustomerFields) => dispatch(actions.CUSTOMER_FIELDS_LIST_ORDER_CHANGED, updatedCustomerFields),
  customerFieldsListMessageSet: message => dispatch(actions.CUSTOMER_FIELDS_LIST_MESSAGE_SET, message),
  // Download File
  customerFieldDownloadFile: ({ key, formName, name }) => dispatch(actions.CUSTOMER_FIELDS_DOWNLOAD_FILE, { key, formName, name })

  // // Reorder
  // customerFieldsListReorderReset: () => dispatch(actions.CUSTOMER_FIELDS_LIST_REORDER_RESET),
  // customerFieldsListReorderGet: () => dispatch(actions.CUSTOMER_FIELDS_LIST_REORDER_GET),
  // customerFieldsListReorderSave: () => dispatch(actions.CUSTOMER_FIELDS_LIST_REORDER_SAVE),
  // customerFieldsListReorderReady: payload => dispatch(actions.CUSTOMER_FIELDS_LIST_REORDER_READY, payload),

  // // One
  // customerFieldUpdate: customerField => dispatch(actions.CUSTOMER_FIELD_UPDATE, customerFieldTransform(customerField)),
  // customerFieldUpdated: customerField => dispatch(actions.CUSTOMER_FIELD_UPDATED, customerField),
  // customerFieldDelete: id => dispatch(actions.CUSTOMER_FIELD_DELETE, id),
  // customerFieldDeleted: id => dispatch(actions.CUSTOMER_FIELD_DELETED, id),
  // customerFieldRemoveDeleted: id => dispatch(actions.CUSTOMER_FIELD_REMOVE_DELETED, id),
  // customerFieldResetGlobal: internalId => dispatch(actions.CUSTOMER_FIELD_RESET_GLOBAL, internalId),
  // customerFieldResetGlobalReady: (payload) => dispatch(actions.CUSTOMER_FIELD_RESET_GLOBAL_READY, payload),

  // // Preview
  // customerFieldPreviewGet: ({ id, forceFetch }) => dispatch(actions.CUSTOMER_FIELD_PREVIEW_GET, { id, forceFetch }),
  // customerFieldPreviewPopulate: id => dispatch(actions.CUSTOMER_FIELD_PREVIEW_POPULATE, id),
  // customerFieldMessagePreviewSet: message => dispatch(actions.CUSTOMER_FIELD_MESSAGE_PREVIEW_SET, message),

  // // Form
  // customerFieldFormGet: id => dispatch(actions.CUSTOMER_FIELD_FORM_GET, id),
  // customerFieldFormPopulate: customerField => {
  //   handlers.formSet('customerFields', customerFieldFormTransform(customerField))
  //   handlers.customerFieldFormReady()
  // },
  // customerFieldFormReady: () => dispatch(actions.CUSTOMER_FIELD_FORM_READY),
  // customerFieldFormSave: (customerField, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_FORM_SAVE, { customerField, scrollToError }),
  // customerFieldDeleteFormPopulate: () => {
  //   handlers.formSet('customerFieldsDelete', customerFieldDeleteFormTransform())
  //   handlers.customerFieldDeleteFormReady()
  // },
  // customerFieldDeleteFormSave: (customerField, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_DELETE_FORM_SAVE, { customerField, scrollToError }),
  // customerFieldDeleteFormReady: () => dispatch(actions.CUSTOMER_FIELD_DELETE_FORM_READY),

  // // Category
  // customerFieldCategoryUpdate: category => dispatch(actions.CUSTOMER_FIELD_CATEGORY_UPDATE, category),
  // customerFieldCategoryUpdated: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_UPDATED),
  // customerFieldCategoryDelete: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETE, id),
  // customerFieldCategoryDeleted: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETED, id),
  // customerFieldCategoryRemoveDeleted: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_REMOVE_DELETED, id),
  // customerFieldCategoryDeleteFailed: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_DELETE_FAILED),
  // customerFieldCategoryOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  // customerFieldCategoryOrderChanged: updatedCategories => dispatch(actions.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGED, updatedCategories),
  // customerFieldCategoryResetGlobal: ({ internalId, id }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_RESET_GLOBAL, { internalId, id }),
  // customerFieldCategoryResetGlobalReady: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_RESET_GLOBAL_READY),

  // // Category form
  // customerFieldCategoryFormGet: id => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_GET, id),
  // customerFieldCategoryFormPopulate: category => {
  //   handlers.formSet('customerFieldCategory', customerFieldCategoryFormTransform(category))
  //   handlers.customerFieldCategoryFormReady()
  // },
  // customerFieldCategoryFormReady: () => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_READY),
  // customerFieldCategoryFormSave: (category, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FIELD_CATEGORY_FORM_SAVE, { category: customerFieldCategorySaveTransform(category), scrollToError })
})

// // Reset

// reducer.CUSTOMER_FIELDS_RESET = state => reducer.initialState

// // Subscription

// reducer.CUSTOMER_FIELDS_SUBSCRIPTION_SET = state => state

// // List

// reducer.CUSTOMER_FIELDS_LIST_GET = state => ({
//   ...state,
//   pendingList: true,
//   messageList: null,
//   messagePreview: null
// })

reducer.CUSTOMER_FIELDS_LIST_POPULATE = (state, { customerFields = [], customerFieldCategories = [] }) => ({
  ...state,
  list: customerFields.sort(sortByOrderIndex),
  categoriesList: customerFieldCategories.sort(sortByOrderIndex),
  pendingList: false,
  areFetched: true
})

// reducer.CUSTOMER_FIELDS_LIST_READY = state => ({
//   ...state,
//   pendingList: false
// })

// reducer.CUSTOMER_FIELDS_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
//   if (newIndex === oldIndex && sourceCategoryId === destinationCategoryId) return { ...state }
//   const reorderList = [...(state.reorderList || [])]
//   const customerField = { ...(reorderList.find(item => item.id === id) || {}) }
//   let sourceCategoryCustomerFields = reorderList.filter(item => item.categoryId === sourceCategoryId) || []
//   const destinationCategoryCustomerFields = reorderList.filter(item => item.categoryId === destinationCategoryId) || []
//   const otherCategoriesCustomerFields = reorderList.filter(item => item.categoryId !== sourceCategoryId && item.categoryId !== destinationCategoryId) || []
//   if (sourceCategoryId === destinationCategoryId) {
//     destinationCategoryCustomerFields.splice(oldIndex, 1)
//     destinationCategoryCustomerFields.splice(newIndex, 0, customerField)
//     sourceCategoryCustomerFields = []
//   }
//   if (sourceCategoryId !== destinationCategoryId) {
//     customerField.categoryId = destinationCategoryId
//     sourceCategoryCustomerFields.splice(oldIndex, 1)
//     destinationCategoryCustomerFields.splice(newIndex, 0, customerField)
//   }
//   return {
//     ...state,
//     hasOrderChanged: true,
//     reorderList: [
//       ...destinationCategoryCustomerFields,
//       ...sourceCategoryCustomerFields,
//       ...otherCategoriesCustomerFields
//     ]
//   }
// }

// reducer.CUSTOMER_FIELDS_LIST_ORDER_CHANGED = (state, updatedCustomerFields) => {
//   updatedCustomerFields = updatedCustomerFields.map(item => {
//     const oldCustomerField = state.list.find(customerField => customerField.id === item.id)
//     return {
//       ...oldCustomerField,
//       ...item
//     }
//   })
//   return {
//     ...state,
//     list: state.list
//       .filter(item => !updatedCustomerFields.find(customerField => customerField.id === item.id))
//       .concat(updatedCustomerFields)
//       .sort(sortByOrderIndex)
//   }
// }

reducer.CUSTOMER_FIELDS_LIST_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingList: false,
  messageList: message,
  messagePreview: null
})

// // Reorder

// reducer.CUSTOMER_FIELDS_LIST_REORDER_RESET = state => ({
//   ...state,
//   reorderList: null,
//   hasOrderChanged: false,
//   reorderCategoriesList: null
// })

// reducer.CUSTOMER_FIELDS_LIST_REORDER_GET = state => {
//   const categories = [...state.categoriesList].map(item => ({ ...item }))
//     .filter(Boolean)
//     .sort(sortByOrderIndex)
//   // const localCategories = categories.filter(item => !item.internalId)
//   // const globalCategories = categories.filter(item => item.internalId && !item.isDefault)
//   const withoutDefault = categories.filter(item => !item.isDefault)
//   return {
//     ...state,
//     hasOrderChanged: false,
//     reorderList: ([...state.list]).filter(item => !item.internalId).sort(sortByOrderIndex),
//     reorderCategoriesList: [ ...withoutDefault ]
//     // reorderCategoriesList: [ ...localCategories, ...globalCategories ]
//   }
// }

// reducer.CUSTOMER_FIELDS_LIST_REORDER_SAVE = state => ({
//   ...state,
//   pendingList: true,
//   hasOrderChanged: false
// })

// reducer.CUSTOMER_FIELDS_LIST_REORDER_READY = (state, payload) => {
//   payload = payload || { customerFields: [], categories: [] }
//   const { list, categoriesList } = state || {}
//   const customerFields = {}
//   const categories = {}
//   payload.customerFields.forEach(item => {
//     customerFields[item.id] = {
//       orderIndex: item.orderIndex,
//       categoryId: item.categoryId
//     }
//   })
//   payload.categories.forEach(item => {
//     categories[item.id] = { orderIndex: item.orderIndex }
//   })
//   return {
//     ...state,
//     pendingList: false,
//     list: list.map(item => ({ ...item, ...(customerFields[item.id] || {}) })),
//     categoriesList: categoriesList.map(item => ({ ...item, ...(categories[item.id] || {}) }))
//   }
// }

// // One

// reducer.CUSTOMER_FIELD_UPDATE = (state, customerField) => {
//   if (!customerField) return state
//   const reorderList = [...((state.reorderList || []).map(item => item.id === customerField.id ? customerField : item))]
//   const reorderIndex = reorderList.findIndex(item => item.id === customerField.id)
//   if (reorderIndex === -1) reorderList.push(customerField)
//   return {
//     ...state,
//     reorderList,
//     list: state.list
//       .filter(item => item.id !== customerField.id)
//       .concat([{ ...customerField, isUpdated: true }])
//       .sort(sortByOrderIndex),
//     pendingPreview: true
//   }
// }

// reducer.CUSTOMER_FIELD_UPDATED = (state, customerField) => {
//   if (!customerField) return state
//   const list = [...state.list]
//   const index = list.findIndex(item => item.id === customerField.id)
//   if (index < 0) return state
//   list[index] = { ...list[index], isUpdated: false }
//   return {
//     ...state,
//     list,
//     pendingPreview: false
//   }
// }

// reducer.CUSTOMER_FIELD_DELETE = state => ({
//   ...state,
//   pendingPreview: true
// })

// reducer.CUSTOMER_FIELD_DELETED = (state, id) => {
//   const list = [...state.list]
//   const index = list.findIndex(item => item.id === id)
//   if (index < 0) return state
//   list[index] = { ...list[index], isDeleted: true }
//   return {
//     ...state,
//     list
//   }
// }

// reducer.CUSTOMER_FIELD_REMOVE_DELETED = (state, id) => {
//   return {
//     ...state,
//     list: state.list.filter(item => item.id !== id),
//     reorderList: (state.reorderList || []).filter(item => item.id !== id)
//   }
// }

// reducer.CUSTOMER_FIELD_RESET_GLOBAL = state => state

// reducer.CUSTOMER_FIELD_RESET_GLOBAL_READY = (state, payload) => {
//   const { internalId } = payload || {}
//   const customerFields = state.list || []
//   let selected = customerFields.find(item => item.internalId === internalId)
//   selected = payload
//   const index = customerFields.findIndex(item => item.id === selected.id)
//   customerFields[index] = selected

//   return {
//     ...state,
//     list: customerFields
//   }
// }

// // Preview

// reducer.CUSTOMER_FIELD_PREVIEW_GET = state => ({
//   ...state,
//   pendingPreview: true,
//   messageList: null,
//   messagePreview: null
// })

// reducer.CUSTOMER_FIELD_PREVIEW_POPULATE = (state, id) => {
//   if (!id) return { ...state, pendingPreview: false, selected: null }
//   return {
//     ...state,
//     pendingPreview: false,
//     selected: id
//   }
// }

// reducer.CUSTOMER_FIELD_MESSAGE_PREVIEW_SET = (state, message) => ({
//   ...state,
//   pendingPreview: false,
//   messagePreview: message,
//   selected: null
// })

// // Form

// reducer.CUSTOMER_FIELD_FORM_GET = state => ({
//   ...state,
//   pendingForm: true
// })

// reducer.CUSTOMER_FIELD_FORM_READY = state => ({
//   ...state,
//   pendingForm: false
// })

// reducer.CUSTOMER_FIELD_FORM_SAVE = state => ({
//   ...state,
//   pendingForm: true
// })

// reducer.CUSTOMER_FIELD_DELETE_FORM_READY = state => ({
//   ...state,
//   pendingDeleteForm: false
// })

// reducer.CUSTOMER_FIELD_DELETE_FORM_SAVE = state => ({
//   ...state,
//   pendingDeleteForm: true
// })

// // Category

// reducer.CUSTOMER_FIELD_CATEGORY_UPDATE = (state, category) => {
//   if (!category) return state
//   const reorderCategoriesList = (state.reorderCategoriesList || []).map(item => item.id === category.id ? category : item)
//   const reorderIndex = reorderCategoriesList.findIndex(item => item.id === category.id)
//   if (reorderIndex === -1) reorderCategoriesList.push(category)
//   return {
//     ...state,
//     reorderCategoriesList,
//     categoriesList: state.categoriesList
//       .filter(item => item.id !== category.id)
//       .concat([category])
//       .sort(sortByOrderIndex),
//     pendingCategory: category.id
//   }
// }

// reducer.CUSTOMER_FIELD_CATEGORY_UPDATED = state => ({
//   ...state,
//   pendingCategory: null
// })

// reducer.CUSTOMER_FIELD_CATEGORY_DELETE = (state, id) => ({
//   ...state,
//   pendingCategory: id
// })

// reducer.CUSTOMER_FIELD_CATEGORY_DELETED = (state, id) => {
//   const categoriesList = [...state.categoriesList]
//   const index = categoriesList.findIndex(item => item.id === id)
//   if (index < 0) return state
//   categoriesList[index] = { ...categoriesList[index], isDeleted: true }
//   return {
//     ...state,
//     categoriesList,
//     pendingCategory: null
//   }
// }

// reducer.CUSTOMER_FIELD_CATEGORY_REMOVE_DELETED = (state, id) => {
//   const categoriesList = [...state.categoriesList]
//   const defaultCategory = categoriesList.find(item => item.isDefault)
//   const deletedCategorycustomerFields = [...state.list]
//     .filter(item => item.categoryId === id)
//     .map(item => ({ ...item, categoryId: defaultCategory.id }))
//   const list = [...state.list]
//     .filter(item => item.categoryId !== id)
//     .concat(deletedCategorycustomerFields)
//     .map((item, index) => ({ ...item, orderIndex: index + 1 }))
//   return {
//     ...state,
//     list,
//     categoriesList: state.categoriesList.filter(item => item.id !== id),
//     reorderCategoriesList: (state.reorderCategoriesList || []).filter(item => item.id !== id)
//   }
// }

// reducer.CUSTOMER_FIELD_CATEGORY_DELETE_FAILED = state => ({
//   ...state,
//   pendingCategory: null
// })

// reducer.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex }) => {
//   if (newIndex === oldIndex) return { ...state }
//   const reorderCategoriesList = [...(state.reorderCategoriesList || [])]
//   const category = reorderCategoriesList.find(item => item.id === id) || {}
//   reorderCategoriesList.splice(oldIndex, 1)
//   reorderCategoriesList.splice(newIndex, 0, category)
//   return {
//     ...state,
//     hasOrderChanged: true,
//     reorderCategoriesList
//   }
// }

// reducer.CUSTOMER_FIELD_CATEGORIES_LIST_ORDER_CHANGED = (state, updatedCategories) => {
//   updatedCategories = updatedCategories.map(item => {
//     const oldCategory = state.categoriesList.find(category => category.id === item.id)
//     return {
//       ...oldCategory,
//       ...item
//     }
//   })
//   return {
//     ...state,
//     categoriesList: state.categoriesList
//       .filter(item => !updatedCategories.find(category => category.id === item.id))
//       .concat(updatedCategories)
//       .sort(sortByOrderIndex)
//   }
// }

// reducer.CUSTOMER_FIELD_CATEGORY_RESET_GLOBAL = (state, { id }) => ({
//   ...state,
//   pendingCategory: id
// })

// reducer.CUSTOMER_FIELD_CATEGORY_RESET_GLOBAL_READY = state => ({
//   ...state,
//   pendingCategory: null
// })

// // Category form

// reducer.CUSTOMER_FIELD_CATEGORY_FORM_GET = state => ({
//   ...state,
//   pendingCategoryForm: true
// })

// reducer.CUSTOMER_FIELD_CATEGORY_FORM_READY = state => ({
//   ...state,
//   pendingCategoryForm: false
// })

// reducer.CUSTOMER_FIELD_CATEGORY_FORM_SAVE = state => ({
//   ...state,
//   pendingForm: true
// })

// DownloadFile
reducer.CUSTOMER_FIELDS_DOWNLOAD_FILE = state => state

export default reducer
