// Add here subscriptions to handler relation
export default {
  accountUpdated: 'accountSubscriptionSet',
  accountDeleted: 'accountSubscriptionSet',
  enterpriseUpdated: 'accountSubscriptionSet',
  enterpriseUserUpdated: 'agentsSubscriptionSet',
  enterpriseUserDeleted: 'agentsSubscriptionSet',
  agentDeleted: 'agentsSubscriptionSet',
  customerEnterpriseUpdated: 'customersSubscriptionSet',
  invalidate2faSessions: 'accountSubscriptionSet',
  invalidate2faSession: 'accountSubscriptionSet'
}
