import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import momenttz from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  OutsideClick,
  format,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../..'

import './CalendarDayInput.css'

class CalendarDayInput extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.togglePicker = this.togglePicker.bind(this)
    this.closePicker = this.closePicker.bind(this)
    this.getDayClassNames = this.getDayClassNames.bind(this)
    this.onClickNextWeek = this.onClickNextWeek.bind(this)
    this.onClickPrevWeek = this.onClickPrevWeek.bind(this)

    this.state = { isOpen: false }
  }

  onChange (momentDate) {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const value = (momentDate && momenttz.tz(momentDate, 'YYYY-MM-DD', timezone).format('YYYY-MM-DD')) || null
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    this.closePicker()
  }

  onClickPrevWeek () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const value = form.value
      ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone).subtract(1, 'days').format('YYYY-MM-DD')
      : momenttz.tz(timezone).subtract(1, 'days').format('YYYY-MM-DD')
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  onClickNextWeek () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const value = form.value
      ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone).add(1, 'days').format('YYYY-MM-DD')
      : momenttz.tz(timezone).add(1, 'days').format('YYYY-MM-DD')
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  closePicker () {
    this.setState({ isOpen: false })
  }

  togglePicker () {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getDayClassNames (date) {
    let { timezone, form } = this.props
    form = form || { value: '' }
    const { value } = form
    const classNames = []
    if (momenttz.tz(timezone).format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) classNames.push('react-datepicker__day-current')
    if (momenttz.tz(value, timezone).format('DD-MM-YYYY') === date.format('DD-MM-YYYY')) classNames.push('react-datepicker__day-selected')
    return classNames.join(' ')
  }

  render () {
    const { isOpen } = this.state
    let {
      className,
      name,
      dateFormat,
      timezone,
      form,
      holiday
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-calendar-day-input', 'no-select']
    if (isOpen) classNames.push('open')
    const customInputClassNames = ['ta-calendar-day-input__custom-input']
    if (holiday) customInputClassNames.push('hasHoliday')
    const value = form.value ? moment.tz(form.value, 'YYYY-MM-DD', timezone) : moment.tz(timezone)

    return (
      <div className={className}>
        <div className={classNames.join(' ')}>
          <div onClick={this.onClickPrevWeek} className='ta-calendar-day-input__btn'>
            <FontAwesome5 icon='angle-left' type='solid' />
          </div>
          <HoverPopup disabled={!holiday}>
            <HoverPopupContent position='bottom' autoSize>
              {t(`holidays.${holiday}`)}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <div onClick={this.togglePicker} className={customInputClassNames.join(' ')}>
                <FontAwesome5 icon={holiday ? 'wreath' : 'calendar-alt'} type='solid' />
                <div className='ta-calendar-day-input__week-label'>
                  {format(momenttz.tz(value, timezone), 'longWithShortWeekDay', true)}
                </div>
              </div>
            </HoverPopupTrigger>
          </HoverPopup>
          {isOpen &&
            <OutsideClick onClickOutside={this.togglePicker}>
              <div className='ta-calendar-day-input-wrapper'>
                <DatePicker
                  className='ta-calendar-day-input-datepicker'
                  inline
                  name={name}
                  selected={value}
                  onChange={this.onChange}
                  dateFormat={dateFormat}
                  dayClassName={date => this.getDayClassNames(date)}
                  autoComplete='off'
                />
              </div>
            </OutsideClick>
          }
          <div onClick={this.onClickNextWeek} className='ta-calendar-day-input__btn'>
            <FontAwesome5 icon='angle-right' type='solid' />
          </div>
        </div>
      </div>
    )
  }
}

CalendarDayInput.propTypes = {
  name: PropTypes.string,
  format: PropTypes.string,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  timezone: selectors.timezoneSelector(state)
})

export default feedContextInProps(connect(maps)(CalendarDayInput), FormContext)
