import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Loader } from '../../Common'
import { AppAuth } from '../../Beauties'
import { AccountSelectWrapper, AccountEnforcePasswordChangeWrapper } from '../../Beasts'

class AppAuthWrapper extends Component {
  constructor (props, context) {
    super(props)
  }

  componentDidMount () {
    let { locale, routerLocale } = this.props
    locale = locale || 'en-gb'
    handlers.languageChange(routerLocale || locale)
  }

  render () {
    let {
      hasCompany,
      pendingLanguage,
      route,
      enforcePasswordChange
    } = this.props
    hasCompany = !!hasCompany
    pendingLanguage = !!pendingLanguage
    enforcePasswordChange = !!enforcePasswordChange
    return (
      <>
        {!hasCompany && !pendingLanguage && route !== 'logout' && !enforcePasswordChange && <AccountSelectWrapper />}
        {!hasCompany && !pendingLanguage && enforcePasswordChange && <AccountEnforcePasswordChangeWrapper />}
        {hasCompany && !pendingLanguage && <AppAuth />}
        {hasCompany && pendingLanguage && <Loader active hideBackground />}
      </>
    )
  }
}

AppAuthWrapper.propTypes = {
  language: PropTypes.string,
  pendingLanguage: PropTypes.bool.isRequired
}

const maps = state => ({
  hasCompany: !!selectors.companyFieldSelector(state, { field: 'id' }),
  locale: selectors.appLocaleSelector(state),
  routerLocale: selectors.routerDataFieldSelector(state, { field: 'locale' }),
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  route: selectors.routerFieldSelector(state, { field: 'name' }),
  enforcePasswordChange: selectors.accountFieldSelector(state, { field: 'enforcePasswordChange' })
})

export default connect(maps)(AppAuthWrapper)
