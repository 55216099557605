import { payloads$, actions, handlers, store, selectors } from '../../../Store'
import { q } from '../../API'
import { t } from '../../../Common'
import { getCssFileLink } from '../../../Utils'
import {
  accountEditFormValidate,
  accountPasswordChangeFormValidate,
  accountUserDataSaveTransform,
  accountSaveUserDataTransform,
  accountEditFormServerErrorsTransform,
  accountPasswordChangeFormServerErrorsTransform,
  accountDeleteFormValidate,
  accountUserEmailChangeFormValidate,
  accountEmailChangeCancelFormValidate,
  accountUserEmailChangeTransform,
  accountUserEmailChangeCancelTransform,
  accountUserEmailChangeFormServerErrorsTransform,
  accountEmailChangeCancelSaveServerErrorsTransform
  // userSettingsTransform
  // accountDeleteFormSaveTransform,
  // accountDeleteFormServerErrorsTransform
} from './utils'
import {
  accountUpdated,
  accountDeleted,
  accountEnterpriseUpdated
} from './subscriptions'
// import moment from 'moment'

// Subscription
payloads$(actions.ACCOUNT_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (name === 'accountUpdated') {
      const account = (await q('getAccount')) || {}
      if (account.error) return
      const { accounts = [] } = account
      accountUpdated({ ...account, accounts })
      handlers.agentsListGet()
    }
    if (name === 'accountDeleted' && id) accountDeleted(id)
    if (name === 'enterpriseUpdated' && id) accountEnterpriseUpdated(id)
    if (name === 'invalidate2faSessions' && id) handlers.accountCompanySessionConfirm(id)
    if (name === 'invalidate2faSession') handlers.accountSessionConfirm({ id, data })
  })

payloads$(actions.ACCOUNT_COMPANY_SESSION_CONFIRM)
  .subscribe(({ id }) => {
    const state = store.getState()
    const { account, company } = state || {}
    const { id: companyId } = company || {}
    const { jwtAuth2FAs } = account || {}

    if (id !== companyId) return
    if (jwtAuth2FAs && jwtAuth2FAs.length && jwtAuth2FAs[0] && jwtAuth2FAs[0].isAuth) return

    handlers.logout()
  })

payloads$(actions.ACCOUNT_SESSION_CONFIRM)
  .subscribe(({ id, data }) => {
    const state = store.getState()
    const { account, twoFA, company } = state || {}
    const { jwtAuth2FAs, userId } = account || {}
    const { pending2FASetup } = twoFA || {}
    const { isEnabled } = data || {}
    const { settings } = company || {}
    const { requiredAuth2FAs } = settings || {}
    const isJwtSet = jwtAuth2FAs && jwtAuth2FAs.length && jwtAuth2FAs[0] && jwtAuth2FAs[0].isAuth
    const isCompanyRequires2FA = requiredAuth2FAs === 'OTP'
    const isCurrentUser = !!isJwtSet === isEnabled

    if (isCompanyRequires2FA && isCurrentUser && !isEnabled) return handlers.logout()
    if (!isCurrentUser) return handlers.logout()
    if (id !== userId || pending2FASetup) return
    if (isJwtSet) return

    handlers.logout()
  })

// Account select
payloads$(actions.ACCOUNT_SELECT)
  .subscribe(async accountId => {
    const redirectUrl = `/?enterprise=${accountId}`
    const tokens = await q('selectAccount', { accountId })
    const { error } = tokens
    // TODO: handle the error correct
    if (error && error.code === 'Auth2FACodeRequired') {
      handlers.accountPendingSet(false)
      handlers.accountSecurityErrorToShow([{ key: 'globalErrors', value: 'errors.2fa.auth2FARequired' }])
      return handlers.navigateToPath('/user@@userSecurity')
    }
    if (error) return error

    // Reset data
    handlers.branchesReset()
    handlers.accountReset()
    handlers.companyReset()
    handlers.usersReset()
    handlers.settingsReset()
    handlers.overlayHide()

    handlers.authTokensChange(tokens)
    handlers.navigateToPath(redirectUrl)
  })

// Account changed
payloads$(actions.ACCOUNT_CHANGED)
  .subscribe(async ({ account, showTwoFA }) => {
    const state = store.getState()
    const query = selectors.routerFieldSelector(state, { field: 'query' })
    const { redirect, locale } = query || {}
    const params = []
    if (redirect && !redirect.includes('logout')) params.push(`redirect=${redirect}`)
    if (locale) params.push(`locale=${locale}`)
    const { enterprise, accounts = [] } = account || {}
    handlers.accountPopulate({ ...account, accounts })
    handlers.branchesListGet({ account, forceFetch: true })
    if (!showTwoFA && enterprise) handlers.companyPopulate(enterprise)
    state.router.name === 'login' && handlers.navigateToPath('/')
  })

// Form
payloads$(actions.ACCOUNT_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    const locale = selectors.appLocaleSelector(state)
    handlers.accountFormPopulate(locale)
  })

// Change locale save
payloads$(actions.ACCOUNT_FORM_EDIT_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountEditFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const userData = accountUserDataSaveTransform(account)
    const savedAccount = await q('saveUserData', { userData })
    const { error } = savedAccount || { error: {} }
    if (error) return setAccountFormSaveErrors(accountEditFormServerErrorsTransform(error), scrollToError)
    handlers.languageChange(userData.locale)
    handlers.navigateToPath('/user')
  })

// Change password save
payloads$(actions.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const state = store.getState()
    let enforcePasswordChange = selectors.accountFieldSelector(state, { field: 'enforcePasswordChange' })
    enforcePasswordChange = !!enforcePasswordChange
    const errors = accountPasswordChangeFormValidate(account, enforcePasswordChange)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('saveUserData', { userData: accountSaveUserDataTransform(account) })
    const { error } = savedAccount || { error: null }
    if (error) return setAccountFormSaveErrors(accountPasswordChangeFormServerErrorsTransform(error), scrollToError)
    if (!enforcePasswordChange) return handlers.navigateToPath('/user')
    handlers.accountFormReady()
    handlers.accountPreviewMessageChange({ text: t('account.password.enfroceReset.success.message'), type: 'success' })
    setTimeout(() => {
      handlers.accountFormPasswordChangeRedirect()
    }, 3000)
  })

payloads$(actions.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT)
  .subscribe(() => {
    const state = store.getState()
    const email = selectors.accountFieldSelector(state, { field: 'email' })
    const password = selectors.formFieldPropertySelector(state, { formName: 'account', name: 'newPassword', property: 'value' })
    // if user is using temporary password, log him in
    handlers.loginWithEmailAndPassword({ email: { value: email }, password: { value: password } })
  })

// Change email save
payloads$(actions.ACCOUNT_FORM_EMAIL_CHANGE_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountUserEmailChangeFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('changeEnterpriseCallCentreUserEmail', accountUserEmailChangeTransform(account))
    const { error } = savedAccount || { error: {} }
    if (error) return setAccountFormSaveErrors(accountUserEmailChangeFormServerErrorsTransform(error), scrollToError)
    const { email } = account || {}
    const { value: emailValue } = email || {}
    handlers.accountPendingEmailPopulate(emailValue)
    handlers.navigateToPath('/user')
  })

// Change email cancel save
payloads$(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE)
  .subscribe(async ({ account, scrollToError }) => {
    const errors = accountEmailChangeCancelFormValidate(account)
    if (errors.length) return setAccountFormSaveErrors(errors, scrollToError)
    const savedAccount = await q('cancelChangeEnterpriseCallCentreUserEmail', accountUserEmailChangeCancelTransform(account))
    const { error } = savedAccount || { error: {} }
    if (error && error.code !== 'InvalidCancelRequest') return setAccountFormSaveErrors(accountEmailChangeCancelSaveServerErrorsTransform(error), scrollToError)
    handlers.accountFormEmailChangeCancelInvalidRequest()
    handlers.navigateToPath('/user')
    handlers.popupHide('email-change-cancel')
    setTimeout(() => { handlers.popupSet() }, 500)
  })

const setAccountFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('account', errors)
  scrollToError && scrollToError(errors)
  handlers.accountFormReady()
}

// Resend confirmation email
payloads$(actions.ACCOUNT_EMAIL_CONFIRMATION_SEND)
  .subscribe(async ({ accountId, email }) => {
    await q('sendEmailConfirmationCode', { accountId, email })
    // TODO: display some message or something
  })

// Delete
payloads$(actions.ACCOUNT_DELETE_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = accountDeleteFormValidate(form)
    if (errors.length) return accountUserDeleteFormSaveErrors(errors, scrollToError)
    // const { error } = await q('deleteAccount', accountDeleteFormSaveTransform(form))
    // if (error) return accountUserDeleteFormSaveErrors(accountDeleteFormServerErrorsTransform(error), scrollToError)
    handlers.popupSet()
    handlers.logout()
  })

const accountUserDeleteFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('popup', errors)
  scrollToError && scrollToError(errors)
  handlers.accountDeleteFormReady()
}

// Delete
payloads$(actions.ACCOUNT_UNLINK)
  .subscribe(async ({ type, id }) => {
    if (!id) return
    if (type === 'ENTERPRISE') await q('unlinkConnectedAccountToEnterprise', { enterpriseId: id })
    if (type === 'COMPANY') await q('unlinkConnectedAccountToCompanyResource', { companyId: id })
    handlers.accountDeleted(id)
  })

// Customisations
payloads$(actions.ACCOUNT_CUSTOMISATIONS_APPLY)
  .subscribe(() => {
    const { company, enterprise } = store.getState()
    const { customisations: companyCustomisations } = company || {}
    const { customisations: enterpriseCustomisations } = enterprise || {}
    const customisations = companyCustomisations || enterpriseCustomisations || {}
    const { settings } = customisations || {}
    const { cssOverwriteUrl } = settings || {}
    const cssUrlLink = getCssFileLink(cssOverwriteUrl)
    cssOverwriteUrl && document.head.appendChild(cssUrlLink)
  })

// Security form
payloads$(actions.ACCOUNT_SECURITY_FORM_GET)
  .subscribe(async () => {
    const { account = {} } = store.getState()

    handlers.accountSecurityFormPopulate({ ...account }, account.errorToShow)
  })

payloads$(actions.ACCOUNT_TWO_FA_DISABLE)
  .subscribe(async ({ withLogout, code, cb }) => {
    handlers.twoFAPendingFormSet(true)
    const tokens = await q('disable2FA', { type: 'OTP', code })

    handlers.twoFAPendingFormSet(false)
    const { error } = tokens
    if (error && error.code === 'OTP2FATokenVerificationFailed') {
      handlers.formErrorsSet('twoFA', [{ key: 'code', value: 'errors.twoFA.invalidToken' }])
      return
    }

    handlers.account2FADisabled()
    handlers.accountSecurityFormGet()
    if (cb) cb()
    // We don't need to invalidate session in this request
    if (withLogout) return handlers.logout(null, null, true)

    const { accessToken, refreshToken, sessionDuration } = tokens
    handlers.authTokensPopulate({
      accessToken,
      refreshToken,
      expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
    })
  })
