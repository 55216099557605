export default (loggedInUser, agent, userId) => {
  agent = agent || {}
  const { isCustom: isLoggedInUserCustom, isOwner: isLoggedInUserOwner, usersWrite, usersDelete } = loggedInUser
  const { isOwner: isAgentOwner } = agent

  const isSelectedAgentCurrUser = agent.id === userId
  const canEditDefaultRule = (!isLoggedInUserCustom || (isLoggedInUserCustom && usersWrite)) && !isAgentOwner
  const canDeleteDefaultRule = (!isLoggedInUserCustom || (isLoggedInUserCustom && usersDelete)) && !isAgentOwner

  return {
    showEditButton: (isLoggedInUserOwner && isAgentOwner) || canEditDefaultRule,
    showDeleteButton: !isSelectedAgentCurrUser && !isAgentOwner && canDeleteDefaultRule
  }
}
