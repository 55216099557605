import React, { memo } from 'react'
import { connect, selectors } from '../../Store'
import { BookingForm } from '../../Beauties'

const BookingFormWrapper = props => {
  let {
    pendingContainer,
    form,
    services
  } = props
  form = form || {}
  services = services || []
  const { service: formService, transformedService } = form
  const { selectedId: selectedServiceId } = formService || {}
  const { value: transformedServiceValue } = transformedService || {}

  const selectedService = selectedServiceId ? services.find(item => item.id === selectedServiceId) : null

  return (
    <BookingForm
      pendingContainer={pendingContainer}
      selectedService={transformedServiceValue.id ? transformedServiceValue : selectedService}
    />
  )
}

const maps = state => ({
  form: selectors.formSelector(state, { formName: 'booking' }),
  services: state.services.list,
  pendingContainer: state.bookings.pendingFormContainer
})

export default memo(connect(maps)(BookingFormWrapper))
