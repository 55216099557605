import React from 'react'
import PropTypes from 'prop-types'
import { Link, FontAwesome5, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupCustomerRemove = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__customers-remove'>
      <div className='ta-popup__title'>
        <FontAwesome5 icon='trash' type='solid' />{dangerousHTML(t('popup.customers.remove.label'))}
      </div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('global.no')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={approve}>{t('popup.account.unlink.confirm')}</Link>
      </div>
    </div>
  )
}

PopupCustomerRemove.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupCustomerRemove
