import React from 'react'
import { CustomerPreviewBookingsWrapper } from '../../Beasts'
import { BookingFormResourceAvatars } from '../../Beauties'
import {
  SimpleListItem,
  SimpleListItemContent,
  FontAwesome5,
  SimpleListItemButtons,
  Avatar,
  Row,
  Col,
  ListButton,
  t,
  Spinner,
  Alert,
  format
} from '../../Common'
import { highlight, dangerousHTML } from '../../Utils'
import momenttz from 'moment-timezone'

import './Customers.css'

const CustomersListItem = props => {
  let {
    customer,
    search,
    noListButtons,
    stacked,
    customers,
    noHover,
    onSelectCustomer,
    onPreviewCustomer,
    pendingBranches,
    isScheduleForm,
    transformedCustomer,
    selectedBookings,
    branches,
    services,
    bookingBranch,
    displayBookedResources
  } = props
  branches = branches || []
  customer = customer || {}
  services = services || []
  selectedBookings = selectedBookings || []
  onSelectCustomer = onSelectCustomer || (() => { })
  onPreviewCustomer = onPreviewCustomer || (() => { })
  const { selected } = customers || {}
  const { upcomingEvents, pastEvents } = selected || {}
  const { id: selectedCustomerId } = selected || {}
  const isACustomerSelected = upcomingEvents && pastEvents && selectedCustomerId === customer.id
  const angleIcon = isACustomerSelected ? 'angle-up' : 'angle-down'
  const classNames = ['ta-customers__customer']
  if (stacked) classNames.push('stack-list')
  if (customer.isDeleted) classNames.push('deleted')
  if (customer.isUpdated) classNames.push('updated')
  if (noHover || isACustomerSelected) classNames.push('no-hover')
  if (isACustomerSelected) classNames.push('opened')
  const customerName = customer
    ? (customer.fullName && customer.companyName)
        ? `${customer.fullName}, ${customer.companyName}`
        : customer.fullName || customer.companyName
    : null
  const isServiceOnlineBookable = !!services.length
  const listButtonClassNames = ['ta-btn-primary']
  if (!isServiceOnlineBookable) {
    listButtonClassNames.push('ta-btn-disabled')
  }

  return (
    <>
      <SimpleListItem
        className={classNames.join(' ')}
        key={customer.id}
      >
        <div className='ta-customers__customer__body'>
          <SimpleListItemContent>
            <Row>
              <Col size={10}>
                <div className='ta-customers-list-item__avatar'>
                  <Avatar
                    name={customer.fullName}
                    image={customer.avatarUrl}
                    color={customer.color}
                    abbreviation={customer.abbreviation}
                  />
                </div>
              </Col>
              <Col size={90}>
                <ul>
                  <li>
                    {customerName}
                  </li>
                  <li>
                    {customer.phone
                      ? (
                        <span className='ta-customers-list-item__info_item'>
                          <FontAwesome5 type='solid' icon='phone' />
                          {highlight(customer.phone ? customer.phone.phone : '', search)}
                        </span>
                        )
                      : null}
                    {customer.email
                      ? (
                        <span className='ta-customers-list-item__info_item'>
                          <FontAwesome5 type='solid' icon='envelope' />
                          {highlight(customer.email, search)}
                        </span>
                        )
                      : null}
                  </li>
                </ul>
                {!noListButtons && (
                  <SimpleListItemButtons>
                    <ListButton
                      icon={angleIcon}
                      onClick={() => onPreviewCustomer(customer, isACustomerSelected)}
                      hoverText={t('bookings.customerSelect.popup.content')}
                    />
                    {isScheduleForm && (
                      <ListButton
                        icon='calendar-plus'
                        disabled={!isServiceOnlineBookable}
                        className={listButtonClassNames.join(' ')}
                        onClick={() => onSelectCustomer(customer)}
                        hoverText={isServiceOnlineBookable ? t('bookings.scheduleAppointment.popup.content') : t('bookings.scheduleAppointment.popup.notAvailable.content')}
                      />
                    )}
                  </SimpleListItemButtons>
                )}
              </Col>
            </Row>
            {selectedBookings?.length > 0 && <div className='ta-customers-list-item__selected-booking__header'>{t('rescheduleBooking.customer.selectedBooking')}</div>}
            {selectedBookings?.length > 0 && selectedBookings?.map((item, index) => {
              const { name, companyId, bookingDate: date, bookingTime: time, duration, resources, resourceCategories } = item || {}
              const selectedBranch = branches.find(({ id }) => id === companyId) || branches.find(({ id }) => id === bookingBranch) || {}
              const timezone = selectedBranch.timezone
              const branchName = selectedBranch.name
              return (
                <Row key={`${name}-${index}`} className='ta-customers-list-item__selected-booking__row'>
                  <Col>
                    <div className='ta-customers-list-item__selected-booking__container'>
                      <div className='ta-customers-list-item__selected-booking__left'>
                        <div className='ta-customers-list-item__selected-booking__date'>{format(date, 'DD.MM.YY', { timezone })}</div>
                        <div className='ta-customers-list-item__selected-booking__time'>{momenttz.utc(`${date} ${time}`, 'YYYY-MM-DD HH:mm')?.tz(timezone)?.format('HH:mm')}</div>
                      </div>
                      <div className='ta-customers-list-item__selected-booking__right'>
                        <div className='ta-customers-list-item__selected-booking__name'>{name}</div>
                        {branchName && (
                          <div className='ta-customers-list-item__selected-booking__branch-name'>{branchName}</div>
                        )}
                        <div className='ta-customers-list-item__selected-booking__info-container'>
                          <div className='ta-customers-list-item__selected-booking__duration'>
                            <FontAwesome5 type='s' icon='clock' /> {dangerousHTML(t('global.min', [{ key: 'MIN', value: duration }]))}
                          </div>
                          {!!displayBookedResources && (
                            <div className='ta-customers-list-item__selected-booking__resources'>
                              <FontAwesome5 type='s' icon='user-friends' />
                              <BookingFormResourceAvatars
                                resources={resources}
                                resourceCategories={resourceCategories}
                                listPopupPosition='bottom'
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )
            })}
            {transformedCustomer && transformedCustomer.isUpdatedLocally && (
              <Alert theme='alert'>{t('booking.form.customer.message.isUpdatedLocally')}</Alert>
            )}
            {transformedCustomer === null && (
              <Alert theme='alert'>{t('booking.form.customer.message.notFound')}</Alert>
            )}
          </SimpleListItemContent>
        </div>
      </SimpleListItem>
      {isACustomerSelected && pendingBranches && (
        <div className='ta-customers-list-item__spinner-container'>
          <Spinner />
        </div>
      )}
      {isACustomerSelected && !pendingBranches && (
        <CustomerPreviewBookingsWrapper
          isScheduleForm={isScheduleForm}
          onSelectCustomer={onSelectCustomer}
          customer={customer}
        />
      )}
    </>
  )
}

export default CustomersListItem
