import { handlers, store, selectors } from '../../../Store'

export const companyUpdated = company => {
  if (!company) return
  const {
    hasStripeConnected,
    stripeEmail,
    stripeExtraFeePercentage,
    phone,
    billingData,
    plan
  } = company
  const state = store.getState()
  const { plan: oldPlan } = selectors.companySelector(state) || {}
  if (oldPlan !== plan) {
    handlers.subscriptionPlanChanged()
    return
  }
  const { name, address1, zipCode, city } = billingData || {}
  const hasBillingDetails = name && address1 && zipCode && city
  // TODO: @yanko optimize with ts
  handlers.companyPopulate({ ...company })
  const error = selectors.routerDataFieldSelector(state, { field: 'error' })
  !error && handlers.onlinePaymentMethodsPopulate({
    hasStripeConnected,
    stripeEmail,
    stripeExtraFeePercentage,
    hasBillingDetails,
    hasPhoneNumber: !!(phone && phone.phone)
  })
}

export const companyPublicHolidaysDeleted = idsOfDeletedPhGroup => {
  idsOfDeletedPhGroup = idsOfDeletedPhGroup || []
  const state = store.getState()
  let { company } = state
  let { settings, rawSettings } = company || {}
  settings = settings || {}
  rawSettings = rawSettings || {}
  let { publicHolidays: rawPublicHolidays } = rawSettings || {}
  let { publicHolidays } = settings || {}
  publicHolidays = publicHolidays || []
  // if not set to inherit
  if (rawPublicHolidays !== null) {
    rawPublicHolidays = rawPublicHolidays || []
    rawPublicHolidays = rawPublicHolidays.filter(phGroup => !idsOfDeletedPhGroup.includes(phGroup.id))
  }
  // remove ph group that has only this resource category assigned to it
  publicHolidays = publicHolidays.filter(phGroup => !idsOfDeletedPhGroup.includes(phGroup.id))
  const companySettings = { ...settings, publicHolidays }
  const rawCompanySettings = { ...rawSettings, publicHolidays: rawPublicHolidays }
  handlers.companySettingsUpdate({ rawSettings: rawCompanySettings, settings: companySettings })
}
