import * as formSelectors from '../Forms/selectors'
import * as routerSelectors from '../Router/selectors'
import * as companySelectors from '../Components/Company/selectors'
import * as staticDataSelectors from '../Components/StaticData/selectors'
import * as accountSelectors from '../Components/Account/selectors'
import * as navigationSelectors from '../Components/Navigation/selectors'
import * as appSelectors from '../Components/App/selectors'
import * as agentsSelectors from '../Components/Agents/selectors'
import * as customerFieldsSelectors from '../Components/CustomerFields/selectors'
import * as customers from '../Components/Customers/selectors'
import * as bookings from '../Components/Bookings/selectors'
import * as services from '../Components/Services/selectors'
import * as tagsSelectors from '../Components/Tags/selectors'

export default {
  ...accountSelectors,
  ...appSelectors,
  ...services,
  ...bookings,
  ...customers,
  ...formSelectors,
  ...companySelectors,
  ...staticDataSelectors,
  ...routerSelectors,
  ...navigationSelectors,
  ...agentsSelectors,
  ...customerFieldsSelectors,
  ...tagsSelectors
}
