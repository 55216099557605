// import moment from 'moment'
import momenttz from 'moment-timezone'
import { translateServerCode, validator, uniqueIdFilter } from '../../../Utils'
import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'

// // LIST

export const bookingsIntervalsListTransform = intervals => {
  const result = []
  intervals = intervals || []
  intervals.forEach(interval => {
    const resultItem = {
      ...interval,
      resourceId: interval.booking.calendarId,
      eventId: interval.booking.eventId,
      from: interval.beginTime,
      until: interval.endTime
    }
    delete resultItem.booking
    delete resultItem.beginTime
    delete resultItem.endTime
    result.push(resultItem)
  })
  return result
}

export const bookingsEventsListTransform = (events) => {
  const result = []
  events = (events || []).filter(uniqueIdFilter)
  events.forEach(event => {
    event.intervals = event.intervals || []
    const resultItem = {
      ...event,
      combinationId: event.combination ? event.combination.eventId : null,
      durationAfter: event.durationAfter || 0,
      resourceIds: event.resourceIds || 0,
      from: event.intervals.length > 0 && event.intervals[0].interval.begin,
      until: event.intervals.length > 0 && event.intervals[event.intervals.length - 1].interval.end,
      isBatch: (event.durationsPattern || []).length > 1,
      isBookedOnline: !!event.isBookedOnline,
      tagIds: event.tagIds,
      intervals: event.intervals.map(item => ({
        from: item.interval.begin,
        until: item.interval.end,
        batchNumber: item.batchNumber,
        recurringDay: item.recurringDay
      }))
    }
    result.push(resultItem)
  })
  return result
}

// Form
export const prepareBookingFormDependencies = ({
  availableResources,
  availableResourcesCategories,
  selectedResourceIds,
  service = null,
  isEditMode = false,
  initialLoad = false
}) => {
  availableResources = availableResources || []
  availableResourcesCategories = availableResourcesCategories || []
  selectedResourceIds = selectedResourceIds || []
  const result = {
    selectedResourceIds,
    availableResources,
    availableResourcesCategories,
    service
  }
  if (service) {
    let { dependencies } = service
    dependencies = dependencies || []
    let resourcesInParallel = []
    const serviceDependencies = dependencies.reduce((acc, dependency) => {
      const dependencyCategories = dependency.specificResourceCategoryIds
      const dependencyResourcesCategoriesIds = [...new Set(
        (dependency.specificResourceIds && dependency.specificResourceIds.length > 0
          ? availableResources.filter(item => dependency.specificResourceIds.includes(item.id))
          : availableResources
        ).map(item => item.categoryId)
      )]
      const dependencyResourceIds = dependency.specificResourceIds
        ? dependency.specificResourceIds.length === 0
          ? availableResources.map(item => item.id)
          : dependency.specificResourceIds
        : availableResources
          .filter(item => dependencyCategories.includes(item.categoryId))
          .map(item => item.id)
      if (dependency.workInParallel) resourcesInParallel = resourcesInParallel.concat(dependencyResourceIds)
      acc.resourceIds = [...new Set(acc.resourceIds.concat(dependencyResourceIds))]
      acc.resourceCategoriesIds = [...new Set(acc.resourceCategoriesIds.concat(dependencyResourcesCategoriesIds))]
      return acc
    }, { resourceIds: [], resourceCategoriesIds: [] })

    // if service resources are edited after the booking was made
    if (initialLoad) {
      result.selectedResourceIds.forEach(item => {
        if (!serviceDependencies.resourceIds.includes(item)) {
          serviceDependencies.resourceIds = [...serviceDependencies.resourceIds, item]
          const { categoryId } = availableResources.find(resource => resource.id === item) || {}
          if (!serviceDependencies.resourceCategoriesIds.includes(categoryId)) {
            serviceDependencies.resourceCategoriesIds = [...serviceDependencies.resourceCategoriesIds, categoryId]
          }
        }
      })
    }

    result.availableResources = availableResources.filter(item => serviceDependencies.resourceIds.includes(item.id))
    result.availableResourcesCategories = availableResourcesCategories
      ? availableResourcesCategories.filter(item => serviceDependencies.resourceCategoriesIds.includes(item.id))
      : []
    selectedResourceIds = selectedResourceIds.filter(item => result.availableResources.map(item => item.id).includes(item))
    if (selectedResourceIds.length === 0 && result.availableResources.length === 1) selectedResourceIds = [result.availableResources[0].id]
    result.selectedResourceIds = isEditMode
      ? selectedResourceIds
      : [...new Set([...selectedResourceIds, ...resourcesInParallel])]
  }

  return result
}

export const bookingFormTransform = (booking, settings) => {
  const { type, persist } = booking || {}
  const { allowBookingWithinWorkingTimes } = settings || {}

  const result = {
    bookingCustomer: { value: null },
    progress: { value: 0 },
    from: { value: null },
    service: { value: null },
    dependencies: { values: [] },
    color: { value: DEFAULT_SERVICE_HEXCOLOR },
    notes: { value: '' },
    splitDurationInIntervals: { value: null },
    intervals: { values: [0, 0, 0] },
    duration: { value: '' },
    durationBefore: { value: 0 },
    durationAfter: { value: 0 },
    price: { value: null },
    recurring: { value: null },
    tags: { values: [] },
    hasNotifyCustomers: {
      value: settings?.hasCCNotifyOnCustomers === 'SELECTED'
        ? persist?.hasNotifyCustomersBooking || false
        : settings?.hasCCNotifyOnCustomers === 'TRUE'
    },
    hasNotifyResources: {
      value: settings?.hasCCNotifyOnResources === 'SELECTED'
        ? persist?.hasNotifyResourcesBooking || false
        : settings?.hasCCNotifyOnResources === 'TRUE'
    },
    accordionRefs: { value: {} },
    search: { value: '' },
    bookingBranch: { value: null, options: [], allOptions: [] },
    allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
    customerAddressRadius: { value: '5000' },
    useExactMatchSearch: {
      value: 'exact'
    }
  }
  if (['reschedule', 'cancel'].includes(type)) {
    result.password = { value: '', type: 'password' }
    result.hasNotifyCustomers = { value: true, disabled: true }
    result.hasNotifyResources = { value: true, disabled: true }
  }

  return result
}

export const bookingFormValidate = (booking, customerFields) => {
  if (!booking) return
  const { transformedService } = booking || {}
  const { value: transformedServiceValue } = transformedService || {}
  const { rescheduledEventId } = transformedServiceValue || {}
  const rules = [{
    'password.value': [`requiredIf:${!!rescheduledEventId}`]
  }]
  const messages = {
    password: {
      requiredIf: 'errors.password.required'
    }
  }
  const replaces = {}

  // Data fields
  let fieldErrors = []
  let fields = Object.keys(booking).filter(key => key.includes('customerFields'))
  const serviceId = booking?.transformedService?.id

  if (serviceId) {
    fields = fields.filter(name => {
      const fieldId = name.replace('customerFields', '')
      const field = (customerFields && customerFields.length > 0 && customerFields.find(item => item.id === fieldId)) || {}
      return field.specificServiceIds && (field.specificServiceIds.includes(serviceId) || field.specificServiceIds.length === 0)
    })
  }
  fields.forEach(name => {
    const combinedField = (customerFields && customerFields.length > 0 && customerFields.find(item => `customerFields${item.id}` === name)) || {}
    const label = combinedField.label
    const field = booking[name]
    const type = field.type && field.type
    const value = field.value && field.value
    const isMandatory = !!field.isMandatory
    const translation = field.translationKey || label
    const translateReplace = !!field.translationKey
    const defaultId = field.defaultId

    switch (type) {
      case 'FILE':
        if (defaultId && defaultId.toLowerCase() === 'avatar') {
          if (isMandatory) {
            const isAvatarSet = !!(field.avatarUrl && field.avatarUrl.value && field.avatarUrl.value !== '')
            rules.push({ [`${name}.avatarFile`]: [`requiredIf:${!isAvatarSet}`] })
          }
          messages[name] = {
            requiredIf: 'errors.required'
          }
          replaces[name] = {
            requiredIf: { key: 'FIELD_NAME', value: translation, translateReplace }
          }
        }
        break

      case 'TEXT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'TEXTAREA':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:3000'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 3000 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'SELECT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'DATE':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'CHECKBOX':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:64'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'EMAIL':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['email', 'max:255'] })
        messages[name] = {
          email: 'errors.email.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'PHONE':
        if (isMandatory) rules.push({ [`${name}.phone`]: ['required'] })
        rules.push({ [`${name}.phone`]: ['phone', 'max:64'] })
        messages[name] = {
          phone: 'errors.phone.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'ADDRESS':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required', 'max:800'] })
        const isAddressSet = value && value !== ''
        if (isAddressSet) {
          rules.push({ [`${name}.data.city`]: [`requiredIf:${isAddressSet}`] })
          rules.push({ [`${name}.data.streetName`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.streetNumber`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.postalCode`]: [`requiredIf:${isAddressSet}`] })
        }
        let addressErrorMessage = 'errors.address.required'
        if (isAddressSet && field && field.data) {
          if (!field.data.city || field.data.city === '') {
            addressErrorMessage = 'errors.address.fillCity'
          } else if (!field.data.streetName || field.data.streetName === '') {
            addressErrorMessage = 'errors.address.fillStreetName'
            // } else if (!field.data.streetNumber || field.data.streetNumber === '') {
            //   addressErrorMessage = 'errors.address.fillStreetNumber'
            // else if (!field.data.postalCode || field.data.postalCode === '') {
            //   addressErrorMessage = 'errors.address.fillZipCode'
            // }
          }
        }
        messages[name] = {
          requiredIf: addressErrorMessage,
          max: 'errors.invalidMaxLength'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 800 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'FILES':
        if (isMandatory) rules.push({ [`${name}.values`]: [`requiredIf:${(field.selected || []).length === 0}`] })
        messages[name] = {
          requiredIf: 'errors.required'
        }
        replaces[name] = {
          requiredIf: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        fieldErrors = [...fieldErrors, ...(field.errors || [])]
        break

      default:
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'invalidMaxLength',
          required: 'errors.required'
        }
        break
    }
  })

  let errors = validator(booking, rules, messages, replaces)
  if (fieldErrors.length > 0) {
    fieldErrors = fieldErrors.filter(item => !(errors || []).find(error => error.key === item.key)) // Remove duplicated errors
    errors = [...errors, ...fieldErrors]
  }

  return errors.length && errors
}

export const bookingCancelFormValidate = (booking) => {
  if (!booking) return
  const rules = [{
    'password.value': ['required']
  }]
  const messages = {
    password: {
      required: 'errors.password.required'
    }
  }
  const replaces = {}

  const errors = validator(booking, rules, messages, replaces)

  return errors.length && errors
}

export const bookingFormServerErrorsTransform = (error, resources = [], stripeMinPrice, locale, currency) => {
  const errors = []

  switch (error.code) {
    case 'BookingAllowanceConflict':
    case 'BookingOverlap':
      errors.push({
        key: 'globalErrors',
        value: translateServerCode(error.code),
        replace: [{ key: 'RESOURCES', value: error.value }]
      })
      break
    case 'CombinationConflicts':
      const conflictCombination = (error.data || {}).combination || {}
      conflictCombination.forEach(item => {
        errors.push({
          key: `dependencies${item.eventId}`,
          value: translateServerCode(error.code),
          replace: [{ key: 'RESOURCES', value: item.value }]
        })
        errors.push({
          key: `dependencies${item.serviceId}`,
          value: translateServerCode(error.code),
          replace: [{ key: 'RESOURCES', value: item.value }]
        })
      })
      break
    case 'RecurringConflicts':
      errors.push({
        key: 'dependencies',
        value: translateServerCode(error.code),
        replace: [{ key: 'RESOURCES', value: error.value }]
      })
      break
    case 'ResourcesDontMatchShape':
    case 'DependencyNotAvailable':
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
      break
    case 'BookingDurationLimitReached':
      errors.push({ key: 'duration', value: translateServerCode(error.code) })
      break
    case 'MinAmountStripe':
      const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
      errors.push({
        key: 'price',
        value: 'errors.services.stripeMinPrice',
        replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
      })
      break
    default:
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
      break
  }
  return errors
}

export const bookingSaveTransform = ({
  booking,
  services,
  customerFields,
  customisations,
  isRescheduleForm,
  isResourceSelectorEnabled
}) => {
  booking = booking || {}
  services = services || []
  customerFields = customerFields || []
  customisations = customisations || {}
  const {
    id,
    from,
    dependencies,
    service,
    color,
    notes,
    duration,
    durationBefore,
    durationAfter,
    // tags,
    splitDurationInIntervals,
    intervals,
    hasNotifyCustomers,
    hasNotifyResources,
    slots,
    timezone,
    plan,
    type,
    customersCount,
    combinationId,
    combination,
    transformedCustomer,
    transformedService,
    password
  } = booking || {}
  let { errors } = dependencies || {}
  errors = errors || []
  const skipConflictsOnChained = errors.findIndex(item => item.value === 'errors.booking.form.recurringConflicts') > -1
  const serviceId = service.selectedId || null
  const selectedService = services.find(item => item.id === serviceId)
  let isCombination = false
  let combinationServiceIds = []
  const transformedServiceValue = transformedService && transformedService.value ? transformedService.value : {}
  if (transformedServiceValue.isCombination) {
    combinationServiceIds = transformedServiceValue?.combinationServiceIds || []
    isCombination = transformedServiceValue?.isCombination
  } else {
    combinationServiceIds = selectedService?.combinationServiceIds || []
    isCombination = selectedService?.isCombination
  }
  const resourceIds = !isCombination
    ? dependencies.values
    : Object.entries(booking)
      .filter(([key]) => key.includes('dependencies'))
      .flatMap(([key, value]) => value.values || [])

  const transformedServiceId = transformedServiceValue.id
  const result = {
    startDate: from.value ? momenttz.utc(momenttz.tz(from.value, 'YYYY-MM-DD HH:mm', timezone)).format('YYYY-MM-DD HH:mm') : null,
    // title: service.value,
    serviceId: transformedServiceId,
    resourceIds,
    color: color.value,
    notes: notes.value,
    durationsPattern: splitDurationInIntervals.value ? intervals.values : [duration.value || transformedServiceValue.duration || null],
    durationBefore: durationBefore.value || transformedServiceValue.durationBefore || 0,
    durationAfter: durationAfter.value || transformedServiceValue.durationAfter || 0,
    recurring: null,
    // tagIds: tags.values || null,
    hasNotifyCustomers: hasNotifyCustomers.value || false,
    hasNotifyResources: hasNotifyResources.value || false
  }
  const { settings } = customisations || {}
  const { hideBookingFormNotifyResource, hideBookingFormNotifyCustomer } = settings || {}
  if (hideBookingFormNotifyResource) result.hasNotifyResources = false
  if (hideBookingFormNotifyCustomer) result.hasNotifyCustomers = false
  if (transformedServiceValue.rescheduledEventId) result.id = transformedServiceValue.rescheduledEventId
  if (plan !== 'ENTERPRISE') delete result.tagIds
  result.customerId = transformedCustomer && transformedCustomer.value && transformedCustomer.value.id
  // Single booking
  if (['singleBooking', 'widgetBooking'].includes(type)) {
    const formattedFields = {}
    let fields = Object.keys(booking).filter(item => item.includes('customerFields'))
    if (serviceId && result.customerId) {
      fields = fields.filter(name => {
        const fieldId = name
          .replace('customerFields', '')
          .replace('Secondary', '')
        const filed = (customerFields && customerFields.length > 0 && customerFields.find(item => item.id === fieldId)) || {}
        return filed.specificServiceIds && (filed.specificServiceIds.includes(transformedServiceId) || filed.specificServiceIds.length === 0)
      })
    }
    fields.forEach(item => {
      const id = item.replace('customerFields', '')
      const field = booking[item] || {}
      field.type = (customerFields.find(({ id: customerFieldId }) => id.replace('Secondary', '') === customerFieldId) || {}).type
      if (field.type === 'DATE' && field.value) {
        formattedFields[id] = momenttz.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
      }
      if (field.type === 'PHONE' && field.phone) {
        formattedFields[id] = JSON.stringify({
          number: field.phone,
          country: field.phoneCountry
        })
      }
      if (field.type === 'CHECKBOX') {
        formattedFields[id] = field.value + ''
      }
      if (field.type === 'FILE' && field.defaultId === 'avatar') {
        if (field.value === null) formattedFields[id] = null
        if (field.avatarFile) formattedFields[id] = field.avatarFile
      }
      if (field.type === 'FILES') {
        formattedFields[id] = {
          valuesAdd: (field.valuesAdd || []).map(file => JSON.stringify(file)),
          valuesRemove: field.valuesRemove || []
        }
      }
      if (field.type === 'ADDRESS' && field.value) {
        const value = formattedFields[id] ? JSON.parse(formattedFields[id]) : null
        const data = field.data || null
        const newValue = (data && {
          placeId: data && data.placeId,
          latitude: data && data.lat,
          longitude: data && data.lng,
          street: data && data.streetName,
          streetNumber: data && data.streetNumber,
          city: data && data.city,
          country: data && data.country,
          zipCode: data && data.postalCode,
          formatted: data && data.formattedAddress
        }) || null
        // if secondary address come first, we have to pre-fill it
        if (newValue && value && value.details) newValue.details = value.details
        formattedFields[id] = JSON.stringify(newValue)
      }
      if (id.includes('Secondary')) {
        const addressId = id.replace('Secondary', '')
        const newValue = formattedFields[addressId] ? JSON.parse(formattedFields[addressId]) : null
        if (newValue) {
          newValue.details = field.value
          formattedFields[addressId] = JSON.stringify(newValue)
        }
      }
      if (!formattedFields[id] && field.type !== 'FILE') formattedFields[id] = field.value || null
    })

    result.fields = Object
      .keys(formattedFields)
      .filter(item => item.length === 24)
      .map(id => {
        if (formattedFields[id] && formattedFields[id].valuesAdd) {
          return { id, valuesAdd: formattedFields[id].valuesAdd, valuesRemove: formattedFields[id].valuesRemove }
        }
        return { id, value: formattedFields[id] }
      })

    // Custom permission without access to selected customer
    if (customersCount > 0 && !result.customerId) {
      delete result.customerId
      delete result.fields
    }
  }

  if (id) result.id = id
  delete result.timezone
  const formattedResult = {
    event: result,
    skipConflictsOnChained,
    dontForceElect: isRescheduleForm && !!resourceIds?.length
  }

  if (booking.slots) formattedResult.multipleStartDates = (slots.values || []).map(item => momenttz.utc(momenttz.tz(item, 'YYYY-MM-DD HH:mm', timezone)).format('YYYY-MM-DD HH:mm'))
  if (transformedServiceValue.rescheduledEventId) {
    result.startDate = formattedResult.multipleStartDates[0]
    formattedResult.password = password && password.value
    delete formattedResult.multipleStartDates
  }
  formattedResult.allowanceType = booking.allowanceType.value || 'NONE'

  // Service combination
  if (isCombination) {
    delete result.duration
    delete result.durationsPattern
    delete result.color
    const combinationServices = []
    combinationServiceIds.forEach(item => {
      const duration = booking[`duration${item}`] ? booking[`duration${item}`].value : null
      const resourceIds = (isResourceSelectorEnabled && booking[`dependencies${item}`]) ? booking[`dependencies${item}`].values : null
      combinationServices.push({
        serviceId: item,
        resourceIds,
        duration
      })
    })
    if (booking.isFastBookingWidget && booking.slots) {
      result.startDate = formattedResult.multipleStartDates[0]
      delete formattedResult.multipleStartDates
    }
    formattedResult.event = result
    formattedResult.combinationServices = combinationServices
  }
  if (combinationId && combinationId.value) {
    delete result.duration
    delete result.durationsPattern
    delete result.color
    delete result.recurring
    delete result.resourceIds
    delete result.title
    delete result.serviceId
    const { value: combinationValue } = combination || {}
    const { events } = combinationValue || {}
    const combinationServices = []
    events.forEach(item => {
      const duration = booking[`duration${item.eventId}`] ? booking[`duration${item.eventId}`].value : null
      const resourceIds = (isResourceSelectorEnabled && booking[`dependencies${item}`]) ? booking[`dependencies${item.serviceId}`].values : null
      combinationServices.push({
        eventId: item.eventId,
        resourceIds,
        duration
      })
    })
    result.id = events[0]?.eventId || '---'
    formattedResult.event = result
    formattedResult.combinationServices = combinationServices
  }

  return formattedResult
}

export const bookingWidgetAvailabilityTransform = (booking, services, branches, company, isResourceSelectorEnabled) => {
  booking = booking || {}
  services = services || {}
  let {
    currentWeekDays,
    dependencies,
    duration,
    durationBefore,
    durationAfter,
    splitDurationInIntervals,
    intervals,
    // hasEnforceResourceAllowanceType,
    timeIntervalMorning,
    timeIntervalNoon,
    timeIntervalAfternoon,
    timeIntervalEvening,
    allowanceType,
    transformedServiceId,
    transformedService
  } = booking || {}
  dependencies = dependencies || {}
  durationBefore = durationBefore || {}
  durationAfter = durationAfter || {}
  splitDurationInIntervals = splitDurationInIntervals || {}
  intervals = intervals || {}
  duration = duration || {}
  timeIntervalMorning = timeIntervalMorning || {}
  timeIntervalNoon = timeIntervalNoon || {}
  timeIntervalAfternoon = timeIntervalAfternoon || {}
  timeIntervalEvening = timeIntervalEvening || {}
  allowanceType = allowanceType || {}
  const dependenciesValues = dependencies.values || []
  const serviceId = transformedServiceId || null
  const { value: transformedServiceValue } = transformedService || {}
  let {
    rescheduledEventId,
    rescheduledSecret,
    isCombination,
    combinationServiceIds
  } = transformedServiceValue || {}
  combinationServiceIds ||= []

  const result = {
    options: {
      days: currentWeekDays,
      serviceId,
      selectedResourceIds: dependenciesValues.filter(Boolean),
      durationBefore: durationBefore.value || 0,
      durationAfter: durationAfter.value || 0,
      durationsPattern: splitDurationInIntervals.value
        ? intervals.values
        : [duration.value || null]
    }
  }
  const filterByTimeIntervals = []
  if (timeIntervalMorning.value) filterByTimeIntervals.push({ begin: '04:00', end: '11:55' })
  if (timeIntervalNoon.value) filterByTimeIntervals.push({ begin: '12:00', end: '14:55' })
  if (timeIntervalAfternoon.value) filterByTimeIntervals.push({ begin: '15:00', end: '16:55' })
  if (timeIntervalEvening.value) filterByTimeIntervals.push({ begin: '17:00', end: '23:55' })
  if (filterByTimeIntervals.length > 0) result.options.filterByTimeIntervals = filterByTimeIntervals
  result.options.allowanceType = allowanceType.value || 'NONE'

  // Service combination
  if (isCombination) {
    delete result.options.duration
    delete result.options.durationsPattern
    delete result.options.selectedResourceIds
    const combinationServices = []
    combinationServiceIds.forEach(item => {
      const duration = booking[`duration${item}`] ? booking[`duration${item}`].value : null
      const resourceIds = (isResourceSelectorEnabled && booking[`dependencies${item}`])
        ? booking[`dependencies${item}`].values
        : null
      combinationServices.push({
        serviceId: item,
        resourceIds,
        duration
      })
    })
    result.options.combinationServices = combinationServices
  }

  // TODO: ask should i get this from the logged in user
  // result.options.companyId = '5e73582036a8e51185773672' // TODO: make dynamic
  result.options.companyId = branches.selected.id // TODO: make dynamic
  result.options.region = branches.selected.region || 'EUROPE' // TODO: ask why company is null

  if (rescheduledEventId) {
    result.options.rescheduledEventId = rescheduledEventId
    result.options.rescheduledSecret = rescheduledSecret
  }

  // delete result.options.filterByTimeIntervals
  // delete result.options.allowanceType

  return result
}

// BOOKING CUSTOMER

export const bookingFormCustomerSearchValidate = booking => {
  if (!booking) return []

  const { search, tags } = booking

  let rules = []
  const messages = {
    search: {
      required: 'errors.required'
    },
    tags: {
      required: 'errors.required'
    }
  }
  const replaces = {
    search: {
      required: {
        key: 'FIELD_NAME',
        value: 'customers.fieldSearch.label',
        translateReplace: true
      }
    }
  }

  if (!search.value && !tags.value) {
    rules = [{ 'search.value': ['required'] }]
  }

  const errors = validator(booking, rules, messages, replaces)

  return errors
}

export const bookingFormServicesSaveValidate = ({ services, booking }) => {
  const { service, splitDurationInIntervals } = booking || {}
  if (!booking) return []

  const serviceId = service.selectedId || null
  const selectedService = services.find(item => item.id === serviceId)
  let { isCombination, combinationServiceIds } = selectedService || {}
  combinationServiceIds = combinationServiceIds || []
  const isSplit = splitDurationInIntervals && splitDurationInIntervals.value

  const rules = [
    { 'service.selectedId': ['required'] }
  ]

  if (!isCombination) {
    rules.push({ 'duration.value': [`requiredIf:${!isSplit}`] })
  }

  const messages = {
    service: {
      required: 'errors.required'
    },
    duration: {
      requiredIf: 'errors.required'
    }
  }
  const replaces = {
    service: {
      required: {
        key: 'FIELD_NAME',
        value: 'global.service',
        translateReplace: true
      }
    },
    duration: {
      requiredIf: {
        key: 'FIELD_NAME',
        value: 'global.duration',
        translateReplace: true
      }
    }
  }
  const errors = validator(booking, rules, messages, replaces)

  if (errors.length) return errors
}

export const bookingAvailabilityGetFormServerErrorsTransform = error => {
  const errors = []
  switch (error.code) {
    case 'NoMoreCancelOrReschedule':
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
      break
    default:
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
      break
  }
  return errors
}

export const filterBranches = (id, externalId, allowedBranchIds, allowedBranchExternalIds) => {
  allowedBranchIds = allowedBranchIds || []
  allowedBranchExternalIds = allowedBranchExternalIds || []
  const filterBranches = !!allowedBranchIds.length || !!allowedBranchExternalIds.length
  return filterBranches ? (allowedBranchIds.includes(id) || allowedBranchExternalIds.includes(externalId)) : true
}
