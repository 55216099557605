import React from 'react'
import { handlers, selectors, connect } from '../../../Store'
import { BookingFormServiceAdhoc } from '../../../Beauties'

const BookingServiceAdhocWrapper = ({
  form,
  selectedService,
  pending,
  intervals,
  splitDurationInIntervals,
  isScheduleForm,
  isRescheduleForm,
  serviceId,
  services
}) => {
  services = services || []

  const handleServicesSubmit = async () => {
    handlers.bookingFormServicesSave()
  }
  const isServiceAvailable = services.find(({ id }) => id === serviceId)

  return (
    <BookingFormServiceAdhoc
      hasServiceSelected={!!(selectedService && selectedService.id)}
      intervals={intervals}
      isServiceAvailable={isServiceAvailable}
      splitDurationInIntervals={splitDurationInIntervals}
      handleServicesSubmit={handleServicesSubmit}
      pending={pending}
      selectedService={selectedService}
      services={services}
      isScheduleForm={isScheduleForm}
      isRescheduleForm={isRescheduleForm}
      serviceId={serviceId}
    />
  )
}

const maps = state => ({
  services: state.services.list,
  pending: selectors.servicesFieldSelector(state, { field: 'pendingList' }),
  selectedService: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'value' }),
  serviceId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'selectedId' }),
  intervals: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'intervals', property: 'values' }),
  splitDurationInIntervals: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'splitDurationInIntervals', property: 'value' })
})

export default connect(maps)(BookingServiceAdhocWrapper)
