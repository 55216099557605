import React from 'react'
import PropTypes from 'prop-types'

import './StripedTitle.css'

const StripedTitle = props => {
  const { label, extraText, customStyle } = props

  return (
    <div className='ta-striped-title' style={customStyle} data-testid='striped-title'>
      <div className='ta-striped-title__label' data-testid='striped-title-label'>
        {label}
      </div>
      {extraText &&
        <div className='ta-striped-title__extraText' data-testid='striped-title-extra-text'>
          ({extraText})
        </div>
      }
    </div>
  )
}

StripedTitle.propTypes = {
  label: PropTypes.string.isRequired,
  extraText: PropTypes.string
}

export default StripedTitle
