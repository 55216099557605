import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store } from '../../Store'
import { Loader, t } from '../../Common'
import { CustomerForm } from '../../Beauties'
import { groupCustomerFieldsByCategory } from '../../Utils'

class CustomerAddWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.state = { fileLoading: false }
  }

  componentDidMount () {
    // handlers.customerFormGet({})
  }

  onSubmit (cb) {
    const form = store.getState().forms.customers

    handlers.customerFormSave(form)
  }

  onCancel () {
    handlers.formFieldsUpdate('booking', { isCustomerAdd: { value: false }, isCustomerEdit: { value: false } })
  }

  setFileLoading (value) {
    this.setState({ fileLoading: value })
  }

  render () {
    let {
      pending,
      fields,
      categories,
      showExternalIds,
      timezones,
      allowOnlyGlobalCustomers
    } = this.props
    pending = !!pending
    fields = fields || []
    categories = categories || []
    timezones = timezones || []
    showExternalIds = !!showExternalIds
    allowOnlyGlobalCustomers = !!allowOnlyGlobalCustomers
    const filteredFields = fields.filter(item => item.hasOverwrite && item.isActive)
    const groupedFields = groupCustomerFieldsByCategory(filteredFields, categories)
    const availableFields = filteredFields
      .filter(item => item.defaultId && item.isActive)
      .map(item => item.defaultId) || []

    return (
      <>
        <Loader active={pending} />
        {(!pending &&
          <CustomerForm
            title={t('customers.form.heading')}
            type='add'
            groupedFields={groupedFields}
            availableFields={availableFields}
            cancelLink='/customers'
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            showExternalIds={showExternalIds}
            allowOnlyGlobalCustomers={allowOnlyGlobalCustomers}
            setFileLoading={this.setFileLoading}
            fileLoading={this.fileLoading}
            timezones={timezones}
          />
        )}
      </>
    )
  }
}

CustomerAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: state.customers.pendingForm,
  customerFields: state.customerFields.list,
  fields: state.customerFields.list,
  categories: state.customerFields.categoriesList,
  timezones: state.staticData.timezones,
  showExternalIds: state.company.settings.showExternalIds,
  allowOnlyGlobalCustomers: state.company.settings.allowOnlyGlobalCustomers
})

export default connect(maps)(CustomerAddWrapper)
