export const dayFields = `{
  isActive
  times {
    begin
    end
  }
}`

export const courseFields = `{
  id
  externalId
  internalId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  hasOnlinePayment
  isPaymentMandatory
  isStalled
  isUpdatedLocally
  categoryId
  resourcesCount
  maxParticipants
  extraPersonsPerParticipant
  isGloballyDeleted
  isUpdatedLocally
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
  calendar {
    bookingWeeklyAllowancePlan ${dayFields}
    workingWeeklyAllowancePlan ${dayFields}
  }
}`

export const courseFieldsForList = `{
  id
  ts
  categoryId
  internalId
  externalId
  name
  orderIndex
  color
  duration
  durationsPattern
  price
  hasOnlinePayment
  isBookable
  resourcesCount
  maxParticipants
  extraPersonsPerParticipant
  isStalled
  isGloballyDeleted
  isUpdatedLocally
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  dependencies {
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
}`

export const courseCategoryFields = `{
  id
  name
  orderIndex
  isDefault
  internalId
  externalId
  isUpdatedLocally
}`
