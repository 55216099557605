import React from 'react'
import PropTypes from 'prop-types'

const Col = props => {
  const { children, size, className, ...rest } = props
  const classNames = ['ta-col']
  if (size) classNames.push(`col-${size}`)
  if (className) classNames.push(className)

  return (
    <div {...rest} className={classNames.join(' ')} data-testid='col'>
      {children}
    </div>
  )
}

Col.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string
}

export default Col
