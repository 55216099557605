import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, FontAwesome5, Select } from '../../../Common'

import './BookingServiceSelect.css'
import { t } from '../../Translations/Translations'

class BookingServiceSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onSearch (value) {
    let { name, form, formName, onChange } = this.props
    form = form || { value: '' }
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value, selectedId: null } })
  }

  onChange (item) {
    let { name, form, formName, onChangeAddon } = this.props
    form = form || { value: '' }
    const { label, value } = item
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: label, selectedId: value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    let {
      label,
      placeholder,
      name,
      mandatory,
      disabled,
      options,
      className,
      onChangeAddon,
      hideError,
      form
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-booking-service-select']
    if (className) classNames.push(className)
    const selectOptions = options || form.options || []

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        <FormGroup
          focused={focused}
          filled={!!form.value}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
          disabled={disabled || form.disabled}
        >
          <Select
            name={name}
            value={form.selectedId}
            onChangeAddon={onChangeAddon}
            customizedValue={form.value}
            placeholder={form.value || placeholder || form.placeholder}
            options={selectOptions}
            hideArrow
            hasCategories
            searchable
            unresetableSearchValue
            onSearch={this.onSearch}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            noResultsText={t('global.noResults')}
            disabled={disabled || form.disabled}
            renderItem={(item, index, { isActive, isSelected, onSelect }) => {
              const itemClassNames = ['ta-select__options__item']
              if (isActive) itemClassNames.push('active')
              if (isSelected) itemClassNames.push('selected')
              return (
                <div className={itemClassNames.join(' ')} onClick={onSelect}>
                  {item.isCombination &&
                    <FontAwesome5
                      icon='layer-group'
                      type='solid'
                      className='ta-select__options__item__combination-icon'
                    />
                  }
                  {item.label}
                </div>
              )
            }}
          />
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(BookingServiceSelect), FormContext)
