import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { StripedTitle, SelectTags, SelectTag } from '../../Common'

const SelectTagsWithCategories = (props) => {
  let { values, options, disabled, onDelete, renderSelectedItem } = props
  values = values || []
  options = options || []

  const selectedOptions = useMemo(
    () =>
      options
        .map((category) => {
          let { name, items, ...rest } = category
          if (!name) return category
          items = items || []
          items = items.filter((item) => values.includes(item.value))
          return { name, items, ...rest }
        })
        .filter((category) => (values.includes(category.value) || (category.items || []).length > 0)),
    [options, values]
  )

  return selectedOptions.map((option, key) => {
    if (option.value === 'all') {
      return (
        <SelectTag
          key={key}
          index={key}
          item={option}
          disabled={disabled}
          onDelete={onDelete}
          renderSelectedItem={renderSelectedItem}
        />
      )
    }

    return <div className='ta-select__values__category' key={key}>
      <StripedTitle label={option.name} extraText={option.extraText} />
      <SelectTags
        values={values}
        disabled={disabled}
        onDelete={onDelete}
        options={option.items}
        renderSelectedItem={renderSelectedItem}
      />
    </div>
  })
}

SelectTagsWithCategories.propTypes = {
  values: PropTypes.array,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
  renderSelectedItem: PropTypes.func
}

export default SelectTagsWithCategories
