import React from 'react'
import {
  Avatar,
  FontAwesome5,
  Link,
  Online,
  t
} from '../../Common'
import { highlight } from '../../Utils'

const ResourcesListItem = props => {
  const { agent, search } = props

  const classNames = ['ta-agents__agent']
  if (agent.isDeleted) classNames.push('deleted')
  if (agent.isUpdated) classNames.push('updated')
  const statusClassNames = ['ta-agents__agent-status']
  if (agent.isBookable) statusClassNames.push('active')
  if (agent.hasOnlineWarning) statusClassNames.push('has-online-warning')

  return (
    <div className={classNames.join(' ')}>
      <Online>
        <Link to={`/agents/${agent.id}`}>
          <Avatar
            name={agent.name}
            image={agent.avatarUrl}
            color={agent.color}
            abbreviation={agent.abbreviation}
          />
          {(agent.isOwner &&
            <div
              className='ta-agents__agent__label owner'
              title={t('agents.preview.sectionDefault.agentOwner.tooltip')}
            >
              <FontAwesome5 icon='suitcase' type='solid' />
            </div>
          )}
          {(!agent.isOwner && agent.isAdmin &&
            <div
              className='ta-agents__agent__label admin'
              title={t('agents.preview.sectionDefault.agentAdmin.tooltip')}
            >
              <FontAwesome5 icon='user-shield' type='solid' />
            </div>
          )}
          {(!agent.isOwner && agent.isCustom &&
            <div
              className='ta-agents__agent__label custom'
              title={t('agents.preview.sectionDefault.agentCustom.tooltip')}
            >
              <FontAwesome5 icon='cogs' type='solid' />
            </div>
          )}
          <div className='ta-agents__agent-name'>{highlight(agent.name, search)}</div>
        </Link>
      </Online>
    </div>
  )
}

export default ResourcesListItem
