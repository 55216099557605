import React from 'react'

const IconListItemTitle = props => {
  const { children, width, className } = props
  const styles = {}
  if (width) styles.width = width
  const classNames = ['ta-icon-list__title']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} style={styles} data-testid='icon-list-item-title'>
      {children}
    </div>
  )
}

export default IconListItemTitle
