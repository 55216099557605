import React from 'react'
import PropTypes from 'prop-types'
import { Sidebar } from '../../Beauties'
import {
  LeftFirstSidebarWrapper,
  LeftSecondSidebarWrapper,
  NegativeLeftFirstSidebarWrapper,
  RightFirstSidebarWrapper
} from '../../Beasts'

import './Sidebar.css'

const Sidebars = props => {
  const {
    position,
    level,
    size,
    parentSize,
    negative,
    onClose,
    hideCloseButton,
    navigationPosition,
    route,
    noBottomFade
  } = props
  const status = {
    left1: '',
    left1negative: '',
    left2: '',
    right1: ''
  }

  // Left sidebar
  if (position === 'left' && level === 1 && !negative) status.left1 = 'active'
  if (position === 'left' && level === 2 && !negative) {
    status.left1 = 'pending'
    status.left2 = 'active'
  }
  if (position === 'left' && level === 1 && negative) status.left1negative = 'active'

  // Right sidebar
  if (position === 'right') {
    if (level === 1) status.right1 = 'active'
  }

  return (
    <>
      <Sidebar
        position='left'
        level='1'
        size={parentSize || size}
        childSize={parentSize ? size : null}
        status={status.left1}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
        route={route}
        navigationPosition={navigationPosition}
        noBottomFade={noBottomFade}
      >
        <LeftFirstSidebarWrapper />
      </Sidebar>
      <Sidebar
        position='left'
        level='2'
        size={level === 2 && size}
        status={status.left2}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
        route={route}
        navigationPosition={navigationPosition}
        noBottomFade={noBottomFade}
      >
        <LeftSecondSidebarWrapper />
      </Sidebar>
      <Sidebar
        position='left-negative'
        level='1'
        status={status.left1negative}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
        navigationPosition={navigationPosition}
        size={size}
        route={route}
        noBottomFade={noBottomFade}
      >
        <NegativeLeftFirstSidebarWrapper />
      </Sidebar>
      <Sidebar
        position='right'
        level='1'
        status={status.right1}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
        size={size}
        route={route}
        noBottomFade={noBottomFade}
      >
        <RightFirstSidebarWrapper />
      </Sidebar>
    </>
  )
}

Sidebars.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default Sidebars
