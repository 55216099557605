import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { isRouteDisabledByPermissions, Loader, t } from '../../Common'
import { AgentForm } from '../../Beauties'
import { MAX_NO_OF_AGENTS } from '../../Settings/settings'

const AgentAddWrapper = props => {
  let {
    pending,
    agents,
    form
  } = props
  form = form || {}

  useEffect(() => {
    if (isRouteDisabledByPermissions()) {
      handlers.navigateToPath('/')
    }

    handlers.agentFormGet()
  }, [])

  const onSubmit = cb => {
    handlers.agentFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <AgentForm
          availableAgents={MAX_NO_OF_AGENTS - agents.length}
          title={t('agents.add.heading')}
          type='add'
          cancelLink='/agents'
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

AgentAddWrapper.propTypes = {
  pending: PropTypes.bool.isRequired
}

const maps = state => ({
  pending: selectors.agentsPendingFormSelector(state),
  agents: selectors.agentsListSelector(state),
  form: selectors.formSelector(state, { formName: 'agents' })
})

export default memo(connect(maps)(AgentAddWrapper))
