import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './LabelInput.css'

export class LabelInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    let { name, form, onChange, onChangeAddon } = this.props
    form = form || { value: '' }
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    let {
      label,
      extraLabel,
      hintText,
      addon,
      type,
      name,
      value,
      mandatory,
      disabled,
      hideError,
      className,
      form
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-label-input']
    if (className) classNames.push(className)

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled || form.disabled}
          labelText={extraLabel || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          <div className='ta-label-input__prefix'>{label}</div>
          <input
            className='ta-form-control'
            ref={ref => { this.ref = ref }}
            type={type || form.type || 'text'}
            name={name}
            value={value || form.value}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            disabled={disabled || form.disabled}
            autoComplete='off'
          />
          {addon &&
            <div className='ta-form-control__addon'>{addon}</div>
          }
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>
          }
        </FormGroup>
        {!hideError &&
          <Error name={name} />
        }
      </div>
    )
  }
}

LabelInput.propTypes = {
  label: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

export const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(LabelInput), FormContext)
