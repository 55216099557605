import { payloads$, actions, handlers } from '../..'
import { q } from '../../API'
import { transformSetup2FASave } from './utils'

// 2FA Init
payloads$(actions.TWO_FA_INIT)
  .subscribe(async ({ authType }) => {
    const twoFASettings = await q('initUser2FA', {
      type: authType
    })

    const { error } = twoFASettings
    if (error) return set2FAErrors(transformTwoFAErrors([error.message]))
    handlers.twoFADataPopulate(twoFASettings)
    handlers.formFieldsUpdate('accountSecurity', {
      globalErrors: {
        error: '',
        errors: []
      }
    })
  })

payloads$(actions.TWO_FA_USER_SETUP)
  .subscribe(async ({ data, cb, isWithCallback }) => {
    const user2FA = await q('setupUser2FA', transformSetup2FASave(data))

    const { error } = user2FA
    if (error) {
      handlers.twoFAPendingSetupSet(false)
      set2FAErrors(transformTwoFAErrors([error.message]))
      return
    }
    handlers.twoFAFormPopulate()
    handlers.twoFARecoveryCodesPopulate(user2FA)
    const { accessToken, refreshToken, sessionDuration } = user2FA.authTokens
    handlers.authTokensPopulate({
      accessToken,
      refreshToken,
      expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
    })
    if (isWithCallback && cb) {
      handlers.account2FAJwtActivated()
      return cb()
    }
    handlers.account2FAActivated()
    handlers.twoFAPendingSetupSet(false)
  })

payloads$(actions.TWO_FA_REFRESH_RECOVERY_CODES)
  .subscribe(async ({ code, callback }) => {
    const recoveryCodes = await q('refresh2FARecoveryCodes', {type: 'OTP', code })
    const { error } = recoveryCodes || {}

    if (error) {
      set2FAErrors(transformTwoFAErrors([error.message]))
      return
    }

    if (callback) callback()

    handlers.twoFARecoveryCodesPopulate({ recoveryCodes })
    handlers.twoFAFormReady()
  })

const transformTwoFAErrors = (errors) => {
  return errors.map(() => ({ key: 'code', value: 'errors.twoFA.invalidToken' }))
}

const set2FAErrors = (errors) => {
  handlers.formErrorsSet('twoFA', errors)
  handlers.twoFAFormReady()
}