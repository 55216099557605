import { useEffect, useState } from 'react'
import { CALL_CENTER_DEFAULT_MAP_CENTER, GOOGLE_MAPS_API_KEY  } from '../../../Settings'

const getLocaleMapCentre = async (query) => {
  const geocodeURL = 'https://maps.googleapis.com/maps/api/geocode/json'
  const url = `${geocodeURL}?address=${query}&key=${GOOGLE_MAPS_API_KEY}`
  const result = await fetch(url, { method: 'GET' })
  const parsedJSON = await result.json()

  if (parsedJSON.status !== 'OK') {
    return CALL_CENTER_DEFAULT_MAP_CENTER
  }

  const location = parsedJSON.results[0].geometry.location

  return location
}

const useMapCenter = ({ locale, defaultCenter }) => {
  const [center, setCenter] = useState(defaultCenter || {})

  useEffect(() => {
    const fetchCenter = async () => {
      setCenter(await getLocaleMapCentre(locale))
    }
    if (!defaultCenter) {
      fetchCenter()
    }
  }, [locale, defaultCenter])

  return [center, setCenter]
}

export default useMapCenter
