import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../Store'
import { PopupTrigger } from '../../Beauties'

class PopupTriggerWrapper extends Component {
  constructor (props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick (name, position, id, extras) {
    const { extraClassName, disable } = this.props
    if (disable) return
    const availablePositions = ['right', 'left', 'top', 'top-left', 'bottom']
    const formattedPosition = availablePositions.includes(position) ? position : availablePositions[0]
    handlers.popupSet(name, formattedPosition, this.trigger, extraClassName, id, extras)
  }

  render () {
    const { block, extraClassName } = this.props
    const classNames = ['ta-popup__trigger']
    if (block) classNames.push('block')
    if (extraClassName) classNames.push(extraClassName)

    return (
      <div className={classNames.join(' ')} ref={trigger => { this.trigger = trigger }}>
        <PopupTrigger {...this.props} onClick={this.onClick} />
      </div>
    )
  }
}

PopupTriggerWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  extraClassName: PropTypes.string
}

export default PopupTriggerWrapper
