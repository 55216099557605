export const rulesFields = `{
  bookingsRead
  bookingsWrite
  bookingsDelete
  bookingsReschedule
  bookingsSchedule
  customersRead
  customersWrite
  usersRead
  usersWrite
  usersDelete
}`

export const phoneFields = `{
  prefix
  country
  number
  phone
}`

export const agentsFields = `{
  id
  email
  isConfirmed
  avatarUrl
  name
  color
  abbreviation
  lastAccountLoginAt
  createdAt
  phone ${phoneFields}
  roles 
  rules ${rulesFields}
  isPermissionUpdateAllowedByEnterpriseAgentIssuer
}`

// chainedEvents
