import { sortByOrderIndex } from '../../../Utils'
import { serviceListTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  pendingList: true,
  pendingListCategory: false,
  selected: null,
  messagePreview: null,
  messageList: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // // Reset
  // servicesReset: () => dispatch(actions.SERVICES_RESET),

  // // Subscription
  // servicesSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.SERVICES_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  servicesListGet: ({ allowedBranchIds }) => dispatch(actions.SERVICE_LIST_GET, { allowedBranchIds }),
  servicesListPopulate: ({ services, serviceCategories }) => dispatch(actions.SERVICES_LIST_POPULATE, { services: serviceListTransform(services), serviceCategories }),
  servicesListReady: () => dispatch(actions.SERVICES_LIST_READY)
  // servicesListOrderChange: ({ id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => dispatch(actions.SERVICES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }),
  // servicesListOrderChanged: (updatedServices) => dispatch(actions.SERVICES_LIST_ORDER_CHANGED, updatedServices),
  // servicesListMessageSet: message => dispatch(actions.SERVICES_LIST_MESSAGE_SET, message),
  // servicesUpdated: id => dispatch(actions.SERVICES_UPDATED, id),

  // // Reorder
  // servicesListReorderReset: () => dispatch(actions.SERVICES_LIST_REORDER_RESET),
  // servicesListReorderGet: () => dispatch(actions.SERVICES_LIST_REORDER_GET),
  // servicesListReorderSave: () => dispatch(actions.SERVICES_LIST_REORDER_SAVE),
  // servicesListReorderReady: payload => dispatch(actions.SERVICES_LIST_REORDER_READY, payload),

  // // One
  // serviceUpdate: service => dispatch(actions.SERVICE_UPDATE, serviceTransform(service)),
  // serviceUpdated: service => dispatch(actions.SERVICE_UPDATED, service),
  // serviceDelete: id => dispatch(actions.SERVICE_DELETE, id),
  // serviceDeleted: id => dispatch(actions.SERVICE_DELETED, id),
  // serviceRemoveDeleted: id => dispatch(actions.SERVICE_REMOVE_DELETED, id),
  // serviceResetGlobal: internalId => dispatch(actions.SERVICE_RESET_GLOBAL, internalId),
  // serviceResetGlobalReady: (payload) => dispatch(actions.SERVICE_RESET_GLOBAL_READY, payload),

  // // Preview
  // servicePreviewGet: ({ id, forceFetch }) => dispatch(actions.SERVICE_PREVIEW_GET, { id, forceFetch }),
  // servicePreviewPopulate: service => dispatch(actions.SERVICE_PREVIEW_POPULATE, service),
  // servicePreviewMessageSet: message => dispatch(actions.SERVICE_PREVIEW_MESSAGE_SET, message),

  // // Form
  // serviceFormGet: id => dispatch(actions.SERVICE_FORM_GET, id),
  // serviceFormPopulate: service => {
  //   handlers.formSet('services', serviceFormTransform(service))
  //   handlers.serviceFormReady()
  // },
  // serviceFormReady: () => dispatch(actions.SERVICE_FORM_READY),
  // serviceFormSave: (service, scrollToError = () => { }) => dispatch(actions.SERVICE_FORM_SAVE, { service, scrollToError }),

  // // Combination Form
  // serviceCombinationFormGet: id => dispatch(actions.SERVICE_COMBINATION_FORM_GET, id),
  // serviceCombinationFormPopulate: service => {
  //   handlers.formSet('services', serviceCombinationFormTransform(service))
  //   handlers.serviceCombinationFormReady()
  // },
  // serviceCombinationFormReady: () => dispatch(actions.SERVICE_COMBINATION_FORM_READY),
  // serviceCombinationFormSave: (service, scrollToError = () => { }) => dispatch(actions.SERVICE_COMBINATION_FORM_SAVE, { service, scrollToError }),

  // // Category
  // serviceCategoryUpdate: category => dispatch(actions.SERVICE_CATEGORY_UPDATE, category),
  // serviceCategoryDelete: id => dispatch(actions.SERVICE_CATEGORY_DELETE, id),
  // serviceCategoryDeleted: id => dispatch(actions.SERVICE_CATEGORY_DELETED, id),
  // serviceCategoryDeleteFailed: () => dispatch(actions.SERVICE_CATEGORY_DELETE_FAILED),
  // serviceCategoryRemoveDeleted: id => dispatch(actions.SERVICE_CATEGORY_REMOVE_DELETED, id),
  // serviceCategoryOrderChange: ({ id, oldIndex, newIndex }) => dispatch(actions.SERVICE_CATEGORIES_LIST_ORDER_CHANGE, { id, oldIndex, newIndex }),
  // serviceCategoryOrderChanged: updatedCategories => dispatch(actions.SERVICE_CATEGORIES_LIST_ORDER_CHANGED, updatedCategories),
  // serviceCategoryResetGlobal: ({ internalId, id }) => dispatch(actions.SERVICE_CATEGORY_RESET_GLOBAL, { internalId, id }),
  // serviceCategoryResetGlobalReady: () => dispatch(actions.SERVICE_CATEGORY_RESET_GLOBAL_READY),

  // // Category form
  // serviceCategoryFormGet: id => dispatch(actions.SERVICE_CATEGORY_FORM_GET, id),
  // serviceCategoryFormPopulate: category => {
  //   handlers.formSet('serviceCategory', serviceCategoryFormTransform(category))
  //   handlers.serviceCategoryFormReady()
  // },
  // serviceCategoryFormReady: () => dispatch(actions.SERVICE_CATEGORY_FORM_READY),
  // serviceCategoryFormSave: (category, scrollToError = () => { }) => dispatch(actions.SERVICE_CATEGORY_FORM_SAVE, { category, scrollToError })
})

// // Reset

// reducer.SERVICES_RESET = state => reducer.initialState

// // Subscription

// reducer.SERVICES_SUBSCRIPTION_SET = state => state

// List

reducer.SERVICE_LIST_GET = (state) => ({
  ...state,
  pendingList: true
})

reducer.SERVICES_LIST_POPULATE = (state, { services, serviceCategories, customerService }) => ({
  ...state,
  list: (services || []).sort(sortByOrderIndex),
  categoriesList: (serviceCategories || []).sort(sortByOrderIndex),
  messagePreview: null
})

reducer.SERVICES_LIST_READY = state => ({
  ...state,
  pendingList: false
})

// reducer.SERVICES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex, sourceCategoryId, destinationCategoryId }) => {
//   if (newIndex === oldIndex && sourceCategoryId === destinationCategoryId) return { ...state }
//   const reorderList = [...(state.reorderList || [])]
//   const service = { ...(reorderList.find(item => item.id === id) || {}) }
//   let sourceCategoryServices = reorderList.filter(item => item.categoryId === sourceCategoryId) || []
//   const destinationCategoryServices = reorderList.filter(item => item.categoryId === destinationCategoryId) || []
//   const otherCategoriesServices = reorderList.filter(item => item.categoryId !== sourceCategoryId && item.categoryId !== destinationCategoryId) || []
//   if (sourceCategoryId === destinationCategoryId) {
//     destinationCategoryServices.splice(oldIndex, 1)
//     destinationCategoryServices.splice(newIndex, 0, service)
//     sourceCategoryServices = []
//   }
//   if (sourceCategoryId !== destinationCategoryId) {
//     service.categoryId = destinationCategoryId
//     sourceCategoryServices.splice(oldIndex, 1)
//     destinationCategoryServices.splice(newIndex, 0, service)
//   }
//   return {
//     ...state,
//     hasOrderChanged: true,
//     reorderList: [
//       ...destinationCategoryServices,
//       ...sourceCategoryServices,
//       ...otherCategoriesServices
//     ]
//   }
// }

// reducer.SERVICES_LIST_ORDER_CHANGED = (state, updatedServices) => {
//   updatedServices = updatedServices.map(item => {
//     const oldService = state.list.find(service => service.id === item.id)
//     return {
//       ...oldService,
//       ...item
//     }
//   })
//   return {
//     ...state,
//     list: state.list
//       .filter(item => !updatedServices.find(service => service.id === item.id))
//       .concat(updatedServices)
//       .sort(sortByOrderIndex)
//   }
// }

// reducer.SERVICES_LIST_MESSAGE_SET = (state, message) => ({
//   ...state,
//   pendingList: false,
//   messageList: message,
//   messagePreview: null
// })

// // Reorder

// reducer.SERVICES_LIST_REORDER_RESET = state => ({
//   ...state,
//   hasOrderChanged: false,
//   reorderList: null,
//   reorderCategoriesList: null
// })

// reducer.SERVICES_LIST_REORDER_GET = state => {
//   const categories = [...state.categoriesList].map(item => ({ ...item }))
//     .filter(Boolean)
//     .sort(sortByOrderIndex)
//   const localCategories = categories.filter(item => !item.internalId)
//   const defaultCategory = categories.find(item => item.isDefault)
//   return {
//     ...state,
//     hasOrderChanged: false,
//     reorderList: ([...state.list]).filter(item => !item.internalId).sort(sortByOrderIndex),
//     reorderCategoriesList: [ ...localCategories, defaultCategory ]
//   }
// }

// reducer.SERVICES_LIST_REORDER_SAVE = state => ({
//   ...state,
//   hasOrderChanged: false,
//   pendingList: true
// })

// reducer.SERVICES_LIST_REORDER_READY = (state, payload) => {
//   payload = payload || { services: [], categories: [] }
//   const { list, categoriesList } = state || {}
//   const services = {}
//   const categories = {}
//   payload.services.forEach(item => {
//     services[item.id] = {
//       orderIndex: item.orderIndex,
//       categoryId: item.categoryId
//     }
//   })
//   payload.categories.forEach(item => {
//     categories[item.id] = { orderIndex: item.orderIndex }
//   })
//   return {
//     ...state,
//     pendingList: false,
//     list: list.map(item => ({ ...item, ...(services[item.id] || {}) })),
//     categoriesList: categoriesList.map(item => ({ ...item, ...(categories[item.id] || {}) }))
//   }
// }

// // One

// reducer.SERVICE_UPDATE = (state, service) => {
//   if (!service) return state
//   const reorderList = [...((state.reorderList || []).map(item => item.id === service.id ? service : item))]
//   const reorderIndex = reorderList.findIndex(item => item.id === service.id)
//   if (reorderIndex === -1) reorderList.push(service)
//   return {
//     ...state,
//     reorderList,
//     list: state.list
//       .filter(item => item.id !== service.id)
//       .concat([{ ...service, isUpdated: true }])
//       .sort(sortByOrderIndex)
//   }
// }

// reducer.SERVICE_UPDATED = (state, service) => {
//   if (!service) return state
//   const list = [...state.list]
//   const index = list.findIndex(item => item.id === service.id)
//   if (index < 0) return state
//   list[index] = { ...list[index], isUpdated: false }
//   return {
//     ...state,
//     list
//   }
// }

// reducer.SERVICES_UPDATED = state => state

// reducer.SERVICE_DELETE = state => ({
//   ...state,
//   pendingPreview: true
// })

// reducer.SERVICE_DELETED = (state, id) => {
//   const list = [...state.list]
//   const index = list.findIndex(item => item.id === id)
//   if (index < 0) return state
//   list[index] = { ...list[index], isDeleted: true }
//   return {
//     ...state,
//     list
//   }
// }

// reducer.SERVICE_REMOVE_DELETED = (state, id) => ({
//   ...state,
//   list: state.list.filter(item => item.id !== id),
//   reorderList: (state.reorderList || []).filter(item => item.id !== id)
// })

// reducer.SERVICE_RESET_GLOBAL = state => ({
//   ...state,
//   pendingPreview: true
// })

// reducer.SERVICE_RESET_GLOBAL_READY = state => ({
//   ...state,
//   pendingPreview: false
// })

// // Preview

// reducer.SERVICE_PREVIEW_GET = state => ({
//   ...state,
//   pendingPreview: true,
//   messagePreview: null
// })

// reducer.SERVICE_PREVIEW_POPULATE = (state, id) => {
//   if (!id) return { ...state, pendingPreview: false, selected: null }
//   return {
//     ...state,
//     pendingPreview: false,
//     selected: id
//   }
// }

// reducer.SERVICE_PREVIEW_MESSAGE_SET = (state, message) => ({
//   ...state,
//   pendingPreview: false,
//   messagePreview: message,
//   selected: null
// })

// // Form

// reducer.SERVICE_FORM_GET = state => ({
//   ...state,
//   pendingForm: true
// })

// reducer.SERVICE_FORM_READY = state => ({
//   ...state,
//   pendingForm: false
// })

// reducer.SERVICE_FORM_SAVE = state => ({
//   ...state,
//   pendingForm: true
// })

// // Combination Form

// reducer.SERVICE_COMBINATION_FORM_GET = state => ({
//   ...state,
//   pendingForm: true
// })

// reducer.SERVICE_COMBINATION_FORM_READY = state => ({
//   ...state,
//   pendingForm: false
// })

// reducer.SERVICE_COMBINATION_FORM_SAVE = state => ({
//   ...state,
//   pendingForm: true
// })

// // Category

// reducer.SERVICE_CATEGORY_UPDATE = (state, category) => {
//   if (!category) return state
//   const reorderCategoriesList = (state.reorderCategoriesList || []).map(item => item.id === category.id ? category : item)
//   const reorderIndex = reorderCategoriesList.findIndex(item => item.id === category.id)
//   if (reorderIndex === -1) reorderCategoriesList.push(category)
//   return {
//     ...state,
//     reorderCategoriesList,
//     categoriesList: state.categoriesList
//       .filter(item => item.id !== category.id)
//       .concat([category])
//       .sort(sortByOrderIndex)
//   }
// }

// reducer.SERVICE_CATEGORY_DELETE = (state, id) => ({
//   ...state,
//   pendingCategory: id
// })

// reducer.SERVICE_CATEGORY_DELETED = (state, id) => {
//   const categoriesList = [...state.categoriesList]
//   const index = categoriesList.findIndex(item => item.id === id)
//   if (index < 0) return state
//   categoriesList[index] = { ...categoriesList[index], isDeleted: true }
//   return {
//     ...state,
//     categoriesList,
//     pendingCategory: null
//   }
// }

// reducer.SERVICE_CATEGORY_REMOVE_DELETED = (state, id) => {
//   const categoriesList = [...state.categoriesList]
//   const defaultCategory = categoriesList.find(item => item.isDefault)
//   const deletedCategoryServices = [...state.list]
//     .filter(item => item.categoryId === id)
//     .map(item => ({ ...item, categoryId: defaultCategory.id }))
//   const list = [...state.list]
//     .filter(item => item.categoryId !== id)
//     .concat(deletedCategoryServices)
//     .map((item, index) => ({ ...item, orderIndex: index + 1 }))
//   return {
//     ...state,
//     list,
//     categoriesList: state.categoriesList.filter(item => item.id !== id),
//     reorderCategoriesList: (state.reorderCategoriesList || []).filter(item => item.id !== id)
//   }
// }

// reducer.SERVICE_CATEGORY_DELETE_FAILED = state => ({
//   ...state,
//   pendingCategory: null
// })

// reducer.SERVICE_CATEGORIES_LIST_ORDER_CHANGE = (state, { id, oldIndex, newIndex }) => {
//   if (newIndex === oldIndex) return { ...state }
//   const reorderCategoriesList = [...(state.reorderCategoriesList || [])]
//   const category = reorderCategoriesList.find(item => item.id === id) || {}
//   reorderCategoriesList.splice(oldIndex, 1)
//   reorderCategoriesList.splice(newIndex, 0, category)
//   return {
//     ...state,
//     hasOrderChanged: true,
//     reorderCategoriesList
//   }
// }

// reducer.SERVICE_CATEGORIES_LIST_ORDER_CHANGED = (state, updatedCategories) => {
//   updatedCategories = updatedCategories.map(item => {
//     const oldCategory = state.categoriesList.find(c => c.id === item.id)
//     return {
//       ...oldCategory,
//       ...item
//     }
//   })

//   return {
//     ...state,
//     categoriesList: state.categoriesList
//       .filter(item => !updatedCategories.find(uC => uC.id === item.id))
//       .concat(updatedCategories)
//       .sort(sortByOrderIndex)
//   }
// }

// reducer.SERVICE_CATEGORY_RESET_GLOBAL = (state, { id }) => ({
//   ...state,
//   pendingCategory: id
// })

// reducer.SERVICE_CATEGORY_RESET_GLOBAL_READY = state => ({
//   ...state,
//   pendingCategory: null
// })

// // Category form

// reducer.SERVICE_CATEGORY_FORM_GET = state => ({
//   ...state,
//   pendingCategoryForm: true
// })

// reducer.SERVICE_CATEGORY_FORM_READY = state => ({
//   ...state,
//   pendingCategoryForm: false
// })

// reducer.SERVICE_CATEGORY_FORM_SAVE = state => ({
//   ...state,
//   pendingForm: true
// })

export default reducer
