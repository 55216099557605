import React, { useEffect, useState } from 'react'
import { connect, selectors, handlers } from '../../../Store'
import { BookingFormLocation } from '../../../Beauties'

const BookingLocationWrapper = ({
  addresses,
  branchesOptions,
  form,
  branches,
  progress,
  branchesValue,
  pendingBranches,
  pendingTransformation,
  isRescheduleForm,
}) => {
  branchesOptions = branchesOptions || []
  const [parsedAddress, setAddress] = useState(addresses)

  useEffect(() => {
    let isValidLocationValue = {}
    if (isRescheduleForm) {
      isValidLocationValue = branches?.find(({ id }) => id === branchesValue)
    } else {
      isValidLocationValue = branchesOptions?.find(({ value }) => value === branchesValue)
    }
    if (!isValidLocationValue) {
      handlers.formFieldsUpdate('booking', { progress: { value: 2 } })
    }
    if (isRescheduleForm) {
      handlers.formFieldsUpdate('booking', { progress: { value: 3 } })
    }
  }, [branchesValue])

  const setSelectedAddress = (item) => {
    const selectedBranch = branchesOptions.find(branch => branch.value === item.id) || {}
    handlers.branchSelect(item.id)
    handlers.formFieldsUpdate('booking', { selectedBranchAddress: { value: { ...item, name: selectedBranch.label } }, selectedBranch: { value: item.id, options: branchesOptions }, bookingBranch: { value: item.id, options: branchesOptions, allOptions: branchesOptions } })
    handlers.formFieldsUpdate('booking', { progress: { value: 3 } })
    handlers.bookingLocalTransformationsGet({ isRescheduleForm })
  }

  const setSelectedBranch = () => {
    const { bookingBranch } = form || {}
    const { value } = bookingBranch || {}
    handlers.branchSelect(value)
    const selectedBranch = branches.find(branch => branch.id === value) || {}
    if (selectedBranch.address) {
      handlers.formFieldsUpdate('booking', {
        selectedBranchAddress: { value: { ...selectedBranch.address, name: selectedBranch.name } }
      })
      handlers.formFieldsUpdate('booking', { progress: { value: 3 } })
      handlers.bookingLocalTransformationsGet({ isRescheduleForm })
      return
    } else {
      handlers.formFieldsUpdate('booking', {
        selectedBranchAddress: { value: null }
      })
    }
    handlers.formFieldsUpdate('booking', { progress: { value: 3 } })
    handlers.bookingLocalTransformationsGet({ isRescheduleForm })
  }

  const onAddressSelect = (data) => {
    handlers.branchSelect(null)
    handlers.formFieldsUpdate('booking', { selectedBranchAddress: { value: null } })
    setAddress(parsedAddress && parsedAddress[0]
      ? parsedAddress.map(addr => ({
        ...addr,
        distance: window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng({ lat: addr.latitude, lng: addr.longitude }),
          new window.google.maps.LatLng({ lat: data.lat, lng: data.lng })
        ).toFixed(2)
      }))
      : null)
  }

  return (
    <BookingFormLocation
      addresses={addresses}
      branchesOptions={branchesOptions}
      branchesValue={branchesValue}
      form={form}
      branches={branches}
      progress={progress}
      setSelectedAddress={setSelectedAddress}
      setSelectedBranch={setSelectedBranch}
      parsedAddress={parsedAddress}
      onAddressSelect={onAddressSelect}
      pendingBranches={pendingBranches}
      pendingTransformation={pendingTransformation}
      isRescheduleForm={isRescheduleForm}
    />
  )
}

const maps = state => ({
  form: selectors.formSelector(state, { formName: 'booking' }),
  addresses: (state.branches.list || []).filter(({ address }) => address).map(({ address, id }) => ({ ...address, id })),
  branchesOptions: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'allOptions' }),
  branchesValue: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'value' }),
  branches: state.branches.list,
  progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
  pendingBranches: selectors.bookingsFieldSelector(state, { field: 'pendingBranches' }),
  pendingTransformation: selectors.bookingsFieldSelector(state, { field: 'pendingTransformation' }),
})

export default connect(maps)(BookingLocationWrapper)
