import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'
import { PopupAccountUnlink } from '../../../Beauties'

const PopupAccountUnlinkWrapper = props => {
  const approve = () => {
    const { id, extras, closePopup } = props
    const { type } = extras || {}
    if (id) handlers.accountUnlink({ id, type })
    closePopup && closePopup()
  }
  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }

  return (
    <PopupAccountUnlink approve={approve} reject={reject} />
  )
}

PopupAccountUnlinkWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

export default PopupAccountUnlinkWrapper
