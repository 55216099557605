import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, Error, FontAwesome5, format } from '../../../Common'

import 'rc-slider/assets/index.css'
import './RangeSlider.css'

class RangeSlider extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onChange (value) {
    let { form, formName, name, onChange } = this.props
    form = form || { value: '' }
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  render () {
    let {
      left,
      leftLabel,
      right,
      rightLabel,
      name,
      hintText,
      disabled,
      hideError,
      min,
      max,
      value,
      pushable,
      defaultValue,
      arrow,
      arrowStep,
      form
    } = this.props
    form = form || { value: '' }
    value = value || form.value
    const steps = max - min
    const stepSize = 100 / steps
    const leftOffset = value && value[0] ? stepSize * value[0] : 0
    const rightOffset = value && value[1] ? stepSize * value[1] : 100
    const centerOffset = (leftOffset + rightOffset) / 2
    const from = format(`${value[0]}:00`, 'time', { isUTC: true, format: 'HH:mm' })
    let until = format(`${value[1]}:00`, 'time', { isUTC: true, format: 'HH:mm' })
    if (until === '00:00') until = '24:00'

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className='ta-range-slider'>
        <div className='ta-range-slider__content'>
          <div className='ta-range-slider__body'>
            {(left && value &&
              <div className='ta-range-slider__left-value' style={{ left: `${leftOffset}%` }}>
                {from}
              </div>
            )}
            <Slider
              range
              min={min || form.min}
              max={max || form.max}
              pushable={pushable || form.pushable}
              defaultValue={defaultValue || form.defaultValue}
              value={value || form.value || [0, 1]}
              disabled={disabled || form.disabled}
              onChange={this.onChange}
            />
            {(arrow && value && value[1] - form.value[0] > arrowStep &&
              <div className='ta-range-slider__center' style={{ left: `${centerOffset}%` }}>
                <FontAwesome5 icon='arrows-h' />
              </div>
            )}
            {(right && value &&
              <div className='ta-range-slider__right-value' style={{ left: `${rightOffset}%` }}>
                {until}
              </div>
            )}
          </div>
          {((leftLabel || rightLabel) &&
            <div className='ta-range-slider__label-container'>
              <div>{leftLabel}</div>
              <div>{rightLabel}</div>
            </div>
          )}
        </div>
        {(hintText &&
          <div className='ta-form-control__hint'>{hintText}</div>
        )}
        {(!hideError &&
          <Error name={name} />
        )}
      </div>
    )
  }
}

RangeSlider.propTypes = {
  name: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.array
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(RangeSlider), FormContext)
