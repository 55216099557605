import React from 'react'
import {
  BorderedBox,
  Checkbox,
  Col,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  FormText,
  Link,
  ListItem,
  Radio,
  Row,
  SectionTitle,
  t,
  Title
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import './Settings.css'

const SettingsForm = (props) => {
  const {
    onSubmit,
    onCancel,
    rescheduleBookingActivated
  } = props

  return (
    <FixedContent>
      <FixedContentHeader>
        <div className='ta-settings__header-title'>
          <Title label={t('settings.section.edit.title')} />
        </div>
      </FixedContentHeader>
      <FixedContentBody>
        <Form className='ta-settings-form' name='settings'>
          <SectionTitle label={t('global.settings.multipleAppointments.title')} icon='calendar-check' />
          <ListItem>
            <div className='ta-settings__title'>{t('global.settings.multipleAppointments.subtitle')}</div>
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='allowMultipleAppointmentsPerBooking'
                  label={t('global.enable')}
                />
              </Col>
            </Row>
          </ListItem>
          <SectionTitle label={t('global.settings.resourceSelector.title')} icon='calendar-check' />
          <ListItem>
            <div className='ta-settings__title'>{t('global.settings.resourceSelector.subtitle')}</div>
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='newBookingActivated'
                  label={t('global.settings.resourceSelector.add.checkbox.label')}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='rescheduleBookingActivated'
                  label={t('global.settings.resourceSelector.reschedule.checkbox.label')}
                />
              </Col>
            </Row>
            {rescheduleBookingActivated && (
              <BorderedBox>
                <Row>
                  <Col>
                    <Radio
                      name='resourceSelectorRescheduleManual'
                      label={t('global.settings.resourceSelector.reschedule.radio.automatic.label')}
                      value={false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Radio
                      name='resourceSelectorRescheduleManual'
                      label={t('global.settings.resourceSelector.radio.manual.label')}
                      value
                    />
                  </Col>
                </Row>
              </BorderedBox>
            )}
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='displayBookedResources'
                  label={t('global.settings.resourceSelector.displayBookedResources.checkbox.label')}
                />
              </Col>
            </Row>
          </ListItem>
          <SectionTitle label={t('global.settings.slotPreferences.title')} icon='calendar-check' />
          <ListItem>
            <div className='ta-settings__title'>{t('global.settings.slotPreferences.subtitle')}</div>
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='allowBookingWithinWorkingTimes'
                  label={t('global.enable')}
                />
              </Col>
            </Row>
          </ListItem>
          <SectionTitle label={t('global.settings.hideOfflineSettings.title')} icon='signal-alt-slash' />
          <ListItem>
            <div className='ta-settings__title'>{t('global.settings.hideOfflineSettings.subtitle')}</div>
            <Row>
              <Col>
                <Checkbox
                  theme='switch'
                  name='hideOfflineServices'
                  label={t('global.enable')}
                />
              </Col>
            </Row>
          </ListItem>
          <FormSection>
            <SectionTitle label={t('settings.section.notifyCheckboxes.heading')} icon='envelope' />
            <Row>
              <Col>
                <FormText noOffset>{t('settings.section.notifyCheckboxes.questionText')}</FormText>
              </Col>
            </Row>
            <BorderedBox>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <FormText noOffset>{dangerousHTML(t('settings.section.notifyCheckboxes.resource.title'))}</FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnResources' value='default' label={t('settings.section.notifyCheckboxes.radio.flexiblySelectedDefault')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnResources' value='selected' label={t('settings.section.notifyCheckboxes.radio.alwaysSelected')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnResources' value='deselected' label={t('settings.section.notifyCheckboxes.radio.neverSelected')} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <FormText noOffset>{dangerousHTML(t('settings.section.notifyCheckboxes.customer.title'))}</FormText>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnCustomers' value='default' label={t('settings.section.notifyCheckboxes.radio.flexiblySelectedDefault')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnCustomers' value='selected' label={t('settings.section.notifyCheckboxes.radio.alwaysSelected')} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Radio name='hasCCNotifyOnCustomers' value='deselected' label={t('settings.section.notifyCheckboxes.radio.neverSelected')} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BorderedBox>
          </FormSection>
          <FormButtons>
            <Link onClick={onCancel} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
            <Link onClick={onSubmit} type='button' className='ta-btn ta-btn-primary'>{t('buttons.save.label')}</Link>
          </FormButtons>
        </Form>
      </FixedContentBody>
    </FixedContent>
  )
}

export default SettingsForm
