import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  SectionTitle,
  Row,
  Col,
  Input,
  FontAwesome5,
  Error,
  t
} from '../../Common'

const AccountEmailChangeForm = props => {
  const {
    title,
    message,
    onSubmit,
    cancelLink,
    oldPasswordFieldType,
    toggleOldPasswordFieldType
  } = props

  return (
    <div className='ta-account-form__email-change'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          {message && message.text}
          <Form onSubmit={onSubmit} name='account'>
            <FormSection>
              <SectionTitle label={t('user.edit.email.sectionEmail.heading')} icon='lock' />
              <Row>
                <Col className='relative'>
                  <Input
                    name='email'
                    label={t('user.edit.email.sectionEmail.newEmail.label')}
                    placeholder={t('user.edit.email.sectionEmail.newEmail.placeholder')}
                    mandatory
                  />
                </Col>
              </Row>
              <Row>
                <Col className='relative'>
                  <Input
                    name='reEmail'
                    label={t('user.edit.email.sectionEmail.repeatEmail.label')}
                    placeholder={t('user.edit.email.sectionEmail.repeatEmail.placeholder')}
                    mandatory
                  />
                </Col>
              </Row>
            </FormSection>
            <FormSection>
              <SectionTitle label={t('user.edit.email.sectionPassword.heading')} icon='fingerprint' />
              <Row>
                <Col className='relative'>
                  <Input
                    type={oldPasswordFieldType}
                    name='oldPassword'
                    label={t('user.edit.email.sectionPassword.label')}
                    placeholder={t('user.edit.email.sectionPassword.placeholder')}
                    mandatory
                  />
                  <div className='ta-account__password-type-toggle-btn' onClick={toggleOldPasswordFieldType}>
                    <FontAwesome5 icon={oldPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
              <Error name='globalErrors' noTopOffset />
            </FormSection>
            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <button type='submit' className='ta-btn ta-btn-primary'>{t('buttons.save.label')}</button>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

AccountEmailChangeForm.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default AccountEmailChangeForm
