import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { TwoFACodeForm } from '../../Beauties'

const TwoFACodeFormWrapper = props => {
  let { form, onAccountSelect, pending } = props
  form = form || {}
  const { code } = form
  const codeValue = code && code.value

  useEffect(() => {
    handlers.twoFAFormPopulate()
  }, [])

  const onSubmit = () => {
    onAccountSelect(code.value)
  }

  return (
    <TwoFACodeForm code={codeValue} onSubmit={onSubmit} pending={pending} />
  )
}

TwoFACodeFormWrapper.propTypes = {
  onAccountSelect: PropTypes.func.isRequired
}

const maps = state => ({
  form: state.forms.twoFA,
  pending: state.twoFA.pendingForm
})

export default connect(maps)(TwoFACodeFormWrapper)
