import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { AVAILABLE_SERVICE_HEXCOLORS } from '../../../Settings'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  Error,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'

import './ColorSelect.css'

class ColorSelect extends Component {
  constructor (props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.state = { open: false }
  }

  componentDidMount () {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.addEventListener('mouseup', this.handleOutsideClick)
    }
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mouseup', this.handleOutsideClick)
    } else {
      document.removeEventListener('mouseup', this.handleOutsideClick)
    }
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  handleOutsideClick (e) {
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ open: false })
    }
  }

  toggle () {
    this.setState(prevState => ({ open: !prevState.open }))
  }

  onChange (color) {
    let { name, form } = this.props
    form = form || { value: '' }
    this.setState({ open: false })
    handlers.formFieldsUpdate(this.props.formName, { [name]: { ...form, value: color } })
  }

  render () {
    const { open } = this.state

    let {
      name,
      pattern = '',
      colors = AVAILABLE_SERVICE_HEXCOLORS,
      value,
      children,
      form,
      hideError,
      hide,
      disabled
    } = this.props
    form = form || { value: '' }
    const classicPlanColor = null

    const optionsClasses = ['ta-color-select__options']
    const selectedOptionColorClasses = ['ta-color-select__options__color']
    const selectedClassNames = ['ta-color-select__selected']
    if (classicPlanColor) selectedClassNames.push('disabled')
    if (disabled) selectedClassNames.push('disabled')
    if (pattern !== '') {
      optionsClasses.push(pattern)
      selectedOptionColorClasses.push(pattern)
    }
    if (open) {
      selectedClassNames.push('active')
      optionsClasses.push('active')
    }
    if (classicPlanColor || value || form.value) selectedClassNames.push('hasValue')

    return (
      <div ref={wrapper => { this.wrapper = wrapper?.getElementsByClassName('ta-color-select__options')?.[0] }}>
        <div className='ta-color-select'>
          {children}
          {(!hide &&
            <>
              <HoverPopup disabled={!disabled}>
                <HoverPopupContent position='left' autoSize>
                  {t('global.premiumOnly')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <div className={selectedClassNames.join(' ')} onClick={this.toggle}>
                    <div className={selectedOptionColorClasses.join(' ')} style={{ backgroundColor: classicPlanColor || value || form.value }} />
                    <FontAwesome5 icon='sort' type='solid' />
                  </div>
                </HoverPopupTrigger>
              </HoverPopup>
              {(!disabled &&
                <div className={optionsClasses.join(' ')}>
                  {colors.map(color => {
                    const colorClassNames = [...selectedOptionColorClasses]
                    if (color === (classicPlanColor || value || form.value)) colorClassNames.push('ta-color-select__options__color__active')

                    return (
                      <div
                        key={color}
                        className={colorClassNames.join(' ')}
                        onClick={() => this.onChange(color)}
                        style={{
                          backgroundColor: color,
                          boxShadow: `0 0 0 1px ${color}`
                        }}
                      />
                    )
                  })}
                </div>
              )}
            </>
          )}
        </div>
        {(!hideError &&
          <Error name={name} />
        )}
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  plan: selectors.companyPlanSelector(state)
})

export default feedContextInProps(connect(maps)(ColorSelect), FormContext)
