import React from 'react'
import { connect, selectors } from '../../Store'
import { BookingFormDuration } from '../../Beauties'

const BookingFormDurationWrapper = props => {
  let {
    splitDurationInIntervals,
    color,
    formDurationBefore,
    formDurationAfter,
    formIntervals,
    from,
    hash,
    services,
    serviceId,
    formDurationHasErrors,
    enforceServiceDurationLimit,
    formIntervalsHasErrors,
    transformedService
  } = props
  services = services || []
  formIntervals = formIntervals || {}
  formDurationAfter = formDurationAfter || {}
  formDurationBefore = formDurationBefore || {}
  transformedService = transformedService || {}
  splitDurationInIntervals = !!splitDurationInIntervals
  const hasErrors = formDurationHasErrors || formIntervalsHasErrors || false
  const hasDurationBeforeOrAfter = formDurationBefore.value || formDurationAfter.value
  let service = services.find(item => item.id === serviceId)
  service = {
    ...service,
    duration: transformedService.duration || service.duration,
    durationsPattern: transformedService.durationsPattern || service.durationsPattern
  }

  return (
    <BookingFormDuration
      enforceServiceDurationLimit={enforceServiceDurationLimit}
      splitDurationInIntervals={splitDurationInIntervals}
      showDurationBeforeAndAfter={hasDurationBeforeOrAfter}
      color={color}
      from={from}
      hasErrors={hasErrors}
      formIntervals={formIntervals}
      formDurationBefore={formDurationBefore}
      formDurationAfter={formDurationAfter}
      service={service}
      hash={hash}
      showDurationDaySelector
    />
  )
}

const maps = state => ({
  color: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'color', property: 'value' }),
  splitDurationInIntervals: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'splitDurationInIntervals', property: 'value' }),
  formDurationBefore: selectors.formFieldSelector(state, { formName: 'booking', name: 'durationBefore' }),
  formDurationAfter: selectors.formFieldSelector(state, { formName: 'booking', name: 'durationAfter' }),
  formIntervals: selectors.formFieldSelector(state, { formName: 'booking', name: 'intervals' }),
  from: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'from', property: 'value' }),
  transformedService: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }),
  serviceId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'selectedId' }),
  services: selectors.servicesListSelector(state),
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  formDurationHasErrors: selectors.formFieldHasErrorSelector(state, { formName: 'booking', name: 'duration' }),
  enforceServiceDurationLimit: selectors.enterpriseSettingsFieldSelector(state, { field: 'enforceServiceDurationLimit' }),
  formIntervalsHasErrors: selectors.formFieldHasErrorSelector(state, { formName: 'booking', name: 'intervals' })
})

export default connect(maps)(BookingFormDurationWrapper)
