import React from 'react'
import { connect, selectors } from '../../Store'
import { AgentFormDetails } from '../../Beauties'

const AgentFormDetailsWrapper = props => {
  let { hasDuplicateEmails } = props
  hasDuplicateEmails = !!hasDuplicateEmails

  return (
    <AgentFormDetails
      hasDuplicateEmails={hasDuplicateEmails}
    />
  )
}

const maps = state => ({
  hasDuplicateEmails: selectors.formFieldPropertySelector(state, { formName: 'agents', name: 'hasDuplicateEmails', property: 'value' })
})

export default connect(maps)(AgentFormDetailsWrapper)
