import { createSelector } from '../../../Utils'

export const customersSelector = state => state.customers

export const customersPropertySelector = createSelector(
  customersSelector,
  (_, props) => props.property,
  (customers, property) => (customers || {})[property]
)

export const customerSelector = createSelector(
  state => customersPropertySelector(state, { property: 'list' }),
  (_, props) => props.id,
  (list, id) => (list || []).find(customer => customer.id === id)
)

export const customerEventSelector = createSelector(
  state => customersPropertySelector(state, { property: 'selected' }),
  (_, props) => props.id,
  (selected, eventId) =>
    [
      ...(selected?.pastEvents?.list || []),
      ...(selected?.upcomingEvents?.list || [])
    ].find(event => event.id === eventId)
)
