import React from 'react'
import { SingleSelect, t } from '../../Common'

const CustomersListFilterTags = props => {
  const { tags = [] } = props
  const options = tags.filter(item => !!item.isForCustomers).map((tag) => ({ value: tag.id, label: tag.name }))

  return (
    <div className='ta-customers__filter-sort'>
      <SingleSelect
        label={t('customers.filterByTag.label')}
        placeholder={t('customers.filterByTag.placeholder')}
        name='tags'
        options={options}
        clearable
        icon='sort'
      />
    </div>
  )
}

export default CustomersListFilterTags
