import { DEFAULT_LOCALE } from '../../../Settings'

const reducer = {}

reducer.initialState = {
  isReady: false,
  isConnected: false,
  isPending: false,
  pendingLanguage: true
}

reducer.handlers = (dispatch, actions) => ({
  appReady: () => dispatch(actions.APP_READY),
  appPendingSet: status => dispatch(actions.APP_PENDING_SET, status),
  languageChange: (locale) => dispatch(actions.LANGUAGE_CHANGE, locale),
  languageLoaded: (locale) => dispatch(actions.LANGUAGE_LOADED, locale),
  windowResize: (w) => dispatch(actions.WINDOW_RESIZE, w && {
    windowHeight: w.innerHeight,
    windowWidth: w.innerWidth
  }),
  connectionChange: isConnected => dispatch(actions.CONNECTION_CHANGE, isConnected)
})

reducer.APP_READY = state => ({
  ...state,
  isReady: true
})

reducer.APP_PENDING_SET = (state, status) => ({
  ...state,
  isPending: status === undefined ? true : status
})

reducer.LANGUAGE_CHANGE = state => ({
  ...state,
  pendingLanguage: true
})

reducer.LANGUAGE_LOADED = (state, locale = DEFAULT_LOCALE) => ({
  ...state,
  locale,
  pendingLanguage: false
})

reducer.WINDOW_RESIZE = (state, payload) => ({
  ...state,
  firstTrigger: !payload,
  windowHeight: payload && payload.windowHeight,
  windowWidth: payload && payload.windowWidth
})

reducer.CONNECTION_CHANGE = (state, isConnected) => ({
  ...state,
  isConnected: !!isConnected
})

export default reducer
