import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, globalActions, selectors } from '../../../Store'
import { PopupCustomerRemove } from '../../../Beauties'

class PopupCustomerRemoveWrapper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  onSubmit () {
    const { allowBookingWithinWorkingTimes, closePopup } = this.props
    globalActions.populateCustomerFieldsAndCategories(true)
    handlers.formFieldsUpdate('booking', {
      progress: { value: 0 },
      service: { value: null },
      duration: { value: null },
      durationBefore: { value: null },
      durationAfter: { value: null },
      bookingBranch: { value: null },
      customerAddress: { value: null },
      customerAddressRadius: { value: '5000' },
      selectedBranchAddress: { value: null },
      allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
      from: { value: null },
      timeIntervalMorning: { value: null },
      // keepExistingResource: { value: true },
      timeIntervalNoon: { value: null },
      timeIntervalAfternoon: { value: null },
      timeIntervalEvening: { value: null },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      step: { value: null },
      notes: { value: null },
      bookingCustomer: { value: null },
      transformedCustomer: { value: {} }
    })
    handlers.customerUpdate(null)
    handlers.customerPreviewPopulate(null)
    handlers.formDiscardPopupReset('booking')
    globalActions.populateTags([])
    closePopup && closePopup()
  }

  onCancel () {
    const { closePopup } = this.props
    closePopup && closePopup()
  }

  render () {
    return (
      <PopupCustomerRemove
        approve={this.onSubmit}
        reject={this.onCancel}
      />
    )
  }
}

PopupCustomerRemoveWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

const maps = (state, props) => ({
  allowBookingWithinWorkingTimes: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowBookingWithinWorkingTimes' })
})

export default connect(maps)(PopupCustomerRemoveWrapper)
