import React from 'react'
import PropTypes from 'prop-types'
import { AgentPreviewDetails } from '../../Beauties'
import { handlers } from '../../Store'

const AgentPreviewDetailsWrapper = props => {
  let { agent } = props
  agent = agent || {}

  const onResendEmailClick = email => {
    handlers.agentPreviewEmailConfirmationSend(email)
  }

  return (
    <AgentPreviewDetails onResendEmailClick={onResendEmailClick} {...agent} />
  )
}

AgentPreviewDetailsWrapper.propTypes = {
  agent: PropTypes.object.isRequired
}

export default AgentPreviewDetailsWrapper
