import React from 'react'
// import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE } from '../../Settings'
import {
  Link,
  FontAwesome5,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  IconListItemButtons,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  SectionTitle,
  Online,
  t,
  Alert
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { getAvailableLanguage } from '../../Utils'

const AccountDetails = props => {
  const {
    account,
    language,
    onAccountChange
    // onResendEmailClick
  } = props
  let {
    userId,
    email,
    name,
    // enterprise,
    userAccounts,
    companyId,
    providers,
    pendingEmail
  } = account || {}

  // const { id: selectedEnterpriseId } = enterprise || {}

  providers = providers || []
  const providersLabels = []
  if (providers.includes('EMAIL')) providersLabels.push(t('global.email.label'))
  if (providers.includes('PRIVATE_APP')) providersLabels.push('SSO')
  const systemLanguage = getAvailableLanguage(language)
  const editEmailButtonClassNames = ['ta-btn', 'ta-btn-icon-only', 'ta-btn-primary']
  if (pendingEmail) editEmailButtonClassNames.push('ta-btn-disabled')

  const ownEnterpriseAccounts = userAccounts.filter(item => item.authScope === 'ENTERPRISE' && item.isOwner && item.enterprise).sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []
  const invitedEnterpriseAccounts = userAccounts.filter(item => item.authScope === 'ENTERPRISE' && !item.isOwner && item.enterprise).sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []

  const ownCompanyAccounts = userAccounts.filter(item => item.authScope === 'COMPANY' && item.isOwner && item.company).sort((a, b) => a.company.name > b.company.name ? 1 : -1) || []
  const invitedCompanyAccounts = userAccounts.filter(item => item.authScope === 'COMPANY' && !item.isOwner && item.company).sort((a, b) => a.company.name > b.company.name ? 1 : -1) || []

  const ownCallCentreAccounts = userAccounts.filter(item => item.authScope === 'ENTERPRISE_CALL_CENTRE' && item.isOwner && item.enterprise).sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []
  const invitedCallCentreAccounts = userAccounts.filter(item => item.authScope === 'ENTERPRISE_CALL_CENTRE' && !item.isOwner && item.enterprise).sort((a, b) => a.enterprise.name > b.enterprise.name ? 1 : -1) || []

  const selectedAccount = userAccounts.find(item => item.authScope === 'ENTERPRISE_CALL_CENTRE' && item.accountId === companyId) || {}
  const bookerAccount = userAccounts.find(item => item.authScope === 'BOOKER')
  const developerAccount = userAccounts.find(item => item.authScope === 'DEVELOPER')
  const timifyAccount = userAccounts.find(item => item.authScope === 'TIMIFY')

  return (
    <div className='ta-account__details'>
      <IconListItem>
        <IconListItemTitle width='130px'>{t('user.preview.tabDetails.id')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{userId}</IconListItemBody>
      </IconListItem>
      {(name &&
        <IconListItem>
          <IconListItemTitle width='130px'>{t('global.detailsBasic')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{name}</IconListItemBody>
        </IconListItem>
      )}
      {(email &&
        <IconListItem>
          <IconListItemTitle width='130px'>{t('user.preview.tabDetails.loginEmail')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{email}</IconListItemBody>
          <IconListItemButtons>
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {pendingEmail ? t('user.preview.tabDetails.emailChangeInProcess') : t('user.preview.tabDetails.changeLoginEmail')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Online>
                  <Link to='/user/change-email' className={editEmailButtonClassNames.join(' ')} disabled={!!pendingEmail}>
                    <FontAwesome5 icon='pen' type='solid' />
                  </Link>
                </Online>
              </HoverPopupTrigger>
            </HoverPopup>
          </IconListItemButtons>
        </IconListItem>
      )}
      {(pendingEmail &&
        <IconListItem>
          <IconListItemTitle width='130px'>{t('user.preview.tabDetails.pendingEmail')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{pendingEmail}</IconListItemBody>
          <IconListItemButtons>
            <PopupTriggerWrapper name='email-change-cancel' position='left'>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {t('user.preview.tabDetails.cancelEmailChange')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Online>
                    <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                      <FontAwesome5 icon='trash' type='solid' />
                    </Link>
                  </Online>
                </HoverPopupTrigger>
              </HoverPopup>
            </PopupTriggerWrapper>
          </IconListItemButtons>
        </IconListItem>
      )}
      <IconListItem>
        <IconListItemTitle width='130px'>{t('global.password')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>****************</IconListItemBody>
        <IconListItemButtons>
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('user.preview.tabDetails.changePassword')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Online>
                <Link to='/user/change-password' className='ta-btn ta-btn-icon-only ta-btn-primary' data-testid='changePasswordButton'>
                  <FontAwesome5 icon='pen' type='solid' />
                </Link>
              </Online>
            </HoverPopupTrigger>
          </HoverPopup>
        </IconListItemButtons>
      </IconListItem>
      {/* TODO: Uncomment the following lines for system language in next iteration */}
      <IconListItem>
        <IconListItemTitle width='130px'>{t('global.systemLanguage')}:</IconListItemTitle>
        <IconListItemBody offset='180px'>{systemLanguage && systemLanguage.name}</IconListItemBody>
        <IconListItemButtons>
          <HoverPopup>
            <HoverPopupContent position='left' autoSize>
              {t('user.buttonHeading.editTooltip')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Online>
                <Link to='/user/edit' className='ta-btn ta-btn-icon-only ta-btn-primary'>
                  <FontAwesome5 icon='pen' type='solid' />
                </Link>
              </Online>
            </HoverPopupTrigger>
          </HoverPopup>
        </IconListItemButtons>
      </IconListItem>
      {(providers.includes('PRIVATE_APP') &&
        <IconListItem>
          <IconListItemTitle width='130px'>{t('global.providers')}:</IconListItemTitle>
          <IconListItemBody offset='180px'>{providersLabels.join(', ')}</IconListItemBody>
        </IconListItem>
      )}
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.ownerAccounts')} />
      {ownCallCentreAccounts.length > 0 ? ownCallCentreAccounts.map(item => {
        const { id, name } = item.enterprise || {}
        const { id: selectedAccountId } = selectedAccount.enterprise || {}
        return (
          <IconListItem key={id}>
            <IconListItemTitle>{name}</IconListItemTitle>
            {id !== selectedAccountId &&
              <IconListItemButtons>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {name}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Online>
                      <Link className='ta-btn ta-btn-icon-only ta-btn-delete' onClick={() => onAccountChange(id)}>
                        <FontAwesome5 icon='angle-right' type='solid' />
                      </Link>
                    </Online>
                  </HoverPopupTrigger>
                </HoverPopup>
              </IconListItemButtons>
            }
          </IconListItem>
        )
      }) : (
        <Alert label={t('accounts.callCentreAccounts.notFound')} theme='no-content' />
      )}
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.ownerAccounts')} />
      {ownCallCentreAccounts.length > 0
        ? ownCallCentreAccounts.map(item => {
          const { id, name } = item.enterprise || {}
          const { id: selectedAccountId } = selectedAccount.enterprise || {}
          return (
            <IconListItem key={id}>
              <IconListItemTitle>{name}</IconListItemTitle>
              {(id !== selectedAccountId &&
                <IconListItemButtons>
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize>
                      {name}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Online>
                        <Link className='ta-btn ta-btn-icon-only ta-btn-delete' onClick={() => onAccountChange(id)}>
                          <FontAwesome5 icon='angle-right' type='solid' />
                        </Link>
                      </Online>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </IconListItemButtons>
              )}
            </IconListItem>
          )
        })
        : (
          <Alert noOffset label={t('accounts.callCentreAccounts.noAccess')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.accountInvitations')} />
      {invitedCallCentreAccounts.length > 0
        ? invitedCallCentreAccounts.map((item, index) => {
          const { id, name } = item.enterprise || {}
          // const { id: selectedAccountId } = selectedAccount.enterprise || {}
          // const isSelectedAccount = id === selectedAccountId
          const zIndex = invitedCallCentreAccounts.length - index
          return (
            <IconListItem key={id} className={item.isConfirmed ? '' : 'not-confirmed'} zIndex={zIndex}>
              <IconListItemTitle>
                {name}
                {(!item.isConfirmed &&
                  <span>({t('user.preview.tabDetails.pendingInvitation')}…)</span>
                )}
              </IconListItemTitle>
              {/* {item.isConfirmed &&
              <IconListItemButtons>
                <PopupTriggerWrapper
                  name='account-unlink'
                  position='left'
                  id={id}
                  extras={{ type: 'CALL_CENTRE_ENTERPRISE' }}
                  disable={isSelectedAccount}
                >
                  <HoverPopup>
                    <HoverPopupContent position='left' autoSize={!isSelectedAccount}>
                      {isSelectedAccount
                        ? t('user.preview.tabDetails.unlinkDisabled')
                        : name
                      }
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Online>
                        <Link
                          className={`ta-btn ta-btn-delete ${isSelectedAccount ? 'disabled' : ''}`}
                          external>
                          {t('user.preview.tabDetails.unlink')}
                        </Link>
                      </Online>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </PopupTriggerWrapper>
              </IconListItemButtons>
            }
            {!item.isConfirmed && !item.isConfirmationEmailReSent &&
              <IconListItemButtons>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {name}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Online>
                      <div className='ta-account__details__invitation__btn' onClick={() => onResendEmailClick({ accountId: id, email: item.email })}>
                        <FontAwesome5 icon='envelope' />
                        {t('global.resendEmail')}
                      </div>
                    </Online>
                  </HoverPopupTrigger>
                </HoverPopup>
              </IconListItemButtons>
            } */}
            </IconListItem>
          )
        }) : (
          <Alert label={t('accounts.callCentreAccounts.noAccess')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.ownerKeyAccounts')} />
      {ownEnterpriseAccounts.length > 0
        ? ownEnterpriseAccounts.map(item => {
          const { id, name } = item.enterprise || {}
          return (
            <IconListItem key={id}>
              <IconListItemTitle>{name}</IconListItemTitle>
              {/* <IconListItemButtons>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {name}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link className='ta-btn ta-btn-icon-only ta-btn-delete'>
                    <FontAwesome5 icon='angle-right' type='solid' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </IconListItemButtons> */}
            </IconListItem>
          )
        }) : (
          <Alert label={t('accounts.keyAccounts.notFound')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.keyAccountInvitations')} />
      {invitedEnterpriseAccounts.length > 0
        ? invitedEnterpriseAccounts.map(item => {
          const { id, name } = item.enterprise || {}
          // const isSelectedEnterprise = selectedEnterpriseId === id

          return (
            <IconListItem key={id} className={item.isConfirmed ? '' : 'not-confirmed'}>
              <IconListItemTitle>
                {name}
                {(!item.isConfirmed &&
                  <span>({t('user.preview.tabDetails.pendingInvitation')}…)</span>
                )}
              </IconListItemTitle>
              {/* {item.isConfirmed &&
              <IconListItemButtons>
                <PopupTriggerWrapper
                  name='account-unlink'
                  position='left'
                  extras={{ type: 'ENTERPRISE' }}
                  id={id}
                  disable={isSelectedEnterprise}
                >
                  <HoverPopup>
                    <HoverPopupContent position='left'>
                      {isSelectedEnterprise
                        ? t('user.preview.tabTitle.loggedIn')
                        : name
                      }
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link
                        className={`ta-btn ta-btn-delete ta-account__action-button ${isSelectedEnterprise ? 'disabled' : ''}`}
                        external>
                        {t('user.preview.tabDetails.unlink')}
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </PopupTriggerWrapper>
              </IconListItemButtons>
            }
            {!item.isConfirmed && !item.isConfirmationEmailReSent &&
              <IconListItemButtons>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {name}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <div
                      className='ta-btn ta-btn-primary ta-account__action-button'
                      onClick={() => onResendEmailClick({ accountId: id, email: item.email })}
                    >
                      {t('global.invitationResend')}
                    </div>
                  </HoverPopupTrigger>
                </HoverPopup>
              </IconListItemButtons>
            } */}
            </IconListItem>
          )
        }) : (
          <Alert label={t('accounts.keyAccounts.noAccess')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.ownerCompanyAccounts')} />
      {ownCompanyAccounts.length > 0
        ? ownCompanyAccounts.map(item => {
          const { id, name } = item.company || {}
          return (
            <IconListItem key={id}>
              <IconListItemTitle>{name}</IconListItemTitle>
              {/* <IconListItemButtons>
              <HoverPopup>
                <HoverPopupContent position='left' autoSize>
                  {name}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Link className='ta-btn ta-btn-icon-only ta-btn-delete'>
                    <FontAwesome5 icon='angle-right' type='solid' />
                  </Link>
                </HoverPopupTrigger>
              </HoverPopup>
            </IconListItemButtons> */}
            </IconListItem>
          )
        }) : (
          <Alert label={t('accounts.companyAccounts.noAccess')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabTitle.companyInvitations')} />
      {invitedCompanyAccounts.length > 0
        ? invitedCompanyAccounts.map(item => {
          const { id, name } = item.company || {}

          return (
            <IconListItem key={id} className={item.isConfirmed ? '' : 'not-confirmed'}>
              <IconListItemTitle>
                {name}
                {(!item.isConfirmed &&
                  <span>({t('user.preview.tabDetails.pendingInvitation')}…)</span>
                )}
              </IconListItemTitle>
              {/* {item.isConfirmed &&
              <IconListItemButtons>
                <PopupTriggerWrapper
                  name='account-unlink'
                  position='left'
                  extras={{ type: 'COMPANY' }}
                  id={id}
                >
                  <HoverPopup>
                    <HoverPopupContent position='left'>{name}</HoverPopupContent>
                    <HoverPopupTrigger>
                      <Link className={'ta-btn ta-btn-delete ta-account__action-button'} external>
                        {t('user.preview.tabDetails.unlink')}
                      </Link>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </PopupTriggerWrapper>
              </IconListItemButtons>
            }
            {!item.isConfirmed && !item.isConfirmationEmailReSent &&
              <IconListItemButtons>
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {name}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <div
                      className='ta-btn ta-btn-primary ta-account__action-button'
                      onClick={() => onResendEmailClick({ accountId: id, email: item.email })}
                    >
                      {t('global.invitationResend')}
                    </div>
                  </HoverPopupTrigger>
                </HoverPopup>
              </IconListItemButtons>
            } */}
            </IconListItem>
          )
        }) : (
          <Alert label={t('accounts.companyAccounts.noAccess')} theme='no-content' />
        )
      }
      <SectionTitle icon='briefcase' label={t('user.preview.tabDetails.otherAccounts')} />
      {(timifyAccount || developerAccount || bookerAccount) ? (
        <>
          {(timifyAccount &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('user.preview.tabDetails.adminPannelAccount')}</IconListItemTitle>
              <IconListItemBody offset='180px'>
                <FontAwesome5 icon='check' type='solid' />
              </IconListItemBody>
            </IconListItem>
          )}
          {(developerAccount &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('user.preview.tabDetails.developerAccount')}</IconListItemTitle>
              <IconListItemBody offset='180px'>
                <FontAwesome5 icon='check' type='solid' />
              </IconListItemBody>
            </IconListItem>
          )}
          {(bookerAccount &&
            <IconListItem>
              <IconListItemTitle width='130px'>{t('user.preview.tabDetails.bookerAccount')}</IconListItemTitle>
              <IconListItemBody offset='180px'>
                <FontAwesome5 icon='check' type='solid' />
              </IconListItemBody>
            </IconListItem>
          )}
        </>
      ) : (
        <Alert noOffset label={t('accounts.otherAccounts.noAccess')} theme='no-content' />
      )}
    </div>
  )
}

export default AccountDetails
