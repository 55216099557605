import React from 'react'
import PropTypes from 'prop-types'

const PopupTrigger = props => {
  const {
    id,
    name,
    position,
    extras,
    className,
    children,
    onClick
  } = props
  const classNames = []
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} onClick={() => onClick(name, position, id, extras)}>
      {children}
    </div>
  )
}

PopupTrigger.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default PopupTrigger
