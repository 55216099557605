import React, { Component } from 'react'
import { connect, handlers, store, selectors } from '../../Store'
import { Sidebars } from '../../Beauties'

class SidebarsWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onClose = this.onClose.bind(this)
    this.state = { ignoreOnClose: false }
  }

  onClose () {
    const { ignoreOnClose } = this.state
    const { router } = this.props
    const { redirectOnClose, defaultRedirect = '/', formDiscard } = router.props
    const state = store.getState()
    const redirectUrl = `${defaultRedirect}${router.hash ? '@@' + router.hash : ''}`
    const formsWithUnsavedChanges = state.forms.formsWithUnsavedChanges || []

    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      handlers.formDiscardPopupSet(formDiscard, redirectUrl)
      return
    }

    // Fix sidebar overlay double click
    if (ignoreOnClose) return null
    this.setState({ ignoreOnClose: true })
    setTimeout(() => this.setState({ ignoreOnClose: false }), 500)

    redirectOnClose
      ? redirectOnClose()
      : handlers.navigateToPath(redirectUrl)
  }

  render () {
    let { sidebar, navigationPosition } = this.props
    sidebar = sidebar || {}

    return (
      <Sidebars
        {...sidebar}
        navigationPosition={navigationPosition}
        onClose={this.onClose}
      />
    )
  }
}

SidebarsWrapper.defaultProps = {
  defaultRedirect: '/'
}

const maps = state => ({
  sidebar: selectors.routerPropsFieldSelector(state, { field: 'sidebar' }),
  navigationPosition: state.navigation.position,
  router: selectors.routerSelector(state)
})

export default connect(maps)(SidebarsWrapper)
