export const dayFields = `{
  isActive
  times {
    begin
    end
  }
}`

export const serviceFields = `{
  id
  externalId
  internalId
  ts
  name
  description
  orderIndex
  color
  duration
  durationsPattern
  durationBefore
  durationAfter
  price
  isBookable
  isStalled
  hasOnlinePayment
  isPaymentMandatory
  hasOnlineWarning
  hasOnlinePaymentWarning
  categoryId
  resourcesCount
  isUpdatedLocally
  isGloballyDeleted
  isCombination
  isCombinationPriceOverwritten
  hasCombinationSameResourcesPreference
  combinationServiceIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  dependencies {
    name
    isStalled
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
  calendar {
    bookingWeeklyAllowancePlan ${dayFields}
    workingWeeklyAllowancePlan ${dayFields}
  }
}`

export const serviceFieldsForList = `{
  id
  ts
  categoryId
  internalId
  externalId
  name
  orderIndex
  color
  duration
  durationsPattern
  price
  hasOnlinePayment
  isBookable
  isStalled
  hasOnlineWarning
  hasOnlinePaymentWarning
  resourcesCount
  isUpdatedLocally
  isGloballyDeleted
  isCombination
  combinationServiceIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  dependencies {
    workInParallel
    specificResourceCategoryIds
    specificResourceIds
  }
}`

// discount {
//   discount
//   price
//   offerId
// }

export const serviceCategoryFields = `{
  id
  name
  orderIndex
  isUpdatedLocally
  isDefault
  externalId
  internalId
}`
