import React, { Suspense } from 'react'
import { connect, selectors } from '../../Store'
import { Spinner } from '../../Common'

const AccountsWrapper = React.lazy(() => import('../../Beasts/Accounts/AccountsWrapper'))

const NegativeLeftFirstSidebarWrapper = props => {
  const { route } = props
  const ROUTES_ACCOUNTS = ['accounts']

  return (
    <Suspense fallback={<Spinner />}>
      {ROUTES_ACCOUNTS.includes(route) && <AccountsWrapper />}
    </Suspense>
  )
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' })
})

export default connect(maps)(NegativeLeftFirstSidebarWrapper)
