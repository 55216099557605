import React from 'react'
import { dangerousHTML, getNameByColor } from '../../../Utils'
import momenttz from 'moment-timezone'
import {
  FontAwesome5,
  SimpleListItem,
  SimpleListItemTitle,
  SimpleListItemContent,
  Alert,
  format,
  t,
  Button,
  Row,
  Col,
  HoverPopupContent,
  HoverPopup,
  HoverPopupTrigger
} from '../../../Common'
import { BookingFormResourceAvatars } from '../../../Beauties'

const BookingFormSuccess = props => {
  const {
    onCreateNew,
    onCreateFollowup,
    onRescheduleAgain,
    isScheduleForm,
    isRescheduleForm,
    isCancellationForm,
    savedBookings,
    selectedBranch,
    branches,
    oldBooking,
    resources,
    resourceCategories,
    isResourceSelectorEnabled,
    displayBookedResources
  } = props
  let messageKey = 'bookings.scheduledBookingsSaved.title'
  if (isRescheduleForm) messageKey = 'bookings.rescheduledBookingsSaved.title'
  if (isCancellationForm) messageKey = 'bookings.scheduledBookingsCanceled.title'
  const showResourceAvatars = !isCancellationForm ? isResourceSelectorEnabled : displayBookedResources
  const bookings = [...savedBookings].map(event => {
    const intervals = [...event.intervals] || []
    const combination = event.combination
    const resourceIds = !combination ? event.resourceIds : combination.events?.flatMap?.(ev => ev.resourceIds)
    if (isCancellationForm) {
      return event
    }
    if (combination) {
      event.duration = combination.events.reduce((acc, ev) => acc + ev.duration, 0)
    }
    const interval = { ...(intervals[0] && intervals[0].interval) } || {}
    return {
      ...event,
      from: interval.begin || interval.from,
      intervalsCount: intervals.length,
      resources: resourceIds?.map?.(id => resources.find(item => item.id === id)) || [],
      resourceCategories: resourceCategories.filter(({ resources }) => resourceIds?.some(id => resources.map(item => item.id).includes(id)))
    }
  })

  return (
    <div className='ta-booking-form__widget-success'>
      <Alert
        theme='success'
        label={t(messageKey)}
      >
        {bookings.map((item, index) => {
          let {
            id,
            color,
            title,
            from,
            duration,
            combination,
            intervalsCount
          } = item || {}
          const dateParts = (from || '').split(' ')
          let date = dateParts[0]
          const extraTitle = intervalsCount > 1 ? ` (${intervalsCount} ${t('global.splitIntervals')})` : ''
          if (combination) {
            const {
              title: combinationTitle,
              startDate: combinationFrom
            } = combination || {}
            title = combinationTitle
            const combinationFromParts = combinationFrom.split(' ')
            from = combinationFrom
            date = combinationFromParts[0]
          }
          const {
            bookingDate: oldDate,
            bookingTime: oldTime,
            duration: oldDuration,
            resourceIds
          } = oldBooking || {}

          const eventBranch = branches.find(({ id }) => id === item.companyId) || selectedBranch
          const timezone = eventBranch?.timezone
          const selectedResources = resourceIds?.values?.map?.(id => resources.find(item => item.id === id)) || []
          const selectedCategories = resourceCategories.filter(({ resources }) => resourceIds?.values?.some(id => resources.map(item => item.id).includes(id)))

          return (
            <div key={id}>
              {isRescheduleForm && (
                <>
                  <div className='ta-booking-form__success_list_item'>
                    <SimpleListItem status={getNameByColor(color)} className='no-hover'>
                      <SimpleListItemTitle title={`${title}${extraTitle}`} />
                      <SimpleListItemContent>
                        <ul className='ta-list-inline'>
                          <li><FontAwesome5 icon='calendar' type='solid' /> {format(oldDate, 'shortWithShortWeekDay', { format: 'YYYY-MM-DD', timezone })}</li>
                          <li><strong><FontAwesome5 icon='clock' type='solid' />{momenttz.utc(`${oldDate} ${oldTime}`, 'YYYY-MM-DD HH:mm').tz(timezone).format('HH:mm')}</strong></li>
                          <li><FontAwesome5 icon='hourglass-half' /> {dangerousHTML(t('global.min', [{ key: 'MIN', value: oldDuration }]))}</li>
                          {showResourceAvatars && (
                            <li className='ta-booking-form__success_list_item__resources'>
                              <FontAwesome5 icon='user-friends' type='s' />
                              <BookingFormResourceAvatars resources={selectedResources} resourceCategories={selectedCategories} />
                            </li>
                          )}
                        </ul>
                      </SimpleListItemContent>
                    </SimpleListItem>
                  </div>
                  <div className='ta-booking-form__success_list__path'>
                    <div className='ta-booking-form__success_list__oval' />
                    <div className='ta-booking-form__success_list__dashed_line' />
                    <div className='ta-booking-form__success_list__oval' />
                  </div>
                </>
              )}
              <div className='ta-booking-form__success_list_item'>
                <SimpleListItem status={getNameByColor(color)} className='no-hover'>
                  <SimpleListItemTitle title={`${title}${extraTitle}`} />
                  <SimpleListItemContent>
                    <ul className='ta-list-inline'>
                      <li><FontAwesome5 icon='calendar' type='solid' /> {format(date, 'shortWithShortWeekDay', { format: 'YYYY-MM-DD' })}</li>
                      <li><strong><FontAwesome5 icon='clock' type='solid' />{momenttz.utc(from, 'YYYY-MM-DD HH:mm').tz(timezone).format('HH:mm')}</strong></li>
                      <li><FontAwesome5 icon='hourglass-half' /> {dangerousHTML(t('global.min', [{ key: 'MIN', value: duration }]))}</li>
                      {showResourceAvatars && (
                        <li className='ta-booking-form__success_list_item__resources'>
                          <FontAwesome5 icon='user-friends' type='s' />
                          <BookingFormResourceAvatars resources={item.resources} resourceCategories={item.resourceCategories} />
                        </li>
                      )}
                    </ul>
                  </SimpleListItemContent>
                </SimpleListItem>
              </div>
            </div>
          )
        })}
      </Alert>
      {(isRescheduleForm || isCancellationForm) && (
        <Row>
          <Col>
            <HoverPopup>
              <HoverPopupContent>
                {isRescheduleForm ? t('bookings.bookingRescheduled.createBooking.tooltip.message') : t('bookings.bookingCanceled.createBooking.tooltip.message')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button
                  greyGhost
                  block
                  className='ta-btn__create-customer'
                  onClick={onRescheduleAgain}
                >
                  {isRescheduleForm ? t('bookings.bookingRescheduled.createBooking.button.label') : t('bookings.bookingCanceled.createBooking.button.label')}
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      )}
      {isScheduleForm && (
        <Row>
          <Col size={50}>
            <HoverPopup>
              <HoverPopupContent>
                {t('bookings.scheduledBookingsSaved.createBooking.tooltip.message')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button
                  greyGhost
                  block
                  className='ta-btn__create-customer'
                  onClick={onCreateNew}
                >
                  {t('bookings.scheduledBookingsSaved.createBooking.button.label')}
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
          <Col size={50}>
            <HoverPopup>
              <HoverPopupContent>
                {t('bookings.scheduledBookingsSaved.createFollowupBooking.tooltip.message')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button
                  primary
                  block
                  className='ta-btn__create-customer'
                  onClick={onCreateFollowup}
                >
                  {t('bookings.scheduledBookingsSaved.createFollowupBooking.button.label')}
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default BookingFormSuccess
