import React, { Component } from 'react'
import './Accordion.css'

class Accordion extends Component {
  constructor (props, context) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      expand: this.props.expand === undefined ? true : this.props.expand,
      toggle: this.toggle,
      focused: false
    }
  }

  componentDidMount () {
    const { inactive } = this.props
    inactive && this.setState({ expand: true })

    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('mousedown', this.handleClick)
      document.attachEvent('keyup', this.handleKeyUp)
    } else {
      document.addEventListener('mousedown', this.handleClick)
      document.addEventListener('keyup', this.handleKeyUp)
    }
  }

  componentWillUnmount () {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('mousedown', this.handleClick)
    } else {
      document.removeEventListener('mousedown', this.handleClick)
    }
  }

  handleClick (e) {
    if (this.wrapper && this.wrapper.contains(e.target)) {
      this.setState({ focused: true })
    }
    if (this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ focused: false })
    }
  }

  handleKeyUp (e) {
    // gain focus with tab
    if (e.keyCode === 9 && this.wrapper && this.wrapper.contains(e.target)) {
      this.setState({ focused: true })
    }
    if (e.keyCode === 9 && this.wrapper && !this.wrapper.contains(e.target)) {
      this.setState({ focused: false })
    }
  }

  toggle (name) {
    const { inactive, addOnClick } = this.props
    !inactive && this.setState({ expand: !this.state.expand })
    addOnClick && addOnClick()
  }

  render () {
    const {
      children,
      inactive,
      hasFieldWithError,
      className
    } = this.props
    const { expand, focused } = this.state
    const classNames = ['ta-accordion']
    if (className) classNames.push(className)
    if (inactive) classNames.push('inactive')
    if (expand) classNames.push('expand')
    if (hasFieldWithError) classNames.push('has-field-with-error')
    const styles = {}
    if (focused) styles.zIndex = 9990

    return (
      <AccordionContext.Provider value={this.state}>
        <div
          ref={wrapper => { this.wrapper = wrapper }}
          className={classNames.join(' ')}
          style={styles}
          data-testid='accordion'
        >
          {children}
        </div>
      </AccordionContext.Provider>
    )
  }
}

export const AccordionContext = React.createContext({
  toggle: () => { }
})

export default Accordion
