import React from 'react'
import {
  Row,
  Col,
  DurationInput,
  FormText,
  t,
  BorderedBox,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Checkbox,
  BookingIntervalsInput
} from '../../../Common'
import { BookingFormDurationBeforeAndAfter } from '../../../Beauties'

const BookingFormDuration = props => {
  let {
    showDurationDaySelector,
    formDurationAfter,
    formDurationBefore,
    splitDurationInIntervals,
    enforceServiceDurationLimit,
    color,
    collapsedFormSections,
    plan,
    hash,
    service,
    course
  } = props
  collapsedFormSections = collapsedFormSections || []
  const shouldEnforceServiceDurationLimit = (enforceServiceDurationLimit !== null && enforceServiceDurationLimit !== undefined && enforceServiceDurationLimit >= 0) && (!!service || !!course)
  const isDurationChangeForbidden = shouldEnforceServiceDurationLimit && enforceServiceDurationLimit === 0

  return (
    <div className='ta-single-booking-form__duration'>
      <Row>
        <Col>
          <HoverPopup disabled={!shouldEnforceServiceDurationLimit} className='ta-split-intervals__btn'>
            <HoverPopupContent position='top'>
              {t('bookings.form.duration.btnDisabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <Checkbox
                name='splitDurationInIntervals'
                label={t('servicesGroups.form.section.duration.checkboxSplitBooking.description')}
                theme='switch'
                disabled={shouldEnforceServiceDurationLimit}
              />
            </HoverPopupTrigger>
          </HoverPopup>
        </Col>
      </Row>
      {(splitDurationInIntervals &&
        <BorderedBox>
          <Row>
            <Col>
              <BookingIntervalsInput
                name='intervals'
                limit={7}
                color={color}
                noDays={!showDurationDaySelector}
                shouldEnforceServiceDurationLimit={shouldEnforceServiceDurationLimit}
                enforceServiceDurationLimit={enforceServiceDurationLimit}
                service={hash === 'groupBooking' ? course : service}
              />
            </Col>
          </Row>
        </BorderedBox>
      )}
      {(!splitDurationInIntervals &&
        <BorderedBox>
          <Row>
            <Col>
              <FormText>{t('global.duration')}</FormText>
              <DurationInput
                name='duration'
                noDays={!showDurationDaySelector}
                enforceServiceDurationLimit={enforceServiceDurationLimit}
                shouldEnforceServiceDurationLimit={shouldEnforceServiceDurationLimit}
                isDurationChangeForbidden={isDurationChangeForbidden}
                service={hash === 'groupBooking' ? course : service}
                plan={plan}
                hash={hash}
              />
            </Col>
          </Row>
        </BorderedBox>
      )}
      <BorderedBox noMargin>
        <FormText>{t('global.duration.preparationAndFollowUpTimes')}</FormText>
        <BookingFormDurationBeforeAndAfter
          showDurationDaySelector
          showDurationBeforeAndAfter
          formDurationAfter={formDurationAfter}
          formDurationBefore={formDurationBefore}
          color={color}
          collapsedFormSections={collapsedFormSections}
        />
      </BorderedBox>
    </div>
  )
}

export default BookingFormDuration
