export default radius => ({
  strokeColor: '#385f87',
  strokeOpacity: 0,
  strokeWeight: 0,
  fillColor: '#385f87',
  fillOpacity: 0.14,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius,
  zIndex: 1
})
