// import moment from 'moment'
import momenttz from 'moment-timezone'
import { store } from '../../Store'

export const format = (date, format, options = {}) => {
  // options:
  // "isUTC": ignoring the timezone
  // "format": the incoming date format

  const state = store.getState()
  const { company, branches } = state
  const selectedBranchTimezone = branches.selected ? branches.selected.timezone : 'Europe/Berlin'
  if (!company) return 'errors.companyMissing'
  const timezone = options.useCompanyTimezone
    ? company.timezone
    : options.timezone || selectedBranchTimezone || company.timezone

  if (!timezone) return 'errors.missingTimezone'
  if (!format) return 'errors.missingFormat'
  if (!date) return 'errors.missingDate'
  if (options.isUTC) date = momenttz.utc(date, options.format || 'YYYY-MM-DDTHH:mm')
  else date = momenttz.utc(date, options.format || 'YYYY-MM-DDTHH:mm').tz(timezone)
  const shortDate = getShortDate(date)

  // DD/MM
  if (format === 'short') return shortDate

  // DD/MM HH:mm
  else if (format === 'shortWithTime') return `${shortDate} ${date.format('LT')}`

  // dd, DD/MM
  else if (format === 'shortWithShortWeekDay') return `${date.format('dd')}, ${shortDate}`

  // dddd, DD/MM
  else if (format === 'shortWithLongWeekDay') return `${date.format('dddd')}, ${shortDate}`

  // DD/MM/YYYY
  else if (format === 'long') return date.format('L')

  // DD/MM/YYYY HH:mm
  else if (format === 'longWithTime') return date.format('L LT')

  // dd, DD/MM/YYYY
  else if (format === 'longWithShortWeekDay') return date.format('dd, L')

  // dddd, DD/MM/YYYY
  else if (format === 'longWithLongWeekDay') return date.format('dddd, L')

  // HH:mm
  else if (format === 'time') return date.format('LT')

  // Custom format
  else return date.format(format)
}

const getShortDate = date => {
  const year = date.format('YYYY')
  let short = date.format('L').replace(year, '')
  if (isNaN(parseInt(short[0], 10))) {
    short = short.slice(1)
  }
  if (isNaN(parseInt(short[short.length - 1], 10))) {
    short = short.slice(0, -1)
  }
  return short
}
