import { MarkerClusterer, Renderer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

export class ClusterRenderer implements Renderer {
  render({ count, position }, stats) {
    const styles = [{
      '25': 'ta-cluster-icon__sm',
      '50': 'ta-cluster-icon__lg',
    }]
    const selectedStyles = count >= 50 ? styles['50'] : styles['25']
    const svg = window.btoa(`
      <svg fill="#cb3b43" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" r="100" />    
      </svg>
    `);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: count >= 50 ? new google.maps.Size(50, 50) : new google.maps.Size(25, 25),
      },
      label: {
        text: String(count),
        color: "#fff",
        fontSize: count >= 50 ? '15px' : '12px',
        fontWeight: '700'
      },
      title: `Cluster of ${count} markers`,
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  }
}

const applyClusterStyle = (map, clusteredMarkers) => new MarkerClusterer({
  map,
  markers: clusteredMarkers,
  renderer: new ClusterRenderer(),
  algorithm: new SuperClusterAlgorithm({
    maxDistance: 50000
  })
})

  // function ({ count, position }, stats) {
  //   const styles = [{
  //     '25': 'ta-cluster-icon__sm',
  //     '50': 'ta-cluster-icon__lg',
  //   }]
  //   const svg = window.btoa(`
  //     <svg fill="#cb3b43" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  //       <circle cx="120" cy="120" opacity=".8" r="70" />    
  //     </svg>
  //   `);

  //   // create marker using svg icon
  //   return new google.maps.Marker({
  //       position,
  //       icon: {
  //           url: `data:image/svg+xml;base64,${svg}`,
  //           scaledSize: new google.maps.Size(75, 75),
  //       },
  //       label: {
  //           text: String(count),
  //           color: "rgba(255,255,255,0.9)",
  //           fontSize: "12px",
  //       },
  //       // adjust zIndex to be above other markers
  //       zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
  //   });
  // }

export default applyClusterStyle
