import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  FormGroup,
  Error,
  DnDDraggable,
  DnDWrapper,
  DnDDroppable,
  Link,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Row,
  Col,
  Checkbox,
  t
} from '../../../Common'

import './MultipleFieldsSelect.css'

class MultipleFieldsSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChangeLabel = this.onChangeLabel.bind(this)
    this.onChangeValue = this.onChangeValue.bind(this)
    this.addValue = this.addValue.bind(this)
    this.deleteValue = this.deleteValue.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onChangeDragAndDropMode = this.onChangeDragAndDropMode.bind(this)
    this.onClickCheckbox = this.onClickCheckbox.bind(this)
    this.state = { focusedIndex: null, isDragAndDropMode: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (index) {
    this.setState({ focusedIndex: index })
  }

  onBlur () {
    this.setState({ focusedIndex: null })
  }

  onChangeLabel (value, index) {
    let { name, formName, values, errors } = this.props
    values = values || []
    if (!values[index]) return
    values[index].label = value || ''
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...values],
        errors
      }
    })
  }

  onChangeValue (value, index) {
    let { name, formName, values, errors } = this.props
    values = values || []
    if (!values[index]) return
    values[index].value = value || ''
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...values],
        errors
      }
    })
  }

  onChangeDragAndDropMode () {
    this.setState({ isDragAndDropMode: !this.state.isDragAndDropMode })
  }

  addValue () {
    let { name, formName, values, errors } = this.props
    values = values || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...values, { label: '' }],
        errors
      }
    })
  }

  deleteValue (index) {
    let { name, formName, values, errors } = this.props
    values = values || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...values.filter((item, key) => key !== index)],
        errors
      }
    })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  onDragUpdate () { }

  onDragStart () {
    this.onBlur()
  }

  onDragEnd (event) {
    let { name, formName, values, errors } = this.props
    values = values || []
    const orderedValues = this.swapValues(event, values)

    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: orderedValues,
        errors
      }
    })
  }

  swapValues (event, originalArray) {
    const movedItem = originalArray.find((item, index) => index === event.oldIndex)
    const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex)

    return [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex)
    ]
  }

  onClickCheckbox (value) {
    if (value) return
    let { name, formName, values, errors } = this.props
    values = values || []
    if (errors && errors.length === 0) return
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values,
        errors: []
      }
    })
  }

  render () {
    const { focusedIndex } = this.state
    let {
      className,
      values,
      errors,
      hasOrdering,
      name,
      checkboxValue,
      limit
    } = this.props
    limit = limit || 10
    values = values || []
    errors = errors || []
    checkboxValue = !!checkboxValue
    const classNames = ['ta-multi-fields-select']
    if (className) classNames.push(className)
    if (values.length === 0) values.push({ label: '' })
    const switchReorderingBtnClasses = ['ta-multi-fields-select-switch-btn', 'ta-btn']
    if (this.state.isDragAndDropMode) switchReorderingBtnClasses.push('ta-btn-primary')
    else switchReorderingBtnClasses.push('ta-btn-secondary')

    return (
      <div className={classNames.join(' ')}>
        {hasOrdering && values.length > 1 &&
          <div className='ta-multi-fields-select-switch-wrapper'>
            <HoverPopup>
              <HoverPopupContent position='left' autoSize>
                {this.state.isDragAndDropMode
                  ? t('global.exitOrdering')
                  : t('customerFields.form.select.values.reorder.tooltip')
                }
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Link
                  onClick={this.onChangeDragAndDropMode}
                  className={switchReorderingBtnClasses.join(' ')}
                >
                  <FontAwesome5 icon={this.state.isDragAndDropMode ? 'times' : 'arrows-v'} />
                </Link>
              </HoverPopupTrigger>
            </HoverPopup>
          </div>
        }
        <Row>
          <Col>
            <Checkbox
              name={`${name}Checkbox`}
              label={t('customerFields.form.select.values.customValues')}
              theme='switch'
              onChangeAddon={this.onClickCheckbox}
            />
          </Col>
        </Row>
        <DnDWrapper
          onDragUpdate={this.onDragUpdate}
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <DnDDroppable id='multiFieldsSelect' type='multiFieldsSelect'>
            {values.map((item, index) => (
              <DnDDraggable
                className='ta-multi-fields-select__dnd-wrapper'
                type='multiFieldsSelect'
                id={(index + 1).toString()}
                index={index}
                key={index}
                isDragDisabled={!hasOrdering || !this.state.isDragAndDropMode}
              >
                <div className={`ta-multi-fields-select__input ${values.length > 1 ? 'has-delete' : ''}`}>
                  <Row>
                    <Col>
                      <FormGroup
                        className='ta-multi-fields-select__input__value'
                        focused={focusedIndex === index}
                        filled={!!item.label}
                        labelText={t('global.labelCount', [{ key: 'COUNT', value: (index + 1) }])}
                        labelMandatory
                      >
                        <input
                          className='ta-form-control'
                          type='text'
                          value={item.label || ''}
                          placeholder={t('global.label.placeholder')}
                          onFocus={() => this.onFocus(index)}
                          onBlur={this.onBlur}
                          onChange={e => this.onChangeLabel(e.target.value, index)}
                          // maxLength={limit && limit}
                          autoComplete='off'
                        />
                      </FormGroup>
                    </Col>
                    {checkboxValue &&
                      <Col>
                        <FormGroup
                          className='ta-multi-fields-select__input__value'
                          focused={focusedIndex === index}
                          filled={!!item.label}
                          labelText={t('global.valueCount', [{ key: 'COUNT', value: (index + 1) }])}
                          labelMandatory
                        >
                          <input
                            className='ta-form-control'
                            type='text'
                            value={item.value || ''}
                            placeholder={t('global.value.placeholder')}
                            onFocus={() => this.onFocus(index)}
                            onBlur={this.onBlur}
                            onChange={e => this.onChangeValue(e.target.value, index)}
                            // maxLength={limit && limit}
                            autoComplete='off'
                          />
                        </FormGroup>
                      </Col>
                    }
                  </Row>
                  {hasOrdering && this.state.isDragAndDropMode &&
                    <div className='ta-multi-fields-select__input__btn-reorder'>
                      <FontAwesome5 icon='arrows-v' type='regular' />
                    </div>
                  }
                  {!this.state.isDragAndDropMode && values.length > 1 &&
                    <div className='ta-multi-fields-select__input__btn-delete' onClick={() => this.deleteValue(index)}>
                      <FontAwesome5 icon='trash' type='regular' />
                    </div>
                  }
                  {errors.length > 0 && errors.map((error, key) => error.index === index &&
                    <Error key={key} error={error} />
                  )}
                </div>
              </DnDDraggable>
            ))}
          </DnDDroppable>
        </DnDWrapper >
        {values.length < limit &&
          <div className='ta-multi-fields-select__btn-add' onClick={this.addValue}>
            <FontAwesome5 icon='plus' type='regular' /> {t('global.addValue')}
          </div>
        }
      </div>
    )
  }
}

MultipleFieldsSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  values: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'values' }),
  errors: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'errors' }),
  checkboxValue: selectors.formFieldPropertySelector(state, { formName: props.formName, name: `${props.name}Checkbox`, property: 'value' })
})

export default feedContextInProps(connect(maps)(MultipleFieldsSelect), FormContext)
