import { distinctUntilChanged, map } from 'rxjs/operators'
import { actions, globalActions, handlers, payloads$, store, store$, selectors } from '../../../Store'
import { q } from '../../API'
import { getWeekDays, sortByOrderIndex, truncateText } from '../../../Utils'
import {
  bookingFormValidate,
  bookingSaveTransform,
  bookingFormServerErrorsTransform,
  bookingsEventsListTransform,
  bookingWidgetAvailabilityTransform,
  bookingFormCustomerSearchValidate,
  bookingFormServicesSaveValidate,
  bookingAvailabilityGetFormServerErrorsTransform,
  filterBranches,
  bookingCancelFormValidate,
  prepareBookingFormDependencies
} from './utils'
import { format, t } from '../../../Common'
import { customerTransform } from '../../Components/Customers/utils'
import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'

globalActions.transformEvents = events => {
  return bookingsEventsListTransform(events)
}

globalActions.mapEventsForPreview = async events => await Promise.allSettled(
  events.map(async item => {
    let {
      interval,
      service,
      title,
      duration,
      companyId,
      id,
      resources,
      resourceCategories,
      areResourcesFetched
    } = item || {}
    const dateParts = interval.from.split(' ')
    const date = dateParts[0]
    const time = dateParts[1]
    if (!areResourcesFetched && id) ({ resources, resourceCategories } = await globalActions.customerEventResourcesGet(id))

    return {
      ...service,
      bookingDate: date,
      bookingTime: time,
      name: title,
      duration,
      companyId,
      id,
      resources,
      resourceCategories
    }
  })).then(results => results.map(item => item.value))

globalActions.prepareBookingFieldsWithValues = values => {
  if (!values) return []
  const parsedValues = {}
  const fileValues = {}
  values.forEach(item => {
    parsedValues[item.id] = item.value
    fileValues[item.id] = item.values
  })
  const { list = [] } = store.getState().customerFields
  return list
    .filter(({ id, isActive }) => isActive && Object.keys({ ...parsedValues, ...fileValues }).includes(id))
    .map(item => ({ ...item, value: parsedValues[item.id] === undefined ? null : parsedValues[item.id], values: fileValues[item.id] }))
    .sort(sortByOrderIndex)
}

globalActions.prepareBookingDependencies = async ({ isRescheduleForm, service, companyId, selectedResourceIds }) => {
  const state = store.getState()
  const { region, id: enterpriseId } = selectors.accountEnterpriseSelector(state) || {}
  const {
    newBookingActivated,
    displayBookedResources,
    rescheduleBookingActivated,
    resourceSelectorRescheduleManual
  } = selectors.accountCallCentreSettingsSelector(state) || {}
  const isResourceSelectorEnabled = isRescheduleForm ? rescheduleBookingActivated : newBookingActivated
  let resources = []
  let resourceCategories = []
  if (isResourceSelectorEnabled || displayBookedResources)({ resources, resourceCategories } = await q('getResourcesLinkedToService', { serviceId: service?.id, region, companyId, enterpriseId }))
  const fields = {
    resources: { values: resources },
    resourceCategories: { values: resourceCategories }
  }
  if (isResourceSelectorEnabled) {
    const dependencies = prepareBookingFormDependencies({
      availableResources: resources,
      availableResourcesCategories: resourceCategories,
      service,
      selectedResourceIds
    })
    if (isRescheduleForm && resourceSelectorRescheduleManual) dependencies.selectedResourceIds = []

    fields.dependencies = {
      values: dependencies.selectedResourceIds || [],
      resources: dependencies.availableResources || [],
      categories: dependencies.availableResourcesCategories || [],
      groups: dependencies.availableResourceGroups || [],
      service
    }

    if (service.isCombination) {
      const form = selectors.formSelector(state, { formName: 'booking' })
      let { events } = form?.combination?.value || {}
      events ||= []
      const eventServiceSelectedResourceIds = events.reduce((acc, { serviceId, resourceIds }) => (acc[serviceId] = resourceIds, acc), {})
      const combinationServiceIds = service.combinationServiceIds || []
      const combinationServices = await q('getEnterpriseCallCentreBranchServices', {
        filter: { ids: combinationServiceIds },
        companyId,
        region
      })
      fields.combinationServices = { value: combinationServices }
      combinationServices.forEach(item => {
        const dependencies = prepareBookingFormDependencies({
          availableResources: resources,
          availableResourcesCategories: resourceCategories,
          service: item,
          selectedResourceIds: eventServiceSelectedResourceIds[item.id]
        })
        if (isRescheduleForm && resourceSelectorRescheduleManual) dependencies.selectedResourceIds = []

        fields[`dependencies${item.id}`] = {
          values: dependencies.selectedResourceIds || [],
          resources: dependencies.availableResources || [],
          categories: dependencies.availableResourcesCategories || [],
          groups: dependencies.availableResourceGroups || [],
          service: item
        }
      })
    }
  }

  return fields
}

payloads$(actions.BOOKING_FORM_GET)
  .subscribe(({ type }) => {
    const state = store.getState()
    const { persist } = state || {}
    const settings = selectors.accountCallCentreSettingsSelector(state)
    handlers.bookingFormPopulate({ type, persist }, settings || {})
  })

// Save
payloads$(actions.BOOKING_FORM_SAVE)
  .subscribe(async ({ scrollToError }) => {
    await globalActions.populateCustomerFieldsAndCategories()
    const state = store.getState()
    const {
      company,
      branches,
      services
    } = state
    const { locale } = company || {}
    const country = selectors.countrySelector(state) || {}
    const plan = selectors.companyPlanSelector(state) || 'CLASSIC'
    const booking = selectors.formSelector(state, { formName: 'booking' })
    const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
    const { code: currencyCode } = currency || {}
    const customerFields = store.getState().customerFields.list || []
    const { list: servicesList } = services || {}
    const routeName = selectors.routerFieldSelector(state, { field: 'name' })
    const {
      newBookingActivated,
      rescheduleBookingActivated
    } = selectors.accountCallCentreSettingsSelector(state) || {}
    const isRescheduleForm = routeName === 'rescheduleBooking'
    const isResourceSelectorEnabled = isRescheduleForm ? rescheduleBookingActivated : newBookingActivated

    const errors = bookingFormValidate(booking, customerFields)
    if (errors && errors.length) {
      setBookingFormSaveErrors(errors, scrollToError)
      return
    }

    const isFastBookingWidget = booking.type === 'widgetBooking'
    const timezone = branches.selected.timezone
    booking.timezone = timezone
    // If we are adding a booking, save the previous state of hasNotifyCustomers/hasNotifyResources
    handlers.persistSet({ hasNotifyCustomersBooking: booking.hasNotifyCustomers && booking.hasNotifyCustomers.value })
    handlers.persistSet({ hasNotifyResourcesBooking: booking.hasNotifyResources && booking.hasNotifyResources.value })
    booking.hasEnforceResourceAllowanceType = false
    booking.isFastBookingWidget = isFastBookingWidget
    const queryVariables = {
      ...bookingSaveTransform({
        booking: { ...booking, plan },
        services: servicesList,
        customerFields,
        isRescheduleForm,
        isResourceSelectorEnabled
      }),
      companyId: branches.selected.id,
      region: branches.selected.region || 'EUROPE'
    }

    const savedBookings = await q('saveEnterpriseCallCentreBranchCompanyEvents', queryVariables)
    const { error } = savedBookings || { error: {} }
    if (error) {
      // Custom error content
      if (error.code === 'CombinationConflicts') {
        const conflictCombination = (error.data || {}).combination || {}
        conflictCombination.forEach(item => {
          const conflictResources = (item || {}).resources || {}
          const result = []
          Object
            .keys(conflictResources)
            .forEach(key => {
              const item = conflictResources[key]
              const resource = selectors.agentByIdSelector(state, { id: key }) || {}
              const { dates } = item || {}
              result.push('<div class="ta-error__recurring-conflicts">')
              result.push(`<div class="ta-error__recurring-conflicts__agents"><span>${truncateText(resource.name, 30, true)}</span></div>`)
              if (dates) {
                dates.forEach(date => {
                  const { isAllowanceConflict } = date || {}
                  result.push(`<em>${format(date.startDate, 'long', { isUTC: true })} - ${t(`errors.booking.form.recurringConflicts${isAllowanceConflict ? 'BookingAllowanceConflict' : 'BookingOverlap'}.text`)}</em>`)
                })
              }
              result.push('</div>')
            })
          item.value = result.join('')
        })
      }
      if (error.code === 'RecurringConflicts') {
        const conflictResources = (error.data || {}).resources || {}
        const result = []
        Object
          .keys(conflictResources)
          .forEach(key => {
            const item = conflictResources[key]
            const resource = selectors.agentByIdSelector(state, { id: key }) || {}
            const { dates } = item || {}
            result.push('<div class="ta-error__recurring-conflicts">')
            result.push(`<div class="ta-error__recurring-conflicts__agents"><span>${truncateText(resource.name, 30, true)}</span></div>`)
            if (dates) {
              dates.forEach(date => {
                const { isAllowanceConflict } = date || {}
                result.push(`<em>${format(date.startDate, 'long', { isUTC: true })} - ${t(`errors.booking.form.recurringConflicts${isAllowanceConflict ? 'BookingAllowanceConflict' : 'BookingOverlap'}.text`)}</em>`)
              })
            }
            result.push('</div>')
          })
        error.value = result.join('')
      }
      setBookingFormSaveErrors(bookingFormServerErrorsTransform(error, [], stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode), scrollToError)
      return
    }
    // const parallelBookingsResources = resourcesList
    //   .filter(item => item.threads > 1)
    //   .map(item => item.id)
    savedBookings.forEach(savedBooking => {
      savedBooking = savedBooking || {}
      const { chainedEvents } = savedBooking
      // if (resourceIds.some(item => parallelBookingsResources.includes(item))) return
      handlers.bookingsListUpdate({ event: savedBooking, intervals: savedBooking.bookingIntervals })
      if (chainedEvents) {
        chainedEvents.forEach(item => {
          handlers.bookingsListUpdate({ event: item, intervals: item.bookingIntervals })
        })
      }
    })
    handlers.bookingFormReady()
    handlers.persistDelete('customerId')
    handlers.persistDelete('externalCustomerId')
    handlers.bookingFormContainerSet({})
    handlers.formFieldsUpdate('booking', {
      savedBookings: { values: savedBookings }
    })
    handlers.formFieldsUpdate('booking', { progress: { value: 6 } })
  })

const setBookingFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('booking', errors)
  scrollToError && scrollToError(errors)
  handlers.bookingFormReady()
}

// Fast booking widget
payloads$(actions.BOOKING_FORM_AVAILABTY_GET)
  .subscribe(async date => {
    const state = store.getState()
    const {
      company,
      enterprise,
      resources,
      branches,
      forms
    } = state || {}
    const transformedService = selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }) || {}
    const isRescheduleForm = transformedService.rescheduledEventId
    const newBookingActivated = selectors.accountCallCentreSettingsPropertySelector(state, { property: 'newBookingActivated' })
    const rescheduleBookingActivated = selectors.accountCallCentreSettingsPropertySelector(state, { property: 'rescheduleBookingActivated' })
    const isResourceSelectorEnabled = isRescheduleForm ? rescheduleBookingActivated : newBookingActivated
    const servicesList = selectors.servicesListSelector(state) || []
    const { booking } = forms || {}
    const {
      slots: bookingFormSlots,
      from: bookingFormFrom
    } = booking || {}
    const { values } = bookingFormSlots || {}
    handlers.formFieldsUpdate('booking', {
      slots: { values }
    })
    date = date || (bookingFormFrom || {}).value || new Date()
    const { settings: companySettings } = company || {}
    const { settings: enterpriseSettings } = enterprise || {}
    const timezone = branches && branches.selected && branches.selected.timezone
    const { dependencies } = booking || {}
    const { values: dependenciesValues } = dependencies || {}
    const { list: resourcesList } = resources || {}
    const hasResourceEnforceAllowanceType = (resourcesList || [])
      .filter(item => dependenciesValues.includes(item.id))
      .some(item => item.enforceAllowanceType)
    const {
      hideFastWidgetTimeFrame,
      enforceResourcesAllowanceType: companyEnforceResourcesAllowanceType
    } = companySettings || {}
    const {
      enforceResourcesAllowanceType: enterpriseEnforceResourcesAllowanceType
    } = enterpriseSettings || {}
    const hasEnforceResourceAllowanceType =
      (hasResourceEnforceAllowanceType && hasResourceEnforceAllowanceType !== 'NONE') ||
      (companyEnforceResourcesAllowanceType && companyEnforceResourcesAllowanceType !== 'NONE') ||
      (!companyEnforceResourcesAllowanceType && enterpriseEnforceResourcesAllowanceType && enterpriseEnforceResourcesAllowanceType !== 'NONE') ||
      false
    const allowMultipleAppointmentsPerBooking = selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowMultipleAppointmentsPerBooking' })
    const areMultipleBookingDisabled = !allowMultipleAppointmentsPerBooking
    const bookingForm = { ...booking }
    bookingForm.currentWeekDays = getWeekDays({ date, timezone, days: [0, 1, 2, 3, 4, 5, 6] }).map(item => item.date)
    bookingForm.hasEnforceResourceAllowanceType = hasEnforceResourceAllowanceType
    bookingForm.hideFastWidgetTimeFrame = hideFastWidgetTimeFrame
    bookingForm.transformedServiceId = transformedService.id
    const result = await q('getEnterpriseCallCentreBranchServiceAvailability', { ...bookingWidgetAvailabilityTransform(bookingForm, servicesList, branches, company, isResourceSelectorEnabled), timezone })
    // const result = availability.getCompanyServiceAvailability
    let {
      error,
      slots,
      onDays,
      offDays,
      calendarBegin,
      calendarEnd
    } = result || { error: {} }
    slots = slots || []
    if (error) {
      return setBookingAvailabilityGetFormSaveErrors(bookingAvailabilityGetFormServerErrorsTransform(error))
    }

    const isCombination = transformedService.isCombination
    const availableSlots = slots.map(slot => ({
      day: slot.day,
      times: slot.times.map((item, index) => ({
        time: item,
        minutes: slot.minutes[index],
        isDisabled: (isRescheduleForm || isCombination || areMultipleBookingDisabled) && (values || []).length > 0
      }))
    }))
    handlers.formFieldsUpdate('booking', {
      slots: {
        availableSlots,
        onDays,
        offDays,
        calendarBegin,
        calendarEnd,
        values
      }
    })
    handlers.bookingFormWidgetAvailabilityReady()
  })

const setBookingAvailabilityGetFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('booking', errors)
  scrollToError && scrollToError(errors)
  handlers.bookingFormWidgetAvailabilityReady()
}

payloads$(actions.BOOKING_FORM_CUSTOMER_SEARCH)
  .subscribe(async () => {
    const state = store.getState()
    const search = selectors.formFieldSelector(state, { formName: 'booking', name: 'search' })
    const tags = selectors.formFieldSelector(state, { formName: 'booking', name: 'tags' })
    handlers.formFieldsUpdate('booking', { isCustomerAdd: { value: false }, isCustomerEdit: { value: false } })
    const errors = bookingFormCustomerSearchValidate({ search, tags }) || []
    if (errors.length) {
      handlers.customersListPopulate()
      setBookingFormNextStepErrors(errors)
      return
    }
    handlers.formFieldsUpdate('booking', { showResults: { value: true } })
    handlers.customersListGet()
  })

payloads$(actions.BOOKING_FORM_SERVICES_SAVE)
  .subscribe(async () => {
    const state = store.getState()
    const { account, services, forms } = state
    const { list: servicesList } = services || {}
    const { booking } = forms || {}
    const { region } = account.enterprise || {}
    const service = selectors.formFieldSelector(state, { formName: 'booking', name: 'service' })
    const customer = selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }) || {}
    const bookingBranch = selectors.formFieldSelector(state, { formName: 'booking', name: 'bookingBranch' }) || {}
    const errors = bookingFormServicesSaveValidate({ booking, services: servicesList }) || []
    if (errors.length) {
      setBookingFormNextStepErrors(errors)
      handlers.bookingFormServicesReady()
      return
    }
    handlers.formFieldsUpdate('booking', { progress: { value: 2 } })
    const selectedService = servicesList.find(({ id }) => service && service.selectedId ? id === service.selectedId : false) || {}
    const filter = { globalCustomerId: customer.internalId, globalServiceId: service && service.selectedId }
    const branches = await q('getEnterpriseCallCentreBranches', { region, filter }) || []
    const { allowedBranchIds: customerAllowedBranchIds, allowedBranchExternalIds: customerAllowedBranchExternalIds } = customer
    let { allowedBranchIds: serviceAllowedBranchIds, allowedBranchExternalIds: serviceAllowedBranchExternalIds } = selectedService
    serviceAllowedBranchIds = serviceAllowedBranchIds || []
    serviceAllowedBranchExternalIds = serviceAllowedBranchExternalIds || []
    const branchOptions = branches
      .filter(({ id, externalId }) => (
        filterBranches(id, externalId, customerAllowedBranchIds, customerAllowedBranchExternalIds) && filterBranches(id, externalId, serviceAllowedBranchIds, serviceAllowedBranchExternalIds)
      ))
      .map(item => ({ value: item.id, rawValue: item, label: item.name }))
    // if there is only one branch, preselect it
    if (branches.length === 1) {
      const selectedBranch = branches[0] || {}
      const branchValue = selectedBranch.id
      handlers.branchSelect(branchValue)
      if (selectedBranch.address) {
        handlers.formFieldsUpdate('booking', {
          selectedBranchAddress: { value: { ...selectedBranch.address, name: selectedBranch.name } }
        })
      }
      handlers.formFieldsUpdate('booking', {
        bookingBranch: {
          ...bookingBranch,
          value: branchValue,
          options: branchOptions,
          allOptions: branchOptions
        }
      })
      handlers.bookingLocalTransformationsGet({})
      return
    }
    handlers.formFieldsUpdate('booking', { bookingBranch: { ...bookingBranch, options: branchOptions, allOptions: branchOptions } })
    handlers.bookingFormServicesReady()
  })

payloads$(actions.BOOKINGS_SCHEDULE_FORM_CLEAR)
  .subscribe(() => {
    handlers.formSet('booking', {})
  })

const setBookingFormNextStepErrors = (errors) => {
  handlers.formErrorsSet('booking', errors)
}

payloads$(actions.BOOKING_LOCAL_TRANSFORMATIONS_GET)
  .subscribe(async ({ preserveBookingTime, fields, isRescheduleForm }) => {
    const state = store.getState()
    const { account, forms, services: servicesList, branches } = state
    const { booking } = forms || {}
    const {
      bookingCustomer,
      service,
      duration,
      durationBefore,
      durationAfter,
      color,
      price,
      intervals,
      transformedService: oldTransformedService
    } = booking || {}
    const { filteredService, list: services } = servicesList
    const { value: bookingCustomerValue } = bookingCustomer || {}
    const { selectedId: serviceValue } = service || {}
    const { value: durationValue } = duration || {}
    const { value: durationBeforeValue } = durationBefore || {}
    const { value: durationAfterValue } = durationAfter || {}
    const { values: intervalsValues } = intervals || {}
    const { value: colorValue } = color || {}
    const { value: priceValue } = price || {}
    const { value: oldTransformedServiceValue } = oldTransformedService || {}
    const { region } = account.enterprise || {}
    const bookingBranchId = selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'value' })
    const selectedBranchAddress = selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'selectedBranchAddress', property: 'value' }) || {}
    const { id: selectedBranchAddressId } = selectedBranchAddress || {}
    const companyId = bookingBranchId || selectedBranchAddressId
    const options = {
      globalCustomerId: bookingCustomerValue?.internalId,
      globalServiceId: serviceValue,
      region,
      companyId,
      checkServiceEditedInCompany: true
    }
    const data = await q('getEnterpriseCallCentreLocalTransformations', { options })
    const { service: transformedService, customerFields: transformedCustomerFields } = data || {}
    const { list: branchesList } = branches || {}
    const combinationEvents = []
    const transformedCustomer = customerTransform((data || {}).customer) || {}
    delete transformedCustomer.allowedBranchIds
    delete transformedCustomer.allowedBranchExternalIds

    const dependenciesFields = await globalActions.prepareBookingDependencies({
      isRescheduleForm,
      service: transformedService || oldTransformedService,
      companyId,
      selectedResourceIds: booking.dependencies.selectedResourceIds || booking.resourceIds?.values
    }) || {}

    handlers.formFieldsUpdate('booking', {
      transformedCustomer: { value: transformedCustomer },
      transformedService: {
        value: {
          bookingDate: preserveBookingTime ? oldTransformedServiceValue.bookingDate : null,
          bookingTime: preserveBookingTime ? oldTransformedServiceValue.bookingTime : null,
          rescheduledEventId: preserveBookingTime ? oldTransformedServiceValue.rescheduledEventId : null,
          rescheduledSecret: preserveBookingTime ? oldTransformedServiceValue.rescheduledSecret : null,
          combinationEvents,
          ...oldTransformedServiceValue,
          ...transformedService
        }
      },
      ...dependenciesFields
    })

    const existService = [...(filteredService || services)].find(({ id }) => id === service.selectedId)
    const existBranch = branchesList.find(({ id }) => id === bookingBranchId)
    if (!existService && !oldTransformedServiceValue.rescheduledSecret) {
      handlers.formFieldsUpdate('booking', {
        progress: { value: 1 }
      })
    } else if (!existBranch && !isRescheduleForm) {
      handlers.formFieldsUpdate('booking', { progress: { value: 2 } })
    } else if (!transformedService || (transformedService && !transformedService.isStalled)) handlers.formFieldsUpdate('booking', { progress: { value: 3 } })
    if (transformedCustomer) {
      handlers.formFieldsUpdate('booking', {
        bookingCustomer: {
          ...(bookingCustomer || {}),
          value: {
            ...(bookingCustomerValue || {}),
            ...transformedCustomer
          }
        }
      })
    }
    if (transformedService) {
      handlers.formFieldsUpdate('booking', {
        duration: {
          ...(duration || {}),
          value: (transformedService.duration || transformedService.duration === 0) ? transformedService.duration : durationValue
        },
        durationBefore: {
          ...(durationBefore || {}),
          value: (transformedService.durationBefore || transformedService.durationBefore === 0) ? transformedService.durationBefore : durationBeforeValue
        },
        durationAfter: {
          ...(durationAfter || {}),
          value: (transformedService.durationAfter || transformedService.durationAfter === 0) ? transformedService.durationAfter : durationAfterValue
        },
        color: {
          ...(color || {}),
          value: transformedService.color || colorValue
        },
        price: {
          ...(price || {}),
          value: (transformedService.price || transformedService.price === 0) ? transformedService.price : priceValue
        },
        intervals: {
          ...(intervals || {}),
          values: transformedService.durationsPattern || intervalsValues
        }

      })
    }
    if (transformedCustomerFields && transformedCustomerFields.length > 0) {
      const categories = transformedCustomerFields.reduce((acc, item) => {
        if (!item || !item.category) return [...acc]
        if (acc.find(category => category.id === item.category.id)) return [...acc]
        return [...acc, item.category]
      }, [])
      handlers.customerFieldsListPopulate({
        customerFields: transformedCustomerFields || [],
        customerFieldCategories: categories || []
      })
    }
    handlers.bookingFormServicesReady()
    handlers.bookingLocalTransformationsReady()
    let customerFieldsWithValues = globalActions.prepareCustomerFieldsForForm()
    if (fields) {
      const parsedSelectedEventFields = globalActions.parseCustomerFieldsValues(fields)
      customerFieldsWithValues = globalActions.prepareBookingFieldsWithValues(parsedSelectedEventFields)
    }
    const filteredCustomerFieldsWithValues = customerFieldsWithValues.filter(item => item.isActive && !item.hasOverwrite)
    handlers.formFieldsUpdate('booking', {
      ...globalActions.prepareCustomerFieldsFormValue(filteredCustomerFieldsWithValues, booking, companyId)
    })
  })

payloads$(actions.BOOKING_FORM_CANCEL)
  .subscribe(async ({ booking, scrollToError }) => {
    const state = store.getState()
    const { customers } = state
    const { selected: selectedCustomer } = customers || {}
    const { upcomingEvents } = selectedCustomer || {}

    const fieldsToBeDeleted = Object.keys(booking).filter(key => key.includes('bookingForDeletion') && !!booking[key].value).map(key => key.replace('bookingForDeletion', ''))
    const events = ((upcomingEvents || {}).list || []).filter(({ id }) => fieldsToBeDeleted.includes(id))

    const { hasNotifyResources, hasNotifyCustomers, notes, password } = booking

    const errors = bookingCancelFormValidate(booking)
    if (errors && errors.length) {
      setBookingFormSaveErrors(errors, scrollToError)
      return
    }

    const canceledBookings = await q('deleteEnterpriseCallCentreBranchCompanyEvents', {
      idsMap: events.map(({ id, companyId, region }) => ({
        eventId: id,
        companyId,
        region
      })),
      reason: notes && notes.value ? notes.value : '',
      password: password && password.value ? password.value : '',
      hasNotifyCustomers: hasNotifyCustomers && hasNotifyCustomers.value,
      hasNotifyResources: hasNotifyResources && hasNotifyResources.value
    })

    const { error } = canceledBookings || { error: {} }

    if (error) {
      setBookingFormSaveErrors(bookingFormServerErrorsTransform(error, []), scrollToError)
      return
    }

    handlers.bookingFormReady()
    handlers.bookingFormContainerSet({})

    handlers.formFieldsUpdate('booking', { savedBookings: { values: events } })
    setTimeout(() => {
      handlers.formFieldsUpdate('booking', { progress: { value: 2 } })
    }, 100)
  })

// Form Customer search value change
store$
  .pipe(
    map(state => state.forms.booking.search && state.forms.booking.search.value),
    distinctUntilChanged()
  ).subscribe(async (search) => {
    if (!search) {
      handlers.customersListPopulate([])
      handlers.formFieldsUpdate('booking', { showResults: { value: false } })
    }
  })

// Form customer create new change
store$
  .pipe(
    map(state => state.forms.booking.isCustomerAdd && state.forms.booking.isCustomerAdd.value),
    distinctUntilChanged()
  ).subscribe(async (isCustomerAdd) => {
    if (isCustomerAdd) {
      handlers.customersListPopulate([])
      handlers.formFieldsUpdate('booking', {
        showResults: { value: false },
        search: { value: '' }
      })
    }
  })

// Form customer selected
store$
  .pipe(
    map(state => state.forms.booking.bookingCustomer && state.forms.booking.bookingCustomer.value && state.forms.booking.bookingCustomer.value.id),
    distinctUntilChanged()
  ).subscribe(async (id) => {
    if (id) {
      handlers.customersListPopulate([])
      handlers.formFieldsUpdate('booking', {
        showResults: { value: false },
        search: { value: '' }
      })
    }
  })

// Form progress change
store$
  .pipe(
    map(state => state.forms.booking.progress && state.forms.booking.progress.value),
    distinctUntilChanged()
  ).subscribe(async (progress) => {
    if (progress) {
      const state = store.getState()
      const { forms } = state || {}
      const { booking } = forms || {}
      const { accordionRefs } = booking || {}
      const { value: accordionRefsValue } = accordionRefs || {}

      Object.keys(accordionRefsValue || {}).forEach(index => {
        if (parseInt(index, 10) < progress) {
          const accordionState = (accordionRefsValue[index] && accordionRefsValue[index].state) || {}
          if (accordionState.expand) accordionState.toggle()
        }
      })
    }
  })

// Form bookingBranch change
store$
  .pipe(
    map(state => state.forms.booking.bookingBranch && state.forms.booking.bookingBranch.value),
    distinctUntilChanged()
  ).subscribe((bookingBranch) => {
    const state = store.getState()
    const { forms, settings } = state || {}
    const { allowBookingWithinWorkingTimes } = settings || {}
    const { booking } = forms || {}
    const { progress } = booking || {}
    const { value: progressValue } = progress || {}

    if (progressValue >= 2) {
      handlers.formFieldsUpdate('booking', {
        progress: { value: 2 },
        allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
        from: { value: null },
        timeIntervalMorning: { value: null },
        // keepExistingResource: { value: true },
        timeIntervalNoon: { value: null },
        timeIntervalAfternoon: { value: null },
        timeIntervalEvening: { value: null },
        availabilityDate: { value: null },
        availableSlots: [],
        slots: {},
        notes: { value: '' }
      })
    }
  })

// Form dependencies change
store$
  .pipe(
    map(state => JSON.stringify(
      Object.entries(state.forms?.booking || {})
        .filter(([key]) => key.includes('dependencies'))
        .flatMap(([_, field]) => field?.values).filter(Boolean)
    )),
    distinctUntilChanged()
  ).subscribe((bookingBranch) => {
    const state = store.getState()
    const { forms } = state || {}
    const { booking } = forms || {}
    const { progress } = booking || {}
    const { value: progressValue } = progress || {}

    if (progressValue >= 3) {
      handlers.formFieldsUpdate('booking', {
        progress: { value: 3 },
        availabilityDate: { value: null },
        availableSlots: [],
        slots: {},
        notes: { value: '' }
      })
    }
  })

// Form customerAddress change
store$
  .pipe(
    map(state => (state.forms.booking.customerAddress && state.forms.booking.customerAddress.data && state.forms.booking.customerAddress.data.formattedAddress) || ''),
    distinctUntilChanged()
  ).subscribe(formattedAddress => {
    if (!formattedAddress) return
    const state = store.getState()
    const { settings, forms } = state || {}
    const { allowBookingWithinWorkingTimes } = settings || {}
    const { booking } = forms || {}
    let { bookingBranch } = booking || {}
    bookingBranch = bookingBranch || {}

    handlers.formFieldsUpdate('booking', {
      progress: { value: 2 },
      allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
      from: { value: null },
      timeIntervalMorning: { value: null },
      // keepExistingResource: { value: true },
      timeIntervalNoon: { value: null },
      timeIntervalAfternoon: { value: null },
      timeIntervalEvening: { value: null },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      notes: { value: '' }
    })

    if (bookingBranch.value) {
      handlers.formFieldsUpdate('booking', {
        bookingBranch: { ...bookingBranch, value: '' }
      })
    }
  })

// Form from change
store$
  .pipe(
    map(state => state.forms.booking.from && state.forms.booking.from.value),
    distinctUntilChanged()
  ).subscribe(from => {
    if (!from) return
    handlers.formFieldsUpdate('booking', {
      progress: { value: 3 },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      notes: { value: '' }
    })
  })

// Form slots change
store$
  .pipe(
    map(state => state.forms.booking.slots && state.forms.booking.slots.values),
    distinctUntilChanged()
  ).subscribe(slots => {
    if (!slots || (slots && slots.length > 0)) return
    handlers.formFieldsUpdate('booking', {
      progress: { value: 4 },
      notes: { value: '' }
    })
  })

// Form availability date change
store$
  .pipe(
    map(state => state.forms.booking.availabilityDate ? state.forms.booking.availabilityDate.value || '' : ''),
    distinctUntilChanged()
  ).subscribe(async availabilityDate => {
    const { forms } = store.getState()
    const { booking } = forms || {}
    const { progress } = booking || {}
    if (!progress || progress.value < 4) return
    handlers.bookingFormAvailabilityGet(availabilityDate)
  })

// Form service change
store$
  .pipe(
    map(state => state.forms.booking.service ? `${state.forms.booking.service.value}${state.forms.booking.service.selectedId}` : ''),
    distinctUntilChanged()
  ).subscribe(async () => {
    const state = store.getState()
    const { router, bookings, services } = state || {}
    const { name: routeName } = router || {}
    const servicesList = services.list || []
    if (!(routeName || '').includes('Booking')) return
    const { pendingForm } = bookings || {}
    if (pendingForm) return
    const selectedResources = (state.forms.booking.dependencies && state.forms.booking.dependencies.values) || []
    const bookingForm = state.bookings.form || {}
    let selectedResourceIds = selectedResources
    if (selectedResourceIds.length === 0 && bookingForm.resourceId) selectedResourceIds.push(bookingForm.resourceId)
    const { service: serviceFromForm = {} } = state.forms.booking
    const serviceId = serviceFromForm.selectedId

    let service = null
    if (serviceId) {
      if (bookingForm.resourceId) selectedResourceIds = [bookingForm.resourceId]
      // Service
      service = servicesList.find(item => serviceFromForm.selectedId === item.id)
    }
    if (!service) service = state.forms.booking.transformedService?.value || {}
    const booking = {
      dependencies: {
        service
      },
      step: {
        value: 1
      }
    }
    if (serviceId) {
      booking.color = { value: service.color || DEFAULT_SERVICE_HEXCOLOR }
      booking.duration = { value: service.duration }
      booking.durationBefore = { value: service.durationBefore || 0 }
      booking.durationAfter = { value: service.durationAfter || 0 }
      booking.intervals = { values: service.durationsPattern && service.durationsPattern.length > 2 ? service.durationsPattern : [0, 0, 0] }
      booking.splitDurationInIntervals = { value: service.isBatch }
      booking.price = { value: (service.price && service.price.toFixed(2)) || '' }
      if (service.type === 'course') {
        booking.maxParticipants = { value: service.maxParticipants || '' }
        booking.extraPersonsPerParticipant = { value: service.extraPersonsPerParticipant || '' }
        booking.isBookable = { value: !!service.isBookable }
        booking.hasOnlinePayment = { value: !!service.hasOnlinePayment }
        booking.isOnlinePaymentMandatory = { value: !!service.isPaymentMandatory }
      }
      if (service.isCombination) {
        const combinationServiceIds = service.combinationServiceIds || []
        const services = selectors.servicesListSelector(state) || []
        const filteredServices = services.filter(item => combinationServiceIds.includes(item.id))
        filteredServices.forEach(item => {
          booking[`duration${item.id}`] = { value: item.duration || 0 }
          booking[`dependencies${item.id}`] = {
            values: [],
            resources: [],
            categories: [],
            service: item
          }
        })
      }
      setTimeout(() => handlers.formFieldsUpdate('booking', booking), 0)
    }
  })

store$
  .pipe(
    map(state => ({
      duration: state.forms.booking.duration,
      durationBefore: state.forms.booking.durationBefore,
      durationAfter: state.forms.booking.durationAfter,
      routeName: state.router.name,
      intervals: state.forms.booking.intervals,
      progress: state.forms.booking.progress ? state.forms.booking.progress.value || 0 : 0,
      slots: state.forms.booking.slots ? state.forms.booking.slots.values || [] : [],
      splitDurationInIntervals: state.forms.booking.splitDurationInIntervals ? state.forms.booking.splitDurationInIntervals.value || false : false,
      availabilityDate: state.forms.booking.availabilityDate ? state.forms.booking.availabilityDate.value || '' : ''
    })),
    distinctUntilChanged((prev, curr) => {
      const durationChanged = ['duration', 'durationBefore', 'durationAfter'].reduce((acc, field) => acc && (prev[field] ? prev[field].value === curr[field].value : false), true)
      const intervalsChanged = prev.intervals ? JSON.stringify(prev.intervals.values) === JSON.stringify(curr.intervals.values) : false
      return durationChanged && intervalsChanged
    })
  )
  .subscribe(async (data) => {
    const { routeName, progress, availabilityDate, intervals, splitDurationInIntervals } = data || {}
    if (!(routeName || '').includes('Booking') || !progress || progress < 4 || !availabilityDate || (splitDurationInIntervals && intervals.values.includes(0))) return
    handlers.formFieldsUpdate('booking', {
      slots: {
        availableSlots: [],
        values: []
      }
    })
    handlers.bookingFormAvailabilityGet(availabilityDate)
  })
