import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store, selectors } from '../../Store'
import { Loader, t, isRouteDisabledByCustomisations } from '../../Common'
import { AccountForm } from '../../Beauties'

const AccountEditWrapper = props => {
  let { pending, errors } = props
  pending = !!pending
  errors = errors || []

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.accountFormGet()
  }, [])

  const onSubmit = (cb) => {
    const form = store.getState().forms.account
    handlers.accountFormEditSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <AccountForm
          title={t('user.edit.language.heading')}
          cancelLink='/user'
          errors={errors}
          onSubmit={onSubmit}
        />
      )}
    </>
  )
}

AccountEditWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => ({
  errors: selectors.accountFieldSelector(state, { field: 'errors' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingForm' })
})

export default connect(maps)(AccountEditWrapper)
