import React from 'react'
import { BookingFormDataFieldsWrapper, BookingFormNotifyWrapper } from '../../../Beasts'
import { Button, Row, Col, t, Input, Title, FontAwesome5 } from '../../../Common'
import './BookingForm.css'

const BookingFormData = ({
  progress,
  isScheduleForm,
  form,
  onSubmit,
  togglePasswordFieldType,
  passwordFieldType
}) => (
  <Row>
    <Col>
      <BookingFormDataFieldsWrapper />
      <BookingFormNotifyWrapper type='add' />
      {!isScheduleForm && (
        <Row>
          <Col>
            <Title isCompact label={t('global.confirmation')} />
            <Row>
              <Col className='relative'>
                <Input
                  name='password'
                  dataTestid='password'
                  label={t('global.password')}
                  placeholder={t('global.password.placeholder')}
                  mandatory
                />
                <div className='ta-login__password-type-toggle-btn' onClick={togglePasswordFieldType}>
                  <FontAwesome5 icon={passwordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Button block primary type='button' onClick={onSubmit}>{t('bookingData.confirmBooking.label')}</Button>
    </Col>
  </Row>
)

export default BookingFormData
