import React from 'react'
import PropTypes from 'prop-types'

const FormGroup = props => {
  const {
    children,
    focused,
    filled,
    disabled,
    className,
    hasError,
    labelText,
    labelMandatory
  } = props
  const classNames = ['ta-form-group']
  if (className) classNames.push(className)
  if (focused) classNames.push('focused')
  if (filled) classNames.push('filled')
  if (disabled) classNames.push('disabled')
  if (hasError) classNames.push('hasError')

  return (
    <div className={classNames.join(' ')}>
      {labelText &&
        <label>
          {labelText}
          {labelMandatory && ' *'}
        </label>
      }
      {children}
    </div>
  )
}

FormGroup.propTypes = {
  focused: PropTypes.bool,
  filled: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.string,
  labelMandatory: PropTypes.bool
}

export default FormGroup
