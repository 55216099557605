import { payloads$, actions, handlers } from '../../../Store'
import { q } from '../../API'

payloads$(actions.SERVICE_LIST_GET)
  .subscribe(async ({ id, allowedBranchIds }) => {
    let services
    allowedBranchIds = allowedBranchIds || []
    const filter = allowedBranchIds.length !== 0 ? { allowedCompanyIds: allowedBranchIds } : undefined
    services = await q('getEnterpriseCallCentreServices', { filter })
    services = (services || []).filter(item => !item.isStalled)
    const serviceCategories = services
      .map(item => item.category)
      .reduce((acc, item) => item && acc.find(category => (category || {}).id === item.id) ? [...acc] : [...acc, item], [])
      .filter(Boolean)
    handlers.servicesListPopulate({ services, serviceCategories })
    handlers.servicesListReady()
  })

// Global actions

// globalActions.transformService = service => {
//   return serviceTransform(service)
// }

// // Subscription
// payloads$(actions.SERVICES_SUBSCRIPTION_SET)
//   .subscribe(async ({ name, id, data, ts }) => {
//     if (name === 'servicesMoved' && data) servicesMoved(data)
//     if (name === 'serviceUpdated' && id) serviceUpdatedCollect(id)
//     if (name === 'serviceDeleted' && id) serviceDeleted(id)
//     if (name === 'serviceCategoriesMoved' && data) serviceCategoriesMoved(data)
//     if (name === 'serviceCategoryUpdated') {
//       const serviceCategory = (await q('getServiceCategory', { id })) || {}
//       if (!serviceCategory.id || serviceCategory.error) return
//       serviceCategoryUpdated(serviceCategory)
//     }
//     if (name === 'serviceCategoryDeleted' && id) serviceCategoryDeleted(id)
//   })

// payloads$(actions.SERVICES_UPDATED)
//   .subscribe(async (ids) => {
//     if (!ids.length) return
//     const services = (await q('getServices', { filter: { ids } })) || []
//     services.forEach(service => serviceUpdated(service))
//   })

// // Preview
// payloads$(actions.SERVICE_PREVIEW_GET)
//   .subscribe(async ({ id, forceFetch = false }) => {
//     if (!id) {
//       handlers.navigateToPath('/management/services')
//       return
//     }
//     const state = store.getState()
//     const selectedService = selectors.serviceByIdSelector(state, { id }) || {}
//     const { isComplete } = selectedService || {}
//     if (isComplete && !forceFetch) {
//       handlers.servicePreviewPopulate(id)
//       return
//     }
//     const service = (await q('getService', { id })) || { error: { text: 'errors.api.unavailable' } }
//     const { error } = service
//     if (error) {
//       handlers.servicePreviewMessageSet(servicePreviewErrorTransform(error))
//       return
//     }
//     handlers.serviceUpdate({ ...service, isComplete: true })
//     handlers.servicePreviewPopulate(id)
//   })

// // Form
// payloads$(actions.SERVICE_FORM_GET)
//   .subscribe(async id => {
//     const state = store.getState()
//     const query = selectors.routerFieldSelector(state, { field: 'query' }) || {}
//     const categoriesList = selectors.servicesCategoriesListSelector(state) || []
//     const { settings: companySettings } = selectors.companySelector(state)
//     const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
//     const defaultCategory = categoriesList.find(category => category.isDefault)
//     let category = queryCategory || defaultCategory
//     category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
//     if (!id) {
//       handlers.serviceFormPopulate({
//         category,
//         availableResources: selectors.resourcesListSelector(state),
//         availableCategories: selectors.resourcesCategoriesListSelector(state),
//         companySettings
//       })
//       return
//     }
//     const selectedService = selectors.serviceByIdSelector(state, { id }) || {}
//     const serviceCategory = categoriesList.find(category => category.id === selectedService.categoryId) || null
//     const { isComplete } = selectedService
//     const service = isComplete
//       ? selectedService
//       : serviceTransform((await q('getService', { id })) || { error: { text: 'errors.api.unavailable' } })
//     const { error } = service
//     if (error) {
//       handlers.navigateToPath(id ? `/management/services/${id}` : '/management/services')
//       return
//     }
//     category = serviceCategory || defaultCategory
//     category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
//     handlers.serviceUpdate({ ...service, isComplete: true })
//     handlers.serviceFormPopulate({
//       ...service,
//       category,
//       availableResources: selectors.resourcesListSelector(state),
//       availableCategories: selectors.resourcesCategoriesListSelector(state),
//       plan: selectors.companyPlanSelector(state) || 'CLASSIC',
//       companySettings
//     })
//   })

// // Form auto fill times
// payloads$(actions.FORM_FIELDS_UPDATE)
//   .pipe(
//     filter(fields => (
//       fields.form === 'services' &&
//       Object.keys(fields.fields).length > 0 &&
//       Object.keys(fields.fields)[0].indexOf('WeeklyAllowancePlanDay') !== -1 &&
//       !fields.form.lastSet
//     ))
//   ).subscribe(fields => {
//     const key = Object.keys(fields.fields)[0]
//     const field = fields.fields[key]
//     handlers.formErrorsSet('services', [])

//     if (!field.isActive) return
//     if (field.values.length === 0) return
//     if (field.values[0].from || field.values[0].until) return

//     const form = store.getState().forms.services
//     const lastValues = Object
//       .keys(form)
//       .filter(key => key.indexOf('WeeklyAllowancePlanDay') !== -1)
//       .reduce((acc, item) => {
//         const values = [...(form[item].values || []).map(item => ({ ...item }))] || []
//         if (values[0] && (values[0].until || values[0].from)) {
//           acc = values
//         }
//         return acc
//       }, [])

//     const currentValues =
//       ((
//         (form[key].values && form[key].values[0] && form[key].values[0].until) ||
//         (form[key].values && form[key].values[0] && form[key].values[0].from)
//       ) && form[key].values) || []

//     const newValues =
//       ((
//         field &&
//         field.values &&
//         (field.values[0].from || field.values[0].until)
//       ) && field.values) || []

//     handlers.formFieldsUpdate('services', {
//       lastSet: true,
//       [key]: {
//         isActive: form[key].isActive,
//         values: (newValues.length > 0 && newValues) || (currentValues.length > 0 && currentValues) || (lastValues.length > 0 && lastValues) || []
//       }
//     })
//   })

// // Save
// payloads$(actions.SERVICE_FORM_SAVE)
//   .subscribe(async ({ service, scrollToError }) => {
//     const state = store.getState()
//     const { company, router } = state || {}
//     const services = selectors.servicesListSelector(state) || []
//     const serviceExternalIds = services.map(service => service.externalId).filter(Boolean) || []
//     const { hash } = router || {}
//     let { plan, timezone, locale } = company || {}
//     locale = locale || 'en-gb'
//     const country = selectors.countrySelector(state) || {}
//     const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
//     const { code: currencyCode } = currency || {}
//     service.companyTimezone = timezone
//     const transformedService = serviceSaveTransform(service)
//     if (plan === 'CLASSIC') {
//       delete transformedService.color
//       delete transformedService.externalId
//     }
//     const errors = serviceFormValidate(service, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode, serviceExternalIds)
//     if (errors.length) return setServiceFormSaveErrors(errors, scrollToError)
//     const savedService = await q('saveService', { service: transformedService })
//     const { error, id } = savedService
//     if (error) return setServiceFormSaveErrors(serviceFormServerErrorsTransform(error, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode), scrollToError)
//     handlers.serviceUpdate({ ...savedService, isComplete: true })
//     handlers.navigateToPath(`/management/services/${id}@@${hash}`)
//   })

// const setServiceFormSaveErrors = (errors, scrollToError) => {
//   handlers.formErrorsSet('services', errors)
//   scrollToError && scrollToError(errors)
//   handlers.serviceFormReady()
// }

// // Update
// payloads$(actions.SERVICE_UPDATE)
//   .subscribe(async service => {
//     if (!service) return handlers.servicePreviewPopulate()
//     setTimeout(() => handlers.serviceUpdated(service), 2000)
//   })

// // Delete
// payloads$(actions.SERVICE_DELETE)
//   .subscribe(async id => {
//     if (!id) return handlers.servicePreviewPopulate()
//     const { error } = await q('deleteService', { id })
//     if (error) return
//     handlers.serviceDeleted(id)
//     setTimeout(() => handlers.serviceRemoveDeleted(id), 2000)
//     setTimeout(() => handlers.navigateToPath('/management/services'), 0)
//   })

// // Category form
// payloads$(actions.SERVICE_CATEGORY_FORM_GET)
//   .subscribe(async id => {
//     const { categoriesList } = store.getState().services
//     const selectedCategory = categoriesList.find(category => category.id === id)
//     handlers.serviceCategoryFormPopulate(selectedCategory)
//   })

// // Category save
// payloads$(actions.SERVICE_CATEGORY_FORM_SAVE)
//   .subscribe(async ({ category, scrollToError }) => {
//     const state = store.getState()
//     let { services } = state
//     services = services || {}
//     let { categoriesList } = services
//     categoriesList = categoriesList || []
//     const categoryExternalIds = categoriesList.map(category => category.externalId).filter(Boolean)
//     const errors = serviceCategoryFormValidate(category, categoryExternalIds)
//     if (errors.length) return setServiceCategoryErrors(errors, scrollToError)
//     const savedCategory = await q('saveServiceCategory', { category: serviceCategorySaveTransform(category) })
//     const { error } = savedCategory
//     if (error) return setServiceCategoryErrors(serviceCategoryFormServerErrorsTransform(error), scrollToError)
//     handlers.serviceCategoryUpdate(savedCategory)
//     handlers.navigateToPath('/management/services')
//   })

// const setServiceCategoryErrors = (errors, scrollToError) => {
//   handlers.formErrorsSet('serviceCategory', errors)
//   handlers.serviceCategoryFormReady()
//   scrollToError && scrollToError(errors)
// }

// // Category delete
// payloads$(actions.SERVICE_CATEGORY_DELETE)
//   .subscribe(async id => {
//     if (!id) return
//     const response = await q('deleteServiceCategory', { id }) || { error: { text: 'errors.api.unavailable' } }
//     const { error } = response
//     if (error) return setCategoryErrors(id, serverErrorsTransform(error))
//     handlers.serviceCategoryDeleted(id)
//     setTimeout(() => handlers.serviceCategoryRemoveDeleted(id), 2000)
//   })

// const setCategoryErrors = (id, errors, scrollToError) => {
//   handlers.formErrorsSet(`serviceCategory${id}`, errors)
//   handlers.serviceCategoryDeleteFailed()
//   scrollToError && scrollToError(errors)
//   setTimeout(() => {
//     handlers.formErrorsSet(`serviceCategory${id}`, [])
//   }, 3500)
// }

// // Global category reset
// payloads$(actions.SERVICE_CATEGORY_RESET_GLOBAL)
//   .subscribe(async ({ internalId }) => {
//     if (!internalId) return handlers.serviceCategoryResetGlobalReady()
//     const result = await q('resetGlobalServiceCategory', { internalId })
//     const { errors } = result || { errors: {} }
//     if (errors) return
//     handlers.serviceCategoryResetGlobalReady()
//   })

// // Reset global entity
// payloads$(actions.SERVICE_RESET_GLOBAL)
//   .subscribe(async internalId => {
//     if (!internalId) return handlers.serviceResetGlobalReady()
//     const result = await q('resetGlobalService', { internalId })
//     const { errors } = result || { errors: {} }
//     if (errors) return
//     handlers.serviceResetGlobalReady()
//     handlers.navigateToPath('/management/services')
//   })

// // Reorder save
// payloads$(actions.SERVICES_LIST_REORDER_SAVE)
//   .subscribe(async () => {
//     const state = store.getState()
//     let { reorderList, reorderCategoriesList } = state.services || {}
//     reorderList = reorderList || []
//     reorderCategoriesList = reorderCategoriesList || []
//     const payload = reorderCategoriesList.reduce((acc, categoryItem, categoryIndex) => {
//       const filteredServices = reorderList.filter(serviceItem => serviceItem.categoryId === categoryItem.id) || []
//       if (!categoryItem.isDefault) acc.categories = [...acc.categories, { id: categoryItem.id, orderIndex: categoryIndex + 1 }]
//       acc.services = [
//         ...acc.services,
//         ...(filteredServices.map((serviceItem, serviceIndex) => ({ id: serviceItem.id, categoryId: serviceItem.categoryId, orderIndex: serviceIndex + 1 })))
//       ]
//       return acc
//     }, { services: [], categories: [] })
//     const result = await q('moveServicesAndCategories', payload)
//     const { error } = result || {}
//     if (error) return
//     handlers.servicesListReorderReady(payload)
//     handlers.navigateToPath('/management/services')
//   })

// // Combination Form
// payloads$(actions.SERVICE_COMBINATION_FORM_GET)
//   .subscribe(async id => {
//     const state = store.getState()
//     const query = selectors.routerFieldSelector(state, { field: 'query' }) || {}
//     const list = selectors.servicesListSelector(state) || []
//     const categoriesList = selectors.servicesCategoriesListSelector(state) || []
//     const { settings: companySettings } = selectors.companySelector(state) || {}
//     const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || null
//     const defaultCategory = categoriesList.find(category => category.isDefault)
//     let category = queryCategory || defaultCategory
//     category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
//     const categorizedServices = categoriesList
//       .map(category => ({
//         name: category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name,
//         items: list
//           .filter(item =>
//             item.categoryId === category.id &&
//             item.durationsPattern &&
//             item.durationsPattern.length === 1 &&
//             !item.isCombination
//           )
//           .map(item => ({ label: item.name, value: item.id }))
//       }))
//       .filter(category => category.items.length > 0)
//     if (!id) {
//       handlers.serviceCombinationFormPopulate({
//         category,
//         categorizedServices,
//         companySettings
//       })
//       return
//     }
//     const selectedService = selectors.serviceByIdSelector(state, { id }) || {}
//     const serviceCategory = categoriesList.find(category => category.id === selectedService.categoryId) || null
//     const { isComplete } = selectedService
//     const service = isComplete
//       ? selectedService
//       : serviceTransform((await q('getService', { id })) || { error: { text: 'errors.api.unavailable' } })
//     const { error } = service
//     if (error) {
//       handlers.navigateToPath(id ? `/management/services/${id}` : '/management/services')
//       return
//     }
//     category = serviceCategory || defaultCategory
//     category.name = category.name === 'default' ? t('servicesGroups.list.service.defaultCategoryName') : category.name
//     handlers.serviceUpdate({ ...service, isComplete: true })
//     handlers.serviceCombinationFormPopulate({
//       ...service,
//       category,
//       categorizedServices,
//       companySettings
//     })
//   })

// // Combination Save
// payloads$(actions.SERVICE_COMBINATION_FORM_SAVE)
//   .subscribe(async ({ service, scrollToError }) => {
//     const state = store.getState()
//     const { company } = state || {}
//     const services = selectors.servicesListSelector(state) || []
//     const serviceExternalIds = services.map(service => service.externalId).filter(Boolean) || []
//     const { timezone, locale } = company || {}
//     const country = selectors.countrySelector(state) || {}
//     const { stripeMinAmountOnlinePaymentsCountryCurrency, currency } = country || {}
//     const { code: currencyCode } = currency || {}
//     service.companyTimezone = timezone
//     service.allServices = services
//     const errors = serviceCombinationFormValidate(
//       service,
//       stripeMinAmountOnlinePaymentsCountryCurrency,
//       locale,
//       currencyCode,
//       serviceExternalIds
//     )
//     if (errors.length) return setServiceCombinationFormSaveErrors(errors, scrollToError)
//     const savedService = await q('saveService', { service: serviceCombinationSaveTransform(service) })
//     const { error, id } = savedService
//     if (error) return setServiceCombinationFormSaveErrors(serviceCombinationFormServerErrorsTransform(error, stripeMinAmountOnlinePaymentsCountryCurrency, locale, currencyCode), scrollToError)
//     handlers.serviceUpdate({ ...savedService, isComplete: true })
//     handlers.navigateToPath(`/management/services/${id}`)
//   })

// const setServiceCombinationFormSaveErrors = (errors, scrollToError) => {
//   handlers.formErrorsSet('services', errors)
//   scrollToError && scrollToError(errors)
//   handlers.serviceCombinationFormReady()
// }
