import React from 'react'
import { feedContextInProps } from '../../Utils'
import { HoverPopupContext } from '../../Common'

const HoverPopupTrigger = props => {
  const { children, setIsHover } = props
  const onMouseEnter = () => {
    setIsHover && setIsHover(true)
  }
  const onMouseLeave = () => {
    setIsHover && setIsHover(false)
  }

  return (
    <div className='ta-hover-popup__trigger' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-testid='hover-popup-trigger'>
      {children}
    </div>
  )
}

// TODO: Fix this old context
export default feedContextInProps(HoverPopupTrigger, HoverPopupContext)
