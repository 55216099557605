import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Loader, t, isRouteDisabledByCustomisations } from '../../Common'
import { AccountPasswordChangeForm } from '../../Beauties'

const AccountPasswordChangeWrapper = props => {
  let {
    form,
    pending,
    errors,
    oldPasswordFieldType,
    newPasswordFieldType
  } = props
  pending = !!pending
  form = form || {}
  errors = errors || []
  oldPasswordFieldType = oldPasswordFieldType || 'password'
  newPasswordFieldType = newPasswordFieldType || 'password'

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    handlers.accountFormGet()
  }, [])

  const onSubmit = (cb) => {
    handlers.accountFormPasswordChangeSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const toggleOldPasswordFieldType = () => {
    const oldPassword = {
      value: form.oldPassword.value,
      type: form.oldPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { oldPassword })

    return oldPassword.type
  }

  const toggleNewPasswordFieldType = () => {
    const newPassword = {
      value: form.newPassword.value,
      type: form.newPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { newPassword })

    return newPassword.type
  }

  return (
    <>
      <Loader active={pending} />
      <AccountPasswordChangeForm
        title={t('user.edit.password.heading')}
        cancelLink='/user'
        errors={errors}
        onSubmit={onSubmit}
        pending={pending}
        oldPasswordFieldType={oldPasswordFieldType}
        newPasswordFieldType={newPasswordFieldType}
        toggleOldPasswordFieldType={toggleOldPasswordFieldType}
        toggleNewPasswordFieldType={toggleNewPasswordFieldType}
      />
    </>
  )
}

AccountPasswordChangeWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = (state) => ({
  errors: selectors.accountFieldSelector(state, { field: 'errors' }),
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingForm' }),
  form: selectors.formSelector(state, { formName: 'account' }),
  oldPasswordFieldType: selectors.formFieldPropertySelector(state, { formName: 'account', name: 'oldPassword', property: 'type' }),
  newPasswordFieldType: selectors.formFieldPropertySelector(state, { formName: 'account', name: 'newPassword', property: 'type' })
})

export default connect(maps)(AccountPasswordChangeWrapper)
