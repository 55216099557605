import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error, RichText } from '../../../Common'

import './Textarea.css'

class Textarea extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (newValue) {
    let { form, formName, name, limit, onChange, onChangeAddon, useEditor } = this.props
    form = form || { value: '' }
    let value = (this.ref ? this.ref.value : newValue) || ''
    if (limit && !useEditor && value.length > limit) value = value.substring(0, limit)
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    let {
      label,
      placeholder,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      limit,
      form,
      useEditor,
      className,
      hasResizeHorizontally,
      hasResizeVertically
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-textarea']
    if (limit) classNames.push('ta-textarea__has-limit')
    if (className) classNames.push(className)
    const wrapperClassNames = ['relative', 'ta-form-control', 'ta-textarea__wrapper']
    if (hasResizeHorizontally && !hasResizeVertically) wrapperClassNames.push('ta-textarea__has-resize-horizontally')
    if (hasResizeVertically && !hasResizeHorizontally) wrapperClassNames.push('ta-textarea__has-resize-vertically')
    if (hasResizeVertically && hasResizeHorizontally) wrapperClassNames.push('ta-textarea__has-resize-both')
    const hasError = form.errors && form.errors.length > 0

    return (
      <div ref={wrapper => { this.wrapper = wrapper }}>
        {(useEditor &&
          <RichText
            className='ta-textarea'
            name={name}
            value={form.value}
            placeholder={placeholder || form.placeholder}
            onChange={this.onChange}
            maxLength={limit}
            disabled={disabled || form.disabled}
            label={label || form.label}
            isLabelMandatory={mandatory || form.mandatory}
            hintText={hintText}
            hideError={hideError}
            hasError={hasError}
          />
        )}
        {(!useEditor &&
          <FormGroup
            focused={focused}
            filled={!!form.value}
            disabled={disabled || form.disabled}
            labelText={label || form.label}
            labelMandatory={mandatory || form.mandatory}
          >
            <div className={wrapperClassNames.join(' ')}>
              <textarea
                className='ta-form-control ta-textarea'
                ref={ref => { this.ref = ref }}
                name={name}
                value={form.value}
                placeholder={placeholder || form.placeholder}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                maxLength={limit && limit + 1}
                disabled={disabled || form.disabled}
                autoComplete='off'
              />
              {limit &&
                <div className='ta-textarea__limit'>{(form.value && form.value.length) || 0} / {limit}</div>
              }
            </div>
            {(hintText &&
              <div className='ta-form-control__hint'>{hintText}</div>
            )}
            {(!hideError &&
              <Error name={name} className='ta-clear' />
            )}
          </FormGroup>
        )}
      </div>
    )
  }
}

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  hintText: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  limit: PropTypes.number,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  useEditor: PropTypes.bool,
  hasResizeHorizontally: PropTypes.bool,
  hasResizeVertically: PropTypes.bool
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(Textarea), FormContext)
