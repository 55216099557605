import { BookingFormResourceAvatarsPopupContent } from '../../../Beauties'

const PopupCustomerEventResources = ({ resources, resourceCategories }) => {
  return (
    <div className='ta-popup__customer-event-resources'>
      <BookingFormResourceAvatarsPopupContent resources={resources} resourceCategories={resourceCategories} />
    </div>
  )
}

export default PopupCustomerEventResources
