import React from 'react'

import './SmallTitle.css'

const SmallTitle = props => {
  const { label, className, bold, children } = props
  const classNames = ['ta-small-title']
  if (className) classNames.push(className)
  if (bold) classNames.push('bold')

  return (
    <div className={classNames.join(' ')} data-testid='small-title'>{label || children}</div>
  )
}

export default SmallTitle
