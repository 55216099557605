import React from 'react'
import {
  t
} from '../../../Common'
import { PermissionTypeAccordion } from '../../../Beauties'
import './PermissionTypeSummaryPermissions.css'

const PermissionTypeSummaryPermissions = props => {
  const { permissions } = props
  let {
    customersRead,
    customersWrite,
    usersRead,
    usersWrite,
    usersDelete,
    bookingsDelete,
    bookingsReschedule,
    bookingsSchedule
  } = permissions || {}
  customersRead = !!customersRead
  customersWrite = !!customersWrite
  bookingsReschedule = !!bookingsReschedule
  bookingsSchedule = !!bookingsSchedule
  bookingsDelete = !!bookingsDelete
  usersRead = !!usersRead
  usersWrite = !!usersWrite
  usersDelete = !!usersDelete

  const getPermissionTag = (permission) => {
    return permission
      ? <div className='ta-permission-types__preview__summary__permission__tag ta-permission-types__preview__summary__permission__tag__yes'>{t('global.yes')}</div>
      : <div className='ta-permission-types__preview__summary__permission__tag ta-permission-types__preview__summary__permission__tag__no'>{t('global.no')}</div>
  }

  return (
    <div className='ta-permission-types__preview__summary__permissions'>
      <PermissionTypeAccordion title={t('global.bookings')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('agents.permissions.bookingsSchedule.label')}
          {getPermissionTag(bookingsSchedule)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('agents.permissions.bookingsreschedule.label')}
          {getPermissionTag(bookingsReschedule)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('agents.permissions.bookingsDelete.label')}
          {getPermissionTag(bookingsDelete)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.customers')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(customersRead)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(customersWrite)}
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.agents')}>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.read')}
          {getPermissionTag(usersRead)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.write')}
          {getPermissionTag(usersWrite)}
        </div>
        <div className='ta-permission-types__preview__summary__permission__title'>
          {t('global.delete')}
          {getPermissionTag(usersDelete)}
        </div>
      </PermissionTypeAccordion>
    </div>
  )
}

export default PermissionTypeSummaryPermissions
