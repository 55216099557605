import React from 'react'
import { connect, selectors } from '../../../Store'
import { BookingFormSlotPreferencesHeader } from '../../../Beauties'

const BookingSlotPreferencesHeaderWrapper = ({
  form,
  resources,
  resourceCategories,
  routeName,
  newBookingActivated,
  rescheduleBookingActivated
}) => {
  resources ||= []
  resourceCategories ||= []
  const selectedResourceIds = [...new Set(Object.entries(form).filter(([key]) => key.includes('dependencies')).flatMap(([_, field]) => field.values))]
  const selectedResources = selectedResourceIds.map?.(id => resources.find(item => item.id === id)) || []
  const selectedResourceCategories = resourceCategories.filter(({ resources }) => selectedResourceIds?.some(id => resources.map(item => item.id).includes(id)))
  const isRescheduleForm = routeName === 'rescheduleBooking'
  const isResourceSelectorEnabled = isRescheduleForm ? rescheduleBookingActivated : newBookingActivated

  return (
    <BookingFormSlotPreferencesHeader
      form={form}
      selectedResources={selectedResources}
      selectedResourceCategories={selectedResourceCategories}
      isResourceSelectorEnabled={isResourceSelectorEnabled}
    />
  )
}

const maps = state => ({
  form: selectors.formSelector(state, { formName: 'booking' }),
  resources: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resources', property: 'values' }),
  resourceCategories: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resourceCategories', property: 'values' }),
  routeName: selectors.routerFieldSelector(state, { field: 'name' }),
  newBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'newBookingActivated' }),
  rescheduleBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'rescheduleBookingActivated' })
})

export default connect(maps)(BookingSlotPreferencesHeaderWrapper)
