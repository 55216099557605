import Route from 'route-parser'
import qs from 'query-string'
const historyLibrary = 'createBrowserHistory'
const createHistory = require('history')[historyLibrary]
export const history = createHistory()

// priority function
const priority = (r1, r2) => {
  if (r1.priority && r2.priority) return (r1.priority || 0) - (r2.priority || 0)
  if (r1.pattern && r1.pattern.indexOf(':') > -1) return 1
  if (r2.pattern && r2.pattern.indexOf(':') > -1) return -1
  return 0
}
let Routes = {}

// general hooks register
export let onBeforeEach
export let onAfterEach
export const registerBeforeEach = (onBeforeEachHandler) => { onBeforeEach = onBeforeEachHandler }
export const registerAfterEach = (onAfterEachHandler) => { onAfterEach = onAfterEachHandler }
export const registerRoute = (name, route) => {
  if (!name) throw new Error('Route must have a name')
  Routes = Routes || {}
  Routes[name] = route && route.pattern
    ? { ...route, name, element: new Route(route.pattern) }
    : { name }
}

// Register default routes
registerRoute('home', { pattern: '/' })
registerRoute('notfound')

// Transforms a route with name, params and query in fullPath
export const getPath = (name, params, query = '') => {
  if (!name) throw new Error('Route name must be specified')
  const myRoute = Routes[name] && Routes[name]
  if (!myRoute) throw new Error(`Route ${name} does not exists`)
  // only not found has no pattern
  if (!myRoute.pattern) return
  const fullPath = myRoute.element.reverse(params)
  if (!fullPath) throw new Error(`Not enough params provided for route ${name}`)
  const qry = query ? (typeof query === 'string' ? query : qs.stringify(query)) : ''
  return fullPath + (qry ? '?' + qry : '')
}

export const getRoute = (fullPathRequested) => {
  let pathWithHash = fullPathRequested.split('@@')
  let path = pathWithHash[0].split('?')
  let hash = pathWithHash[1]
  let fullPath = fullPathRequested
  const query = path[1]
  path = path[0]
  if (path.length > 1 && path[path.length - 1] === '/') {
    path = path.substr(0, path.length - 1)
    fullPath = path + (query ? '?' + query : '')
  }
  const route = Object
    .keys(Routes)
    .map(name => ({ ...Routes[name], params: Routes[name].element && Routes[name].element.match(path) }))
    .sort(priority)
    .find(route => route.element && route.element.match(path)) || Routes.notfound

  return {
    ...route,
    fullPath,
    path,
    hash,
    query: { ...qs.parse(query) }
  }
}
