import React from 'react'
import { feedContextInProps } from '../../Utils'
import { StepperContext } from '../../Common'

const StepperProgressBar = props => {
  const className = ['ta-stepper__progress-bar']

  return (
    <div className={className.join(' ')} data-testid='stepper-progress-bar'>
      Progress Bar
    </div>
  )
}

export default feedContextInProps(StepperProgressBar, StepperContext)
