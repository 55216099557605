import React from 'react'
import {
  Row,
  Col,
  Error,
  t,
  BookingServiceSelect
} from '../../../Common'

const BookingFormService = props => {
  const { services, onChangeAddon, isRescheduleForm } = props

  return (
    <div className='ta-single-booking-form__service'>
      <Row>
        <Col>
          <BookingServiceSelect
            name='service'
            className='ta-single-booking-form__service'
            label={t('booking.form.fieldService.label')}
            options={services}
            hasCategories
            onChangeAddon={onChangeAddon}
            searchable
            disabled={isRescheduleForm}
          />
          <Error name='serviceExtraError' />
        </Col>
      </Row>
    </div>
  )
}

export default BookingFormService
