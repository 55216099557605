import React from 'react'
import { connect, selectors } from '../../Store'
import { BookingFormServiceCombination } from '../../Beauties'

const BookingFormServiceCombinationWrapper = props => {
  let {
    form,
    selectedService,
    services,
    formDurationBefore,
    formDurationAfter,
    color
  } = props
  form = form || {}
  services = services || []
  formDurationAfter = formDurationAfter || {}
  formDurationBefore = formDurationBefore || {}

  let { combinationServiceIds, combinationEvents } = selectedService || {}
  combinationServiceIds = combinationServiceIds || []
  const filteredServices = combinationEvents && combinationEvents.length > 0
    ? combinationEvents
    : combinationServiceIds.map(item => services.find(service => service.id === item)).filter(Boolean)

  return (
    <BookingFormServiceCombination
      services={filteredServices}
      formDurationBefore={formDurationBefore}
      formDurationAfter={formDurationAfter}
      form={form}
      color={color}
      collapsedFormSections={[]}
    />
  )
}

const maps = state => ({
  services: selectors.servicesListSelector(state),
  form: selectors.formSelector(state, { formName: 'booking' }),
  formDurationBefore: selectors.formFieldSelector(state, { formName: 'booking', name: 'durationBefore' }),
  formDurationAfter: selectors.formFieldSelector(state, { formName: 'booking', name: 'durationAfter' }),
  color: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'color', property: 'value' })
})

export default connect(maps)(BookingFormServiceCombinationWrapper)
