import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Popup.css'

class Popup extends Component {
  constructor (props) {
    super(props)

    this.getStyles = this.getStyles.bind(this)
  }

  getStyles () {
    const { position, trigger } = this.props
    const styles = {}
    const triggerProps = trigger ? trigger.getBoundingClientRect() : null
    const contentProps = this.content ? this.content.getBoundingClientRect() : null
    if (position === 'left' && triggerProps && contentProps) {
      styles.top = triggerProps.top + (triggerProps.height / 2)
      styles.left = triggerProps.left - contentProps.width - 20
    }
    if (position === 'right' && triggerProps) {
      styles.top = triggerProps.top + (triggerProps.height / 2)
      styles.left = triggerProps.left + triggerProps.width
    }
    if (position === 'top' && triggerProps && contentProps) {
      styles.bottom = (-1 * (triggerProps.top - window.innerHeight)) + 10
      styles.left = triggerProps.left + (triggerProps.width / 2) - (contentProps.width / 2) - 10
    }
    if (position === 'top-left' && triggerProps && contentProps) {
      styles.bottom = (-1 * (triggerProps.bottom - window.innerHeight)) - 10
      styles.left = triggerProps.left - triggerProps.width - contentProps.width + 10
    }
    if (position === 'bottom' && triggerProps && contentProps) {
      styles.top = triggerProps.bottom + 10
      styles.left = triggerProps.left + (triggerProps.width / 2) - (contentProps.width / 2) - 10
    }
    return styles
  }

  render () {
    const {
      status,
      position,
      closePopup,
      extraClassName,
      children
    } = this.props

    const classNames = ['ta-popup']
    if (status) classNames.push('active')

    const contentClassNames = ['ta-popup__content']
    if (position) contentClassNames.push(position)
    if (extraClassName) contentClassNames.push(extraClassName)

    return (
      <div className={classNames.join(' ')} onClick={closePopup}>
        <div
          ref={content => { this.content = content }}
          className={contentClassNames.join(' ')}
          style={this.getStyles()}
          onClick={(e) => e.stopPropagation()}
        >
          <div className='ta-popup__content-inner'>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

Popup.propTypes = {
  status: PropTypes.bool,
  position: PropTypes.string,
  closePopup: PropTypes.func.isRequired,
  extraClassName: PropTypes.string
}

export default Popup
