import { Avatar, t } from '../../../Common'

const BookingFormResourceAvatarsPopupContent = ({ resources, resourceCategories }) => {
  return (
    <div className='ta-booking-form__success__event__resources-hover-list'>
      {resourceCategories && resourceCategories.map((category, categoryIndex) => {
        const resourceByCategory = resources && resources.filter(item => item.categoryId === category.id)
        return resourceByCategory.length > 0 && (
          <div key={categoryIndex} className='ta-booking-form__success__event__resources-hover-list__category'>
            <div className='ta-booking-form__success__event__resources-hover-list__sub-title'>
              {category.name === 'default' ? t('resources.list.sectionDefault.title') : category.name}:
            </div>
            {resourceByCategory && resourceByCategory.map((resource, resourceIndex) => (
              <div key={resourceIndex} className='ta-booking-form__success__event__resources-hover'>
                <Avatar
                  name={resource?.name}
                  image={resource?.avatarUrl}
                  color={resource?.color}
                  abbreviation={resource?.abbreviation}
                />
                <div className='ta-booking-form__success__event__resources-hover__label'>{resource?.name}</div>
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default BookingFormResourceAvatarsPopupContent
