// Add here all fields from redux store that will be persisted on localstorage
export default () => state => ({
  app: { locale: state.app.locale },
  auth: { tokens: state.auth.tokens },
  bookings: {},
  forms: {},
  courses: {},
  customerFields: {},
  customers: {},
  navigation: { position: state.navigation.position },
  persist: state.persist,
  services: {},
  tags: {},
  settings: {}
})
