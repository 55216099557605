import React, { useEffect } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { Loader, isRouteDisabledByCustomisations } from '../../Common'
import { Account } from '../../Beauties'

const AccountWrapper = props => {
  let {
    account,
    pending,
    language,
    message,
    pendingAccountSelect,
    hash
  } = props
  account = account || {}
  pendingAccountSelect = !!pendingAccountSelect

  useEffect(() => {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
    }
  }, [])

  const onAccountChange = (id) => {
    handlers.accountSelect(id)
  }

  const onResendEmailClick = ({ accountId, email }) => {
    handlers.accountEmailConfirmationSend({ accountId, email })
  }

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <Account
          account={account}
          hash={hash}
          message={message}
          language={language}
          pendingAccountSelect={pendingAccountSelect}
          onAccountChange={onAccountChange}
          onResendEmailClick={onResendEmailClick}
        />
      )}
    </>
  )
}

const maps = state => ({
  message: selectors.accountFieldSelector(state, { field: 'messagePreview' }),
  account: state.account,
  hash: state.router.hash,
  language: selectors.appLocaleSelector(state),
  pending: selectors.accountFieldSelector(state, { field: 'pendingPreview' }),
  pendingAccountSelect: selectors.accountFieldSelector(state, { field: 'pendingAccountSelect' })
})

export default connect(maps)(AccountWrapper)
