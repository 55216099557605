import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  BorderedBox,
  FormText,
  Row,
  Col,
  Error,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Select,
  t
} from '../../../Common'

import './DependenciesInput.css'

class DependenciesInput extends Component {
  constructor (props, context) {
    super(props)

    this.generateEmptyDependency = this.generateEmptyDependency.bind(this)
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onResourceChange = this.onResourceChange.bind(this)
    this.onWorkInParalelChange = this.onWorkInParalelChange.bind(this)
    this.addDependency = this.addDependency.bind(this)
    this.deleteDependency = this.deleteDependency.bind(this)
    this.renderCategory = this.renderCategory.bind(this)
    this.renderResources = this.renderResources.bind(this)
    this.renderWorkInParale = this.renderWorkInParale.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  generateEmptyDependency () {
    let { categories } = this.props
    categories = categories || []
    return {
      categoryId: categories.length === 1 ? categories[0].value : null,
      resourceIds: null,
      workInParallel: false
    }
  }

  onCategoryChange (selectValue, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    if (!dependencies[index]) return
    dependencies[index].categoryId = (selectValue && selectValue.value) || null
    dependencies[index].resourceIds = []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onResourceChange (selectValues, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    if (!dependencies[index]) return

    dependencies[index].resourceIds = selectValues.length > 0
      ? selectValues[selectValues.length - 1].value === 'all'
        ? ['all']
        : [...selectValues.map(item => item.value).filter(value => value !== 'all')]
      : null

    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  onWorkInParalelChange (value, index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    if (!dependencies[index]) return
    dependencies[index].workInParallel = !!value
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies],
        resources,
        categories,
        errors
      }
    })
  }

  addDependency () {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies, this.generateEmptyDependency()],
        resources,
        categories,
        errors
      }
    })
  }

  deleteDependency (index) {
    let { name, formName, resources, categories, dependencies, errors } = this.props
    resources = resources || []
    categories = categories || []
    dependencies = dependencies || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        values: [...dependencies.filter((item, key) => key !== index)],
        resources,
        categories,
        errors
      }
    })
  }

  // RENDER

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  renderCategory (item, index) {
    let { categories } = this.props
    categories = categories || []
    const { categoryId } = item || {}
    const selectedCategory = categories.find(item => item.value === categoryId) || {}
    const isSelectedCategoryDefault = selectedCategory.label === 'default'
    const isOnlyDefaultCategory = !!(categories.length === 1)
    const isDasabled = isOnlyDefaultCategory && isSelectedCategoryDefault
    const displayCategories = [{ value: 'all', label: t('global.all'), isDependency: false }, ...categories.map((item, index) => {
      return {
        ...item,
        label: (item.label === 'default') ? t('resources.list.sectionDefault.title') : item.label
      }
    })]

    return (
      <div className='ta-dependencies-input__select-container'>
        <div className='ta-dependencies-input__select-container__label'>
          <span>{t('servicesGroups.form.section.resources.fieldResourceCategory.label')}</span>
        </div>
        <Select
          className='ta-single-select'
          noResultsText={t('global.noResults')}
          value={item.categoryId}
          arrowRenderer={this.renderArrows}
          onChange={(selectValue) => this.onCategoryChange(selectValue, index)}
          options={displayCategories}
          searchable={false}
          placeholder={t('servicesGroups.form.section.resources.fieldResourceCategory.placeholder')}
          disabled={isDasabled}
          autoComplete='off'
        />
      </div>
    )
  }

  renderResources (item, index) {
    let { resources, categories } = this.props
    resources = resources || []
    categories = categories || []
    const filteredResources = item.categoryId === 'all'
      ? resources
      : resources.filter(resource => resource.categoryId === item.categoryId)
    const options = [{ value: 'all', label: t('global.all') }, ...filteredResources]
    const category = item.categoryId === 'all'
      ? { value: 'all', label: t('global.all'), isDependency: false }
      : categories.find(category => category.value === item.categoryId) || {}

    return (
      <div className='ta-dependencies-input__select-container'>
        <div className='ta-dependencies-input__select-container__label'>
          <span>{category.label === 'default' ? t('calendar.filter.yourResources.title') : category.label}:</span>
        </div>
        <Select
          inlineTags
          className='ta-single-select'
          noResultsText={t('global.noResults')}
          value={item.resourceIds}
          arrowRenderer={this.renderArrows}
          onChange={(selectValues) => this.onResourceChange(selectValues, index)}
          multi
          searchable
          options={options}
          placeholder={t('servicesGroups.form.section.resources.fieldResource.placeholder')}
          autoComplete='off'
        />
      </div>
    )
  }

  renderWorkInParale (value, index) {
    let { isCourse, plan } = this.props
    plan = plan || 'CLASSIC'
    const yesClassNames = ['ta-radio']
    const noClassNames = ['ta-radio']
    if (plan === 'ENTERPRISE' && value) yesClassNames.push('active')
    if (plan !== 'ENTERPRISE') yesClassNames.push('disabled')
    if (!value) noClassNames.push('active')
    // TODO: Update renderWorkInParale to consider also the plan
    // Make it available only for enterprise users and show enterpriseOnly label

    return (
      <div>
        <FormText>
          {t(isCourse
            ? 'servicesGroups.form.group.section.resources.dependency.options.title'
            : 'servicesGroups.form.service.section.resources.dependency.options.title'
          )}
        </FormText>
        <Row>
          <Col>
            <div className='ta-radio-wrapper'>
              <div className={noClassNames.join(' ')} onClick={() => plan === 'ENTERPRISE' ? this.onWorkInParalelChange(false, index) : undefined}>
                {t(isCourse
                  ? 'servicesGroups.form.group.section.resources.dependency.options.one'
                  : 'servicesGroups.form.service.section.resources.dependency.options.one'
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <HoverPopup disabled={plan === 'ENTERPRISE'}>
              <HoverPopupContent position='top' autoSize>
                {t('global.enterpriseOnly')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className='ta-radio-wrapper'>
                  <div className={yesClassNames.join(' ')} onClick={() => plan === 'ENTERPRISE' ? this.onWorkInParalelChange(true, index) : undefined}>
                    {t(isCourse
                      ? 'servicesGroups.form.group.section.resources.dependency.options.all'
                      : 'servicesGroups.form.service.section.resources.dependency.options.all'
                    )}
                  </div>
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          </Col>
        </Row>
      </div >
    )
  }

  renderButtons (item, index) {
    let { dependencies, plan } = this.props
    plan = plan || 'CLASSIC'
    dependencies = dependencies || []
    // TODO: Update renderButtons to consider also the plan
    // Make "Add new dependency" button inactive for CLASSIC and PREMIUM accounts

    return (
      <>
        <div className='ta-dependencies-input__buttons'>
          {item.resourceIds && item.resourceIds.length > 0 && index === (dependencies.length - 1) &&
            <HoverPopup disabled={plan === 'ENTERPRISE'}>
              <HoverPopupContent position='top' autoSize>
                {t('global.enterpriseOnly')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <div className={`ta-btn ta-btn-primary ${plan !== 'ENTERPRISE' ? ' disabled' : ''}`} onClick={plan === 'ENTERPRISE' ? this.addDependency : undefined}>
                  {t('servicesGroups.form.section.resources.buttonDependency.label')}
                </div>
              </HoverPopupTrigger>
            </HoverPopup>
          }
          <div className='ta-dependencies-input__buttons__btn-delete' onClick={() => this.deleteDependency(index)}>
            <FontAwesome5 icon='trash' type='regular' />
          </div>
        </div>
      </>
    )
  }

  render () {
    let { className, dependencies, errors } = this.props
    errors = errors || []
    dependencies = dependencies || []
    const classNames = ['ta-dependencies-input']
    if (className) classNames.push(className)
    if (dependencies.length === 0) dependencies.push(this.generateEmptyDependency())
    if (dependencies.length === 1) classNames.push('one')

    return (
      <div className={classNames.join(' ')}>
        {dependencies.map((item, index) => (
          <div className='ta-dependencies-input__group' key={index}>
            {this.renderCategory(item, index)}
            {item.categoryId &&
              <>
                <BorderedBox>
                  {this.renderResources(item, index)}
                  {item.resourceIds && (item.resourceIds.length > 1 || (item.resourceIds.length === 1 && item.resourceIds[0] === 'all')) &&
                    this.renderWorkInParale(item.workInParallel, index)
                  }
                </BorderedBox>
              </>
            }
            {errors.length > 0 && errors.map((error, key) => error.index === index &&
              <Error key={key} error={error} />
            )}
            {this.renderButtons(item, index)}
          </div>
        ))}
      </div>
    )
  }
}

const maps = (state, props) => ({
  resources: selectors.formFieldPropertySelector(state, { name: props.name, formName: props.formName, property: 'resources' }),
  categories: selectors.formFieldPropertySelector(state, { name: props.name, formName: props.formName, property: 'categories' }),
  dependencies: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'values' }),
  errors: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'errors' }),
  plan: selectors.companyPlanSelector(state)
})

export default feedContextInProps(connect(maps)(DependenciesInput), FormContext)
