import React from 'react'
import PropTypes from 'prop-types'
import {
  Link,
  Form,
  FormButtons,
  FormSection,
  Error,
  t,
  BorderedBox,
  Row,
  Col,
  SectionTitle,
  MultipleSelect
} from '../../../Common'
import { CustomerFormField } from '../../../Beauties'

import './CustomerForm.css'

const CustomerForm = props => {
  const {
    onSubmit,
    groupedFields,
    availableFields,
    onCancel,
    allowOnlyGlobalCustomers,
    timezones,
    setFileLoading,
    type
  } = props

  return (
    <div className='ta-customers__form'>
      <Form name='customers'>
        <BorderedBox>
          {/* {showExternalIds &&
          <FormSection>
            <SectionTitle label={t('global.externalId')} icon='fingerprint' />
            <Row>
              <Col>
                <Input
                  name='externalId'
                  label={t('global.externalId')}
                  placeholder={t('global.externalId.placeholder')}
                  hintText={t('global.externalId.hint')}
                />
              </Col>
            </Row>
          </FormSection>
          } */}
          {groupedFields.map((item, i) => (
            <div key={`${i}`}>
              {item.fields.map(field => (
                <CustomerFormField
                  key={field.id}
                  field={field}
                  availableFields={availableFields}
                  timezones={timezones}
                  setFileLoading={setFileLoading}
                />
              ))}
            </div>
          ))}
          <FormSection>
            <Error name='globalErrors' noTopOffset />
          </FormSection>
          {!allowOnlyGlobalCustomers && (
            <FormSection className='ta-cleaner'>
              <SectionTitle label='Location' icon='building' />
              <Row>
                <Col>
                  <MultipleSelect
                    name='location'
                    label={t('global.select.label')}
                    placeholder={t('global.select.placeholder')}
                    searchable
                    hasAll
                    allLabel={t('global.allLocations')}
                  />
                </Col>
              </Row>
            </FormSection>
          )}
        </BorderedBox>
        <FormSection className='ta-cleaner'>
          <FormButtons>
            <Link onClick={onSubmit} type='button' className='ta-btn ta-btn-primary'>
              {(type === 'add'
                ? t('buttons.customerForm.add.label')
                : t('buttons.customerForm.edit.label')
              )}
            </Link>
            <Link onClick={onCancel} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
          </FormButtons>
        </FormSection>
      </Form>
    </div>
  )
}

CustomerForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default CustomerForm
