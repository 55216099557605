import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FontAwesome5,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  SimpleTabs,
  SimpleTabsHeader,
  SimpleTabsHeaderItem,
  SimpleTabsBody,
  SimpleTabsBodyItem,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  Alert,
  Online,
  t
} from '../../../Common'
import {
  PopupTriggerWrapper,
  AgentPreviewDetailsWrapper
} from '../../../Beasts'

import './AgentPreview.css'
import { getAgentsPermissions } from '../../../Utils'

const AgentPreview = props => {
  const {
    agent,
    hash,
    userId,
    rules,
    isOwner,
    isCustom,
    message
  } = props
  const availableTabs = ['details', 'services']
  const activeTab = availableTabs.indexOf(hash) > -1 ? hash : availableTabs[0]
  const { id, name } = agent || {}
  const { showEditButton, showDeleteButton } = getAgentsPermissions({ isOwner, isCustom, ...rules }, agent, userId)

  return (
    <div className='ta-agents__preview'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-agents__title'>
            <Title label={name || 'Agent details'}>
              {(id &&
                <>
                  {(showDeleteButton &&
                    <PopupTriggerWrapper name='agents-delete' position='bottom' id={id}>
                      <HoverPopup>
                        <HoverPopupContent position='left' autoSize>
                          {t('agents.buttonHeading.deleteTooltip')}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <Online>
                            <Link className='ta-btn ta-btn-delete ta-btn-icon-only' external>
                              <FontAwesome5 icon='trash' />
                            </Link>
                          </Online>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    </PopupTriggerWrapper>
                  )}
                  {(showEditButton &&
                    <HoverPopup>
                      <HoverPopupContent position='left' autoSize>
                        {t('agents.buttonHeading.editTooltip')}
                      </HoverPopupContent>
                      <HoverPopupTrigger>
                        <Online>
                          <Link
                            to={`/agents/${id}/edit`}
                            className='ta-btn ta-btn-primary ta-btn-icon-only'
                          >
                            <FontAwesome5 icon='pencil' />
                          </Link>
                        </Online>
                      </HoverPopupTrigger>
                    </HoverPopup>
                  )}
                </>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          {message && (
            <Alert theme={message.type} label={t('errors.somethingWentWrong')}>
              {message.text && t(message.text)}
            </Alert>
          )}
          {(id && !message &&
            <SimpleTabs active={activeTab} scrollableContent>
              <SimpleTabsHeader>
                <SimpleTabsHeaderItem name='details' icon='user' label={t('global.details')} />
              </SimpleTabsHeader>
              <SimpleTabsBody>
                <SimpleTabsBodyItem name='details'>
                  <AgentPreviewDetailsWrapper agent={agent} />
                </SimpleTabsBodyItem>
              </SimpleTabsBody>
            </SimpleTabs>
          )}
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

AgentPreview.propTypes = {
  id: PropTypes.string
}

export default AgentPreview
