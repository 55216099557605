import React from 'react'
import { Checkbox, Col, Row, t, Title } from '../../../Common'

const BookingFormNotify = props => {
  return (
    <div className='ta-single-booking-form__notify'>
      <Title isCompact label={t('global.notifications')} />
      <Row>
        <Col>
          <Checkbox
            name='hasNotifyResources'
            label={t('calendar.form.checkboxEmailResource.label')}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Checkbox
            name='hasNotifyCustomers'
            label={t('calendar.form.checkboxEmailParticipants.label')}
          />
        </Col>
      </Row>
    </div>
  )
}

export default BookingFormNotify
