import React from 'react'
import { connect, selectors } from '../../Store'
import { GoogleMaps } from '../../Common'
import './MapsWrapper.css'
import OverlayWrapper from '../Overlay/OverlayWrapper'
import { mapStyles, polylineStyles, getCircleStyles } from './styles'

const GoogleMapsWrapper = ({
  customerAddress,
  customerAddressRadius,
  selectedBranchAddress,
  addresses,
  sidebar,
  locale,
  ...props
}) => {
  const { position } = sidebar || {}
  customerAddressRadius = parseInt(customerAddressRadius, 10)

  return (
    <OverlayWrapper>
      <GoogleMaps
        options={{
          styles: mapStyles,
          extraStyles: { polylineStyles, circleStyles: getCircleStyles(customerAddressRadius) },
          disableDefaultUI: true
        }}
        isMarkerShown
        locale={locale}
        markers={addresses}
        sidebarPosition={position}
        customerAddress={customerAddress}
        customerAddressRadius={customerAddressRadius}
        selectedBranchAddress={selectedBranchAddress}
      />
    </OverlayWrapper>
  )
}

const maps = state => {
  const locales = (state.staticData.locales || []).reduce((acc, locale) => ({ ...acc, [locale.code.toLowerCase()]: (locale.country || {}).label }), {})
  const account = selectors.accountSelector(state)
  const locale = locales[account && account.enterprise && account.enterprise.locale]
  const addresses = (state.branches.list || [])
    .filter(({ address }) => address && address.latitude && address.longitude)
    .map(({ name, address }) => ({
      position: {
        lat: address.latitude,
        lng: address.longitude
      },
      desc: address.formatted,
      title: name
    }))

  return {
    account,
    locale,
    forms: state.forms,
    router: state.router,
    addresses,
    selectedBranchAddress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'selectedBranchAddress', property: 'value' }),
    customerAddress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'customerAddress', property: 'data' }),
    customerAddressRadius: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'customerAddressRadius', property: 'value' }),
    app: selectors.appSelector(state)
  }
}

export default connect(maps)(GoogleMapsWrapper)
