import React, { useEffect } from 'react'
import { BookingFormData } from '../../../Beauties'
import { Loader } from '../../../Common'
import { globalActions, handlers, selectors, connect } from '../../../Store'

const BookingDataWrapper = ({ progress, isCancellationForm, pending, form, isScheduleForm, passwordFieldType }) => {
  useEffect(() => {
    onLoad()
    // eslint-disable-next-line
  }, [])
  const onLoad = () => {
    globalActions.populateTags()
  }
  const onSubmit = () => {
    form.type = 'singleBooking'
    if (isCancellationForm) {
      handlers.bookingFormCancel(form, errors => {
        if (!errors) {
          handlers.formFieldsUpdate('booking', { progress: { value: progress + 1 } })
        }
      })
    } else {
      handlers.bookingFormSave(form, errors => {
        if (!errors) {
          handlers.formFieldsUpdate('booking', { progress: { value: progress + 1 } })
        }
      })
    }
  }
  const togglePasswordFieldType = () => {
    const { password: formPassword } = form || {}
    const password = {
      value: formPassword.value,
      type: formPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('booking', { password })
    return password.type
  }

  return (
    <>
      <Loader active={pending} />
      <BookingFormData
        isScheduleForm={isScheduleForm}
        onSubmit={onSubmit}
        progress={progress}
        form={form}
        passwordFieldType={passwordFieldType || 'password'}
        togglePasswordFieldType={togglePasswordFieldType}
      />
    </>
  )
}

const maps = state => ({
  progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
  pending: state.bookings.pendingForm,
  form: selectors.formSelector(state, { formName: 'booking' }),
  isCancellationForm: state.router.name === 'cancelBooking',
  passwordFieldType: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'password', property: 'type' })
})

export default connect(maps)(BookingDataWrapper)
