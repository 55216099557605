import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './OptionsSelect.css'

class OptionsSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (selectValue) {
    let { mandatory, options, form, name, onChange, onChangeAddon } = this.props
    form = form || { value: '', options: [] }
    const _options = options || form.options
    let value = selectValue ? selectValue.value : ''
    if (mandatory && !value && _options.length > 0) value = _options[0].value
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(this.props.formName, { [this.props.name]: { ...this.props.form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  render () {
    const { focused } = this.state
    let {
      label,
      name,
      hintText,
      mandatory,
      disabled,
      hideError,
      value,
      options,
      width,
      form
    } = this.props
    form = form || { value: '', options: [] }
    const styles = { width: width && width }

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} style={styles}>
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled !== undefined ? disabled : form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory !== undefined ? mandatory : form.mandatory}
        >
          <div className='select-options__container'>
            {(options || form.options).map((item, i) => {
              const selectOptionClassNames = ['select-option']
              if (item.value === form.value) selectOptionClassNames.push('active')
              return (
                <button
                  key={i}
                  onClick={() => this.onChange({ value: item.value })}
                  className={selectOptionClassNames.join(' ')}>
                  {item.label}
                </button>
              )
            })}
          </div>
          {hintText &&
            <div className='ta-form-control__hint'>{hintText}</div>
          }
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(OptionsSelect), FormContext)
