import { circleFlags } from '../Assets/images'

export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_VERSION_SUFFIX = process.env.REACT_APP_VERSION_SUFFIX
export const CALL_CENTRE_URL = process.env.REACT_APP_CALL_CENTRE_URL
export const WEBAPP_URL = process.env.REACT_APP_WEBAPP_URL
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const SENTRY_KEY = process.env.REACT_APP_SENTRY_KEY
export const SENTRY_PROJECT = process.env.REACT_APP_SENTRY_PROJECT
export const PHRASE_PROJECT_ID = process.env.REACT_APP_PHRASE_PROJECT_ID
export const PATH_TO_S3_LOCALE = process.env.REACT_APP_PATH_TO_S3_LOCALE
export const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL
export const FILE_DOWNLOAD_URL = process.env.REACT_APP_FILE_DOWNLOAD_URL

export const DISCOUNT_VALIDATION_REGEX = /^[1-9][0-9]?$|^100$/
export const NAME_VALIDATION_REGEX = /^[a-zA-Z ]{2,30}$/
export const EMAIL_VALIDATION_REGEX = /^([\w.'+-]+)@([\w.-]+)((\.(\w){2,63})+)$/i
export const NUMBERS_VALIDATION_REGEX = /^\d+$/
export const FLOAT_VALIDATION_REGEX = /^\d+(\.\d+)?$/
export const PHONE_VALIDATION_REGEX = /^(((\+[1-9][0-9]*)|\([0-9]+\))[/\- ]?)?[0-9]+([- ]?[0-9]+)*$/
export const PRICE_VALIDATION_REGEX = /^(?!.*(,,|,\.|\.,|\.\.))[\d.,]+$/
export const EMOJIS_REGEX = '[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]'
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
export const HTTP_REGEX = /^https?:\/\//i
export const SLUG_REGEX = /^[a-zA-Z0-9-_]*$/gi

export const CHART_DEFAULTS = {
  width: 460,
  widthInner: 420,
  height: 180,
  strokeDasharry: '2 2',
  margin: {
    line: {
      top: 20,
      right: 10,
      left: -30,
      bottom: 0
    },
    pie: {
      top: 40,
      left: 60,
      right: 10,
      bottom: 0
    }
  },
  lineDote: {
    fill: '#cb3b43',
    stroke: 'none'
  },
  lineTooltipCursor: { stroke: '#cb3b43' },
  barTooltipCursor: { fill: '#c1c8cd' },
  weeklyVerticalPoints: [30, 90, 150, 210, 270, 330, 390, 450],
  weeklyVerticalPointsInner: [30, 84.25, 138.5, 193, 247, 301.5, 355.75, 410],
  monthlyVerticalPointsInner: [30, 61.75, 93.25, 125, 156.75, 188.5, 220, 251.75, 283.5, 314.75, 346.5, 378.25, 410]
}
export const PATH_TO_CIRCLE_FLAGS = circleFlags
export const FIVE_MINUTES_INTERVAL_TIMES = [...Array(1441).keys()].filter(time => time % 5 === 0)
export const HOURS = [...Array(24).keys()]
export const DEFAULT_RESOURCES_PAGE_SIZE = 16
export const DEFAULT_BOOKING_BUTTON_POSITION = 'flexible'
export const DEFAULT_SUBJECT_HEXCOLOR = '#ebb65d'
export const DEFAULT_SERVICE_HEXCOLOR = '#385f87'
export const DEFAULT_COURSE_HEXCOLOR = '#b26492'
export const DEFAULT_TAG_HEXCOLOR = '#385f87'
export const AVAILABLE_SERVICE_HEXCOLORS = [
  '#82a774', '#b22e53', '#ebb65d',
  '#95c5a6', '#b26492', '#cc9e52',
  '#8ebec3', '#a476ca', '#e5774c',
  '#385f87', '#5d5a79', '#c46f4e',
  '#7684ca', '#8b8b8b', '#7b6161'
]
export const AVAILABLE_COURSE_HEXCOLORS = [
  '#82a774', '#b22e53', '#ebb65d',
  '#95c5a6', '#b26492', '#cc9e52',
  '#8ebec3', '#a476ca', '#e5774c',
  '#385f87', '#5d5a79', '#c46f4e',
  '#7684ca', '#8b8b8b', '#7b6161'
]

export const COLORS_CLASS_NAMES = {
  green: '#82A774',
  red: '#B22E53',
  breaks: '#EBB65D',
  'light-green': '#95C5A6',
  course: '#B26492',
  ocher: '#CC9E52',
  aqua: '#8EBEC3',
  violet: '#A476CA',
  orange: '#E5774C',
  purple: '#7684CA',
  dusk: '#5D5A79',
  grey: '#8B8B8B',
  brown: '#C46F4E',
  wood: '#7B6161',
  'light-blue': '#6882B0',
  default: '#385f87',
  white: '#FFFFFF'
}
export const DEFAULT_DATE_FORMAT = ''
export const AVAILABLE_TAGS_HEXCOLORS = {
  green: '#82A774',
  red: '#B22E53',
  breaks: '#EBB65D',
  'light-green': '#95C5A6',
  course: '#B26492',
  ocher: '#CC9E52',
  aqua: '#8EBEC3',
  violet: '#A476CA',
  orange: '#E5774C',
  purple: '#7684CA',
  dusk: '#5D5A79',
  grey: '#8B8B8B',
  brown: '#C46F4E',
  wood: '#7B6161',
  'light-blue': '#6882B0',
  default: '#385f87'
}

export const DEFAULT_LOCALE = 'en-gb'
export const DEFAULT_LOCALE_COUNTRY_CODE = 'GB'
export const SUPPORTED_LOCALES = [
  // 'bg-bg',
  'de-de', 'de-at', 'de-ch',
  'en-au', 'en-ca', 'en-gb', 'en-ie', 'en-in', 'en-mt', 'en-ph', 'en-sg', 'en-us',
  // 'et-ee',
  // 'fi-fi',
  // 'hu-hu',
  // 'ru-ru',
  // 'sv-se',
  // 'pl-pl',
  'es-es', 'es-mx', 'es-co', 'es-cl',
  'fr-be', 'fr-ca', 'fr-ch', 'fr-fr', 'fr-lu'
  // 'it-it',
  // 'nl-be', 'nl-nl',
  // 'cs-cz',
  // 'pt-pt',
  // 'ja-jp',
  // 'ko-kr'
]
export const AVAILABLE_LANGUAGES = [
//   {
//   name: 'Български',
//   locale: 'bg-bg'
// }, {
//   name: 'Czech',
//   locale: 'cs-cz'
// },
// {
//   name: 'English (US)',
//   locale: 'en-us'
// },
  {
    name: 'English (UK)',
    locale: 'en-gb'
  }, {
    name: 'Deutsch',
    locale: 'de-de'
  }, {
    name: 'Español',
    locale: 'es-es'
  }, {
    name: 'Français',
    locale: 'fr-fr'
  }
//, {
//   name: 'Nederlands',
//   locale: 'nl-nl'
// }, {
//   name: 'Italiano',
//   locale: 'it-it'
// }
]
export const BOOKING_SETTINGS_DEFAULTS = {
  cancellationLeadTime: [1, 2, 4, 6, 12, 24, 48],
  bookingReminder: [1, 2, 4, 6, 12, 24, 48, 72],
  rescheduling: [2, 4, 6, 12, 24, 48],
  maximumLeadTimeDurationOptions: ['MONTHS', 'WEEKS'],
  minimumLeadTimeDurationOptions: ['HOURS', 'DAYS']
}
export const SERVICE_DEFAULTS = {
  serviceReminder: [1, 2, 4, 6, 12, 24, 48, 72]
}
export const PLUGINS = {
  joomla: {
    downloadUrl: 'https://github.com/timify/joomla-plugin/archive/master.zip',
    documentationUrl: 'https://cdn.timify.com/download/en/TIMIFY-Joomla-Plugin.pdf'
  },
  wordpress: {
    downloadUrl: 'https://timify.github.io/wordpress-plugin/',
    documentationUrl: 'https://data.timify.com/download/timify-wordpress-plugin-%WORDPRESS_DOCUMENTATION_LANGUAGE%.pdf'
  },
  drupal: {
    downloadUrl: 'https://timify.github.io/drupal-plugin/',
    documentationUrl: 'https://cdn.timify.com/download/en/TIMIFY-Drupal8-Plugin.pdf'
  },
  // wix: {
  //   downloadUrl: 'https://',
  //   documentationUrl: 'https://'
  // },
  // weebly: {
  //   downloadUrl: 'https://',
  //   documentationUrl: 'https://'
  // },
  notListed: {
    url: 'https://timify.typeform.com/to/dSH7Fx'
  }
}
export const AVAILABLE_CALENDAR_VIEWS = ['daily', 'weekly', 'monthly', 'shiftplan']
export const DEFAULT_CALENDAR_VIEW = 'weekly'
export const CALENDAR_DIMENSIONS = {
  margin: 20,
  head: 30,
  weeklyHead: 88,
  dayHead: 48,
  shiftPlanHead: 48,
  footer: 10
}
export const DEFAULT_AVAILABLE_BOOKING_TIMES = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
export const BRANCHES = []
export const GDPR_DOWNLOAD_URLS = {
  DE: 'https://cdn.timify.com/legal/TerminApp-GmbH-AV-DE.pdf',
  EN: 'https://cdn.timify.com/legal/TerminApp-GmbH_AV-EN.pdf'
}
export const PLANS_WEIGHTS = {
  CLASSIC: 1,
  PREMIUM: 2,
  ENTERPRISE: 3
}

export const AVAILABLE_ICONS = [
  'user',
  'user-tie',
  'user-secret',
  'user-clock',
  'house-user',
  'user-md',
  'user-nurse',
  'user-headset',
  'user-hard-hat',
  'user-crown',
  'user-cowboy',
  'user-ninja',
  'user-astronaut',
  'user-alien',
  'user-robot'
]
export const FOOTFALL_COLORS = {
  GREEN: '#82a774',
  YELLOW: '#ebb65d',
  ORANGE: '#e5774c',
  RED: '#b22e53'
}

export const FOOTFALL_STATUS = {
  GREEN: 'settings.footfall.low',
  YELLOW: 'settings.footfall.belowAverage',
  ORANGE: 'settings.footfall.aboveAverage',
  RED: 'settings.footfall.high'
}

export const FOOTFALLS_ORDER = [
  'weekdays.su.long',
  'weekdays.mo.long',
  'weekdays.tu.long',
  'weekdays.we.long',
  'weekdays.th.long',
  'weekdays.fr.long',
  'weekdays.sa.long'
]

export const SESSION_LENGTH_DEFAULT = {
  value: '4',
  mode: 'DAYS'
}

export const MAX_LEAD_TIME_MAXIMUMS = {
  MONTHS: 12,
  WEEKS: 52
}

export const MIN_LEAD_TIME_MAXIMUMS = {
  DAYS: 60,
  HOURS: 1440
}

export const ALLOWED_REGIONS = [
  'EUROPE', 'ASIA', 'LATIN_AMERICA', 'USA'
]

export const MAX_NO_OF_AGENTS = 100

export const CALL_CENTER_ADDRESS_RADIUS = [
  { label: '1km', value: '1000' },
  { label: '5km', value: '5000' },
  { label: '10km', value: '10000' },
  { label: '15km', value: '15000' },
  { label: '20km', value: '20000' },
  { label: '25km', value: '25000' },
  { label: '30km', value: '30000' },
  { label: '35km', value: '35000' },
  { label: '40km', value: '40000' },
  { label: '45km', value: '45000' },
  { label: '50km', value: '50000' },
  { label: '50+km', value: '500000' }
]

export const CALL_CENTER_ADDRESS_ZOOM_BY_RADIUS = {
  1000: 15,
  5000: 13,
  10000: 12,
  15000: 11,
  20000: 11,
  25000: 11,
  30000: 10,
  35000: 10,
  40000: 10,
  45000: 10,
  50000: 10,
  500000: 6
}

export const CALL_CENTER_ADDRESS_DEFAULT_ZOOM = 6
export const CALL_CENTER_LOCATION_SELECTED_DEFAULT_ZOOM = 15

export const DEFAULT_LOCALES_BY_LANG = {
  cs: 'cs-cz',
  de: 'de-de',
  en: 'en-gb',
  es: 'es-es',
  fr: 'fr-fr',
  it: 'it-it',
  nl: 'nl-nl',
  pt: 'pt-pt',
  sv: 'sv-se',
  bg: 'bg-bg'
}
export const PASSWORD_MAX_LENGTH = 40
export const PASSWORD_MIN_LENGTH = 8

export const CALL_CENTER_DEFAULT_MAP_CENTER = {
  lat: 48.1926301,
  lng: 11.56950489999997
}

export const UPLOAD_FILES_LIMIT = 25
export const UPLOAD_FILE_SIZE_LIMIT = '15MB'
