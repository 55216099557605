import React from 'react'
import PropTypes from 'prop-types'

const MenuItemNotifications = props => {
  const { notifications } = props

  return (
    <div className='ta-menu__notifications'>
      <svg with='10' height='38' viewBox='0 0 10 38' version='1.1'>
        <g>
          <circle cx='0' cy='19' r='10' fill='#25405a' stroke='none' />
          <path d={`M0,0
                    L0,35
                    A11,15 -45 0,1 6,27
                    L6,11
                    A15,11 -45 0,1 0,3
                    Z`} stroke='none' fill='#25405a' />
        </g>
      </svg>
      <div className='ta-menu__notifications__counter'>{notifications}</div>
    </div>
  )
}

MenuItemNotifications.propTypes = {
  notifications: PropTypes.number
}

export default MenuItemNotifications
