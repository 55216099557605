import React, { useRef, useEffect } from 'react'
import {
  Title,
  Accordion,
  BorderedBox,
  AccordionTitle,
  AccordionBody
} from '../../../Common'

const BookingFormSection = ({ title, header, children, index, setAccordionRef, accordionProps, className }) => {
  const accordionRef = useRef(null)
  const latestSetAccordionRef = useRef(setAccordionRef)
  const { hideAccordionToggle } = accordionProps || {}
  const classNames = ['ta-booking-form__section']
  if (className) classNames.push(className)

  // assign setAccordionRef to ref in order for it not to be needed as a dependency in the other useEffect
  useEffect(() => {
    latestSetAccordionRef.current = setAccordionRef
  }, [setAccordionRef])

  useEffect(() => {
    if (latestSetAccordionRef.current && accordionRef.current && accordionRef.current.state && accordionRef.current.state.toggle) {
      latestSetAccordionRef.current(accordionRef.current, index)
    }
  }, [index])

  return (
    <Accordion className={classNames.join(' ')} ref={accordionRef} key={title} zIndex={140}>
      <BorderedBox>
        {!hideAccordionToggle && (
          <AccordionTitle>
            <Title label={title} isCompact>{header}</Title>
          </AccordionTitle>
        )}
        {hideAccordionToggle && (
          <Title label={title} isCompact>{header}</Title>
        )}
        <AccordionBody>
          {children}
        </AccordionBody>
      </BorderedBox>
    </Accordion>
  )
}

export default BookingFormSection
