import React from 'react'
import { connect, selectors } from '../../Store'
import { SmallScreenOverlay } from '../../Beauties'

const SmallScreenOverlayWrapper = props => {
  const { windowWidth, pendingLanguage } = props
  const isActive = windowWidth <= 896

  return (
    <SmallScreenOverlay
      isActive={isActive}
      pendingLanguage={pendingLanguage}
    />
  )
}

const maps = state => ({
  windowWidth: selectors.appFieldSelector(state, { field: 'windowWidth' })
})

export default connect(maps)(SmallScreenOverlayWrapper)
