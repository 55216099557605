import React from 'react'
import {
  Row,
  Col,
  Textarea,
  t
} from '../../../Common'
import { CustomerFormField } from '../../../Beauties'

const BookingFormDataFields = props => {
  const {
    customerFields,
    timezones,
    isCancellationForm,
    hideBookingInternalNotes,
    setFileLoading
  } = props

  return (
    <div className='ta-single-booking-form__data-fields'>
      {!isCancellationForm && customerFields && customerFields.map(field => (
        <Row key={field.id}>
          <Col>
            <CustomerFormField
              field={field}
              timezones={timezones}
              setFileLoading={setFileLoading}
            />
          </Col>
        </Row>
      ))}
      {(!hideBookingInternalNotes &&
        <Row>
          <Col>
            <Textarea name='notes' label={isCancellationForm ? t('global.messageToCustomer') : t('global.notes')} limit={3000} />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default BookingFormDataFields
