import React from 'react'
import { PermissionTypeSummaryPermissions } from '../../Beauties'

const AgentPreviewPermissionsWrapper = props => {
  const { permissions } = props

  return <PermissionTypeSummaryPermissions permissions={permissions} />
}

export default AgentPreviewPermissionsWrapper
