import { companyFieldsForAccountAccounts } from '../Companies/fields'

export const eugdprRuleFields = `{
  customerType
  customerTagId
  frequency
  frequencyUnit
}`
export const companySessionFields = `{
  value
  mode
}`
export const minMaxLeadTimeFields = `{
  value
  frequency
}`
export const enterpriseAccountFields = `{
  id
  region
  customersMiddlewareUrl
  name
}`

export const enterpriseSettingsFields = `{
  snapToGridInterval
  internalSnapToGridInterval
  hasFacebookLogin
  hasTimifyLogin
  hasGuestBooking
  cancellationLeadTimeHours
  resourceEmailRemindersMinutes
  customerEmailRemindersMinutes
  showWidgetLanguageSelector
  isWidgetWeeklyView
  showWidgetCategoriesClosed
  showWidgetFirstCategoryOpen
  showWidgetTimezoneSelector
  showAddServiceCombinations
  customTermsAndConditionsUrl
  customPrivacyPolicyUrl
  widgetExternalUrlRedirect
  hideServiceDuration
  hideResourcesSection
  hasNotifyOnResources
  hasNotifyOnCustomers
  showExternalIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  resourceRemindersEmailRecipients
  customerRemindersEmailRecipients
  resourceBookingCreateEmailRecipients
  resourceBookingUpdateEmailRecipients
  resourceBookingDeleteEmailRecipients
  customerBookingCreateEmailRecipients
  customerBookingUpdateEmailRecipients
  customerBookingDeleteEmailRecipients
  enforceResourcesAllowanceType
  enforceReschedulingTagRule
  enforceServiceDurationLimit
  resetCopyBookingClipboardAfterPaste
  hideFastWidgetTimeFrame
  allowUserLocaleForCustomers
  allowUserLocaleForResources
  allowOnlyGlobalCustomers
  EUGDPRRules ${eugdprRuleFields}
  forbidMoreThanOneBookingInFBW
  forbidCreatingAndUpdatingBookingsInThePast
  isCompanyTagsLocalUpdateForbidden
  isServiceLocalUpdateForbidden
  isCourseLocalUpdateForbidden
  isCustomersLocalUpdateForbidden
  isResourceLocalUpdateForbidden
  isPermissionsGroupsLocalUpdateForbidden
  requiredEnterpriseAuth2FAType
  requiredAuth2FAType
  securitySessionConfig ${companySessionFields}
  securitySessionInactivityConfig ${companySessionFields}
  shouldUseMongoCustomerSearch
  publicHolidays {
    id
    publicHolidayKeys
    resourceCategoryIds
  }
  maximumLeadTime ${minMaxLeadTimeFields}
  minimumLeadTime ${minMaxLeadTimeFields}
}`

export const accountSettingsFields = `{
  beginHour
  endHour
  displayDays
  gridLevel
  dailyResources
  weeklyResources
  monthlyResources
  availabilityPlanResources
  reduceIntensityInThePast
  showBookingIntervals
  showFootfallIntervals
  showNotWorkingIntervals
  showAddLocation
  showDurationDaySelector
  showDurationBeforeAndAfter
  showRepeat
  showSplitSelector
  timezone
}`

export const accountAccountsFields = `{
  company ${companyFieldsForAccountAccounts}
  accountId
  isConfirmed
  isConnected
  updatedAt
  isAdmin
  isCustom
  isOwner
  roles
  email
  ts
  enterprise {
    id
    region
    name
    slug
    lastStatisticsStateChange
    enterpriseCustomisation {
      id
      name
      type
      payload
    }
    address { 
      placeId
      latitude
      longitude
      street
      streetNumber
      city
      country
      zipCode
      formatted
      details
    }
    settings {
      requiredEnterpriseAuth2FAType
      requiredAuth2FAType
    }
  }
  auth2FAs {
    type
    isAuth
    isActive
  }
  jwtAuth2FAs {
    type
    isAuth
    isActive
  }
}`

export const accountUserAccountsFields = `{
  company ${companyFieldsForAccountAccounts}
  enterprise ${enterpriseAccountFields}
  authScope
  accountId
  isConfirmed
  isConnected
  updatedAt
  isAdmin
  isCustom
  isOwner
  roles
  email
  ts
}`

export const accountFields = `{
  userId
  pendingEmail
  accounts {
    accountId
    isConfirmed
    isAdmin
    userId
    isOwner
    isCustom
    roles
    email
    enterprise {
      id
      region
      name
      slug
      lastStatisticsStateChange
      address { 
        placeId
        latitude
        longitude
        street
        streetNumber
        city
        country
        zipCode
        formatted
        details
      }
      settings {
        requiredEnterpriseAuth2FAType
        requiredAuth2FAType
      }
    }
    auth2FAs {
      type
      isAuth
      isActive
    }
    jwtAuth2FAs {
      type
      isAuth
      isActive
    }
  }
  enterprise {
    id
    region
    customersMiddlewareUrl
    name
    slug
    ownerEmail
    ownerName
    phone { number phone prefix country }
    totalCompanyPlans
    createdAt
    lastStatisticsStateChange
    address { 
      placeId
      latitude
      longitude
      street
      streetNumber
      city
      country
      zipCode
      formatted
      details
    }
    avatarPrefix
    locale
    settings ${enterpriseSettingsFields}
    rawSettings ${enterpriseSettingsFields}
    callCentreSettings {
      allowMultipleAppointmentsPerBooking
      allowBookingWithinWorkingTimes
      hideOfflineServices
      hasCCNotifyOnCustomers
      hasCCNotifyOnResources
      newBookingActivated
      rescheduleBookingActivated
      resourceSelectorRescheduleManual
      displayBookedResources
    }
    chargebeeId
  }
  enforcePasswordChange
  isAdmin
  isOwner
  isCustom
  email
  authScope
  roles
  rules
  settings
  company {
    name
    timezone
    currency
    hasStripeConnected
  }
  companySettings {
    showDurationDaySelector
  }
  companyId
  locale
  isConfirmed
  isVerified
  region
  userAccounts ${accountUserAccountsFields}
  intendedAccountIdAfter2FA
  auth2FAs {
    type
    isAuth
    isActive
  }
  jwtAuth2FAs {
    type
    isAuth
    isActive
  }
  enterprisePermissionsGroupId
}`

export const accountForOAuthFields = `{
  accounts {
    company ${companyFieldsForAccountAccounts}
    oauthValidation(appId: $appId) {
      isAppInstallAllowed
      isAppInstalled
    }
  }
}`

export const tokensFields = `{
  accessToken
  refreshToken
  sessionDuration
  refreshDuration
  account ${accountFields}
}`

export const profileFields = `{
  userId
  email
  isVerified
  locale
  isVerificationSent
  roles
  pendingEmail
}`

export const grantCode = `{
  code
  error
}`
