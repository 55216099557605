import { payloads$, actions, store, handlers, selectors } from '../../../Store'
import { q } from '../../API'
import { debounceTime } from 'rxjs/operators'
// import moment from 'moment'
import {
  companyFormValidate,
  companyFormServerErrorsTransform,
  companyFormSaveTransform,
  companyDeleteFormValidate,
  companyDeleteFormSaveTransform,
  companyDeleteFormServerErrorsTransform,
  companyOnboardingFormSaveTransform
} from './utils'
// import { GOOGLE_MAPS_API_KEY } from '../../../Settings'
import { t } from '../../../Common'
import { sortByLabel } from '../../../Utils'
import { companyUpdated, companyPublicHolidaysDeleted } from './subscriptions'

// Subscription
payloads$(actions.COMPANY_SUBSCRIPTION_SET)
  .subscribe(async ({ name, data, ts, ids }) => {
    if (name === 'companyUpdated') {
      const account = (await q('getAccount')) || {}
      if (account.error) return
      const { company } = account || {}
      companyUpdated(company)
    }
    if (name === 'publicHolidaysDeleted' && ids) companyPublicHolidaysDeleted(ids)
  })

// GDPR
payloads$(actions.COMPANY_GDPR_TIMESTAMP_SET)
  .subscribe(() => {
    q('setCompanyGDPRTimestamp')
  })

const setCompanyDeleteFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('popup', errors)
  scrollToError && scrollToError(errors)
  handlers.companyDeleteFormReady()
}

// Delete
payloads$(actions.COMPANY_DELETE_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const errors = companyDeleteFormValidate(form)
    if (errors.length) return setCompanyDeleteFormSaveErrors(errors, scrollToError)
    const { error } = await q('deleteCompany', companyDeleteFormSaveTransform(form))
    if (error) return setCompanyDeleteFormSaveErrors(companyDeleteFormServerErrorsTransform(error), scrollToError)
    handlers.popupSet()
    handlers.logout()
  })

// Form
payloads$(actions.COMPANY_FORM_GET)
  .subscribe(async () => {
    const { company = {}, staticData } = store.getState()
    const { businessSectors } = staticData || {}
    const businessSectorsOptions = businessSectors
      .filter(item => item !== 'others')
      .map(item => ({ label: t(`branch.${item}`), value: item }))
      .sort(sortByLabel)
    businessSectorsOptions.push({ label: t('branch.others'), value: 'others' })

    handlers.companyFormPopulate({ ...company, businessSectorsOptions })
  })

const setCompanyFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('company', errors)
  scrollToError && scrollToError(errors)
  handlers.companyFormReady()
}

// // Populate
// payloads$(actions.COMPANY_POPULATE)
//   .subscribe(() => {
//     const state = store.getState()
//     const { company } = state || {}
//     const { locale } = company || {}

//     // Remove old maps api scripts && all its traces
//     window.google = undefined
//     const scripts = [...document.head.getElementsByTagName('script')]
//     scripts.forEach(node => {
//       if (node) {
//         const src = node.getAttribute('src')
//         if (src && src.includes('maps.googleapis.com')) {
//           node.remove()
//         }
//       }
//     })
//     // Append google maps script
//     const googleMapsScript = document.createElement('script')
//     googleMapsScript.async = true
//     googleMapsScript.id = 'timify-google-maps-api'
//     googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=${locale || 'en-gb'}`
//     document.head.appendChild(googleMapsScript)
//   })

// Save
payloads$(actions.COMPANY_FORM_SAVE)
  .subscribe(async ({ company, scrollToError }) => {
    const errors = companyFormValidate(company)
    if (errors.length) return setCompanyFormSaveErrors(errors, scrollToError)

    const savedCompany = await q('saveCompanyProfile', { company: companyFormSaveTransform(company) })
    const { error } = savedCompany
    if (error) return setCompanyFormSaveErrors(companyFormServerErrorsTransform(error), scrollToError)

    handlers.companyPopulate(savedCompany)
    handlers.navigateToPath('/account')
  })

// OnlineBooking
payloads$(actions.COMPANY_ONLINE_STATUS_TOGGLE)
  .pipe(
    debounceTime(200)
  )
  .subscribe(async () => {
    const onlineStatus = await q('toggleOnlineStatus')
    handlers.companyOnlineStatusPopulate(onlineStatus)
    const { isOnline } = onlineStatus || {}
    if (isOnline) handlers.navigateToPath('/integration/booking-link')
  })

// Appcues
payloads$(actions.COMPANY_APPCUES_INIT)
  .subscribe(() => {
    if (!document.getElementById('ta-appcues-script')) {
      let appcuesElement = document.createElement('script')
      appcuesElement.setAttribute('id', 'ta-appcues-script')
      appcuesElement.setAttribute('src', 'https://fast.appcues.com/29577.js')
      appcuesElement.setAttribute('async', true)
      document.body.insertBefore(appcuesElement, document.body.firstChild)
    }

    // 1 sec delay the script to load
    setTimeout(() => {
      if (!window.Appcues) return
      const state = store.getState()
      const app = selectors.appSelector(state) || {}
      const account = state.account || {}
      // const company = selectors.companySelector(state) || {}
      // const resources = selectors.resourcesListSelector(state) || []
      // const resource = resources.find(item => item.email === account.email) || {}
      // const subscription = state.subscription.data || {}
      const statistics = state.statistics || {}
      const {
        locale: userLocale
      } = app || {}
      // let {
      //   locale: companyLocale,
      //   name: companyName,
      //   plan: companyPlan,
      //   createdAt,
      //   onlineStatus,
      //   onboardData,
      //   enterpriseId
      // } = company || {}
      // companyLocale = companyLocale || 'en-gb'
      // const {
      //   bookingAvailability: onboardDataBookingAvailability,
      //   bookingType: onboardDataBookingType,
      //   locationType: onboardDataLocations
      // } = onboardData || {}
      // const {
      //   wantsToBeOnline: companyWantsToBeOnlineBookable,
      //   isOnline: companyIsOnlineBookable
      // } = onlineStatus || {}
      // const {
      //   name: resourceName,
      //   email: resourceEmail
      // } = resource || {}
      const {
        isOwner,
        isAdmin,
        isCustom,
        isVerified,
        accounts
      } = account || {}
      // const {
      //   isTrial: companyIsTrial,
      //   renewDay: companyRenewDate,
      //   renewPlan: companyRenewPlan,
      //   trialEndDay: companyTrialEndDay,
      //   startDay: companySubscriptionStartDay
      // } = subscription || {}
      const {
        generalStatistics
      } = statistics || {}
      const {
        coursesCreated: totalCoursesCreated,
        customerFieldsCreated: totalCustomerFieldsCreated,
        eventsCreated: totalBookingsCreated,
        resourcesCreated: totalResourcesCreated,
        servicesCreated: totalServicesCreated
      } = generalStatistics || {}
      // const localeParts = companyLocale.split('-')
      // const userLocaleParts = companyLocale.split('-')
      const appcuesSettings = {
        userLocale,
        // userLanguage: userLocaleParts[0],
        isOwner,
        isAdmin,
        isCustom,
        isVerified,
        role: isOwner ? 'Owner' : isAdmin ? 'Admin' : 'Custom',
        // companyName,
        // companyPlan,
        // companyRenewPlan,
        // companyRenewDate,
        // companyIsTrial,
        // companyTrialEndDay,
        // companySubscriptionStartDay,
        // // companyLocale,
        // companyLanguage: localeParts[0],
        // companyCountry: localeParts[1],
        // companyCreatedAt: moment.utc(createdAt).unix(),
        // companyWantsToBeOnlineBookable,
        // companyIsOnlineBookable,
        // enterpriseId,
        // resourceName: resourceName,
        // resourceEmail: resourceEmail,
        totalCoursesCreated,
        totalCustomerFieldsCreated,
        totalBookingsCreated,
        totalResourcesCreated,
        totalServicesCreated,
        totalLocations: accounts.length
        // onboardDataBookingAvailability,
        // onboardDataBookingType,
        // onboardDataLocations
      }

      // Appcues auth
      window.Appcues.identify(account.userId, appcuesSettings)
    }, 1000)
  })

// Onboarding
payloads$(actions.COMPANY_ONBOARDING_FORM_SAVE)
  .subscribe(async ({ form, scrollToError }) => {
    const companyId = store.getState().company.id
    await q('saveCompanyOnboardData', { companyId, onboardData: companyOnboardingFormSaveTransform(form) })
    setTimeout(() => window.location.reload(), 3000)
  })
