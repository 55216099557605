import React from 'react'

import './FixedContent.css'

const FixedContent = props => {
  const { children, fixedFormButtons } = props
  const classNames = ['ta-fixed-content']
  if (fixedFormButtons) classNames.push('fixed-form-buttons')

  return (
    <div className={classNames.join(' ')} data-testid='fixed-content'>{children}</div>
  )
}

export default FixedContent
