import React from 'react'
import { BlueTemplate, t } from '../../Common'

const AccessRevoked = props => {
  return (
    <div className='ta-access-revoked'>
      <BlueTemplate logoUrl='/'>
        <div className='ta-access-revoked__box'>
          {t('global.accessRevoked')}
        </div>
      </BlueTemplate>
    </div>
  )
}

export default AccessRevoked
