import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../../Store'
import { FIVE_MINUTES_INTERVAL_TIMES } from '../../../Settings'
import {
  feedContextInProps,
  convertMinutesToHours
} from '../../../Utils'
import {
  FormContext,
  FontAwesome5,
  FormGroup,
  Error,
  format,
  Select,
  t
} from '../../../Common'

import './WeekDayIntervalsInput.css'

class WeekDayIntervalsInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onActiveChange = this.onActiveChange.bind(this)
    this.addInterval = this.addInterval.bind(this)
    this.deleteInterval = this.deleteInterval.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = {
      focusedIndex: null,
      focusedField: null
    }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (index, field) {
    this.setState({
      focusedIndex: index,
      focusedField: field
    })
  }

  onBlur () {
    this.setState({
      focusedIndex: null,
      focusedField: null
    })
  }

  onChange (selectValue, index, field) {
    let { name, formName, isActive, intervals, errors } = this.props
    isActive = !!isActive
    intervals = intervals || []
    if (!intervals[index]) return
    intervals[index][field] = (selectValue && selectValue.value) || null
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals],
        errors
      }
    })
  }

  onActiveChange () {
    let { name, formName, isActive, intervals, errors } = this.props
    isActive = !!isActive
    intervals = intervals || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive: !isActive,
        values: intervals,
        errors
      }
    })
  }

  addInterval () {
    let { name, formName, isActive, intervals, errors } = this.props
    isActive = !!isActive
    intervals = intervals || []
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals, { from: null, until: null }],
        errors
      }
    })
  }

  deleteInterval (index) {
    let { name, formName, isActive, intervals, errors } = this.props
    isActive = !!isActive
    intervals = intervals || []
    if (!isActive) return
    handlers.formFieldsUpdate(formName, {
      [name]: {
        isActive,
        values: [...intervals.filter((item, key) => key !== index)],
        errors
      }
    })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  getInterval = (intervals, index) => {
    intervals = intervals || []
    const currentItem = intervals[index] || {}
    const prevItem = intervals[index - 1] || {}
    const from = prevItem.until || '--:--'
    const until = currentItem.from || '--:--'
    return `${from} - ${until}`
  }

  render () {
    const { focusedIndex, focusedField } = this.state
    let { className, isActive, intervals, errors, label, limit = 5, intervalGapMessage } = this.props
    errors = errors || []
    isActive = !!isActive
    intervals = intervals || []
    const classNames = ['ta-intervals-input']
    if (className) classNames.push(className)
    if (!isActive) classNames.push('disabled')
    const checkboxClassNames = ['ta-checkbox']
    if (isActive) checkboxClassNames.push('active')
    if (intervals.length === 0) intervals.push({ from: null, until: null })
    const options = FIVE_MINUTES_INTERVAL_TIMES.map(time => ({
      label: format(convertMinutesToHours(time), 'time', { isUTC: true, format: 'HH:mm' }),
      value: convertMinutesToHours(time)
    }))

    return (
      <div className={classNames.join(' ')}>
        <div className='ta-intervals-input__interval__checkbox'>
          <input
            className='ta-checkbox-field'
            ref={ref => { this.ref = ref }}
            type='checkbox'
            checked={isActive}
            onChange={() => { }}
            autoComplete='off'
          />
          <div className={checkboxClassNames.join(' ')} onClick={this.onActiveChange}>{label}</div>
        </div>
        {intervals.map((item, index) => (
          <div className={`ta-intervals-input__interval ${intervals.length > 1 ? 'has-delete' : ''}`} key={index}>
            {intervalGapMessage && index > 0 &&
              <div className='ta-intervals-input__interval__gap'>
                {intervalGapMessage} {this.getInterval(intervals, index)}
              </div>
            }
            <FormGroup
              className='ta-intervals-input__interval__from'
              focused={focusedIndex === index && focusedField === 'from'}
              filled={!!item.from}
              labelText={t('global.from')}
              labelMandatory
              disabled={!isActive}
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={item.from}
                arrowRenderer={this.renderArrows}
                onFocus={() => this.onFocus(index, 'from')}
                onBlur={this.onBlur}
                onChange={(selectValue) => this.onChange(selectValue, index, 'from')}
                options={options}
                searchable
                placeholder='--:--'
                disabled={!isActive}
                autoComplete='off'
              />
            </FormGroup>
            <div className='ta-intervals-input__interval__separator'>-</div>
            <FormGroup
              className='ta-intervals-input__interval__until'
              focused={focusedIndex === index && focusedField === 'until'}
              filled={!!item.until}
              labelText={t('global.until')}
              labelMandatory
              disabled={!isActive}
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={item.until}
                arrowRenderer={this.renderArrows}
                onFocus={() => this.onFocus(index, 'until')}
                onBlur={this.onBlur}
                onChange={(selectValue) => this.onChange(selectValue, index, 'until')}
                options={options}
                searchable
                placeholder='--:--'
                disabled={!isActive}
                autoComplete='off'
              />
            </FormGroup>
            {intervals.length > 1 &&
              <div className='ta-intervals-input__interval__btn-delete' onClick={() => this.deleteInterval(index)}>
                <FontAwesome5 icon='trash' type='regular' />
              </div>
            }
            {errors.length > 0 && errors.map((error, key) => error.index === index &&
              <Error key={key} error={error} />
            )}
          </div>
        ))}
        {intervals.length < limit &&
          <div className='ta-intervals-input__btn-add' onClick={this.addInterval}>
            <FontAwesome5 icon='plus' type='regular' /> {t('global.addInterval')}
          </div>
        }
      </div>
    )
  }
}

WeekDayIntervalsInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object,
  intervalGapMessage: PropTypes.string
}

const maps = (state, props) => ({
  isActive: selectors.formFieldPropertySelector(state, { name: props.name, formName: props.formName, property: 'isActive' }),
  intervals: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'values' }),
  errors: selectors.formFieldPropertySelector(state, { formName: props.formName, name: props.name, property: 'errors' })
})

export default feedContextInProps(connect(maps)(WeekDayIntervalsInput), FormContext)
