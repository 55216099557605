import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store, selectors } from '../../Store'
import { Overlay } from '../../Beauties'

const OverlayWrapper = props => {
  const { children, overlay } = props

  const onClick = () => {
    if (!overlay) return
    handlers.overlayHide('bottom')
    let { router, forms } = store.getState()
    router = router || {}
    forms = forms || {}
    let { hash, props } = router || {}
    props = props || {}
    let {
      redirectOnClose,
      defaultRedirect,
      formDiscard
    } = props || {}
    defaultRedirect = defaultRedirect || '/'
    // const redirectDate = calendar.date === null ? getToday() : calendar.date
    const params = ''
    const redirectUrl = `${defaultRedirect}${params}${hash ? '@@' + hash : ''}`
    const formsWithUnsavedChanges = forms.formsWithUnsavedChanges || []

    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      handlers.formDiscardPopupSet(formDiscard, redirectUrl)
      return
    }

    redirectOnClose
      ? redirectOnClose()
      : handlers.navigateToPath(redirectUrl)
  }

  return (
    <Overlay position='top' isActive={overlay} onClick={onClick}>{children}</Overlay>
  )
}

OverlayWrapper.propTypes = {
  redirectOnClose: PropTypes.func,
  defaultRedirect: PropTypes.string,
  hash: PropTypes.string
}

const maps = state => ({
  isActive: state.overlay.isTopActive,
  overlay: selectors.routerPropsFieldSelector(state, { field: 'overlay' })
})

export default connect(maps)(OverlayWrapper)
