import React, { useCallback, useEffect, useState } from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Circle, GoogleMap, Polyline } from '@react-google-maps/api'
import {
  CALL_CENTER_ADDRESS_ZOOM_BY_RADIUS,
  GOOGLE_MAPS_API_KEY,
  CALL_CENTER_ADDRESS_DEFAULT_ZOOM,
  CALL_CENTER_LOCATION_SELECTED_DEFAULT_ZOOM
} from '../../Settings'
import { centreMarker } from '../../Assets/images'
import { applyClusterStyle, markersBuilderCallback, drawCircle } from './utils'
import { useMapCenter } from './hooks'
import Marker from './Marker'
import Map from './Map'

const render = (status) => {
  return <h1>{status}</h1>;
};

const GoogleMaps = ({
  options,
  defaultCenter,
  locale,
  markers,
  customerAddress,
  customerAddressRadius,
  isMarkerShown,
  selectedBranchAddress
}) => {
  const radius = customerAddressRadius || 5000
  const { extraStyles } = options || {}
  const { polylineStyles, circleStyles } = extraStyles || {}
  const [clicks, setClicks] = React.useState([]);
  const [circlePath, setCirclePath] = React.useState()
  const [circle, setCircle] = React.useState()
  const [zoom, setZoom] = React.useState(CALL_CENTER_ADDRESS_DEFAULT_ZOOM); // initial zoom
  const [center, setCenter] = useMapCenter({ locale, defaultCenter })
  const [visibleMarkers, setMarkers] = useState([])
  const [cluster, setCluster] = useState()
  const renderMarkers = useCallback((map) => {
    if(center?.lat && center?.lng) {
      const defaultPosition = center
      const defaultMarkers = [{ position: defaultPosition }]
      const clusteredMarkers = (markers || defaultMarkers).map((marker, index) => markersBuilderCallback(marker, index, map))
      setMarkers(clusteredMarkers)
      cluster && cluster.setMap(null)
      setCluster(applyClusterStyle(map, clusteredMarkers))
    }
  }, [center?.lat, center?.lng, cluster, markers])
  useEffect(() => {
    if (customerAddress) {
      setCenter({ lat: customerAddress.lat, lng: customerAddress.lng })
      setZoom(CALL_CENTER_ADDRESS_ZOOM_BY_RADIUS[radius])
      if(circle && circlePath) {
        circle.setMap(null)
        circlePath.setMap(null)
        setCirclePath(null)
        setCircle(null)
      }
    }
    // eslint-disable-next-line
  }, [customerAddress, radius])
  useEffect(() => {
    if (selectedBranchAddress) {
      let position = { lat: selectedBranchAddress.latitude, lng: selectedBranchAddress.longitude }
      setCenter(position)
      setZoom(CALL_CENTER_LOCATION_SELECTED_DEFAULT_ZOOM)
      const customerMarker = (visibleMarkers || []).find(item => item.position.lat() === position.lat && item.position.lng() === position.lng)
      if (customerMarker) window.google.maps.event.trigger(customerMarker, 'click')
    }
    // eslint-disable-next-line
  }, [selectedBranchAddress, visibleMarkers])
  useEffect(() => {
    if((!customerAddress || selectedBranchAddress) && circle && circlePath) {
      circle.setMap(null)
      circlePath.setMap(null)
      setCirclePath(null)
      setCircle(null)
    }
  }, [customerAddress, selectedBranchAddress])
  const onIdle = (m) => {
    if(customerAddress && !selectedBranchAddress && !circle && !circlePath) {
      setCirclePath(new google.maps.Polyline({
        path: drawCircle(new window.google.maps.LatLng(center.lat, center.lng), radius, 1),
        options: polylineStyles(window.google.maps.SymbolPath.CIRCLE),
        map: m
      }));  
      setCircle(new google.maps.Circle({
        center,
        radius,
        map: m,
        options: circleStyles
      }));
    }
    if (m) {
      renderMarkers(m)
    }
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper
        apiKey={GOOGLE_MAPS_API_KEY}
        render={render}
        libraries={["places", "geometry", "drawing"]}
      >
        {(center?.lat && center?.lng &&
          <Map
            center={center}
            onIdle={onIdle}
            renderMarkers={renderMarkers}
            zoom={zoom}
            isMarkerShown={isMarkerShown}
            style={{ flexGrow: "1", height: "100%" }}
            {...options}
          />
        )}
      </Wrapper>
    </div>
  );
};

export default GoogleMaps
