import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers } from '../../Store'
import { Popup } from '../../Beauties'
import {
  PopupAgentsHelpBox,
  PopupAgentsDeleteWrapper,
  PopupEmailChangeCancelWrapper,
  PopupCustomerRemoveWrapper,
  PopupBookingsDeleteWrapper,
  PopupUrlOpenWarningWrapper,
  PopupCustomerEventResourcesWrapper
} from '../../Beasts'
import TwoFADisableWrapper from '../TwoFA/TwoFADisableWrapper'
import TwoFARefreshCodesWrapper from '../TwoFA/TwoFARefreshCodesWrapper'
import PopupSettingsHelpBox from './Content/PopupSettingsHelpBox'
import PopupAccountUnlinkWrapper from './Content/PopupAccountUnlinkWrapper'

export class PopupWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.closePopup = this.closePopup.bind(this)
  }

  closePopup () {
    const { content } = this.props
    handlers.popupHide(content)

    setTimeout(() => {
      handlers.popupSet()
    }, 500)
  }

  render () {
    const { content, ...rest } = this.props

    return (
      <Popup {...rest} closePopup={this.closePopup}>
        {content === 'customers-remove' && <PopupCustomerRemoveWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'customers-event-resources' && <PopupCustomerEventResourcesWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'agents-help-box' && <PopupAgentsHelpBox />}
        {content === 'agents-delete' && <PopupAgentsDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'two-fa-disable' && <TwoFADisableWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'two-fa-refresh-codes' && <TwoFARefreshCodesWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'settings-help-box' && <PopupSettingsHelpBox />}
        {content === 'account-unlink' && <PopupAccountUnlinkWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'email-change-cancel' && <PopupEmailChangeCancelWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'bookings-delete' && <PopupBookingsDeleteWrapper {...rest} closePopup={this.closePopup} />}
        {content === 'url-open-warning' && <PopupUrlOpenWarningWrapper {...rest} closePopup={this.closePopup} />}
      </Popup>
    )
  }
}

PopupWrapper.propTypes = {
  content: PropTypes.string
}

const maps = state => ({
  id: state.popup.id,
  status: state.popup.status,
  position: state.popup.position,
  content: state.popup.content,
  trigger: state.popup.trigger,
  extraClassName: state.popup.extraClassName,
  extras: state.popup.extras
})

export default connect(maps)(PopupWrapper)
