import React from 'react'
import { connect, selectors } from '../../Store'
import { BookingFormNotify } from '../../Beauties'

const BookingFormNotifyWrapper = props => {
  const { hash } = props
  const customerHasEmail = true

  return (
    <BookingFormNotify
      customerHasEmail={customerHasEmail}
      hash={hash}
      resourceNotificationEnabled
      customerNotificationEnabled
    />
  )
}

const maps = state => ({
  hash: selectors.routerFieldSelector(state, { field: 'hash' })
})

export default connect(maps)(BookingFormNotifyWrapper)
