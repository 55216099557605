import { useEffect } from 'react'
import { PopupCustomerEventResources } from '../../../Beauties'
import { connect, handlers, selectors } from '../../../Store'
import { Spinner } from '../../../Common'

const PopupCustomerEventResourcesWrapper = ({ event }) => {
  const { id, areResourcesFetched, resources, resourceCategories } = event || {}
  useEffect(() => {
    handlers.customerEventResourcesGet(id)
  }, [])

  return (
    <div className='ta-popup__customer-event-resources__container'>
      {!areResourcesFetched && <Spinner />}
      {areResourcesFetched && (
        <PopupCustomerEventResources resources={resources} resourceCategories={resourceCategories} />
      )}
    </div>
  )
}

const maps = (state, { extras }) => ({
  event: selectors.customerEventSelector(state, { id: extras.eventId })
})

export default connect(maps)(PopupCustomerEventResourcesWrapper)
