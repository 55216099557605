import { createSelector } from '../../../Utils'

export const agentsSelector = state => state.agents

export const agentsFieldSelector = createSelector(
  agentsSelector,
  (_, props) => props.field,
  (agents, field) => (agents || {})[field]
)

export const agentsListSelector = createSelector(
  agentsSelector,
  agents => (agents || {}).list
)

export const agentsPendingListSelector = createSelector(
  agentsSelector,
  agents => (agents || {}).pendingList
)

export const agentsPendingFormSelector = createSelector(
  agentsSelector,
  agents => (agents || {}).pendingForm
)

export const agentByIdSelector = createSelector(
  agentsListSelector,
  (_, props) => props.id,
  (agents, id) => (agents || []).find(item => item.id === id)
)
