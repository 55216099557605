import React from 'react'
import { Alert, Link, t } from '../../Common'

//
// DO NOT TRANSLATE THIS PAGE
//
// We may not have language at the moment of loading,
// because language is coming from profile
// and 404 page can be riched also on login page

export default () => {
  return (
    <div className='ta-route-not-found'>
      <Alert noOffset label='Page could not be found' theme='no-content'>
        <Link to='/' className='ta-btn ta-btn-primary'>{t('global.returnToMain')}</Link>
      </Alert>
    </div>
  )
}
