import React, { Suspense } from 'react'
import { connect, selectors } from '../../Store'
import { Spinner } from '../../Common'

const AccountWrapper = React.lazy(() => import('../../Beasts/Account/AccountWrapper'))
const AgentsWrapper = React.lazy(() => import('../Agents/AgentsListWrapper'))
const SettingsWrapper = React.lazy(() => import('../Settings/SettingsListWrapper'))
const BookingWrapper = React.lazy(() => import('../../Beasts/Bookings/BookingWrapper'))
// const CustomersWrapper = React.lazy(() => import('../../Beasts/Customers/CustomerWrapper'))

const LeftFirstSidebarWrapper = props => {
  const { route } = props
  const ROUTES_ACCOUNT = ['account', 'accountEdit', 'accountPasswordChange', 'accountEmailChange']
  const ROUTES_AGENTS = ['agents', 'agentsPreview', 'agentsAdd', 'agentsEdit']
  const ROUTES_SETTINGS = ['settings', 'settingsEdit']
  const ROUTES_BOOKING = ['scheduleBooking', 'rescheduleBooking', 'cancelBooking']
  // const ROUTES_CUSTOMER = ['createCustomer', 'editCustomer']

  return (
    <Suspense fallback={<Spinner />}>
      {ROUTES_ACCOUNT.includes(route) && <AccountWrapper />}
      {ROUTES_AGENTS.includes(route) && <AgentsWrapper />}
      {ROUTES_SETTINGS.includes(route) && <SettingsWrapper />}
      {ROUTES_BOOKING.includes(route) && <BookingWrapper />}
      {/* {ROUTES_CUSTOMER.includes(route) && <CustomersWrapper /> } */}
    </Suspense>
  )
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' })
})

export default connect(maps)(LeftFirstSidebarWrapper)
