import React, { Component } from 'react'
import { connect, selectors, handlers } from '../../Store'
import { BookingFormService } from '../../Beauties'
import { t } from '../../Common'

class BookingFormServiceWrapper extends Component {
  constructor(props, context) {
    super(props)
    this.state = {
      filteredServices: props.services || [],
      filteredCourses: props.courses || []
    }

    this.onChangeAddon = this.onChangeAddon.bind(this)
  }

  categorize = (data, type) => {
    const categories = this.props[`${type}Categories`] || []

    return categories
      .map(category => ({
        name: category.name === 'default'
          ? t(`servicesGroups.list.${type === 'courses' ? 'group' : 'service'}.defaultCategoryName`)
          : category.name,
        items: data
          .filter(item => item.categoryId === category.id)
          .map(item => ({ value: item.id, label: item.name, isCombination: item.isCombination }))
      }))
      .filter(category => category.items.length > 0)
  }

  syncDependencies = () => {
    let {
      router,
      type,
      event,
      resources,
      services,
      courses,
      dependencies,
      plan,
      selectedCustomer
    } = this.props
    router = router || {}
    plan = plan || 'CLASSIC'
    courses = courses || []
    services = services || []
    resources = resources || []
    dependencies = dependencies || {}
    const { data } = router
    const selectedResources = dependencies.values || []
    let resourceIds = []
    if (type === 'add') resourceIds = selectedResources.length > 0 ? selectedResources : [data.resource]
    if (type === 'edit') {
      event = event || {}
      resourceIds = selectedResources.length > 0
        ? selectedResources
        : event.resourceIds || []
    }
    const resultCourses = selectedResources.length === 0
      ? [...courses]
      : [...courses].filter(item => {
        let { dependencies } = item
        dependencies = dependencies || []
        const courseResources = dependencies.reduce((acc, dependency) => {
          const dependencyCategory = dependency.specificResourceCategoryIds && dependency.specificResourceCategoryIds[0]
          const dependencyResources = dependency.specificResourceIds
            ? dependency.specificResourceIds.length === 0
              ? resources.map(item => item.id)
              : dependency.specificResourceIds
            : resources
              .filter(item => item.categoryId === dependencyCategory)
              .map(item => item.id)
          acc = acc.concat(dependencyResources)
          return [...new Set(acc)]
        }, [])
        const diffResourcesLength = [...new Set([...courseResources, ...resourceIds])].length
        return diffResourcesLength === courseResources.length
      })

    this.setState({
      filteredServices: services,
      filteredCourses: resultCourses.length ? resultCourses : courses
    })
  }

  componentDidMount() {
    this.syncDependencies()
  }

  onChangeAddon(id) {
    const { allowBookingWithinWorkingTimes, services, isScheduleForm } = this.props

    handlers.formFieldsUpdate('booking', {
      transformedService: { value: {} }
    })
    const service = (services || []).find(item => item.id === id) || {}
    if (id) {
      handlers.formFieldsUpdate('booking', {
        progress: { value: 1 },
        duration: { value: service.duration },
        durationBefore: { value: service.durationBefore },
        durationAfter: { value: service.durationAfter },
        color: { value: service.color },
        price: { value: service.price },
        bookingBranch: { value: null },
        customerAddress: { value: null },
        customerAddressRadius: { value: '5000' },
        selectedBranchAddress: { value: null },
        allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
        from: { value: null },
        timeIntervalMorning: { value: null },
        // keepExistingResource: { value: true },
        timeIntervalNoon: { value: null },
        timeIntervalAfternoon: { value: null },
        timeIntervalEvening: { value: null },
        availabilityDate: { value: null },
        availableSlots: [],
        slots: {},
        notes: { value: '' }
      })
    }
  }

  render() {
    const { filteredServices, filteredCourses } = this.state
    let {
      router,
      plan,
      isRescheduleForm
    } = this.props
    plan = plan || 'CLASSIC'
    router = router || {}
    const { hash } = router

    return (
      <BookingFormService
        hash={hash}
        services={this.categorize(filteredServices, 'services')}
        courses={this.categorize(filteredCourses, 'courses')}
        plan={plan}
        hideColorSelector={false}
        onChangeAddon={this.onChangeAddon}
        isRescheduleForm={isRescheduleForm}
      />
    )
  }
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })

  return {
    router: selectors.routerSelector(state),
    services: selectors.servicesListSelector(state),
    servicesCategories: selectors.servicesCategoriesListSelector(state),
    plan: selectors.companyPlanSelector(state),
    dependencies: selectors.formFieldSelector(state, { formName: 'booking', name: 'dependencies' }),
    event: selectors.bookingsEventByIdSelector(state, { id }),
    allowBookingWithinWorkingTimes: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowBookingWithinWorkingTimes' }),
    selectedCustomer: state.customers.selected
  }
}

export default connect(maps)(BookingFormServiceWrapper)
