import React, { Component } from 'react'
import { MapsWrapper } from '../../Beasts'
// import { MapsWrapper } from '../../Beasts'
import { ContentMessage, VersionIndicator } from '../../Beauties'
import { Link } from '../../Common'

import './Content.css'

class Content extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasUpdate: false,
      version: ''
    }
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose () {
    this.setState(prevState => ({
      hasUpdate: false
    }))
  }

  render () {
    const { position, branchesPending } = this.props
    const positionClassName = position === 'iconOnly' ? 'icon-only' : position
    const classNames = ['ta-content', positionClassName]

    return (
      <div className={classNames.join(' ')}>
        {this.state.hasUpdate && (
          <ContentMessage
            theme='alert'
            active
            title='NEW UPDATE AVAILABLE'
            icon='lightbulb'
            text='Dear customer, we’ve just published a newer version of our desktop application.'
            buttons={(
              <Link action={this.handleInstallUpdate}>
                <div className='ta-content-message-button'>
                  <div className='ta-content-message-button-title'>RESTART AND INSTALL</div>
                  <div className='ta-content-message-button-text'>version {this.state.version}</div>
                </div>
              </Link>
            )}
            closeButton
            closeAction={this.handleClose}
          />
        )}
        {!branchesPending && <MapsWrapper />}
        <VersionIndicator />
      </div>
    )
  }
}

export default Content
