import React, { Component } from 'react'

class OutsideClick extends Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    if (!document.addEventListener && document.attachEvent) {
      document.attachEvent('click', this.handleClick)
      document.attachEvent('touchend', this.handleClick)
    } else {
      document.addEventListener('click', this.handleClick)
      document.addEventListener('touchend', this.handleClick)
    }
  }

  componentWillUnmount () {
    if (!document.removeEventListener && document.detachEvent) {
      document.detachEvent('click', this.handleClick)
      document.detachEvent('touchend', this.handleClick)
    } else {
      document.removeEventListener('click', this.handleClick)
      document.removeEventListener('touchend', this.handleClick)
    }
  }

  handleClick (e) {
    const { target } = e || {}
    const { onClickOutside } = this.props
    if (this.wrapper && this.wrapper.contains(target)) return
    if (this.wrapper &&
      (target.classList.contains('react-datepicker__day') ||
      target.classList.contains('react-datepicker__navigation') ||
      target.classList.contains('ta-calendar-day-input__custom-input') ||
      target.classList.contains('ta-calendar-week-input__custom-input') ||
      target.classList.contains('ta-calendar-day-input__week-label') ||
      target.classList.contains('ta-calendar-week-input__week-label') ||
      target.classList.contains('ta-calendar-month-input__custom-input') ||
      target.classList.contains('ta-calendar-month-input__month-label') ||
      target.classList.contains('ta-calendar-month-input__current-year__label') ||
      target.classList.contains('fa-calendar-alt')
      )
    ) return
    onClickOutside && onClickOutside(e)
  }

  render () {
    const { onClickOutside, children, ...rest } = this.props

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} {...rest} data-testid='outside-click'>
        {children}
      </div>
    )
  }
}

export default OutsideClick
