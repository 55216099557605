import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import {
  usersTransform,
  usersFormValidate,
  usersSaveTransform,
  usersFormServerErrorsTransform
} from './utils'

// Global actions
globalActions.transformUsers = users => {
  return usersTransform(users)
}

// List
payloads$(actions.USERS_LIST_GET)
  .subscribe(async () => {
    const users = await q('getEnterpriseUsers')
    const { error } = users
    if (error) return error
    handlers.usersListPopulate({
      users: users.map(user => ({
        ...user
      }))
    })
  })

// List Filters
// payloads$(actions.FORM_FIELDS_UPDATE)
//   .pipe(
//     filter(fields => fields.form === 'usersFilters'),
//     tap(() => handlers.usersListPending()),
//     debounceTime(500)
//   ).subscribe(() => {
//     handlers.usersSearch()
//   })

// Preview
payloads$(actions.USERS_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    if (!id) {
      handlers.navigateToPath('/users')
      return
    }
    const state = store.getState()
    const { list = [] } = state.users
    const selectedUser = list.find(user => user.id === id) || {}
    const { isComplete } = selectedUser
    if (isComplete && !forceFetch) {
      handlers.usersPreviewPopulate(id)
      return
    }
    const user = (await q('getEnterpriseUser', { id })) || { error: { text: 'Not found ' } }
    const { error } = user
    if (error) return
    handlers.usersUpdate({ ...user, isComplete: true })
    handlers.usersPreviewPopulate(id)
  })

// Form
payloads$(actions.USERS_FORM_GET)
  .subscribe(async id => {
    const state = store.getState()
    // const { query = {} } = state.router
    const { list = [] } = state.users

    if (!id) {
      handlers.usersFormPopulate()
      return
    }
    const selectedUser = list.find(user => user.id === id) || {}
    const { isComplete } = selectedUser

    const user = isComplete
      ? selectedUser
      : usersTransform(await q('getEnterpriseUser', { id }))

    const { error } = user
    if (error) {
      handlers.navigateToPath(id ? `/users/${id}` : '/users')
      return
    }
    handlers.usersUpdate({ ...user, isComplete: true })
    handlers.usersFormPopulate({
      ...user
    })
  })

// Save
payloads$(actions.USERS_FORM_SAVE)
  .subscribe(async ({ user, scrollToError }) => {
    const state = store.getState()
    const { users } = state
    const { list: usersList } = users || {}
    const { id: userId } = user || {}
    const userEmails = usersList.filter(userItem => userItem.id !== userId).map(item => item.email) || []
    const errors = usersFormValidate({ user, userEmails })
    if (errors.length) return setUsersFormSaveErrors(errors, scrollToError)
    const savedUser = await q('saveEnterpriseUser', { user: usersSaveTransform(user) })
    const { error, id } = savedUser || {}
    if (error) return setUsersFormSaveErrors(usersFormServerErrorsTransform(error), scrollToError)
    handlers.usersUpdate({ ...savedUser, isComplete: true })
    handlers.navigateToPath(`/users/${id}/preview`)
  })

const setUsersFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('users', errors)
  scrollToError && scrollToError(errors)
  handlers.usersFormReady()
}

// Update
payloads$(actions.USERS_UPDATE)
  .subscribe(async user => {
    if (!user) return handlers.usersPreviewPopulate()
    setTimeout(() => handlers.usersUpdated(user), 2000)
  })

// Delete
payloads$(actions.USERS_DELETE)
  .subscribe(async id => {
    if (!id) return handlers.usersPreviewPopulate()
    const { error } = await q('deleteEnterpriseUser', { id })
    if (error) return
    handlers.usersDeleted(id)
    setTimeout(() => handlers.usersRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/users'), 0)
  })
