import React from 'react'
import PropTypes from 'prop-types'
import {
  MenuWrapper,
  AccountsSwitchWrapper,
  NavigationAccountWrapper
} from '../../Beasts'
import { Online } from '../../Common'

import './Navigation.css'

const Navigation = props => {
  const {
    pending,
    accountsCount,
    notOwnerAccountsCount,
    position,
    isHover,
    negativeLevel,
    disabled,
    changePosition,
    onMouseEnter,
    onMouseLeave
  } = props
  const isExpanded = !position || position === 'expanded'
  const isCollapsed = position === 'collapsed'
  const isIconOnly = position === 'iconOnly'
  let showAccountSwitch = accountsCount > 1
  if (accountsCount === 1 && notOwnerAccountsCount === 1) showAccountSwitch = false
  const classNames = ['ta-navigation']
  if (disabled || isExpanded) classNames.push('expanded')
  if (isCollapsed) classNames.push('collapsed')
  if (isIconOnly) classNames.push('icon-only')
  if (isHover) classNames.push('hover')
  if (negativeLevel) classNames.push(`negative-${negativeLevel}`)
  if (pending) classNames.push('pending')
  if (showAccountSwitch) classNames.push('show-account-switch')
  const expandClassNames = ['ta-navigation__expand']
  if (!disabled && isCollapsed) expandClassNames.push('active')

  return (
    <>
      <div className={classNames.join(' ')}>
        <div className='ta-navigation__top__intro'>
          <div />
          <span>CALL CENTRE</span>
        </div>
        <NavigationAccountWrapper />
        {showAccountSwitch && (
          <Online>
            <AccountsSwitchWrapper negativeLevel={negativeLevel} />
          </Online>
        )}
        <MenuWrapper />
      </div>
      <div
        className={expandClassNames.join(' ')}
        onClick={() => changePosition('expanded')}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  )
}

PropTypes.propTypes = {
  status: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  collapse: PropTypes.func.isRequired
}

export default Navigation
