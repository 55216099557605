import React from 'react'
import PropTypes from 'prop-types'
import { Logo } from '../../../Beauties'

import './BlueTemplate.css'

const BlueTemplate = props => {
  const { logoUrl, children } = props

  return (
    <div className='ta-blue-template' data-testid='blue-template'>
      <Logo to={logoUrl} />
      {children}
    </div>
  )
}

BlueTemplate.propTypes = {
  locale: PropTypes.string
}

export default BlueTemplate
