import React from 'react'
import { sliders } from '../../../Assets/images'
import { Row, Col, FontAwesome5, format, t } from '../../../Common'
import { BookingFormResourceAvatars } from '../../../Beauties'

import './BookingForm.css'

const BookingFormSlotPreferencesHeader = ({ form, selectedResources, selectedResourceCategories, isResourceSelectorEnabled }) => {
  let {
    allowanceType,
    from,
    timeIntervalMorning,
    timeIntervalNoon,
    timeIntervalAfternoon,
    timeIntervalEvening
  } = form || {}
  allowanceType = allowanceType || {}
  from = from || {}
  timeIntervalMorning = timeIntervalMorning || {}
  timeIntervalNoon = timeIntervalNoon || {}
  timeIntervalAfternoon = timeIntervalAfternoon || {}
  timeIntervalEvening = timeIntervalEvening || {}
  const time = []
  if (timeIntervalMorning.value) time.push(t('calendar.form.widgetBooking.preferredTimeframe.checkboxTimeframe.morning.label'))
  if (timeIntervalNoon.value) time.push(t('calendar.form.widgetBooking.preferredTimeframe.checkboxTimeframe.noon.label'))
  if (timeIntervalAfternoon.value) time.push(t('calendar.form.widgetBooking.preferredTimeframe.checkboxTimeframe.afternoon.label'))
  if (timeIntervalEvening.value) time.push(t('calendar.form.widgetBooking.preferredTimeframe.checkboxTimeframe.evening.label'))

  return (
    <div className='ta-booking__slot-preferences-header'>
      <div style={{ flex: '10%' }}>
        <img src={sliders} width='50' alt='sliders' />
      </div>
      <div className='ta-booking__slot-preferences-header__content'>
        <div className='ta-booking_form-slot-preferences__title'>
          {allowanceType.value === 'WORKING'
            ? t('scheduleBooking.withinWorkingTimes.label')
            : t('scheduleBooking.withinBookingTimes.label')}
        </div>
        <div className='ta-booking_form-slot-preferences__row'>
          <FontAwesome5 type='solid' icon='calendar-day' />
          <span className='ta-booking_form-slot-preferences__time'>
            {from.value && format(from.value, 'DD/MM/YYYY')}
            {!from.value && t('scheduleBooking.slotSection.asSoonAsPossible')}
          </span>
        </div>
        <div className='ta-booking_form-slot-preferences__row flex'>
          <div>
            <FontAwesome5 type='solid' icon='clock' />
            <span className='ta-booking_form-slot-preferences__time'>{time.join(', ') || t('global.anyTime')}</span>
          </div>
          {isResourceSelectorEnabled && !!selectedResources.length && (
            <div className='ta-booking_form-slot-preferences__avatars'>
              <FontAwesome5 type='s' icon='user-friends' />
              <BookingFormResourceAvatars
                resources={selectedResources}
                resourceCategories={selectedResourceCategories}
                listPopupPosition='top'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookingFormSlotPreferencesHeader
