import React from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Menu } from '../../Beauties'
import { menu } from '../../Settings'

const MenuWrapper = props => {
  const { route } = props
  const exceptions = []

  const filteredMenu = menu
    .filter(item => !exceptions.includes(item.id))
    .map(item => {
      const result = {
        ...item,
        submenu: item.submenu
          ? item.submenu.filter(subItem => !exceptions.includes(subItem.id))
          : null
      }
      if (result.submenu && result.submenu.length === 1) {
        const subItem = result.submenu[0] || {}
        result.to = subItem.to
        result.routes = [...(result.routes || []), ...(subItem.routes || [])]
        result.submenu = null
      }
      return result
    })

  const onClickMenuItem = to => {
    let { router, formsWithUnsavedChanges } = props
    router = router || {}
    formsWithUnsavedChanges = formsWithUnsavedChanges || []
    let { props: routerProps, path } = router || {}
    if (path === to) return
    routerProps = routerProps || {}
    const { formDiscard } = routerProps || {}
    if (formDiscard && formsWithUnsavedChanges.includes(formDiscard)) {
      return handlers.formDiscardPopupSet(formDiscard, to)
    }
    handlers.navigateToPath(to)
  }

  return (
    <Menu menu={filteredMenu} route={route} onClickMenuItem={onClickMenuItem} />
  )
}

MenuWrapper.propTypes = {
  route: PropTypes.string
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' }),
  router: selectors.routerSelector(state),
  formsWithUnsavedChanges: selectors.formSelector(state, { formName: 'formsWithUnsavedChanges' })
})

export default connect(maps)(MenuWrapper)
