import React from 'react'

const SimpleListItemTitle = props => {
  const { title, children } = props
  const hasIcon = !!children
  const titleClassNames = ['ta-simple-list-item__title-text']
  if (hasIcon) titleClassNames.push('has-icon')

  return (
    <div className='ta-simple-list-item__title' data-testid='simple-list-item-title'>
      <div className={titleClassNames.join(' ')} title={title}>{title}</div>
      {hasIcon &&
        <div className='ta-simple-list-item__title-icon'>{children}</div>
      }
    </div>
  )
}

export default SimpleListItemTitle
