export default locale => {
  switch (locale) {
    case 'bg-bg':
      return 'bg'
    case 'de-de':
    case 'de-ch':
      return 'de'
    case 'de-at':
      return 'de-AT'
    case 'en-au':
      return 'en-AU'
    case 'en-ca':
      return 'en-CA'
    case 'en-gb':
    case 'en-ie':
    case 'en-mt':
    case 'en-ph':
    case 'en-sg':
      return 'en-GB'
    case 'en-in':
      return 'en-IN'
    case 'en-us':
      return 'en-US'
    case 'et-ee':
      return 'et'
    case 'fi-fi':
      return 'fi'
    case 'hu-hu':
      return 'hu'
    case 'ja-jp':
      return 'ja'
    case 'ru-ru':
      return 'ru'
    case 'sv-se':
      return 'sv'
    case 'pl-pl':
      return 'pl'
    case 'es-es':
    case 'es-mx':
    case 'es-co':
    case 'es-cl':
      return 'es'
    case 'fr-fr':
    case 'fr-be':
    case 'fr-lu':
      return 'fr'
    case 'fr-ca':
      return 'fr-CA'
    case 'fr-ch':
      return 'fr-CH'
    case 'it-it':
      return 'it'
    case 'nl-be':
      return 'nl-BE'
    case 'nl-nl':
      return 'nl'
    case 'cs-cz':
      return 'cs'
    case 'pt-pt':
      return 'pt'
    case 'ko-kr':
      return 'ko'
    default:
      return 'en-GB'
  }
}
