import React from 'react'
import { Row, Col, FontAwesome5, Alert, Price, t } from '../../../Common'
import './BookingForm.css'

const BookingFormServiceAdhocHeader = ({
  bufferTimesStyles,
  bufferTimesContentContentStyles,
  form,
  service,
  transformedService,
  splitDurationInIntervals
}) => {
  transformedService = transformedService || {}
  service = service || {}
  let { duration, durationAfter, intervals, durationBefore, price, bookingBranch } = form || {}
  let { value: bookingBranchValue } = bookingBranch
  bookingBranchValue = bookingBranchValue || ''
  const isUpdatedLocally = bookingBranch && (service && !!service.locallyUpdatedBranches?.[bookingBranchValue] || (service.combinationEvents || []).some(event => !!service.locallyUpdatedBranches?.[bookingBranchValue]))
  duration = !splitDurationInIntervals ? duration : { value: intervals.values.reduce((item, acc) => item + acc, 0) }
  if (isUpdatedLocally && transformedService.combinationEvents && transformedService.combinationEvents.length > 0) {
    duration = { value: transformedService.combinationEvents.reduce((acc, ev) => { return ev.duration + acc }, 0) }
  }

  durationAfter = durationAfter || {}
  durationBefore = durationBefore || {}
  price = price || {}
  const bufferTimesContentClassNames = ['ta-buffer-times__box__content']
  if (durationBefore.value) bufferTimesContentClassNames.push('top-offset')
  if (durationAfter.value) bufferTimesContentClassNames.push('bottom-offset')

  return (
    <Row className='ta-booking_form-service-adhoc__header-content'>
      <Col>
        <Row>
          <Col size={10}>
            <div className='ta-buffer-times__box' style={bufferTimesStyles}>
              <div className={bufferTimesContentClassNames.join(' ')} style={bufferTimesContentContentStyles} />
            </div>
          </Col>
          <Col size={90}>
            <div className='ta-booking_form-service-adhoc__title'>{(service.name || transformedService && transformedService.name)}</div>
            <div className='ta-booking_form-service-adhoc__row'>
              <FontAwesome5 type='solid' icon='clock' />
              <span className='ta-booking_form-service-adhoc__time'>
                {t('global.minutesWithFigure', [{ key: 'VALUE', value: duration.value || transformedService.duration || '0' }])}
              </span>
            </div>
            <div className='ta-booking_form-service-adhoc__row right'>
              <FontAwesome5 type='solid' icon='arrows-alt-v' />
              <span className='ta-booking_form-service-adhoc__time ta-booking_form-service-adhoc__push_right'>
                {t('global.minutesWithFigure', [{ key: 'VALUE', value: durationBefore.value || transformedService.durationBefore || '0' }])}
              </span>
              <FontAwesome5 type='solid' icon='arrows-alt-v' />
              <span className='ta-booking_form-service-adhoc__time'>
                {t('global.minutesWithFigure', [{ key: 'VALUE', value: durationAfter.value || transformedService.durationAfter || '0' }])}
              </span>
            </div>
            {price && !!price.value && (
              <div className='ta-booking_form-service-adhoc__row'>
                <FontAwesome5 type='solid' icon='money-bill' />
                <span className='ta-booking_form-service-adhoc__time'>
                  {t('global.price.label')} <Price price={price.value || transformedService.price} />
                </span>
              </div>
            )}
          </Col>
        </Row>
        {isUpdatedLocally && (
          <Row noOffset={!(transformedService && transformedService.isStalled) && transformedService !== null}>
            <Col>
              <Alert theme='alert' noOffset>{t('booking.form.service.message.isUpdatedLocally')}</Alert>
            </Col>
          </Row>
        )}
        {transformedService && transformedService.isStalled && (
          <Row noOffset={transformedService !== null}>
            <Col>
              <Alert theme='alert' noOffset>{t('booking.form.service.message.isStalled')}</Alert>
            </Col>
          </Row>
        )}
        {transformedService === null && (
          <Alert theme='alert' noOffset>{t('booking.form.service.message.notFound')}</Alert>
        )}
      </Col>
    </Row>
  )
}

export default BookingFormServiceAdhocHeader
