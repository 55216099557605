import React, { useEffect } from 'react'
import { connect, globalActions, handlers, selectors } from '../../../Store'
import { BookingFormSuccess } from '../../../Beauties'

const BookingSuccessWrapper = props => {
  let {
    savedBookings,
    allowBookingWithinWorkingTimes,
    isCancellationForm,
    isScheduleForm,
    isRescheduleForm,
    selectedCustomer,
    accordionRefs,
    form,
    fieldsToBeDeleted,
    selectedBranch,
    oldBooking,
    branches,
    settings,
    persist,
    resources,
    resourceCategories,
    newBookingActivated,
    rescheduleBookingActivated,
    displayBookedResources
  } = props

  form = form || {}
  oldBooking = oldBooking || {}
  savedBookings = savedBookings || []
  accordionRefs = accordionRefs || {}
  resources ||= []
  resourceCategories ||= []
  const isResourceSelectorEnabled = isRescheduleForm ? rescheduleBookingActivated : newBookingActivated
  const emptyCustomerFields = Object.keys(form)
    .filter(item => item.includes('customerFields'))
    .reduce((acc, item) => ({ ...acc, [item]: { value: null } }), {})

  useEffect(() => {
    globalActions.populateCustomerFieldsAndCategories(true)
    handlers.formDiscardPopupReset('booking')
  }, [])

  const onCreateNew = () => {
    handlers.formFieldsUpdate('booking', {
      bookingCustomer: { value: null },
      progress: { value: 0 },
      service: { value: null },
      duration: { value: null },
      durationBefore: { value: null },
      durationAfter: { value: null },
      bookingBranch: { value: null },
      customerAddress: { value: null },
      customerAddressRadius: { value: '5000' },
      selectedBranchAddress: { value: null },
      allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
      from: { value: null },
      timeIntervalMorning: { value: null },
      // keepExistingResource: { value: true },
      timeIntervalNoon: { value: null },
      timeIntervalAfternoon: { value: null },
      timeIntervalEvening: { value: null },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      step: { value: null },
      notes: { value: null },
      hasNotifyResources: { value: null },
      hasNotifyCustomers: { value: null },
      accordionRefs: { value: {} },
      transformedCustomer: {},
      ...emptyCustomerFields
    })
    handlers.bookingFormPopulate({ persist }, settings)
    handlers.customerPreviewPopulate(null)
    handlers.formSet('customers', null)
    handlers.formErrorsSet('booking', [])
  }

  const onCreateFollowup = () => {
    const customerAccordionRef = accordionRefs[0] || {}
    const { state: customerAccordionState } = customerAccordionRef
    const {
      expand: isCustomerAccordionExpanded,
      toggle: toggleCustomerAccordion
    } = customerAccordionState || {}
    handlers.formFieldsUpdate('booking', {
      progress: { value: 1 },
      service: { value: null },
      duration: { value: null },
      durationBefore: { value: null },
      durationAfter: { value: null },
      bookingBranch: { value: null },
      customerAddress: { value: null },
      customerAddressRadius: { value: '5000' },
      selectedBranchAddress: { value: null },
      allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
      from: { value: null },
      timeIntervalMorning: { value: null },
      // keepExistingResource: { value: true },
      timeIntervalNoon: { value: null },
      timeIntervalAfternoon: { value: null },
      timeIntervalEvening: { value: null },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      step: { value: null },
      notes: { value: null },
      hasNotifyCustomers: {
        value: settings?.hasCCNotifyOnCustomers === 'SELECTED'
          ? persist?.hasNotifyCustomersBooking || false
          : settings?.hasCCNotifyOnCustomers === 'TRUE'
      },
      hasNotifyResources: {
        value: settings?.hasCCNotifyOnResources === 'SELECTED'
          ? persist?.hasNotifyResourcesBooking || false
          : settings?.hasCCNotifyOnResources === 'TRUE'
      },
      accordionRefs: { value: { 0: customerAccordionRef } },
      transformedCustomer: {},
      ...emptyCustomerFields,
      bookingCustomer: { value: selectedCustomer }
    })
    handlers.customerPreviewPopulate(selectedCustomer)
    handlers.formErrorsSet('booking', [])
    if (isCustomerAccordionExpanded && toggleCustomerAccordion) toggleCustomerAccordion()
  }

  const onRescheduleAgain = () => {
    if (isCancellationForm) {
      fieldsToBeDeleted.forEach(field => {
        handlers.formFieldsUpdate('booking', { [field]: { value: false } })
      })
    }
    const customerAccordionRef = accordionRefs[0] || {}
    const { state: customerAccordionState } = customerAccordionRef
    const {
      expand: isCustomerAccordionExpanded,
      toggle: toggleCustomerAccordion
    } = customerAccordionState || {}
    handlers.formFieldsUpdate('booking', {
      search: { value: selectedCustomer && selectedCustomer.fullName },
      progress: { value: 0 },
      service: { value: null },
      duration: { value: null },
      durationBefore: { value: null },
      durationAfter: { value: null },
      bookingBranch: { value: null },
      customerAddress: { value: null },
      customerAddressRadius: { value: '5000' },
      selectedBranchAddress: { value: null },
      allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
      from: { value: null },
      timeIntervalMorning: { value: null },
      // keepExistingResource: { value: true },
      timeIntervalNoon: { value: null },
      timeIntervalAfternoon: { value: null },
      timeIntervalEvening: { value: null },
      availabilityDate: { value: null },
      hasNotifyResources: { value: true, disabled: true },
      hasNotifyCustomers: { value: true, disabled: true },
      availableSlots: [],
      slots: {},
      step: { value: null },
      notes: { value: null },
      accordionRefs: { value: { 0: customerAccordionRef } },
      transformedCustomer: {},
      ...emptyCustomerFields,
      bookingCustomer: { value: null },
      transformedService: { value: null },
      password: { value: '', type: 'password' }
    })
    handlers.bookingFormCustomerSearch()
    handlers.customerPreviewPopulate(selectedCustomer)
    handlers.customerBookingsGet()
    handlers.formErrorsSet('booking', [])
    if (!isCustomerAccordionExpanded && toggleCustomerAccordion) toggleCustomerAccordion()
  }

  return (
    <BookingFormSuccess
      onCreateNew={onCreateNew}
      selectedBranch={selectedBranch}
      onCreateFollowup={onCreateFollowup}
      onRescheduleAgain={onRescheduleAgain}
      savedBookings={savedBookings}
      branches={branches}
      oldBooking={oldBooking}
      isScheduleForm={isScheduleForm}
      isRescheduleForm={isRescheduleForm}
      isCancellationForm={isCancellationForm}
      resources={resources}
      resourceCategories={resourceCategories}
      isResourceSelectorEnabled={isResourceSelectorEnabled}
      displayBookedResources={displayBookedResources}
    />
  )
}

const maps = state => ({
  savedBookings: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'savedBookings', property: 'values' }),
  oldBooking: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }),
  bookingCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingCustomer', property: 'value' }),
  selectedCustomer: state.customers.selected,
  allowBookingWithinWorkingTimes: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowBookingWithinWorkingTimes' }),
  accordionRefs: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'accordionRefs', property: 'value' }),
  selectedBranch: state.branches.selected,
  branches: state.branches.list,
  form: selectors.formSelector(state, { formName: 'booking' }),
  isCancellationForm: state.router.name === 'cancelBooking',
  fieldsToBeDeleted: Object.keys(state.forms.booking).filter(key => key.includes('bookingForDeletion') && !!state.forms.booking[key].value),
  settings: selectors.accountCallCentreSettingsSelector(state),
  persist: state.persist,
  resources: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resources', property: 'values' }),
  resourceCategories: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resourceCategories', property: 'values' }),
  newBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'newBookingActivated' }),
  rescheduleBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'rescheduleBookingActivated' }),
  displayBookedResources: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'displayBookedResources' })
})

export default connect(maps)(BookingSuccessWrapper)
