import React from 'react'

const SimpleTabsHeader = (props) => {
  const { children } = props

  return (
    <div className='ta-simple-tabs__header' data-testid='simple-tabs-header'>
      {children}
    </div>
  )
}

export default SimpleTabsHeader
