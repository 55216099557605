import { useEffect } from "react";
import useDeepCompareMemoize from "./useDeepCompareMemoize";

const useDeepCompareEffectForMaps = (
  callback,
  dependencies
) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export default useDeepCompareEffectForMaps