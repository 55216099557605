import React from 'react'
import {
  Title,
  Loader,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  Online,
  HoverPopupTrigger,
  t,
  Blur
} from '../../Common'
import { PopupTriggerWrapper } from '../../Beasts'
import { AgentsList, AgentsFilters } from '../../Beauties'

import './Agents.css'

const Agents = props => {
  const {
    available,
    total,
    pending,
    search,
    agents,
    agentsRead,
    agentsWrite
  } = props

  const addBtnClassNames = ['ta-btn', 'ta-btn-primary', 'ta-btn-icon-only']
  available <= 0 && addBtnClassNames.push('disabled')

  return (
    <div className='ta-agents'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-agents__title'>
            <Title label={t('global.agents')}>
              <PopupTriggerWrapper name='agents-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {(agentsWrite &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('agents.list.buttonHeading.addTooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Online>
                      <Link className={addBtnClassNames.join(' ')} disabled={available <= 0} to='/agents/add'>
                        <FontAwesome5 icon='plus' />
                      </Link>
                    </Online>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
          {agentsRead && <AgentsFilters />}
        </FixedContentHeader>
        <FixedContentBody>
          <Blur active={!agentsRead} icon='ban' label={t('global.accessDenied')}>
            {(agentsRead &&
              <>
                <Loader active={pending} />
                {(!pending &&
                  <AgentsList
                    search={search}
                    available={available}
                    total={total}
                    agents={agents}
                    agentsWrite={agentsWrite}
                  />
                )}
              </>
            )}
          </Blur>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default Agents
