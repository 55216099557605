import { useRef, useState, useEffect, Children, isValidElement, cloneElement } from 'react'
import { useDeepCompareEffectForMaps } from './hooks'

const Map = ({
  onIdle,
  children,
  style,
  isMarkerShown,
  renderMarkers,
  ...options
}) => {
  const ref = useRef(null);
  const [map, setMap] = useState();
  useEffect(() => {
    if (ref.current && !map) {
      const initialMap = new window.google.maps.Map(ref.current, {})
      setMap(initialMap);
    }
  }, [ref, map]);
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  useEffect(() => {
    if (map) {
      ["idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);
  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map