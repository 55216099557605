import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'

import { Error, t } from '../../Common'
import { dangerousHTML, extractFromHtml, removeMultipleSpaces, removeImgFromString } from '../../Utils'

import './RichText.css'
import 'react-quill/dist/quill.snow.css'

const RichText = (props) => {
  let {
    className,
    label,
    size,
    value,
    hideLink,
    onChange,
    maxLength,
    maxWords,
    hintText,
    isLabelMandatory,
    hideError,
    name,
    placeholder,
    disabled,
    hasError
  } = props
  size = size || 'small'
  if (isLabelMandatory) label = `${label} *`
  const quillRef = useRef()
  const [focused, setFocused] = useState(false)
  const [changed, setChanged] = useState(false)
  // const [fullscreen, setFullscreen] = useState(false)
  const [currentLength, setCurrentLength] = useState(0)

  const handleOnChange = (value, _, source) => {
    // Only trigger onChange if user types in the box
    if (source === 'user') {
      onChange(removeImgFromString(removeHighlight(value)))
      setChanged(true)
    }
  }

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)
  }

  const onKeyUp = (e) => {
    if (typeof onChange !== 'undefined') {
      const editor = quillRef.current.getEditor()
      const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor)

      if (maxLength || maxWords) {
        checkWordsCount(e)
      } else {
        onChange(unprivilegedEditor.getHTML())
      }
    }
  }

  const checkWordsCount = event => {
    if (event && event.key === 'Meta') {
      const editor = quillRef.current.getEditor()
      const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor)
      const htmlContent = unprivilegedEditor.getHTML()

      // strip html tags to get real string length
      const strippedString = extractFromHtml(htmlContent)
      let error = false

      if (maxLength) {
        if (strippedString.length >= +maxLength && event.key !== 'Backspace') {
          event.preventDefault()
          error = true
        }
        // second param represents error
        onChange(htmlContent, error)
        setCurrentLength(strippedString.length)
      }

      if (maxWords) {
        if (strippedString.split(' ').length > +maxWords && event.key !== 'Backspace') {
          event.preventDefault()
          error = true
        }
        // second param represents error
        onChange(htmlContent, error)
        setCurrentLength(strippedString.split(' ').length)
      }
    }
  }

  const checkCharacterCount = (event) => {
    const editor = quillRef.current.getEditor()
    const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(editor)

    // strip html tags to get real string length
    const strippedString = extractFromHtml(unprivilegedEditor.getHTML())
    const isKeyAllowed = event && ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)
    if (maxLength) {
      if (strippedString.length >= maxLength && !isKeyAllowed) {
        event.preventDefault()
      }
      setCurrentLength(strippedString.length)
    }

    if (maxWords) {
      if ((strippedString.split(' ').length + 1 >= +maxWords + 1) && !isKeyAllowed) {
        event.preventDefault()
      }
      setCurrentLength(strippedString.split(' ').length)
    }
  }

  const getPureValue = () => {
    // count spaces when typing
    if (changed) return extractFromHtml(value)
    // dont count spaces when opening the form
    return extractFromHtml(removeMultipleSpaces(value)).trim()
  }

  const removeHighlight = value => (value || '').replace(/color:\s([^;]+);/ig, '')

  const pureValue = getPureValue()
  let counter = 0
  if (maxLength && !maxWords && pureValue) {
    counter = pureValue.length
  } else if (!maxLength && maxWords && pureValue) {
    counter = currentLength
  }

  let limitLabel = ''
  if (maxLength) limitLabel = maxLength
  if (maxWords) limitLabel = maxWords

  const mainClasses = ['ta-rich-text']
  if (className) mainClasses.push(className)
  if (hasError) mainClasses.push('hasError')
  if (focused) mainClasses.push('focused')
  if (disabled) mainClasses.push('disabled')
  // if (fullscreen) mainClasses.push('fullscreen')

  const editorClasses = ['ta-rich-text__editor']
  if (pureValue !== '') editorClasses.push('filled')
  if (focused) editorClasses.push('focused')
  if (disabled) editorClasses.push('disabled')
  // Make editor default tall 400px
  if (size === 'big') editorClasses.push('tall')
  if (size === 'medium') editorClasses.push('medium')
  if (size === 'small') editorClasses.push('short')

  let style = {}
  let bounceClass = ''
  if (maxLength && pureValue.length > maxLength) {
    style = { color: 'red' }
    bounceClass = 'ta-rich-text__bounce'
  }
  if (maxWords && currentLength > maxWords) {
    style = { color: 'red' }
    bounceClass = 'ta-rich-text__bounce'
  }

  // Add toolbar icons
  const toolbarButtons = ['bold', 'italic', 'underline']
  // remove link if needed
  if (!hideLink) toolbarButtons.push('link')

  const { tooltip } = (quillRef && quillRef.current && quillRef.current.getEditor().theme) || {}
  if (tooltip && tooltip.root) {
    tooltip.root.setAttribute('data-before-enter', `${t('servicesGroups.form.description.link.enterUrl')}:`)
    tooltip.root.setAttribute('data-before-visit', `${t('servicesGroups.form.description.link.visit')}:`)
    const actionButton = tooltip.root.querySelector('a.ql-action')
    actionButton.setAttribute('data-after-save', t('buttons.save.label'))
    actionButton.setAttribute('data-after-edit', t('global.edit'))
    const removeButton = tooltip.root.querySelector('a.ql-remove')
    removeButton.setAttribute('data-before', t('global.delete'))
  }

  return (
    <div className={mainClasses.join(' ')} data-testid='rich-text'>
      <div className='ta-rich-text__container'>
        <div className='ta-rich-text__label' data-testid='rich-text-label'>{dangerousHTML(label)}</div>
        <div className='ta-rich-text__wrapper'>
          <ReactQuill
            ref={quillRef}
            className={editorClasses.join(' ')}
            modules={{ toolbar: toolbarButtons }}
            formats={['bold', 'italic', 'underline', 'link']}
            defaultValue={value} // important, see https://github.com/zenoamaro/react-quill/issues/309#issuecomment-503545398
            value={value}
            readOnly={disabled}
            onChange={handleOnChange}
            onKeyUp={onKeyUp}
            onKeyDown={checkCharacterCount}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
          />
          {(maxLength || maxWords) && (
            <span className={['ta-rich-text__length', bounceClass].join(' ')} style={style} data-testid='rich-text-length'>
              <strong>{counter}</strong> / {limitLabel}
            </span>
          )}
        </div>
        {/* <button type='button' className='ql-fullscreen' onClick={toggleFullscreen}>
          <FontAwesome5 icon={fullscreen ? 'compress' : 'expand'} type='s' />
        </button> */}
        <div className='ta-rich-text__label-bottom' data-testid='rich-text-hint'>
          {dangerousHTML(hintText)}
        </div>
      </div>
      {!hideError && (
        <Error name={name} className='ta-clear' />
      )}
    </div>
  )
}

RichText.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  hideLink: PropTypes.bool,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  maxWords: PropTypes.number,
  hintText: PropTypes.string,
  isLabelMandatory: PropTypes.bool,
  hideError: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool
}

export default RichText
