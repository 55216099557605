import { serviceFieldsForList, serviceCategoryFields } from '../Services/fields'
import { courseFieldsForList, courseCategoryFields } from '../Courses/fields'

export const eugdprRuleFields = `{
  customerType
  customerTagId
  frequency
  frequencyUnit
}`

export const companySessionFields = `{
  value
  mode
}`

export const minMaxLeadTimeFields = `{
  value
  frequency
}`

export const companySettingsFields = `{
  snapToGridInterval
  internalSnapToGridInterval
  showAddServiceCombinations
  hasFacebookLogin
  hasTimifyLogin
  hasGuestBooking
  cancellationLeadTimeHours
  resourceEmailRemindersMinutes
  customerEmailRemindersMinutes
  showWidgetLanguageSelector
  isWidgetWeeklyView
  showWidgetCategoriesClosed
  showWidgetFirstCategoryOpen
  showWidgetTimezoneSelector
  customTermsAndConditionsUrl
  customPrivacyPolicyUrl
  customOfflineBookingMessage
  widgetExternalUrlRedirect
  hideServiceDuration
  hideResourcesSection
  hasNotifyOnResources
  hasNotifyOnCustomers
  showExternalIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  resourceRemindersEmailRecipients
  customerRemindersEmailRecipients
  resourceBookingCreateEmailRecipients
  resourceBookingUpdateEmailRecipients
  resourceBookingDeleteEmailRecipients
  customerBookingCreateEmailRecipients
  customerBookingUpdateEmailRecipients
  customerBookingDeleteEmailRecipients
  enforceResourcesAllowanceType
  enforceReschedulingTagRule
  enforceServiceDurationLimit
  resetCopyBookingClipboardAfterPaste
  hideFastWidgetTimeFrame
  allowUserLocaleForCustomers
  allowUserLocaleForResources
  EUGDPRRules ${eugdprRuleFields}
  forbidMoreThanOneBookingInFBW
  forbidCreatingAndUpdatingBookingsInThePast
  securitySessionConfig ${companySessionFields}
  securitySessionInactivityConfig ${companySessionFields}
  requiredAuth2FAType
  publicHolidays {
    id
    resourceIds
    publicHolidayKeys
    resourceCategoryIds
  }
  footfallMapping {
    isActive
    intervals {
      begin
      end
      footfall
    }
  }
  maximumLeadTime ${minMaxLeadTimeFields}
  minimumLeadTime ${minMaxLeadTimeFields}
}`

export const companyAvailabilityFields = `{
  slots {
    day
    times
    minutes
  }
  onDays
  offDays
  calendarBegin
  calendarEnd
}`

export const invoiceFields = `{
  id
  invoiceLink
  invoiceSerialNo
  invoiceNo
  emittedDay
  invoicedAmount
  beginDay
  endDay
  paymentDate
  plan,
  paymentStatus
  paymentLink
}`

export const enterpriseSettingsFields = `{
  snapToGridInterval
  internalSnapToGridInterval
  hasFacebookLogin
  hasTimifyLogin
  hasGuestBooking
  cancellationLeadTimeHours
  resourceEmailRemindersMinutes
  customerEmailRemindersMinutes
  showWidgetLanguageSelector
  isWidgetWeeklyView
  showWidgetCategoriesClosed
  showWidgetFirstCategoryOpen
  showWidgetTimezoneSelector
  customTermsAndConditionsUrl
  customPrivacyPolicyUrl
  widgetExternalUrlRedirect
  hideServiceDuration
  hideResourcesSection
  hasNotifyOnResources
  hasNotifyOnCustomers
  showExternalIds
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  resourceRemindersEmailRecipients
  customerRemindersEmailRecipients
  resourceBookingCreateEmailRecipients
  resourceBookingUpdateEmailRecipients
  resourceBookingDeleteEmailRecipients
  customerBookingCreateEmailRecipients
  customerBookingUpdateEmailRecipients
  customerBookingDeleteEmailRecipients
  enforceResourcesAllowanceType
  enforceReschedulingTagRule
  enforceServiceDurationLimit
  resetCopyBookingClipboardAfterPaste
  hideFastWidgetTimeFrame
  allowUserLocaleForCustomers
  allowUserLocaleForResources
  allowOnlyGlobalCustomers
  EUGDPRRules ${eugdprRuleFields}
  forbidMoreThanOneBookingInFBW
  forbidCreatingAndUpdatingBookingsInThePast
  isCompanyTagsLocalUpdateForbidden
  isServiceLocalUpdateForbidden
  isCourseLocalUpdateForbidden
  isCustomersLocalUpdateForbidden
  isResourceLocalUpdateForbidden
  securitySessionConfig ${companySessionFields}
  securitySessionInactivityConfig ${companySessionFields}
  publicHolidays {
    id
    resourceIds
    publicHolidayKeys
    resourceCategoryIds
  }
  footfallMapping {
    isActive
    intervals {
      begin
      end
      footfall
    }
  }
  maximumLeadTime ${minMaxLeadTimeFields}
  minimumLeadTime ${minMaxLeadTimeFields}
}`

export const enterpriseFields = `{
  id
  customersMiddlewareUrl
  settings ${enterpriseSettingsFields}
  webappCustomisation { payload }
}`

export const onlineStatusFields = `{
  wantsToBeOnline
  hasResources
  hasServices
  hasCustomerFields
  isOnline
  hasCoursesInTheFuture
  ts
}`

export const companyBillingDataFields = `{
  name
  address1
  address2
  zipCode
  city
  vatNo
}`

export const companyPaymentMethodFields = `{
  isDefault
  type
  id
  data
}`

export const subscriptionFields = `{
  id
  plan
  subscriptionType
  billingEndDay
  startDay
  renewDay
  renewPlan
  isTrial
  trialPlan
  trialEndDay
  trialDaysLeft
  billingCycleMonths
}`

export const companyGeneralStatisticsFields = `{
  servicesCreated
  coursesCreated
  eventsCreated
  resourcesCreated
  customerFieldsCreated
}`

export const onboardData = `{
  locationType
  bookingType
  bookingAvailability
}`

export const companyFieldsForAccount = `{
  id
  createdAt
  externalId
  enterpriseId
  facebookPageId
  name
  description
  personName
  isPerson
  plan
  info
  businessSectors
  onlineStatus ${onlineStatusFields}
  onboardData ${onboardData}
  phone {
    prefix
    country
    number
    phone
  }
  contactEmail
  slug
  website
  locale
  timezone
  ownerEmail
  currency
  settings ${companySettingsFields}
  rawSettings ${companySettingsFields}
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
    details
  }
  coverUrl
  hideAddressOnProfilePage
  isProfilePagePublished
  calendar {
    workingWeeklyAllowancePlan {
      isActive
      times {
        begin
        end
      }
    }
  }
  ts
  showOnboarding
  hasNoContract
  subscription ${subscriptionFields}
  enterprise ${enterpriseFields}
  courses ${courseFieldsForList}
  courseCategories ${courseCategoryFields}
  services ${serviceFieldsForList}
  serviceCategories ${serviceCategoryFields}
  billingData ${companyBillingDataFields}
  paymentMethods ${companyPaymentMethodFields}
  statistics {
    generalStatistics ${companyGeneralStatisticsFields}
  }
  hasStripeConnected
  stripeEmail
  lastCustomersDownload
  lastStatisticsStateChange
  stripeExtraFeePercentage
  webappCustomisation { payload }
  financialStatus { amountDue }
}`

export const companyFieldsForAccountAccounts = `{
  id
  externalId
  name
  enterprise {
    id
    slug
  }
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
    details
  }
  plan
  timezone
  currency
  settings {
    requiredAuth2FAType
  }
}`

export const bookingIntervalFields = `{
  bookingId
  booking {
    calendarId
    eventId
  }
  day
  beginTime
  endTime
  duration
  durationBefore
  durationAfter
  batchNumber
  recurringDay
  isFirst
  isLast
}`

const participantFields = `{
  reservationId
  customerId
  customerName
  extraPersons
  isBookedOnline
  isPaidOnlineWithStripe
  unitPrice
  totalPrice
  bookedAt
  email
  fields {
    id
    type
    value
    translationKey
    label
  }
}`

const eventMinumumFields = `
  id
  ts
  type
  chainId
  isLastInTheChain
  isCourse
  combination {
    eventId
    serviceId
    title
    price
    durationBefore
    durationAfter
    startDate
    events {
      eventId
      serviceId
      resourceIds
      title
      color
      duration
    }
    isPaidOnlineWithStripe
    hasNotifyCustomers
    hasNotifyResources
  }
  maxParticipants
  extraPersonsPerParticipant
  participantsCount
  participants ${participantFields}
  isBookable
  createdAt
  updatedAt
  createdBy
  updatedBy
  title
  color
  serviceId
  resourceIds
  notes
  customerId
  customerName
  customersCount
  isBookedOnline
  isPaidOnlineWithStripe
  reservedUntil
  price
  tagIds
  fields {
    id
    type
    value
  }
  hasNotifyCustomers
  hasNotifyResources
  intervals(timezone: $timezone) {
    batchNumber
    recurringDay
    interval {
      begin
      end
    }
  }
  duration
  durationsPattern
  durationBefore
  durationAfter
  recurring {
    timezone
    frequency
    period
    weekDays
    monthDays
    yearMonths
    repeatTimes
    repeatLastDay
    maxDay
  }
  startDate
  hasOnlinePayment
  isPaymentMandatory
  deepLinkConfig {
    appId
  }
`

export const eventFieldsShort = `{
  ${eventMinumumFields}
}`

export const eventWidgetFields = `{
  ${eventMinumumFields}
  bookingIntervals(timezone: $timezone) ${bookingIntervalFields}
}`

export const eventFields = `{
  ${eventMinumumFields}
  bookingIntervals(timezone: $timezone) ${bookingIntervalFields}
  chainedEvents {
    ${eventMinumumFields}
    bookingIntervals(timezone: $timezone) ${bookingIntervalFields}
  }
}`

export const companyFields = `{
  id
  externalId
  name
  isPerson
  personName
  description
  plan
  businessSectors
  onlineStatus ${onlineStatusFields}
  timezone
  currency
  locale
  info
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
    details
  }
  phone {
    prefix
    country
    number
    phone
  }
  contactEmail
  website
  slug
  facebookPageId
  coverUrl
  hideAddressOnProfilePage
  isProfilePagePublished
  calendar {
    threads
    workingWeeklyAllowancePlan {
      isActive
      times {
        begin
        end
      }
    }
  }
  ts
  enterprise ${enterpriseFields}
  settings ${companySettingsFields}
  rawSettings ${companySettingsFields}
}`

export const companyStatisticsFields = `{
  overallEvents {
    total
    totalOnline
    totalOffline
  }
  overallCustomers {
    total
    totalOnline
    totalOffline
  }
  cumulatedSales
  firstBookingDay
  lastSevenDays
  nextSevenDays
  bookingsAverage
  revenueService
  generalStatistics ${companyGeneralStatisticsFields}
}`

export const companyStatisticsEnterpriseFieldsV2 = `{
  averageBookingGuestsPerCourseEvent
  averageBookingParticipantsPerCourseEvent
  averageDailyServiceBookings
  averageWeeklyServiceBookings
  averageWeeklyCancelledBookings
  blockerDenialsStatistics { hours days }
  cancelledEventsByDay
  eventSignupsByDaysOfWeek
  eventSignupsByDays
  eventSignupsByDaysWithGuests
  eventSignupsByHoursOfDay
  eventsByDays
  onlineMarketingUtmStatistics
  sickDenialsStatistics { hours days }
  topBookingBookableResources
  topBookingDependencyResources
  topBookingResources
  topBookingServices
  topBookingServicesWithPrice
  topCancelledBookingServices
  totalCancelledEvents
  totalCancelledInternalEvents
  totalCancelledOnlineEvents
  totalCourseBookingGuests
  totalCourseBookingParticipants
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEvents
  totalPastCourseEventSeats
  totalFutureCourseEventSeats
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalEvents
  totalFutureCourseEventBookedSeats
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlinePaidRevenue
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalPastCourseEventBookedSeats
  totalRescheduledEvents
  totalResources
  totalResourcesOnlineBookedTimes { hours days}
  totalResourcesOnlineTimes { hours days}
  totalResourcesWorkingTimes { hours days}
  totalResourcesWorkingBookedTimes { hours days}
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
  vacationDenialsStatistics { hours days }
}`
export const companyStatisticsClassicFieldsV2 = `{
  totalEvents
  totalOnlineEvents
  totalCustomers
  totalCumulatedEventsCountWithPrice {
    totalPrice
    totalEvents
    totalCustomers
  }
}`
export const companyStatisticsPremiumFieldsV2 = `{
  averageBookingGuestsPerCourseEvent
  averageBookingParticipantsPerCourseEvent
  averageDailyServiceBookings
  averageWeeklyServiceBookings
  eventSignupsByDaysOfWeek
  eventSignupsByDays
  eventSignupsByHoursOfDay
  eventSignupsByHoursOfDayWithGuests
  eventsByDays
  onlineMarketingUtmStatistics
  topBookingServices
  totalCourseBookingGuests
  totalCourseBookingParticipants
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEvents
  totalPastCourseEventSeats
  totalFutureCourseEventSeats
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalEvents
  totalFutureCourseEventBookedSeats
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalPastCourseEventBookedSeats
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
}`
export const companyStatisticsFieldsV2Overall = `{
  topBookingServices
  topCancelledBookingServices
  totalCourseBookings
  totalCourseBookingsWithId
  totalCourseBookingsWithTitle
  totalCourseEventGuests
  totalCourseEventParticipants
  totalCourseEvents
  totalCourseEventsWithId
  totalCourseEventsWithTitle
  totalCourseInStorePaidRevenue
  totalCourseOnlinePaidRevenue
  totalCourseRevenue
  totalCustomers
  totalInternalCourseBookingsWithId
  totalInternalCourseBookingsWithTitle
  totalInternalCustomers
  totalInternalServiceEventsWithId
  totalInternalServiceEventsWithIdAndCustomer
  totalOnlineCourseBookingsWithId
  totalOnlineCourseBookingsWithTitle
  totalOnlineCustomers
  totalOnlineServiceEventsWithId
  totalOnlineServiceEventsWithIdAndCustomer
  totalRevenue
  totalServiceEvents
  totalServiceEventsWithCustomer
  totalServiceEventsWithId
  totalServiceEventsWithIdAndCustomer
  totalServiceEventsWithTitle
  totalServiceEventsWithTitleAndCustomer
  totalServiceInStorePaidRevenue
  totalServiceOnlinePaidRevenue
  totalServiceRevenue
}`
export const companyStatisticsFieldsV2OverallTotalEvents = `{
  totalEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2OverallTotalOnlineEvents = `{
  totalOnlineEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2OverallTotalInternalEvents = `{
  totalInternalEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2TotalEvents = `{
  totalEvents(filter: $partialStatsFilter)  
}`
export const companyStatisticsFieldsV2TotalOnlineEvents = `{
  totalOnlineEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2TotalInternalEvents = `{
  totalInternalEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2TotalCancelledEvents = `{
  totalCancelledEvents(filter: $partialStatsFilter)
}`
export const companyStatisticsFieldsV2SickDenialsStatistics = `{
  sickDenialsStatistics(filter: $partialStatsFilter) { hours days}  
}`
export const companyStatisticsFieldsV2VacationDenialsStatistics = `{
  vacationDenialsStatistics(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2BlockerDenialsStatistics = `{
  blockerDenialsStatistics(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2TotalResourcesWorkingTimes = `{
  totalResourcesWorkingTimes(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2TotalResourcesWorkingBookedTimes = `{
  totalResourcesWorkingBookedTimes(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2TotalResourcesWorkingOnlineAvailabilityTimes = `{
  totalResourcesOnlineAvailabilityTimes(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2TotalResourcesOnlineBookedTimes = `{
  totalResourcesOnlineBookedTimes(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsFieldsV2TotalResourcesOnlineTimes = `{
  totalResourcesOnlineTimes(filter: $partialStatsFilter) { hours days}
}`
export const companyStatisticsV2FieldsSingleBookings = `{
  totalServiceEventsWithTitle(filter: $partialStatsFilter)
  totalServiceEvents(filter: $partialStatsFilter)
  totalOnlineServiceEventsWithId(filter: $partialStatsFilter)
  totalInternalServiceEventsWithId(filter: $partialStatsFilter)
  totalServiceEventsWithId(filter: $partialStatsFilter)
}`
export const companyStatisticsV2FieldsCourseBookings = `{
  totalCourseEventsWithTitle(filter: $partialStatsFilter)
  totalCourseEventsWithId(filter: $partialStatsFilter)
  totalCourseEvents(filter: $partialStatsFilter)
}`

export const companyAppFields = `{
  id
  externalId
  name
  version
  imageUrl
  categories
  isPublic
  isEnterprise
  developer {
    companyName
    name
  }
  plan
  installedVersion
}`

export const companyAppPreviewFields = `{
  id
  externalId
  name
  version
  imageUrl
  gallery
  roles {
    name
    types
  }
  benefits
  functionality
  tutorialUrl
  privacyUrl
  isEnterprise
  plan
  categories
  isPublic
  developer {
    website
    companyName
    name
  }
  installedVersion
  description
}`

export const publicAppFields = `{
  id
  externalId
  name
  version
  plan
  imageUrl
  roles {
    name
    types
  }
  developer {
    companyName
    name
  }
}`

export const companyShiftsFields = `{
  id
  ts
  resourceId
  denialReason
  days
  name
  translationKey
  interval {
    begin
    end
  }
  denialTimes {
    begin
    end
  }
  workingTimes {
    begin
    end
  }
  bookingTimes {
    begin
    end
  }
}`

export const activityLogFields = `{
  id
  type
  operation
  addedByResourceId
  addedByResourceName
  addedByCustomer
  createdAt
  data
  changedFields
  isDeleted
  isOld
}`

export const emailDeliveryStatus = `{
  id
  type
  operation
  destination
  status
  createdAt
  updatedAt
  email
  subject
  locale
  payload
}`
