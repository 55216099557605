import React from 'react'
import PropTypes from 'prop-types'
import { Link, Image } from '../../Common'

import './Logo.css'
import { logoTimify } from '../../Assets/images'

const Logo = props => {
  const { to } = props
  const logoUrl = to || '/'

  return (
    <Link to={logoUrl} className='ta-logo'>
      <Image src={logoTimify} alt='TIMIFY' />
    </Link>
  )
}

Logo.propTypes = {
  to: PropTypes.string
}

export default Logo
