import { popupFormTransform } from './utils'

const reducer = {}

reducer.initialState = {
  status: false,
  content: null,
  trigger: null,
  position: 'right',
  extraClassName: '',
  id: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  popupSet: (content, position, trigger, extraClassName, id, extras) => dispatch(actions.POPUP_SET, { content, position, trigger, extraClassName, id, extras }),
  popupHide: (content) => dispatch(actions.POPUP_HIDE, content),
  // Form
  popupFormPopulate: form => {
    handlers.formSet('popup', popupFormTransform(form))
  }
})

reducer.POPUP_SET = (state, payload) => ({
  ...state,
  status: !!payload.content,
  content: payload.content,
  trigger: payload.trigger,
  position: payload.position || state.position,
  extraClassName: payload.extraClassName || '',
  id: payload.id || null,
  extras: payload.extras
})

reducer.POPUP_HIDE = (state) => ({
  ...state,
  status: false
})

export default reducer
