import React, { Component } from 'react'
import { connect, handlers, selectors, store } from '../../Store'
import { Accounts } from '../../Beauties'
import { sortAccounts } from '../../Utils'
import { isRouteDisabledByCustomisations } from '../../Common'

class AccountsWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onAccountChange = this.onAccountChange.bind(this)
    this.onResendEmailClick = this.onResendEmailClick.bind(this)
  }

  componentDidMount () {
    const isRouteDisabled = isRouteDisabledByCustomisations()
    if (isRouteDisabled) {
      handlers.navigateToPath('/')
      return
    }

    const { company, account } = store.getState()
    const { plan } = company || {}
    let { accounts } = account || {}
    accounts = accounts || []
    if (plan !== 'ENTERPRISE' && accounts.length < 2) handlers.navigateToPath('/')
    if (accounts.length === 1 && accounts.filter(item => !item.isOwner).length === 1) handlers.navigateToPath('/')
    handlers.formFieldsUpdate('accountsSearch', { search: { value: '' } })
  }

  onAccountChange (id) {
    handlers.accountSelect(id)
  }

  onResendEmailClick ({ accountId, email }) {
    handlers.accountEmailConfirmationSend({ accountId, email })
  }

  render () {
    let { company, accounts, pending, search } = this.props
    pending = !!pending
    search = search || ''
    company = company || {}
    accounts = accounts || []
    const sortedAccounts = accounts.sort(sortAccounts)
    const selectedAccount = sortedAccounts.find(account => account.id === company.id) || {}
    const filteredAccounts = sortedAccounts.filter(account => account.id !== company.id) || []
    const confirmedAccounts = filteredAccounts.filter(account => account.isConfirmed) || []
    const pendingAccounts = filteredAccounts.filter(account => !account.isConfirmed) || []
    const showSearch = accounts.length > 10

    return (
      <Accounts
        company={{
          ...company,
          isConfirmed: selectedAccount.isConfirmed,
          isOwner: selectedAccount.isOwner,
          isCustom: selectedAccount.isCustom,
          isAdmin: selectedAccount.isAdmin
        }}
        confirmedAccounts={confirmedAccounts}
        pendingAccounts={pendingAccounts}
        onAccountChange={this.onAccountChange}
        onResendEmailClick={this.onResendEmailClick}
        pending={pending}
        showSearch={showSearch}
        search={search}
      />
    )
  }
}

const maps = state => ({
  company: selectors.companySelector(state),
  accounts: selectors.accountFieldSelector(state, { field: 'accounts' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingAccountSelect' }),
  search: selectors.formFieldPropertySelector(state, { formName: 'accountsSearch', name: 'search', property: 'value' })
})

export default connect(maps)(AccountsWrapper)
