import router from '../Router'
import forms from '../Forms'
import app from '../Components/App'
import auth from '../Components/Auth'
import account from '../Components/Account'
import company from '../Components/Company'
import navigation from '../Components/Navigation'
import popup from '../Components/Popup'
import modal from '../Components/Modal'
import overlay from '../Components/Overlay'
import staticData from '../Components/StaticData'
import oAuth from '../Components/OAuth'
import persist from '../Components/Persist'
import settings from '../Components/Settings'
import phrase from '../Components/Phrase'
import debugOptions from '../Components/DebugOptions'
import twoFA from '../Components/TwoFA'
import user from '../Components/User'
import users from '../Components/Users'
import branches from '../Components/Branches'
import agents from '../Components/Agents'
import customerFields from '../Components/CustomerFields'
import customers from '../Components/Customers'
import bookings from '../Components/Bookings'
import services from '../Components/Services'
import tags from '../Components/Tags'
import courses from '../Components/Courses'
// This name will be available in store object
export default {
  router,
  bookings,
  courses,
  forms,
  account,
  app,
  auth,
  company,
  modal,
  navigation,
  overlay,
  popup,
  staticData,
  oAuth,
  persist,
  settings,
  phrase,
  debugOptions,
  twoFA,
  user,
  users,
  branches,
  agents,
  services,
  tags,
  customers,
  customerFields
}
