import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import { customerFieldDownloadFileServerErrorsTransform, customerFieldListErrorTransform } from './utils'
import { sortByOrderIndex } from '../../../Utils'
import { t } from '../../../Common'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'

// Global actions

globalActions.populateCustomerFieldsAndCategories = async (forceFetch = false) => {
  const { areFetched = false } = store.getState().customerFields
  if (areFetched && !forceFetch) return
  const result = await q('getEnterpriseCallCentreCustomerFieldCategories') || { error: { text: 'errors.api.unavailable' } }
  const { error } = result
  if (error) {
    handlers.customerFieldsListMessageSet(customerFieldListErrorTransform(error))
    return result
  }
  handlers.customerFieldsListPopulate({
    customerFields: result.reduce((acc, { customerFields }) => [...acc, ...customerFields], []) || [],
    customerFieldCategories: result || []
  })
  return result
}

globalActions.parseCustomerFieldsValues = values => {
  if (!values) return
  const PARSE_TYPES = ['PHONE', 'ADDRESS']
  return values.map(item => ({
    id: item.id,
    type: item.type,
    values: item.values,
    value: item.value && PARSE_TYPES.includes(item.type)
      ? JSON.parse(item.value)
      : item.value
  }))
}

globalActions.prepareCustomerFieldsWithValues = values => {
  if (!values) return []
  const parsedValues = {}
  values.forEach(item => {
    parsedValues[item.id] = item.value
  })
  const { list = [] } = store.getState().customerFields
  return list
    .map(item => ({ ...item, value: parsedValues[item.id] === undefined ? null : parsedValues[item.id] }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

globalActions.prepareCustomerFieldsFormValue = (customerFieldsWithValues, booking, companyId) => {
  const state = store.getState()
  const bookingFields = {}
  const { branches } = state
  const { list: branchesList } = branches || {}
  const companyLocale = (branchesList.find(({ id }) => id === companyId) || {}).locale
  customerFieldsWithValues
    .forEach(item => {
      bookingFields[`customerFields${item.id}`] = {
        value: item.value || ''
      }
      if (item.type === 'CHECKBOX') {
        bookingFields[`customerFields${item.id}`].value = item.value + '' === 'true'
      }
      if (item.type === 'SELECT') {
        bookingFields[`customerFields${item.id}`].options = item.selectOptions
        if ((item.selectOptions || []).findIndex(option => option.value === item.value) === -1) bookingFields[`customerFields${item.id}`].value = ''
      }
      if (item.type === 'PHONE') {
        bookingFields[`customerFields${item.id}`] = {
          phone: (item.value && item.value.number) || '',
          phoneCountry: item.value
            ? item.value.country
            : companyLocale.length > 2
              ? (companyLocale.split('-')[1] || '').toUpperCase()
              : DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || {}
        bookingFields[`customerFields${item.id}`].value = address.formatted
        bookingFields[`customerFields${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        bookingFields[`customerFields${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      if (item.type === 'FILES') {
        bookingFields[`customerFields${item.id}`] = {
          selected: (item.values || []).map(file => JSON.parse(file)),
          valuesAdd: [],
          valuesRemove: [],
          value: ''
        }
      }
      bookingFields[`customerFields${item.id}`].type = item.type
      bookingFields[`customerFields${item.id}`].defaultId = item.defaultId
      bookingFields[`customerFields${item.id}`].isMandatory = item.isMandatoryOffline

      // Participant field
      if (booking.participants) {
        booking.participants.forEach(participant => {
          participant.parsedFields = participant.parsedFields || []
          const participantField = participant.parsedFields.find(f => f.id === item.id) || {}
          bookingFields[`customerFields${item.id}_${participant.customerId}`] = {
            value: participantField.value || ''
          }
          if (item.type === 'CHECKBOX') {
            bookingFields[`customerFields${item.id}_${participant.customerId}`].value = participantField.value + '' === 'true'
          }
          if (item.type === 'SELECT') {
            bookingFields[`customerFields${item.id}_${participant.customerId}`].options = participantField.selectOptions
          }
          if (item.type === 'PHONE') {
            bookingFields[`customerFields${item.id}_${participant.customerId}`] = {
              phone: (participantField.value && participantField.value.number) || '',
              phoneCountry: participantField.value
                ? participantField.value.country
                : companyLocale.length > 2
                  ? (companyLocale.split('-')[1] || '').toUpperCase()
                  : DEFAULT_LOCALE_COUNTRY_CODE
            }
          }
          if (item.type === 'ADDRESS') {
            const address = participantField.value || {}
            bookingFields[`customerFields${item.id}_${participant.customerId}`].value = address.formatted
            bookingFields[`customerFields${item.id}_${participant.customerId}`].data = ((address.formatted && address.formatted !== '') && {
              placeId: address.placeId || '',
              city: address.city || '',
              streetName: address.street || '',
              streetNumber: address.streetNumber || '',
              postalCode: address.zipCode || '',
              country: address.country || '',
              lat: address.latitude || '',
              lng: address.longitude || '',
              formattedAddress: address.formatted || '',
              details: address.details || ''
            }) || null
            bookingFields[`customerFields${item.id}_${participant.customerId}Secondary`] = {
              value: address.details || ''
            }
          }
          if (item.type === 'FILES') {
            bookingFields[`customerFields${item.id}_${participant.customerId}`] = {
              selected: (participantField.values || []).map(file => JSON.parse(file)),
              valuesAdd: [],
              valuesRemove: [],
              value: ''
            }
          }
          bookingFields[`customerFields${item.id}_${participant.customerId}`].type = item.type
          bookingFields[`customerFields${item.id}_${participant.customerId}`].defaultId = item.defaultId
          bookingFields[`customerFields${item.id}_${participant.customerId}`].isMandatory = item.isMandatoryOffline
        })
      }
    })

  return bookingFields
}

globalActions.prepareCustomerFieldsForForm = fields => {
  const { list = [] } = store.getState().customerFields
  return (fields || list)
    .map(item => ({
      ...item,
      selectOptions: (item.selectOptions || []).map(option => ({
        label: option.label || t(option.translationKey),
        value: option.value
      }))
    }))
    .filter(item => item.isActive)
    .sort(sortByOrderIndex)
}

// Subscription
payloads$(actions.CUSTOMER_FIELDS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    // const updateSubscriptions = ['customerFieldUpdated', 'customerFieldEnterpriseGlobalUpdated']
    // const deleteSubscriptions = ['customerFieldDeleted', 'customerFieldEnterpriseGlobalDeleted']
    // const categoryUpdateSubscriptions = ['customerFieldCategoryUpdated', 'customerFieldCategoryEnterpriseGlobalUpdated']
    // const categoryDeleteSubscriptions = ['customerFieldCategoryDeleted', 'customerFieldCategoryEnterpriseGlobalDeleted']

    // if (name === 'customerFieldsMoved' && data) customerFieldsMoved(data)
    // if (updateSubscriptions.includes(name) && id) {
    //   const customerField = (await q('getCustomerField', { id })) || {}
    //   if (customerField.error) return
    //   customerFieldUpdated(customerField)
    // }
    // if (deleteSubscriptions.includes(name) && id) customerFieldDeleted(id)
    // if (name === 'customerFieldCategoriesMoved' && data) customerFieldCategoriesMoved(data)
    // if (categoryUpdateSubscriptions.includes(name)) {
    //   const customerFieldCategory = (await q('getCustomerFieldCategory', { id })) || {}
    //   if (customerFieldCategory.error) return
    //   customerFieldCategoryUpdated(customerFieldCategory)
    //   setTimeout(() => {
    //     handlers.customerFieldCategoryUpdated()
    //   }, 500)
    // }
    // if (categoryDeleteSubscriptions.includes(name) && id) customerFieldCategoryDeleted(id)
  })

// List
payloads$(actions.CUSTOMER_FIELDS_LIST_GET)
  .subscribe(async () => {
    // await globalActions.populateCustomerFieldsAndCategories()
    // handlers.customerFieldsListReorderGet()
  })

// Preview
payloads$(actions.CUSTOMER_FIELD_PREVIEW_GET)
  .subscribe(async ({ id, forceFetch = false }) => {
    // if (!id) {
    //   handlers.navigateToPath('/customers/customer-fields')
    //   return
    // }
    // await globalActions.populateCustomerFieldsAndCategories()
    // handlers.customerFieldPreviewPopulate(id)
  })

// Form
payloads$(actions.CUSTOMER_FIELD_FORM_GET)
  .subscribe(async id => {
    // await globalActions.populateCustomerFieldsAndCategories()

    // const state = store.getState()
    // const services = selectors.servicesListSelector(state) || []
    // const servicesCategories = selectors.servicesCategoriesListSelector(state) || []
    // const courses = selectors.coursesListSelector(state) || []
    // const coursesCategories = selectors.coursesCategoriesListSelector(state) || []
    // const plan = selectors.companyPlanSelector(state) || 'CLASSIC'
    // const query = selectors.routerFieldSelector(state, { field: 'query' }) || {}
    // let { list, categoriesList } = state.customerFields
    // list = list || []
    // categoriesList = categoriesList || []
    // const queryCategory = (query.c && categoriesList.find(category => category.id === query.c)) || {}
    // const defaultCategory = categoriesList.find(category => category.isDefault) || {}

    // queryCategory.name = queryCategory.name || t(queryCategory.translationKey)
    // defaultCategory.name = defaultCategory.name || t(defaultCategory.translationKey)

    // if (!id) {
    //   handlers.customerFieldFormPopulate({
    //     category: queryCategory || defaultCategory,
    //     availableServices: services,
    //     availableServicesCategories: servicesCategories,
    //     availableCourses: courses,
    //     availableCoursesCategories: coursesCategories,
    //     activeNameFields: [],
    //     plan
    //   })
    //   return
    // }

    // const selectedCustomerField = list.find(customerField => customerField.id === id) || {}
    // const customerFieldCategory = categoriesList.find(category => category.id === selectedCustomerField.categoryId) || {}
    // const { isComplete } = selectedCustomerField
    // const customerField = isComplete
    //   ? selectedCustomerField
    //   : customerFieldTransform(await q('getCustomerField', { id }))
    // const { error } = customerField

    // if (error) {
    //   handlers.navigateToPath(id ? `/customers/customer-fields/${id}` : '/customers/customer-fields')
    //   return
    // }

    // const filterByDefaultId = ['firstName', 'lastName', 'company']
    // const activeNameFields = list.filter(item => filterByDefaultId.includes(item.defaultId) && item.isActive)
    // const publishedNameFields = list.filter(item => filterByDefaultId.includes(item.defaultId) && item.isPublished)

    // customerFieldCategory.name = customerFieldCategory.name || t(customerFieldCategory.translationKey)

    // handlers.customerFieldUpdate({ ...customerField, isComplete: true })
    // handlers.customerFieldFormPopulate({
    //   ...customerField,
    //   label: customerField.label || t(customerField.translationKey),
    //   category: customerFieldCategory || defaultCategory,
    //   availableResources: selectors.resourcesListSelector(state),
    //   availableServices: services,
    //   availableServicesCategories: servicesCategories,
    //   availableCourses: courses,
    //   availableCoursesCategories: coursesCategories,
    //   activeNameFields,
    //   publishedNameFields,
    //   plan
    // })
  })

// Save
payloads$(actions.CUSTOMER_FIELD_FORM_SAVE)
  .subscribe(async ({ customerField, scrollToError }) => {
    // const state = store.getState()
    // const { list } = state.customerFields || {}
    // const errors = customerFieldFormValidate(customerField, list)
    // if (errors.length) return setCustomerFieldFormSaveErrors(errors, scrollToError)
    // const savedCustomerField = await q('saveCustomerField', { customerField: customerFieldSaveTransform(customerField) })
    // const { error, id } = savedCustomerField
    // if (error) return setCustomerFieldFormSaveErrors(customerFieldFormServerErrorsTransform(error), scrollToError)
    // handlers.customerFieldUpdate({ ...savedCustomerField, isComplete: true })
    // handlers.bookingCustomersReset()
    // handlers.navigateToPath(`/customers/customer-fields/${id}`)
  })

// const setCustomerFieldFormSaveErrors = (errors, scrollToError) => {
// handlers.formErrorsSet('customerFields', errors)
// scrollToError && scrollToError(errors)
// handlers.customerFieldFormReady()
// }

// Delete form save
payloads$(actions.CUSTOMER_FIELD_DELETE_FORM_SAVE)
  .subscribe(async ({ customerField, scrollToError }) => {
    // const { id, name } = customerField || {}
    // let { value: nameValue } = name || {}
    // nameValue = nameValue || ''
    // if (!id) return
    // const customerFields = store.getState().customerFields.list || []
    // const selectedCustomerField = customerFields.find(item => item.id === id)
    // if (!selectedCustomerField) return
    // let { translationKey, label } = selectedCustomerField || {}
    // label = label || selectedCustomerField.defaultId
    // if (!nameValue || (nameValue.toLowerCase() !== label.toLowerCase() && nameValue.toLowerCase() !== t(translationKey).toLowerCase())) {
    //   handlers.formErrorsSet('customerFieldsDelete', [{ key: 'name', value: 'errors.customerFieldNameNotMatch' }])
    //   handlers.customerFieldDeleteFormReady()
    //   return
    // }
    // handlers.customerFieldDelete(id)
    // handlers.popupHide('customer-fields-delete')
    // setTimeout(() => { handlers.popupSet() }, 500)
  })

// Update
payloads$(actions.CUSTOMER_FIELD_UPDATE)
  .subscribe(async customerField => {
    // if (!customerField) return handlers.customerFieldPreviewPopulate()
    // setTimeout(() => handlers.customerFieldUpdated(customerField), 2000)
  })

// Delete
payloads$(actions.CUSTOMER_FIELD_DELETE)
  .subscribe(async id => {
    // if (!id) return handlers.customerFieldPreviewPopulate()
    // const { error } = await q('deleteCustomerField', { id })
    // if (error) {
    //   handlers.customerFieldPreviewPopulate()
    //   return
    // }
    // handlers.customerFieldDeleted(id)
    // setTimeout(() => handlers.customerFieldRemoveDeleted(id), 2000)
    // setTimeout(() => handlers.navigateToPath('/customers/customer-fields'), 0)
  })

// Category form
payloads$(actions.CUSTOMER_FIELD_CATEGORY_FORM_GET)
  .subscribe(async id => {
    // await globalActions.populateCustomerFieldsAndCategories()
    // const { categoriesList } = store.getState().customerFields
    // const selectedCategory = categoriesList.find(category => category.id === id) || {}
    // selectedCategory.name = selectedCategory.name || (selectedCategory.translationKey && t(selectedCategory.translationKey)) || ''
    // handlers.customerFieldCategoryFormPopulate(selectedCategory)
  })

// Category save
payloads$(actions.CUSTOMER_FIELD_CATEGORY_FORM_SAVE)
  .subscribe(async ({ category, scrollToError }) => {
    // const state = store.getState()
    // let { customerFields } = state
    // customerFields = customerFields || {}
    // let { categoriesList } = customerFields || {}
    // categoriesList = categoriesList || []
    // category = category || {}
    // const { id } = category || {}
    // const categoryExternalIds = categoriesList.filter(category => category.id !== id).map(category => category.externalId).filter(Boolean)
    // const errors = customerFieldCategoryFormValidate(category, categoryExternalIds)
    // if (errors.length) return setCustomerFieldCategoryErrors(errors, scrollToError)
    // const savedCategory = await q('saveCustomerFieldCategory', { category })
    // const { error } = savedCategory
    // if (error) return setCustomerFieldCategoryErrors(customerFieldCategoryFormServerErrorsTransform(error), scrollToError)
    // handlers.customerFieldCategoryUpdate(savedCategory)
    // handlers.bookingCustomersReset()
    // handlers.navigateToPath('/customers/customer-fields')
  })

// const setCustomerFieldCategoryErrors = (errors, scrollToError) => {
// handlers.formErrorsSet('customerFieldCategory', errors)
// handlers.customerFieldCategoryFormReady()
// scrollToError && scrollToError(errors)
// }

// Category delete
payloads$(actions.CUSTOMER_FIELD_CATEGORY_DELETE)
  .subscribe(async id => {
    // if (!id) return
    // const response = await q('deleteCustomerFieldCategory', { id }) || { error: { text: 'errors.api.unavailable' } }
    // const { error } = response
    // if (error) return setCategoryError(id, serverErrorsTransform(error))
    // handlers.customerFieldCategoryDeleted(id)
    // setTimeout(() => handlers.customerFieldCategoryRemoveDeleted(id), 2000)
  })

// const setCategoryError = (id, errors, scrollToError) => {
// handlers.formErrorsSet(`customerFieldCategory${id}`, errors)
// handlers.customerFieldCategoryDeleteFailed()
// scrollToError && scrollToError(errors)
// setTimeout(() => {
//   handlers.formErrorsSet(`customerFieldCategory${id}`, [])
// }, 3500)
// }

// Global category reset
payloads$(actions.CUSTOMER_FIELD_CATEGORY_RESET_GLOBAL)
  .subscribe(async ({ internalId }) => {
    // if (!internalId) return handlers.customerFieldCategoryResetGlobalReady()
    // const result = await q('resetGlobalCustomerFieldCategory', { internalId })
    // const { errors } = result || { errors: {} }
    // if (errors) return
    // handlers.customerFieldCategoryResetGlobalReady()
  })

// Reset global entity
payloads$(actions.CUSTOMER_FIELD_RESET_GLOBAL)
  .subscribe(async internalId => {
    // if (!internalId) return handlers.customerFieldResetGlobalReady()
    // const result = await q('resetGlobalCustomerField', { internalId })
    // const { errors } = result || { errors: {} }
    // if (errors) return
    // handlers.customerFieldResetGlobalReady(result)
    // handlers.navigateToPath('/customers/customer-fields')
  })

// Reorder save
payloads$(actions.CUSTOMER_FIELDS_LIST_REORDER_SAVE)
  .subscribe(async () => {
    // const state = store.getState()
    // let { reorderList, reorderCategoriesList } = state.customerFields || {}
    // reorderList = reorderList || []
    // reorderCategoriesList = reorderCategoriesList || []
    // const payload = reorderCategoriesList.reduce((acc, categoryItem, categoryIndex) => {
    //   const filteredCustomerFields = reorderList.filter(customerFieldItem => customerFieldItem.categoryId === categoryItem.id) || []
    //   if (!categoryItem.isDefault) acc.categories = [...acc.categories, { id: categoryItem.id, orderIndex: categoryIndex + 2 }]
    //   acc.customerFields = [
    //     ...acc.customerFields,
    //     ...(filteredCustomerFields.map((customerFieldItem, customerFieldIndex) => ({ id: customerFieldItem.id, categoryId: customerFieldItem.categoryId, orderIndex: customerFieldIndex + 1 })))
    //   ]
    //   return acc
    // }, { customerFields: [], categories: [] })
    // const result = await q('moveCustomerFieldsAndCategories', payload)
    // const { error } = result || {}
    // if (error) return
    // handlers.customerFieldsListReorderReady(payload)
    // handlers.navigateToPath('/customers/customer-fields')
  })

// Download file
payloads$(actions.CUSTOMER_FIELDS_DOWNLOAD_FILE)
  .subscribe(async ({ key, formName, name }) => {
    const result = await q('downloadEnterpriseFile', { key })
    const { error, content, contentType } = result || {}
    if (error) return setCustomerFieldDownloadFileErrors(customerFieldDownloadFileServerErrorsTransform(error, name), formName)
    const byteCharacters = window.atob(content)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: contentType })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
  })

const setCustomerFieldDownloadFileErrors = (errors, formName) => {
  handlers.formErrorsSet(formName, errors)
}
