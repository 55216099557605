const reducer = {}

reducer.initialState = {
  isEnabled: false,
  branchesList: [],
  defaultBranch: 'Staging'
}

reducer.handlers = (dispatch, actions) => ({
  phraseLoad: () => dispatch(actions.PHRASE_LOAD),
  phraseReady: () => dispatch(actions.PHRASE_READY),
  phraseUpdate: (options) => dispatch(actions.PHRASE_UPDATE, options),
  phraseBranchesListGet: () => dispatch(actions.PHRASE_BRANCHES_LIST_GET),
  phraseBranchesListPopulate: (branches) => dispatch(actions.PHRASE_BRANCHES_LIST_POPULATE, branches)
})

reducer.PHRASE_LOAD = state => state

reducer.PHRASE_READY = state => ({
  ...state,
  isEnabled: true
})

reducer.PHRASE_UPDATE = state => state

reducer.PHRASE_BRANCHES_LIST_GET = state => state

reducer.PHRASE_BRANCHES_LIST_POPULATE = (state, branches) => ({
  ...state,
  branchesList: branches
})

export default reducer
