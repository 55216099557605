import React from 'react'
import {
  Link,
  IconListItem,
  IconListItemTitle,
  IconListItemBody,
  UserPreview,
  FontAwesome5,
  Form,
  Error,
  t,
  Alert,
  Online
} from '../../../Common'
import { AgentPreviewPermissions } from '../..'

const AgentPreviewDetails = props => {
  const {
    id,
    isConfirmationEmailReSent,
    email,
    name,
    avatarUrl,
    isOwner,
    isAdmin,
    isCustom,
    color,
    isConfirmed,
    rules,
    lastAccountLoginAt,
    onResendEmailClick,
    abbreviation
  } = props
  const permissions = rules || {}

  return (
    <div className='ta-agents__preview__details'>
      <div className='ta-agents__preview__details__header'>
        <UserPreview
          name={name}
          avatarUrl={avatarUrl}
          color={color}
          abbreviation={abbreviation}
          lastAccountLoginAt={lastAccountLoginAt}
        >
          {(isOwner &&
            <div
              className='ta-agents__preview__details__label owner'
              title={t('agents.preview.sectionDefault.agentOwner.tooltip')}
            >
              <FontAwesome5 icon='suitcase' type='solid' />
            </div>
          )}
          {(!isOwner && isAdmin &&
            <div
              className='ta-agents__preview__details__label admin'
              title={t('agents.preview.sectionDefault.agentAdmin.tooltip')}
            >
              <FontAwesome5 icon='user-shield' type='solid' />
            </div>
          )}
          {(!isOwner && isCustom &&
            <div
              className='ta-agents__preview__details__label custom'
              title={t('agents.preview.sectionDefault.agentCustom.tooltip')}
            >
              <FontAwesome5 icon='cogs' type='solid' />
            </div>
          )}
        </UserPreview>
        <Form name='resourcePreview'>
          <Error name='globalErrors' noTopOffset />
        </Form>
        <hr />
        <IconListItem icon='id-badge' iconType='solid'>
          <IconListItemTitle width='90px'>{t('global.agentID')}:</IconListItemTitle>
          <IconListItemBody offset='90px'>{id}</IconListItemBody>
        </IconListItem>
        {(color &&
          <IconListItem icon='eye-dropper' iconType='solid'>
            <IconListItemTitle width='90px'>{t('global.color')}:</IconListItemTitle>
            <IconListItemBody offset='90px'>
              <FontAwesome5 icon='square' type='solid' color={color} className='ta-agents__preview__details__color' />
            </IconListItemBody>
          </IconListItem>
        )}
        {(email &&
          <>
            <IconListItem icon='envelope' iconType='solid'>
              <IconListItemTitle width='90px'>{t('global.email.label')}:</IconListItemTitle>
              <IconListItemBody offset='90px'>
                <Link to={`mailto:${email}`} target='_top' external>
                  {email}
                  {isConfirmed && (
                    <div className='ta-agents__preview__details__status active'> <FontAwesome5 icon='check' type='solid' /> {t('global.confirmed')} </div>
                  )}
                  {!isConfirmed && (
                    <div className='ta-agents__preview__details__status'> {t('global.pendingConfirmation')} </div>
                  )}
                </Link>
              </IconListItemBody>
            </IconListItem>
            {(!isConfirmed &&
              <IconListItem>
                <div className='ta-agents__preview__details__invitation'>
                  <Alert
                    noIcon
                    theme='alert'
                    label={
                      <>
                        <FontAwesome5 icon='exclamation-triangle' /> {t('resources.preview.tabDetailsInvitation.text')}
                      </>
                    }
                  >
                    {(!isConfirmationEmailReSent &&
                      <Online>
                        <div
                          className='ta-agents__preview__details__invitation__btn'
                          onClick={() => onResendEmailClick(email)}
                        >
                          <FontAwesome5 icon='envelope' /> {t('global.resendEmail')}
                        </div>
                      </Online>
                    )}
                  </Alert>
                </div>
              </IconListItem>
            )}
          </>
        )}
      </div>
      <AgentPreviewPermissions
        id={id}
        isCustom={isCustom}
        permissions={permissions}
      />
    </div>
  )
}

export default AgentPreviewDetails
