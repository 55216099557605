import PropTypes from 'prop-types'
import { Button, Link, t, Title } from '../../../Common'
import { dangerousHTML, withHttp } from '../../../Utils'

const PopupUrlOpenWarning = props => {
  const { approve, reject, url } = props
  const urlWithHttp = withHttp(url)

  return (
    <div className='ta-popup__courses-reset-global-entity'>
      <Title
        label={dangerousHTML(t('popup.urlWarning.title'))}
        icon='exclamation-triangle'
        ignoreTopOffset
        iconBoxStyle={{
          backgroundColor: '#fff',
          color: 'rgba(28, 39, 43, 0.6)'
        }}
      />
      <div className='ta-popup__text'>
        {dangerousHTML(t('popup.urlWarning.text', [{ key: 'URL', value: url }]))}
      </div>
      <div className='ta-popup__buttons'>
        <Button
          secondary
          onClick={reject}
        >
          {t('buttons.cancel.label')}
        </Button>
        <Link
          external
          primary
          to={urlWithHttp}
          onClick={approve}
          className='ta-btn ta-btn-primary'
          target='_blank'
        >
          {t('global.continue')}
        </Link>
      </div>
    </div>
  )
}

PopupUrlOpenWarning.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupUrlOpenWarning
