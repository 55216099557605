import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import './Form.css'
// import { handlers } from '../../Store'

class Form extends Component {
  constructor (props, context) {
    super(props)

    this.addRef = this.addRef.bind(this)
    this.removeRef = this.removeRef.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.components = []
    this.state = {
      formName: props.name,
      addRef: this.addRef,
      removeRef: this.removeRef
    }
  }

  // Revert form clear on unmount
  // Will keep the code in case we decide
  // to add it later on as optimisation
  // componentWillUnmount () {
  //   const { name, skipClear } = this.props
  //   if (!skipClear) handlers.formSet(name)
  // }

  addRef (component) {
    if (this.components.indexOf(component) === -1) this.components.push(component)
  }

  removeRef (component) {
    if (this.components.indexOf(component) > -1) this.components.filter(c => c !== component)
  }

  onSubmit (e) {
    e.preventDefault()
    const { onSubmit, scrollWrapper, elementWrapper } = this.props
    onSubmit && onSubmit((errors = []) => {
      const focus = errors.length > 0 ? errors[0].key : ''
      const component = this.components.find(c => c.props && c.props.name === focus)
      const isFormMounted = this.updater && this.updater.isMounted(this)
      if (!isFormMounted) return
      const scrollWrapperElements = scrollWrapper ? ReactDOM.findDOMNode(this).getElementsByClassName(scrollWrapper) : []
      if (component) {
        const scrollWrapperElement = scrollWrapper && scrollWrapperElements.length > 0
          ? scrollWrapperElements[0]
          : ReactDOM.findDOMNode(this).parentNode
        if (component.wrapper) {
          const closestElement = elementWrapper && ReactDOM.findDOMNode(component.wrapper).closest(`.${elementWrapper}`)
          const scrollToComponent = closestElement
            ? closestElement.offsetTop + ReactDOM.findDOMNode(component.wrapper).offsetTop
            : ReactDOM.findDOMNode(component.wrapper).offsetTop
          scrollWrapperElement.scrollTop = scrollToComponent - 10
        }
        if (component.ref) {
          ReactDOM.findDOMNode(component.ref).focus()
        }
      }
    })
  }

  render () {
    let {
      children,
      className,
      scrollWrapper,
      elementWrapper,
      skipClear, // Do not remove from here or the form element throw an error
      ...rest
    } = this.props
    let classNames = ['ta-form']
    if (className) classNames.push(className)

    return (
      <FormContext.Provider value={this.state}>
        <form
          ref={ref => { this.ref = ref }}
          {...rest}
          className={classNames.join(' ')}
          onSubmit={this.onSubmit}
        >
          {children}
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export const FormContext = React.createContext({
  formName: '',
  addRef: () => {},
  removeRef: () => {}
})

export default Form
