import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect, store, handlers, globalActions, selectors } from '../../Store'
import { CustomerPreviewDetails } from '../../Beauties'
import { filterBranches } from '../../Store/Components/Bookings/utils'
import { q } from '../../Store/API'

const CustomerPreviewBookingsWrapper = props => {
  const {
    id,
    resources,
    customer,
    selectedCustomer,
    timezones,
    hash,
    messageBookings,
    message,
    onSelectCustomer,
    allowBookingWithinWorkingTimes,
    isUpcomingBookingsLastPage,
    isPastBookingsLastPage,
    pendingUpcomingBookings,
    pendingPastBookings,
    services,
    branches,
    bookingsType,
    isScheduleForm,
    isCancellationForm,
    fieldsToBeDeleted,
    shownCustomerDetailsId,
    customerServices,
    displayBookedResources,
    ...rest
  } = props
  const [initial] = useState({ id })
  const [initialUpcomingBookingLoad, setInitialUpcomingBookingLoad] = useState(true)
  const [initialPastBookingLoad, setInitialPastBookingLoad] = useState(true)
  const events = {
    upcoming: selectedCustomer.upcomingEvents || { list: [] },
    past: selectedCustomer.pastEvents || { list: [] }
  }
  const eventTypes = Object.keys(events)
  const loadMoreUpcomingBookings = () => {
    !pendingUpcomingBookings && handlers.customerBookingsPageIncrement('upcoming', initialUpcomingBookingLoad)
    setInitialUpcomingBookingLoad(false)
  }
  const loadMorePastBookings = () => {
    !pendingPastBookings && handlers.customerBookingsPageIncrement('past', initialPastBookingLoad)
    setInitialPastBookingLoad(false)
  }
  eventTypes.forEach(type => {
    if (type === 'upcoming') {
      events[type].pending = pendingUpcomingBookings
      events[type].isBookingsLastPage = isUpcomingBookingsLastPage
      events[type].loadMoreBookings = loadMoreUpcomingBookings
    } else {
      events[type].pending = pendingPastBookings
      events[type].isBookingsLastPage = isPastBookingsLastPage
      events[type].loadMoreBookings = loadMorePastBookings
    }
  })

  const showBookingOnCalendar = (bookingId, date, resourceIds) => {
    resourceIds = resourceIds || []
    const state = store.getState()
    const { calendar } = state
    const { resourcesDaily } = calendar
    const allResources = [...resourceIds, ...resourcesDaily.filter(item => !resourceIds.includes(item))]
    handlers.formFieldsUpdate('calendarDailySettings', { resources: { value: allResources } })
    setTimeout(() => handlers.navigateToPath(`/?view=daily&date=${date}`), 1500)
  }

  const toggleUserDetails = (value) => {
    handlers.formFieldsUpdate('booking', { shownCustomerDetailsId: { value } })
  }
  const onCancelBookings = async (customer, forcedIds = []) => {
    onSelectCustomer({
      ...customer,
      upcomingEvents: events.upcoming,
      pastEvents: events.past
    })
    const selectedBookingsForDeletion = await globalActions.mapEventsForPreview(events.upcoming.list.filter(({ id }) => (forcedIds.length ? forcedIds : fieldsToBeDeleted).includes(id)))
    handlers.formFieldsUpdate('booking', {
      ...(forcedIds.length ? { [`bookingForDeletion${forcedIds[0]}`]: { value: true } } : {}),
      selectedBookingsForDeletion: { values: selectedBookingsForDeletion },
      progress: { value: 1 }
    })
  }
  const getCustomerBookings = () => {
    const { id } = initial
    toggleUserDetails(false)
    handlers.customerBookingsGet(id)
  }
  const onClickPrintBookings = () => {
    handlers.customerBookingsPrint()
  }

  const onEventRebook = async ({
    customer,
    fields,
    companyId,
    region,
    service: eventService,
    branch,
    date,
    time,
    rescheduledEventId,
    rescheduledSecret,
    resources,
    resourceCategories,
    areResourcesFetched
  }) => {
    let combinationId
    let combination
    let resourceIds = {}
    const combinationServicesResourceIds = {}
    const durations = {}
    const resourcesFields = {}
    const existService = services.find(({ internalId }) => internalId === eventService.internalId)
    if (eventService.combination) {
      eventService.isCombination = true
      combinationId = eventService.combination.eventId
      combination = eventService.combination
      let { events: combinationEvents } = combination || {}
      combinationEvents = combinationEvents || []
      combinationEvents.forEach(item => {
        durations[`duration${item.eventId}`] = { value: item.duration }
        resourcesFields[`dependencies${item.serviceId}`] = { values: item.resourceIds }
        combinationServicesResourceIds[item.serviceId] = item.resourceIds
      })
      const globallyTransformedService = await globalActions.transformGlobalServiceId(
        eventService.combination.serviceId,
        eventService.companyId,
        region
      )
      eventService = globallyTransformedService || {}
      resourceIds = { values: combinationEvents?.flatMap?.(ev => ev.resourceIds) }
    } else {
      eventService = eventService || {}
      if (rescheduledEventId) {
        const item = events.upcoming.list.find(({ id }) => id === rescheduledEventId) || {}
        resourceIds = { values: item.resourceIds }
      }
      if (!existService && !rescheduledEventId) {
        handlers.formFieldsUpdate('booking', {
          progress: { value: 1 }
        })
      }
    }
    if (isCancellationForm) {
      onCancelBookings(customer, [rescheduledEventId])
      return
    }
    if (isScheduleForm) {
      handlers.formFieldsUpdate('booking', {
        hasNotifyResources: { value: null },
        hasNotifyCustomers: { value: null }
      })
    }
    if (!areResourcesFetched && rescheduledEventId) { ({ resources, resourceCategories } = await globalActions.customerEventResourcesGet(rescheduledEventId)) }
    const filter = { globalCustomerId: customer.internalId, globalServiceId: eventService && eventService.internalId }
    const branches = await q('getEnterpriseCallCentreBranches', { region, filter }) || []
    const { allowedBranchIds: customerAllowedBranchIds, allowedBranchExternalIds: customerAllowedBranchExternalIds } = customer
    let { allowedBranchIds: serviceAllowedBranchIds, allowedBranchExternalIds: serviceAllowedBranchExternalIds } = eventService
    serviceAllowedBranchIds = serviceAllowedBranchIds || []
    serviceAllowedBranchExternalIds = serviceAllowedBranchExternalIds || []
    const branchOptions = branches
      .filter(({ id, externalId }) => (
        filterBranches(id, externalId, customerAllowedBranchIds, customerAllowedBranchExternalIds) && filterBranches(id, externalId, serviceAllowedBranchIds, serviceAllowedBranchExternalIds)
      ))
      .map(item => ({ value: item.id, rawValue: item, label: item.name }))

    onSelectCustomer(customer)
    handlers.branchSelect(branch.id)
    handlers.formFieldsUpdate('booking', {
      transformedService: {
        value: {
          ...eventService,
          bookingDate: date,
          bookingTime: time,
          rescheduledEventId,
          rescheduledSecret,
          resourceIds,
          resources,
          resourceCategories,
          combinationServicesResourceIds
        }
      },
      bookingBranch: {
        value: branch.id,
        options: branchOptions,
        allOptions: branchOptions
      }
    })
    const service = (services || []).find(item => item.id === (eventService.internalId || eventService.id)) || {}
    if (eventService.id) {
      handlers.formFieldsUpdate('booking', {
        duration: { value: service.duration },
        durationBefore: { value: service.durationBefore },
        durationAfter: { value: service.durationAfter },
        color: { value: service.color },
        price: { value: service.price },
        customerAddress: { value: null },
        customerAddressRadius: { value: '5000' },
        selectedBranchAddress: { value: null },
        allowanceType: { value: allowBookingWithinWorkingTimes ? 'WORKING' : 'BOOKING' },
        from: { value: null },
        timeIntervalMorning: { value: null },
        // keepExistingResource: { value: true },
        timeIntervalNoon: { value: null },
        timeIntervalAfternoon: { value: null },
        timeIntervalEvening: { value: null },
        availabilityDate: { value: null },
        availableSlots: [],
        slots: {},
        notes: { value: '' },
        ...(rescheduledEventId
          ? {
              combinationId: { value: combinationId },
              combination: { value: combination },
              resourceIds,
              ...durations,
              ...resourcesFields
            }
          : {}),
        service: {
          selectedId: service.id || eventService.internalId || eventService.id,
          value: service.name || eventService.name
        }
      })
      setTimeout(() => {
        handlers.bookingLocalTransformationsGet({ preserveBookingTime: true, fields, isRescheduleForm: !isScheduleForm })
      }, 0)
      if (branch.address) {
        handlers.formFieldsUpdate('booking', {
          selectedBranchAddress: { value: { ...branch.address, name: branch.name } }
        })
      }
    }
  }

  return (
    <CustomerPreviewDetails
      branches={branches}
      customer={{ ...customer, events: selectedCustomer.events, groupedFields: [...(customer.groupedCustomerFields || [])] }}
      hideExternalId
      hideGoogleMap
      hideTags
      bookings={events}
      eventTypes={eventTypes}
      toggleUserDetails={toggleUserDetails}
      shownCustomerDetailsId={shownCustomerDetailsId}
      hash={hash}
      services={services}
      onEventRebook={onEventRebook}
      timezones={timezones}
      showBookingOnCalendar={showBookingOnCalendar}
      message={message}
      messageBookings={messageBookings}
      onClickPrintBookings={onClickPrintBookings}
      getCustomerBookings={getCustomerBookings}
      bookingsType={bookingsType}
      resources={resources}
      customerServices={customerServices}
      isScheduleForm={isScheduleForm}
      isCancellationForm={isCancellationForm}
      onCancelBookings={onCancelBookings}
      fieldsToBeDeleted={fieldsToBeDeleted}
      displayBookedResources={displayBookedResources}
      {...rest}
    />
  )
}

CustomerPreviewBookingsWrapper.propTypes = {
  isBookingsLastPage: PropTypes.bool,
  pending: PropTypes.bool
}

const maps = state => ({
  id: (state.router.data && state.router.data.id) || '',
  isUpcomingBookingsLastPage: state.customers.isUpcomingBookingsLastPage || null,
  isPastBookingsLastPage: state.customers.isPastBookingsLastPage || null,
  pendingUpcomingBookings: selectors.customersPropertySelector(state, { property: 'pendingUpcomingBookings' }),
  pendingPastBookings: selectors.customersPropertySelector(state, { property: 'pendingPastBookings' }),
  branches: state.branches.list,
  timezones: state.staticData.timezones || [],
  message: state.customers.messagePreviewBookings || null,
  hash: state.router.hash || '',
  messageBookings: state.customers.messagePreviewBookings || null,
  selectedCustomer: selectors.customersPropertySelector(state, { property: 'selected' }),
  allowBookingWithinWorkingTimes: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowBookingWithinWorkingTimes' }),
  services: selectors.servicesListSelector(state),
  customerServices: selectors.customerServicesListSelector(state),
  shownCustomerDetailsId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'shownCustomerDetailsId', property: 'value' }),
  bookingsType: (state.forms.customerEvents && state.forms.customerEvents.type && state.forms.customerEvents.type.value) || null,
  fieldsToBeDeleted: Object.keys(state.forms.booking).filter(key => key.includes('bookingForDeletion') && !!state.forms.booking[key].value).map(key => key.replace('bookingForDeletion', '')),
  isCancellationForm: state.router.name === 'cancelBooking',
  displayBookedResources: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'displayBookedResources' })
})

export default memo(connect(maps)(CustomerPreviewBookingsWrapper))
