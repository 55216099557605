import React from 'react'
import PropTypes from 'prop-types'
import { connect } from '../../Store'
import { OverlayMessage } from '../../Common'

const OverlayMessageWrapper = props => {
  let { isActive } = props
  isActive = !!isActive

  return (
    <OverlayMessage {...props} isActive={isActive} />
  )
}

OverlayMessageWrapper.propTypes = {
  isActive: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  buttonLabel: PropTypes.string
}

const maps = state => ({
  isActive: state.overlay.isMessageActive,
  message: state.overlay.message,
  buttonLabel: state.overlay.buttonLabel
})

export default connect(maps)(OverlayMessageWrapper)
