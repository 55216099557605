import { registerRoute, registerAfterEach } from '../Router'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE, AVAILABLE_LANGUAGES, DEFAULT_LOCALES_BY_LANG } from '../../Settings'
import { loginFormInitialValues } from '../../Store/Components/Auth/utils'

registerAfterEach((route, state, handlers) => {
  const { query } = route || {}
  let {
    token,
    locale: routeLocale,
    redirect,
    customerId,
    externalCustomerId,
    redirect_uri: oAuthRedirectUrl,
    client_id: oAuthClientId,
    response_type: oAuthResponseType,
    state: oAuthState,
    debug
  } = query || {}
  if (token) {
    handlers.logout(routeLocale, token)
  }
  routeLocale = !SUPPORTED_LOCALES.includes(routeLocale) ? null : routeLocale
  const appLocale = !SUPPORTED_LOCALES.includes(state.app.locale) ? null : state.app.locale
  const persistPayload = { customerId, externalCustomerId }
  if (!customerId) persistPayload.customerId = null
  if (debug === 'true') persistPayload.isDebuggingActive = true
  if (debug === 'false') {
    persistPayload.isDebuggingActive = false
    persistPayload.isPhraseActive = false
    persistPayload.isIntercomActive = true
  }
  handlers.persistSet(persistPayload)
  let loginUrl = '/login'
  let locale = routeLocale || appLocale || DEFAULT_LOCALE
  const params = []
  if (locale && locale !== DEFAULT_LOCALE) params.push(`locale=${locale}`)
  if (redirect && !redirect.includes('logout')) params.push(`redirect=${redirect}`)
  if (oAuthRedirectUrl) params.push(`redirect_uri=${oAuthRedirectUrl}`)
  if (oAuthClientId) params.push(`client_id=${oAuthClientId}`)
  if (oAuthResponseType) params.push(`response_type=${oAuthResponseType}`)
  if (oAuthState) params.push(`response_type=${oAuthState}`)
  if (!state.auth.tokens && !['login', 'sso', 'oAuth'].includes(route.name)) {
    if (!redirect) params.push(`redirect=${route.path}`)
    if (params.length > 0) loginUrl = `${loginUrl}?${params.join('&')}`
    handlers.navigateToPath(loginUrl)
  }
  if (state.auth.tokens && ['login', 'sso'].includes(route.name) && !token) handlers.navigateToPath('/')
})

// HOME

registerRoute('home', {
  pattern: '/'
})

// AUTH

registerRoute('login', {
  pattern: '/login',
  onAfter: (route, state, handlers) => {
    const mappedLocales = {}
    const availableLocales = AVAILABLE_LANGUAGES.map(language => language.locale)
    SUPPORTED_LOCALES.forEach(locale => {
      mappedLocales[locale] = availableLocales.find(availableLocale => locale.includes(availableLocale)) || DEFAULT_LOCALES_BY_LANG[locale.split('-')[0]] || 'en-gb'
    })
    const { query } = route || {}
    const { locale: routeLocale } = query || {}
    let locale = routeLocale || state.app.locale
    locale = mappedLocales[locale] || DEFAULT_LOCALE

    handlers.languageChange(locale)
    handlers.formFieldsUpdate('login', { language: { ...loginFormInitialValues(locale).language, value: locale } })
  }
})

registerRoute('logout', {
  pattern: '/logout',
  onAfter: (route, state, handlers) => {
    handlers.logout()
  }
})

// ACCOUNTS

registerRoute('accounts', {
  pattern: '/accounts',
  props: {
    sidebar: {
      position: 'left',
      level: 1,
      negative: true
    },
    overlay: true
  }
})

// ACCOUNT

registerRoute('account', {
  pattern: '/user',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('accountEdit', {
  pattern: '/user/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'account'
  }
})

registerRoute('accountPasswordChange', {
  pattern: '/user/change-password',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'account'
  }
})

registerRoute('accountEmailChange', {
  pattern: '/user/change-email',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/user',
    formDiscard: 'account'
  }
})

// SETTINGS

registerRoute('settings', {
  pattern: '/settings',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('settingsEdit', {
  pattern: '/settings/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/settings',
    formDiscard: 'settings'
  }
})

registerRoute('scheduleBooking', {
  pattern: '/schedule-booking',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    formDiscard: 'booking'
  }
})

registerRoute('rescheduleBooking', {
  pattern: '/reschedule-booking',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    formDiscard: 'booking'
  }
})

registerRoute('cancelBooking', {
  pattern: '/cancel-booking',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    formDiscard: 'booking'
  }
})

registerRoute('createCustomer', {
  pattern: '/create-customer',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('editCustomer', {
  pattern: '/edit-customer',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('agents', {
  pattern: '/agents',
  props: {
    sidebar: {
      position: 'left',
      level: 1
    },
    overlay: true
  }
})

registerRoute('agentsPreview', {
  pattern: '/agents/:id',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/agents'
  }
})

registerRoute('agentsAdd', {
  pattern: '/agents/add',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/agents',
    formDiscard: 'agents'
  }
})

registerRoute('agentsEdit', {
  pattern: '/agents/:id/edit',
  props: {
    sidebar: {
      position: 'left',
      level: 2
    },
    overlay: true,
    defaultRedirect: '/agents',
    formDiscard: 'agents'
  }
})
