import React from 'react'
import PropTypes from 'prop-types'
import { Link, FontAwesome5, Online } from '../../Common'
import { MenuItemNotifications } from '../../Beauties'

const MenuItem = props => {
  const {
    active,
    label,
    icon,
    iconType,
    to,
    hasChildrens,
    notifications,
    hideWhenOffline,
    isSubMenu,
    metaData,
    onClick,
    children
  } = props
  const classNames = ['ta-menu__item']
  if (active) classNames.push('active')
  if (active && !hasChildrens) classNames.push('no-childrens')
  if (metaData && metaData.className) classNames.push(metaData.className)

  return (
    <li className={classNames.join(' ')}>
      <Online disabled={!hideWhenOffline}>
        <Link onClick={() => onClick(to)}>
          {(icon &&
            <div className='ta-menu__icon'>
              <FontAwesome5 icon={icon} type={iconType} />
            </div>
          )}
          {(notifications > 0 && !active &&
            <MenuItemNotifications notifications={notifications} />
          )}
          <span>{label}</span>
        </Link>
        <div className='ta-menu__item__sub-menu'>
          {(!isSubMenu &&
            <div className='ta-menu__item__sub-menu__label'>{label}</div>
          )}
          {children}
        </div>
      </Online>
    </li>
  )
}

MenuItem.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icons: PropTypes.arrayOf(PropTypes.string),
  to: PropTypes.string.isRequired
}

export default MenuItem
