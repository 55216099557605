import React from 'react'
import { BookingScheduleWrapper, BookingRescheduleWrapper, BookingCancelWrapper } from '../../Beasts'
import { connect, selectors } from '../../Store'
import './Bookings.css'

const BookingWrapper = ({ route }) => {
  return (
    <>
      {route === 'scheduleBooking' && <BookingScheduleWrapper />}
      {route === 'rescheduleBooking' && <BookingRescheduleWrapper />}
      {route === 'cancelBooking' && <BookingCancelWrapper />}
    </>
  )
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' })
})

export default connect(maps)(BookingWrapper)
