import React from 'react'
import momenttz from 'moment-timezone'
import { handlers, selectors, connect } from '../../../Store'
import { BookingFormSlotPreferences } from '../../../Beauties'

const BookingSlotPreferencesWrapper = ({
  timezone,
  progress,
  from,
  isScheduleForm,
  allowBookingWithinWorkingTimes,
  pendingBranches,
  pendingTransformation
}) => {
  const onSubmit = () => {
    handlers.formFieldsUpdate('booking', {
      availabilityDate: { value: from },
      progress: { value: 4 }
    })
  }

  const onAllowanceTypeClick = () => {
    handlers.formFieldsUpdate('booking', {
      progress: { value: 3 },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      notes: { value: '' }
    })
  }

  const onIntervalChangeAddon = () => {
    handlers.formFieldsUpdate('booking', {
      progress: { value: 3 },
      availabilityDate: { value: null },
      availableSlots: [],
      slots: {},
      notes: { value: '' }
    })
  }

  return (
    <BookingFormSlotPreferences
      onSubmit={onSubmit}
      minDate={momenttz.tz(new Date(), timezone)}
      onAllowanceTypeClick={onAllowanceTypeClick}
      allowBookingWithinWorkingTimes={allowBookingWithinWorkingTimes}
      isScheduleForm={isScheduleForm}
      pendingBranches={pendingBranches}
      pendingTransformation={pendingTransformation}
      onIntervalChangeAddon={onIntervalChangeAddon}
    />
  )
}

const maps = state => ({
  progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
  from: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'from', property: 'value' }),
  pendingTransformation: selectors.bookingsFieldSelector(state, { field: 'pendingTransformation' }),
  pendingBranches: selectors.bookingsFieldSelector(state, { field: 'pendingBranches' }),
  allowBookingWithinWorkingTimes: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowBookingWithinWorkingTimes' })
})

export default connect(maps)(BookingSlotPreferencesWrapper)
