import { handlers, globalActions, payloads$, actions } from '../../../Store'
import { q } from '../../API'

globalActions.populateTags = async (tags) => {
  // await globalActions.populateBranches()
  handlers.tagsListPopulate({ tags: tags || [] })
  return tags
}

let isLoading = false
payloads$(actions.GLOBAL_TAGS_GET)
  .subscribe(async () => {
    if (isLoading) return
    isLoading = true

    const tags = await q('getEnterpriseCallCenterCompanyTags')
    const { error } = tags || {}

    if (error) {
      isLoading = false
      return
    }
    handlers.globalTagsPopulate(tags)
  })
