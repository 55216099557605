import React from 'react'
import { Checkbox, t } from '../../../Common'
import { PermissionTypeAccordion } from '../../../Beauties'

import './PermissionTypesPermissionsForm.css'

const PermissionTypesPermissionsForm = ({ form, ...props }) => {
  const {
    customersRead,
    usersRead,
    usersWrite
  } = props

  return (
    <div className='ta-agents__form__acl'>
      <PermissionTypeAccordion title={t('global.bookings')}>
        <div className='ta-agents__form__acl__row-title'>
          <div className='ta-agents__form__acl__label'>{t('agents.permissions.bookingsSchedule.label')}:</div>
          <Checkbox name='bookingsSchedule' theme='switch' />
        </div>
        <div className='ta-agents__form__acl__row-title'>
          <div className='ta-agents__form__acl__label'>{t('agents.permissions.bookingsreschedule.label')}:</div>
          <Checkbox name='bookingsReschedule' theme='switch' />
        </div>
        <div className='ta-agents__form__acl__row-title'>
          <div className='ta-agents__form__acl__label'>{t('agents.permissions.bookingsDelete.label')}:</div>
          <Checkbox name='bookingsDelete' theme='switch' />
        </div>
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.customers')}>
        <div className='ta-agents__form__acl__row-title'>
          <div className='ta-agents__form__acl__label'>{t('agents.permissions.customersRead.label')}:</div>
          <Checkbox name='customersRead' theme='switch' />
        </div>
        {(customersRead &&
          <div className='ta-agents__form__acl__row-title'>
            <div className='ta-agents__form__acl__label'>{t('agents.permissions.customersWrite.label')}:</div>
            <Checkbox name='customersWrite' theme='switch' />
          </div>
        )}
      </PermissionTypeAccordion>
      <PermissionTypeAccordion title={t('global.agents')}>
        <div className='ta-agents__form__acl__row-title'>
          <div className='ta-agents__form__acl__label'>{t('agents.permissions.agentsRead.label')}:</div>
          <Checkbox name='usersRead' theme='switch' />
        </div>
        {(usersRead &&
          <div className='ta-agents__form__acl__row-title'>
            <div className='ta-agents__form__acl__label'>{t('agents.permissions.agentsWrite.label')}:</div>
            <Checkbox name='usersWrite' theme='switch' />
          </div>
        )}
        {(usersRead && usersWrite &&
          <div className='ta-agents__form__acl__row-title'>
            <div className='ta-agents__form__acl__label'>{t('agents.permissions.agentsDelete.label')}:</div>
            <Checkbox name='usersDelete' theme='switch' />
          </div>
        )}
      </PermissionTypeAccordion>
    </div>
  )
}

export default PermissionTypesPermissionsForm
