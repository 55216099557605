import {
  agentFormTransform,
  agentDeleteFormTransform,
  agentTransform,
  agentsListTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pendingForm: true,
  pendingPreview: true,
  pendingList: true,
  pendingDeleteForm: true,
  pendingEmailUnlink: false,
  messagePreview: null
}

reducer.handlers = (dispatch, actions, handlers) => ({

  // Subscription
  agentsSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.AGENTS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  agentsListGet: () => dispatch(actions.AGENTS_LIST_GET),
  agentsListPopulate: ({ agents }) => dispatch(actions.AGENTS_LIST_POPULATE, { agents: agentsListTransform(agents) }),

  // One
  agentUpdate: agent => dispatch(actions.AGENT_UPDATE, agentTransform(agent)),
  agentUpdated: agent => dispatch(actions.AGENT_UPDATED, agent),
  enterpriseUserUpdated: agent => dispatch(actions.AGENT_UPDATED, agent),
  agentDelete: id => dispatch(actions.AGENT_DELETE, id),
  agentDeleted: id => dispatch(actions.AGENT_DELETED, id),
  agentRemoveDeleted: id => dispatch(actions.AGENT_REMOVE_DELETED, id),
  agentPermissionsChanged: () => dispatch(actions.AGENT_PERMISSIONS_CHANGED),

  // Preview
  agentPreviewGet: ({ id, forceFetch }) => dispatch(actions.AGENT_PREVIEW_GET, { id, forceFetch }),
  agentPreviewPopulate: agent => dispatch(actions.AGENT_PREVIEW_POPULATE, agent),

  // Form
  agentFormGet: id => dispatch(actions.AGENT_FORM_GET, id),
  agentFormPopulate: (agent, permissionOptions) => {
    handlers.formSet('agents', agentFormTransform(agent, permissionOptions))
    handlers.agentFormReady()
  },
  agentFormReady: () => dispatch(actions.AGENT_FORM_READY),
  agentFormSave: (agent, scrollToError = () => { }) => dispatch(actions.AGENT_FORM_SAVE, { agent, scrollToError }),
  agentDeleteFormPopulate: () => {
    handlers.formSet('agentDelete', agentDeleteFormTransform())
    handlers.agentDeleteFormReady()
  },
  agentDeleteFormReady: () => dispatch(actions.AGENT_DELETE_FORM_READY),
  agentDeleteFormSave: (agent, scrollToError = () => { }) => dispatch(actions.AGENT_DELETE_FORM_SAVE, { agent, scrollToError }),

  agentPreviewEmailConfirmationSend: email => dispatch(actions.AGENT_PREVIEW_EMAIL_CONFIRMATION_SEND, email)

})

// List

reducer.AGENTS_LIST_GET = state => ({
  ...state,
  pendingList: true
})

reducer.AGENTS_LIST_POPULATE = (state, { agents }) => ({
  ...state,
  list: agents,
  pendingList: false,
  messagePreview: null,
  messagePreviewServices: null
})

// One

reducer.AGENT_UPDATE = (state, agent) => {
  if (!agent) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === agent.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: true }

  return {
    ...state,
    list
  }
}

reducer.AGENT_UPDATED = (state, agent) => {
  if (!agent) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === agent.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}

reducer.AGENT_DELETE = state => ({
  ...state,
  pendingPreview: true
})

reducer.AGENT_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}

reducer.AGENT_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

// Preview

reducer.AGENT_PREVIEW_GET = state => ({
  ...state,
  messagePreview: null,
  pendingPreview: true
})

reducer.AGENT_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false }
  return {
    ...state,
    pendingPreview: false
  }
}

// Form

reducer.AGENT_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.AGENT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.AGENT_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.AGENT_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.AGENT_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

// Subscription
reducer.AGENT_PERMISSIONS_CHANGED = state => state
reducer.AGENTS_SUBSCRIPTION_SET = state => state

reducer.AGENT_PREVIEW_EMAIL_CONFIRMATION_SEND = (state, email) => {
  if (!email) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.email === email)
  if (index < 0) return state
  list[index] = { ...list[index], isConfirmationEmailReSent: true }
  return {
    ...state,
    list
  }
}

export default reducer
