import React, { Suspense } from 'react'
import { connect, selectors } from '../../Store'
import { Spinner } from '../../Common'

const AccountEditWrapper = React.lazy(() => import('../../Beasts/Account/AccountEditWrapper'))
const AccountPasswordChangeWrapper = React.lazy(() => import('../../Beasts/Account/AccountPasswordChangeWrapper'))
const AccountEmailChangeWrapper = React.lazy(() => import('../../Beasts/Account/AccountEmailChangeWrapper'))
const SettingsEditWrapper = React.lazy(() => import('../Settings/SettingsEditWrapper'))
const AgentPreviewWrapper = React.lazy(() => import('../Agents/AgentPreviewWrapper'))
const AgentsAddWrapper = React.lazy(() => import('../Agents/AgentAddWrapper'))
const AgentEditWrapper = React.lazy(() => import('../Agents/AgentEditWrapper'))

const LeftSecondSidebarWrapper = props => {
  const { route } = props

  return (
    <Suspense fallback={<Spinner />}>
      {route === 'accountEdit' && <AccountEditWrapper />}
      {route === 'accountPasswordChange' && <AccountPasswordChangeWrapper />}
      {route === 'accountEmailChange' && <AccountEmailChangeWrapper />}
      {route === 'settingsEdit' && <SettingsEditWrapper />}
      {route === 'agentsPreview' && <AgentPreviewWrapper />}
      {route === 'agentsAdd' && <AgentsAddWrapper />}
      {route === 'agentsEdit' && <AgentEditWrapper />}
    </Suspense>
  )
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' })
})

export default connect(maps)(LeftSecondSidebarWrapper)
