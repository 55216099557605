import { handlers, store, selectors } from '../../../Store'

export const agentUpdated = async id => {
  if (!id) return
  handlers.agentsListGet()
}

export const agentDeleted = id => {
  if (!id) return
  const state = store.getState()
  const agent = selectors.agentByIdSelector(state, { id }) || {}
  if (!agent || agent.isDeleted) return
  handlers.agentDeleted(id)
  setTimeout(() => handlers.agentRemoveDeleted(id), 2000)
}
