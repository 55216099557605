import React, { Suspense } from 'react'
import { connect, selectors } from '../../Store'
import { Spinner } from '../../Common'

const RightFirstSidebarWrapper = props => {
  return (
    <Suspense fallback={<Spinner />} />
  )
}

const maps = state => ({
  route: selectors.routerFieldSelector(state, { field: 'name' })
})

export default connect(maps)(RightFirstSidebarWrapper)
