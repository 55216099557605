import React, { Component } from 'react'
import { FontAwesome5 } from '../../Common'

class NavigationToggleArrow extends Component {
  constructor (props, context) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    const { position, changePosition } = this.props
    const newPosition = position === 'expanded' ? 'iconOnly' : 'collapsed'
    changePosition && changePosition(newPosition)
  }

  render () {
    const { position, disabled } = this.props
    const isCollapsed = position === 'collapsed'
    const classNames = ['ta-navigation__toggle-arrow']
    if (disabled) classNames.push('disabled')
    if (isCollapsed) classNames.push('collapsed')
    const color = isCollapsed ? '#CB3B43' : '#3E5062'
    const icon = isCollapsed ? 'angle-right' : 'angle-left'

    return (
      <div className={classNames.join(' ')} onClick={this.onClick}>
        <svg with='10' height='38' viewBox='0 0 10 38' version='1.1'>
          <g>
            <circle cx='0' cy='19' r='10' fill={color} stroke='none' />
            <path d={`M0,0
                      L0,35
                      A11,15 -45 0,1 6,27
                      L6,11
                      A15,11 -45 0,1 0,3
                      Z`} stroke='none' fill={color} />
          </g>
        </svg>
        <div className='ta-navigation__toggle-arrow__icon'>
          <FontAwesome5 icon={icon} />
        </div>
      </div>
    )
  }
}

export default NavigationToggleArrow
