import React from 'react'
import { PopupTriggerWrapper } from '../../Beasts'
import {
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  FontAwesome5,
  FormSection,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Link,
  ListItem,
  Row,
  Col,
  SectionTitle,
  Title,
  t
} from '../../Common'
import { dangerousHTML } from '../../Utils'
import './Settings.css'

const SettingsList = (props) => {
  const {
    isOwner,
    allowMultipleAppointmentsPerBooking,
    allowBookingWithinWorkingTimes,
    hideOfflineService,
    hasCCNotifyOnResources,
    hasCCNotifyOnCustomers,
    newBookingActivated,
    rescheduleBookingActivated,
    resourceSelectorRescheduleManual,
    displayBookedResources
  } = props

  return (
    <div className='ta-settings'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-settings__header-title'>
            <Title label={t('settings.section.title')}>
              <PopupTriggerWrapper name='settings-help-box' position='bottom' extraClassName='ta-help-box'>
                <Link className='ta-title__link' external>
                  {t('global.help')} <FontAwesome5 type='r' icon='lightbulb' />
                </Link>
              </PopupTriggerWrapper>
              {(isOwner &&
                <HoverPopup>
                  <HoverPopupContent position='left' autoSize>
                    {t('global.settings.edit')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link to='/settings/edit' className='ta-btn ta-btn-primary ta-btn-icon-only'>
                      <FontAwesome5 icon='pencil' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </Title>
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <FormSection>
            <SectionTitle label={t('global.settings.multipleAppointments.title')} icon='calendar-check' />
            <ListItem>
              {/* <div className='ta-settings__title'>{t('global.settings.multipleAppointments.subtitle')}</div> */}
              <div className='ta-settings__title'>{dangerousHTML(allowMultipleAppointmentsPerBooking ? t('global.settings.multipleAppointments.desc') : t('global.settings.singleAppointments.desc'))}</div>
              {/* <BorderedBox>
                <div className='ta-settings__desc'>
                  {dangerousHTML(allowMultipleAppointmentsPerBooking ? 'global.settings.multipleAppointments.desc' : 'global.settings.singleAppointments.desc')}
                </div>
              </BorderedBox> */}
            </ListItem>
          </FormSection>
          <FormSection>
            <SectionTitle label={t('global.settings.resourceSelector.title')} icon='user-friends' />
            <ListItem>
              <div className='ta-settings__title'>
                {dangerousHTML(t(`global.settings.resourceSelector.preview.addBooking.${newBookingActivated ? 'enabled' : 'disabled'}`))}
                <br />
                {dangerousHTML(t(`global.settings.resourceSelector.preview.rescheduleBooking.${rescheduleBookingActivated ? 'enabled' : 'disabled'}`))}
                {rescheduleBookingActivated && (
                  <ListItem className='bullet-item ta-settings__resources-li'>
                    {dangerousHTML(t(`global.settings.resourceSelector.preview.rescheduleBooking.${resourceSelectorRescheduleManual ? 'manual' : 'automatic'}`))}
                  </ListItem>
                )}
                {!rescheduleBookingActivated && <br />}
                {dangerousHTML(t(`global.settings.resourceSelector.preview.displayBookedResources.${displayBookedResources ? 'enabled' : 'disabled'}`))}
              </div>
            </ListItem>
          </FormSection>
          <FormSection>
            <SectionTitle label={t('global.settings.slotPreferences.title')} icon='calendar-check' />
            <ListItem>
              {/* <div className='ta-settings__title'>{t('global.settings.slotPreferences.subtitle')}</div> */}
              <div className='ta-settings__title'>{dangerousHTML(allowBookingWithinWorkingTimes ? t('global.settings.slotPreferences.enabled.desc') : t('global.settings.slotPreferences.disabled.desc'))}</div>
              {/* <BorderedBox>
                <div className='ta-settings__desc'>
                  {dangerousHTML(allowBookingWithinWorkingTimes ? 'global.settings.slotPreferences.enabled.desc' : 'global.settings.slotPreferences.disabled.desc')}
                </div>
              </BorderedBox> */}
            </ListItem>
          </FormSection>
          <FormSection>
            <SectionTitle label={t('global.settings.hideOfflineSettings.title')} icon='signal-alt-slash' />
            <ListItem>
              <div className='ta-settings__desc'>
                {(hideOfflineService &&
                  <div>
                    {dangerousHTML(t('global.enabled'))}
                  </div>
                )}
                {(hideOfflineService &&
                  <div>
                    {dangerousHTML(t('global.settings.hideOfflineSettings.value.enabled'))}
                  </div>
                )}
                {(!hideOfflineService &&
                  <div>
                    {dangerousHTML(t('global.disabled'))}
                  </div>
                )}
                {(!hideOfflineService &&
                  <div>{dangerousHTML(t('global.settings.hideOfflineSettings.value.disabled'))}
                  </div>
                )}
              </div>
            </ListItem>
          </FormSection>
          <FormSection>
            <SectionTitle label={t('settings.section.notifyCheckboxes.heading')} icon='envelope' />
            <Row noOffset>
              <Col>
                <ListItem className='bullet-item'>
                  {hasCCNotifyOnResources === 'SELECTED' && t('settings.section.notifyCheckboxes.resources.radio.flexiblySelectedDefault')}
                  {hasCCNotifyOnResources === 'TRUE' && t('settings.section.notifyCheckboxes.resources.radio.alwaysSelected')}
                  {hasCCNotifyOnResources === 'FALSE' && t('settings.section.notifyCheckboxes.resources.radio.neverSelected')}
                </ListItem>
              </Col>
            </Row>
            <Row noOffset>
              <Col>
                <ListItem className='bullet-item'>
                  {hasCCNotifyOnCustomers === 'SELECTED' && t('settings.section.notifyCheckboxes.customers.radio.flexiblySelectedDefault')}
                  {hasCCNotifyOnCustomers === 'TRUE' && t('settings.section.notifyCheckboxes.customers.radio.alwaysSelected')}
                  {hasCCNotifyOnCustomers === 'FALSE' && t('settings.section.notifyCheckboxes.customers.radio.neverSelected')}
                </ListItem>
              </Col>
            </Row>
          </FormSection>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

export default SettingsList
