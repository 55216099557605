const menu = [
  {
    id: 'schedule-booking',
    translationKey: 'global.scheduleBooking',
    routes: ['scheduleBooking'],
    to: '/schedule-booking',
    icon: 'calendar-plus',
    iconType: 'solid',
    hideWhenOffline: true,
    metaData: {
      className: 'ta-navigation__links__active__persist'
    }
  },
  {
    id: 'reschedule-booking',
    translationKey: 'global.rescheduleBooking',
    routes: ['rescheduleBooking'],
    to: '/reschedule-booking',
    icon: 'calendar-day',
    iconType: 'solid',
    notifications: 0,
    hideWhenOffline: true
  },
  {
    id: 'cancelBooking',
    translationKey: 'global.cancelBooking',
    routes: ['cancelBooking'],
    to: '/cancel-booking',
    icon: 'calendar-times',
    iconType: 'solid',
    notifications: 0,
    hideWhenOffline: true
  },
  // {
  //   id: 'createCustomer',
  //   translationKey: 'global.createCustomer',
  //   routes: ['createCustomer'],
  //   to: '/create-customer',
  //   icon: 'user-plus',
  //   iconType: 'solid',
  //   notifications: 0,
  //   hideWhenOffline: true
  // },
  // {
  //   id: 'edit-customer',
  //   translationKey: 'global.editCustomer',
  //   routes: ['editCustomer'],
  //   to: '/edit-customer',
  //   icon: 'user-edit',
  //   iconType: 'solid',
  //   notifications: 0,
  //   hideWhenOffline: true
  // },
  {
    id: 'agents',
    translationKey: 'global.agents',
    routes: ['agents', 'agentsPreview', 'agentsAdd', 'agentsEdit'],
    to: '/agents',
    icon: 'users',
    iconType: 'solid',
    notifications: 0,
    hideWhenOffline: true
  },
  // {
  //   id: 'statistics',
  //   translationKey: 'global.statistics',
  //   routes: ['statistics'],
  //   to: '/statistics',
  //   icon: 'chart-pie',
  //   iconType: 'solid',
  //   notifications: 0,
  //   hideWhenOffline: true
  // },
  {
    id: 'settings',
    translationKey: 'global.settings',
    routes: ['settings', 'settingsEdit'],
    to: '/settings',
    icon: 'cog',
    iconType: 'solid',
    notifications: 0,
    hideWhenOffline: true
  }
]

export default menu
