import React from 'react'
import {
  SidebarsWrapper,
  PopupWrapper,
  NavigationWrapper,
  ContentWrapper,
  OverlayMessageWrapper,
  DebugOptionsWrapper
} from '../../Beasts'
import { FormDiscardPopup } from '../../Common'

const AppAuth = props => {
  const { hasOverwriteCss } = props
  const classNames = ['ta-application']
  if (hasOverwriteCss) classNames.push('overwrite')

  return (
    <div className={classNames.join(' ')}>
      <NavigationWrapper />
      <SidebarsWrapper />
      <PopupWrapper />
      <ContentWrapper />
      <OverlayMessageWrapper />
      <FormDiscardPopup />
      <DebugOptionsWrapper />
    </div>
  )
}

export default AppAuth
