import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, store, selectors } from '../../../Store'
import { PopupAgentsDelete } from '../../../Beauties'

class PopupAgentsDeleteWrapper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
  }

  componentDidMount () {
    handlers.agentDeleteFormPopulate()
  }

  onSubmit (cb) {
    const { id } = this.props
    const form = store.getState().forms.agentDelete
    form.id = id
    handlers.agentDeleteFormSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  onCancel () {
    const { closePopup } = this.props
    closePopup && closePopup()
  }

  render () {
    let { pending, agent } = this.props
    pending = !!pending
    agent = agent || {}

    return (
      <PopupAgentsDelete
        name={agent.name}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        pending={pending}
      />
    )
  }
}

PopupAgentsDeleteWrapper.propTypes = {
  closePopup: PropTypes.func.isRequired
}

const maps = (state, props) => ({
  pending: selectors.agentsFieldSelector(state, { field: 'pendingDeleteForm' }),
  agent: selectors.agentByIdSelector(state, { id: props.id })
})

export default connect(maps)(PopupAgentsDeleteWrapper)
