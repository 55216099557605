import {
  companyPopulateTransform,
  companyFormTransform,
  companySettingsPopulateTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  pendingOnlineStatus: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  companyReset: () => dispatch(actions.COMPANY_RESET),

  // Subscription
  companySubscriptionSet: ({ name, ids, data, ts }) => dispatch(actions.COMPANY_SUBSCRIPTION_SET, { name, ids, data, ts }),

  // Appcues
  companyAppcuesInit: () => dispatch(actions.COMPANY_APPCUES_INIT),

  // One
  companyPopulate: company => dispatch(actions.COMPANY_POPULATE, companyPopulateTransform(company)),
  companyGDPRTimestampSet: () => dispatch(actions.COMPANY_GDPR_TIMESTAMP_SET),
  companyLastCustomersDownloadSet: date => dispatch(actions.COMPANY_LAST_CUSTOMERS_DOWNLOAD_SET, date),
  companyLastStatisticsStateChangeSet: date => dispatch(actions.COMPANY_LAST_STATISTICS_STATE_CHANGE_SET, date),

  // Form
  companyFormGet: () => dispatch(actions.COMPANY_FORM_GET),
  companyFormPopulate: (company) => {
    handlers.companyFormReady()
    handlers.formSet('company', companyFormTransform(company))
  },
  companyFormReady: () => dispatch(actions.COMPANY_FORM_READY),
  companyFormSave: (company, scrollToError = () => { }) => dispatch(actions.COMPANY_FORM_SAVE, { company, scrollToError }),
  companyDeleteFormSave: (form, scrollToError = () => { }) => dispatch(actions.COMPANY_DELETE_FORM_SAVE, { form, scrollToError }),
  companyDeleteFormReady: () => dispatch(actions.COMPANY_DELETE_FORM_READY),
  companyOnboardingFormSave: (form, scrollToError = () => { }) => dispatch(actions.COMPANY_ONBOARDING_FORM_SAVE, { form, scrollToError }),
  companyOnboardingFormReady: () => dispatch(actions.COMPANY_ONBOARDING_FORM_READY),

  // Settings
  companySettingsUpdate: ({ rawSettings, settings }) => dispatch(actions.COMPANY_SETTINGS_UPDATE, { rawSettings, settings: companySettingsPopulateTransform(settings) }),

  // Online status
  companyOnlineStatusToggle: () => dispatch(actions.COMPANY_ONLINE_STATUS_TOGGLE),
  companyOnlineStatusPopulate: onlineStatus => dispatch(actions.COMPANY_ONLINE_STATUS_POPULATE, onlineStatus)
})

reducer.COMPANY_RESET = () => reducer.initialState

reducer.COMPANY_SUBSCRIPTION_SET = state => state

reducer.COMPANY_APPCUES_INIT = state => state

reducer.COMPANY_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.COMPANY_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.COMPANY_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.COMPANY_POPULATE = (state, company = {}) => ({
  ...state,
  ...company
})

reducer.COMPANY_SETTINGS_UPDATE = (state, { rawSettings = {}, settings = {} }) => ({
  ...state,
  rawSettings,
  settings,
  pendingForm: true
})

reducer.COMPANY_GDPR_TIMESTAMP_SET = state => state

reducer.COMPANY_LAST_CUSTOMERS_DOWNLOAD_SET = (state, date) => ({
  ...state,
  lastCustomersDownload: date || null
})

reducer.COMPANY_LAST_STATISTICS_STATE_CHANGE_SET = (state, date) => ({
  ...state,
  lastStatisticsStateChange: date || null
})

// Form

reducer.COMPANY_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

reducer.COMPANY_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.COMPANY_ONBOARDING_FORM_SAVE = state => ({
  ...state,
  pendingOnboardingForm: true
})

reducer.COMPANY_ONBOARDING_FORM_READY = state => ({
  ...state,
  pendingOnboardingForm: false
})

reducer.COMPANY_ONLINE_STATUS_TOGGLE = state => ({
  ...state,
  pendingOnlineStatus: true
})

reducer.COMPANY_ONLINE_STATUS_POPULATE = (state, onlineStatus) => ({
  ...state,
  pendingOnlineStatus: false,
  onlineStatus
})

export default reducer
