import React from 'react'
import PropTypes from 'prop-types'
import {
  AppAuthWrapper,
  LoginWrapper,
  OAuthWrapper
} from '../../Beasts'
import { RouteNotFound, AccessRevoked } from '../../Beauties'

import './App.css'

const App = props => {
  const {
    isAuthenticated,
    isPending,
    hasTokens,
    route,
    flow
  } = props

  return (
    <>
      {route === 'oAuth' && !isPending && <OAuthWrapper />}
      {route === 'accessRevoked' && !isPending && !flow && <AccessRevoked />}
      {route !== 'notfound' && !isPending && !flow && !hasTokens && !isAuthenticated && <LoginWrapper />}
      {route !== 'notfound' && !isPending && !flow && hasTokens && isAuthenticated && <AppAuthWrapper />}
      {route === 'notfound' && !isPending && <RouteNotFound />}
    </>
  )
}

App.propTypes = {
  account: PropTypes.object,
  route: PropTypes.string
}

export default App
