import React from 'react'
import { GoogleStaticMap, Online } from '../../Common'

import './Address.css'

const Address = props => {
  const { address, details = '' } = props

  return (
    <div className='ta-address'>
      <Online hideContent>
        <div className='ta-address__map'>
          <GoogleStaticMap address={address} size='450x200' />
        </div>
      </Online>
      <div className='ta-address__label' data-testid='address-label'>
        {address}
        {details && `, ${details}`}
      </div>

    </div>
  )
}

export default Address
