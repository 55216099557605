import { DEFAULT_LOCALE, AVAILABLE_LANGUAGES } from '../../../Settings'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '../../../Settings/settings'
import { translateServerCode, validator, sortBy } from '../../../Utils'

export const accountPopulateTransform = account => {
  if (!account) return
  let { accounts } = account
  accounts = accounts || []
  const settings = account.settings || {}
  const pattern = /^((http|https|ftp):\/\/)/
  const prefix = (account.enterprise && account.enterprise.avatarPrefix) || ''
  const avatarUrl = (
    settings.avatarUrl && pattern.test(settings.avatarUrl)
      ? settings.avatarUrl
      : (settings.avatarUrl && settings.avatarUrl !== '')
        ? `${prefix}/${settings.avatarUrl}`
        : account.avatarUrl || ''
  )
  const result = {
    ...account,
    ...settings,
    avatarUrl,
    accounts: accounts.map(item => {
      const formattedAccount = {
        ...item,
        ...item.enterprise
      }
      delete formattedAccount.enterprise
      return formattedAccount
    })
  }
  delete result.settings

  return result
}

export const userSettingsTransform = (settings, resources) => {
  settings = settings || {}
  resources = resources || []
  const resourceId = (resources[0] || {}).id
  const resourcesDaily = (settings.dailyResources || []).filter(item => resources.find(resource => resource.id === item))
  const resourcesWeekly = (settings.weeklyResources || []).filter(item => resources.find(resource => resource.id === item))
  const resourcesMonthly = (settings.monthlyResources || []).filter(item => resources.find(resource => resource.id === item))
  const resourcesShiftPlan = (settings.availabilityPlanResources || []).filter(item => resources.find(resource => resource.id === item))
  const result = { ...settings }
  result.from = settings.beginHour || 0
  result.until = settings.endHour || 23
  result.resourcesDaily = resourcesDaily.length > 0 ? resourcesDaily : [resourceId]
  result.resourcesWeekly = resourcesWeekly.length > 0 ? resourcesWeekly : [resourceId]
  result.resourcesMonthly = resourcesMonthly.length > 0 ? resourcesMonthly : [resourceId]
  result.resourcesShiftPlan = resourcesShiftPlan.length > 0 ? resourcesShiftPlan : [resourceId]
  result.days = settings.displayDays || [0, 1, 2, 3, 4, 5, 6]
  delete result.beginHour
  delete result.endHour
  delete result.dailyResources
  delete result.weeklyResources
  delete result.monthlyResources
  delete result.availabilityPlanResources
  delete result.displayDays
  return result
}

export const accountFormTransform = locale => {
  const result = {
    language: {
      value: locale || DEFAULT_LOCALE,
      options: AVAILABLE_LANGUAGES
        .sort(sortBy('name'))
        .map(language => ({
          label: language.name,
          value: language.locale
        }))
    },
    oldPassword: {
      value: '',
      type: 'password'
    },
    newPassword: {
      value: '',
      type: 'password'
    },
    email: {
      value: ''
    },
    reEmail: {
      value: ''
    }
  }
  return result
}

export const accountEditFormValidate = account => {
  if (!account) return
  const errors = []
  if (!account.language || (account.language && account.language.value === '')) errors.push({ key: 'language', value: 'errors.language.required' })

  return errors.length && errors
}

export const accountPasswordChangeFormValidate = (account, enforcePasswordMode) => {
  if (!account) return
  let { oldPassword, newPassword } = account || {}
  oldPassword = oldPassword || {}
  newPassword = newPassword || {}
  const { value: oldPasswordValue } = oldPassword || {}
  const { value: newPasswordValue } = newPassword || {}

  const passwordMinLength = PASSWORD_MIN_LENGTH
  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [{
    'oldPassword.value': ['required', `max:${passwordMaxLength}`, `min:${passwordMinLength}`]
  }]
  const messages = {
    'oldPassword': {
      'required': 'errors.password.required',
      'max': 'errors.invalidMaxLength',
      'min': 'errors.password.weak'
    },
    'newPassword': {
      'required': 'errors.password.required',
      'max': 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    'oldPassword': {
      'max': { key: 'MAX', value: passwordMaxLength },
      'min': { key: 'MIN', value: passwordMinLength }
    },
    'newPassword': {
      'max': { key: 'MAX', value: passwordMaxLength },
      'min': { key: 'MIN', value: passwordMinLength }
    }
  }
  if (!enforcePasswordMode) {
    // rules for if not enforce password form
    rules.push({
      'newPassword.value': ['required', `min:${passwordMinLength}`, `max:${passwordMaxLength}`]
    })
    messages.oldPassword['required'] = 'errors.password.old.required'
    messages.oldPassword['min'] = 'errors.invalidMinLength'
    messages.newPassword['min'] = 'errors.password.weak'
  }
  const errors = validator(account, rules, messages, replaces)

  if (enforcePasswordMode &&
    oldPasswordValue !== newPasswordValue &&
    oldPasswordValue.length >= passwordMinLength
  ) {
    errors.push({ key: 'globalErrors', value: 'errors.password.notMatching' })
  }
  if (!new RegExp(/[!@#$%^&*(),.?":{}|<>\-_]/).test(newPassword.value)) {
    errors.push({ key: 'newPassword', value: 'errors.password.specialCharacter.required' })
  }

  return errors.length && errors
}

export const accountEditSaveTransform = account => {
  const { language } = account || {}
  return { language }
}

export const accountUserDataSaveTransform = account => {
  const { language } = account || {}
  const { value: locale } = language || {}
  return { locale }
}

export const accountPasswordChangeSaveTransform = account => {
  const { oldPassword, newPassword } = account || {}
  return { oldPassword, newPassword }
}

export const accountSaveUserDataTransform = account => {
  const { oldPassword, newPassword } = account || {}
  const { value: oldPasswordValue } = oldPassword || {}
  const { value: newPasswordValue } = newPassword || {}
  return {
    oldPassword: oldPasswordValue,
    password: newPasswordValue
  }
}

export const accountEditFormServerErrorsTransform = (err) => {
  const errors = []
  if (err && err.code) {
    errors.push({ key: 'language', value: translateServerCode(err.code) })
  }
  return errors
}

export const accountPasswordChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }
  if (err.code === 'PasswordWeak') {
    errors.push({ key: 'newPassword', value: translateServerCode(err.code) })
  }
  return errors
}

export const accountPreviewErrorsTransform = err => {
  if (err && err.graphQLErrors && err.graphQLErrors[0]) {
    return { text: err.graphQLErrors[0].message }
  } else {
    return { text: 'Server is down!' }
  }
}

export const accountDeleteFormValidate = form => {
  if (!form) return
  const errors = []

  if (form.password && form.password.value.length === 0) errors.push({ key: 'password', value: 'errors.password.required' })

  return errors.length && errors
}

export const accountDeleteFormSaveTransform = form => {
  const comment = form.comment && form.comment.value
  const result = {
    password: form.password && form.password.value
  }
  if (comment) result.comment = comment
  return result
}

export const accountDeleteFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'password', value: translateServerCode(err.code) })
  }
  return errors
}

// Email change

export const accountUserEmailChangeFormValidate = account => {
  if (!account) return

  const passwordMaxLength = PASSWORD_MAX_LENGTH
  const rules = [
    { 'oldPassword.value': ['required', `max:${passwordMaxLength}`] },
    { 'email.value': ['required', 'email'] },
    { 'reEmail.value': ['required', 'email'] }
  ]
  const messages = {
    'oldPassword': {
      'required': 'errors.password.old.required',
      'max': 'errors.invalidMaxLength'
    },
    'email': {
      'required': 'errors.email.required',
      'email': 'errors.email.invalid'
    },
    'reEmail': {
      'required': 'errors.email.required',
      'email': 'errors.email.invalid'
    }
  }
  const replaces = {
    'oldPassword': {
      'max': { key: 'MAX', value: passwordMaxLength }
    }
  }
  const errors = validator(account, rules, messages, replaces)
  const { email, reEmail } = account || {}
  const { value: emailValue } = email || {}
  const { value: reEmailValue } = reEmail || {}
  if (!errors.length && emailValue !== reEmailValue) errors.push({ key: 'reEmail', value: 'errors.emails.dontMatch' })

  return errors.length && errors
}

export const accountEmailChangeCancelFormValidate = account => {
  if (!account) return

  const passwordMaxLength = 255
  const rules = [
    { 'oldPassword.value': ['required', `max:${passwordMaxLength}`] }
  ]
  const messages = {
    'oldPassword': {
      'required': 'errors.password.old.required',
      'max': 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    'oldPassword': {
      'max': { key: 'MAX', value: passwordMaxLength }
    }
  }
  const errors = validator(account, rules, messages, replaces)

  return errors.length && errors
}

export const accountEmailChangeSaveTransform = account => {
  const { email, oldPassword, reEmail } = account || {}
  return { email, oldPassword, reEmail }
}

export const accountEmailChangeCancelSaveTransform = account => {
  const { oldPassword } = account || {}
  return { oldPassword }
}

export const accountUserEmailChangeTransform = account => {
  const { email, oldPassword } = account || {}
  const { value: emailValue } = email || {}
  const { value: oldPasswordValue } = oldPassword || {}
  return {
    email: emailValue,
    password: oldPasswordValue
  }
}

export const accountUserEmailChangeCancelTransform = account => {
  const { oldPassword } = account || {}
  const { value: oldPasswordValue } = oldPassword || {}
  return { password: oldPasswordValue }
}

export const accountUserEmailChangeFormServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }
  if (err.code === 'EmailChangeInProcess') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (err.code === 'SameEmail') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (err.code === 'EmailExists') {
    errors.push({ key: 'email', value: translateServerCode(err.code) })
  }
  if (errors.length === 0) {
    errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
  }

  return errors
}

export const accountEmailChangeCancelSaveServerErrorsTransform = (err) => {
  const errors = []
  if (err.code === 'PasswordInvalid') {
    errors.push({ key: 'oldPassword', value: translateServerCode(err.code) })
  }

  return errors
}

export const accountSecurityFormTransform = (user) => {
  const { auth2FAs } = user || {}
  let is2FAEnabled = false

  if (auth2FAs && auth2FAs.length && auth2FAs[0]) {
    is2FAEnabled = auth2FAs[0].isActive && auth2FAs[0].type === 'OTP'
  }

  return {
    enabled2FA: {
      value: is2FAEnabled
    },
    isAlreadyEnabled2FA: {
      value: is2FAEnabled
    }
  }
}
