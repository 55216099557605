import React from 'react'
import PropTypes from 'prop-types'
import { connect, selectors } from '../../Store'
import { getDateByTimestamp } from '../../Utils'

const DateFormat = props => {
  const { date, format, timezone } = props
  const _format = format || 'DD/MM/YYYY'
  let timestamp = parseInt(date, 10)
  if (isNaN(timestamp)) timestamp = new Date(date).getTime()
  if (isNaN(timestamp)) return null

  return (
    <span>{getDateByTimestamp(timestamp, timezone, _format)}</span>
  )
}

DateFormat.propTypes = {
  format: PropTypes.string,
  timezone: PropTypes.string.isRequired
}

const maps = state => ({
  timezone: selectors.timezoneSelector(state)
})

export default connect(maps)(DateFormat)
