import { translateServerCode } from '../../../Utils'

export const settingsErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

export const settingsPopulateTransform = settings => {
  return {
    allowBookingWithinWorkingTimes: settings ? settings.allowBookingWithinWorkingTimes : false,
    allowMultipleAppointmentsPerBooking: settings ? settings.allowMultipleAppointmentsPerBooking : false,
    hideOfflineServices: settings ? settings.hideOfflineServices : false,
    hasCCNotifyOnResources: settings ? settings.hasCCNotifyOnResources : 'FALSE',
    hasCCNotifyOnCustomers: settings ? settings.hasCCNotifyOnCustomers : 'TRUE',
    newBookingActivated: settings?.newBookingActivated,
    rescheduleBookingActivated: settings?.rescheduleBookingActivated,
    resourceSelectorRescheduleManual: !!settings?.resourceSelectorRescheduleManual,
    displayBookedResources: !!settings?.displayBookedResources
  }
}

export const settingsFormTransform = settings => {
  return ({
    allowBookingWithinWorkingTimes: { value: settings ? settings.allowBookingWithinWorkingTimes : false },
    allowMultipleAppointmentsPerBooking: { value: settings ? !settings.allowMultipleAppointmentsPerBooking : true },
    hideOfflineServices: { value: settings ? settings.hideOfflineServices : false },
    hasCCNotifyOnResources: {
      value: [null, undefined].includes(settings?.hasCCNotifyOnResources)
        ? 'deselected'
        : settings.hasCCNotifyOnResources === 'SELECTED'
          ? 'default'
          : settings.hasCCNotifyOnResources === 'TRUE'
            ? 'selected'
            : 'deselected'
    },
    hasCCNotifyOnCustomers: {
      value: [null, undefined].includes(settings?.hasCCNotifyOnCustomers)
        ? 'selected'
        : settings.hasCCNotifyOnCustomers === 'SELECTED'
          ? 'default'
          : settings.hasCCNotifyOnCustomers === 'TRUE'
            ? 'selected'
            : 'deselected'
    },
    newBookingActivated: { value: settings?.newBookingActivated },
    rescheduleBookingActivated: { value: !!settings?.rescheduleBookingActivated },
    resourceSelectorRescheduleManual: { value: !!settings?.resourceSelectorRescheduleManual },
    displayBookedResources: { value: !!settings?.displayBookedResources }
  })
}

export const settingsFormSaveTransform = settings => ({
  allowMultipleAppointmentsPerBooking: settings.allowMultipleAppointmentsPerBooking ? !settings.allowMultipleAppointmentsPerBooking.value : true,
  allowBookingWithinWorkingTimes: settings.allowBookingWithinWorkingTimes ? settings.allowBookingWithinWorkingTimes.value : false,
  hideOfflineServices: settings.hideOfflineServices ? settings.hideOfflineServices.value : false,
  hasCCNotifyOnResources: settings.hasCCNotifyOnResources.value === 'default'
    ? 'SELECTED'
    : settings.hasCCNotifyOnResources.value === 'selected'
      ? 'TRUE'
      : 'FALSE',
  hasCCNotifyOnCustomers: settings.hasCCNotifyOnCustomers.value === 'default'
    ? 'SELECTED'
    : settings.hasCCNotifyOnCustomers.value === 'selected'
      ? 'TRUE'
      : 'FALSE',
  newBookingActivated: settings.newBookingActivated.value,
  resourceSelectorRescheduleManual: settings.resourceSelectorRescheduleManual.value,
  rescheduleBookingActivated: settings.rescheduleBookingActivated.value,
  displayBookedResources: settings.displayBookedResources.value
})
