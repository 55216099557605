import React from 'react'
import PropTypes from 'prop-types'

export const Iframe = props => {
  const { src = '', width = 600, height = 400, title = 'iframe' } = props

  return <iframe src={src} width={width} height={height} title={title} data-testid='iframe' />
}

Iframe.propTypes = {
  src: PropTypes.string.isRequired
}

export default Iframe
