import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, Error } from '../../../Common'

import 'rc-slider/assets/index.css'
import './SingleSlider.css'

class SingleSlider extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onChange (value) {
    let { form, formName, name, onChange, defaultValue } = this.props
    form = form || { value: defaultValue }
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })

    if (onChange && typeof onChange === 'function') {
      onChange(value)
    }
  }

  render () {
    let {
      leftLabel,
      rightLabel,
      name,
      hintText,
      disabled,
      hideError,
      min,
      max,
      step,
      pushable,
      defaultValue,
      form,
      noFill
    } = this.props
    form = form || { value: defaultValue }
    const classNames = ['ta-single-slider']
    if (noFill) classNames.push('ta-single-slider__non-filled')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        <div className='ta-single-slider__content'>
          <div className='ta-single-slider__body'>
            <Slider
              min={min || form.min}
              max={max || form.max}
              step={step || 1}
              pushable={pushable || form.pushable}
              defaultValue={defaultValue || form.defaultValue}
              value={form.value || 0}
              disabled={disabled || form.disabled}
              onChange={this.onChange}
            />
          </div>
          {((leftLabel || rightLabel) &&
            <div className='ta-single-slider__label-container'>
              <div>{leftLabel}</div>
              <div>{rightLabel}</div>
            </div>
          )}
        </div>
        {(hintText &&
          <div className='ta-form-control__hint'>{hintText}</div>
        )}
        {(!hideError &&
          <Error name={name} />
        )}
      </div>
    )
  }
}

SingleSlider.propTypes = {
  name: PropTypes.string,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(SingleSlider), FormContext)
