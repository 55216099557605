import React from 'react'
import { locationSelected } from '../../../Assets/images'
import { Row, Col, FontAwesome5, t } from '../../../Common'
import './BookingForm.css'

const BookingFormLocationHeader = ({ selectedBranchAddress, branchName }) => {
  selectedBranchAddress = selectedBranchAddress || {}
  return (
    <Row className='ta-booking_form-location__header-content'>
      <Col size={10}>
        <img src={locationSelected} width='50' alt='map-placeholder' />
      </Col>
      <Col size={90}>
        <div className='ta-booking_form-location__title'>{selectedBranchAddress.name || branchName}</div>
        {(selectedBranchAddress.streetNumber || selectedBranchAddress.street) && (
          <div className='ta-booking_form-location__row'>
            <FontAwesome5 type='solid' icon='map-marker-alt' />
            <span className='ta-booking_form-location__time'>{(selectedBranchAddress.streetNumber ? (selectedBranchAddress.streetNumber + ' ') : '') + selectedBranchAddress.street}</span>
          </div>
        )}
        {selectedBranchAddress.distance && (
          <div className='ta-booking_form-location__row'>
            <FontAwesome5 type='solid' icon='map-marked' />
            <span className='ta-booking_form-location__time'>
              {t('booking.location.kmAway', [{ key: 'DISTANCE', value: (selectedBranchAddress.distance / 1000).toFixed(2) }])}
            </span>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default BookingFormLocationHeader
