import React from 'react'
import PropTypes from 'prop-types'
import { PopupBookingsDelete } from '../../../Beauties'

export const PopupBookingsDeleteWrapper = props => {
  const { extras } = props

  const approve = () => {
    const { closePopup } = props
    if (extras && extras.approve) extras.approve()
    closePopup && closePopup()
  }

  const reject = () => {
    const { closePopup } = props
    closePopup && closePopup()
  }
  return (
    <PopupBookingsDelete approve={approve} reject={reject} />
  )
}

PopupBookingsDeleteWrapper.propTypes = {
  id: PropTypes.string,
  closePopup: PropTypes.func.isRequired
}

export default PopupBookingsDeleteWrapper
