import React from 'react'
import { connect, selectors } from '../../../Store'
import { BookingFormLocationHeader } from '../../../Beauties'

const BookingLocationHeaderWrapper = ({ bookingBranch, bookingBranchOptions }) => {
  const branchName = (bookingBranchOptions.find(item => item.id === bookingBranch) || {}).name
  const selectedBranchAddress = (bookingBranchOptions.find(item => item.id === bookingBranch) || {}).address

  return (
    <BookingFormLocationHeader
      selectedBranchAddress={selectedBranchAddress}
      branchName={branchName}
    />
  )
}

const maps = state => ({
  bookingBranch: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'value' }),
  bookingBranchOptions: state.branches.list
})

export default connect(maps)(BookingLocationHeaderWrapper)
