import { marker as markerImg } from '../../../Assets/images'

let activeInfoWindow
const markersBuilderCallback = ({ position, title = '', desc = '' }, index, map) => {
  const contentString = '<div class="ta-infowindow__container"><div class="ta-infowindow__title">' + title + '</div><div class="ta-infowindow__desc">' + desc + '</div></div>'
  const infoWindow = new window.google.maps.InfoWindow({
    content: contentString
  })

  const marker = new window.google.maps.Marker({
    position,
    icon: {
      url: markerImg,
      scaledSize: new google.maps.Size(24, 32)
    },
    infoWindow
  })

  if (desc || title) {
    marker.addListener('click', () => {
      if (activeInfoWindow) { 
        activeInfoWindow.close()
      }
      infoWindow.open({
        anchor: marker,
        map,
        shouldFocus: true
      })
      activeInfoWindow = infoWindow
    })
  }

  return marker
}

export default markersBuilderCallback
