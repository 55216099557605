import React from 'react'
import {
  Link,
  IconListItem,
  Address,
  format,
  FontAwesome5,
  t,
  IconListItemTitle,
  IconListItemBody,
  UrlDetectable
} from '../../../Common'

const CustomerPreviewDetailsField = props => {
  let { item, timezones, hideGoogleMap, isLast, ignoreColon, className, onDownloadFile } = props
  timezones = timezones || []
  const filterByDefaultId = ['salutation', 'title']
  let formattedSelectLabel = ''
  if (item.type === 'SELECT') {
    const selectedOption = (item.selectOptions || []).find(option => option.value === item.value)
    formattedSelectLabel = selectedOption ? selectedOption.label : item.value
  }
  const classNames = []
  if (isLast) classNames.push('last')
  if (item.type === 'FILES') classNames.push('inline')
  if (className) classNames.push(className)

  return (
    <IconListItem className={classNames.join(' ')} key={item.id}>
      <IconListItemTitle width='128px'>{item.label || t(item.translationKey)}{!ignoreColon && ':'}</IconListItemTitle>
      <IconListItemBody offset='131px'>
        {(item.type === 'EMAIL' &&
          <Link to={`mailto:${item.value}`} target='_top' external>{item.value}</Link>
        )}
        {(item.type === 'ADDRESS' && item.value &&
          <>
            {(hideGoogleMap &&
              <>
                {item.value.formatted}
                {item.value.details && `, ${item.value.details}`}
              </>
          )}
            {(!hideGoogleMap &&
              <Address
                address={item.value.formatted}
                details={item.value.details}
                lat={item.value.latitude}
                lng={item.value.longitude}
              />
          )}
          </>
        )}
        {item.type === 'PHONE' && item.value && item.value.phone}
        {item.type === 'DATE' && format(item.value, 'long', { isUTC: true })}
        {item.type === 'TEXT' && item.value}
        {item.type === 'TEXTAREA' && (
          <UrlDetectable isNl2br>
            {item.value}
          </UrlDetectable>
        )}
        {item.type === 'SELECT' && !filterByDefaultId.includes(item.defaultId) && formattedSelectLabel}
        {item.type === 'SELECT' && item.defaultId === 'title' && t(`global.title.${item.value}`)}
        {item.type === 'SELECT' && item.defaultId === 'salutation' && t(`global.salutation.${item.value}`)}
        {item.type === 'CHECKBOX' && (item.value + '' === 'true' ? t('global.yes') : t('global.no'))}
        {item.type === 'TIMEZONE' && (timezones.find(timezone => timezone.code === item.value) || {}).label}
        {item.type === 'FILES' && (item.values || []).map((fileItemStringified, index) => {
          const fileItem = JSON.parse(fileItemStringified)
          return (
            <div className='ta-customer-preview-data-field__file-row' key={`${index}-${fileItem.url}`}>
              <div className='ta-customer-preview-data-field__file-name'>
                {fileItem.name}
              </div>
              <Link
                className='ta-customer-preview-data-field__file-button'
                onClick={() => onDownloadFile({ key: fileItem.key, fieldId: item.id, name: fileItem.name })}
              >
                <FontAwesome5 icon='file-download' type='solid' />
              </Link>
            </div>
          )
        })}
      </IconListItemBody>
    </IconListItem>
  )
}

export default CustomerPreviewDetailsField
