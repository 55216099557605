import React from 'react'
import {
  Row,
  Col,
  DurationInput,
  StripedTitle,
  t
} from '../../../Common'
import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'
import { hex2rgba } from '../../../Utils'

const BookingFormDurationBeforeAndAfter = props => {
  const {
    showDurationDaySelector,
    color
  } = props
  const bufferTimesStyles = { background: hex2rgba(color || DEFAULT_SERVICE_HEXCOLOR, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color || DEFAULT_SERVICE_HEXCOLOR, 1) }

  return (
    <div className='ta-single-booking-form__duration__before-and-after'>
      <div className='ta-buffer-times'>
        <div className='ta-buffer-times__box' style={bufferTimesStyles}>
          <div className='ta-buffer-times__box__content' style={bufferTimesContentContentStyles} />
        </div>
        <Row>
          <Col>
            <StripedTitle label={t('global.duration.preparationTime')} />
            <DurationInput name='durationBefore' noDays={!showDurationDaySelector} />
          </Col>
        </Row>
        <Row>
          <Col>
            <StripedTitle label={t('global.duration.followUpTime')} />
            <DurationInput name='durationAfter' noDays={!showDurationDaySelector} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BookingFormDurationBeforeAndAfter
