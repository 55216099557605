import { useCallback } from 'react'

import { URL_REGEX } from '../../Settings'
import { UrlDetectableReplacer } from '../../Common'
import { nl2br } from '../../Utils'

import './UrlDetectable.css'

const UrlDetectable = ({ children, position, isNl2br }) => {
  children = children || ''
  const regex = new RegExp(URL_REGEX, 'g')
  const urls = children.match(regex) || []

  const renderChildren = useCallback(children => isNl2br ? nl2br(children) : children, [isNl2br])

  const urlReplacer = (url, index) => {
    return (
      <UrlDetectableReplacer
        key={`${url}-${index}`}
        url={url}
        position={position}
        renderChildren={renderChildren}
      />
    )
  }

  return (
    <span className='ta-url-detectable'>
      {urls.length === 0 && renderChildren(children)}
      {urls.length > 0 && (
        children.split(regex).map((item, index) =>
          urls.includes(item)
            ? urlReplacer(item, index)
            : renderChildren(item)
        )
      )}
    </span>
  )
}

export default UrlDetectable
