import React from 'react'
import { feedContextInProps } from '../../Utils'
import { StepperContext, Link } from '../../Common'

// not used
const StepperButtons = props => {
  const {
    onClickNext,
    onClickPrev,
    activeStep,
    isLastStep
  } = props
  const className = ['ta-stepper__buttons']

  return (
    <div className={className.join(' ')}>
      {activeStep > 1 &&
        <Link onClick={onClickPrev}>Back</Link>
      }
      {!isLastStep &&
        <Link onClick={onClickNext}>Next</Link>
      }
    </div>
  )
}

export default feedContextInProps(StepperButtons, StepperContext)
