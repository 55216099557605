import React from 'react'

import './HintText.css'

const HintText = props => {
  const { children, className } = props
  const classNames = ['ta-hint-text']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} data-testid='hint-text'>
      {children}
    </div>
  )
}

export default HintText
