import { bookingFormTransform, bookingsEventsListTransform, bookingsIntervalsListTransform } from './utils'

const reducer = {}

reducer.initialState = {
  progress: 0,
  selectedCustomer: null,
  isCustomerEdit: false,
  isCustomerAdd: false,
  intervalsList: [],
  eventsList: [],
  intervals: [0, 0, 0],
  pendingForm: false,
  pendingBranches: false,
  pendingTransformation: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Form
  bookingFormGet: (type, booking) => dispatch(actions.BOOKING_FORM_GET, { type }),
  bookingFormPopulate: (booking, settings) => {
    handlers.formSet('booking', bookingFormTransform(booking, settings))
    handlers.bookingFormReady()
  },
  bookingsScheduleFormClear: (payload) => dispatch(actions.BOOKINGS_SCHEDULE_FORM_CLEAR, payload),
  bookingsListUpdate: ({ intervals, event }) => dispatch(actions.BOOKINGS_LIST_UPDATE, { intervals: bookingsIntervalsListTransform(intervals), event: bookingsEventsListTransform([event])[0] }),
  bookingFormContainerSet: ({ eventId, bookingId, resourceId, date, time, trigger }) => dispatch(actions.BOOKING_FORM_CONTAINER_SET, { eventId, bookingId, resourceId, date, time, trigger }),
  bookingsScheduleUpdate: (payload) => dispatch(actions.BOOKINGS_SCHEDULE_UPDATE, payload),
  bookingFormReady: () => dispatch(actions.BOOKING_FORM_READY),
  bookingFormSave: (booking, scrollToError = () => { }) => dispatch(actions.BOOKING_FORM_SAVE, { booking, scrollToError }),
  bookingFormCancel: (booking, scrollToError = () => { }) => dispatch(actions.BOOKING_FORM_CANCEL, { booking, scrollToError }),
  bookingFormAvailabilityGet: date => dispatch(actions.BOOKING_FORM_AVAILABTY_GET, date),
  bookingFormWidgetAvailabilityReady: () => dispatch(actions.BOOKING_FORM_WIDGET_AVAILABTY_READY),
  bookingFormCustomerUpdate: customer => dispatch(actions.BOOKING_FORM_CUSTOMER_UPDATE, customer),
  bookingFormCustomerSearch: () => dispatch(actions.BOOKING_FORM_CUSTOMER_SEARCH),
  bookingFormServicesSave: () => dispatch(actions.BOOKING_FORM_SERVICES_SAVE),
  bookingFormServicesReady: () => dispatch(actions.BOOKING_FORM_SERVICES_READY),
  bookingLocalTransformationsGet: ({ preserveBookingTime, fields, isRescheduleForm }) => dispatch(actions.BOOKING_LOCAL_TRANSFORMATIONS_GET, { preserveBookingTime, fields, isRescheduleForm }),
  bookingLocalTransformationsReady: () => dispatch(actions.BOOKING_LOCAL_TRANSFORMATIONS_READY)
})

reducer.BOOKING_FORM_GET = state => state

reducer.BOOKINGS_SCHEDULE_FORM_CLEAR = state => ({
  ...state
})

reducer.BOOKINGS_LIST_UPDATE = (state, { event, intervals }) => {
  delete event.bookingIntervals
  return {
    ...state,
    pendingForm: false,
    intervalsList: [...state.intervalsList.filter(item => item.eventId !== event.id), ...intervals],
    eventsList: [...state.eventsList.filter(item => item.id !== event.id), event]
  }
}

reducer.BOOKING_FORM_CONTAINER_SET = (state, { eventId, bookingId, resourceId, date, time, trigger }) => ({
  ...state,
  form: {
    eventId: eventId || null,
    bookingId: bookingId || null,
    resourceId: resourceId || null,
    date: date || null,
    time: time || null,
    trigger: trigger || null
  },
  options: {}
})

reducer.BOOKINGS_SCHEDULE_UPDATE = (state, payload) => ({
  ...state,
  ...payload
})

reducer.BOOKING_FORM_READY = state => ({
  ...state,
  pendingForm: false,
  pendingFormContainer: false
})

reducer.BOOKING_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.BOOKING_FORM_CANCEL = state => ({
  ...state,
  pendingForm: true
})

reducer.BOOKING_FORM_AVAILABTY_GET = state => ({
  ...state,
  pendingWidgetForm: true
})

reducer.BOOKING_FORM_WIDGET_AVAILABTY_READY = state => ({
  ...state,
  pendingWidgetForm: false
})

reducer.BOOKING_FORM_CUSTOMER_UPDATE = (state, customer) => ({
  ...state,
  eventsList: state && state.eventsList
    .map((event, index) => {
      const customerName = (event.customerId && customer && customer.id && event.customerId === customer.id)
        ? customer.fullName
        : event.customerName
      return {
        ...event,
        customerName,
        customer: {
          ...event.customer,
          fullName: customerName
        }
      }
    })
})

reducer.BOOKING_FORM_CUSTOMER_SEARCH = state => state

reducer.BOOKING_FORM_SERVICES_SAVE = state => ({
  ...state,
  pendingBranches: true
})

reducer.BOOKING_FORM_SERVICES_READY = state => ({
  ...state,
  pendingBranches: false
})

reducer.BOOKING_LOCAL_TRANSFORMATIONS_GET = state => ({
  ...state,
  pendingTransformation: true
})

reducer.BOOKING_LOCAL_TRANSFORMATIONS_READY = state => ({
  ...state,
  pendingTransformation: false
})

export default reducer
