// Add here initial state for forms if required
export default {
  login: {},
  accountSecurity: {},
  agents: {},
  agentsFilters: {},
  booking: {
    useExactMatchSearch: { value: 'exact' }
  },
  debugOptions: {},
  courses: {},
  customerFields: {},
  customers: {},
  services: {},
  tags: {},
  settings: {}
}
