import React from 'react'
import PropTypes from 'prop-types'

import './Button.css'

const TYPES = {
  block: 'ta-btn-block',
  secondary: 'ta-btn-secondary',
  primary: 'ta-btn-primary',
  ghost: 'ta-btn-ghost',
  tertiary: 'ta-btn-tertiary',
  dark: 'ta-btn-dark',
  greyGhost: 'ta-btn-grey-ghost',
  blackGhost: 'ta-btn-black-ghost'
}

const Button = ({ children, className, onClick, type, disabled, topOffset, ...rest }) => {
  const classNames = Object.keys(TYPES).reduce((acc, type) => rest[type] ? [...acc, TYPES[type]] : [...acc], ['ta-btn'])
  if (className) classNames.push(className)
  if (disabled) classNames.push('ta-btn-disabled')
  if (topOffset) classNames.push('top-offset')

  const handleOnClick = (e) => {
    if (!disabled && onClick) onClick(e)
  }

  return (
    <button onClick={handleOnClick} className={classNames.join(' ')} type={type}>{children}</button>
  )
}

export default Button

Button.propTypes = {
  className: PropTypes.string,
  block: PropTypes.bool,
  ghost: PropTypes.bool,
  primary: PropTypes.bool,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  type: PropTypes.string
}
