import React from 'react'
import PropTypes from 'prop-types'
import { BlueTemplate, Loader, t } from '../../Common'
import { LoginForm } from '../../Beauties'
import { MigrationOverlayWrapper } from '../../Beasts'

import './Login.css'

const Login = props => {
  const {
    pending,
    pendingLanguage,
    onSubmit,
    passwordFieldType,
    togglePasswordFieldType,
    onLanguageChange,
    onResendVerificationClick,
    emailWarningStatus,
    userTemporaryBlockedStatus,
    locale,
    skipClear
  } = props

  return (
    <div className='ta-login'>
      <BlueTemplate logoUrl='/'>
        {(!pendingLanguage &&
          <div className='ta-login__box'>
            <div className='ta-login__box-content'>
              <div className='ta-login__title'>{t('login.greeting')}</div>
              <Loader active={pendingLanguage || pending} />
              <LoginForm
                locale={locale}
                onSubmit={onSubmit}
                passwordFieldType={passwordFieldType}
                togglePasswordFieldType={togglePasswordFieldType}
                onLanguageChange={onLanguageChange}
                onResendVerificationClick={onResendVerificationClick}
                userTemporaryBlockedStatus={userTemporaryBlockedStatus}
                emailWarningStatus={emailWarningStatus}
                skipClear={skipClear}
              />
            </div>
            {/* {REGISTRATION_URL &&
              <div className='ta-login__box-footer'>
                <Link to={REGISTRATION_URL.replace(`/${DEFAULT_LOCALE}/`, `/${locale}/`)} target='_blank' external>
                  {t('login.newAccount')}
                </Link>
              </div>
            } */}
          </div>
        )}
        <MigrationOverlayWrapper />
      </BlueTemplate>
    </div>
  )
}

Login.propTypes = {
  pending: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  passwordFieldType: PropTypes.string.isRequired,
  togglePasswordFieldType: PropTypes.func.isRequired
}

export default Login
