import React, { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DnDContainer } from '../../../Common'

import './SortableList.css'

// not used
class SortableListItem extends Component {
  render () {
    const { children, index, id, empty } = this.props
    const classNames = ['ta-sortable-list__item']
    if (empty) classNames.push('empty')

    return (
      <>
        {empty &&
          <div className={classNames.join(' ')} data-testid='sortable-list-item-container'>
            {children}
          </div>
        }
        {!empty &&
          <Draggable
            draggableId={id}
            index={index}
          >
            {(provided) => (
              <DnDContainer
                className={classNames.join(' ')}
                provided={provided}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {children}
              </DnDContainer>
            )}
          </Draggable>
        }
      </>
    )
  }
}

export default SortableListItem
