import React, { Component } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer
} from 'recharts'
import { CHART_DEFAULTS } from '../../Settings'
import { ChartLegendVertical, ChartShapePieActive } from '../../Common'

class ChartPie extends Component {
  constructor (props, context) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.state = { activeIndex: 0 }
  }

  onMouseEnter (data, index) {
    this.setState({ activeIndex: index || 0 })
  }

  render () {
    const { activeIndex } = this.state
    const { options, data = [] } = this.props

    return (
      <ResponsiveContainer width='100%' minHeight={options.containerMinHeight || '180px'}>
        <PieChart
          syncId={options.syncId}
          width={options.width || CHART_DEFAULTS.width}
          height={options.height || CHART_DEFAULTS.height}
          margin={options.margin || CHART_DEFAULTS.margin.pie}
          data={data}
        >
          {options.legend &&
          <Legend
            align={options.legend.align}
            verticalAlign={options.legend.verticalAlign}
            content={<ChartLegendVertical
              currency={options.legend.currency}
              locale={options.locale}
              isBookingsStat={options.legend.isBookingsStat}
            />
            } />
          }
          <Pie
            data={data}
            isAnimationActive={false}
            type='monotone'
            dataKey={options.dataKey}
            nameKey={options.nameKey}
            fill={options.fill}
            startAngle={45}
            endAngle={405}
            outerRadius={options.outerRadius || 55}
            innerRadius={options.innerRadius || 0}
            cx={options.cx || 70}
            cy={options.cy || 50}
            activeIndex={activeIndex}
            activeShape={<ChartShapePieActive currency={options.currency} locale={options.locale} />}
            onMouseEnter={this.onMouseEnter}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    )
  }
}

export default ChartPie
