import { Alert, FormText, ListItem, t } from '../../../Common'
import { dangerousHTML, groupByKey } from '../../../Utils'

const BookingFormDependenciesAlert = ({ unfulfilledDependencies = [], resources = [], resourceCategories = [] }) => {
  const resourcesByCategoryId = groupByKey(resources, 'categoryId')
  const resourcesById = groupByKey(resources, 'id')
  const categoriesById = groupByKey(resourceCategories, 'id')

  return (
    <>
      {!!unfulfilledDependencies.length && (
        <Alert theme='alert' className='ta-booking__dependencies__alert'>
          <FormText>{t('booking.resourceSelector.alert.title')}</FormText>
          {unfulfilledDependencies.map(({ specificResourceCategoryIds, specificResourceIds, workInParallel }, idx) => {
            specificResourceIds ||= []
            specificResourceCategoryIds ||= []
            const areAllResourcesSelected = !specificResourceIds?.length
            const areAllCategoriesSelected = !specificResourceCategoryIds?.length && areAllResourcesSelected
            const resources = areAllResourcesSelected
              ? specificResourceCategoryIds.flatMap(categoryId => (resourcesByCategoryId[categoryId] || []))
              : specificResourceIds.flatMap(id => resourcesById[id])
            const resourceNames = resources.map(item => item?.name)
            const dependencyCategoryIds = [...new Set(resources.map(item => item?.categoryId))]
            const dependencyCategoryNames = dependencyCategoryIds
              .flatMap(categoryId => categoriesById[categoryId])
              .map(item => item?.name === 'default' ? t('global.resources') : item?.name)

            let translationKey = 'booking.resourceSelector.alert.case'
            translationKey += areAllResourcesSelected ? '.allResources' : '.specificResources'
            translationKey += specificResourceIds?.length === 1
              ? '.onlyOne'
              : workInParallel ? '.allNeeded' : '.oneNeeded'
            if (areAllResourcesSelected && areAllCategoriesSelected) translationKey = `booking.resourceSelector.alert.case.allResources.allCategories.${workInParallel ? 'allNeeded' : 'oneNeeded'}`

            const replaces = [
              {
                key: 'RESOURCES',
                value: (resourceNames?.length > 1 ? resourceNames.slice(0, -1) : resourceNames).join(', ')
              },
              {
                key: 'LAST_RESOURCE',
                value: resourceNames.slice(-1)
              },
              {
                key: 'CATEGORY',
                value: dependencyCategoryNames.join(', ')
              }
            ]

            return (
              <ListItem key={idx} className='bullet-item'>
                {dangerousHTML(t(translationKey, replaces))}
              </ListItem>
            )
          })}
        </Alert>
      )}
    </>
  )
}

export default BookingFormDependenciesAlert
