import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'
import { validator, translateServerCode } from '../../../Utils'

export const companyPopulateTransform = company => {
  let { settings } = company || {}
  settings = settings || {}
  const result = {
    ...company,
    settings: {
      ...settings,
      hasNotifyOnResources: [null, undefined, 'SELECTED'].includes(settings.hasNotifyOnResources)
        ? 'default'
        : settings.hasNotifyOnResources === 'TRUE'
          ? 'selected'
          : 'deselected',
      hasNotifyOnCustomers: [null, undefined, 'SELECTED'].includes(settings.hasNotifyOnCustomers)
        ? 'default'
        : settings.hasNotifyOnCustomers === 'TRUE'
          ? 'selected'
          : 'deselected'
    }
  }
  return result
}

export const companySettingsPopulateTransform = settings => {
  settings = settings || {}
  return {
    ...settings,
    hasCCNotifyOnResources: [null, undefined].includes(settings.hasCCNotifyOnResources)
      ? null
      : settings.hasCCNotifyOnResources === 'SELECTED'
        ? 'default'
        : settings.hasCCNotifyOnResources === 'TRUE'
          ? 'selected'
          : 'deselected',
    hasCCNotifyOnCustomers: [null, undefined].includes(settings.hasCCNotifyOnCustomers)
      ? null
      : settings.hasCCNotifyOnCustomers === 'SELECTED'
        ? 'default'
        : settings.hasCCNotifyOnCustomers === 'TRUE'
          ? 'selected'
          : 'deselected'
  }
}
export const companyFormTransform = (company) => {
  const address = company.address || {}
  const phone = company.phone || {}
  const companyLocale = company.locale || ''

  const result = {
    businessSectors: {
      values: company.businessSectors || [],
      options: company.businessSectorsOptions
    },
    name: {
      value: company.name || ''
    },
    isPerson: {
      value: !!company.isPerson
    },
    personName: {
      value: company.personName || ''
    },
    contactEmail: {
      value: company.contactEmail || ''
    },
    address: {
      value: address.formatted || '',
      data: ((address.formatted && address.formatted !== '') && {
        placeId: address.placeId || '',
        city: address.city || '',
        streetName: address.street || '',
        streetNumber: address.streetNumber || '',
        postalCode: address.zipCode || '',
        country: address.country || '',
        lat: address.latitude || '',
        lng: address.longitude || '',
        formattedAddress: address.formatted || '',
        details: address.details || ''
      }) || null
    },
    phone: {
      phone: phone.number || '',
      phoneCountry: phone.country
        ? phone.country
        : companyLocale.length > 2
          ? (companyLocale.split('-')[1] || '').toUpperCase()
          : DEFAULT_LOCALE_COUNTRY_CODE
    },
    plan: {
      value: company.plan || 'CLASSIC'
    },
    addressSecondary: {
      value: address.details || ''
    },
    timezone: {
      value: company.timezone || ''
    },
    info: {
      value: company.info || ''
    }
  }
  return result
}

export const companyFormValidate = company => {
  if (!company) return
  const isCompany = !company.isPerson.value
  const isAddressSet = (company.address && company.address.value !== '')
  const phoneLength = 16
  const inputLength = 64
  const textLength = 600
  const emailLength = 255
  const rules = [{
    'name.value': [`requiredIf:${isCompany}`, `max:${inputLength}`]
  }, {
    'personName.value': ['required', `max:${inputLength}`]
  }, {
    'contactEmail.value': ['required', `max:${emailLength}`, 'email']
  }, {
    'phone.phone': [`max:${phoneLength}`, 'phone']
  },
  {
    'address.value': [`max:${textLength}`]
  }, {
    'address.data.city': [`requiredIf:${isAddressSet}`]
  }, {
    'address.data.streetName': [`requiredIf:${isAddressSet}`]
  },
  // {
  //   'address.data.postalCode': [`requiredIf:${isAddressSet}`]
  // }
  // {
  //   'address.data.streetNumber': [`requiredIf:${isAddressSet}`]
  // }
  {
    'businessSectors.value': [`max:${inputLength}`]
  }]
  let addressErrorMessage = 'errors.address.required'
  if (isAddressSet && company.address && company.address.data) {
    if (!company.address.data.city || company.address.data.city === '') {
      addressErrorMessage = 'errors.address.fillCity'
    } else if (!company.address.data.streetName || company.address.data.streetName === '') {
      addressErrorMessage = 'errors.address.fillStreetName'
      // } else if (!company.address.data.streetNumber || company.address.data.streetNumber === '') {
      //   addressErrorMessage = 'errors.address.fillStreetNumber'
      // } else if (!company.address.data.postalCode || company.address.data.postalCode === '') {
      //   addressErrorMessage = 'errors.address.fillZipCode'
      // }
    }
  }
  const messages = {
    contactEmail: {
      email: 'errors.email.invalid',
      required: 'errors.email.required',
      max: 'errors.invalidMaxLength',
      min: 'errors.invalidMinLength'
    },
    address: {
      max: 'errors.invalidMinLength',
      requiredIf: `${addressErrorMessage}`,
      required: 'errors.address.required'
    },
    phone: {
      required: 'errors.phone.required',
      phone: 'errors.phone.invalid',
      max: 'errors.invalidMaxLength',
      min: 'errors.invalidMinLength'
    },
    name: {
      max: 'errors.invalidMaxLength',
      requiredIf: 'errors.required'
    },
    personName: {
      required: 'errors.required',
      max: 'errors.invalidMaxLength'
    }
  }
  const replaces = {
    contactEmail: {
      max: { key: 'MAX', value: inputLength }
    },
    personName: {
      max: { key: 'MAX', value: inputLength },
      required: { key: 'FIELD_NAME', value: 'global.ownerName.label', translateReplace: true }
    },
    name: {
      max: { key: 'MAX', value: inputLength },
      requiredIf: { key: 'FIELD_NAME', value: 'global.name.label', translateReplace: true }
    },
    address: {
      max: { key: 'MAX', value: textLength }
    },
    phone: {
      max: { key: 'MAX', value: phoneLength }
    }
  }
  const errors = validator(company, rules, messages, replaces)

  return errors.length && errors
}

export const companyFormSaveTransform = company => {
  const { data = null } = company.address
  const companyName = (!company.isPerson.value) ? company.name.value : company.personName.value
  const result = {
    name: companyName,
    personName: company.personName.value,
    isPerson: company.isPerson.value,
    contactEmail: company.contactEmail.value,
    address: (data && {
      placeId: data && data.placeId,
      latitude: data && data.lat,
      longitude: data && data.lng,
      street: data && data.streetName,
      streetNumber: data && data.streetNumber,
      city: data && data.city,
      country: data && data.country,
      zipCode: data && data.postalCode,
      formatted: data && data.formattedAddress,
      details: data && company.addressSecondary.value
    }) || null,
    phone: ((company.phone && company.phone.phone) && {
      number: company.phone.phone,
      country: company.phone.phoneCountry
    }) || null,
    businessSectors: company.businessSectors.values,
    info: company.info.value
  }

  if (company.timezone && company.timezone.value) {
    result.timezone = company.timezone.value
  }

  return result
}

export const companyFormServerErrorsTransform = (err) => {
  const errors = []
  if (err && err.code) {
    if (err.code === 'EmailRequired') {
      errors.push({ key: 'contactEmail', value: translateServerCode(err.code) })
    } else if (err) {
      errors.push({ key: 'globalErrors', value: translateServerCode(err.code) })
    }
  }
  return errors
}

export const companyDeleteFormValidate = form => {
  if (!form) return
  const errors = []

  if (form.password && form.password.value.length === 0) errors.push({ key: 'password', value: 'errors.password.required' })

  return errors.length && errors
}

export const companyDeleteFormSaveTransform = form => {
  const comment = form.comment && form.comment.value
  const hasNotifyMe = (form.hasNotifyMe && form.hasNotifyMe.value) || false
  const result = {
    password: form.password && form.password.value,
    reason: form.reason && form.reason.value
  }
  if (comment) result.comment = comment
  if (comment && hasNotifyMe) result.hasNotifyMe = hasNotifyMe
  return result
}

export const companyDeleteFormServerErrorsTransform = err => {
  const errors = []
  if (err && err.code) {
    if (err) errors.push({ key: 'password', value: translateServerCode(err.code) })
  }
  return errors
}

export const companyOnboardingFormSaveTransform = form => {
  const {
    bookingAvailability,
    bookingType,
    companies,
    companiesCount
  } = form || {}
  const { value: bookingAvailabilityValue } = bookingAvailability || {}
  const { value: bookingTypeValue } = bookingType || {}
  const { value: companiesValue } = companies || {}
  const { value: companiesCountValue } = companiesCount || {}
  return {
    bookingAvailability: bookingAvailabilityValue,
    bookingType: bookingTypeValue,
    locationType: companiesValue === 'SINGLE'
      ? '1'
      : companiesCountValue
  }
}
