import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { DnDContainer } from '../../Common'

const DnDDraggable = props => {
  let {
    children,
    className,
    id,
    index,
    isDragDisabled,
    renderDragHandle,
    style
  } = props
  style = style || {}

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => {
        let containerStyle = provided.draggableProps.style
        if (snapshot.isDragging) containerStyle = { ...containerStyle, ...style }

        return (
          <DnDContainer
            className={className}
            snapshot={snapshot}
            provided={provided}
            {...provided.draggableProps}
            {...(renderDragHandle ? {} : provided.dragHandleProps)}
            style={containerStyle}
          >
            {children}
            {renderDragHandle && renderDragHandle(provided.dragHandleProps)}
          </DnDContainer>
        )
      }}
    </Draggable>
  )
}

export default DnDDraggable
