import { store } from '../../Store'
import { getAgentsPermissions } from '../../Utils'

export const isRouteDisabledByPermissions = () => {
  const state = store.getState()
  let {
    account,
    router,
    agents
  } = state || {}
  agents = agents || {}
  router = router || {}
  let { data, name: route } = router
  let { list: agentsList } = agents || {}
  agentsList = agentsList || []
  data = data || {}
  const { id } = data
  let {
    rules,
    userId,
    isOwner,
    isAdmin,
    isCustom
  } = account
  let { usersWrite, usersRead } = rules
  usersWrite = !!isAdmin || !!usersWrite
  usersRead = !!isAdmin || !!usersRead
  const agent = (agentsList || []).find(a => a.id === id)
  const { showEditButton } = getAgentsPermissions({ isOwner, isCustom, ...rules }, agent, userId)
  const AGENTS_EDIT = ['agentsEdit']
  const AGENTS_ADD = ['agentsAdd']
  const AGENTS_PREVIEW = ['agentsPreview']
  const SETTINGS_EDIT = ['settingsEdit']

  return (
    (AGENTS_EDIT.includes(route) && !showEditButton) ||
    (AGENTS_ADD.includes(route) && !usersWrite) ||
    (AGENTS_PREVIEW.includes(route) && !usersRead) ||
    (SETTINGS_EDIT.includes(route) && !isOwner)
  )
}
