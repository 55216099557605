import { customerFormTransform, customerEventFormTransform, customerTransform } from './utils'
const reducer = {}

reducer.initialState = {
  list: [],
  pendingLoadMore: false,
  pendingList: true,
  pendingForm: true,
  pendingPreview: true,
  pendingUpcomingBookings: false,
  pendingPastBookings: false,
  dragging: {},
  page: 1,
  pastBookingsPage: 1,
  upcomingBookingsPage: 1,
  areFetched: false,
  messageList: null,
  messagePreview: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Subscription
  customersSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.CUSTOMERS_SUBSCRIPTION_SET, { name, id, data, ts }),

  // List
  customersListGet: (page, isLoadMore) => dispatch(actions.CUSTOMERS_LIST_GET, { page, isLoadMore }),
  customersListPopulate: (customers, isLoadMore, total) => dispatch(actions.CUSTOMERS_LIST_POPULATE, { customers, isLoadMore, total }),
  customersListOptionsChange: options => dispatch(actions.CUSTOMERS_LIST_OPTIONS_CHANGE, options),

  // One
  customerUpdate: (customer, skipTransform) => dispatch(actions.CUSTOMER_UPDATE, customerTransform(customer, skipTransform)),
  customerUpdated: (customer, availableLetters) => dispatch(actions.CUSTOMER_UPDATED, { customer, availableLetters }),
  customersPreviewBookingsMessageSet: message => dispatch(actions.CUSTOMERS_PREVIEW_BOOKINGS_MESSAGE_SET, message),

  // Preview bookings
  customerPreviewGet: id => dispatch(actions.CUSTOMER_PREVIEW_GET, id),
  customerBookingsGet: (id) => dispatch(actions.CUSTOMER_BOOKINGS_GET, id),
  customerUpcomingBookingsPopulate: ({ id, upcomingEvents, isLoadMore, upcomingBookingsPage, pendingUpcomingBookings, isUpcomingBookingsLastPage }) => dispatch(actions.CUSTOMER_BOOKINGS_UPCOMING_POPULATE, { id, upcomingEvents, upcomingBookingsPage, isLoadMore, pendingUpcomingBookings, isUpcomingBookingsLastPage }),
  customerPastBookingsPopulate: ({ id, pastEvents, isLoadMore, pastBookingsPage, pendingPastBookings, isPastBookingsLastPage }) => dispatch(actions.CUSTOMER_BOOKINGS_PAST_POPULATE, { id, pastEvents, isLoadMore, pastBookingsPage, pendingPastBookings, isPastBookingsLastPage }),
  customerPreviewPopulate: (customer) => dispatch(actions.CUSTOMER_PREVIEW_POPULATE, customer),
  customerBookingsPageIncrement: (type, noIncrement) => dispatch(actions.CUSTOMERS_BOOKINGS_PAGE_INCREMENT, { type, noIncrement }),

  // Form
  customerFormGet: ({ id }) => dispatch(actions.CUSTOMER_FORM_GET, { id }),
  customerFormPopulate: ({ customer, branches }) => {
    handlers.formSet('customers', customerFormTransform({ customer, branches }))
    handlers.customerFormReady()
  },
  customerFormReady: () => dispatch(actions.CUSTOMER_FORM_READY),
  customerFormSave: (customer, scrollToError = () => { }) => dispatch(actions.CUSTOMER_FORM_SAVE, { customer, scrollToError }),
  customerEventFormGet: () => dispatch(actions.CUSTOMER_EVENT_FORM_GET),
  customerEventFormPopulate: (options) => {
    handlers.formSet('customerEvents', customerEventFormTransform(options))
    handlers.customerEventFormReady()
  },
  customerEventFormReady: () => dispatch(actions.CUSTOMER_EVENT_FORM_READY),

  // Events
  customerEventResourcesGet: eventId => dispatch(actions.CUSTOMER_EVENT_RESOURCES_GET, eventId),
  customerEventResourcesPopulate: ({ eventId, resources, resourceCategories }) => dispatch(actions.CUSTOMER_EVENT_RESOURCES_POPULATE, { eventId, resources, resourceCategories })
})

// List
reducer.CUSTOMERS_LIST_GET = state => ({
  ...state,
  pendingList: true,
  page: 1,
  messagePreview: null,
  messageList: null
})

reducer.CUSTOMERS_LIST_OPTIONS_CHANGE = (state, options = {}) => ({
  ...state,
  ...options
})

reducer.CUSTOMERS_LIST_POPULATE = (state, { customers, isLoadMore = false, total }) => ({
  ...state,
  total,
  list: (isLoadMore && [...state.list, ...customers || []]) || customers || [],
  pendingList: false,
  pendingLoadMore: false,
  areFetched: true,
  page: state.page || 1
})

// One
reducer.CUSTOMER_UPDATE = (state, customer) => {
  if (!customer) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === customer.id)
  if (index < 0) return state
  list[index] = { ...customer, isUpdated: true }
  return {
    ...state,
    list,
    pendingPreview: true
  }
}

reducer.CUSTOMER_UPDATED = (state, { customer }) => {
  if (!customer) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === customer.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list,
    pendingPreview: false,
    selected: state.selected && state.selected.id === customer.id ? customer : state.selected
  }
}

// Preview bookings
reducer.CUSTOMER_BOOKINGS_GET = state => ({
  ...state,
  pendingUpcomingBookings: false,
  pendingPastBookings: false,
  messagePreviewBookings: null
})

reducer.CUSTOMER_BOOKINGS_UPCOMING_POPULATE = (state, {
  id,
  upcomingEvents = { list: [] },
  isLoadMore = false,
  pendingUpcomingBookings,
  upcomingBookingsPage,
  isUpcomingBookingsLastPage
}) => ({
  ...state,
  selected: id
    ? {
        ...state.selected,
        upcomingEvents: (isLoadMore && [...state.selected.upcomingEvents, ...upcomingEvents]) || upcomingEvents,
        id
      }
    : null,
  pendingUpcomingBookings,
  isUpcomingBookingsLastPage,
  upcomingBookingsPage: upcomingBookingsPage || 1
})

reducer.CUSTOMER_BOOKINGS_PAST_POPULATE = (state, {
  id,
  pastEvents = { list: [] },
  isLoadMore = false,
  pendingPastBookings,
  pastBookingsPage,
  isPastBookingsLastPage
}) => ({
  ...state,
  selected: id
    ? {
        ...state.selected,
        pastEvents: (isLoadMore && [...state.selected.pastEvents, ...pastEvents]) || pastEvents,
        id
      }
    : null,
  pendingPastBookings,
  isPastBookingsLastPage,
  pastBookingsPage: pastBookingsPage || 1
})

reducer.CUSTOMERS_PREVIEW_BOOKINGS_MESSAGE_SET = (state, message) => ({
  ...state,
  pendingUpcomingBookings: false,
  pendingPastBookings: false,
  messagePreviewBookings: message
})

// Preview
reducer.CUSTOMER_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true,
  messagePreview: null,
  messageList: null
})

// Form
reducer.CUSTOMER_FORM_GET = (state, { id }) => ({
  ...state,
  pendingForm: true,
  id: id || null
})

reducer.CUSTOMER_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.CUSTOMER_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_EVENT_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.CUSTOMER_EVENT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.CUSTOMER_PREVIEW_POPULATE = (state, customer) => {
  if (!customer) return { ...state, selected: null }
  return {
    ...state,
    selected: customer
  }
}

reducer.CUSTOMERS_BOOKINGS_PAGE_INCREMENT = (state, { type, noIncrement }) => {
  return {
    ...state,
    ...(type === 'upcoming' ? { pendingUpcomingBookings: true } : { pendingPastBookings: true }),
    ...(type === 'upcoming'
      ? { upcomingBookingsPage: noIncrement ? state.upcomingBookingsPage : state.upcomingBookingsPage + 1 }
      : { pastBookingsPage: noIncrement ? state.pastBookingsPage : state.pastBookingsPage + 1 }
    ),
    type
  }
}

// Events
reducer.CUSTOMER_EVENT_RESOURCES_GET = state => state

reducer.CUSTOMER_EVENT_RESOURCES_POPULATE = (state, { eventId, resources, resourceCategories }) => {
  const selected = { ...state.selected }
  selected.upcomingEvents.list = (selected.upcomingEvents.list || []).map(event =>
    event.id === eventId ? { ...event, resources, resourceCategories, areResourcesFetched: true } : event
  )
  selected.pastEvents.list = (selected.pastEvents.list || []).map(event =>
    event.id === eventId ? { ...event, resources, resourceCategories, areResourcesFetched: true } : event
  )

  return { ...state, selected }
}

// Subscription
reducer.CUSTOMERS_SUBSCRIPTION_SET = state => state

export default reducer
