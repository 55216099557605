import React from 'react'
import { connect } from '../../Store'

import { MigrationOverlay } from '../../Beauties'

const MigrationOverlayWrapper = props => {
  const { doRedirect } = props

  return (
    <MigrationOverlay active={!!doRedirect} />
  )
}

const maps = state => ({
  doRedirect: state.auth.doRedirect
})

export default connect(maps)(MigrationOverlayWrapper)
