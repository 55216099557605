import React, { Component } from 'react'
import PropTypes from 'prop-types'
import momenttz from 'moment-timezone'
import DatePicker from 'react-datepicker'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps, getWeekDays } from '../../../Utils'
import { FormContext, FontAwesome5, OutsideClick, format, t } from '../..'

import './CalendarWeekInput.css'

class CalendarWeekInput extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.togglePicker = this.togglePicker.bind(this)
    this.closePicker = this.closePicker.bind(this)
    this.getDayClassNames = this.getDayClassNames.bind(this)
    this.onClickNextWeek = this.onClickNextWeek.bind(this)
    this.onClickPrevWeek = this.onClickPrevWeek.bind(this)

    this.state = { isOpen: false }
  }

  onChange (momentDate) {
    let { timezone, formName, name, form, onChangeAddon } = this.props
    form = form || { value: '' }
    const value = (momentDate && momenttz.tz(momentDate, 'YYYY-MM-DD', timezone).format('YYYY-MM-DD')) || null
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    this.closePicker()
    onChangeAddon && onChangeAddon(momentDate, name)
  }

  onClickPrevWeek () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const value = form.value
      ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone).subtract(1, 'weeks').format('YYYY-MM-DD')
      : momenttz.tz(timezone).subtract(1, 'weeks').format('YYYY-MM-DD')
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  onClickNextWeek () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const value = form.value
      ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone).add(1, 'weeks').format('YYYY-MM-DD')
      : momenttz.tz(timezone).add(1, 'weeks').format('YYYY-MM-DD')
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
  }

  closePicker () {
    this.setState({ isOpen: false })
  }

  togglePicker () {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getDayClassNames (date) {
    let { timezone, form } = this.props
    form = form || { value: '' }
    const { value } = form
    const classNames = []
    const dateDate = new Date(date)
    const momentDate = momenttz(dateDate, timezone)
    const currentYear = momenttz.tz(timezone).year()
    const dateYear = momentDate.year()
    const valueMoment = momenttz(value, 'YYYY-MM-DD', timezone)
    if (momenttz.tz(timezone).startOf('week').format('YYYY-MM-DD') === momentDate.startOf('week').format('YYYY-MM-DD') && (currentYear === dateYear)) classNames.push('react-datepicker__day-current')
    if (valueMoment.startOf('week').format('YYYY-MM-DD') === momentDate.startOf('week').format('YYYY-MM-DD')) classNames.push('react-datepicker__day-selected')
    return classNames.join(' ')
  }

  render () {
    const { isOpen } = this.state
    let {
      className,
      name,
      dateFormat,
      startWeekDateFormat,
      endWeekDateFormat,
      timezone,
      minDate,
      maxDate,
      locale,
      form
    } = this.props
    form = form || { value: '' }
    const classNames = ['ta-calendar-week-input', 'no-select']
    if (isOpen) classNames.push('open')
    const valueMoment = form.value ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone) : momenttz.tz(timezone)
    const valueDate = valueMoment.toDate()
    const weekDays = getWeekDays({ date: form.value, timezone, days: [0, 1, 2, 3, 4, 5, 6] })
    const startWeekDate = format(weekDays[0].date, startWeekDateFormat || 'short', { timezone })
    const endWeekDate = format(weekDays[weekDays.length - 1].date, endWeekDateFormat || 'short', { timezone })
    const prevBtnClassNames = ['ta-calendar-week-input__btn']
    if (minDate && weekDays.map(item => item.date).includes(format(minDate, 'YYYY-MM-DD', { isUTC: true, timezone }))) prevBtnClassNames.push('disable')
    const nextBtnClassNames = ['ta-calendar-week-input__btn']

    return (
      <div className={className}>
        <div className={classNames.join(' ')}>
          <div onClick={this.onClickPrevWeek} className={prevBtnClassNames.join(' ')}>
            <FontAwesome5 icon='angle-left' type='solid' />
          </div>
          <div onClick={this.togglePicker} className='ta-calendar-week-input__custom-input'>
            <FontAwesome5 icon='calendar-alt' type='solid' />
            <div className='ta-calendar-week-input__week-label'>
              {t('global.cw')}{momenttz.tz(valueDate, timezone).week()}
            </div>, {startWeekDate} - {endWeekDate}
          </div>
          {isOpen &&
            <OutsideClick onClickOutside={this.togglePicker}>
              <div className='ta-calendar-week-input-wrapper'>
                <DatePicker
                  className='ta-calendar-week-input-datepicker'
                  inline
                  name={name}
                  selected={valueDate}
                  onChange={this.onChange}
                  dateFormat={dateFormat}
                  dayClassName={date => this.getDayClassNames(date)}
                  minDate={minDate}
                  maxDate={maxDate}
                  autoComplete='off'
                  locale={locale}
                />
              </div>
            </OutsideClick>
          }
          <div onClick={this.onClickNextWeek} className={nextBtnClassNames.join(' ')}>
            <FontAwesome5 icon='angle-right' type='solid' />
          </div>
        </div>
      </div>
    )
  }
}

CalendarWeekInput.propTypes = {
  name: PropTypes.string,
  format: PropTypes.string,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  timezone: state.branches.selected.timezone,
  locale: selectors.appFieldSelector(state, { field: 'locale' })
})

export default feedContextInProps(connect(maps)(CalendarWeekInput), FormContext)
