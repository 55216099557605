import React, { Component, createRef, forwardRef, useContext, useImperativeHandle } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  Select,
  t,
  HoverPopupContext,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'

import './BookingDependenciesInput.css'

const HoverPopupContextConsumer = forwardRef((_, ref) => {
  const { setIsHover } = useContext(HoverPopupContext)

  useImperativeHandle(
    ref, () => ({
      setIsHover
    }), [setIsHover]
  )

  return <></>
})

class BookingDependenciesInput extends Component {
  constructor (props, context) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.state = { focused: false }
    this.hoverPopupConsumer = createRef()
    this.select = createRef()
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)

    // Select input event listeners
    const inputRef = this.select.current.getInputWrapperRef()
    const setIsHover = this.hoverPopupConsumer.current.setIsHover
    inputRef.current.addEventListener('mouseenter', () => setIsHover(true))
    inputRef.current.addEventListener('mouseleave', () => setIsHover(false))
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)

    // Clear select input event listeners
    const inputRef = this.select.current.getInputWrapperRef()
    inputRef.current.removeEventListener('mouseover', () => {})
    inputRef.current.removeEventListener('mouseout', () => {})
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange (values = []) {
    const newValues = values.map(value => value.value)
    let { name, form, formName } = this.props
    form = form || { values: [], options: [] }
    handlers.formFieldsUpdate(formName, {
      [name]: { ...form, values: newValues }
    })
  }

  onDelete (value) {
    let { form, hash, plan } = this.props
    form = form || { values: [], options: [] }
    if (plan === 'CLASSIC' && hash === 'groupBooking') return
    const newValues = form.values.filter(v => v !== value)
    handlers.formFieldsUpdate(this.props.formName, { [this.props.name]: { ...this.props.form, values: newValues } })
  }

  render () {
    const { focused } = this.state
    let {
      className,
      resources,
      categories,
      hideError,
      name,
      form,
      plan,
      hash,
      account,
      disabled,
      isSelectionDisabled
    } = this.props
    form = form || { values: [], options: [] }
    const hasError = form.errors && form.errors.length > 0
    const classNames = ['ta-booking-dependencies-input']
    const selectClassNames = ['ta-multiple-select']
    if (className) classNames.push(className)
    let { permissions } = account || {}
    permissions = permissions || {}
    let { calendarReadResourceCategoryIds, calendarReadResourceIds } = permissions
    calendarReadResourceIds = calendarReadResourceIds || []
    calendarReadResourceCategoryIds = calendarReadResourceCategoryIds || []
    let localCategories = categories || form.categories || []
    if (calendarReadResourceCategoryIds.length > 0) localCategories = localCategories.filter(item => calendarReadResourceCategoryIds.includes(item.id))
    let localResources = resources || form.resources || []
    if (calendarReadResourceIds.length > 0) localResources = localResources.filter(item => calendarReadResourceIds.includes(item.id))
    if (disabled) {
      classNames.push('disabled')
      selectClassNames.push('disabled')
    }
    const options = localCategories
      .map(category => ({
        name: category.name === 'default' ? t('global.resources') : category.name,
        extraText: category.isDependency ? t('resources.categories.dependency') : null,
        items: localResources
          .filter(item => item.categoryId === category.id)
          .map(item => ({
            value: item.id,
            label: item.name,
            avatarName: item.name,
            avatarColor: item.color,
            avatarImage: item.avatarUrl,
            abbreviation: item.abbreviation
          }))
      }))
      .filter(category => category.items.length > 0)

    return (
      <HoverPopup disabled={!isSelectionDisabled} block>
        <HoverPopupContent>
          {t('booking.resourceSelector.selectionDisabled.message')}
        </HoverPopupContent>
        <HoverPopupTrigger>
          <HoverPopupContextConsumer ref={this.hoverPopupConsumer} />
        </HoverPopupTrigger>
        <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
          <FormGroup
            focused={focused}
            labelText={t('global.all')}
            labelMandatory
            hasError={hasError}
          >
            <Select
              ref={this.select}
              className={selectClassNames.join(' ')}
              noResultsText={t('global.noResults')}
              value={form.values}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              onDelete={this.onDelete}
              multi
              searchable
              options={options}
              autoComplete='off'
              aria-autocomplete='none'
              isSelectionDisabled={isSelectionDisabled}
              disabled={disabled || (plan === 'CLASSIC' && hash === 'groupBooking')}
              hasCategories
              hasError={hasError}
            />
          </FormGroup>
          {(!hideError &&
            <Error name={name} />
        )}
        </div>
      </HoverPopup>
    )
  }
}

const maps = (state, props) => ({
  account: state.account,
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(BookingDependenciesInput), FormContext)
