import React from 'react'

import './BorderedBox.css'

const BorderedBox = props => {
  let { children, topOffset, className, style, noMargin } = props
  style = style || {}
  const classNames = ['ta-bordered-box']
  if (className) classNames.push(className)
  if (topOffset) classNames.push('top-offset')
  if (noMargin) classNames.push('no-margin')

  return (
    <div className={classNames.join(' ')} style={style} data-testid='bordered-box'>
      {children}
    </div>
  )
}

export default BorderedBox
