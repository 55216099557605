import React from 'react'
import { Link, Avatar, FontAwesome5 } from '../../Common'
import NavigationLinks from './NavigationLinks'

const NavigationAccount = props => {
  const { name, avatarUrl, isActive, color, abbreviation, onClick } = props
  const classNames = ['ta-navigation__account']
  if (isActive) classNames.push('active')

  return (
    <div className='ta-navigation__links__wrapper'>
      <Link onClick={() => onClick('/user')} className={classNames.join(' ')} data-testid='linkToUser'>
        <Avatar name={name} image={avatarUrl} color={color} abbreviation={abbreviation} />
        <div className='ta-navigation__account__avatar-icon'>
          <FontAwesome5 icon='pencil' type='solid' />
        </div>
        <div className='ta-navigation__account__name'>{name}</div>
      </Link>
      <NavigationLinks hideSupportLink active={isActive} onClick={onClick} />
    </div>
  )
}

export default NavigationAccount
