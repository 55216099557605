import React from 'react'

const FormButtons = props => {
  const { children, className } = props
  const classNames = ['ta-form-buttons']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  )
}

export default FormButtons
