import React, { useContext } from 'react'
import { connect, selectors } from '../../../Store'
import { BookingFormSlotHeader } from '../../../Beauties'
import { AccordionContext } from '../../../Common'

const BookingSlotHeaderWrapper = props => {
  const { expand } = useContext(AccordionContext)
  let { slots } = props
  slots = slots || {}

  return (
    <BookingFormSlotHeader slots={slots} hide={expand} />
  )
}

const maps = state => ({
  slots: selectors.formFieldSelector(state, { formName: 'booking', name: 'slots' })
})

export default connect(maps)(BookingSlotHeaderWrapper)
