import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Form,
  SectionTitle,
  Checkbox,
  ListItem,
  BorderedBox,
  FontAwesome5,
  Error,
  t
} from '../../Common'
import { TwoFAFormWrapper, PopupTriggerWrapper } from '../../Beasts'
import { handlers } from '../../Store'
import { dangerousHTML } from '../../Utils'
import './Account.css'

const AccountSecurity = ({
  enabled2FA,
  isAlreadyEnabled2FA,
  recoveryCodes,
  isEnterpriseRequire2FA
}) => {
  return (
    <>
      <Form className='ta-user-account-security-form' name='accountSecurity'>
        <SectionTitle icon='lock' label={t('user.preview.security.2fa.title')} ignoreTopOffset />
        <ListItem>{t('user.preview.security.description')}</ListItem>
        <Row>
          <Col>
            <Checkbox
              name='enabled2FA'
              label={t('global.enable')}
              theme='switch'
              disabled={isAlreadyEnabled2FA || !!recoveryCodes}
            />
          </Col>
        </Row>
      </Form>
      {enabled2FA && !isAlreadyEnabled2FA && !recoveryCodes && (
        <BorderedBox>
          <TwoFAFormWrapper onCancel={handlers.twoFACancel} />
        </BorderedBox>
      )}
      {recoveryCodes && recoveryCodes.length && (
        <div className='ta-2fa-recovery-codes'>
          <div className='ta-2fa-recovery-codes__title'>
            <FontAwesome5 className='ta-2fa-recovery-codes__success-icon' icon='check-square' type='solid' />{t('global.success')}
          </div>
          <div className='ta-2fa-recovery-codes__description'>{dangerousHTML(t('twoFA.recoveryCodes.description'))}</div>
          <div className='ta-2fa-recovery-codes__list'>
            {recoveryCodes.map(code =>
              <div className='ta-2fa-recovery-codes__list__item' key={code}>
                <div className='ta-2fa-recovery-codes__list__item__circle' />{code}
              </div>
            )}
          </div>
        </div>
      )}
      {(isAlreadyEnabled2FA && !recoveryCodes &&
        <Row>
          <Col>
            <BorderedBox noMargin>
              <div className='ta-user-account-security-2fa-enabled__title'>{t('user.preview.security.twoFAEnabled.title')}</div>
              <div className='ta-user-account-security-2fa-enabled__description'>{t('user.preview.security.twoFAEnabled.disable.description')}</div>
              {(isEnterpriseRequire2FA &&
                <div className='ta-user-account-security-2fa-enabled__description ta-user-account-security-2fa-enabled__bold'>
                  {t('user.preview.security.twoFALogout')}
                </div>
          )}
              <PopupTriggerWrapper name='two-fa-disable' position='top' extraClassName='ta-help-box'>
                <button className='ta-user-account-security-2fa-enabled__button'>{t('global.disable')}</button>
              </PopupTriggerWrapper>
              <div className='ta-user-account-security-2fa-enabled__description'>{t('user.preview.security.twoFAEnabled.recovery.description')}</div>
              <PopupTriggerWrapper name='two-fa-refresh-codes' position='top' extraClassName='ta-help-box'>
                <button className='ta-user-account-security-2fa-enabled__button'>
                  {t('user.preview.security.twoFAEnabled.regenerateRecovery')}
                </button>
              </PopupTriggerWrapper>
            </BorderedBox>
          </Col>
        </Row>
      )}
      <Error name='globalErrors' noTopOffset formName='accountSecurity' />
    </>
  )
}

AccountSecurity.propTypes = {
  enabled2FA: PropTypes.bool,
  isAlreadyEnabled2FA: PropTypes.bool,
  refreshRecoveryCodes: PropTypes.func,
  disable2FA: PropTypes.func,
  recoveryCodes: PropTypes.array
}

export default AccountSecurity
