import React from 'react'

import './ContentMessage.css'
import { FontAwesome5 } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const ContentMessage = props => {
  const classNames = ['ta-content-message']
  const {
    active,
    title,
    icon,
    iconType,
    text,
    buttons,
    closeButton = false,
    closeAction,
    type
  } = props

  if (active) classNames.push('active')
  if (type === 'warning') classNames.push('warning')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-content-message-info-container'>
        {(title || icon) && (
          <div className='ta-content-message-title'>
            {icon && <FontAwesome5 className='ta-content-message-icon' icon={icon} type={iconType} />}
            {dangerousHTML(title)}
          </div>
        )}
        {(text &&
          <div className='ta-content-message-text'>{dangerousHTML(text)}</div>
          )}
      </div>
      {(buttons &&
        <div className='ta-content-message-buttons'>
          {buttons}
        </div>
        )}
      {closeButton && (
        <div onClick={closeAction} className='ta-content-message-close'>
          <FontAwesome5 icon='times' />
        </div>
      )}
    </div>
  )
}

export default ContentMessage
