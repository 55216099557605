import React from 'react'
import { Col, Divider, Row, SingleSelect, t, AddressInput, Button, Spinner, LocationSelect, Alert } from '../../../Common'
import { CALL_CENTER_ADDRESS_RADIUS } from '../../../Settings'
import { dangerousHTML } from '../../../Utils'
import './BookingForm.css'

const BookingFormLocation = ({
  addresses,
  branchesOptions,
  form,
  progress,
  branches,
  setSelectedAddress,
  setSelectedBranch,
  parsedAddress,
  onAddressSelect,
  branchesValue,
  pendingBranches,
  pendingTransformation,
  isRescheduleForm
}) => {
  const isValidLocationValue = !!form.bookingBranch.options?.find(({ value }) => value === form.bookingBranch.value)
  const selectedBranchName = branches?.find((branch) => branch.id === form.bookingBranch.value)?.name || ''

  return (
    <>
      {(pendingBranches || pendingTransformation) && (
        <div className='ta-booking_form-spinner-container'>
          <Spinner />
        </div>
      )}
      {(!pendingBranches && !pendingTransformation && !isValidLocationValue && !!form.bookingBranch.value && !!selectedBranchName && !isRescheduleForm &&
        <Alert theme='alert'>{dangerousHTML(t('booking.serviceNotAvailable', [{ key: 'LOCATION', value: selectedBranchName }]))}</Alert>
      )}
      {(!pendingBranches && !pendingTransformation &&
        <>
          <LocationSelect
            name='bookingBranch'
            label={t('address.label')}
            clearable
            searchable
            options={isRescheduleForm && branches}
            disabled={isRescheduleForm && form.progress.value !== 2}
          />
          {!branchesValue && addresses && addresses.length > 0 && (
            <>
              <Divider>{t('global.or')}</Divider>

              <AddressInput
                name='customerAddress'
                label={t('address.label')}
                defaultZoom={1.5}
                defaultBorderRadius={50}
                validation={{ maxSize: 7 }}
                clearable
                onSelect={onAddressSelect}
                radiusInput={
                  <SingleSelect
                    name='customerAddressRadius'
                    label={t('booking.customer.radius')}
                    options={CALL_CENTER_ADDRESS_RADIUS}
                  />
                }
              />
            </>
          )}
          {(!branchesValue &&
            form.customerAddress &&
            form.customerAddress.value &&
            parsedAddress &&
            parsedAddress
              .filter(item =>
                item &&
                item.distance &&
                item.distance <= Number(form.customerAddressRadius ? form.customerAddressRadius.value : 5000) &&
                !!branchesOptions.find(branch => branch.value === item.id)
              )
              .sort((a, b) => a.distance - b.distance)
              .map(item => {
                const locationString = item.formatted
                const currentBranch = branchesOptions.find(branch => branch.value === item.id) || {}
                return (
                  <div key={locationString} className='ta-booking_form-location__locations_container'>
                    <Row>
                      <Col size={70}>
                        <Row>
                          <Col size={95}><div className='ta-booking_form-location__main_title'>{currentBranch.label}</div></Col>
                        </Row>
                        <Row>
                          <Col size={70}><span className='ta-booking_form-location__secondary_title'>{(item.streetNumber ? (item.streetNumber + ' ') : '') + item.street}</span></Col>
                          <Col size={30}><span className='ta-booking_form-location__subtitle'>{t('booking.location.kmAway', [{ key: 'DISTANCE', value: (item.distance / 1000).toFixed(2) }])}</span></Col>
                        </Row>
                      </Col>
                      <Col className='ta-booking_form-location__select' size={30} onClick={() => { setSelectedAddress(item) }}>{t('booking.select.label')}</Col>
                    </Row>
                  </div>
                )
              })
          )}
          {form.bookingBranch && isValidLocationValue && form.bookingBranch.value && (!isRescheduleForm || (isRescheduleForm && form.progress.value === 2)) && (
            <div className='ta-booking_form-location__buttons'>
              <Button
                type='button'
                onClick={setSelectedBranch}
                primary
                block
                topOffset
              >
                {t('global.continue')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default BookingFormLocation
