import momenttz from 'moment-timezone'

export default (day, time, footfalls, options) => {
  const { companyTimezone } = options || {}
  let weekday = momenttz(day).isoWeekday()
  weekday = weekday === 7 ? 0 : weekday

  const footfall = footfalls[weekday]
  if (!footfall || !footfall.intervals || !footfall.intervals.length || !footfall.isActive) {
    return null
  }

  const { intervals } = footfall

  const interval = intervals
    .find(interval => {
      const intervalBegin = momenttz.tz(interval.begin, 'HH:mm', companyTimezone).tz(companyTimezone)
      const intervalEnd = momenttz.tz(interval.end, 'HH:mm', companyTimezone).tz(companyTimezone)

      return (
        (intervalBegin.isBefore(momenttz.tz(time, 'HH:mm', companyTimezone)) ||
        intervalBegin.format('HH:mm') === time) &&
        intervalEnd.isAfter(momenttz.tz(time, 'HH:mm', companyTimezone))
      )
    })

  return interval ? interval.footfall : null
}
