import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Loader, t } from '../../Common'
import { AccountEmailChangeForm } from '../../Beauties'

const AccountEmailChangeWrapper = props => {
  let {
    form,
    pending,
    errors,
    oldPasswordFieldType
  } = props
  pending = !!pending
  form = form || {}
  errors = errors || []
  oldPasswordFieldType = oldPasswordFieldType || 'password'

  useEffect(() => {
    handlers.accountFormGet()
  }, [])

  const onSubmit = (cb) => {
    handlers.accountFormEmailChangeSave(form, (errors) => {
      cb && cb(errors)
    })
  }

  const toggleOldPasswordFieldType = () => {
    const oldPassword = {
      value: form.oldPassword.value,
      type: form.oldPassword.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('account', { oldPassword })

    return oldPassword.type
  }

  return (
    <>
      <Loader active={pending} />
      <AccountEmailChangeForm
        title={t('user.edit.email.heading')}
        cancelLink='/user'
        errors={errors}
        onSubmit={onSubmit}
        pending={pending}
        oldPasswordFieldType={oldPasswordFieldType}
        toggleOldPasswordFieldType={toggleOldPasswordFieldType}
      />
    </>
  )
}

AccountEmailChangeWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
}

const maps = state => ({
  errors: selectors.accountFieldSelector(state, { field: 'errors' }),
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  pending: selectors.accountFieldSelector(state, { field: 'pendingForm' }),
  form: selectors.formSelector(state, { formName: 'account' }),
  oldPasswordFieldType: selectors.formFieldPropertySelector(state, { formName: 'account', name: 'oldPassword', property: 'type' })
})

export default connect(maps)(AccountEmailChangeWrapper)
