import React from 'react'
import { BookingFormDurationWrapper, BookingFormServiceCombinationWrapper } from '../../../Beasts'

const SingleBookingFormFullscreen = props => {
  const { selectedService } = props
  const { isCombination } = selectedService || {}

  return (
    <div className='ta-single-booking-form'>
      <div className='ta-booking-form__col'>
        {(isCombination &&
          <BookingFormServiceCombinationWrapper selectedService={selectedService} />
        )}
        {(!isCombination &&
          <BookingFormDurationWrapper />
       )}
      </div>
    </div>
  )
}

export default SingleBookingFormFullscreen
