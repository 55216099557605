import React from 'react'
import PropTypes from 'prop-types'
import {
  Title,
  Link,
  FixedContent,
  FixedContentBody,
  FixedContentHeader,
  Form,
  FormButtons,
  FormSection,
  SectionTitle,
  Row,
  Col,
  Input,
  FontAwesome5,
  Online,
  t
} from '../../Common'

const AccountPasswordChangeForm = props => {
  const {
    title,
    message,
    onSubmit,
    cancelLink,
    oldPasswordFieldType,
    newPasswordFieldType,
    toggleOldPasswordFieldType,
    toggleNewPasswordFieldType
  } = props

  return (
    <div className='ta-account-form__password-change'>
      <FixedContent>
        <FixedContentHeader>
          <div className='ta-account__title'>
            <Title label={title} />
          </div>
        </FixedContentHeader>
        <FixedContentBody>
          <>{message && message.text}</>
          <Form onSubmit={onSubmit} name='account'>

            <FormSection>
              <SectionTitle label={t('global.password')} icon='lock' />
              <Row>
                <Col className='relative'>
                  <Input
                    type={oldPasswordFieldType}
                    name='oldPassword'
                    label={t('user.edit.password.sectionPassword.fieldOld.label')}
                    placeholder={t('user.edit.password.sectionPassword.fieldOld.hint')}
                    mandatory
                    dataTestid='oldPasswordInput'
                  />
                  <div className='ta-account__password-type-toggle-btn' onClick={toggleOldPasswordFieldType}>
                    <FontAwesome5 icon={oldPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className='relative'>
                  <Input
                    type={newPasswordFieldType}
                    name='newPassword'
                    label={t('user.edit.password.sectionPassword.fieldNew.label')}
                    placeholder={t('user.edit.password.sectionPassword.fieldNew.hint')}
                    mandatory
                    dataTestid='newPasswordInput'
                  />
                  <div className='ta-account__password-type-toggle-btn' onClick={toggleNewPasswordFieldType}>
                    <FontAwesome5 icon={newPasswordFieldType === 'password' ? 'eye' : 'eye-slash'} />
                  </div>
                </Col>
              </Row>
            </FormSection>

            <FormButtons>
              <Link to={cancelLink} className='ta-btn ta-btn-secondary'>{t('buttons.cancel.label')}</Link>
              <Online>
                <button type='submit' className='ta-btn ta-btn-primary' data-testid='savePasswordButton'>{t('buttons.save.label')}</button>
              </Online>
            </FormButtons>
          </Form>
        </FixedContentBody>
      </FixedContent>
    </div>
  )
}

AccountPasswordChangeForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  cancelLink: PropTypes.string
}

export default AccountPasswordChangeForm
