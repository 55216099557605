import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'
import {
  FontAwesome5,
  Link,
  Online,
  t
} from '../../Common'

import './CopyToClipboard.css'

class CopyToClipboard extends Component {
  constructor (props, context) {
    super(props)

    this.onCopy = this.onCopy.bind(this)
    this.state = { copied: false }
  }

  onCopy (text) {
    clearTimeout(this.timeout)
    const { onClickAddon } = this.props
    if (!navigator || !navigator.clipboard) {
      window.alert(t('errors.navigator.copy'))
      return
    }
    this.setState({ copied: true })
    navigator.clipboard.writeText(text)
    this.timeout = setTimeout(() => {
      this.setState({ copied: false })
      onClickAddon && onClickAddon()
    }, 1000)
  }

  render () {
    const { copied } = this.state
    const { children, text, textButton, className, extraButton, iconType } = this.props
    const classNames = ['ta-copy-to-clipboard']
    if (className) classNames.push(className)
    if (copied) classNames.push('copied')
    const buttonClassNames = ['ta-copy-to-clipboard__button', 'ta-btn', 'ta-btn-primary']
    if (textButton) buttonClassNames.push('text-button')
    if (extraButton) {
      classNames.push('with-external')
      buttonClassNames.push('with-external')
    }

    return (
      <div className={classNames.join(' ')} data-testid='copy-to-clipboard'>
        <Copy text={text} onCopy={this.onCopy}>
          <div className={buttonClassNames.join(' ')} title='Copy' data-testid='copy-to-clipboard-button'>
            {textButton && textButton}
            {!textButton &&
              <div className='ta-copy-to-clipboard__button-text' data-testid='copy-to-clipboard-button-icon'>
                <FontAwesome5 icon='copy' type={iconType} />
              </div>
            }
          </div>
        </Copy>
        {extraButton &&
          <Online className='ta-copy-to-clipboard__extra-button__offline'>
            <Link
              external
              className='ta-copy-to-clipboard__extra-button ta-btn ta-btn-primary'
              to={text || ''}
              target='_blank'
            >
              <FontAwesome5 icon='external-link-alt' />
            </Link>
          </Online>
        }
        {children}
      </div>
    )
  }
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired
}

export default CopyToClipboard
