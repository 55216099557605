import { Fragment } from 'react'
import { BookingFormDependenciesAlert } from '../../../Beauties'
import {
  Alert,
  BookingDependenciesInput,
  Col,
  FormText,
  Row,
  Title,
  t
} from '../../../Common'

const BookingFormDependencies = ({
  resources,
  isCombination,
  isScheduleForm,
  resourceCategories,
  combinationServices,
  isServiceShapeBroken,
  areWorkingInParallel,
  isSubServiceShapeBroken,
  unfulfilledDependencies,
  hasResourcesSelectedById,
  isResourceSelectorEnabled
}) => (
  <>
    {isResourceSelectorEnabled && (
      <>
        <Title label={t('global.resources')} isCompact />
        <Row>
          <Col>
            <FormText>{t('booking.resourceSelector.subtitle')}</FormText>
          </Col>
        </Row>
        {!isCombination && (
          <>
            <Row>
              <Col>
                <BookingDependenciesInput
                  name='dependencies'
                />
              </Col>
            </Row>
            {!areWorkingInParallel && !hasResourcesSelectedById[''] && (
              <Alert theme='info'>{t('booking.resourceSelector.noneSelected.message')}</Alert>
            )}
            {hasResourcesSelectedById[''] && (isScheduleForm || isServiceShapeBroken) && (
              <BookingFormDependenciesAlert
                resources={resources}
                resourceCategories={resourceCategories}
                unfulfilledDependencies={unfulfilledDependencies}
              />
            )}
          </>
        )}
        {isCombination && (
          combinationServices.map(service => (
            <Fragment key={service.id}>
              <Row>
                <Col>
                  <div className='ta-booking__dependencies__combination-service-title'>
                    <div className='ta-booking__dependencies__combination-service-marker' style={{ background: service.color }} />
                    <div className='ta-booking__dependencies__combination-service-name'>{service.name}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BookingDependenciesInput
                    name={`dependencies${service.id}`}
                  />
                </Col>
              </Row>
              {!hasResourcesSelectedById[service.id] && (
                <Alert theme='info'>{t('booking.resourceSelector.noneSelected.message')}</Alert>
              )}
              {hasResourcesSelectedById[service.id] && (isScheduleForm || isSubServiceShapeBroken[service.id]) && (
                <BookingFormDependenciesAlert
                  resources={resources}
                  resourceCategories={resourceCategories}
                  unfulfilledDependencies={unfulfilledDependencies[service.id]}
                />
              )}
            </Fragment>
          ))
        )}
      </>
    )}
  </>
)

export default BookingFormDependencies
