import React from 'react'
import { connect, selectors } from '../../Store'
import { Content } from '../../Beauties'

const ContentWrapper = props => {
  const { sidebar, branches } = props
  const { position } = sidebar || {}

  return (
    <Content position={position} branchesPending={branches.pending} />
  )
}

const maps = state => ({
  sidebar: selectors.routerPropsFieldSelector(state, { field: 'sidebar' }),
  branches: state.branches
})

export default connect(maps)(ContentWrapper)
