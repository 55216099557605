import moment from 'moment'
import React from 'react'
import {
  Row,
  Col,
  SingleSelect,
  Input,
  Textarea,
  Checkbox,
  PhoneInput,
  DateInput,
  ImageInput,
  AddressInput,
  FilesInput,
  FormText,
  t
} from '../../../Common'
import { UPLOAD_FILE_SIZE_LIMIT } from '../../../Settings'

const CustomerFormField = props => {
  let {
    field,
    availableFields,
    timezones,
    setFileLoading
  } = props
  field = field || {}
  availableFields = availableFields || []
  timezones = timezones || []
  const classNames = ['ta-customers__form-row']
  if (field.isDefault) {
    if (field.defaultId === 'avatar') classNames.push('avatar')
    if (availableFields.includes('avatar') && field.defaultId !== 'avatar') classNames.push('with-avatar')
  }
  const name = `customerFields${field.id}`
  const mandatory = field.isMandatoryOffline
  const label = field.label || t(field.translationKey)
  const TYPE_INPUT = ['TEXT', 'EMAIL']
  let { type, defaultId } = field || {}
  if (field.defaultId === 'avatar') type = 'AVATAR'

  if (type === 'FILES') {
    const fieldType = field.hasOverwrite ? 'CUSTOMER' : 'BOOKING'

    return (
      <Row>
        <Col>
          <FilesInput
            name={name}
            maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
            maxFiles={field.fileUploadLimit}
            allowMultiple={field.fileUploadLimit > 1}
            label={field.label}
            acceptedFileTypes={field.fileUploadTypes}
            setLoading={setFileLoading}
            fieldId={field.id}
            fieldType={fieldType}
            mandatory={mandatory}
          />
          {field.description && (
            <FormText>{field.description}</FormText>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Row className={classNames.join(' ')}>
      <Col>
        {(TYPE_INPUT.includes(type) &&
          <Input
            name={name}
            label={label}
            mandatory={mandatory}
          />
        )}
        {(type === 'SELECT' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            options={field.options}
            clearable
          />
        )}
        {(type === 'TIMEZONE' &&
          <SingleSelect
            name={name}
            label={label}
            mandatory={mandatory}
            options={timezones.map(item => ({ value: item.code, label: item.label }))}
            clearable
            searchable
          />
        )}
        {(type === 'TEXTAREA' &&
          <Textarea
            name={name}
            label={label}
            mandatory={mandatory}
            limit={3000}
          />
        )}
        {(type === 'CHECKBOX' &&
          <Checkbox name={name} label={field.label} />
        )}
        {(type === 'PHONE' &&
          <PhoneInput
            name={name}
            label={label}
            mandatory={mandatory}
            top
          />
        )}
        {(type === 'DATE' &&
          <DateInput
            name={name}
            label={label}
            icon='calendar'
            timezone='UTC'
            showMonth
            showYear
            clearable
            mandatory={mandatory}
            maxDate={defaultId === 'birthday' ? moment() : null}
          />
        )}
        {(type === 'ADDRESS' &&
          <AddressInput
            name={name}
            label={label}
            defaultZoom={1.5}
            defaultBorderRadius={50}
            controls={{ radius: false }}
            validation={{ maxSize: 7 }}
            mandatory={mandatory}
            withMap
            clearable
          />
        )}
        {(type === 'FILE' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('customers.form.section.details.avatar.title')}
            text={t('customers.form.section.details.avatar.text')}
          />
        )}
        {(type === 'AVATAR' &&
          <ImageInput
            name={name}
            defaultZoom={1.5}
            defaultBorderRadius={100}
            controls={{ radius: false }}
            validation={{ maxSize: 5 }}
            mandatory={mandatory}
            title={t('customers.form.section.details.avatar.title')}
            text={t('customers.form.section.details.avatar.text')}
          />
        )}
      </Col>
    </Row>
  )
}

export default CustomerFormField
