import React from 'react'
import PropTypes from 'prop-types'
import { feedContextInProps } from '../../../Utils'
import { SimpleTabsContext } from '../../../Common'

const SimpleTabsBodyItem = (props) => {
  const {
    name,
    children,
    className,
    renderOnlyIfActive,
    active
  } = props
  const classNames = ['ta-simple-tabs__body-item']
  if (className) classNames.push(className)
  if (name === active) classNames.push('active')
  classNames.push(`ta-simple-tabs-${name}`)

  return (
    <div className={classNames.join(' ')} data-testid='simple-tabs-body-item'>
      {renderOnlyIfActive && name === active &&
          children
      }
      {!renderOnlyIfActive &&
          children
      }
    </div>
  )
}

SimpleTabsBodyItem.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default feedContextInProps(SimpleTabsBodyItem, SimpleTabsContext)
