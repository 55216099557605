import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionTitle,
  AccordionBody,
  Title
} from '../../Common'
import './PermissionTypeAccordion.css'

const PermissionTypeAccordion = ({ title, children, zIndex = 100 }) => {
  return (
    <Accordion
      zIndex={zIndex}
      className='ta-permission-types__custom-accordion'
      expand={false}
    >
      <AccordionTitle>
        <Title label={title} isCompact />
      </AccordionTitle>
      <AccordionBody>
        {children}
      </AccordionBody>
    </Accordion>
  )
}

PermissionTypeAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  zIndex: PropTypes.number,
  children: PropTypes.node
}

export default PermissionTypeAccordion
