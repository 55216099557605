import { phoneFields } from '../Agents/fields'
import { accountFields, enterpriseAccountFields } from '../AccountsEngine/fields'
import { companySettingsFields } from '../Companies/fields'

export const tagFields = `{
  id
  name
  color
  externalId
  internalId
  isUpdatedLocally
  isGloballyDeleted
  orderIndex
  isForEvents
  isForCustomers
  eventsCount
  customersCount
  ts
}`

export const dayFields = `{
  isActive
  times {
    begin
    end
  }
}`

export const companyPermissionsFields = `{
  accountRead
  calendarReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  calendarWrite
  calendarDelete
  shiftRead
  shiftWrite
  shiftDelete
  statisticsRead
  notificationsRead
  customersReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  customersWrite
  customersDelete
  appsRead
  resourcesReadSet {
    specificResourceCategoryIds
    specificResourceIds
  }
  resourcesReadResourceIds
  resourcesReadResourceCategoryIds
  calendarReadResourceIds
  calendarReadResourceCategoryIds
  customersReadResourceIds
  customersReadResourceCategoryIds
}`

export const resourceCategoryFields = `{
  id
  ts
  name
  isDependency
  orderIndex
  isDefault
  externalId
  internalId
  isUpdatedLocally
}`

export const resourceFields = `{
  id
  userId
  externalId
  abbreviation
  createdAt
  updatedAt
  ts
  name
  avatarUrl
  orderIndex
  isBookable
  hasOnlineWarning
  categoryId
  email
  color
  account {
    isVerified
    isConfirmed
    isAdmin
    isCustom
    isOwner
    companyPermissions ${companyPermissionsFields}
    roles
    providers
    ts
    companyPermissionsGroupId
  }
  calendar {
    timezone
    threads
    enforceAllowanceType
    workingWeeklyAllowancePlan ${dayFields}
    bookingWeeklyAllowancePlan ${dayFields}
  }
}`

export const customisationFields = `{
  id
  name
  type
  payload
}`

export const calendarFields = `{
  id
  timezone
  accountId
  region
  workingWeeklyAllowancePlan {
    isActive
    times {
      begin
      end
    }
  }
  bookingWeeklyAllowancePlan {
    isActive
    times {
      begin
      end
    }
  }
  enforceAllowanceType
  threads
}`

export const accountUserAccountsFields = `{
  company {
    id
    name
    timezone
  }
  enterprise ${enterpriseAccountFields}
  authScope
  accountId
  userId
  isConfirmed
  isConnected
  updatedAt
  isAdmin
  isCustom
  isOwner
  roles
  email
  ts
}`

export const tokensFields = `{
  accessToken
  refreshToken
  sessionDuration
  account ${accountFields}
}`

export const branchesListFields = `{
  id
  name
  personName
  region
  locale
  timezone
  info
  currency
  tags {
    id
    name
    color
    note
  }
  tagIds
  contactEmail
  address {
    placeId
    latitude
    longitude
    street
    streetNumber
    city
    country
    zipCode
    formatted
  }
  companyTags { id name color isForCustomers isForEvents }
  phone {
    prefix
    country
    number
    phone
  }
  plan
  createdAt
  onlineStatus {
    wantsToBeOnline
    isOnline
  }
  settings ${companySettingsFields}
  rawSettings ${companySettingsFields}
  timezone
  ownerEmail
  hasStripeConnected
  webappCustomisation {
    id
    name
    type
    payload
  }
  lastStatisticsStateChange
  externalId
}`

export const selectOptions = `
  {
    label
    value
    translationKey
    extraPrice
    extraDuration
    isDefault
  }
`

export const customerFieldCategoryFields = `
  {
    id
    externalId
    name
    translationKey
    orderIndex
    isDefault
    companyId
    internalId
    defaultId
    isUpdatedLocally
    isGloballyDeleted
    allowedBranchIds
    allowedBranchExternalIds
    locallyUpdatedBranches
    createdAt
    updatedAt
    ts
  }
`

export const customerFieldFields = `
  {
    id
    externalId
    internalId
    defaultId
    label
    translationKey
    isActive
    isDefault
    type
    selectOptions ${selectOptions}
    isMultiple
    orderIndex
    categoryId
    category ${customerFieldCategoryFields}
    fileUploadTypes
    fileUploadLimit
    isMandatoryOnline
    isMandatoryOffline
    isUniqueKey
    isPublished
    description
    placeholder
    isAfterAuth
    hasOverwrite
    extraPrice
    extraDuration
    specificServiceCategoryIds
    specificServiceIds
    serviceIds
    specificCourseCategoryIds
    specificCourseIds
    courseIds
    companyId
    isUpdatedLocally
    isGloballyDeleted
    displayOnHover
    displayOnBookingForm
    allowedBranchIds
    allowedBranchExternalIds
    locallyUpdatedBranches
    createdAt
    updatedAt
    ts
  }
`

export const customerFieldCategoriesFields = `
  {
    id
    externalId
    name
    translationKey
    orderIndex
    isDefault
    companyId
    internalId
    defaultId
    customerFields ${customerFieldFields}
    isUpdatedLocally
    isGloballyDeleted
    allowedBranchIds
    allowedBranchExternalIds
    locallyUpdatedBranches
    createdAt
    updatedAt
    ts
  }
`

export const companyTags = `{
  id
  name
  color
  note
  orderIndex
  isForEvents
  isForCustomers
  companyId
  enterpriseId
  internalId
  region
  eventsCount
  customersCount
  isUpdatedLocally
  isGloballyDeleted
  externalId
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  createdAt
  updatedAt
  ts
}`

export const customFields = `{
  id
  type
  value
  values
  isMultiple
  translationKey
  label
}`

export const intervalFields = `{
  interval {
    begin
    end
  }
  batchNumber
  recurringDay
  totalBatchNumbers
}`

export const recurringFields = `{
  timezone
  frequency
  period
  weekDays
  monthDays
  yearMonths
  repeatTimes
  repeatLastDay
  maxDay
}`

export const combinationFields = `{
  eventId
  serviceId
  title
  price
  events {
    eventId
    serviceId
    resourceIds
    title
    color
    duration
  }
  hasNotifyCustomers
  hasNotifyResources
  durationBefore
  durationAfter
  startDate
  isPaidOnlineWithStripe
}`

export const categoryFields = `{
  isCourse
  id
  companyId
  externalId
  internalId
  orderIndex
  name
  translationKey
  isDefault
  isUpdatedLocally
  isGloballyDeleted
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  createdAt
  updatedAt
  ts
}`

export const dependencyFields = `{
  name
  translationKey
  specificResourceCategoryIds
  specificResourceIds
  workInParallel
  resourceIds
  isStalled
}`

export const bookingFields = `{
  id
  calendarId
  accountId
  region
  eventId
  startDate
  interval {
    begin
    end
  }
  isFirstBatch
  batchNumber
  recurringDay
  isLastBatch
  duration
  durationTotal
  durationBefore
  durationAfter
}`

export const bookingIntervalFields = `{
  bookingId
  booking ${bookingFields}
  day
  beginTime
  endTime
  duration
  durationBefore
  durationAfter
  durationTotal
  isFirstDay
  isLastDay
  isFirstBatch
  batchNumber
  recurringDay
  isLastBatch
  isFirst
  isLast
}`

export const participantFields = `{
  reservationId
  customerId
  customerName
  extraPersons
  unitPrice
  isPaidOnlineWithStripe
  fields ${customFields}
  totalPrice
  reservedUntil
  isBookedOnline
  bookedAt
  email
}`

export const serviceFields = `{
  isCourse
  id
  externalId
  orderIndex
  categoryId
  companyId
  enterpriseId
  internalId
  name
  color
  isBookable
  hasOnlineWarning
  hasOnlinePaymentWarning
  description
  durationBefore
  durationAfter
  duration
  durationsPattern
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  price
  hasOnlinePayment
  isPaymentMandatory
  isStalled
  hasStrictDependencies
  isUpdatedLocally
  isGloballyDeleted
  isGhost
  calendar ${calendarFields}
  isCombination
  combinationServiceIds
  isCombinationPriceOverwritten
  hasCombinationSameResourcesPreference
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  maxParticipants
  extraPersonsPerParticipant
  category ${categoryFields}
  resourcesCount
  dependencies ${dependencyFields}
  createdAt
  updatedAt
  ts
}`

export const eventsFields = `{
  id
  externalId
  companyId
  region
  isCourse
  startDate
  intervals ${intervalFields}
  recurring ${recurringFields}
  chainId
  chainLength
  isLastInTheChain
  combination ${combinationFields}
  durationBefore
  durationAfter
  duration
  durationsPattern
  notes
  tagIds
  tags ${tagFields}
  color
  reschedulingSecret
  price
  title
  serviceId
  services ${serviceFields} 
  service ${serviceFields}
  resourceIds
  resources ${resourceFields}
  customersCount
  bookings ${bookingFields}
  customerEmailRemindersMinutes
  resourceEmailRemindersMinutes
  bookingIntervals ${bookingIntervalFields}
  customerId
  customerName
  fields ${customFields}
  reservedUntil
  hasNotifyCustomers
  hasNotifyResources
  isBookedOnline
  isPaidOnlineWithStripe
  maxParticipants
  spotsLeft
  extraPersonsPerParticipant
  participants ${participantFields}
  isBookable
  hasOnlinePayment
  isPaymentMandatory
  metadata
  participantsCount
  rescheduleUntil
  type
  deepLinkConfig {
    appId
    isOneTimeUsage
    isForLimitedIntervals
  }
  rescheduledTimes
  scheduledForDeletionAt
  createdBy
  createdByUserId
  createdByScope
  updatedBy
  updatedByUserId
  updatedByScope
  createdAt
  updatedAt
  ts
  reschedulingSecret
}`

export const customerFields = `{
  id
  externalId
  internalId
  tagIds
  tags ${companyTags}
  totalBookings
  bookedCompanies
  avatarUrl
  email
  phone ${phoneFields}
  timezone
  fullName
  firstName
  lastName
  companyName
  nameFirstLetters
  hasBooker
  markedForDeletionByGDPR
  middlewareId
  fields ${customFields}
  events ${eventsFields}
  resourceIds
  resourceCategoryIds
  companyId
  isUpdatedLocally
  isGloballyDeleted
  enterpriseId
  isCreatedOnline
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  createdAt
  updatedAt
  ts
}`

export const serviceAvailabilityFields = `{
  calendarBegin
  calendarEnd
  serviceId
  selectedResourceIds
  resources ${resourceFields}
  dependencies {
    name
    translationKey
    resourceIds
  }
  resourceCategories ${resourceCategoryFields}
  onDays
  offDays
  slots {
    day
    times
    minutes
  }
}`

const eventMinumumFields = `
  id
  ts
  type
  chainId
  isLastInTheChain
  isCourse
  combination {
    eventId
    serviceId
    title
    price
    durationBefore
    durationAfter
    startDate
    events {
      eventId
      serviceId
      resourceIds
      title
      color
      duration
    }
    isPaidOnlineWithStripe
    hasNotifyCustomers
    hasNotifyResources
  }
  maxParticipants
  extraPersonsPerParticipant
  participantsCount
  participants ${participantFields}
  isBookable
  createdAt
  updatedAt
  createdBy
  updatedBy
  title
  color
  serviceId
  resourceIds
  notes
  customerId
  customerName
  customersCount
  isBookedOnline
  isPaidOnlineWithStripe
  reservedUntil
  price
  tagIds
  fields {
    id
    type
    value
    values
  }
  hasNotifyCustomers
  hasNotifyResources
  intervals {
    batchNumber
    recurringDay
    interval {
      begin
      end
    }
  }
  duration
  durationsPattern
  durationBefore
  durationAfter
  recurring {
    timezone
    frequency
    period
    weekDays
    monthDays
    yearMonths
    repeatTimes
    repeatLastDay
    maxDay
  }
  startDate
  hasOnlinePayment
  isPaymentMandatory
  deepLinkConfig {
    appId
  }
`

export const branchCompanyEventsFields = `{
  ${eventMinumumFields}
  bookingIntervals ${bookingIntervalFields}
}`

export const callCentreLocalTransformations = `{
  service ${serviceFields}
  services ${serviceFields}
  customer ${customerFields}
  customerFields ${customerFieldFields}
}`

export const callCentreTagsListFields = `{
  id
  name
  isForCustomers
}`
