import React from 'react'
import {
  Link,
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'

import './ListButton.css'

const ListButton = props => {
  const {
    className,
    icon,
    square,
    children,
    hasText,
    hoverText,
    ...rest
  } = props
  const classNames = ['ta-list-button', 'ta-grab']
  if (className) classNames.push(className)
  if (square) classNames.push('square')
  if (hasText) classNames.push('has-text')

  return (
    <HoverPopup disabled={!hoverText}>
      <HoverPopupContent position='left'>
        {hoverText}
      </HoverPopupContent>
      <HoverPopupTrigger>
        <Link {...rest} className={classNames.join(' ')}>
          {icon &&
            <FontAwesome5 icon={icon} type='solid' />
          }
          {children}
        </Link>
      </HoverPopupTrigger>
    </HoverPopup>
  )
}

export default ListButton
