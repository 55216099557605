import React from 'react'
import { FontAwesome5 } from '../../Common'

import './Spinner.css'

const Spinner = props => {
  const { icon = 'spinner', type = 'solid' } = props

  return (
    <div className='ta-spinner' data-testid='spinner'>
      <FontAwesome5 icon={icon} type={type} spin />
    </div>
  )
}

export default Spinner
