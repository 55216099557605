import React from 'react'
import PropTypes from 'prop-types'

const Image = props => {
  const { src, alt, ...rest } = props

  return (
    <img {...rest} src={src} alt={alt} data-testid='image' />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

export default Image
