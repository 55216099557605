import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Input,
  Error,
  Link,
  FontAwesome5,
  Loader,
  t
} from '../../Common'

import './TwoFAForm.css'

const TwoFACodeForm = ({ code, onSubmit, pending }) => {
  return (
    <Form name='twoFA' onSubmit={onSubmit} className='ta-2fa-login-container'>
      <Loader active={pending} />
      <div className='ta-2fa-login-header'>
        {t('twoFA.login.header')}
        <div className='ta-2fa-select__box__logout'>
          <Link to='/logout'>
            <FontAwesome5 icon='arrow-left' type='solid' />
          </Link>
        </div>
      </div>
      <div className='ta-2fa-login-content'>
        <Input name='code' label={t('global.pinCode.label')} placeholder={t('global.pinCode.placeholder')} mandatory hideError autoFocus />
        <Error name='code' />
        <div className='ta-2fa-login-actions'>
          <button type='submit' disabled={!code} className='ta-btn ta-btn-primary ta-2fa-activate-button'>{t('global.verify')}</button>
        </div>
      </div>
    </Form>
  )
}

TwoFACodeForm.propTypes = {
  code: PropTypes.string,
  onSubmit: PropTypes.func,
  pending: PropTypes.bool
}

export default TwoFACodeForm
