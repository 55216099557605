import React from 'react'
import { AgentPreviewPermissionsWrapper } from '../../../../Beasts'
import {
  SectionTitle,
  InnerAccordion,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../../Common'

import './AgentPreviewPermissions.css'

const AgentPreviewPermissions = props => {
  const { isCustom, permissions } = props
  const permissionText = isCustom ? t('agents.preview.tabDetails.customPermission.text') : t('agents.preview.tabDetails.adminPermission.text')

  return (
    <div className='ta-agents__preview__details__permissions'>
      <SectionTitle label={t('global.permissions')} icon='unlock-alt' />
      <InnerAccordion
        isOpen={false}
        icon={(isCustom ? 'cogs' : 'user-shield')}
        iconStyle={isCustom ? 'blue' : 'red'}
        title={
          <HoverPopup>
            <HoverPopupContent position='top'>
              {permissionText}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <div className='ta-agents__preview__details__permissions__text'>
                {permissionText}
              </div>
            </HoverPopupTrigger>
          </HoverPopup>
        }
      >
        {isCustom && (
          <AgentPreviewPermissionsWrapper permissions={permissions} />
        )}
      </InnerAccordion>
    </div>
  )
}

export default AgentPreviewPermissions
