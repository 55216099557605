import React, { Component } from 'react'
import { connect, handlers } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import { FormContext, FormGroup, Error } from '../../../Common'

import './HostedField.css'

class HostedField extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onReset = this.onReset.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    const { name, form, formName, onChange, onChangeAddon } = this.props
    this.setState({ filled: !!value })
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  onReset () {
    const { name, form, formName } = this.props
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: '' } })
  }

  render () {
    const { focused } = this.state
    const {
      id,
      name,
      label,
      placeholder,
      mandatory,
      disabled,
      hideError,
      className
    } = this.props
    const classNames = ['ta-form-control']

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={className}>
        <FormGroup
          focused={focused}
          filled
          disabled={disabled}
          labelText={label}
          labelMandatory={mandatory}
        >
          <div
            id={id}
            className={classNames.join(' ')}
            placeholder={placeholder}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          {!hideError && <Error name={name} />}
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => ({
  hostedFieldsInstance: state.paymentMethods.hostedFieldsInstance || null
})

export default feedContextInProps(connect(maps)(HostedField), FormContext)
