import React, { Component } from 'react'
import PropTypes from 'prop-types'
import momenttz from 'moment-timezone'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps, getMonthLabelByIndex } from '../../../Utils'
import { FormContext, FontAwesome5, OutsideClick, t } from '../../../Common'

import './CalendarMonthInput.css'

class CalendarMonthInput extends Component {
  constructor (props, context) {
    super(props)

    this.closePicker = this.closePicker.bind(this)
    this.togglePicker = this.togglePicker.bind(this)
    this.onClickPrevYear = this.onClickPrevYear.bind(this)
    this.onClickNextYear = this.onClickNextYear.bind(this)
    this.onClickNextMonth = this.onClickNextMonth.bind(this)
    this.onClickPrevMonth = this.onClickPrevMonth.bind(this)

    this.state = {
      isOpen: false,
      date: null,
      year: null
    }
  }

  componentDidMount () {
    let { timezone, form, router } = this.props
    form = form || { value: '' }

    this.setState(prevState => ({
      year: momenttz.tz((router.data.date || form.value || momenttz.tz(timezone)), 'YYYY-MM-DD', timezone).year(),
      date: momenttz.tz((router.data.date || form.value || momenttz.tz(timezone)), 'YYYY-MM-DD', timezone).format('YYYY-MM-DD')
    }))
  }

  createDate (year = null, month = null, timezone = null, format = 'YYYY-MM-DD') {
    return momenttz.tz([year, month], 'YYYY-MM', timezone).format(format)
  }

  onChange ({ month }) {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const { date } = this.state
    const currentYear = momenttz.tz(date, 'YYYY-MM-DD', timezone).year()
    const newDate = this.createDate(currentYear, month, timezone)

    if (newDate !== form.value) {
      this.setState(prevState => ({
        date: newDate
      }), () => {
        handlers.formFieldsUpdate(formName, { [name]: { ...form, value: newDate } })
        this.closePicker()
      })
    }
  }

  onClickPrevMonth () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const newDate = momenttz.tz(form.value || momenttz.tz().format('YYYY-MM-DD'), 'YYYY-MM-DD', timezone).subtract(1, 'months').format('YYYY-MM-DD')

    this.setState(prevState => ({
      year: prevState.year - 1,
      date: newDate
    }), () => {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value: newDate } })
    })
  }

  onClickNextMonth () {
    let { timezone, formName, name, form } = this.props
    form = form || { value: '' }
    const newDate = momenttz.tz(form.value || momenttz.tz().format('YYYY-MM-DD'), 'YYYY-MM-DD', timezone).add(1, 'months').format('YYYY-MM-DD')

    this.setState(prevState => ({
      year: prevState.year + 1,
      date: newDate
    }), () => {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value: newDate } })
    })
  }

  onClickPrevYear () {
    const { timezone } = this.props

    this.setState(prevState => ({
      year: prevState.year - 1,
      date: momenttz.tz(prevState.date, 'YYYY-MM-DD', timezone).subtract(1, 'years').format('YYYY-MM-DD')
    }))
  }

  onClickNextYear () {
    const { timezone } = this.props

    this.setState(prevState => ({
      year: prevState.year + 1,
      date: momenttz.tz(prevState.date, 'YYYY-MM-DD', timezone).add(1, 'years').format('YYYY-MM-DD')
    }))
  }

  closePicker () {
    this.setState(prevState => ({
      isOpen: false
    }))
  }

  togglePicker () {
    let { timezone, form, disabled } = this.props
    form = form || { value: '' }
    if (disabled) return
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      year: (!prevState.isOpen && momenttz.tz(form.value, 'YYYY-MM-DD', timezone).year()) || prevState.year,
      date: (!prevState.isOpen && form.value) || prevState.date
    }))
  }

  render () {
    const { isOpen, year, date } = this.state
    let { className, timezone, form, disabled } = this.props
    form = form || { value: '' }
    const classNames = ['ta-calendar-month-input', 'no-select']
    const monthInputClassNames = ['ta-calendar-month-input__custom-input']
    const value = form.value ? momenttz.tz(form.value, 'YYYY-MM-DD', timezone) : momenttz.tz(timezone)
    const monthIndex = Number(momenttz.tz(value, 'YYYY-MM-DD', timezone).month()) + 1
    const months = [
      { index: 1, month: t('months.jan.short') },
      { index: 2, month: t('months.feb.short') },
      { index: 3, month: t('months.mar.short') },
      { index: 4, month: t('months.apr.short') },
      { index: 5, month: t('months.may.short') },
      { index: 6, month: t('months.jun.short') },
      { index: 7, month: t('months.jul.short') },
      { index: 8, month: t('months.aug.short') },
      { index: 9, month: t('months.sep.short') },
      { index: 10, month: t('months.oct.short') },
      { index: 11, month: t('months.nov.short') },
      { index: 12, month: t('months.dec.short') }
    ]

    if (isOpen) classNames.push('open')
    if (disabled) monthInputClassNames.push('disabled')

    return (
      <div className={className}>
        <div className={classNames.join(' ')}>
          {!disabled &&
            <div onClick={this.onClickPrevMonth} className='ta-calendar-month-input__btn'>
              <FontAwesome5 icon='angle-left' type='solid' />
            </div>
          }
          <div onClick={this.togglePicker} className={monthInputClassNames.join(' ')}>
            <FontAwesome5 icon='calendar-alt' type='solid' />
            <div className='ta-calendar-month-input__month-label'>
              {t(`months.${getMonthLabelByIndex(monthIndex)}.long`)},  {momenttz.tz(value, 'YYYY-MM-DD', timezone).year()}
            </div>
          </div>
          {isOpen &&
            <OutsideClick onClickOutside={this.togglePicker}>
              <div className='ta-calendar-month-input-wrapper'>
                <div className='ta-calendar-month-input__current-year'>
                  <div onClick={this.onClickPrevYear} className='ta-calendar-month-input__current-year__prev'>
                    <FontAwesome5 icon='angle-left' type='s' />
                  </div>
                  <div className='ta-calendar-month-input__current-year__label'>
                    {year && year.toString()}
                  </div>
                  <div onClick={this.onClickNextYear} className='ta-calendar-month-input__current-year__next'>
                    <FontAwesome5 icon='angle-right' type='s' />
                  </div>
                </div>
                <div className='ta-calendar-month-input__months-wrapper'>
                  {months.map((item, index) => {
                    const classNames = ['ta-calendar-month-input-month']
                    if (item.index === monthIndex && date === form.value) classNames.push('active')
                    return (
                      <div onClick={this.onChange.bind(this, { month: item.index })} className={classNames.join(' ')} key={index}>
                        {item.month}
                      </div>
                    )
                  })}
                </div>
              </div>
            </OutsideClick>
          }
          {!disabled &&
            <div onClick={this.onClickNextMonth} className='ta-calendar-month-input__btn'>
              <FontAwesome5 icon='angle-right' type='solid' />
            </div>
          }
        </div>
      </div>
    )
  }
}

CalendarMonthInput.propTypes = {
  name: PropTypes.string,
  format: PropTypes.string,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName }),
  timezone: selectors.timezoneSelector(state),
  router: selectors.routerSelector(state)
})

export default feedContextInProps(connect(maps)(CalendarMonthInput), FormContext)
