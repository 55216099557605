import {
  tokensFields,
  accountForOAuthFields,
  profileFields,
  accountFields,
  grantCode
} from './fields'

// Queries

export const refreshTokens = `
  query refreshTokens($refreshToken: ID!) {
    refreshTokens(refreshToken: $refreshToken) ${tokensFields}
  }
`
export const getTokensWithOneTimeLoginToken = `
  query getTokensWithOneTimeLoginToken($authScope: AuthScope!, $loginToken: String!) {
    getTokensWithOneTimeLoginToken(
      authScope: $authScope
      loginToken: $loginToken
      longSession: false
    ) ${tokensFields}
  }
`
export const selectAccount = `
  query selectAccount($accountId: ID!) {
    selectAccount(accountId: $accountId) ${tokensFields}
  }
`
export const getAccount = `
  query getAccount {
    getAccount ${accountFields}
  }
`
export const getAccountForOAuth = `
  query getAccount($appId: ID!) {
    getAccount ${accountForOAuthFields}
  }
`
export const getAppOAuthGrantCode = `
  query getAppOAuthGrantCode($accountId: ID, $appId: ID, $responseType: String, $redirectUri: String) {
    getAppOAuthGrantCode(accountId: $accountId, appId: $appId, responseType: $responseType, redirectUri: $redirectUri) ${grantCode}
  }
`
export const getAppPublicData = `
  query getAppPublicData($id: ID!) {
    getAppPublicData(id: $id) {
      id
      externalId
      name
      version
      plan
      imageUrl
      roles {
        name
        types
      }
      developer {
        companyName
        name
      }
    }
  }
`

// Mutations

export const saveUserData = `
  mutation saveUserData($userData: UserData!) {
    saveUserData(userData: $userData) ${profileFields}
  }
`
export const sendEmailVerificationCode = `
  mutation sendEmailVerificationCode($email: String!, $authScope: AuthScope!) {
    sendEmailVerificationCode(email: $email, authScope: $authScope)
  }
`
export const sendEmailConfirmationCode = `
  mutation sendEmailConfirmationCode($accountId: ID!, $email: String!) {
    sendEmailConfirmationCode(accountId: $accountId, authScope: ENTERPRISE_CALL_CENTRE, email: $email)
  }
`
export const unlinkConnectedAccountToResource = `
  mutation unlinkConnectedAccountToResource($companyId: ID!) {
    unlinkConnectedAccountToResource(companyId: $companyId)
  }
`
export const changeEnterpriseCallCentreUserEmail = `
  mutation changeEnterpriseCallCentreUserEmail($email: String!, $password: String!) {
    changeEnterpriseCallCentreUserEmail(email: $email, password: $password) ${profileFields}
  }
`
export const cancelChangeEnterpriseCallCentreUserEmail = `
  mutation cancelChangeEnterpriseCallCentreUserEmail($password: String!) {
    cancelChangeEnterpriseCallCentreUserEmail(password: $password)
  }
`
export const invalidateSession = `
  mutation invalidateSession {
    invalidateSession
  }
`

// 2FA
export const initUser2FA = `
  mutation initUser2FA($type: Auth2FAType!) {
    initUser2FA(type: $type) {
      secretKey
      service
      account
    }
  }
`

export const setupUser2FA = `
  mutation setupUser2FA($type: Auth2FAType!, $code: ID!, $withAuth: Boolean) {
    setupUser2FA(type: $type, code: $code, withAuth: $withAuth) {
      type
      authTokens ${tokensFields}
      recoveryCodes
    }
  }
`

export const disable2FA = `
  mutation disable2FA($type: Auth2FAType!, $code: ID!) {
    disable2FA(type: $type, code: $code) ${tokensFields}
  }
`

export const refresh2FARecoveryCodes = `
  mutation refresh2FARecoveryCodes($type: Auth2FAType!, $code: ID!) {
    refresh2FARecoveryCodes(type: $type, code: $code)
  }
`

export const authenticate2FA = `
  query authenticate2FA($type: Auth2FAType, $code: ID) {
    authenticate2FA(type: $type, code: $code) ${tokensFields}
  }
`

export const unlinkConnectedAccountToCompanyResource = `
  mutation unlinkConnectedAccountToCompanyResource($companyId: ID!) {
    unlinkConnectedAccountToCompanyResource(companyId: $companyId)
  }
`
export const unlinkConnectedAccountToEnterprise = `
  mutation unlinkConnectedAccountToEnterprise($enterpriseId: ID!) {
    unlinkConnectedAccountToEnterprise(enterpriseId: $enterpriseId)
  }
`
