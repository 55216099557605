import { sortByOrderIndex } from '../../../Utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pendingList: true,
  globalTags: [],
  pendingGlobalTags: true,
  areFetched: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  tagsListPopulate: ({ tags }) => dispatch(actions.TAGS_LIST_POPULATE, { tags }),

  // Global Tags
  globalTagsGet: () => dispatch(actions.GLOBAL_TAGS_GET),
  globalTagsPopulate: (tags) => dispatch(actions.GLOBAL_TAGS_POPULATE, { tags })
})

reducer.TAGS_LIST_POPULATE = (state, { tags = [] }) => ({
  ...state,
  list: tags.sort(sortByOrderIndex),
  pendingList: false,
  areFetched: true
})

reducer.GLOBAL_TAGS_GET = state => ({
  ...state,
  pendingGlobalTags: true
})

reducer.GLOBAL_TAGS_POPULATE = (state, { tags = [] }) => ({
  ...state,
  globalTags: tags,
  pendingGlobalTags: false
})

export default reducer
