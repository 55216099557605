import { PopupTriggerWrapper } from '../../../Beasts'
import { BookingFormResourceAvatarsPopupContent } from '../../../Beauties'
import {
  FontAwesome5,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'

const CustomerPreviewEventResources = ({ event }) => {
  const { resourceIds, combination } = event || {}
  const resourcesNr = [...new Set(combination ? combination?.events?.flatMap?.(ev => ev.resourceIds) : resourceIds)]?.length
  const labelKey = resourcesNr > 1 ? 'global.resources' : 'global.resource'

  return (
    <div className='ta-customers__preview__event-resources'>
      <ContentWrapper event={event}>
        <span className='ta-customers__preview__event-resources__label'>
          <FontAwesome5 icon='user-friends' type='s' />
          <span className='ta-customers__preview__event-resources__count'>
            {resourcesNr} {t(labelKey)}
          </span>
        </span>
      </ContentWrapper>
    </div>
  )
}

export default CustomerPreviewEventResources

const ContentWrapper = ({ children, event }) => {
  const { id, areResourcesFetched, resources, resourceCategories } = event || {}

  return (
    <>
      {areResourcesFetched && (
        <HoverPopup>
          <HoverPopupContent>
            <BookingFormResourceAvatarsPopupContent resources={resources} resourceCategories={resourceCategories} />
          </HoverPopupContent>
          <HoverPopupTrigger>{children}</HoverPopupTrigger>
        </HoverPopup>
      )}
      {!areResourcesFetched && (
        <PopupTriggerWrapper
          name='customers-event-resources'
          position='top'
          extras={{ eventId: id }}
          extraClassName='ta-popup__customer-event-resources__popup'
        >
          {children}
        </PopupTriggerWrapper>
      )}
    </>
  )
}
