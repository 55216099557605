import React from 'react'
import { feedContextInProps } from '../../Utils'
import { StepperContext } from '../../Common'

const StepperStep = props => {
  const {
    isLoading,
    children,
    step,
    activeStep,
    prevStep
  } = props
  const classNames = ['ta-stepper__step']
  if (isLoading) classNames.push('loading')
  if (step === activeStep && !prevStep) classNames.push('active')
  if (step === activeStep && prevStep && activeStep > prevStep) classNames.push('active-next')
  if (step === activeStep && prevStep && activeStep < prevStep) classNames.push('active-prev')
  if (step < activeStep) classNames.push('prev')
  if (step > activeStep) classNames.push('next')
  if (Math.abs(activeStep - prevStep) > 1) {
    if (activeStep > prevStep && activeStep > step && prevStep < step) classNames.push('skip')
    if (activeStep < prevStep && activeStep < step && prevStep > step) classNames.push('skip')
  }

  return (
    <div className={classNames.join(' ')} data-testid='stepper-step'>
      {children}
    </div>
  )
}

export default feedContextInProps(StepperStep, StepperContext)
