import React, { PureComponent } from 'react'

class Countdown extends PureComponent {
  constructor (props) {
    super(props)

    this.calculateCountdown = this.calculateCountdown.bind(this)
    this.stop = this.stop.bind(this)
    this.state = { days: 0, hours: 0, min: 0, sec: 0 }
  }

  componentDidMount () {
    const { date } = this.props
    this.interval = setInterval(() => {
      const timeLeft = this.calculateCountdown(date)
      this.setState(timeLeft || { days: 0, hours: 0, min: 0, sec: 0 })
      if (!timeLeft) this.stop()
    }, 1000)
  }

  componentWillUnmount () {
    this.stop()
  }

  calculateCountdown (endDate) {
    let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000
    if (diff <= 0) return false
    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0
    }
    if (diff >= (365.25 * 86400)) { // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400))
      diff -= timeLeft.years * 365.25 * 86400
    }
    if (diff >= 86400) { // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400)
      diff -= timeLeft.days * 86400
    }
    if (diff >= 3600) { // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600)
      diff -= timeLeft.hours * 3600
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60)
      diff -= timeLeft.min * 60
    }
    timeLeft.sec = diff

    return timeLeft
  }

  stop () {
    clearInterval(this.interval)
  }

  addLeadingZeros (value = '') {
    return value.toString().length === 1 ? `0${value}` : value
  }

  render () {
    const { days, hours, min, sec } = this.state

    return (
      <div className='ta-countdown'>
        {days > 0 &&
          <span className='ta-countdown__days' data-testid='countdown-days'>{this.addLeadingZeros(days)}:</span>
        }
        <span className='ta-countdown__hours' data-testid='countdown-hours'>{this.addLeadingZeros(hours)}:</span>
        <span className='ta-countdown__min' data-testid='countdown-min'>{this.addLeadingZeros(min)}:</span>
        <span className='ta-countdown__sec' data-testid='countdown-sec'>{this.addLeadingZeros(sec)}</span>
      </div>
    )
  }
}

export default Countdown
