import {
  sortByOrderIndex
} from '../../../Utils'

import {
  usersTransform,
  usersListTransform,
  usersFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  pendingList: true,
  pendingForm: true,
  pendingPreview: true,
  selected: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  // Reset
  usersReset: () => dispatch(actions.USERS_RESET),

  // List
  usersListGet: () => dispatch(actions.USERS_LIST_GET),
  usersListPopulate: ({ users }) => dispatch(actions.USERS_LIST_POPULATE, { users: usersListTransform(users) }),
  usersListPopulateSearch: ({ users }) => dispatch(actions.USERS_LIST_POPULATE_SEARCH, { users: usersListTransform(users) }),
  usersListReady: () => dispatch(actions.USERS_LIST_READY),
  usersListPending: () => dispatch(actions.USERS_LIST_PENDING),

  // Search
  usersSearch: () => dispatch(actions.USERS_LIST_SEARCH),

  // One
  usersUpdate: user => dispatch(actions.USERS_UPDATE, usersTransform(user)),
  usersUpdated: user => dispatch(actions.USERS_UPDATED, user),
  usersDelete: id => dispatch(actions.USERS_DELETE, id),
  usersDeleted: id => dispatch(actions.USERS_DELETED, id),
  usersRemoveDeleted: id => dispatch(actions.USERS_REMOVE_DELETED, id),
  usersDisabled2FA: id => dispatch(actions.USERS_DISABLED_TWO_FA, id),

  // Preview
  usersPreviewGet: ({ id, forceFetch }) => dispatch(actions.USERS_PREVIEW_GET, { id, forceFetch }),
  usersPreviewPopulate: user => dispatch(actions.USERS_PREVIEW_POPULATE, user),

  // Form
  usersFormGet: id => dispatch(actions.USERS_FORM_GET, id),
  usersFormPopulate: user => {
    handlers.formSet('users', usersFormTransform(user))
    handlers.usersFormReady()
  },
  usersFormReady: () => dispatch(actions.USERS_FORM_READY),
  usersFormSave: (user, scrollToError = () => { }) => dispatch(actions.USERS_FORM_SAVE, { user, scrollToError })
})

// Reset
reducer.USERS_RESET = state => reducer.initialState

// Search
reducer.USERS_LIST_SEARCH = (state) => ({
  ...state,
  pendingList: false
})

// List
reducer.USERS_LIST_GET = (state) => ({
  ...state,
  pendingList: true
})
reducer.USERS_LIST_POPULATE = (state, { users = [] }) => ({
  ...state,
  list: users.sort((a, b) => a - b),
  searchList: [],
  pendingList: false
})
reducer.USERS_LIST_POPULATE_SEARCH = (state, { users = [] }) => ({
  ...state,
  searchList: users.sort((a, b) => a - b),
  pendingList: false
})
reducer.USERS_LIST_READY = state => ({
  ...state,
  pendingList: false
})
reducer.USERS_LIST_PENDING = state => ({
  ...state,
  pendingList: true
})

// One
reducer.USERS_UPDATE = (state, user) => {
  if (!user) return state
  return {
    ...state,
    list: state.list
      .filter(item => item.id !== user.id)
      .concat([{ ...user, isUpdated: true }])
      .sort(sortByOrderIndex)
  }
}
reducer.USERS_UPDATED = (state, user) => {
  if (!user) return state
  const list = [...state.list]
  const index = list.findIndex(item => item.id === user.id)
  if (index < 0) return state
  list[index] = { ...list[index], isUpdated: false }
  return {
    ...state,
    list
  }
}
reducer.USERS_DELETE = state => ({
  ...state,
  pendingPreview: true
})
reducer.USERS_DELETED = (state, id) => {
  const list = [...state.list]
  const index = list.findIndex(item => item.id === id)
  if (index < 0) return state
  list[index] = { ...list[index], isDeleted: true }
  return {
    ...state,
    list
  }
}
reducer.USERS_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

// Preview
reducer.USERS_PREVIEW_GET = state => ({
  ...state,
  pendingPreview: true
})
reducer.USERS_PREVIEW_POPULATE = (state, id) => {
  if (!id) return { ...state, pendingPreview: false, selected: null }
  return {
    ...state,
    pendingPreview: false,
    selected: id
  }
}

// Form
reducer.USERS_FORM_GET = state => ({
  ...state,
  pendingForm: true
})
reducer.USERS_FORM_READY = state => ({
  ...state,
  pendingForm: false
})
reducer.USERS_FORM_SAVE = state => ({
  ...state,
  pendingForm: true
})

export default reducer
