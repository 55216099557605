import React, { Component } from 'react'
// import { LoadScript } from '@react-google-maps/api'
import { connect, selectors } from '../../Store'
import { ErrorBoundary, Loader } from '../../Common'
import { App, Staging } from '../../Beauties'
import { SmallScreenOverlayWrapper } from '../../Beasts'
// import { GOOGLE_MAPS_API_KEY } from '../../Settings'
import 'moment/min/locales'

// const libraries = ['geometry', 'places', 'drawing']

class AppWrapper extends Component {
  render () {
    let {
      isAuthenticated,
      isPending,
      pendingLanguage,
      hasTokens,
      router
      // company
    } = this.props
    pendingLanguage = !!pendingLanguage
    router = router || {}
    pendingLanguage = !!pendingLanguage
    hasTokens = !!hasTokens
    isAuthenticated = !!isAuthenticated
    router = router || {}
    // company = company || {}
    let { name, props: routerProps } = router || {}
    routerProps = routerProps || {}
    const { flow } = routerProps || {}
    // const styles = { height: '100%' }
    // const { locale } = company

    return (
      <ErrorBoundary>
        {(['development', 'staging'].includes(process.env.REACT_APP_ENV) &&
          <Staging />
        )}
        {(!['development', 'staging'].includes(process.env.REACT_APP_ENV) &&
          <SmallScreenOverlayWrapper pendingLanguage={pendingLanguage} />
        )}
        {/* <LoadScript
          googleMapsApiKey={GOOGLE_MAPS_API_KEY}
          loadingElement={<div style={styles} />}
          libraries={libraries}
          language={locale}
        > */}
        <App
          isAuthenticated={isAuthenticated}
          hasTokens={hasTokens}
          route={name}
          flow={flow}
          pendingLanguage={pendingLanguage}
          isPending={isPending}
        />
        {/* </LoadScript> */}
        <Loader active hideBackground />
      </ErrorBoundary>
    )
  }
}

const maps = state => ({
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  isAuthenticated: !!selectors.accountFieldSelector(state, { field: 'userId' }),
  isPending: selectors.appFieldSelector(state, { field: 'isPending' }),
  hasTokens: !!state.auth.tokens,
  route: selectors.routerFieldSelector(state, { field: 'name' }),
  router: selectors.routerSelector(state),
  company: selectors.companySelector(state)
})

export default connect(maps)(AppWrapper)
