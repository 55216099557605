import React, { Component } from 'react'
import { connect, handlers, store, selectors } from '../../Store'
import { Login } from '../../Beauties'
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../../Settings'

class LoginWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onLanguageChange = this.onLanguageChange.bind(this)
  }

  componentDidMount () {
    const { router } = this.props
    const { query } = router || {}
    let { locale } = query || {}
    locale = SUPPORTED_LOCALES.includes(locale) ? locale : null
    handlers.loginFormPopulate(locale)
  }

  onSubmit (cb) {
    const form = store.getState().forms.login
    handlers.loginWithEmailAndPassword(form, (errors) => {
      cb && cb(errors)
    })
  }

  onLanguageChange () {
    const { router } = this.props
    const { query } = router || {}
    const {
      redirect,
      locale: routerLocale,
      companyId,
      externalCompanyId,
      accountId,
      externalAccountId,
      enterpriseSlug,
      enterpriseId,
      customerId,
      externalCustomerId
    } = query || {}
    const form = store.getState().forms.login
    const params = [`locale=${form.language.value || routerLocale}`]
    if (redirect) params.push(`redirect=${redirect}`)
    if (companyId) params.push(`companyId=${companyId}`)
    if (externalCompanyId) params.push(`externalCompanyId=${externalCompanyId}`)
    if (accountId) params.push(`accountId=${accountId}`)
    if (externalAccountId) params.push(`externalAccountId=${externalAccountId}`)
    if (enterpriseSlug) params.push(`enterpriseSlug=${enterpriseSlug}`)
    if (enterpriseId) params.push(`enterpriseId=${enterpriseId}`)
    if (customerId) params.push(`customerId=${customerId}`)
    if (externalCustomerId) params.push(`externalCustomerId=${externalCustomerId}`)
    handlers.navigateToPath(`/login?${params.join('&')}`)
  }

  onResendVerificationClick () {
    const form = store.getState().forms.login
    const email = form.email && form.email.value
    handlers.authEmailVerificationSend(email)
  }

  togglePasswordFieldType () {
    const form = store.getState().forms.login
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('login', { password })

    return password.type
  }

  render () {
    const {
      pending,
      pendingOneTimeToken,
      pendingLanguage,
      emailWarningStatus,
      userTemporaryBlockedStatus,
      passwordFieldType,
      router
    } = this.props

    return (
      <Login
        pendingLanguage={pendingLanguage}
        pending={pending || pendingOneTimeToken}
        passwordFieldType={passwordFieldType || 'password'}
        emailWarningStatus={emailWarningStatus}
        userTemporaryBlockedStatus={userTemporaryBlockedStatus}
        onResendVerificationClick={this.onResendVerificationClick}
        onSubmit={this.onSubmit}
        togglePasswordFieldType={this.togglePasswordFieldType}
        onLanguageChange={this.onLanguageChange}
        locale={router.query.locale || DEFAULT_LOCALE}
      />
    )
  }
}

const maps = state => ({
  router: selectors.routerSelector(state),
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  pending: state.auth.pendingForm,
  pendingOneTimeToken: state.auth.pendingOneTimeToken,
  emailWarningStatus: state.auth.emailWarningStatus,
  userTemporaryBlockedStatus: state.auth.userTemporaryBlockedStatus,
  passwordFieldType: selectors.formFieldPropertySelector(state, { formName: 'login', name: 'password', property: 'type' })
})

export default connect(maps)(LoginWrapper)
