import React from 'react'
import { Price, t } from '../../Common'

const ChartLegendVertical = props => {
  const { payload, isBookingsStat, currency } = props

  return (
    <ul className='ta-chart__vertical-legend' data-testid='chart-legend-vertical'>
      {payload.map((entry, index) => {
        const { payload: entryPayload } = entry || {}
        let description = entryPayload.value
        const { totalBookings } = entryPayload || {}
        if (isBookingsStat) description = `${entryPayload.value} ${t('global.bookings')}`
        if (currency) description = <Price currency={currency} price={entryPayload.value} />
        if (currency && totalBookings) {
          description =
            <>
              <Price currency={currency} price={entryPayload.value} /> {t('statistics.fromBookings', [{ key: 'COUNT', value: totalBookings }])}
            </>
        }
        return (
          <li key={index}>
            {entry.color &&
              <div className='ta-chart__vertical-legend__color' style={{ background: entry.color }} />
            }
            <p className='ta-chart__vertical-legend__value'>{entry.value}</p>
            <p className='ta-chart__vertical-legend__color__value--text'>{description}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default ChartLegendVertical
