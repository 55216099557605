import React from 'react'
import { store } from '../../Store'

const Price = props => {
  const countries = (store.getState().staticData && store.getState().staticData.countries) || []
  const DEFAULT_LOCALE = (store.getState().account && store.getState().account.locale) || 'en-gb'
  const countryCode = DEFAULT_LOCALE.split('-')[1]
  const country = countries.find(item => item.code.toLowerCase() === countryCode) || {}
  const { currency: countryCurrency } = country || {}
  const { code: currencyCode } = countryCurrency || {}
  const DEFAULT_CURRENCY = currencyCode || ''

  const {
    currency = DEFAULT_CURRENCY,
    locale = DEFAULT_LOCALE,
    price = 0,
    digits,
    currencyStyle = 'currency', // decimal / currency / percent
    className
  } = props
  const options = {
    style: currencyStyle,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
    currency: currency && currency.toUpperCase(),
    currencyDisplay: 'symbol'
  }
  if (!Intl) {
    window.alert('Browser support for Intl is missing.')
    return
  }
  const formatedPrice = new Intl.NumberFormat(locale, options).format(price)
  const classNames = []
  if (className) classNames.push(className)

  return <span className={classNames.join(' ')} data-testid='price'>{formatedPrice}</span>
}

export default Price
