import { handlers, store } from '../../../Store'
import { jsonDeepEqual } from '../../../Utils'
import { q } from '../../API'

export const accountUpdated = updatedAccount => {
  // TODO put settings in the store
  if (!updatedAccount || !updatedAccount.userId) return
  const state = store.getState()
  const { account } = state || {}

  const { rules } = account || {}
  const { rules: updatedRules } = updatedAccount
  const isEqualPermissions = jsonDeepEqual(rules, updatedRules)

  if (!isEqualPermissions || (updatedAccount.isAdmin !== account.isAdmin)) {
    handlers.agentPermissionsChanged()
  }

  handlers.accountPopulate(updatedAccount)
}

export const accountDeleted = async companyId => {
  if (!companyId) return
  const { company = {} } = store.getState()
  // TODO: add message the account was deleted
  if (company.id === companyId) {
    handlers.logout()
  }
  handlers.accountDeleted(companyId)
  const { accounts = [] } = store.getState().account
  // No accounts
  if (accounts.length === 0) handlers.logout()
  // TODO: CASES
  // 1. account is the company we are logged in at the moment
  // - remove company from state
  // - 0 accounts => logout
  // - more then 0 => show the list of accounts + message account deleted
  // 2. account is other compnay(not selected one)
  // - remove from accout.accounts array
}

export const accountEnterpriseUpdated = async (enterpriseId) => {
  const state = store.getState()
  const { account } = state || {}
  const { enterprise } = account || {}

  if (enterpriseId !== enterprise.id) return
  handlers.settingsGet()
  const result = (await q('getAccount')) || {}
  handlers.settingsReady()
  if (result.error) return
  const { accounts = [] } = result
  accountUpdated({ ...result, accounts })
}
