import React from 'react'
import {
  Button,
  DateInput,
  HintText,
  Radio,
  t,
  Title,
  Row,
  Col,
  Checkbox,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  Spinner
} from '../../../Common'
import './BookingForm.css'
import { BookingFormDependenciesWrapper } from '../../../Beasts'

const BookingFormSlotPreferences = ({
  minDate,
  isScheduleForm,
  onSubmit,
  onAllowanceTypeClick,
  allowBookingWithinWorkingTimes,
  onIntervalChangeAddon,
  pendingBranches,
  pendingTransformation
}) => {
  return (
    <>
      {(pendingBranches || pendingTransformation) && (
        <div className='ta-booking_form-spinner-container'>
          <Spinner />
        </div>
      )}
      {(!pendingBranches && !pendingTransformation &&
        <>
          <BookingFormDependenciesWrapper isScheduleForm={isScheduleForm} />
          <Title label={t('booking.slotPreferences.preferredTime.title')} isCompact />
          {/* {!isScheduleForm && (
           <Row>
             <Col>
               <Checkbox
                 name='keepExistingResource'
                 label={t('scheduleBooking.keepExistingResource.label')}
                 hideError
               />
             </Col>
           </Row>
         )} */}
          <Row>
            <Col>
              <Radio
                name='allowanceType'
                label={t('scheduleBooking.withinWorkingTimes.label')}
                value='WORKING'
                hideError
                onChangeAddon={onAllowanceTypeClick}
                disabled={!allowBookingWithinWorkingTimes}
              />
              <HintText>{t('scheduleBooking.withinWorkingTimes.hint')}</HintText>
            </Col>
          </Row>
          <Row>
            <Col>
              <Radio
                name='allowanceType'
                label={t('scheduleBooking.withinBookingTimes.label')}
                value='BOOKING'
                hideError
                onChangeAddon={onAllowanceTypeClick}
                disabled={!allowBookingWithinWorkingTimes}
              />
              <HintText>{t('scheduleBooking.withinBookingTimes.hint')}</HintText>
            </Col>
          </Row>
          <Row>
            <Col>
              <Title label={t('booking.slotPreferences.preferredDate.title')} isCompact />
              <DateInput
                name='from'
                label={t('calendar.form.widgetBooking.preferredTimeframe.dateFrom.label')}
                minDate={minDate}
                icon='calendar'
                clearable
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Title label={t('booking.slotPreferences.preferredTime.title')} isCompact />
              <Row>
                <Col>
                  <HoverPopup>
                    <HoverPopupContent position='right' autoSize>
                      04:00 - 12:00
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox
                        name='timeIntervalMorning'
                        label={t('scheduleBooking.preferredTime.morning.label')}
                        hideError
                        onChangeAddon={onIntervalChangeAddon}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HoverPopup>
                    <HoverPopupContent position='right' autoSize>
                      12:00 - 15:00
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox
                        name='timeIntervalNoon'
                        label={t('scheduleBooking.preferredTime.earlyAfternoon.label')}
                        hideError
                        onChangeAddon={onIntervalChangeAddon}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HoverPopup>
                    <HoverPopupContent position='right' autoSize>
                      15:00 - 17:00
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox
                        name='timeIntervalAfternoon'
                        label={t('scheduleBooking.preferredTime.lateAfternoon.label')}
                        hideError
                        onChangeAddon={onIntervalChangeAddon}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <HoverPopup>
                    <HoverPopupContent position='right' autoSize>
                      17:00 - 23:55
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <Checkbox
                        name='timeIntervalEvening'
                        label={t('scheduleBooking.preferredTime.evening.label')}
                        hideError
                        onChangeAddon={onIntervalChangeAddon}
                      />
                    </HoverPopupTrigger>
                  </HoverPopup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Button
            block
            primary
            topOffset
            type='button'
            onClick={onSubmit}
          >
            {t('scheduleBooking.slotPreferences.button.label')}
          </Button>
        </>
      )}
    </>
  )
}

export default BookingFormSlotPreferences
