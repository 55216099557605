import { connect, selectors } from '../../Store'

const Offline = props => {
  const { connection, children } = props

  return connection === false
    ? children
    : null
}

const maps = state => ({
  connection: !!selectors.appFieldSelector(state, { field: 'isConnected' })
})

export default connect(maps)(Offline)
