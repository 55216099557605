import { q } from '../../API'
import { payloads$, actions, handlers, store, globalActions, selectors } from '../../../Store'
import { getQueryName, groupCustomerFieldsByCategory, uniqueIdFilter } from '../../../Utils'
import {
  customerTransform,
  customerFormValidate,
  customerSaveTransform,
  customersListTransform,
  customerFormServerErrorsTransform,
  customerPreviewBookingsErrorsTransform,
  customerPreviewErrorsTransform
} from './utils'
import { t } from '../../../Common'
import { customerUpdated } from './subscriptions'
import { filterBranches } from '../Bookings/utils'

const limit = 10

const getCustomersFilterFromState = state => {
  const shouldUseMongoCustomerSearch = selectors.companySettingsFieldSelector(state, { field: 'shouldUseMongoCustomerSearch' })
  const customersFilters = state.forms.booking ? state.forms.booking : {}
  let { search, useExactMatchSearch, tags } = customersFilters
  search = search || {}
  const value = search.value || ''
  const filter = {
    name: value,
    tagIds: (tags?.value && [tags?.value]) || null
  }
  if (shouldUseMongoCustomerSearch) filter.useExactMatchSearch = useExactMatchSearch?.value === 'exact'
  // TODO find better validation for ID in case this fails
  if (value.length === 24 && value[0] === '5') {
    delete filter.name
    filter.ids = [value]
  }
  return filter
}

const getCustomersPageFromState = state => {
  return state.forms.booking ? state.forms.booking.page || 1 : 1
}
// Global actions

let isLoading = false
globalActions.populateCustomers = async ({ page, isLoadMore }) => {
  if (isLoading && !isLoadMore) return
  isLoading = true
  const state = store.getState()
  const filter = getCustomersFilterFromState(state)
  page = page || getCustomersPageFromState(state)
  const fieldsCategories = await globalActions.populateCustomerFieldsAndCategories()
  const data = await q(getQueryName('getEnterpriseCallCentreCustomers'), { filter, pagination: { limit, page } })
  handlers.customersListOptionsChange({ page, lastPage: !data.length || (data.length < limit) })
  handlers.customersListPopulate(customersListTransform(data || [], fieldsCategories), !!isLoadMore, 0)
  isLoading = false
  return data

  // TODO: pagination
}

globalActions.transformGlobalServiceId = async (id, companyId, region) => {
  const data = await q('getEnterpriseCallCentreGlobalTransformations', {
    options: {
      localServiceIds: [id],
      companyId,
      region
    }
  })
  const { services } = data || {}
  return {
    ...services[0],
    id
  }
  // combinationIdsWithBookings[id][0].combination.serviceId = serviceId
}

globalActions.customerEventResourcesGet = async eventId => {
  if (!eventId) return
  const state = store.getState()
  const {
    companyId,
    resourceIds,
    combination
  } = selectors.customerEventSelector(state, { id: eventId }) || {}
  const ids = [...new Set(combination ? combination?.events?.flatMap?.(ev => ev.resourceIds) : resourceIds)]
  const resources = await q('getResourcesCallCentreBranch', { filter: { ids }, companyId })
  if (resources?.error) return
  const resourceCategoryIds = resources.map(({ categoryId }) => categoryId)
  const resourceCategories = await q('getResourceCategoriesCallCentreBranch', { filter: { ids: resourceCategoryIds }, companyId })
  if (resourceCategories?.error) return
  handlers.customerEventResourcesPopulate({ eventId, resources, resourceCategories })

  return { resources, resourceCategories }
}

globalActions.combineEvents = async events => {
  const state = store.getState()
  const { services } = state
  const { list: servicesList } = services
  const bookings = { list: [] }
  events.forEach(event => {
    event.intervals && event.intervals.forEach(interval => {
      const booking = {
        ...event,
        interval,
        batchCount: (!!event.isBatch && event.durationsPattern && (event.durationsPattern.length + 1) / 2) || 0,
        from: interval.from,
        resources: [],
        dataFields: globalActions
          .prepareCustomerFieldsWithValues(event.fields)
          .filter(item =>
            item.value !== null &&
            item.value !== ''
          ) || []
      }
      // delete booking.intervals
      bookings.list.push(booking)
    })
  })
  const combinationIdsWithBookings = bookings.list.reduce((combinationIds, booking) => {
    if (booking.combinationId) {
      if (!combinationIds[booking.combinationId]) {
        combinationIds[booking.combinationId] = [booking]
      } else {
        (combinationIds[booking.combinationId] || []).push(booking)
      }
    }
    return combinationIds
  }, {})

  const firstEventsOfCombinations = Object.keys(combinationIdsWithBookings).map(combinationId => combinationIdsWithBookings[combinationId][0]) // the event that starts earliest
  firstEventsOfCombinations.forEach(event => {
    let { id, combination } = event
    combination = combination || {}
    const {
      events: combinationEvents,
      startDate: combinationStartDate,
      title: combinationTitle
    } = combination
    const combinationEventsDuration = combinationEvents.reduce((acc, event) => acc + event.duration, 0)
    const indexToInsertCombinationAt = bookings.list.findIndex(booking => booking.id === id)
    const combinationBookings = combinationIdsWithBookings[event.combinationId] || []
    // remove events part of combination and put single event which has combination data in it

    bookings.list[indexToInsertCombinationAt] = {
      ...event,
      serviceId: event.combination.serviceId,
      service: servicesList.find(({ id }) => id === event.serviceId),
      title: combinationTitle,
      duration: combinationEventsDuration,
      interval: {
        ...event.interval,
        from: combinationStartDate
      },
      combinationBookings
    }
  })
  return bookings
}

// List
payloads$(actions.CUSTOMERS_LIST_GET)
  .subscribe(async ({ page, isLoadMore }) => {
    setTimeout(() => globalActions.populateCustomers({ page, isLoadMore }), 0)
  })

// Update
payloads$(actions.CUSTOMER_UPDATE)
  .subscribe(async customer => {
    if (!customer) return
    if ((customer.allowedBranchIds || []).length > 0) {
      // no need to get services again if no branch ids on the customer
      await handlers.servicesListGet({ allowedBranchIds: customer.allowedBranchIds })
    }
    setTimeout(() => handlers.customerUpdated(customer), 2000)
  })

// Preview
payloads$(actions.CUSTOMER_PREVIEW_GET)
  .subscribe(async id => {
    if (!id) return
    await globalActions.populateCustomerFieldsAndCategories()
    const customer = await q(getQueryName('getEnterpriseCallCentreCustomer'), { id })
    const { error } = customer || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.customersPreviewMessageSet(customerPreviewErrorsTransform(error))
      handlers.customerPreviewPopulate()
      return
    }

    const state = store.getState()
    const customers = state.customers
    const customersList = customers.list || []
    const selectedCustomer = customers.selected || {}
    const bookingCustomer = state.forms.booking.bookingCustomer || {}
    const { value: bookingCustomerValue } = bookingCustomer || {}
    // Save customer in "selected", because the list change depends on sort
    // and on refresh the record can be missing if selected from page > 1

    if (selectedCustomer.id === id && (!bookingCustomerValue || !bookingCustomerValue.isUpdatedLocally)) {
      handlers.customerPreviewPopulate(customerTransform(customer))
      handlers.formFieldsUpdate('booking', { bookingCustomer: { value: customer } })
    }

    if (customersList.length) {
      handlers.customersListGet()
    }
  })

// Form
payloads$(actions.CUSTOMER_FORM_GET)
  .subscribe(async ({ id }) => {
    await globalActions.populateCustomerFieldsAndCategories()
    const state = store.getState()
    const { branches, forms } = state || {}
    let { list: branchesList } = branches || {}
    const { booking } = forms || {}
    const { transformedCustomer } = booking || {}
    const { value: transformedCustomerValue } = transformedCustomer || {}
    const isCustomerTransformed = transformedCustomerValue ? id === transformedCustomerValue.internalId : false
    branchesList = branchesList || []
    if (!id) {
      const fields = globalActions.prepareCustomerFieldsForForm()
      handlers.customerFormPopulate({
        customer: { fields },
        branches: branchesList
      })
      return
    }

    const customer = await q(getQueryName('getEnterpriseCallCentreCustomer'), { id })
    if (isCustomerTransformed) {
      id = customer.fields = transformedCustomerValue.fields
    }
    const { error } = customer || { error: {} }
    if (error) {
      handlers.navigateToPath(id ? `/customers/customers/${id}` : '/customers/customers')
      return
    }
    const formattedCustomer = {
      ...customer,
      fields: (isCustomerTransformed ? transformedCustomerValue : customerTransform(customer)).fields
    }

    const customerFieldsWithValues = globalActions.prepareCustomerFieldsWithValues(formattedCustomer.fields)
    handlers.customerFormPopulate({
      customer: {
        ...formattedCustomer,
        fields: globalActions.prepareCustomerFieldsForForm(customerFieldsWithValues)
      },
      branches: branchesList
    })
  })

// Save
payloads$(actions.CUSTOMER_FORM_SAVE)
  .subscribe(async ({ customer, scrollToError }) => {
    const state = store.getState()
    const { customerFields } = state || {}
    const { list: customerFieldsList } = customerFields || {}
    const errors = customerFormValidate(customer, customerFieldsList)
    if (errors.length) return setCustomerFormSaveErrors(errors, scrollToError)
    const savedCustomer = await q(getQueryName('saveEnterpriseCallCentreCustomer'), customerSaveTransform(customer))
    const { error } = savedCustomer || { error: {} }
    if (error) return setCustomerFormSaveErrors(customerFormServerErrorsTransform(error), scrollToError)
    // Handle form addon extra logic
    let { selected } = selectors.customersSelector(state) || {}
    selected = selected || {}
    handlers.customerUpdate({ ...savedCustomer })
    handlers.customerPreviewPopulate(savedCustomer)
    if (customer.id && savedCustomer && selected.fullName !== savedCustomer.fullName) {
      handlers.bookingFormCustomerUpdate(savedCustomer)
    }
    handlers.customerFormReady()
    handlers.formFieldsUpdate('booking', {
      isCustomerAdd: { value: false },
      isCustomerEdit: { value: false },
      bookingCustomer: { value: savedCustomer },
      ...(!customer.id ? { progress: { value: 1 } } : {})
    })
  })

const setCustomerFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('customers', errors)
  scrollToError && scrollToError(errors)
  handlers.customerFormReady()
}

payloads$(actions.CUSTOMER_EVENT_FORM_GET)
  .subscribe(async () => {
    const options = {
      cancelled: t('customers.list.cancelled'),
      past: t('customers.list.past'),
      upcoming: t('customers.list.upcoming')
    }
    handlers.customerEventFormPopulate(options)
  })

// Preview bookings
payloads$(actions.CUSTOMER_BOOKINGS_GET)
  .subscribe(async (id) => {
    handlers.customerUpcomingBookingsPopulate({
      id,
      upcomingEvents: { list: [] },
      pendingUpcomingBookings: true,
      upcomingBookingsPage: 1
    })

    handlers.customerPastBookingsPopulate({
      id,
      pastEvents: { list: [] },
      pendingPastBookings: true,
      pastBookingsPage: 1
    })
    if (!id) {
      return
    }
    const state = store.getState()
    let { forms, customers, customerFields } = state
    forms = forms || {}
    const { list: customersList } = customers || {}
    const { list, categoriesList } = customerFields
    const selectedCustomer = (customersList || []).find(item => item.id === id)
    const fields = selectedCustomer.fields || []
    const filterByExternalId = []
    const filterByType = ['FILE']
    const parsedFields = globalActions.parseCustomerFieldsValues([...fields, ...[]])
    const customerFieldsWithValues = globalActions.prepareCustomerFieldsWithValues(parsedFields)
    const filteredCustomerFields = customerFieldsWithValues
      .filter(item => (![null, ''].includes(item.value) || !([null, '', undefined].includes(item.values) || (item.values && item.values.length === 0))) &&
        !filterByExternalId.includes(item.defaultId) &&
        !filterByType.includes(item.type)
      ) || []
    const avatarField = list.find(item => item.defaultId === 'avatar') || {}
    const isAvatarActive = avatarField.isActive && avatarField.displayOnHover
    const customerBookingFields = filteredCustomerFields.filter(item => !item.hasOverwrite)
    const customerCustomerFieds = filteredCustomerFields.filter(item => item.hasOverwrite)
    const groupedFields = groupCustomerFieldsByCategory(filteredCustomerFields, categoriesList)
    selectedCustomer.groupedFields = groupedFields
    selectedCustomer.groupedBookingFields = groupCustomerFieldsByCategory(customerBookingFields, categoriesList)
    selectedCustomer.groupedCustomerFields = groupCustomerFieldsByCategory(customerCustomerFieds, categoriesList)
    selectedCustomer.isAvatarActive = isAvatarActive
    const limit = 2
    const type = (forms.customerEvents && forms.customerEvents.type && forms.customerEvents.type.value) || ''
    if (!type) return
    const rawEvents = await q('getEnterpriseCallCentreUpcomingAndPastEventsByCustomer', {
      externalId: selectedCustomer.externalId,
      customerId: id,
      pagination: {
        page: 1,
        limit
      }
    })
    const { error } = rawEvents || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.customersPreviewBookingsMessageSet(customerPreviewBookingsErrorsTransform(error))
      return
    }
    const upcomingRawEvents = rawEvents.upcoming ? globalActions.transformEvents(rawEvents.upcoming) : []
    const pastRawEvents = rawEvents.past ? globalActions.transformEvents(rawEvents.past) : []
    const upcomingEvents = await globalActions.combineEvents(upcomingRawEvents)
    const pastEvents = await globalActions.combineEvents(pastRawEvents)

    handlers.customerUpcomingBookingsPopulate({
      id,
      upcomingEvents,
      isUpcomingBookingsLastPage: !(rawEvents.upcoming || []).length || (rawEvents.upcoming || []).length < limit,
      pendingUpcomingBookings: false,
      upcomingBookingsPage: 1
    })

    handlers.customerPastBookingsPopulate({
      id,
      pastEvents,
      isPastBookingsLastPage: !(rawEvents.past || []).length || (rawEvents.past || []).length < limit,
      pendingPastBookings: false,
      upcomingBookingsPage: 1
    })
  })

//  Preview bookings page increment
payloads$(actions.CUSTOMERS_BOOKINGS_PAGE_INCREMENT)
  .subscribe(async ({ type }) => {
    const state = store.getState()
    const { upcomingBookingsPage, pastBookingsPage, selected } = state.customers || {}
    const isUpcoming = type === 'upcoming'
    const queryName = isUpcoming ? 'getEnterpriseCallCentreUpcomingEventsByCustomer' : 'getEnterpriseCallCentrePastEventsByCustomer'
    const limit = 10
    const events = await q(queryName, {
      externalId: selected.externalId,
      customerId: selected.id,
      pagination: {
        page: isUpcoming ? upcomingBookingsPage : pastBookingsPage,
        limit
      }
    })
    const { error } = events || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.customersPreviewBookingsMessageSet(customerPreviewBookingsErrorsTransform(error))
      return
    }
    const filteredEvents = events ? globalActions.transformEvents(events) : []
    const bookings = await globalActions.combineEvents(filteredEvents)

    if (isUpcoming) {
      const { upcomingEvents } = selected || {}
      let { list: upcomingEventsList } = upcomingEvents
      upcomingEventsList = upcomingEventsList.concat(bookings.list).filter(uniqueIdFilter)
      handlers.customerUpcomingBookingsPopulate({
        id: selected.id,
        pendingUpcomingBookings: false,
        upcomingEvents: {
          ...selected.upcomingEvents,
          list: upcomingEventsList
        },
        isUpcomingBookingsLastPage: !events.length || events.length < limit,
        upcomingBookingsPage
      })
    } else {
      const { pastEvents } = selected || {}
      let { list: pastEventsList } = pastEvents
      pastEventsList = pastEventsList.concat(bookings.list).filter(uniqueIdFilter)
      handlers.customerPastBookingsPopulate({
        id: selected.id,
        pendingPastBookings: false,
        pastEvents: {
          ...selected.pastEvents,
          list: pastEventsList
        },
        isPastBookingsLastPage: !events.length || events.length < limit,
        pastBookingsPage
      })
    }
  })

// branches from selected service
payloads$(actions.CUSTOMER_SERVICES_BRANCHES_GET)
  .subscribe(async () => {
    const state = store.getState()
    let { customers, forms, services, account } = state
    account = account || {}
    let { enterprise } = account
    enterprise = enterprise || {}
    let { region } = enterprise
    region = region || ''
    services = services || {}
    let { list: servicesList } = services
    servicesList = servicesList || []
    customers = customers || {}
    forms = forms || {}
    let { booking } = forms
    booking = booking || {}
    let { selected: selectedCustomer } = customers
    let { service } = booking
    service = service || {}
    let { value: selectedService, selectedId: serviceId } = service || {}
    selectedService = selectedService || {}
    serviceId = serviceId || ''
    selectedCustomer = selectedCustomer || {}
    const filter = { globalCustomerId: selectedCustomer.internalId, globalServiceId: (selectedService && selectedService.id) || serviceId }
    const selectedServiceItem = servicesList?.find((service) => service.id === serviceId)
    const branches = await q('getEnterpriseCallCentreBranches', { region, filter }) || []
    const { allowedBranchIds: customerAllowedBranchIds, allowedBranchExternalIds: customerAllowedBranchExternalIds } = selectedCustomer || {}
    let { allowedBranchIds: serviceAllowedBranchIds, allowedBranchExternalIds: serviceAllowedBranchExternalIds } = selectedServiceItem || {}
    serviceAllowedBranchIds = serviceAllowedBranchIds || []
    serviceAllowedBranchExternalIds = serviceAllowedBranchExternalIds || []

    const branchOptions = branches
      .filter(({ id, externalId }) => (
        filterBranches(id, externalId, customerAllowedBranchIds, customerAllowedBranchExternalIds) && filterBranches(id, externalId, serviceAllowedBranchIds, serviceAllowedBranchExternalIds)
      ))
      .map(item => ({ value: item.id, rawValue: item, label: item.name }))
    handlers.customerPreviewPopulate({ ...selectedCustomer, branches: branchOptions })
  })

// Events
payloads$(actions.CUSTOMER_EVENT_RESOURCES_GET)
  .subscribe(globalActions.customerEventResourcesGet)

// Subscriptions
payloads$(actions.CUSTOMERS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    const state = store.getState()
    const selectedCustomerId = state?.customers?.selected?.id
    if (name === 'customerEnterpriseUpdated' && selectedCustomerId === id) customerUpdated(id)
  })
