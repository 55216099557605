import React from 'react'
import { Alert, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const PopupAgentsHelpBox = props => {
  return (
    <div className='ta-popup__agents-help-box'>
      <Alert noOffset noBorder label={t('agents.list.heading.helpTooltip.title')} theme='alert'>
        {dangerousHTML(t('agents.list.heading.helpTooltip.text'))}
      </Alert>
    </div>
  )
}

export default PopupAgentsHelpBox
