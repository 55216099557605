export const resourceFields = `{
  id
  userId
  createdAt
  updatedAt
  abbreviation
  ts
  name
  avatarUrl
  orderIndex
  isBookable
  hasOnlineWarning
  categoryId
  email
  color
  externalId
  totalFutureBookings
  account {
    isAdmin
    isCustom
    isOwner
    isBillingRead
    isBillingWrite
    isConnected
    roles
    ts
    companyPermissionsGroupId
  }
  calendar {
    timezone
    threads
    enforceAllowanceType
  }
}`

export const resourceCategoryFields = `{
  id
  externalId
  internalId
  companyId
  orderIndex
  name
  translationKey
  isDefault
  isDependency
  isUpdatedLocally
  isGloballyDeleted
  resources {
    id
  }
  allowedBranchIds
  allowedBranchExternalIds
  locallyUpdatedBranches
  createdAt
  updatedAt
  ts
}`
