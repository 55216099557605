import {
  fileFields,
  customerFieldDownloadFileFields
} from './fields'

// Queries

export const downloadEnterpriseFile = `
  query downloadEnterpriseFile($key: String, $url: String) {
    downloadEnterpriseFile(key: $key, url: $url) ${fileFields}
  }
`

export const customerFieldDownloadFile = `
  query downloadFile($key: String!, $type: String, $fieldId: String!) {
    downloadFile(key: $key, type: $type, fieldId: $fieldId) ${customerFieldDownloadFileFields}
  }
`
