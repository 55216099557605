import { payloads$, actions, handlers, store, store$, selectors } from '../../../Store'
import { q } from '../../API'
import { map, distinctUntilChanged } from 'rxjs/operators'
import {
  loginServerErrorsTransform,
  loginFormValidate
} from './utils'

// Login with email and password
payloads$(actions.LOGIN_WITH_EMAIL_AND_PASSWORD)
  .subscribe(async ({ fields, scrollToError }) => {
    const errors = loginFormValidate(fields)
    if (errors.length) return setLoginErrors(errors, scrollToError)

    const tokens = await q('loginEnterpriseCallCentreAccountWithEmailAndPassword', {
      email: fields.email,
      password: fields.password,
      locale: fields.locale,
      longSession: fields.remember
    })
    const { error } = tokens

    if (error) return setLoginErrors(loginServerErrorsTransform(error), scrollToError)
    handlers.authTokensChange(tokens, true)
  })

const setLoginErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('login', errors)
  handlers.loginFormReady()
  scrollToError && scrollToError(errors)
}

// Login with one time token
payloads$(actions.LOGIN_WITH_ONE_TIME_TOKEN)
  .subscribe(async token => {
    const tokens = await q('getTokensWithOneTimeLoginToken', {
      authScope: 'ENTERPRISE_CALL_CENTRE',
      loginToken: token,
      longSession: false
    })
    const { error } = tokens
    if (error && error.code === 'Auth2FACodeRequired') {
      handlers.loginPendingDefaultSet()
      return
    }

    if (error) {
      setLoginErrors(loginServerErrorsTransform(error))
      handlers.loginWithOneTimeTokenErrorSet()
      return
    }
    setTimeout(() => handlers.authTokensChange(tokens), 1500)
  })

// Auth tokens change
payloads$(actions.AUTH_TOKENS_CHANGE)
  .subscribe(async tokens => {
    const { accessToken, refreshToken, sessionDuration, account, validateTwoFA } = tokens
    handlers.authTokensPopulate({
      accessToken,
      refreshToken,
      expires: (new Date()).getTime() + parseInt(sessionDuration, 10)
    })
    const isAccountUses2FA = account.auth2FAs && account.auth2FAs[0] && account.auth2FAs[0].isActive
    handlers.accountChanged({ account, showTwoFA: validateTwoFA && isAccountUses2FA })
  })

// Logout
payloads$(actions.LOGOUT)
  .subscribe(async ({ locale, oneTimeLoginToken, skipInvalidateSession }) => {
    const state = store.getState()
    const { router } = state
    const { query } = router || {}
    const {
      redirect,
      locale: routeLocale,
      redirect_uri: oAuthRedirectUrl,
      client_id: oAuthClientId,
      response_type: oAuthResponseType,
      state: oAuthState,
      route
    } = query || {}

    if (!skipInvalidateSession) {
      // Clear the token from DB
      await q('invalidateSession')
    }
    handlers.tokensReset()
    if (oneTimeLoginToken) handlers.loginWithOneTimeToken(oneTimeLoginToken)

    // Reset to initial state
    handlers.accountReset()
    handlers.companyReset()
    handlers.settingsReset()
    handlers.overlayHide()
    handlers.oAuthPopulate({ accounts: null })
    // handlers.settingsSecurityHide()

    let loginUrl = route || '/login'
    let redirectLocale = routeLocale || locale || selectors.appLocaleSelector(state)
    const params = []
    if (redirectLocale) params.push(`locale=${redirectLocale}`)
    if (redirect && !redirect.includes('logout')) params.push(`redirect=${redirect}`)
    if (oAuthRedirectUrl) params.push(`redirect_uri=${oAuthRedirectUrl}`)
    if (oAuthClientId) params.push(`client_id=${oAuthClientId}`)
    if (oAuthResponseType) params.push(`response_type=${oAuthResponseType}`)
    if (oAuthState) params.push(`response_type=${oAuthState}`)
    if (params.length > 0) loginUrl = `${loginUrl}?${params.join('&')}`
    handlers.loginFormPopulate()
    handlers.navigateToPath(loginUrl)
  })

// Login email change
store$
  .pipe(
    map(state => state.forms.login.email ? state.forms.login.email.value : ''),
    distinctUntilChanged()
  ).subscribe(async () => {
    const state = store.getState()
    const { emailWarningStatus } = state.auth || {}
    if (['warning', 'success'].includes(emailWarningStatus)) {
      const formEmail = state.forms.login.email || {}
      handlers.formFieldsUpdate('login', { 'email': { ...formEmail, errors: [], error: null } })
      handlers.loginFormEmailWarningSet()
    }
  })

// Resend verification email
payloads$(actions.AUTH_EMAIL_VERIFICATION_SEND)
  .subscribe(async email => {
    await q('sendEmailVerificationCode', { email, authScope: 'COMPANY' })
    handlers.loginFormEmailWarningSet('success')
    setTimeout(() => handlers.loginFormEmailWarningSet(), 4000)
  })

payloads$(actions.AUTH_TWO_FA)
  .subscribe(async ({ code, callback }) => {
    const tokens = await q('authenticate2FA', { code, type: 'OTP' })
    const { error } = tokens
    if (error && error.code === 'Auth2FACodeValidationFailed') {
      handlers.formErrorsSet('twoFA', [{ key: 'code', value: 'errors.twoFA.invalidToken' }])
      handlers.loginPendingDefaultSet()
    }
    if (error) return setLoginErrors(loginServerErrorsTransform(error))
    handlers.authTokensChange(tokens)
    handlers.twoFAFormPopulate()
    handlers.loginPendingDefaultSet()
    callback()
  })
