import momenttz from 'moment-timezone'

const getSlotFootfalls = (day, startTime, endTime, footfalls, options) => {
  const { companyTimezone } = options || {}
  let weekday = momenttz(day).isoWeekday() - 1
  weekday = weekday === 7 ? 0 : weekday + 1
  const footfall = footfalls[weekday]
  if (!footfall || !footfall.intervals || !footfall.intervals.length || !footfall.isActive) {
    return null
  }

  const { intervals } = footfall
  const overlapIntervals = intervals
    .filter(interval => {
      const intervalBegin = momenttz.tz(interval.begin, 'HH:mm', companyTimezone).tz(companyTimezone)
      const intervalEnd = momenttz.tz(interval.end, 'HH:mm', companyTimezone).tz(companyTimezone)

      return (
        (intervalBegin.isBefore(momenttz.tz(startTime, 'HH:mm', companyTimezone)) ||
        intervalBegin.format('HH:mm') === startTime) &&
        intervalEnd.isAfter(momenttz.tz(startTime, 'HH:mm', companyTimezone))
      ) || (
        intervalBegin.isBefore(momenttz.tz(endTime, 'HH:mm', companyTimezone)) &&
        intervalEnd.isAfter(momenttz.tz(endTime, 'HH:mm', companyTimezone))
      ) || (intervalBegin.isAfter(momenttz.tz(startTime, 'HH:mm', companyTimezone)) &&
        (intervalEnd.isBefore(momenttz.tz(endTime, 'HH:mm', companyTimezone)) || intervalEnd.format('HH:mm') === endTime)
      )
    })
  return overlapIntervals || []
}

export default (dates = [], duration, footfalls, options) => {
  if (!dates || !dates.length || !duration) {
    return false
  }
  const intervals = dates.reduce((result, date) => {
    const intervals = getSlotFootfalls(
      momenttz(date).format('YYYY-MM-DD'),
      momenttz(date).format('HH:mm'),
      momenttz(date).clone().add(duration, 'minutes').format('HH:mm'),
      footfalls,
      options
    )

    if (intervals && intervals.length) {
      result = result.concat(intervals)
    }

    return result
  }, [])

  if (!intervals || !intervals.length) {
    return false
  }

  const isOverlap = intervals.find(interval => interval.footfall === 'RED' || interval.footfall === 'ORANGE')

  return isOverlap || false
}
