import { sortByOrderIndex } from '../../../Utils'
import { courseListTransform } from './utils'

const reducer = {}

reducer.initialState = {
  list: [],
  categoriesList: [],
  reorderList: null,
  reorderCategoriesList: null,
  pendingList: true,
  pendingListCategory: false,
  pendingForm: true,
  pendingPreview: true,
  pendingCategory: null,
  pendingCategoryForm: true,
  pendingParticipantsExport: false,
  selected: null,
  dragging: {},
  messagePreview: null,
  messageList: null,
  hasOrderChanged: false
}

reducer.handlers = (dispatch, actions, handlers) => ({
  coursesListPopulate: ({ courses, courseCategories }) => dispatch(actions.COURSES_LIST_POPULATE, { courses: courseListTransform(courses), courseCategories })
})

reducer.COURSES_LIST_POPULATE = (state, { courses, courseCategories }) => ({
  ...state,
  list: (courses || []).sort(sortByOrderIndex),
  categoriesList: (courseCategories || []).sort(sortByOrderIndex),
  pendingList: false
})

export default reducer
