import React from 'react'
import {
  FontAwesome5,
  Link,
  Online,
  t
} from '../../Common'

const AgentsAddBtn = props => {
  const { available } = props
  const classNames = ['ta-agents__agent', 'ta-agents__agent__add-btn']
  available <= 0 && classNames.push('disabled')

  return (
    <div className={classNames.join(' ')}>
      <Online>
        <Link
          disabled={available <= 0}
          to='/agents/add'
        >
          <div className='ta-avatar ta-avatar__add-btn'>
            <FontAwesome5 icon='plus' />
          </div>
          <div className='ta-agents__agent-name'>{t('agents.add.addAnAgent')}</div>
        </Link>
        <div className='ta-agents__available-agents'>{available + ' ' + t('global.available')}</div>
      </Online>
    </div>
  )
}

export default AgentsAddBtn
