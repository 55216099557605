const compareTable = {
  // Global
  EmailRequired: 'errors.email.required',
  EmailNotValid: 'errors.email.invalid',
  EmailNotConfirmed: 'errors.email.notConfirmed',
  EmailNotVerified: 'errors.email.notVerified',
  ServerDown: 'errors.api.unavailable',

  // Global entities
  NoDeleteFromBranch: 'errors.globalEntities.branchDeleteOnly',

  // Auth
  TokenInvalid: 'errors.auth.token.invalid',
  OldAccount: 'errors.auth.OldAccount',
  UserTemporaryBlocked: 'errors.auth.userTemporaryBlocked',
  UserPermanentlyBlocked: 'errors.auth.userPermanentlyBlocked',
  LoginError: 'errors.loginError',
  SSOError: 'errors.ssoError',

  // Billing
  InvalidVAT: 'errors.billing.invalidVAT',
  BraintreeGenericError: 'errors.payments.transactionNotProcessed',
  ProcessorDeclinedError: 'errors.payments.cardRejected',
  // BraintreeGenericError: 'errors.payments.providerNotAvailable',

  // Booking
  RepeatLastDayNotValid: 'errors.booking.form.repeat.invalidLastDay',
  ResourcesDontMatchShape: 'errors.booking.form.resourcesDontMatchShape',
  DependencyNotAvailable: 'errors.dependencyNotAvailable',
  BookingOverlap: 'errors.bookingOverlap',
  BookingAllowanceConflict: 'errors.bookingAllowanceConflict',
  RecurringConflicts: 'errors.booking.form.recurringConflicts',
  CombinationConflicts: 'errors.booking.form.combinationConflicts',
  MaxChained100: 'errors.booking.form.repeat.maxChained100',
  MaxChained52: 'errors.booking.form.repeat.maxChained100',
  NoBookingsInThePast: 'errors.booking.form.past.noCreate',
  NoOldBookingEditInThePast: 'errors.booking.form.past.noEdit',
  BookingDurationLimitReached: 'errors.booking.form.limitReached',
  MaximumBookingExportCountReached: 'errors.csvExport.form.limitReached',
  MinimumBookingExportCountReached: 'bookings.export.noBookingsForPeriod',
  NoMoreCancelOrReschedule: 'errors.cancelOrReschedule.noMore',

  // User
  PasswordMandatory: 'errors.password.required',
  PasswordWeak: 'errors.password.weak',
  PasswordInvalid: 'errors.password.invalid',
  NoScopeForUser: 'errors.user.noScope', // Booker or Enterprise account. No company yet.
  UserNotFound: 'errors.user.notFound',
  EmailChangeInProcess: 'errors.user.emailChangeInProcess',
  SameEmail: 'errors.user.sameEmail',
  EmailExists: 'errors.user.emailExist',

  // Account
  AccountNotApproved: 'errors.account.notApproved',
  AccountDisabled: 'errors.account.disabled',

  // Services
  ExternalIdConflicts: 'errors.externalId.exists',
  NoCalendarWithoutEnterprisePlan: 'errors.plan.needUpgrade',
  CombinationServicesNotFound: 'errors.serviceCombination.serviceDeleted',
  ServiceNotFoundOrStalled: 'errors.serviceReschedule.serviceDeleted',

  // Settings
  LocalResourceCategoryNotFound: 'errors.publicHolidays.localResourceCategoryNotFound',
  MissingResources: 'errors.publicHolidays.atLeastOneResourceRequired',

  // Offer
  OffersConflict: 'errors.offerConflict',
  OffersConflictServices: 'errors.offerConflictServices',
  OffersConflictCourses: 'errors.offerConflictCourses',
  IncorrectInterval: 'errors.incorrectInterval',

  // Customers
  FieldsMandatory: 'errors.customers.fillMandatoryFields',
  CustomerExistsUniqueKey: 'errors.customers.alreadyExists',
  TagGDPRMax3Span: 'errors.frequency.lessThanThreeYears',

  // Resources
  NameRequired: 'errors.resources.categoryName.required',
  InvalidAbbreviation: 'errors.resources.abbreviation.invalid',
  LocalCustomerFieldNotFound: 'errors.customers.LocalCustomerFieldNotFound',

  // CustomerFields
  NoServiceAndCourse: 'errors.customerFields.noServiceOrCourse',
  MissingOrInvalidPhone: 'errors.api.missingOrInvalidPhone',

  // Shiftplan
  Overlapping: 'errors.shiftplanOverlap',

  // ACL Subscription plans
  PreparationTimeLimitPremium: 'errors.subscriptions.preparationTimeLimitPremium',
  FollowUpTimeLimitPremium: 'errors.subscriptions.followUpTimeLimitPremium',
  MaxParticipantsIncreaseLimitPremium: 'errors.subscriptions.maxParticipantsIncreaseLimitPremium',
  ExtraParticipantsIncreaseLimitPremium: 'errors.subscriptions.extraParticipantsIncreaseLimitPremium',
  DailyResourceLimitPremium: 'errors.subscriptions.dailyResourceLimitPremium',
  WeeklyResourceLimitPremium: 'errors.subscriptions.weeklyResourceLimitPremium',
  AddingParticipantsLimitPremium: 'errors.subscriptions.addingParticipantsLimitPremium',
  GroupBookingsLimitPremium: 'errors.subscriptions.groupBookingsLimitPremium',
  CustomResourceTimezoneLimitEnterprise: 'errors.subscriptions.customResourceTimezoneLimitEnterprise',
  SplitIntervalBookingLimitEnterprise: 'errors.subscriptions.splitIntervalBookingLimitEnterprise',
  SavingTagsLimitEnterprise: 'errors.subscriptions.savingTagsLimitEnterprise',
  DeletingTagsLimitEnterprise: 'errors.subscriptions.deletingTagsLimitEnterprise',
  AddingCustomerFieldLimitPremium: 'errors.subscriptions.addingCustomerFieldLimitPremium',
  UpdatingCustomerFieldLimitPremium: 'errors.subscriptions.updatingCustomerFieldLimitPremium',
  SavingCustomerExternalIdLimitEnterprise: 'errors.subscriptions.savingCustomerExternalIdLimitEnterprise',
  ExternalIdLimitEnterprise: 'errors.subscriptions.externalIdLimitEnterprise',
  SavingResourceExternalIdLimitEnterprise: 'errors.subscriptions.savingResourceExternalIdLimitEnterprise',
  SplitIntervalCourseLimitEnterprise: 'errors.subscriptions.splitIntervalCourseLimitEnterprise',
  DescriptionServiceLimitPremium: 'errors.subscriptions.descriptionServiceLimitPremium',
  PreparationTimeServiceLimitPremium: 'errors.subscriptions.preparationTimeServiceLimitPremium',
  FollowUpTimeServiceLimitPremium: 'errors.subscriptions.followUpTimeServiceLimitPremium',
  SplitIntervalServiceLimitEnterprise: 'errors.subscriptions.splitIntervalServiceLimitEnterprise',
  AdditionalDependenciesServiceLimitEnterprise: 'errors.subscriptions.additionalDependenciesServiceLimitEnterprise',
  SavingCoursesLimitPremium: 'errors.subscriptions.savingCoursesLimitPremium',
  BookingReminderLimitPremium: 'errors.subscriptions.bookingReminderLimitPremium',
  BookingSlotFrequencyLimitPremium: 'errors.subscriptions.bookingSlotFrequencyLimitPremium',
  LanguageSelectorLimitPremium: 'errors.subscriptions.languageSelectorLimitPremium',
  CategoryDisplayLimitPremium: 'errors.subscriptions.categoryDisplayLimitPremium',
  ServiceDurationDisplayLimitPremium: 'errors.subscriptions.serviceDurationDisplayLimitPremium',
  TermsAndConditionsLimitPremium: 'errors.subscriptions.termsAndConditionsLimitPremium',
  TimeZoneSelectorLimitPremium: 'errors.subscriptions.timeZoneSelectorLimitPremium',
  CustomCustomerAuthenticationLimitPremium: 'errors.subscriptions.customCustomerAuthenticationLimitPremium'
}

export default (serverCode) => {
  return compareTable[serverCode] || 'errors.somethingWentWrong'
}
