import React, { memo } from 'react'
import { SingleBookingFormFullscreen } from '../../../Beauties'
import { Spinner } from '../../../Common'
import './BookingForm.css'

const BookingForm = props => {
  const { pendingContainer, selectedService } = props
  const formClassNames = ['ta-booking__form']

  return (
    <div className={formClassNames.join(' ')} onClick={(e) => e.stopPropagation()}>
      {pendingContainer && (
        <Spinner />
      )}
      {!pendingContainer && (
        <SingleBookingFormFullscreen selectedService={selectedService} />
      )}
    </div>
  )
}

export default memo(BookingForm)
