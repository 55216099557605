import {
  tokensFields,
  branchesListFields,
  customerFieldCategoriesFields,
  serviceFields,
  customerFields,
  serviceAvailabilityFields,
  branchCompanyEventsFields,
  customerFieldFields,
  callCentreLocalTransformations,
  eventsFields,
  callCentreTagsListFields
} from './fields'

export const getStaticData = `
  query getStaticData {
    getAllCountries {
      stripeMinAmountOnlinePaymentsCountryCurrency
      code
      currency {
        code
        symbol
        digits
      }
    }
    getAllLocales {
      code
      country {
        code
        label
        translationKey
        timezones
      }
      language {
        code
        label
        translationKey
      }
    }
    getAllTimezones { code label }
  }
`

export const loginEnterpriseCallCentreAccountWithEmailAndPassword = `
  mutation loginEnterpriseCallCentreAccountWithEmailAndPassword($email: String!, $password: String!, $longSession: Boolean, $locale: Locale){
    loginEnterpriseCallCentreAccountWithEmailAndPassword(email: $email, password: $password, locale: $locale, longSession: $longSession) ${tokensFields}
  }
`

export const getEnterpriseCallCentreBranches = `
  query getEnterpriseCallCentreBranches($region: Region!, $filter: BranchCompaniesFilter) {
    getEnterpriseCallCentreBranches(region: $region, filter: $filter) ${branchesListFields}
  }
`

export const getEnterpriseCallCentreCustomerFieldCategories = `
  query getEnterpriseCallCentreCustomerFieldCategories($filter: CustomerFieldCategoriesFilter) {
    getEnterpriseCallCentreCustomerFieldCategories(filter: $filter) ${customerFieldCategoriesFields}
  }
`

export const getEnterpriseCallCentreCustomerFields = `
  query getEnterpriseCallCentreCustomerFields($filter: CustomerFieldsFilter) {
    getEnterpriseCallCentreCustomerFields(filter: $filter) ${customerFieldFields}
  }
`

export const getEnterpriseCallCentreCustomers = `
  query getEnterpriseCallCentreCustomers(
      $filter: CustomersFilter
      $sort: CustomersSort
      $pagination: Pagination
  ){
    getEnterpriseCallCentreCustomers(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) ${customerFields}
  }
`

export const getEnterpriseCallCentreCustomer = `
  query getEnterpriseCallCentreCustomer(
      $id: ID
      $externalId: ID
  ){
    getEnterpriseCallCentreCustomer(
      id: $id
      externalId: $externalId
    ) ${customerFields}
  }
`

export const getEnterpriseCallCentreUpcomingEventsByCustomer = `
  query getEnterpriseCallCentreEventsByCustomer($customerId: ID, $customerExternalId: ID, $pagination: Pagination) {
    getEnterpriseCallCentreEventsByCustomer(customerId: $customerId, customerExternalId: $customerExternalId, pagination: $pagination, isUpcoming: true) ${eventsFields}
  }
`

export const getEnterpriseCallCentrePastEventsByCustomer = `
  query getEnterpriseCallCentreEventsByCustomer($customerId: ID, $customerExternalId: ID, $pagination: Pagination) {
    getEnterpriseCallCentreEventsByCustomer(customerId: $customerId, customerExternalId: $customerExternalId, pagination: $pagination, isUpcoming: false) ${eventsFields}
  }
`

export const getEnterpriseCallCentreUpcomingAndPastEventsByCustomer = `
  query getEnterpriseCallCentreUpcomingAndPastEventsByCustomer($customerId: ID, $customerExternalId: ID, $pagination: Pagination) {
    upcoming: getEnterpriseCallCentreEventsByCustomer(customerId: $customerId, customerExternalId: $customerExternalId, pagination: $pagination, isUpcoming: true) ${eventsFields}
    past: getEnterpriseCallCentreEventsByCustomer(customerId: $customerId, customerExternalId: $customerExternalId, pagination: $pagination, isUpcoming: false) ${eventsFields}
  }
`

export const saveEnterpriseCallCentreCustomer = `
  mutation saveEnterpriseCallCentreCustomer($customer: CustomerPayload!, $globalFilterConfig: GlobalFilterConfig){
    saveEnterpriseCallCentreCustomer(customer: $customer, globalFilterConfig: $globalFilterConfig) ${customerFields}
  }
`

export const getEnterpriseCallCentreServices = `
  query getEnterpriseCallCentreServices(
    $filter: ServicesFilter
    $sort: ServicesSort
    $pagination: Pagination
  ){
    getEnterpriseCallCentreServices(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) ${serviceFields}
  }
`

export const getEnterpriseCallCentreCourses = `
  query getEnterpriseCallCentreCourses(
    $filter: ServicesFilter
    $sort: ServicesSort
    $pagination: Pagination
  ){
    getEnterpriseCallCentreCourses(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) ${serviceFields}
  }
`

export const getEnterpriseCallCentreBranchServiceAvailability = `
  query getEnterpriseCallCentreBranchServiceAvailability(
    $options: CallCentreBranchAvailabilityOptions!
    $timezone: Timezone!
  ){
    getEnterpriseCallCentreBranchServiceAvailability(
      options: $options
      timezone: $timezone
    ) ${serviceAvailabilityFields}
  }
`
export const saveEnterpriseCallCentreBranchCompanyEvents = `
  mutation saveEnterpriseCallCentreBranchCompanyEvents(
    $event: CompanyEventPayload!
    $companyId: ID!
    $region: Region!
    $multipleStartDates: [DateString]
    $combinationServices: [CombinationPayload]
    $skipConflictsOnChained: Boolean
    $updateOnlyRecurringDay: Day
    $updateFromRecurringDay: Day
    $allowanceType: AllowanceType,
    $password: String,
    $dontForceElect: Boolean
  ) {
    saveEnterpriseCallCentreBranchCompanyEvents(
      event: $event
      companyId: $companyId
      region: $region
      multipleStartDates: $multipleStartDates
      combinationServices: $combinationServices
      skipConflictsOnChained: $skipConflictsOnChained
      updateOnlyRecurringDay: $updateOnlyRecurringDay
      updateFromRecurringDay: $updateFromRecurringDay
      allowanceType: $allowanceType,
      password: $password,
      dontForceElect: $dontForceElect
    ) ${branchCompanyEventsFields}
  }
`

export const getEnterpriseCallCentreSettings = `
  query getEnterpriseCallCentreSettings {
    getEnterpriseCallCentreSettings {
      allowMultipleAppointmentsPerBooking
      allowBookingWithinWorkingTimes
      hideOfflineServices
      hasCCNotifyOnCustomers
      hasCCNotifyOnResources
    }
  }
`

export const getEnterpriseCallCentreLocalTransformations = `
  query getEnterpriseCallCentreLocalTransformations($options: CallCentreLocalTransformationsOptions!) {
    getEnterpriseCallCentreLocalTransformations(options: $options) ${callCentreLocalTransformations}
  }
`

export const saveEnterpriseCallCentreSettings = `
  mutation saveEnterpriseCallCentreSettings($settings: EnterpriseCallCentreSettingsPayload!) {
    saveEnterpriseCallCentreSettings(settings: $settings) {
      allowMultipleAppointmentsPerBooking
      allowBookingWithinWorkingTimes
      hideOfflineServices
      hasCCNotifyOnCustomers
      hasCCNotifyOnResources
      newBookingActivated
      rescheduleBookingActivated
      resourceSelectorRescheduleManual
      displayBookedResources
    }
  }
`

export const deleteEnterpriseCallCentreBranchCompanyEvents = `
  mutation deleteEnterpriseCallCentreBranchCompanyEvents(
    $idsMap: [CompanyEventCCMap]!
    $reason: String
    $hasNotifyCustomers: Boolean = true
    $hasNotifyResources: Boolean
    $skipSingleEmitter: Boolean
    $skipMultipleEmitter: Boolean
    $password: String!
  ) {
    deleteEnterpriseCallCentreBranchCompanyEvents(
      idsMap: $idsMap
      reason: $reason
      hasNotifyCustomers: $hasNotifyCustomers
      hasNotifyResources: $hasNotifyResources
      skipSingleEmitter: $skipSingleEmitter
      skipMultipleEmitter: $skipMultipleEmitter
      password: $password
    ) {
      id
      isDeleted
    }
  }
`

export const getEnterpriseCallCentreGlobalTransformations = `
  query getEnterpriseCallCentreGlobalTransformations($options: CallCentreGlobalTransformationsOptions!){
    getEnterpriseCallCentreGlobalTransformations(options: $options) {
      services ${serviceFields}
    }
  }
`

export const getEnterpriseCallCenterCompanyTags = `
  query getEnterpriseCallCenterCompanyTags {
    getEnterpriseCallCenterCompanyTags ${callCentreTagsListFields}
  }
`
