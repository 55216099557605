import {
  Avatar,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'
import { BookingFormResourceAvatarsPopupContent } from '../../../Beauties'
const BookingFormResourceAvatars = ({ resources, resourceCategories, listPopupPosition = 'bottom' }) => {
  return (
    <div className='ta-booking-form__success__event__resources'>
      {resources && resources.length > 3 && (
        <HoverPopup className='ta-booking-form__success__event__resources-hover-list__hover-popup'>
          <HoverPopupContent position={listPopupPosition}>
            <BookingFormResourceAvatarsPopupContent resources={resources} resourceCategories={resourceCategories} />
          </HoverPopupContent>
          <HoverPopupTrigger>
            <Avatar name={`${resources.length - 3} +`} />
          </HoverPopupTrigger>
        </HoverPopup>
      )}
      {resources && resources.slice(0, 3).map((item, index, array) => (
        <HoverPopup key={index} disabled={array.length === 1}>
          <HoverPopupContent position='top' autoSize>
            <div className='ta-booking-form__success__event__resources-hover'>
              {item?.name}
            </div>
          </HoverPopupContent>
          <HoverPopupTrigger>
            <div className='ta-booking-form__success__event__resources-displayed-avatar'>
              <Avatar
                name={item?.name}
                image={item?.avatarUrl}
                color={item?.color}
                abbreviation={item?.abbreviation}
              />
              {array.length === 1 && (
                <div className='ta-booking-form__success__event__resources-displayed-avatar__label'>{item?.name}</div>
              )}
            </div>
          </HoverPopupTrigger>
        </HoverPopup>
      ))}
    </div>
  )
}

export default BookingFormResourceAvatars
