import React from 'react'
import {
  Row,
  Col,
  t,
  Button,
  Blur,
  Divider,
  Spinner,
  Form,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  SingleSelect,
  HintText
} from '../../Common'
import {
  CustomersList,
  CustomersListFilterSearch
} from '../../Beauties'
import { CustomerEditWrapper, CustomerAddWrapper, PopupTriggerWrapper } from '../../Beasts'

import './Customers.css'
import { dangerousHTML } from '../../Utils'
import CustomersListFilterTags from './CustomersListFilterTags'

const Customers = props => {
  const {
    customers,
    getCustomerForm,
    search,
    enterpriseId,
    customersRead,
    customersWrite,
    onSelectCustomer,
    onPreviewCustomer,
    isCustomerEdit,
    isCustomerAdd,
    bookingCustomer,
    getCustomers,
    onCreateCustomer,
    showResults,
    customersStore,
    pendingList,
    pendingBranches,
    isScheduleForm,
    lastPage,
    onLoadMore,
    shouldUseMongoCustomerSearch,
    tags,
    services
  } = props
  const searchQueryTypes = [{ value: 'exact', label: t('customers.searchType.exact') }, { value: 'partial', label: t('customers.searchType.partial') }]
  const classNames = ['ta-customers']
  if (customersRead) classNames.push('access-granted')

  return (
    <div className={classNames.join(' ')}>
      <Blur extraClassName={customersRead ? 'inactive' : ''} active={!customersRead} icon='ban' label={t('global.accessDenied')}>
        <div className='ta-customers__list'>
          {bookingCustomer && isCustomerEdit && (
            <CustomerEditWrapper onSubmit={onCreateCustomer} customer={bookingCustomer} />
          )}
          {isCustomerAdd && (
            <CustomerAddWrapper onSubmit={onCreateCustomer} customer={bookingCustomer} />
          )}
          {isScheduleForm && !bookingCustomer && !isCustomerEdit && !isCustomerAdd && (
            <HoverPopup disabled={customersWrite}>
              <HoverPopupContent position='bottom'>
                {t('customers.tooltip.add.noPermission.title')}
              </HoverPopupContent>
              <HoverPopupTrigger>
                <Button
                  secondary
                  block
                  className='ta-btn__create-customer'
                  onClick={() => getCustomerForm('isCustomerAdd')}
                  disabled={!customersWrite}
                >
                  {t('booking.createCustomer.label')}
                </Button>
              </HoverPopupTrigger>
            </HoverPopup>
          )}
          {isScheduleForm && !bookingCustomer && (
            <Divider>{t('global.or')}</Divider>
          )}
          {!bookingCustomer && (
            <>
              <Form
                name='booking'
                onSubmit={getCustomers}
              >
                <Row>
                  <Col>
                    <CustomersListFilterSearch />
                  </Col>
                </Row>
                <Row>
                  {shouldUseMongoCustomerSearch && (
                    <Col size={50}>
                      <SingleSelect
                        name='useExactMatchSearch'
                        label={t('customers.searchType.label')}
                        placeholder={t('customers.searchType.label')}
                        options={searchQueryTypes}
                      />
                    </Col>
                  )}
                  <Col size={50}>
                    <CustomersListFilterTags tags={tags} />
                  </Col>
                </Row>
                {shouldUseMongoCustomerSearch && (
                  <Row>
                    <Col>
                      <HintText>{dangerousHTML(t('customers.filtersHint'))}</HintText>
                    </Col>
                  </Row>
                )}
                {(customers || []).length > 0 && (
                  <Button
                    primary
                    type='submit'
                    greyGhost
                    block
                  >
                    {t('bookings.customerLink.filter.button.label')}
                  </Button>
                )}
                {(customers || []).length === 0 && (
                  <Button primary type='submit'>
                    {t('global.search')}
                  </Button>
                )}
              </Form>
              {pendingList && !customers.length && (
                <div className='ta-booking_form-spinner-container'>
                  <Spinner />
                </div>
              )}
              <CustomersList
                showResults={showResults}
                onSelectCustomer={onSelectCustomer}
                onPreviewCustomer={onPreviewCustomer}
                customersStore={customersStore}
                customers={customers}
                search={search}
                services={services}
                isScheduleForm={isScheduleForm}
                enterpriseId={enterpriseId}
                pendingBranches={pendingBranches}
                lastPage={lastPage}
                onLoadMore={onLoadMore}
                pendingList={pendingList}
              />
            </>
          )}
          {isScheduleForm && bookingCustomer && !isCustomerEdit && (
            <>
              <HoverPopup disabled={customersWrite}>
                <HoverPopupContent position='top'>
                  {t('customers.tooltip.edit.noPermission.title')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <Button
                    secondary
                    block
                    type='button'
                    className='ta-btn__create-customer'
                    onClick={() => getCustomerForm('isCustomerEdit')}
                    disabled={!customersWrite || (bookingCustomer && bookingCustomer.isUpdatedLocally)}
                  >
                    {t('booking.editCustomer.label')}
                  </Button>
                </HoverPopupTrigger>
              </HoverPopup>
              <PopupTriggerWrapper name='customers-remove' position='bottom'>
                <Button
                  greyGhost
                  block
                  className='ta-btn__create-customer'
                >
                  {t('booking.removeCustomer.label')}
                </Button>
              </PopupTriggerWrapper>
            </>
          )}
        </div>
      </Blur>
    </div>
  )
}

export default Customers
