import React, { PureComponent } from 'react'

import './HoverPopup.css'

class HoverPopup extends PureComponent {
  constructor (props, context) {
    super(props)

    this.setIsHover = this.setIsHover.bind(this)
    this.state = {
      isHover: false,
      setIsHover: this.setIsHover
    }
  }

  setIsHover (isHover) {
    this.setState({ isHover })
  }

  render () {
    let { children, className, disabled, block, styles } = this.props
    const { isHover } = this.state
    const classNames = ['ta-hover-popup']
    if (className) classNames.push(className)
    if (isHover) classNames.push('is-hover')
    if (disabled) classNames.push('disabled')
    if (block) classNames.push('block')

    return (
      <HoverPopupContext.Provider value={this.state}>
        <div className={classNames.join(' ')} style={styles} data-testid='hover-popup'>
          {children}
        </div>
      </HoverPopupContext.Provider>
    )
  }
}

export const HoverPopupContext = React.createContext({
  isHover: false,
  setIsHover: () => { }
})

export default HoverPopup
