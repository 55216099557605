import { createSelector } from '../../../Utils'

export const servicesSelector = state => state.services

// export const servicesSelectedFieldSelector = state => state.services.selected

export const servicesListSelector = state => state.services.list

export const customerServicesListSelector = state => state.services.filteredService

export const servicesCategoriesListSelector = state => state.services.categoriesList

export const servicesFieldSelector = createSelector(
  servicesSelector,
  (_, props) => props.field,
  (services, field) => (services || {})[field]
)

// export const serviceByIdSelector = createSelector(
//   servicesListSelector,
//   (_, props) => props.id,
//   (services, id) => services.find(service => service.id === id) || {}
// )

// export const serviceCategoryByIdSelector = createSelector(
//   servicesCategoriesListSelector,
//   (_, props) => props.id,
//   (categories, id) => categories.find(category => category.id === id) || {}
// )

// export const servicesSelectedSelector = createSelector(
//   servicesSelectedFieldSelector,
//   servicesListSelector,
//   (selected, services) => services.find(service => service.id === selected)
// )

// export const servicesSortedByOrderIndex = createSelector(
//   servicesListSelector,
//   (services) => (services || []).sort(sortByOrderIndex)
// )

// export const serviceCategoriesSortedByOrderIndex = createSelector(
//   servicesCategoriesListSelector,
//   (categories) => (categories || []).sort(sortByOrderIndex)
// )

// export const servicesByCategoriesSelector = createSelector(
//   servicesListSelector,
//   servicesCategoriesListSelector,
//   (list, categoriesList) => (categoriesList || []).reduce((acc, item) => ({
//     ...acc,
//     [item.id]: (list || []).filter(resource => resource.categoryId === item.id)
//   }), {})
// )
