const reducer = {}

reducer.initialState = {
  businessSectors: []
}

reducer.handlers = (dispatch, actions) => ({
  staticDataPopulate: data => dispatch(actions.STATIC_DATA_POPULATE, data)
})

reducer.STATIC_DATA_POPULATE = (state, data) => ({
  ...state,
  countries: data.countries || [],
  locales: data.locales || [],
  timezones: data.timezones || []
})

export default reducer
