import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../Common'

import './Sidebar.css'

const Sidebar = props => {
  const {
    position,
    level,
    status,
    size,
    childSize,
    children,
    onClose,
    hideCloseButton,
    navigationPosition,
    noBottomFade
  } = props
  const classNames = ['ta-sidebar']
  const contentClassNames = ['ta-sidebar__content']
  if (noBottomFade) contentClassNames.push('no-bottom-fade')
  classNames.push(`ta-sidebar__${position}-${level}`, status)
  if (hideCloseButton) classNames.push('hide-close-button')
  if (navigationPosition === 'iconOnly') classNames.push('icon-only')
  if (size === 'large') classNames.push('large')
  if (size === 'big') classNames.push('big')
  if (size === 'medium') classNames.push('medium')
  if (childSize === 'large') classNames.push('child-large')

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-sidebar__close-button' onClick={() => onClose(true)} data-testid='sidebarCloseButton'>
        <FontAwesome5 icon='times' />
      </div>
      <div className={contentClassNames.join(' ')}>
        <div className='ta-sidebar__content-close-button' onClick={onClose} />
        {children}
      </div>
    </div>
  )
}

Sidebar.propTypes = {
  position: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Sidebar
