import React from 'react'
import PropTypes from 'prop-types'
import { OutsideClick } from '../../Common'

import './Modal.css'

const Modal = props => {
  const {
    isActive,
    children,
    className,
    onCloseModal
  } = props
  const classNames = ['ta-modal']
  if (isActive) classNames.push('active')
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')}>
      {(isActive &&
        <OutsideClick onClickOutside={onCloseModal}>
          {children}
        </OutsideClick>
      )}
    </div>
  )
}

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default Modal
