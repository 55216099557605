import React, { useState, useEffect, useRef, memo } from 'react'
import Dropzone from 'react-dropzone'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  SingleSlider,
  Error,
  FontAwesome5,
  Image
} from '../../../Common'

import './ImageCrop.css'

const ImageCrop = props => {
  let {
    formName,
    form,
    name,
    className,
    hintText,
    removeRef,
    preview = false,
    uploadTitle,
    uploadText,
    width = '100%',
    height = '240px',
    aspect = 16 / 9,
    minSize = 0,
    maxSize = 10,
    extensions = ['jpeg', 'jpg', 'png'],
    disabled,
    mandatory,
    hideError,
    addRef,
    controls
  } = props
  form = form || { value: {} }

  const [initial] = useState({ addRef, removeRef })
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const wrapperRef = useRef(null)
  const { value, imageUrl, imageFile } = form
  const classNames = ['ta-image-crop']
  const classNamesDeleteBtn = ['ta-btn ta-btn-delete']

  let timeout

  if (className) classNames.push(className)
  if (disabled) classNames.push('disabled')
  if (mandatory) classNames.push('mandatory')

  // ComponentDidMount
  useEffect(() => {
    const { addRef, removeRef } = initial
    // Mount
    addRef && addRef(ImageCrop)

    return () => {
      // UnMount
      removeRef && removeRef(ImageCrop)
    }
  }, [initial])

  const onCropChange = crop => {
    setCrop(crop)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    showCroppedImage(croppedAreaPixels)
  }

  const showCroppedImage = async (croppedAreaPixels_) => {
    clearTimeout(timeout)
    timeout = setTimeout(async () => {
      const croppedImg = await getCroppedImg(form.value.preview, croppedAreaPixels_)
      handlers.formFieldsUpdate(formName, { [name]: { ...form, imageFile: croppedImg } })
    }, 500)
  }

  const onZoomChange = zoom => {
    setZoom(zoom)
  }

  const validate = file => {
    if (!file) return false

    const lastElementIndex = Number(file.name.split('.').length) - 1
    const ext = (file.name && file.name.split('.')[lastElementIndex]) ? file.name.split('.')[lastElementIndex].toLowerCase() : ''
    const minSizeBytes = minSize * 1048576
    const maxSizeBytes = maxSize * 1048576
    const errors = []

    if (extensions.indexOf(ext) === -1) {
      errors.push({
        key: name,
        value: 'errors.extensionNotAllowed',
        replace: [{ key: 'EXTENSION', value: ext }]
      })
    }

    if (file.size < minSizeBytes) {
      errors.push({
        key: name,
        value: 'errors.minAllowedSize',
        replace: [{ key: 'MIN', value: maxSize }]
      })
    }

    if (file.size > maxSizeBytes) {
      errors.push({
        key: name,
        value: 'errors.maxAllowedSize',
        replace: [{ key: 'MAX', value: maxSize }]
      })
    }

    if (errors.length > 0) {
      handlers.formErrorsSet(formName, errors)
      return false
    }
    return true
  }

  const onDrop = dropped => {
    if (!dropped) return

    const file = dropped[0] || {}
    const reader = new window.FileReader()

    reader.addEventListener('load', function () {
      if (validate(file)) {
        handlers.formFieldsUpdate(formName, {
          [name]: {
            ...form,
            value: {
              ...file,
              preview: reader.result
            }
          }
        })
      }
    }, false)

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const onClickDeleteImage = () => {
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: null, imageUrl: null, imageFile: null } })
  }

  // DELETE BUTTON
  const deleteBtn = (
    <div className={classNamesDeleteBtn.join(' ')} onClick={onClickDeleteImage}>
      <FontAwesome5 icon='trash' type='regular' />
    </div>
  )

  // UPLOAD IMAGE
  const upload = (!value || !value.preview) && ((!imageUrl || !imageUrl.value)) && (
    <Dropzone onDrop={onDrop} className='ta-image-crop__dropzone' >
      {({ getRootProps, getInputProps }) => (
        <div style={{ width, height }} className='ta-image-crop-upload-wrapper'>
          <div {...getRootProps()} className='ta-image-crop-upload-area'>
            <input {...getInputProps()} />
            <FontAwesome5 icon='cloud-upload' type='solid' className='ta-image-crop-upload-area__icon' />
            <div className='ta-image-crop-upload-area__title'>
              {uploadTitle}
            </div>
            <div className='ta-image-crop-upload-area__text'>{uploadText}</div>
            {(extensions && extensions.length > 0) && (
              <div className='ta-image-crop-upload-area__formats-container'>
                {extensions.map((item, index) => (
                  <div className='ta-image-crop-upload-area__format' key={index}>{item}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  )

  // CROP IMAGE
  const cropEditor = form.value && form.value.preview && (
    <>
      <div style={{ width, height }} className='ta-cropper-wrapper'>
        <div className='ta-cropper-container'>
          <Cropper
            image={form.value.preview}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
            showGrid={false}
            style={{ containerStyle: { width: '100%' }, cropAreaStyle: { width: '100%', height: '100%' }, imageStyle: { width: '100%', height: 'auto', maxHeight: '400%' } }}
          />
        </div>
      </div>
      {deleteBtn}
    </>
  )

  // CURRENT IMAGE
  const currentImage = (imageUrl && imageUrl.value) && (
    <div className='ta-image-wrapper'>
      <Image height={100} width={100} src={imageUrl.value} alt='cover' />
      {deleteBtn}
    </div>
  )

  // CONTROLS
  const ImageControls = (
    <div className='ta-image-crop__sliders'>
      <div className='ta-image-crop__controls'>
        <SingleSlider
          name={`${name}Zoom`}
          min={1}
          max={2}
          step={0.1}
          defaultValue={(form.zoom && form.zoom.value) || 1}
          leftLabel={<FontAwesome5 icon='search-minus' type='solid' className='ta-image-crop__slider__icon' />}
          rightLabel={<FontAwesome5 icon='search-plus' type='solid' className='ta-image-crop__slider__icon' />}
          onChange={onZoomChange}
          autoComplete='off'
        />
      </div>
    </div>
  )

  return (
    <div ref={wrapperRef} className={classNames.join(' ')}>
      {upload}
      {currentImage}
      {cropEditor}

      {hintText && <div className='ta-form-control__hint'>{hintText}</div>}
      {(preview && imageFile) &&
        <div className='ta-image-crop-preview'>
          <Image src={imageFile || ''} alt='' />
        </div>
      }
      {form.value && form.value.preview && controls && ImageControls}
      {!hideError && <Error name={name} />}
    </div>
  )
}

export const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default memo(feedContextInProps(connect(maps)(ImageCrop), FormContext))
