import { agentsFields } from './fields'

export const getEnterpriseCallCentreAgents = `
  query getEnterpriseCallCentreAgents {
    getEnterpriseCallCentreAgents ${agentsFields}
  }
`

export const saveEnterpriseCallCentreAgent = `
  mutation saveEnterpriseCallCentreAgent($agent: EnterpriseAgentPayload!) {
    saveEnterpriseCallCentreAgent(agent: $agent) ${agentsFields}
  }
`

export const getEnterpriseCallCentreAgent = `
  query getEnterpriseCallCentreAgent ($id: ID!) {
    getEnterpriseCallCentreAgent(id: $id) ${agentsFields}
  }
`

export const deleteEnterpriseCallCentreAgent = `
  mutation deleteEnterpriseCallCentreAgent ($id: ID!) {
    deleteEnterpriseCallCentreAgent(id: $id)
  }
`
