import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'
import {
  Form,
  FormButtons,
  BorderedBox,
  Error,
  Input,
  CopyToClipboard,
  Loader,
  Row,
  Col,
  t
} from '../../Common'

import './TwoFAForm.css'

const TwoFAForm = ({ authData, onCancel, pinCode, onSubmit, isLogin, pending }) => {
  const {
    secretKey,
    service,
    account
  } = authData
  const btnClassnames = ['ta-btn', 'ta-btn-primary']
  if (isLogin) btnClassnames.push('ta-2fa-btn-full-width')

  return (
    <Form className='ta-2fa-form' name='twoFA' onSubmit={onSubmit}>
      {pending && <Loader active />}
      <div className='ta-2fa-form-wrapper'>
        <div className='ta-2fa-form__title'>
          <div className='ta-2fa-form__title__circle'>1</div>
          {t('twoFA.form.downloadApp')}
        </div>
        <div className='ta-2fa-form__subtitle'>
          {t('twoFA.form.downloadApp.infoText')}
        </div>

        <div className='ta-2fa-form__title'>
          <div className='ta-2fa-form__title__circle'>2</div>
          {t('twoFA.form.scanQRCode')}
        </div>
        <div className='ta-2fa-form__subtitle'>
          {t('twoFA.form.scanQRCode.infoText')}
        </div>
        <div className='ta-2fa-form__qrcode__wrapper'>
          <div className='ta-2fa-form__qrcode'>
            <QRCode value={`otpauth://totp/${service}?secret=${secretKey}&issuer=${account}`} fgColor='#505a5e' />
          </div>
          <BorderedBox>
            <div className='ta-2fa-form__subtitle'>
              {t('twoFA.form.manual.infoText')}
            </div>
            <CopyToClipboard text={account || ''} className='ta-2fa-form__copy-to-clipboard' iconType='solid'>
              <div className='ta-2fa-form__copy-to-clipboard__title'>{t('twoFA.form.serviceURL')}</div>
              <div>{account}</div>
            </CopyToClipboard>
            <CopyToClipboard text={service || ''} className='ta-2fa-form__copy-to-clipboard' iconType='solid'>
              <div className='ta-2fa-form__copy-to-clipboard__title'>{t('twoFA.form.accountID')}</div>
              <div>{service}</div>
            </CopyToClipboard>
            <CopyToClipboard text={secretKey || ''} className='ta-2fa-form__copy-to-clipboard' iconType='solid'>
              <div className='ta-2fa-form__copy-to-clipboard__title'>{t('twoFA.form.key')}</div>
              <div>{secretKey}</div>
            </CopyToClipboard>
          </BorderedBox>
        </div>
        {!isLogin && (
          <>
            <div className='ta-2fa-form__title'>
              <div className='ta-2fa-form__title__circle'>3</div>
              {t('twoFA.form.pinCode')}
            </div>
            <div className='ta-2fa-form__subtitle'>
              {t('twoFA.form.pinCode.infoText')}
            </div>
            <Row>
              <Col>
                <Input
                  name='code'
                  label={t('global.pinCode.label')}
                  placeholder={t('global.pinCode.placeholder')}
                  mandatory
                  hideError
                  autoFocus
                />
                <Error name='code' />
              </Col>
            </Row>
            <FormButtons>
              {!isLogin && (
                <button onClick={onCancel} type='button' className='ta-btn ta-btn-secondary'>
                  {t('buttons.cancel.label')}
                </button>
              )}
              <button type='submit' disabled={!pinCode} className={btnClassnames.join(' ')}>
                {t('global.verify')}
              </button>
            </FormButtons>
          </>
        )}
      </div>
      {isLogin && (
        <div className='ta-2fa-form__login__wrapper'>
          <div className='ta-2fa-form__title'>
            <div className='ta-2fa-form__title__circle'>3</div>
            {t('twoFA.form.pinCode')}
          </div>
          <div className='ta-2fa-form__subtitle'>
            {t('twoFA.form.pinCode.infoText')}
          </div>
          <Row>
            <Col>
              <Input
                name='code'
                label={t('global.pinCode.label')}
                placeholder={t('global.pinCode.placeholder')}
                mandatory
                hideError
                autoFocus
              />
              <Error name='code' />
            </Col>
          </Row>
          <FormButtons>
            {!isLogin && (
              <button onClick={onCancel} type='button' className='ta-btn ta-btn-secondary'>
                {t('buttons.cancel.label')}
              </button>
            )}
            <button type='submit' disabled={!pinCode} className={btnClassnames.join(' ')}>
              {t('global.verify')}
            </button>
          </FormButtons>
        </div>
      )}
    </Form>
  )
}

TwoFAForm.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  pending: PropTypes.bool
}

export default TwoFAForm
