import React from 'react'
import PropTypes from 'prop-types'
import { getInitialsByName, getColorByName } from '../../Utils'
import { Image } from '../../Common'

import './Avatar.css'

const Avatar = props => {
  const { name, image, color, className, abbreviation, children } = props
  const initials = abbreviation || getInitialsByName(name)
  const colorByName = getColorByName(name)
  const styles = { backgroundColor: color || colorByName }
  const classNames = ['ta-avatar']
  if (className) classNames.push(className)

  return (
    <div className={classNames.join(' ')} style={styles} data-testid='avatar'>
      {image &&
        <div className='ta-avatar__image' data-testid='avatar-image'>
          <Image src={image} alt={name} />
        </div>
      }
      {!image &&
        <div className='ta-avatar__initials' data-testid='avatar-initials'>{initials}</div>
      }
      {children || null}
    </div>
  )
}

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
}

export default Avatar
