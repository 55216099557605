import React from 'react'
import { Button, t, Spinner, Alert } from '../../../Common'
import { BookingFormWrapper, BookingFormServiceWrapper } from '../../../Beasts'
import { dangerousHTML } from '../../../Utils'
import './BookingForm.css'

const BookingFormServiceAdhoc = ({
  selectedService,
  hasServiceSelected,
  handleServicesSubmit,
  pending,
  isServiceAvailable,
  intervals,
  splitDurationInIntervals,
  isScheduleForm,
  isRescheduleForm,
  services,
  serviceId
}) => {
  const selectedServiceName = services?.find((service) => service.internalId === serviceId)?.name || ''

  return (
    <>
      {pending && (
        <div className='ta-booking_form-spinner-container'>
          <Spinner />
        </div>
      )}
      {(!isServiceAvailable && !selectedServiceName && serviceId && isScheduleForm &&
        < Alert theme='alert'>{dangerousHTML(t('booking.serviceNotFound', [{ key: 'SERVICENAME', value: selectedService }]))}</Alert>
      )}
      {!pending && (
        <>
          <BookingFormServiceWrapper
            type='add'
            hideColorSelector={false}
            isScheduleForm={isScheduleForm}
            isRescheduleForm={isRescheduleForm}
          />
          {hasServiceSelected && <BookingFormWrapper />}
          {!isRescheduleForm && isServiceAvailable && (
            <Button
              type='button'
              onClick={handleServicesSubmit}
              block
              primary
              topOffset
              disabled={splitDurationInIntervals && intervals.includes(0)}
            >
              {t('scheduleBooking.adhocButton.label')}
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default BookingFormServiceAdhoc
