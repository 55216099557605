import React, { Component } from 'react'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  FontAwesome5,
  Select,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'

import './BookingCustomerSelect.css'

class BookingCustomerSelect extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSearch = this.onSearch.bind(this)
    this.onReset = this.onReset.bind(this)
    this.addCustomer = this.addCustomer.bind(this)
    this.state = { focused: false }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onSearch (value) {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    this.setState({ filled: !!value })
    handlers.formFieldsUpdate(
      formName, {
        [name]: {
          ...form,
          value,
          selectedId: null,
          customer: null,
          options: null,
          pending: value.length > 0
        }
      }
    )
  }

  onChange (item) {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    const { label, value } = item
    const hasNewId = form.selectedId !== value
    hasNewId && handlers.formFieldsUpdate(formName, {
      [name]: {
        ...form,
        value: label,
        selectedId: value,
        customer: null
      }
    })
  }

  onReset () {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    handlers.formFieldsUpdate(formName, {
      customersCount: 0,
      [name]: {
        ...form,
        value: '',
        selectedId: null,
        customer: null
      }
    })
    handlers.persistDelete('customerId')
    handlers.persistDelete('externalCustomerId')
  }

  addCustomer () {
    let { form, addNewDisabled } = this.props
    form = form || { value: '' }
    if (!addNewDisabled) {
      handlers.bookingFormAddonPopulate('addCustomer', { name: form.value || '' })
      this.onReset()
    }
  }

  render () {
    const { focused } = this.state
    let {
      label,
      placeholder,
      name,
      disabled,
      className,
      hideError,
      form,
      addNewDisabled
    } = this.props
    form = form || { value: '' }
    let selectValue = null
    if (form.options && form.options.length && form.selectedId) selectValue = form.selectedId
    else if (form.value) selectValue = form.value
    else if (form.customer && form.customer.companyName) selectValue = form.customer.companyName
    const classNames = ['ta-booking-customer-select']
    if (disabled) classNames.push('disabled')
    if (className) classNames.push(className)
    const addCustomerClassNames = ['ta-booking-customer-select__options__new']
    if (addNewDisabled) addCustomerClassNames.push('disabled')

    return (
      <div className={classNames.join(' ')}>
        <FormGroup
          focused={focused}
          filled={!!form.value}
          labelText={label || form.label}
        >
          <Select
            name={name}
            value={selectValue}
            placeholder={placeholder || form.placeholder}
            options={form.options}
            hideArrow
            searchable
            clearable
            hideNoResults
            onSearch={this.onSearch}
            onReset={this.onReset}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            disabled={disabled || form.disabled}
            pending={form.pending}
            renderOptionsHeader={() => (
              <HoverPopup disabled={!addNewDisabled}>
                <HoverPopupContent position='bottom'>
                  {t('customers.tooltip.add.noPermission.title')}
                </HoverPopupContent>
                <HoverPopupTrigger>
                  <div
                    className={addCustomerClassNames.join(' ')}
                    onClick={this.addCustomer}
                  >
                    {t('calendar.form.section.customer.createNew')}
                  </div>
                </HoverPopupTrigger>
              </HoverPopup>
            )}
            renderItem={(item, index, { isActive, isSelected, onSelect }) => {
              const optionClassNames = ['ta-booking-customer-select__options__item']
              if (isActive) optionClassNames.push('active')
              if (isSelected) optionClassNames.push('selected')
              return (
                <div
                  key={item.value}
                  className={optionClassNames.join(' ')}
                  onClick={onSelect}
                >
                  <div className='ta-booking-customer-select__options__name'>
                    {item.label}
                  </div>
                  <div className='ta-booking-customer-select__options__phone'>
                    <FontAwesome5 icon='phone' type='solid' /> {item.phone || '-'}
                  </div>
                  <div className='ta-booking-customer-select__options__email'>
                    <FontAwesome5 icon='envelope' type='solid' /> {item.email || '-'}
                  </div>
                </div>
              )
            }}
          />
          {!hideError &&
            <Error name={name} />
          }
        </FormGroup>
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(BookingCustomerSelect), FormContext)
