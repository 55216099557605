import React from 'react'

const IconListItemBody = props => {
  let { children, block, offset, styles, className } = props
  styles = styles || {}
  const classNames = ['ta-icon-list__body']
  if (block) classNames.push('block')
  if (className) classNames.push(className)
  if (!block && offset && !styles.width) styles.width = `calc(100% - 5px - ${offset})`

  return (
    <div className={classNames.join(' ')} style={{ ...styles }} data-testid='icon-list-item-body'>
      {children}
    </div>
  )
}

export default IconListItemBody
