import { store } from '../../Store'

export const isRouteDisabledByCustomisations = () => {
  const state = store.getState()
  const { company, enterprise, router } = state || {}
  const route = router.name
  const { customisations: companyCustomisations } = company || {}
  const { customisations: enterpriseCustomisations } = enterprise || {}
  const customisations = companyCustomisations || enterpriseCustomisations || {}
  const { settings } = customisations || {}
  const {
    hideUser,
    hideAccountsSwitcher,
    hideOnlineStatus,
    hideMenuAccount,
    hideMenuAccountsBilling,
    hideMenuAccountsInvoices,
    hideMenuStatistics,
    hideMenuNotifications,
    hideMenuNotificationsActivityLog,
    hideMenuNotificationsEmailStatus,
    hideMenuCustomers,
    hideMenuCustomersCustomerFields,
    hideMenuCustomersTags,
    hideMenuManagement,
    hideMenuManagementResources,
    hideMenuManagementServices,
    hideMenuManagementCourses,
    hideMenuSettings,
    hideMenuSettingsBookings,
    hideMenuSettingsNotifications,
    hideMenuSettingsCustomers,
    hideMenuSettingsSecurity,
    hideMenuSettingsPayments,
    hideMenuIntegration,
    hideMenuIntegrationBookingLink,
    hideMenuIntegrationProfilePage,
    hideMenuIntegrationBookingWidget,
    hideMenuOffers,
    hideMenuApps,
    hideOnlineBookingArrow
  } = settings || {}
  const ROUTES_USER = ['account', 'accountEdit', 'accountPasswordChange']
  const ROUTES_ACCOUNTS = ['accounts']
  const ROUTES_ACCOUNT = ['company', 'companyEdit']
  const ROUTES_BILLING = ['billing', 'billingEdit', 'paymentMethods', 'paymentMethodsPreview', 'paymentMethodsAdd', 'paymentMethodsEdit']
  const ROUTES_INVOICES = ['invoices']
  const ROUTES_STATISTICS = ['statistics']
  const ROUTES_NOTIFICATIONS = ['notifications', 'activityLog', 'emailDeliveryStatus']
  const ROUTES_ACTIVITY_LOG = ['activityLog']
  const ROUTES_EMAIL_DELIVERY_STATUS = ['emailDeliveryStatus']
  const ROUTES_CUSTOMERS = ['customers', 'customerPreview', 'customersAdd', 'customersEdit']
  const ROUTES_CUSTOMER_FIELDS = ['customerFields', 'customerFieldsPreview', 'customerFieldsAdd', 'customerFieldsEdit', 'customerFieldsReorder', 'customerFieldsCategoriesPreview', 'customerFieldsCategoryEdit', 'customerFieldsCategoryAdd', 'customerFieldsCategories']
  const ROUTES_TAGS = ['tags', 'tagAdd', 'tagEdit', 'tagPreview', 'tagsReorder']
  const ROUTES_MANAGEMENTS = ['management']
  const ROUTES_RESOURCES = ['resources', 'resourceAdd', 'resourceEdit', 'resourcePreview', 'resourceCategoryAdd', 'resourceCategoryEdit']
  const ROUTES_SERVICES = ['services', 'serviceAdd', 'serviceEdit', 'servicePreview', 'serviceCategoryAdd', 'serviceCategoryEdit', 'servicesReorder']
  const ROUTES_COURSES = ['courses', 'courseAdd', 'courseEdit', 'coursePreview', 'courseCategoryAdd', 'courseCategoryEdit', 'coursesReorder']
  const ROUTES_SETTINGS = ['settings']
  const ROUTES_BOOKING_SETTINGS = ['settingsBookingSetup', 'settingsBookingSetupEdit']
  const ROUTES_NOTIFICATIONS_SETTINGS = ['settingsNotifications', 'settingsNotificationsEdit']
  const ROUTES_CUSTOMERS_SETTINGS = ['settingsCustomers', 'settingsCustomersEdit']
  const ROUTES_SECURITY_SETTINGS = ['settingsSecurity', 'settingsSecurityEdit']
  const ROUTES_PAYMENT_SETTINGS = ['onlinePaymentMethods', 'onlinePaymentMethodsStripeCallback']
  const ROUTES_INTEGRATION = ['integration']
  const ROUTES_BOOKING_LINK = ['bookingLink']
  const ROUTES_BOOKING_PROFILE = ['bookingProfile', 'bookingProfileEdit']
  const ROUTES_WIDGET = ['widget']
  const ROUTES_OFFERS = ['offers', 'offerAdd', 'offerEdit', 'offerPreview']
  const ROUTES_APPS = ['apps', 'appsPreview']
  const ROUTES_ONLINE_BOOKING = ['onlineBooking']

  if (
    (ROUTES_USER.includes(route) && hideUser) ||
    (ROUTES_ACCOUNTS.includes(route) && hideAccountsSwitcher) ||
    (ROUTES_ACCOUNT.includes(route) && hideMenuAccount) ||
    (ROUTES_BILLING.includes(route) && (hideMenuAccount || hideMenuAccountsBilling)) ||
    (ROUTES_INVOICES.includes(route) && (hideMenuAccount || hideMenuAccountsInvoices)) ||
    (ROUTES_STATISTICS.includes(route) && hideMenuStatistics) ||
    (ROUTES_NOTIFICATIONS.includes(route) && hideMenuNotifications) ||
    (ROUTES_ACTIVITY_LOG.includes(route) && (hideMenuNotifications || hideMenuNotificationsActivityLog)) ||
    (ROUTES_EMAIL_DELIVERY_STATUS.includes(route) && (hideMenuNotifications || hideMenuNotificationsEmailStatus)) ||
    (ROUTES_CUSTOMERS.includes(route) && hideMenuCustomers) ||
    (ROUTES_CUSTOMER_FIELDS.includes(route) && (hideMenuCustomers || hideMenuCustomersCustomerFields)) ||
    (ROUTES_TAGS.includes(route) && (hideMenuCustomers || hideMenuCustomersTags)) ||
    (ROUTES_MANAGEMENTS.includes(route) && hideMenuManagement) ||
    (ROUTES_RESOURCES.includes(route) && (hideMenuManagement || hideMenuManagementResources)) ||
    (ROUTES_SERVICES.includes(route) && (hideMenuManagement || hideMenuManagementServices)) ||
    (ROUTES_COURSES.includes(route) && (hideMenuManagement || hideMenuManagementCourses)) ||
    (ROUTES_SETTINGS.includes(route) && hideMenuSettings) ||
    (ROUTES_BOOKING_SETTINGS.includes(route) && (hideMenuSettings || hideMenuSettingsBookings)) ||
    (ROUTES_NOTIFICATIONS_SETTINGS.includes(route) && (hideMenuSettings || hideMenuSettingsNotifications)) ||
    (ROUTES_CUSTOMERS_SETTINGS.includes(route) && (hideMenuSettings || hideMenuSettingsCustomers)) ||
    (ROUTES_SECURITY_SETTINGS.includes(route) && (hideMenuSettings || hideMenuSettingsSecurity)) ||
    (ROUTES_PAYMENT_SETTINGS.includes(route) && (hideMenuSettings || hideMenuSettingsPayments)) ||
    (ROUTES_INTEGRATION.includes(route) && (hideMenuIntegration)) ||
    (ROUTES_BOOKING_LINK.includes(route) && (hideMenuIntegration || hideMenuIntegrationBookingLink)) ||
    (ROUTES_BOOKING_PROFILE.includes(route) && (hideMenuSettings || hideMenuIntegrationProfilePage)) ||
    (ROUTES_WIDGET.includes(route) && (hideMenuSettings || hideMenuIntegrationBookingWidget)) ||
    (ROUTES_OFFERS.includes(route) && hideMenuOffers) ||
    (ROUTES_APPS.includes(route) && hideMenuApps) ||
    (ROUTES_ONLINE_BOOKING.includes(route) && (hideOnlineStatus || hideOnlineBookingArrow))
  ) return true
  return false
}
