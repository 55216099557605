import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, handlers, selectors } from '../../Store'
import { Loader } from '../../Common'
import { AccountSecurity } from '../../Beauties'

const AccountSecurityWrapper = (props) => {
  let { pending, form, recoveryCodes, settings } = props
  form = form || {}
  const { isAlreadyEnabled2FA, enabled2FA } = form
  const isEnterpriseRequire2FA = settings && settings.requiredAuth2FAType === 'OTP'
  const is2FAEnabled = enabled2FA && enabled2FA.value
  const is2FAAlreadyEnabled = isAlreadyEnabled2FA && isAlreadyEnabled2FA.value

  useEffect(() => {
    handlers.accountSecurityFormGet()

    return () => {
      handlers.twoFARecoveryCodesClear()
    }
  }, [])

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <AccountSecurity
          enabled2FA={is2FAEnabled}
          isAlreadyEnabled2FA={is2FAAlreadyEnabled}
          isEnterpriseRequire2FA={isEnterpriseRequire2FA}
          recoveryCodes={recoveryCodes}
        />
      )}
    </>
  )
}

AccountSecurityWrapper.propTypes = {
  pending: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired
}

const maps = state => ({
  pending: state.account.pendingSecurityForm,
  form: state.forms.accountSecurity,
  recoveryCodes: state.twoFA.recoveryCodes,
  settings: selectors.accountEnterprisePropertySelector(state, { property: 'settings' })
})

export default connect(maps)(AccountSecurityWrapper)
