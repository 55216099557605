import React from 'react'
import {
  Accordion,
  AccordionTitle,
  AccordionBody,
  Row,
  Col,
  DurationInput,
  StripedTitle,
  Error,
  t,
  BorderedBox
} from '../../../Common'
import { BookingFormDurationBeforeAndAfter } from '../../../Beauties'
import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'

const BookingFormServiceCombination = props => {
  const {
    services,
    showDurationDaySelector,
    showDurationBeforeAndAfter,
    formDurationAfter,
    formDurationBefore,
    color,
    collapsedFormSections,
    toggleLocalStorageSection
  } = props

  return (
    <BorderedBox className='ta-single-booking-form__service-combination'>
      {services.map((item, index) => {
        const id = item.id || item.eventId

        return (
          <React.Fragment key={id}>
            <Accordion zIndex={200 - index}>
              <AccordionTitle>
                <div
                  className='ta-single-booking-form__service-combination__service-color'
                  style={{ background: item.color || DEFAULT_SERVICE_HEXCOLOR }}
                />
                {item.title || item.name}
              </AccordionTitle>
              <AccordionBody>
                {/* <Row>
                  <Col>
                    <BookingDependenciesInput name={`dependencies${id}`} />
                  </Col>
                </Row> */}
                <StripedTitle label={t('global.duration')} />
                <Row>
                  <Col>
                    <DurationInput
                      name={`duration${id}`}
                      noDays={!showDurationDaySelector}
                      service={item}
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </Accordion>
          </React.Fragment>
        )
      })}
      <Error
        name='combinationDependenciesError'
        className='ta-service-combintaion-dependencies-error'
      />
      <BookingFormDurationBeforeAndAfter
        showDurationDaySelector={showDurationDaySelector}
        showDurationBeforeAndAfter={showDurationBeforeAndAfter}
        formDurationAfter={formDurationAfter}
        formDurationBefore={formDurationBefore}
        color={color}
        collapsedFormSections={collapsedFormSections}
        toggleLocalStorageSection={toggleLocalStorageSection}
      />
    </BorderedBox>
  )
}

export default BookingFormServiceCombination
