import { BookingFormDependencies } from '../../Beauties'
import { connect, selectors } from '../../Store'
import { groupByKey } from '../../Utils'

const removeDuplicatedDependencies = dependencies => {
  if (!dependencies?.length) return []

  const allRequiredDependency = dependencies.find(({ specificResourceIds, specificResourceCategoryIds, workInParallel }) =>
    !specificResourceIds?.length && !specificResourceCategoryIds?.length && workInParallel)
  if (allRequiredDependency) return [allRequiredDependency]

  const filteredDependencies = dependencies.reduce((acc, currentItem) => {
    const existingItemIndex = acc.findIndex(
      item =>
        (!!item.specificResourceCategoryIds && !!currentItem.specificResourceCategoryIds) &&
        JSON.stringify(item?.specificResourceCategoryIds.sort()) === JSON.stringify(currentItem.specificResourceCategoryIds.sort()) &&
        !item.specificResourceIds?.length
    )

    if (existingItemIndex === -1) acc.push(currentItem)
    else if (currentItem.workInParallel && !acc[existingItemIndex].workInParallel) { acc[existingItemIndex] = currentItem }

    return acc
  }, [])

  return filteredDependencies
}

const BookingFormDependenciesWrapper = ({
  form,
  service,
  resources,
  isScheduleForm,
  resourceCategories,
  combinationServices,
  newBookingActivated,
  rescheduleBookingActivated,
  resourceSelectorRescheduleManual
}) => {
  resources ||= []
  resourceCategories ||= []
  combinationServices ||= []
  let {
    isCombination,
    dependencies = []
  } = service || {}
  dependencies = removeDuplicatedDependencies(dependencies)
  const { values: formSelectedDependencies = [] } = form.dependencies || {}
  const resourcesByCategoryId = groupByKey(resources, 'categoryId')
  const areWorkingInParallel = dependencies?.some?.(item => item?.workInParallel)
  const isResourceSelectorEnabled = isScheduleForm ? newBookingActivated : rescheduleBookingActivated
  const hasResourcesSelectedById = Object.entries(form)
    .filter(([key]) => key.includes('dependencies'))
    .reduce((acc, [key, field]) => (acc[key.replace('dependencies', '')] = !!field?.values?.length, acc), {})
  const isServiceShapeBroken = !dependencies.every(dependency => {
    const { specificResourceIds, specificResourceCategoryIds, workInParallel } = dependency || {}
    const areAllResourcesSelected = !specificResourceIds?.length
    const areAllCategoriesSelected = !specificResourceCategoryIds?.length && areAllResourcesSelected
    const dependencyResourceIds = (areAllCategoriesSelected && areAllResourcesSelected)
      ? resourceCategories.flatMap(({ id }) => resourcesByCategoryId[id]).map(({ id }) => id)
      : areAllResourcesSelected
        ? specificResourceCategoryIds?.flatMap?.(categoryId => (resourcesByCategoryId?.[categoryId] || []).map(item => item?.id))
        : specificResourceIds
    const selectedResourceIds = formSelectedDependencies.filter(id => dependencyResourceIds.includes(id))

    return workInParallel
      ? dependencyResourceIds.length === selectedResourceIds.length
      : selectedResourceIds.length === 1
  })
  const isSubServiceShapeBroken = isCombination && combinationServices
    .reduce((acc, curr) => {
      let { id, dependencies } = curr || {}
      dependencies = removeDuplicatedDependencies(dependencies)
      const { values: formSelectedDependencies = [] } = form[`dependencies${id}`] || {}
      const isServiceShapeBroken = !dependencies.every(dependency => {
        const { specificResourceIds, specificResourceCategoryIds, workInParallel } = dependency || {}
        const areAllResourcesSelected = !specificResourceIds?.length
        const areAllCategoriesSelected = !specificResourceCategoryIds?.length && areAllResourcesSelected
        const dependencyResourceIds = (areAllCategoriesSelected && areAllResourcesSelected)
          ? resourceCategories.flatMap(({ id }) => resourcesByCategoryId[id]).map(({ id }) => id)
          : areAllResourcesSelected
            ? specificResourceCategoryIds?.flatMap?.(categoryId => (resourcesByCategoryId?.[categoryId] || []).map(item => item?.id))
            : specificResourceIds
        const selectedResourceIds = formSelectedDependencies.filter(id => dependencyResourceIds.includes(id))

        return workInParallel
          ? dependencyResourceIds.length === selectedResourceIds.length
          : selectedResourceIds.length === 1
      })
      acc[id] = isServiceShapeBroken

      return acc
    }, {})

  let unfulfilledDependencies = dependencies
  if (isScheduleForm && !isCombination) {
    unfulfilledDependencies = dependencies.filter(dependency => {
      const areAllResourcesSelected = !dependency?.specificResourceIds?.length
      const areAllCategoriesSelected = !dependency.specificResourceCategoryIds?.length && areAllResourcesSelected
      const dependencyResourceIds = (areAllCategoriesSelected && areAllResourcesSelected)
        ? resourceCategories.flatMap(({ id }) => resourcesByCategoryId[id]).map(({ id }) => id)
        : areAllResourcesSelected
          ? dependency.specificResourceCategoryIds.flatMap(categoryId => (resourcesByCategoryId[categoryId] || []).map(({ id }) => id))
          : dependency.specificResourceIds
      const selectedResourceIds = formSelectedDependencies.filter(id => dependencyResourceIds.includes(id))

      return !dependency.workInParallel && selectedResourceIds.length > 1
    })
  }
  if (isCombination) {
    unfulfilledDependencies = combinationServices.reduce((acc, curr) => {
      const { id, dependencies } = curr
      let subserviceUnfulfilledDependencies = dependencies
      if (isScheduleForm) {
        subserviceUnfulfilledDependencies = dependencies.filter(dependency => {
          const areAllResourcesSelected = !dependency?.specificResourceIds?.length
          const areAllCategoriesSelected = !dependency.specificResourceCategoryIds?.length && areAllResourcesSelected
          const dependencyResourceIds = (areAllCategoriesSelected && areAllResourcesSelected)
            ? resourceCategories.flatMap(({ id }) => resourcesByCategoryId[id]).map(({ id }) => id)
            : areAllResourcesSelected
              ? dependency.specificResourceCategoryIds.flatMap(categoryId => (resourcesByCategoryId[categoryId] || []).map(({ id }) => id))
              : dependency.specificResourceIds
          const { values: formSelectedDependencies } = form[`dependencies${id}`] || {}
          const selectedResourceIds = formSelectedDependencies.filter(id => dependencyResourceIds.includes(id))

          return !dependency.workInParallel && selectedResourceIds.length > 1
        })
      }

      acc[id] = subserviceUnfulfilledDependencies

      return acc
    }, {})
  }

  return (
    <BookingFormDependencies
      resources={resources}
      isCombination={isCombination}
      isScheduleForm={isScheduleForm}
      resourceCategories={resourceCategories}
      combinationServices={combinationServices}
      isServiceShapeBroken={isServiceShapeBroken}
      areWorkingInParallel={areWorkingInParallel}
      isSubServiceShapeBroken={isSubServiceShapeBroken}
      unfulfilledDependencies={unfulfilledDependencies}
      hasResourcesSelectedById={hasResourcesSelectedById}
      isResourceSelectorEnabled={isResourceSelectorEnabled}
    />
  )
}

const maps = state => ({
  form: selectors.formSelector(state, { formName: 'booking' }),
  newBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'newBookingActivated' }),
  rescheduleBookingActivated: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'rescheduleBookingActivated' }),
  resourceSelectorRescheduleManual: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'resourceSelectorRescheduleManual' }),
  service: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }),
  combinationServices: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'combinationServices', property: 'value' }),
  resources: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resources', property: 'values' }),
  resourceCategories: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'resourceCategories', property: 'values' })
})

export default connect(maps)(BookingFormDependenciesWrapper)
