import React from 'react'
import { logoTimify } from '../../Assets/images'
import {
  LoginForm,
  OAuthAccountItem
} from '../../Beauties'
import {
  BlueTemplate,
  Link,
  Loader,
  Image,
  HoverPopup,
  HoverPopupTrigger,
  HoverPopupContent,
  FontAwesome5,
  t,
  SectionTitle
} from '../../Common'

import './OAuth.css'

const OAuth = props => {
  let {
    locale,
    pendingLanguage,
    pending,
    onSubmit,
    passwordFieldType,
    togglePasswordFieldType,
    hasTokens,
    app,
    accounts,
    logoutUrl,
    onClickGrantAccess,
    onClickDenieAccess
  } = props
  accounts = accounts || []
  const { id: appId, name, imageUrl } = app || {}
  // const { id: appId, name, imageUrl, roles } = app || {}

  return (
    <div className='ta-oauth'>
      <BlueTemplate logoUrl='/'>
        <div className='ta-oauth__box'>
          <div className='ta-oauth__box-content'>
            <Loader active={pendingLanguage || pending} />
            <div className='ta-oauth__title'>
              {t('oauth.title')}
              {(hasTokens &&
                <HoverPopup className='ta-oauth__box__logout'>
                  <HoverPopupContent position='top' autoSize>
                    {t('navigation.logout.tooltip')}
                  </HoverPopupContent>
                  <HoverPopupTrigger>
                    <Link to={logoutUrl}>
                      <FontAwesome5 icon='sign-out-alt' type='solid' />
                    </Link>
                  </HoverPopupTrigger>
                </HoverPopup>
              )}
            </div>
            <div className='ta-oauth__box-content__scrollable'>
              {(appId &&
                <div className='ta-oauth__app'>
                  <div className='ta-oauth__app__title'>
                    {t('oauth.subtitle', [{ key: 'APP_NAME', value: name }])}
                  </div>
                  <div className='ta-oauth__app__logos'>
                    <div className='ta-oauth__app__logos__timify'>
                      <Image src={logoTimify} alt='TIMIFY' />
                    </div>
                    <div className='ta-oauth__app__logos__separator' />
                    <Image src={imageUrl || ''} alt={name} />
                  </div>
                  {(hasTokens &&
                    <div className='ta-oauth__app__permissions'>
                      <SectionTitle label={t('oauth.permissions.title')} icon='shield-alt' />
                    </div>
                  )}
                </div>
              )}
              {(accounts.length > 0 &&
                <div className='ta-oauth__accounts'>
                  {(accounts.length > 1 &&
                    <SectionTitle label={t('oauth.accounts.title')} icon='store-alt' />
                  )}
                  {accounts.map((item, index) => (
                    <OAuthAccountItem
                      key={index}
                      account={item}
                      onClickGrantAccess={onClickGrantAccess}
                    />
                  ))}
                  <div className='ta-btn ta-btn-block ta-btn-ghost' onClick={onClickDenieAccess}>
                    {t('buttons.denyAccess.label')}
                  </div>
                </div>
              )}
              {(!hasTokens &&
                <LoginForm
                  locale={locale}
                  onSubmit={onSubmit}
                  passwordFieldType={passwordFieldType}
                  togglePasswordFieldType={togglePasswordFieldType}
                  hideLanguageSelector
                  hideRememberMe
                />
              )}
            </div>
          </div>
          {/* {REGISTRATION_URL &&
            <div className='ta-oauth__box-footer'>
              <Link to={REGISTRATION_URL.replace(`/${DEFAULT_LOCALE}/`, `/${locale}/`)} target='_blank' external>
                {t('login.newAccount')}
              </Link>
            </div>
          } */}
        </div>
      </BlueTemplate>
    </div>
  )
}

export default OAuth
