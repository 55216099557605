// import { DEFAULT_SERVICE_HEXCOLOR, EMAIL_VALIDATION_REGEX } from '../../../Settings'
import {
// normalizePrice,
// translateServerCode,
// validator,
// getFirstDayOfWeek,
// extractFromHtml,
// removeMultipleSpaces
} from '../../../Utils'

// const days = [...Array(7).keys()].map(key => ({
//   isActive: false,
//   times: []
// }))
// const servicesDefaults = {
//   bookingWeeklyAllowancePlan: [...days],
//   workingWeeklyAllowancePlan: [...days],
//   category: {}
// }

// LIST

export const serviceListTransform = services => {
  if (!services) return
  return services.map(item => ({
    ...item,
    isBatch: (item.durationsPattern || []).length > 1
  }))
}

// export const serviceListErrorTransform = error => {
//   if (!error) return
//   const text = error.text
//     ? error.text
//     : translateServerCode(error.code) !== 'errors.somethingWentWrong'
//       ? translateServerCode(error.code)
//       : ''
//   return {
//     type: error.type || 'error',
//     text
//   }
// }

// // PREVIEW

// export const servicePreviewErrorTransform = error => {
//   if (!error) return
//   const text = error.text
//     ? error.text
//     : translateServerCode(error.code) !== 'errors.somethingWentWrong'
//       ? translateServerCode(error.code)
//       : ''
//   return {
//     type: error.type || 'error',
//     text
//   }
// }

// // FORM

// export const serviceFormTransform = service => {
//   if (!service) service = servicesDefaults
//   if (!service.category) service.category = servicesDefaults.category
//   if (!service.bookingWeeklyAllowancePlan) service.bookingWeeklyAllowancePlan = days
//   if (!service.workingWeeklyAllowancePlan) service.workingWeeklyAllowancePlan = days
//   const availableResources = service.availableResources || []
//   const availableCategories = service.availableCategories || []
//   const dependencies = {
//     values: (service.dependencies && service.dependencies.map(dependency => {
//       const dependencyCategory = dependency.specificResourceCategoryIds && dependency.specificResourceCategoryIds[0]
//       const resourcesCategoriesIds = [...new Set(
//         (dependency.specificResourceIds && dependency.specificResourceIds.length > 0
//           ? availableResources.filter(item => dependency.specificResourceIds.includes(item.id))
//           : availableResources
//         ).map(item => item.categoryId)
//       )]
//       return {
//         categoryId: dependency.specificResourceIds
//           ? resourcesCategoriesIds.length > 1 ? 'all' : resourcesCategoriesIds[0]
//           : dependencyCategory,
//         workInParallel: dependency.workInParallel,
//         resourceIds: dependency.specificResourceIds
//           ? dependency.specificResourceIds.length === 0
//             ? ['all']
//             : dependency.specificResourceIds
//           : ['all']
//       }
//     })) || [],
//     resources: availableResources.map(resource => ({
//       value: resource.id,
//       label: resource.name,
//       categoryId: resource.categoryId,
//       avatarImage: resource.avatarUrl,
//       avatarName: resource.name,
//       avatarColor: resource.color,
//       abbreviation: resource.abbreviation
//     })),
//     categories: availableCategories.map(category => ({
//       value: category.id,
//       label: category.name,
//       isDependency: category.isDependency
//     }))
//   }
//   const companyCustomerEmailRemindersMinutes = service.companySettings && service.companySettings.customerEmailRemindersMinutes
//   const companyResourceEmailRemindersMinutes = service.companySettings && service.companySettings.resourceEmailRemindersMinutes
//   const serviceCustomReminderCustomerSwitch = (!service.customerEmailRemindersMinutes || JSON.stringify(service.customerEmailRemindersMinutes) === JSON.stringify(companyCustomerEmailRemindersMinutes))
//     ? false
//     : service.customerEmailRemindersMinutes !== null
//   const serviceCustomReminderStaffSwitch = (!service.resourceEmailRemindersMinutes || JSON.stringify(service.resourceEmailRemindersMinutes) === JSON.stringify(companyResourceEmailRemindersMinutes))
//     ? false
//     : service.resourceEmailRemindersMinutes !== null
//   const customerEmailRemindersMinutes = serviceCustomReminderCustomerSwitch ? (service.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
//   const resourceEmailRemindersMinutes = serviceCustomReminderStaffSwitch ? (service.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
//   const resourceRemindersEmailOtherRecipients = service.companySettings && service.companySettings.resourceRemindersEmailRecipients && service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
//     ? service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
//     : ['']
//   const result = {
//     name: {
//       value: service.name || ''
//     },
//     description: {
//       value: service.description || ''
//     },
//     color: {
//       value: service.color || DEFAULT_SERVICE_HEXCOLOR
//     },
//     price: {
//       value: (service.price && service.price.toFixed(2)) || ''
//     },
//     duration: {
//       value: service.duration || null
//     },
//     durationBefore: {
//       value: service.durationBefore || null
//     },
//     durationAfter: {
//       value: service.durationAfter || null
//     },
//     showDurationBeforeAndAfter: {
//       value: service.durationBefore || service.durationAfter
//     },
//     splitDurationInIntervals: {
//       value: service.isBatch
//     },
//     intervals: {
//       values: service.durationsPattern && service.durationsPattern.length > 2 ? service.durationsPattern : [0, 0, 0]
//     },
//     hasAllResources: {
//       value: !!service.hasAllResources
//     },
//     resources: {
//       values: (!service.hasAllResources && service.resources && service.resources.map(resource => resource.id)) || [],
//       options: (service.availableResources && service.availableResources.map(resource => ({
//         value: resource.id,
//         label: resource.name
//       }))) || []
//     },
//     dependencies,
//     isActive: {
//       value: service.isBookable === undefined ? true : !!service.isBookable
//     },
//     hasOnlinePayment: {
//       value: service.hasOnlinePayment || false
//     },
//     isOnlinePaymentMandatory: {
//       value: service.isPaymentMandatory || false
//     },
//     orderIndex: {
//       value: service.orderIndex
//     },
//     category: {
//       value: service.category.id,
//       type: 'hidden'
//     },
//     categoryName: {
//       value: service.category.name
//     },
//     externalId: {
//       value: service.externalId || ''
//     },
//     ownExternalId: service.externalId,
//     hasSpecificBookingDays: {
//       value: !!service.hasSpecificBookingDays
//     },
//     serviceCustomReminderCustomerSwitch: {
//       value: serviceCustomReminderCustomerSwitch
//     },
//     serviceReminderCustomerSwitch: {
//       value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
//     },
//     customerEmailRemindersMinutes: {
//       values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
//     },
//     serviceCustomReminderStaffSwitch: {
//       value: serviceCustomReminderStaffSwitch
//     },
//     serviceReminderStaffSwitch: {
//       value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length > 0) : false
//     },
//     resourceEmailRemindersMinutes: {
//       values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
//     },
//     serviceReminderStaffOwnerCheckBox: {
//       value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
//     },
//     serviceReminderStaffResourcesCheckBox: {
//       value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
//     },
//     serviceReminderStaffOthersCheckBox: {
//       value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
//     },
//     serviceReminderStaffOthers: {
//       values: resourceRemindersEmailOtherRecipients || ['']
//     }
//   }
//   const firstDayOfWeek = getFirstDayOfWeek()
//   const firstIndex = firstDayOfWeek === 1 ? 7 : 0

//   // Flatten booking weekly allowance plan
//   if (service.calendar && service.calendar.bookingWeeklyAllowancePlan) {
//     (service.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
//       result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
//         isActive: !!item.isActive,
//         values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
//       }
//     })
//   }

//   if (service.id) result.id = service.id
//   return result
// }

// export const serviceFormValidate = (service, stripeMinPrice, locale, currency, externalIds) => {
//   if (!service) return
//   const serviceExternalId = service.externalId && service.externalId.value
//   const serviceCustomReminderCustomerSwitch = service.serviceCustomReminderCustomerSwitch && service.serviceCustomReminderCustomerSwitch.value
//   const serviceReminderCustomerSwitch = service.serviceReminderCustomerSwitch && service.serviceReminderCustomerSwitch.value
//   const customerEmailRemindersMinutes = (service.customerEmailRemindersMinutes && service.customerEmailRemindersMinutes.values) || []
//   const serviceCustomReminderStaffSwitch = service.serviceCustomReminderStaffSwitch && service.serviceCustomReminderStaffSwitch.value
//   const serviceReminderStaffSwitch = service.serviceReminderStaffSwitch && service.serviceReminderStaffSwitch.value
//   const serviceReminderStaffOthers = (service.serviceReminderStaffOthersCheckBox && service.serviceReminderStaffOthersCheckBox.value && service.serviceReminderStaffOthers && service.serviceReminderStaffOthers.values) || []
//   const resourceEmailRemindersMinutes = (service.resourceEmailRemindersMinutes && service.resourceEmailRemindersMinutes.values) || []
//   const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)

//   const rules = [
//     { 'externalId.value': ['max:255'] },
//     { 'name.value': ['required', 'max:255'] },
//     { 'duration.value': [`requiredIf:${!service.splitDurationInIntervals.value}`] },
//     { 'price.value': [`requiredIf:${!!service.hasOnlinePayment.value}`, 'price', 'max:32'] }
//   ]

//   const messages = {
//     'externalId': {
//       'max': 'errors.invalidMaxLength'
//     },
//     'name': {
//       'required': 'errors.required',
//       'max': 'errors.invalidMaxLength'
//     },
//     'duration': {
//       'requiredIf': 'errors.services.chooseDuration'
//     },
//     'description': {
//       'max': 'errors.invalidMaxLength'
//     },
//     'price': {
//       'requiredIf': 'errors.required',
//       'price': 'errors.price.invalid',
//       'max': 'errors.invalidMaxLength'
//     }
//   }
//   const replaces = {
//     'externalId': {
//       'max': {
//         key: 'MAX',
//         value: '255'
//       }
//     },
//     'name': {
//       'required': {
//         key: 'FIELD_NAME',
//         value: 'global.name.label',
//         translateReplace: true
//       },
//       'max': {
//         key: 'MAX',
//         value: '255'
//       }
//     },
//     'description': {
//       'max': {
//         key: 'MAX',
//         value: '400'
//       }
//     },
//     'price': {
//       'requiredIf': {
//         key: 'FIELD_NAME',
//         value: 'global.price.label',
//         translateReplace: true
//       },
//       'max': {
//         key: 'MAX',
//         value: '32'
//       }
//     }
//   }

//   let tabWithError = 'global.summary'
//   const errors = validator(service, rules, messages, replaces)

//   // ADVANCED VALIDATION

//   // external id
//   externalIds = externalIds.filter(item => item !== service.ownExternalId)
//   if (externalIds.includes(serviceExternalId)) {
//     errors.push({
//       key: 'externalId',
//       value: 'errors.externalId.exists'
//     })
//   }

//   // Duration (max 60 days)
//   if (service.duration && service.duration.value && service.duration.value > 60 * 24 * 60) {
//     errors.push({ key: 'duration', value: 'errors.duration.limit' })
//   }

//   // Prep-followUp times (max 60 days)
//   if (service.durationBefore && service.durationBefore.value && service.durationBefore.value > 60 * 24 * 60) {
//     errors.push({ key: 'durationBefore', value: 'errors.duration.limit' })
//   }
//   if (service.durationAfter && service.durationAfter.value && service.durationAfter.value > 60 * 24 * 60) {
//     errors.push({ key: 'durationAfter', value: 'errors.duration.limit' })
//   }

//   // Price 0 and online payment
//   if (service.price && service.price.value && parseFloat(service.price.value) === 0 && !!service.hasOnlinePayment.value) {
//     tabWithError = 'global.summary'
//     errors.push({
//       key: 'price',
//       value: 'errors.required',
//       replace: [replaces.price.requiredIf]
//     })
//   }

//   // Description without HTML max 400 characters
//   if (service.description && service.description.value && extractFromHtml(removeMultipleSpaces(service.description.value)).trim().length > 400) {
//     tabWithError = 'global.summary'
//     errors.push({
//       key: 'description',
//       value: messages.description.max,
//       replace: [replaces.description.max]
//     })
//   }

//   if (!!service.hasOnlinePayment.value && stripeMinPrice && service.price && service.price.value && parseFloat(service.price.value) < stripeMinPrice) {
//     const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
//     tabWithError = 'global.summary'
//     errors.push({
//       key: 'price',
//       value: 'errors.services.stripeMinPrice',
//       replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
//     })
//   }

//   // Resources
//   if (service.dependencies && service.dependencies.values && service.dependencies.values.length > 0) {
//     service.dependencies.values.forEach((item, index) => {
//       if (!item.categoryId) {
//         tabWithError = 'global.summary'
//         errors.push({
//           key: 'dependencies',
//           value: 'errors.servicesGroups.selectResourceCategory',
//           index
//         })
//       } else if (!item.resourceIds || (item.resourceIds && item.resourceIds.length === 0)) {
//         tabWithError = 'global.summary'
//         errors.push({
//           key: 'dependencies',
//           value: 'errors.servicesGroups.selectResource',
//           index
//         })
//       }
//     })
//   }

//   // Intervals
//   if (service.splitDurationInIntervals.value && service.intervals && service.intervals.values && service.intervals.values.includes(0)) {
//     tabWithError = 'global.availability'
//     errors.push({ key: 'intervals', value: 'errors.intervals.invalid' })
//   }
//   if (
//     service.splitDurationInIntervals.value &&
//     service.intervals.values &&
//     service.intervals.values.reduce((sum, item) => sum + item, 0) > 60 * 24 * 60
//   ) {
//     errors.push({ key: 'intervals', value: 'errors.duration.limit' })
//   }

//   // bookingWeeklyAllowancePlan interval validation
//   const bookingWeeklyAllowancePlanDays = Object
//     .keys(service)
//     .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
//     .map(key => ({
//       index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
//       isActive: service[key].isActive,
//       intervals: service[key].values
//     }))
//   const areAllDaysInactive = bookingWeeklyAllowancePlanDays.reduce((acc, item) => {
//     const hasNoValues = item.isActive && item.intervals[0] && !item.intervals[0].from && !item.intervals[0].until
//     return acc && (!item.isActive || hasNoValues)
//   }, true)
//   if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value && areAllDaysInactive) {
//     tabWithError = 'global.availability'
//     errors.push({
//       key: `hasSpecificBookingDays`,
//       value: 'errors.services.bookingTime.interval.atLeastOne'
//     })
//   }
//   bookingWeeklyAllowancePlanDays.forEach(item => {
//     if (!item.isActive) return
//     item.intervals && (item.intervals || []).forEach((interval, intervalIndex) => {
//       // Missing from and until
//       if (item.intervals.length > 1 && !interval.from && !interval.until) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
//           value: 'errors.services.bookingTime.interval.required',
//           index: intervalIndex
//         })
//       }
//       // Missing only from
//       if (!interval.from && interval.until) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
//           value: 'errors.services.bookingTime.interval.missingIntervalFromValue',
//           index: intervalIndex
//         })
//       }
//       // Missing only until
//       if (interval.from && !interval.until) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
//           value: 'errors.services.bookingTime.interval.missingIntervalUntilValue',
//           index: intervalIndex
//         })
//       }
//       // The same from and until
//       if (interval.from && interval.until && interval.from === interval.until) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
//           value: 'errors.services.bookingTime.interval.fromUntilNotDifferent',
//           index: intervalIndex
//         })
//       }
//       // Interval end on next day
//       if (interval.from > interval.until) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: `bookingWeeklyAllowancePlanDay${item.index}Intervals`,
//           value: 'errors.services.bookingTime.interval.mustEndSameDay',
//           replace: [
//             { key: 'FROM', value: interval.from },
//             { key: 'UNTIL', value: interval.until }
//           ],
//           index: intervalIndex
//         })
//       }
//     })
//   })

//   // service specific customer reminders
//   if (serviceCustomReminderCustomerSwitch && serviceReminderCustomerSwitch) {
//     customerEmailRemindersMinutes.forEach(item => {
//       if (!item || item < 0) {
//         tabWithError = 'global.advanced'
//         errors.push({
//           key: 'customerEmailRemindersMinutes',
//           value: 'errors.reminder.incorrect',
//           replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
//         })
//       }
//     })
//   }

//   // service specific staff reminders
//   if (serviceCustomReminderStaffSwitch && serviceReminderStaffSwitch) {
//     if (!service.serviceReminderStaffOthersCheckBox.value && !service.serviceReminderStaffResourcesCheckBox.value && !service.serviceReminderStaffOwnerCheckBox.value) {
//       tabWithError = 'global.advanced'
//       errors.push({
//         key: 'serviceReminderStaffOthersCheckBox',
//         value: 'errors.service.reminders.atLeastOneStaff'
//       })
//     }
//     serviceReminderStaffOthers.forEach(item => {
//       if (item && !emailRegex.test(item)) {
//         tabWithError = 'global.advanced'
//         errors.push({
//           key: 'serviceReminderStaffOthers',
//           value: 'errors.email.invalidWithVariable',
//           replace: [{ key: 'EMAIL', value: item }]
//         })
//       }
//       if (!item) {
//         tabWithError = 'global.advanced'
//         errors.push({ key: 'serviceReminderStaffOthers', value: 'errors.email.required' })
//       }
//     })
//     resourceEmailRemindersMinutes.forEach(item => {
//       if (!item || item < 0) {
//         tabWithError = 'global.advanced'
//         errors.push({
//           key: 'resourceEmailRemindersMinutes',
//           value: 'errors.reminder.incorrect',
//           replace: [{ key: 'FIELD_NAME', value: 'global.resource', translateReplace: true }]
//         })
//       }
//     })
//   }

//   if (errors && errors.length) {
//     errors.push({
//       key: 'globalErrors',
//       value: 'errors.tabs.followingTabs',
//       replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
//     })
//   }
//   return errors
// }

// export const serviceFormServerErrorsTransform = (error, stripeMinPrice, locale, currency) => {
//   const errors = []

//   if (error.code === 'ExternalIdConflicts') {
//     errors.push({ key: 'externalId', value: translateServerCode(error.code) })
//   } else if (error.code === 'NoCalendarWithoutEnterprisePlan') {
//     errors.push({ key: 'hasSpecificBookingDays', value: translateServerCode(error.code) })
//   } else if (error.code === 'MinAmountStripe') {
//     const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
//     errors.push({
//       key: 'price',
//       value: 'errors.services.stripeMinPrice',
//       replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
//     })
//   } else {
//     errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
//   }

//   return errors
// }

// export const serviceSaveTransform = service => {
//   const dependencies = service.dependencies.values.map(dependency => ({
//     workInParallel: dependency.workInParallel,
//     specificResourceCategoryIds: dependency.categoryId === 'all'
//       ? null
//       : (dependency.resourceIds || []).includes('all')
//         ? [dependency.categoryId]
//         : null,
//     specificResourceIds: (dependency.resourceIds || []).includes('all')
//       ? dependency.categoryId === 'all'
//         ? []
//         : null
//       : dependency.resourceIds || []
//   }))
//   const hasDescription = service.description && extractFromHtml(service.description.value)

//   const result = {
//     id: service.id,
//     categoryId: service.category && service.category.value,
//     name: service.name && service.name.value,
//     description: hasDescription ? service.description.value : null,
//     dependencies,
//     durationBefore: 0,
//     durationAfter: 0,
//     color: service.color && service.color.value,
//     price: (service.price && service.price.value && normalizePrice(service.price.value)) || 0,
//     isBookable: service.isActive && service.isActive.value,
//     hasOnlinePayment: service.hasOnlinePayment && service.hasOnlinePayment.value,
//     isPaymentMandatory: service.isOnlinePaymentMandatory && service.isOnlinePaymentMandatory.value,
//     externalId: (service.externalId && service.externalId.value) || null,
//     calendar: null,
//     customerEmailRemindersMinutes: !service.serviceCustomReminderCustomerSwitch.value ? null : !service.serviceReminderCustomerSwitch.value ? [] : service.customerEmailRemindersMinutes.values,
//     resourceEmailRemindersMinutes: !service.serviceCustomReminderStaffSwitch.value ? null : !service.serviceReminderStaffSwitch.value ? [] : service.resourceEmailRemindersMinutes.values
//   }

//   if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value) {
//     const days = [...Array(7).keys()].map(() => ({}))
//     const calendar = {
//       timezone: service.companyTimezone,
//       bookingWeeklyAllowancePlan: [...days],
//       workingWeeklyAllowancePlan: [...days]
//     }

//     // Booking weekly allowance plans
//     Object
//       .keys(service)
//       .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
//       .map(key => ({
//         key,
//         index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
//         isActive: service[key].isActive,
//         intervals: service[key].values
//       }))
//       .forEach(item => {
//         const index = (item.index === 0 || item.index === 7) ? 0 : item.index
//         const allowancePlan = {
//           isActive: !!item.isActive,
//           times: item.intervals
//             ? [...(item.intervals || []).map(interval => ({ begin: interval.from, end: interval.until }))]
//             : []
//         }
//         calendar.bookingWeeklyAllowancePlan[index] = allowancePlan
//         calendar.workingWeeklyAllowancePlan[index] = allowancePlan
//       })

//     result.calendar = calendar
//   }

//   // Normal booking
//   if (!service.splitDurationInIntervals.value) {
//     result.duration = service.duration.value
//   }
//   // Batch booking
//   if (service.splitDurationInIntervals.value) {
//     result.durationsPattern = service.intervals.values
//   }

//   // Duration before and after
//   if (service.showDurationBeforeAndAfter && service.showDurationBeforeAndAfter.value) {
//     const durationBefore = service.durationBefore.value
//     const durationAfter = service.durationAfter.value
//     if (durationBefore) result.durationBefore = durationBefore
//     if (durationAfter) result.durationAfter = durationAfter
//   }

//   return result
// }

// // UPDATE

// export const serviceTransform = service => {
//   if (!service) return
//   const { error } = service || {}
//   if (error) return
//   const result = {
//     ...service,
//     isBatch: (service.durationsPattern || []).length > 1
//   }
//   if (service.calendar) result.hasSpecificBookingDays = true
//   return result
// }

// // CATEGORY FORM

// export const serviceCategoryFormTransform = (category = {}) => ({
//   id: {
//     value: category.id || ''
//   },
//   externalId: {
//     value: category.externalId || ''
//   },
//   ownExternalId: category.externalId,
//   name: {
//     value: category.name || ''
//   }
// })

// export const serviceCategoryFormValidate = (category, externalIds) => {
//   if (!category) return
//   const rules = [
//     { 'name.value': ['required'] }
//   ]

//   const messages = {
//     'name': {
//       'required': 'errors.required'
//     }
//   }
//   const replaces = {
//     'name': {
//       'required': {
//         key: 'FIELD_NAME',
//         value: 'global.name.label',
//         translateReplace: true
//       }
//     }
//   }

//   const errors = validator(category, rules, messages, replaces)

//   // external Id
//   const externalIdValue = category.externalId && category.externalId.value
//   externalIds = externalIds.filter(item => item !== category.ownExternalId)
//   if (externalIds.includes(externalIdValue)) {
//     errors.push({
//       key: 'externalId',
//       value: 'errors.externalId.exists'
//     })
//   }

//   return errors.length && errors
// }

// export const serviceCategoryFormServerErrorsTransform = error => {
//   const errors = []
//   if (error.code) {
//     errors.push({ key: 'name', value: translateServerCode(error.code) })
//   } else {
//     errors.push({ key: 'serviceCategoryGlobalError', value: 'errors.somethingWentWrong' })
//   }
//   return errors
// }

// // CATEGORY SAVE

// export const serviceCategorySaveTransform = category => ({
//   id: category.id && category.id.value,
//   name: category.name && category.name.value,
//   externalId: (category.externalId && category.externalId.value && category.externalId.value.trim() !== '')
//     ? category.externalId.value
//     : null
// })

// // COMBINATION FORM

// export const serviceCombinationFormTransform = service => {
//   service = service || servicesDefaults
//   service.category = service.category || servicesDefaults.category
//   service.categorizedServices = service.categorizedServices || []

//   const companyCustomerEmailRemindersMinutes = service.companySettings && service.companySettings.customerEmailRemindersMinutes
//   const companyResourceEmailRemindersMinutes = service.companySettings && service.companySettings.resourceEmailRemindersMinutes
//   const serviceCustomReminderCustomerSwitch = (!service.customerEmailRemindersMinutes || JSON.stringify(service.customerEmailRemindersMinutes) === JSON.stringify(companyCustomerEmailRemindersMinutes))
//     ? false
//     : service.customerEmailRemindersMinutes !== null
//   const serviceCustomReminderStaffSwitch = (!service.resourceEmailRemindersMinutes || JSON.stringify(service.resourceEmailRemindersMinutes) === JSON.stringify(companyResourceEmailRemindersMinutes))
//     ? false
//     : service.resourceEmailRemindersMinutes !== null
//   const customerEmailRemindersMinutes = serviceCustomReminderCustomerSwitch ? (service.customerEmailRemindersMinutes || companyCustomerEmailRemindersMinutes) : null
//   const resourceEmailRemindersMinutes = serviceCustomReminderStaffSwitch ? (service.resourceEmailRemindersMinutes || companyResourceEmailRemindersMinutes) : null
//   const resourceRemindersEmailOtherRecipients = service.companySettings && service.companySettings.resourceRemindersEmailRecipients && service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource').length > 0
//     ? service.companySettings.resourceRemindersEmailRecipients.filter(item => item !== 'owner' && item !== 'resource')
//     : ['']
//   const result = {
//     name: {
//       value: service.name || ''
//     },
//     description: {
//       value: service.description || ''
//     },
//     hasPriceOverwrite: {
//       value: !!service.isCombinationPriceOverwritten
//     },
//     price: {
//       value: (service.price && service.price.toFixed(2)) || ''
//     },
//     isActive: {
//       value: service.isBookable === undefined ? true : !!service.isBookable
//     },
//     hasOnlinePayment: {
//       value: service.hasOnlinePayment || false
//     },
//     isOnlinePaymentMandatory: {
//       value: service.isPaymentMandatory || false
//     },
//     orderIndex: {
//       value: service.orderIndex
//     },
//     category: {
//       value: service.category.id,
//       type: 'hidden'
//     },
//     categoryName: {
//       value: service.category.name
//     },
//     externalId: {
//       value: service.externalId || ''
//     },
//     ownExternalId: service.externalId, // needed for external id validation
//     services: {
//       values: service.combinationServiceIds || [],
//       options: service.categorizedServices
//     },
//     hasCombinationSameResourcesPreference: {
//       value: !!service.hasCombinationSameResourcesPreference
//     },
//     hasSpecificBookingDays: {
//       value: !!service.hasSpecificBookingDays
//     },
//     durationBefore: {
//       value: service.durationBefore || null
//     },
//     durationAfter: {
//       value: service.durationAfter || null
//     },
//     showDurationBeforeAndAfter: {
//       value: service.durationBefore || service.durationAfter
//     },
//     serviceCustomReminderCustomerSwitch: {
//       value: serviceCustomReminderCustomerSwitch
//     },
//     serviceReminderCustomerSwitch: {
//       value: customerEmailRemindersMinutes !== null ? (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length > 0) : false
//     },
//     customerEmailRemindersMinutes: {
//       values: (customerEmailRemindersMinutes && customerEmailRemindersMinutes.length) ? customerEmailRemindersMinutes : [0]
//     },
//     serviceCustomReminderStaffSwitch: {
//       value: serviceCustomReminderStaffSwitch
//     },
//     serviceReminderStaffSwitch: {
//       value: resourceEmailRemindersMinutes !== null ? (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length > 0) : false
//     },
//     resourceEmailRemindersMinutes: {
//       values: (resourceEmailRemindersMinutes && resourceEmailRemindersMinutes.length) ? resourceEmailRemindersMinutes : [0]
//     },
//     serviceReminderStaffOwnerCheckBox: {
//       value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('owner') > 0 : false
//     },
//     serviceReminderStaffResourcesCheckBox: {
//       value: service.companySettings && service.companySettings.resourceRemindersEmailRecipients ? service.companySettings.resourceRemindersEmailRecipients.includes('resource') : false
//     },
//     serviceReminderStaffOthersCheckBox: {
//       value: resourceRemindersEmailOtherRecipients.length >= 1 && resourceRemindersEmailOtherRecipients[0] !== ''
//     },
//     serviceReminderStaffOthers: {
//       values: resourceRemindersEmailOtherRecipients || ['']
//     }
//   }
//   const firstDayOfWeek = getFirstDayOfWeek()
//   const firstIndex = firstDayOfWeek === 1 ? 7 : 0

//   // Flatten booking weekly allowance plan
//   if (service.calendar && service.calendar.bookingWeeklyAllowancePlan) {
//     (service.calendar.bookingWeeklyAllowancePlan || []).forEach((item, itemIndex) => {
//       result[`bookingWeeklyAllowancePlanDay${itemIndex === 0 ? firstIndex : itemIndex}Intervals`] = {
//         isActive: !!item.isActive,
//         values: (item.times || []).map(time => ({ from: time.begin, until: time.end }))
//       }
//     })
//   }

//   if (service.id) result.id = service.id
//   return result
// }

// export const serviceCombinationFormValidate = (service, stripeMinPrice, locale, currency, externalIds) => {
//   if (!service) return
//   const {
//     allServices,
//     services,
//     hasPriceOverwrite,
//     hasOnlinePayment,
//     externalId
//   } = service || {}
//   let { values: selectedServices } = services || {}
//   const { value: externalIdValue } = externalId || {}
//   const { value: hasPriceOverwriteValue } = hasPriceOverwrite || {}
//   const { value: hasOnlinePaymentValue } = hasOnlinePayment || {}
//   selectedServices = selectedServices || []
//   const filteredServices = allServices.filter(item => selectedServices.includes(item.id))
//   const combinationPrice = filteredServices.reduce((acc, item) => {
//     acc = acc + (item.price || 0)
//     return acc
//   }, 0)
//   const serviceCustomReminderCustomerSwitch = service.serviceCustomReminderCustomerSwitch && service.serviceCustomReminderCustomerSwitch.value
//   const serviceReminderCustomerSwitch = service.serviceReminderCustomerSwitch && service.serviceReminderCustomerSwitch.value
//   const customerEmailRemindersMinutes = (service.customerEmailRemindersMinutes && service.customerEmailRemindersMinutes.values) || []
//   const serviceCustomReminderStaffSwitch = service.serviceCustomReminderStaffSwitch && service.serviceCustomReminderStaffSwitch.value
//   const serviceReminderStaffSwitch = service.serviceReminderStaffSwitch && service.serviceReminderStaffSwitch.value
//   const serviceReminderStaffOthers = (service.serviceReminderStaffOthersCheckBox && service.serviceReminderStaffOthersCheckBox.value && service.serviceReminderStaffOthers && service.serviceReminderStaffOthers.values) || []
//   const resourceEmailRemindersMinutes = (service.resourceEmailRemindersMinutes && service.resourceEmailRemindersMinutes.values) || []
//   const emailRegex = new RegExp(EMAIL_VALIDATION_REGEX)
//   const rules = [
//     { 'externalId.value': ['max:255'] },
//     { 'name.value': ['required', 'max:255'] },
//     { 'description.value': ['max:400'] },
//     { 'price.value': [`requiredIf:${(!!hasOnlinePaymentValue && !combinationPrice) || !!hasPriceOverwriteValue}`, 'price', 'max:32'] },
//     { 'hasPriceOverwrite.value': [`requiredIf:${!!hasOnlinePaymentValue && !combinationPrice}`] }
//   ]
//   const messages = {
//     'externalId': {
//       'max': 'errors.invalidMaxLength'
//     },
//     'name': {
//       'required': 'errors.required',
//       'max': 'errors.invalidMaxLength'
//     },
//     'description': {
//       'max': 'errors.invalidMaxLength'
//     },
//     'price': {
//       'requiredIf': 'errors.required',
//       'price': 'errors.price.invalid',
//       'max': 'errors.invalidMaxLength'
//     },
//     'hasPriceOverwrite': {
//       'requiredIf': 'errors.required'
//     }
//   }
//   const replaces = {
//     'externalId': {
//       'max': {
//         key: 'MAX',
//         value: '255'
//       }
//     },
//     'name': {
//       'required': {
//         key: 'FIELD_NAME',
//         value: 'global.name.label',
//         translateReplace: true
//       },
//       'max': {
//         key: 'MAX',
//         value: '255'
//       }
//     },
//     'description': {
//       'max': {
//         key: 'MAX',
//         value: '400'
//       }
//     },
//     'price': {
//       'requiredIf': {
//         key: 'FIELD_NAME',
//         value: 'global.price.label',
//         translateReplace: true
//       },
//       'max': {
//         key: 'MAX',
//         value: '32'
//       }
//     },
//     'hasPriceOverwrite': {
//       'requiredIf': {
//         key: 'FIELD_NAME',
//         value: 'global.price.label',
//         translateReplace: true
//       }
//     }
//   }

//   let tabWithError = 'global.summary'
//   const errors = validator(service, rules, messages, replaces)

//   // ADVANCED VALIDATION

//   // external Id
//   externalIds = externalIds.filter(item => item !== service.ownExternalId)
//   if (externalIds.includes(externalIdValue)) {
//     errors.push({
//       key: 'externalId',
//       value: 'errors.externalId.exists'
//     })
//   }

//   // Price 0 and online payment
//   if (service.price && service.price.value && parseFloat(service.price.value) === 0 && !!service.hasOnlinePayment.value) {
//     tabWithError = 'global.summary'
//     errors.push({
//       key: 'price',
//       value: 'errors.required',
//       replace: [replaces.price.requiredIf]
//     })
//   }

//   if (!!service.hasOnlinePayment.value && stripeMinPrice && service.price && service.price.value && parseFloat(service.price.value) < stripeMinPrice) {
//     tabWithError = 'global.summary'
//     const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
//     errors.push({
//       key: 'price',
//       value: 'errors.services.stripeMinPrice',
//       replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
//     })
//   }

//   if (selectedServices.length < 2) {
//     tabWithError = 'global.summary'
//     errors.push({
//       key: 'services',
//       value: 'errors.services.minimunAmountRequired'
//     })
//   }

//   if (serviceCustomReminderCustomerSwitch && serviceReminderCustomerSwitch) {
//     customerEmailRemindersMinutes.forEach(item => {
//       if (!item || item < 0) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: 'customerEmailRemindersMinutes',
//           value: 'errors.reminder.incorrect',
//           replace: [{ key: 'FIELD_NAME', value: 'global.customer', translateReplace: true }]
//         })
//       }
//     })
//   }

//   if (serviceCustomReminderStaffSwitch && serviceReminderStaffSwitch) {
//     if (!service.serviceReminderStaffOthersCheckBox.value && !service.serviceReminderStaffResourcesCheckBox.value && !service.serviceReminderStaffOwnerCheckBox.value) {
//       tabWithError = 'global.availability'
//       errors.push({
//         key: 'serviceReminderStaffOthersCheckBox',
//         value: 'errors.service.reminders.atLeastOneStaff'
//       })
//     }
//     serviceReminderStaffOthers.forEach(item => {
//       if (item && !emailRegex.test(item)) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: 'serviceReminderStaffOthers',
//           value: 'errors.email.invalidWithVariable',
//           replace: [{ key: 'EMAIL', value: item }]
//         })
//       }
//       if (!item) {
//         tabWithError = 'global.availability'
//         errors.push({ key: 'serviceReminderStaffOthers', value: 'errors.email.required' })
//       }
//     })
//     resourceEmailRemindersMinutes.forEach(item => {
//       if (!item || item < 0) {
//         tabWithError = 'global.availability'
//         errors.push({
//           key: 'resourceEmailRemindersMinutes',
//           value: 'errors.reminder.incorrect',
//           replace: [{ key: 'FIELD_NAME', value: 'global.resource', translateReplace: true }]
//         })
//       }
//     })
//   }

//   if (errors && errors.length) {
//     errors.push({
//       key: 'globalErrors',
//       value: 'errors.tabs.followingTabs',
//       replace: [{ key: 'TAB_NAME', value: tabWithError, translateReplace: true }]
//     })
//   }
//   return errors
// }

// export const serviceCombinationFormServerErrorsTransform = (error, stripeMinPrice, locale, currency) => {
//   const errors = []

//   if (error.code === 'ExternalIdConflicts') {
//     errors.push({ key: 'externalId', value: translateServerCode(error.code) })
//   } else if (error.code === 'NoCalendarWithoutEnterprisePlan') {
//     errors.push({ key: 'hasSpecificBookingDays', value: translateServerCode(error.code) })
//   } else if (error.code === 'MinAmountStripe') {
//     const formattedPrice = new Intl.NumberFormat(locale, { style: 'currency', currency }).format(stripeMinPrice)
//     errors.push({
//       key: 'price',
//       value: 'errors.services.stripeMinPrice',
//       replace: [{ key: 'MIN_PRICE', value: formattedPrice }]
//     })
//   } else if (error.code === 'NoCombinationsWithSplit') {
//     errors.push({
//       key: 'services',
//       value: 'errors.services.minimunAmountRequired'
//     })
//   } else if (error.code === 'CombinationServicesNotFound') {
//     errors.push({ key: 'services', value: translateServerCode(error.code) })
//   } else {
//     errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
//   }

//   return errors
// }

// export const serviceCombinationSaveTransform = service => {
//   let {
//     id,
//     category,
//     name,
//     description,
//     hasPriceOverwrite,
//     price,
//     isActive,
//     hasOnlinePayment,
//     isOnlinePaymentMandatory,
//     externalId,
//     hasCombinationSameResourcesPreference,
//     services,
//     showDurationBeforeAndAfter,
//     durationBefore,
//     durationAfter
//   } = service || {}
//   category = category || {}
//   name = name || {}
//   description = description || {}
//   hasPriceOverwrite = hasPriceOverwrite || {}
//   price = price || {}
//   isActive = isActive || {}
//   hasOnlinePayment = hasOnlinePayment || {}
//   isOnlinePaymentMandatory = isOnlinePaymentMandatory || {}
//   externalId = externalId || {}
//   hasCombinationSameResourcesPreference = hasCombinationSameResourcesPreference || {}
//   services = services || {}
//   const hasDescription = !!extractFromHtml(description.value)

//   const result = {
//     isCombination: true,
//     id: id,
//     categoryId: category.value,
//     name: name.value,
//     description: hasDescription ? description.value : null,
//     isCombinationPriceOverwritten: !!hasPriceOverwrite.value,
//     price: hasPriceOverwrite.value ? price.value && normalizePrice(price.value) : null,
//     isBookable: isActive.value,
//     hasOnlinePayment: hasOnlinePayment.value,
//     isPaymentMandatory: isOnlinePaymentMandatory.value,
//     externalId: externalId.value || null,
//     durationBefore: 0,
//     durationAfter: 0,
//     hasCombinationSameResourcesPreference: !!hasCombinationSameResourcesPreference.value,
//     combinationServiceIds: services.values || [],
//     calendar: null,
//     customerEmailRemindersMinutes: !service.serviceCustomReminderCustomerSwitch.value ? null : !service.serviceReminderCustomerSwitch.value ? [] : service.customerEmailRemindersMinutes.values,
//     resourceEmailRemindersMinutes: !service.serviceCustomReminderStaffSwitch.value ? null : !service.serviceReminderStaffSwitch.value ? [] : service.resourceEmailRemindersMinutes.values
//   }

//   if (service.hasSpecificBookingDays && service.hasSpecificBookingDays.value) {
//     const days = [...Array(7).keys()].map(() => ({}))
//     const calendar = {
//       timezone: service.companyTimezone,
//       bookingWeeklyAllowancePlan: [...days],
//       workingWeeklyAllowancePlan: [...days]
//     }
//     // Booking weekly allowance plans
//     Object
//       .keys(service)
//       .filter(key => key.indexOf('bookingWeeklyAllowancePlan') > -1)
//       .map(key => ({
//         key,
//         index: parseInt(key.replace('bookingWeeklyAllowancePlanDay', '').replace('Intervals', ''), 10),
//         isActive: service[key].isActive,
//         intervals: service[key].values
//       }))
//       .forEach(item => {
//         const index = (item.index === 0 || item.index === 7) ? 0 : item.index
//         const allowancePlan = {
//           isActive: !!item.isActive,
//           times: item.intervals
//             ? [...(item.intervals || []).map(interval => ({ begin: interval.from, end: interval.until }))]
//             : []
//         }
//         calendar.bookingWeeklyAllowancePlan[index] = allowancePlan
//         calendar.workingWeeklyAllowancePlan[index] = allowancePlan
//       })
//     result.calendar = calendar
//   }

//   // Duration before and after
//   if (showDurationBeforeAndAfter.value) {
//     if (durationBefore.value) result.durationBefore = durationBefore.value
//     if (durationAfter.value) result.durationAfter = durationAfter.value
//   }

//   return result
// }
