import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesome5 } from '../../../Common'

import './SectionTitle.css'

const SectionTitle = props => {
  const {
    label,
    number,
    fullLabel,
    extraText,
    icon,
    iconType = 's',
    noIcon,
    children,
    ignoreTopOffset,
    className,
    extraLabel
  } = props
  const classNames = ['ta-section-title']
  if (className) classNames.push(className)
  if (ignoreTopOffset) classNames.push('ignore-top-offset')
  if (noIcon) classNames.push('no-icon')
  const onClick = e => e.stopPropagation()

  return (
    <div className={classNames.join(' ')} data-testid='section-title'>
      {!noIcon && icon && (
        <FontAwesome5 className='ta-section-title__icon' icon={icon} type={iconType} />
      )}
      <span className='ta-section-title__label' title={fullLabel || label} data-testid='section-title-label'>
        {label} {number ? `(${number})` : ''}
      </span>
      {extraLabel && (
        <div className='ta-section-title__extra-label' data-testid='section-title-extra-label'>
          {extraLabel}
        </div>
      )}
      {extraText && (
        <span className='ta-section-title__extra-text' data-testid='section-title-extra-text'>
          {extraText}
        </span>
      )}
      <div className='ta-section-title__content' onClick={onClick} data-testid='section-title-children'>{children}</div>
    </div>
  )
}

SectionTitle.propTypes = {
  extraText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  ignoreTopOffset: PropTypes.bool,
  className: PropTypes.string
}

export default SectionTitle
