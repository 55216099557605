const reducer = {}

reducer.initialState = {
  position: null
}

reducer.handlers = (dispatch, actions) => ({
  navigationPositionSet: position => dispatch(actions.NAVIGATION_POSITION_SET, position)
})

reducer.NAVIGATION_POSITION_SET = (state, position) => ({
  ...state,
  position
})

export default reducer
