import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'
import { validator, translateServerCode } from '../../../Utils'

// LIST

export const agentsListTransform = (agents) => (
  agents
    .map(item => ({
      ...item,
      isOwner: item.roles.includes('OWNER'),
      isAdmin: item.roles.includes('ADMIN'),
      isCustom: item.roles.includes('CUSTOM')
    }))
    .filter(item => !!item.name)
)

export const agentsListErrorsTransform = error => {
  const errors = []
  // TODO implement
  console.warn('--------error', error)
  return errors
}

// PREVIEW

export const agentPreviewErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

export const agentPreviewServicesErrorTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// FORM

export const agentFormTransform = (agent, permissionOptions) => {
  agent = agent || {}
  const agentsDefaults = {}
  if (!agent) agent = agentsDefaults

  let { id, myself } = agent
  myself = myself || {}
  const { isCustom: amICustom, rules: myPermissions } = myself
  const isTypeAdd = !id

  const isPermissionUpdateAllowedByEnterpriseAgentIssuer = typeof agent.isPermissionUpdateAllowedByEnterpriseAgentIssuer === 'boolean'
    ? agent.isPermissionUpdateAllowedByEnterpriseAgentIssuer
    : true

  const transformedAgent = {
    id: {
      value: id || ''
    },
    name: {
      value: agent.name || ''
    },
    abbreviation: {
      value: agent.abbreviation || ''
    },
    color: {
      value: agent.color || DEFAULT_SERVICE_HEXCOLOR
    },
    email: {
      value: agent.email || '',
      disabled: !!id
    },
    permissions: {
      value: agent.roles && agent.roles.includes('ADMIN') ? 'ADMIN' : 'CUSTOM',
      options: permissionOptions
    },
    avatar: {
      avatarUrl: {
        value: agent.avatarUrl
      }
    },
    isOwner: agent.roles && agent.roles.includes('OWNER'),
    isConfirmed: agent.isConfirmed,
    isPermissionUpdateAllowedByEnterpriseAgentIssuer
  }
  const permissions = agent.rules || {}
  const arePermissionChangeDisabled = !isPermissionUpdateAllowedByEnterpriseAgentIssuer || id === myself.id
  const rules = agentFormPermissionsTransform(permissions, arePermissionChangeDisabled, amICustom, myPermissions, isTypeAdd)
  Object.assign(transformedAgent, { ...rules })

  return transformedAgent
}

export const agentFormPermissionsTransform = (permissions, arePermissionChangeDisabled, amICustom, myPermissions, isTypeAdd) => {
  permissions = permissions || {}

  const defaultCustomPermissions = {
    bookingsDelete: false,
    bookingsRead: false,
    bookingsReschedule: false,
    bookingsSchedule: false,
    customersRead: false,
    customersWrite: false
  }

  const rules = {
    bookingsDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsDelete))
        ? false
        : permissions.bookingsDelete || defaultCustomPermissions.bookingsDelete
    },
    bookingsRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsRead))
        ? false
        : permissions.bookingsRead || defaultCustomPermissions.bookingsRead
    },
    bookingsReschedule: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsReschedule),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsReschedule))
        ? false
        : permissions.bookingsReschedule || defaultCustomPermissions.bookingsReschedule
    },
    bookingsSchedule: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsSchedule),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.bookingsSchedule))
        ? false
        : permissions.bookingsSchedule || defaultCustomPermissions.bookingsSchedule
    },
    customersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.customersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.customersRead))
        ? false
        : permissions.customersRead || defaultCustomPermissions.customersRead
    },
    customersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.customersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.customersWrite))
        ? false
        : permissions.customersWrite || defaultCustomPermissions.customersWrite
    },
    usersDelete: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.usersDelete),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.usersDelete))
        ? false
        : permissions.usersDelete || defaultCustomPermissions.usersDelete
    },
    usersRead: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.usersRead),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.usersRead))
        ? false
        : permissions.usersRead || defaultCustomPermissions.usersRead
    },
    usersWrite: {
      disabled: arePermissionChangeDisabled || (amICustom && !myPermissions.usersWrite),
      value: isTypeAdd && (arePermissionChangeDisabled || (amICustom && !myPermissions.usersWrite))
        ? false
        : permissions.usersWrite || defaultCustomPermissions.usersWrite
    }
  }

  return rules
}

export const agentDeleteFormTransform = () => {
  return {
    name: {
      value: ''
    }
  }
}

export const agentFormValidate = (agent) => {
  if (!agent) return

  const abbreviationMaxLength = 4

  const rules = [
    { 'name.value': ['required'] },
    { 'email.value': ['required', 'email'] },
    { 'abbreviation.value': [`max:${abbreviationMaxLength}`]
    }
  ]

  const messages = {
    'name': {
      'required': 'errors.required'
    },
    'email': {
      'requiredIf': 'errors.email.required',
      'email': 'errors.email.invalid'
    },
    'abbreviation': {
      'max': 'errors.invalidMaxLength'
    }
  }

  const replaces = {
    'name': {
      'required': {
        key: 'FIELD_NAME',
        value: 'global.name.label',
        translateReplace: true
      }
    },
    'abbreviation': {
      'max': { key: 'MAX', value: abbreviationMaxLength }
    }
  }

  const errors = validator(agent, rules, messages, replaces)

  // ADVANCED VALIDATION

  return errors
}

export const agentFormServerErrorsTransform = (error) => {
  const errors = []
  if (error.code === 'InvalidAbbreviation') {
    errors.push({ key: 'abbreviation', value: translateServerCode(error.code) })
  } else {
    errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
  }
  return errors
}

// SAVE

export const agentSaveTransform = agent => {
  agent = agent || {}
  const permissions = {
    bookingsDelete: null,
    bookingsRead: true,
    bookingsWrite: true,
    bookingsReschedule: null,
    bookingsSchedule: null,
    customersRead: null,
    customersWrite: null
  }
  if (agent.permissions.value === 'CUSTOM' && agent.isPermissionUpdateAllowedByEnterpriseAgentIssuer) {
    permissions.bookingsReschedule = !!agent.bookingsReschedule.value
    permissions.bookingsSchedule = !!agent.bookingsSchedule.value
    permissions.bookingsDelete = !!agent.bookingsDelete.value
    permissions.customersRead = agent.customersRead.value
    permissions.customersWrite = permissions.customersRead ? agent.customersWrite.value : false
    permissions.usersRead = agent.usersRead.value
    permissions.usersWrite = permissions.usersRead ? agent.usersWrite.value : false
    permissions.usersDelete = permissions.usersRead && permissions.usersWrite ? agent.usersDelete.value : false
  }

  const isAdmin = agent.permissions.value === 'ADMIN' || false
  const avatarUrl = (agent.avatar && agent.avatar.avatarUrl) && agent.avatar.avatarUrl.value
  const email = agent.email && agent.email.value

  const result = {
    id: agent.id && agent.id.value,
    name: agent.name.value,
    abbreviation: agent.abbreviation.value || null,
    avatarFile: agent.avatar && agent.avatar.avatarFile,
    email,
    isAdmin,
    color: agent.color && agent.color.value,
    permissions
  }

  if (!avatarUrl) result.avatarUrl = null
  if (!agent.isPermissionUpdateAllowedByEnterpriseAgentIssuer) {
    delete result.permissions
  }

  if (agent.isLoggedInUser) {
    return {
      id: agent.id && agent.id.value,
      name: result.name,
      abbreviation: result.abbreviation,
      avatarFile: result.avatarFile,
      color: result.color
    }
  }

  return result
}

// UPDATE

export const agentTransform = agent => {
  if (!agent) return
  if (agent.permissions) return agent

  const result = {
    ...agent,
    permissions: agent.rules
  }
  return result
}
