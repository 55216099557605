import React, { useState } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { BookingFormDataFields } from '../../Beauties'
import { sortByOrderIndex } from '../../Utils'

const BookingFormDataFieldsWrapper = props => {
  let {
    customerFieldsCategories,
    isCancellationForm,
    selectedService,
    defaultCategory,
    customerFields,
    timezones,
    plan,
    hash,
    form
  } = props
  form = form || {}
  plan = plan || 'CLASSIC'
  timezones = timezones || []
  customerFields = customerFields || []
  selectedService = selectedService || {}
  customerFieldsCategories = customerFieldsCategories || []
  const { id: selectedServiceId } = selectedService
  const hasErrors = Object.keys(form)
    .filter(item => item.includes('customerFields'))
    .reduce((acc, key) => acc || (form[key] && form[key].errors && form[key].errors.length > 0), false)

  let fields = customerFields
    .filter(item => item.isActive && !item.hasOverwrite)
    .sort(sortByOrderIndex)

  const otherCategories = customerFieldsCategories
    .filter(item => !item.isDefault)
    .sort(sortByOrderIndex)
  const sortedCategories = [defaultCategory, ...otherCategories].filter(Boolean)
  fields = sortedCategories.reduce((acc, item) => {
    const categoryItems = fields.filter(field => field.categoryId === item.id)
    acc = [...(acc || []), ...categoryItems]
    return acc
  }, [])
  if (selectedServiceId && (!hash || hash === 'singleBooking')) {
    fields = fields.filter(item => (
      item.specificServiceIds &&
      (item.specificServiceIds.includes(selectedServiceId) || item.specificServiceIds.length === 0)
    ))
  }
  if (selectedServiceId && hash === 'groupBooking') {
    fields = fields.filter(item => (
      item.specificCourseIds &&
      (item.specificCourseIds.includes(selectedServiceId) || item.specificCourseIds.length === 0)
    ))
  }
  fields = fields.map(item => item.type === 'SELECT' ? { options: item.selectOptions, ...item } : item)
  const [fileLoading, setFileLoading] = useState(false)

  return (
    <BookingFormDataFields
      plan={plan}
      hash={hash}
      timezones={timezones}
      hasErrors={hasErrors}
      customerFields={fields}
      isCancellationForm={isCancellationForm}
      setFileLoading={setFileLoading}
      fileLoading={fileLoading}
      toggleLocalStorageSection={handlers.toggleLocalStorageSection}
    />
  )
}

const maps = state => ({
  timezones: state.staticData.timezones,
  customerFields: selectors.customerFieldsListSelector(state),
  customerFieldsCategories: selectors.customerFieldsCategoriesSelector(state),
  selectedService: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }),
  plan: selectors.companyPlanSelector(state),
  hash: selectors.routerFieldSelector(state, { field: 'hash' }),
  form: selectors.formSelector(state, { formName: 'booking' }),
  defaultCategory: selectors.customerFieldsDefaultCategorySelector(state),
  isCancellationForm: state.router.name === 'cancelBooking'
})

export default connect(maps)(BookingFormDataFieldsWrapper)
