import moment from 'moment-timezone'

export default (timestamp, timezone, format) => {
  try {
    parseInt(timestamp, 10)
  } catch (err) {
    console.warn('Please provide valid timestamp!')
    return null
  }
  if (!timezone) {
    console.warn('Timezone is missing. Example timezone string: "Europe/Berlin".')
    return null
  }
  if (!format) {
    console.warn('Format is missing. Example format string: "HH:mm".')
    return null
  }

  const _timestamp = parseInt(timestamp, 10)

  return moment(new Date(_timestamp)).tz(timezone).format(format)
}
