import React, { useMemo } from 'react'
import { ImageInput, t } from '../../../Common'
import { dangerousHTML } from '../../../Utils'

const AgentFormAvatar = props => {
  const { name } = props
  const maxSize = 20
  const controls = useMemo(() => ({ radius: false }), [])
  const validation = useMemo(() => ({ maxSize }), [])
  const text = useMemo(() => (dangerousHTML(t('agents.form.section.details.avatar.text', [{ key: 'FILE_SIZE', value: maxSize }]))), [])

  return (
    <div className='ta-agents__form__avatar'>
      <ImageInput
        formName='agents'
        name={name}
        defaultZoom={1}
        defaultBorderRadius={100}
        controls={controls}
        validation={validation}
        title={t('agents.form.section.details.avatar.title')}
        text={text}
      />
    </div>
  )
}

export default AgentFormAvatar
