import React from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  FontAwesome5,
  t
} from '../../Common'

const AgentsFilters = props => {
  const { disabled } = props

  return (
    <div className='ta-agents__filters'>
      <Form name='agentsFilters'>
        <Row>
          <Col>
            <Input
              label={t('agents.fieldSearch.label')}
              placeholder={t('agents.fieldSearch.placeholder')}
              type='text'
              name='search'
              clearable={!disabled}
              disabled={disabled}
              addon={<FontAwesome5 icon='search' type='r' />}
            />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default AgentsFilters
