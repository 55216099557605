import React, { Component } from 'react'
import { renderToString } from 'react-dom/server'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { connect, handlers } from '../../../Store'
import { feedContextInProps, getMimeType } from '../../../Utils'
import { FormContext, Error, FontAwesome5, Link, BorderedBox, t } from '../../../Common'
import { FILE_UPLOAD_URL, UPLOAD_FILES_LIMIT } from '../../../Settings'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import './FilesInput.css'

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
)

class FilesInput extends Component {
  constructor (props, context) {
    super(props)

    this.onAddFile = this.onAddFile.bind(this)
    this.onRemoveNewFile = this.onRemoveNewFile.bind(this)
    this.onRemoveExistingFile = this.onRemoveExistingFile.bind(this)
    this.state = { files: [], fileNames: [], filesLoadingCoutner: 0 }
  }

  componentDidMount () {
    const { addRef, name, form, formName } = this.props
    addRef && addRef(this)
    const { rawFiles } = form || {}
    if ((rawFiles || []).length > 0) {
      // if user has mistake in form that's not related to files, keep file
      handlers.formFieldsUpdate(formName, { [name]: { ...form, values: [] } })
      this.setState({ files: rawFiles })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { form, setLoading } = this.props
    const { values, rawFiles } = form || {}
    const { form: prevForm } = prevProps
    const { values: prevValues, rawFiles: prevRawFiles } = prevForm || {}
    const { filesLoadingCoutner } = this.state
    const { filesLoadingCoutner: filesLoadingCoutnerPrev } = prevState
    // if user goes back to reseted form, delete files
    if (prevValues && !values) this.setState({ files: [] })
    if (prevRawFiles && !rawFiles) this.setState({ files: [] })
    // setLoading from props
    if (filesLoadingCoutner !== filesLoadingCoutnerPrev && filesLoadingCoutner > 0) setLoading(true)
    if (filesLoadingCoutner !== filesLoadingCoutnerPrev && filesLoadingCoutner === 0) setLoading(false)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onAddFile ({ key, url }) {
    const { name, form, formName, onChangeAddon } = this.props
    let { fileNames } = this.state
    let { values, valuesAdd } = form || {}
    values = values || []
    valuesAdd = valuesAdd || []
    fileNames = fileNames || []
    values.push({ key, url })
    valuesAdd.push({ key })
    valuesAdd = valuesAdd
      .map((item, index) => ({ ...item, name: fileNames[index] }))
      .filter(item => item.name)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values, valuesAdd } })
    onChangeAddon && onChangeAddon()
  }

  onRemoveNewFile (key) {
    if (!key) return
    const { name, form, formName } = this.props
    let { values, valuesAdd } = form || {}
    values = values || []
    valuesAdd = valuesAdd || []
    const newValues = values.filter(item => item.key !== key) || []
    const newValuesAdd = valuesAdd.filter(item => item.key !== key) || []
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values: newValues, valuesAdd: newValuesAdd } })
  }

  onRemoveExistingFile (url, index) {
    const { name, form, formName, onChangeAddon } = this.props
    let { selected, valuesRemove } = form || {}
    selected = selected || []
    valuesRemove = valuesRemove || []
    valuesRemove.push(url)
    const newSelected = selected.filter((item, i) => index !== i) || []
    handlers.formFieldsUpdate(formName, { [name]: { ...form, selected: newSelected, valuesRemove } })
    onChangeAddon && onChangeAddon()
  }

  renderLabel () {
    const { files } = this.state
    const { label, maxFileSize, maxFiles, form, mandatory } = this.props
    const labelMandatory = mandatory || form.isMandatory
    let { selected } = form || {}
    selected = selected || []

    return renderToString(
      <>
        <div className='ta-files-input__label'>
          <FontAwesome5 icon='cloud-upload-alt' type='solid' />
          <div className='ta-files-input__drop-or-browse'>
            {label || t('global.selectOrDragFile')}
            {labelMandatory && ' *'}
          </div>
          {maxFileSize && (
            <div className='ta-files-input__max-size'>
              {t('fileInput.maxFileSize', [{ key: 'FILE_SIZE', value: maxFileSize }])}
            </div>
          )}
        </div>
        <div className='ta-form-control__addon'>
          {`${files.length + selected.length} / ${maxFiles}`}
        </div>
      </>
    )
  }

  render () {
    const { files, filesLoadingCoutner } = this.state
    let {
      name,
      allowMultiple,
      maxFiles,
      maxFileSize,
      maxTotalFileSize,
      acceptedFileTypes,
      hideError,
      className,
      token,
      authToken,
      form,
      acceptedFileTypesLabel,
      formName,
      disabled,
      label
    } = this.props
    maxFiles = maxFiles || null
    let { selected } = form || {}
    selected = selected || []
    const classNames = ['ta-files-input']
    if (className) classNames.push(className)
    if (!allowMultiple) classNames.push('single-upload')
    if (selected.length > 0 || (files || []).length > 0) classNames.push('has-value')
    const isDisabled = disabled || maxFiles < selected.length + (files || []).length
    let acceptedFileTypesMime
    if (acceptedFileTypes) acceptedFileTypesMime = acceptedFileTypes.map(getMimeType)
    if (selected.length > 0 && maxFiles) maxFiles = maxFiles - selected.length
    const isHidden = (!allowMultiple && selected.length === 1) || (allowMultiple && selected.length === UPLOAD_FILES_LIMIT)
    const isTitleShown = ((!allowMultiple && (selected.length > 0 || (files || []).length > 0)) || (allowMultiple && (selected.length + (files || []).length) === UPLOAD_FILES_LIMIT))

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        {isTitleShown && (
          <div className='ta-files-input__drop-or-browse'>{label || t('global.selectOrDragFile')}</div>
        )}
        {!isHidden && (
          <FilePond
            ref={ref => { this.pond = ref }}
            name='file'
            files={files}
            allowMultiple={allowMultiple}
            maxFiles={maxFiles}
            maxFileSize={maxFileSize || null}
            maxTotalFileSize={maxTotalFileSize || null}
            acceptedFileTypes={acceptedFileTypesMime || null}
            credits={false}
            labelTapToUndo=''
            labelFileProcessingComplete=''
            disabled={isDisabled}
            server={{
              url: FILE_UPLOAD_URL,
              headers: {
                authorization: `Bearer ${token || authToken}`
              },
              process: {
                onload: response => {
                  response = response ? JSON.parse(response) : null
                  const { url, key } = response || {}
                  this.onAddFile({ key, url })
                  return key
                },
                onerror: () => {
                  handlers.formErrorsSet(formName, [{ key: name, value: 'errors.fileInput.upload' }])
                }
              },
              revert: (key, onRevert) => {
                onRevert() // need to call so the library can handle the file remove from UI
                this.onRemoveNewFile(key)
              }
            }}
            onupdatefiles={fileItems => {
              const { name, form, formName } = this.props
              this.setState({ files: fileItems.map(fileItem => fileItem.file) })
              handlers.formFieldsUpdate(formName, { [name]: { ...form, rawFiles: fileItems.map(fileItem => fileItem.file) } })
            }}
            onprocessfilerevert={file => {
              file && this.onRemoveNewFile(file.serverId)
            }}
            labelIdle={this.renderLabel()}
            onerror={() => {
              this.setState({ filesLoadingCoutner: filesLoadingCoutner - 1 })
              handlers.formErrorsSet(formName, [{ key: name, value: 'errors.fileInput.upload' }])
            }}
            onpreparefile={(file, output) => this.setState(state => ({
              ...state,
              fileNames: [...state.fileNames, output.name]
            }))}
            onaddfilestart={() => this.setState({ filesLoadingCoutner: filesLoadingCoutner + 1 })}
            onprocessfileabort={() => setTimeout(() => {
              this.setState({ filesLoadingCoutner: filesLoadingCoutner - 1 })
            }, 100)}
            onprocessfile={() => this.setState({ filesLoadingCoutner: filesLoadingCoutner - 1 })}
          />
        )}
        {acceptedFileTypes.length > 0 && (allowMultiple || selected.length === 0) && (
          <div className='ta-files-input__supported-types'>
            {t('fileInput.supportedFiles')}
            {acceptedFileTypesLabel || `${acceptedFileTypes.map(item => ` .${item.toLowerCase()}`).join(';')}.`}
          </div>
        )}
        {selected.length > 0 && (
          <BorderedBox className='ta-files-input__files'>
            {selected.map((item, index) => (
              <div key={`${index}-${item.url}`} className='ta-files-input__file'>
                <div className='ta-files-input__file-info'>
                  <div className='ta-files-input__file-name'>
                    {item.name}
                  </div>
                  <Link
                    className='ta-btn ta-btn-icon-only'
                    onClick={() => handlers.customerFieldDownloadFile({ key: item.key, formName, name: item.name })}
                  >
                    <FontAwesome5 icon='file-download' type='solid' />
                  </Link>
                </div>
                <Link className='ta-btn ta-btn-delete ta-btn-icon-only' onClick={() => this.onRemoveExistingFile(item.url, index)}>
                  <FontAwesome5 icon='trash' type='solid' />
                </Link>
              </div>
            ))}
          </BorderedBox>
        )}
        {acceptedFileTypes.length > 0 && !allowMultiple && selected.length > 0 && (
          <div className='ta-files-input__supported-types'>
            {t('fileInput.supportedFiles')}
            {acceptedFileTypesLabel || `${acceptedFileTypes.map(item => ` .${item.toLowerCase()}`).join(';')}.`}
          </div>
        )}
        {!hideError && (
          <Error name={name} />
        )}
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: (state.forms && state.forms[props.formName] && state.forms[props.formName][props.name]) || { value: '' },
  authToken: state.auth.tokens.accessToken
})

export default feedContextInProps(connect(maps)(FilesInput), FormContext)
