import React from 'react'
import { connect, handlers } from '../../Store'
import { Modal } from '../../Beauties'
import { Iframe } from '../../Common'

const ModalWrapper = props => {
  let { modal } = props
  modal = modal || {}

  const { isActive, content, params } = modal
  const onCloseModal = () => {
    handlers.modalHide()
    handlers.overlayHide('top')
  }

  return (
    <Modal isActive={isActive} onCloseModal={onCloseModal}>
      {content === 'iframe' && <Iframe {...params} />}
    </Modal>
  )
}

const maps = state => ({
  modal: state.modal
})

export default connect(maps)(ModalWrapper)
