import React from 'react'
import PropTypes from 'prop-types'
import { Link, Online, t } from '../../../Common'

const PopupBookingsDelete = props => {
  const { approve, reject } = props

  return (
    <div className='ta-popup__customers-delete'>
      <div className='ta-popup__title'>{t('servicesGroups.preview.bookings.heading.buttonDelete.tooltip.title')}</div>
      <div className='ta-popup__buttons'>
        <Link className='ta-btn ta-btn-secondary' onClick={reject}>{t('global.no')}</Link>
        <Online>
          <Link className='ta-btn ta-btn-primary' onClick={approve}>{t('global.yes')}</Link>
        </Online>
      </div>
    </div>
  )
}

PopupBookingsDelete.propTypes = {
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
}

export default PopupBookingsDelete
