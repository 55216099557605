import React, { useEffect, useState, memo } from 'react'
import { connect, handlers, selectors } from '../../Store'
import { isRouteDisabledByPermissions, Loader } from '../../Common'
import { AgentPreview } from '../../Beauties'

const AgentPreviewWrapper = props => {
  let {
    id,
    agent,
    pending,
    hash,
    userId,
    isAdmin,
    isOwner,
    isCustom,
    rules,
    message
  } = props
  isAdmin = !!isAdmin
  isOwner = !!isOwner
  isCustom = !!isCustom
  agent = agent || {}

  const [initial] = useState({ id })

  useEffect(() => {
    const { id } = initial
    if (isRouteDisabledByPermissions()) {
      handlers.navigateToPath('/')
    }

    handlers.agentPreviewGet({ id })
  }, [initial])

  return (
    <>
      <Loader active={pending} />
      {(!pending &&
        <AgentPreview
          rules={rules}
          agent={agent}
          hash={hash}
          isOwner={isOwner}
          isAdmin={isAdmin}
          isCustom={isCustom}
          userId={userId}
          message={message}
        />
      )}
    </>
  )
}

const maps = state => {
  const id = selectors.routerDataFieldSelector(state, { field: 'id' })

  return {
    id,
    hash: selectors.routerFieldSelector(state, { field: 'hash' }),
    agent: id && selectors.agentByIdSelector(state, { id }),
    pending: selectors.agentsFieldSelector(state, { field: 'pendingPreview' }),
    userId: selectors.accountFieldSelector(state, { field: 'userId' }),
    isAdmin: selectors.accountFieldSelector(state, { field: 'isAdmin' }),
    isOwner: selectors.accountFieldSelector(state, { field: 'isOwner' }),
    isCustom: selectors.accountFieldSelector(state, { field: 'isCustom' }),
    rules: selectors.accountFieldSelector(state, { field: 'rules' }),
    message: selectors.agentsFieldSelector(state, { field: 'messagePreview' })
  }
}

export default memo(connect(maps)(AgentPreviewWrapper))
