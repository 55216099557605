import React, { Component } from 'react'
import { connect, handlers, store, selectors } from '../../Store'
import { OAuth } from '../../Beauties'
import { DEFAULT_LOCALE } from '../../Settings'

class OAuthWrapper extends Component {
  constructor (props, context) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.onClickGrantAccess = this.onClickGrantAccess.bind(this)
  }

  componentDidMount () {
    const { router } = this.props
    const { data: routerData } = router || {}
    const { locale } = routerData || {}
    handlers.languageChange(locale || DEFAULT_LOCALE)
    handlers.oAuthGet()
    handlers.loginFormPopulate()
  }

  onSubmit (cb) {
    const form = store.getState().forms.login
    handlers.loginWithEmailAndPassword(form, (errors) => {
      cb && cb(errors)
    })
  }

  togglePasswordFieldType () {
    const form = store.getState().forms.oAuth
    const password = {
      value: form.password.value,
      type: form.password.type === 'password' ? 'text' : 'password'
    }
    handlers.formFieldsUpdate('oauth', { password })

    return password.type
  }

  onClickGrantAccess (id) {
    handlers.oAuthAccessGrant(id)
  }

  onClickDenieAccess () {
    handlers.oAuthAccessDenied()
  }

  render () {
    let {
      router,
      pendingLanguage,
      pending,
      loginFormPending,
      passwordFieldType,
      hasTokens,
      accounts,
      app
    } = this.props
    router = router || {}
    app = app || null
    accounts = accounts || []
    const sortedAccounts = accounts
      .filter(item => item.company)
      .sort((a, b) => a.company.name > b.company.name ? 1 : -1)
    const { data: routerData } = router
    const {
      locale,
      redirect_uri: oAuthRedirectUrl,
      client_id: oAuthClientId,
      response_type: oAuthResponseType,
      state: oAuthState
    } = routerData || {}
    const params = [
      `redirect_uri=${oAuthRedirectUrl}`,
      `client_id=${oAuthClientId}`,
      `response_type=${oAuthResponseType}`,
      'route=/authorize'
    ]
    if (oAuthState) params.push(`state=${oAuthState}`)
    if (locale) params.push(`locale=${locale}`)
    const logoutUrl = `/logout?${params.join('&')}`

    return (
      (pendingLanguage || !app)
        ? null
        : <OAuth
            onSubmit={this.onSubmit}
            onClickGrantAccess={this.onClickGrantAccess}
            onClickDenieAccess={this.onClickDenieAccess}
            passwordFieldType={passwordFieldType || 'password'}
            togglePasswordFieldType={this.togglePasswordFieldType}
            locale={router.query.locale || DEFAULT_LOCALE}
            pending={pending || loginFormPending}
            app={app}
            accounts={sortedAccounts}
            hasTokens={hasTokens}
            logoutUrl={logoutUrl}
          />
    )
  }
}

const maps = state => ({
  router: selectors.routerSelector(state),
  app: state.oAuth.app,
  accounts: state.oAuth.accounts,
  pendingLanguage: selectors.appFieldSelector(state, { field: 'pendingLanguage' }),
  pending: state.oAuth.pending,
  loginFormPending: state.auth.pendingForm,
  hasTokens: !!state.auth.tokens,
  passwordFieldType: selectors.formFieldPropertySelector(state, { formName: 'oAuth', name: 'password', property: 'type' })
})

export default connect(maps)(OAuthWrapper)
