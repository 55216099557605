import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  NUMBERS_VALIDATION_REGEX,
  FIVE_MINUTES_INTERVAL_TIMES,
  HOURS
} from '../../../Settings'
import {
  FormContext,
  Row,
  Col,
  FormGroup,
  FontAwesome5,
  Error,
  Select,
  t
} from '../../../Common'

import './DurationInput.css'

class DurationInput extends Component {
  constructor (props, context) {
    super(props)

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onDaysChange = this.onDaysChange.bind(this)
    this.onHoursChange = this.onHoursChange.bind(this)
    this.onMinutesChange = this.onMinutesChange.bind(this)
    this.state = {}
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (field) {
    this.setState({ focusedField: field })
  }

  onBlur () {
    this.setState({ focusedField: null })
  }

  onDaysChange () {
    let { name, formName, form } = this.props
    form = form || { value: '' }
    const value = this.daysRef.value || ''
    const numbersRegex = new RegExp(NUMBERS_VALIDATION_REGEX)
    const days = value && numbersRegex.test(value) ? value : ''
    this.setState({ days })
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    const valueHours = form.value && valueDuration.hours()
    const valueMinutes = form.value && valueDuration.minutes()
    const duration =
      (parseInt(days === null ? valueDays || 0 : days || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(valueHours || 0, 10) * 60) + // hours in minutes
      (parseInt(valueMinutes || 0, 10)) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration || null } })
  }

  onHoursChange (value, serviceHours, lowerHourMinsLimit, serviceDuration) {
    let { name, formName, form, shouldEnforceServiceDurationLimit } = this.props
    form = form || { value: '' }
    const hours = value ? value.value : 0
    this.setState({ hours: hours.toString() })
    const serviceMinutes = moment.duration(serviceDuration || 0, 'minutes').minutes()
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    let valueMinutes = form.value && valueDuration.minutes()
    if (shouldEnforceServiceDurationLimit && hours > serviceHours) valueMinutes = hours > serviceHours && 0
    if (shouldEnforceServiceDurationLimit && hours < serviceHours) valueMinutes = hours < serviceHours && lowerHourMinsLimit
    if (shouldEnforceServiceDurationLimit && hours === serviceHours) valueMinutes = hours === serviceHours && serviceMinutes
    const duration =
      (parseInt(valueDays || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(hours, 10) * 60) + // hours in minutes
      (parseInt(valueMinutes || 0, 10)) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration } })
  }

  onMinutesChange (value) {
    let { name, formName, form } = this.props
    form = form || { value: '' }
    const minutes = value ? value.value : 0
    this.setState({ minutes: minutes.toString() })
    const valueDuration = moment.duration(form.value || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    const valueHours = form.value && valueDuration.hours()
    const duration =
      (parseInt(valueDays || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(valueHours || 0, 10) * 60) + // hours in minutes
      parseInt(minutes, 10) // minutes
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value: duration } })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const { focusedField } = this.state
    let {
      className,
      noDays,
      hideError,
      name,
      enforceServiceDurationLimit,
      shouldEnforceServiceDurationLimit,
      isDurationChangeForbidden,
      form,
      plan,
      hash,
      service,
      disabled
    } = this.props
    form = form || { value: '' }
    const { duration: serviceDuration } = service || {}
    const selectedHour = moment.duration(form.value, 'minutes').hours()
    const mins = moment.duration(serviceDuration, 'minutes').minutes()
    const hours = moment.duration(serviceDuration, 'minutes').hours()
    const lowerHourLimit = parseInt((serviceDuration - enforceServiceDurationLimit) / 60) + 0
    const upperHourLimit = parseInt((serviceDuration + enforceServiceDurationLimit) / 60)
    const limitsAreInSameHour = (mins + enforceServiceDurationLimit) < 60 && (mins - enforceServiceDurationLimit) >= 0
    const upperHourMinsLimit = (mins + enforceServiceDurationLimit) % 60
    const lowerHourMinsLimit = (mins - enforceServiceDurationLimit) % 60 >= 0 ? mins - enforceServiceDurationLimit % 60 : hours === 0 ? 0 : 60 + (mins - enforceServiceDurationLimit % 60)
    const upperHoursLimit = mins + enforceServiceDurationLimit >= 60 ? hours + 1 : hours
    const lowerHoursLimit = mins - enforceServiceDurationLimit < 0 ? hours - 1 : hours
    const decideForSameHour = t => {
      return limitsAreInSameHour
        ? (upperHourMinsLimit >= t && t >= lowerHourMinsLimit)
        : mins === 0
          ? t <= upperHourMinsLimit
          : upperHourLimit > hours && hours > lowerHourLimit
            ? t < 60
            : upperHourLimit === hours
              ? t <= upperHourMinsLimit
              : (t >= lowerHourMinsLimit && t < 60)
    }
    const decideForDifferentHour = t => {
      return selectedHour > hours
        ? t <= upperHourMinsLimit
        : (t >= lowerHourMinsLimit && t < 60)
    }
    const minutesOptions = FIVE_MINUTES_INTERVAL_TIMES.filter(t => {
      return !shouldEnforceServiceDurationLimit
        ? t < 60
        : hours === selectedHour
          ? decideForSameHour(t)
          : decideForDifferentHour(t)
    }).map(time => ({
      label: time,
      value: time
    }))
    const hoursOptions = HOURS.filter(t => !shouldEnforceServiceDurationLimit
      ? t > -1
      : limitsAreInSameHour
        ? t === hours
        : t <= upperHoursLimit && t >= lowerHoursLimit).map(hour => ({
      label: hour,
      value: hour
    }))
    const duration = moment.duration(form.value || 0, 'minutes')
    const valueDays = ((form.value || form.value === 0) && parseInt(duration.asDays(), 10)) || ''
    const valueHours = ((form.value || form.value === 0) && duration.hours().toString()) || ''
    const valueMinutes = ((form.value || form.value === 0) && duration.minutes().toString()) || ''
    const classNames = ['ta-duration-input']
    if (className) classNames.push(className)
    if (disabled) classNames.push('disabled')

    return (
      <div ref={wrapper => { this.wrapper = wrapper }} className={classNames.join(' ')}>
        <Row>
          {(!noDays || (form.value || 0) >= 1440) &&
            <Col size={30}>
              <FormGroup
                focused={focusedField === 'days'}
                filled={!!valueDays}
                labelText={t('global.days.label')}
                labelMandatory
              >
                <input
                  className='ta-form-control'
                  ref={daysRef => { this.daysRef = daysRef }}
                  type='text'
                  value={valueDays}
                  onChange={this.onDaysChange}
                  onFocus={() => this.onFocus('days')}
                  onBlur={this.onBlur}
                  placeholder={t('global.days.placeholder')}
                  autoComplete='off'
                  disabled={disabled || (plan === 'CLASSIC' && hash === 'groupBooking') || isDurationChangeForbidden || (shouldEnforceServiceDurationLimit && serviceDuration < 1440)}
                />
              </FormGroup>
            </Col>
          }
          <Col size={30}>
            <FormGroup
              focused={focusedField === 'hours'}
              filled={!!valueHours}
              labelText={t('global.hours.label')}
              disabled={disabled}
              labelMandatory
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={valueHours}
                arrowRenderer={this.renderArrows}
                onFocus={() => this.onFocus('hours')}
                onBlur={this.onBlur}
                onChange={val => this.onHoursChange(val, hours, lowerHourMinsLimit, serviceDuration)}
                options={hoursOptions}
                searchable
                placeholder={t('global.hours.placeholder')}
                autoComplete='off'
                disabled={disabled || (plan === 'CLASSIC' && hash === 'groupBooking') || isDurationChangeForbidden}
              />
            </FormGroup>
          </Col>
          <Col size={30}>
            <FormGroup
              focused={focusedField === 'minutes'}
              filled={!!valueMinutes}
              labelText={t('global.minutes.label')}
              disabled={disabled}
              labelMandatory
            >
              <Select
                className='ta-single-select'
                noResultsText={t('global.noResults')}
                value={valueMinutes}
                arrowRenderer={this.renderArrows}
                onFocus={() => this.onFocus('minutes')}
                onBlur={this.onBlur}
                onChange={this.onMinutesChange}
                options={minutesOptions}
                searchable
                placeholder={t('global.minutes.placeholder')}
                autoComplete='off'
                disabled={disabled || (plan === 'CLASSIC' && hash === 'groupBooking') || isDurationChangeForbidden}
              />
            </FormGroup>
          </Col>
        </Row>
        {!hideError &&
          <Error name={name} />
        }
      </div>
    )
  }
}

DurationInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  hintText: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  limit: PropTypes.number,
  form: PropTypes.object
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(DurationInput), FormContext)
