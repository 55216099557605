import React from 'react'
import { CustomersListItem } from '../../../Beauties'
import { connect, selectors } from '../../../Store'

const BookingCustomerLinkHeaderWrapper = (props) => <CustomersListItem {...props} />

const maps = state => ({
  transformedCustomer: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedCustomer', property: 'value' }),
  bookingBranch: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'bookingBranch', property: 'value' }),
  displayBookedResources: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'displayBookedResources' })
})

export default connect(maps)(BookingCustomerLinkHeaderWrapper)
