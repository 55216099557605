import { t } from '../../../Common'
import { payloads$, actions, handlers, store, globalActions } from '../../../Store'
import { q } from '../../API'
import selectors from '../../Config/selectors'
import { agentDeleted, agentUpdated } from './subscriptions'
import { agentFormServerErrorsTransform, agentFormValidate, agentPreviewErrorsTransform, agentSaveTransform } from './utils'

// Global actions

globalActions.populateAgents = async () => {
  const state = store.getState()
  const { areFetched = false } = state.agents || {}
  if (areFetched) return
  const agents = await q('getEnterpriseCallCentreAgents')
  const { error } = agents || { error: {} }
  if (error) {
    handlers.agentsListPopulate({ agents: [] })
    return agents
  }
  handlers.agentsListPopulate({ agents })
  return agents
}

// Subscription
payloads$(actions.AGENTS_SUBSCRIPTION_SET)
  .subscribe(async ({ name, id, data, ts }) => {
    if (['enterpriseUserDeleted', 'agentDeleted'].includes(name) && id) agentDeleted(id)
    if (name === 'enterpriseUserUpdated' && id) agentUpdated(id)
  })

payloads$(actions.AGENTS_LIST_GET)
  .subscribe(async (data) => {
    await globalActions.populateAgents()
    handlers.formSet('agentsFilters', {})
  })

// Preview
payloads$(actions.AGENT_PREVIEW_GET)
  .subscribe(async ({ id }) => {
    if (!id) {
      handlers.navigateToPath('/agents')
      return
    }
    const result = await globalActions.populateAgents()
    const { error } = result
    if (error) {
      handlers.navigateToPath(id ? `/agents/${id}` : '/agents')
      handlers.agentsPreviewMessageChange(agentPreviewErrorsTransform(error))
      return
    }
    handlers.agentPreviewPopulate(id)
  })

// Form
payloads$(actions.AGENT_FORM_GET)
  .subscribe(async id => {
    const result = await globalActions.populateAgents()
    const { error } = result
    if (error) {
      handlers.navigateToPath(id ? `/agents/${id}` : '/agents')
      handlers.agentsPreviewMessageChange(agentPreviewErrorsTransform(error))
      return
    }
    const state = store.getState()
    const userId = selectors.accountFieldSelector(state, { field: 'userId' })
    const name = selectors.accountFieldSelector(state, { field: 'name' })
    const myself = selectors.agentByIdSelector(state, { id: userId }) || {}
    let permissionOptions = [
      { label: t('global.admin'), value: 'ADMIN' },
      { label: t('global.custom'), value: 'CUSTOM' }
    ]
    if (name && !myself.isAdmin) permissionOptions = permissionOptions.filter(item => item.value !== 'ADMIN')

    if (!id) {
      handlers.agentFormPopulate({ myself }, permissionOptions)
      return
    }

    const agent = selectors.agentByIdSelector(state, { id })
    handlers.agentUpdate({ ...agent, isComplete: true })
    handlers.agentFormPopulate(
      { ...agent, myself },
      permissionOptions
    )
  })

// Save
payloads$(actions.AGENT_FORM_SAVE)
  .subscribe(async ({ agent, scrollToError }) => {
    const state = store.getState()
    const { account } = state || {}
    const { userId: accountId } = account
    const errors = agentFormValidate(agent)
    if (errors.length) return setAgentFormSaveErrors(errors, scrollToError)
    const savedAgent = await q('saveEnterpriseCallCentreAgent', { agent: agentSaveTransform({ ...agent, isLoggedInUser: accountId === agent.id.value }) })
    const { error, id } = savedAgent || {}
    if (error) return setAgentFormSaveErrors(agentFormServerErrorsTransform(error), scrollToError)
    handlers.agentUpdate({ ...savedAgent, isComplete: true })
    handlers.navigateToPath(`/agents/${id}`)
  })

const setAgentFormSaveErrors = (errors, scrollToError) => {
  handlers.formErrorsSet('agents', errors)
  setTimeout(() => {
    scrollToError && scrollToError(errors)
  }, 0)
  handlers.agentFormReady()
}

// Update
payloads$(actions.AGENT_UPDATE)
  .subscribe(async agent => {
    if (!agent) return handlers.agentPreviewPopulate()
    setTimeout(() => handlers.agentUpdated(agent), 2000)
  })

// Delete
payloads$(actions.AGENT_DELETE)
  .subscribe(async id => {
    if (!id) return
    const { error } = await q('deleteEnterpriseCallCentreAgent', { id }) || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.formErrorsSet('agentPreview', [{ key: 'globalErrors', value: 'errors.somethingWentWrong' }])
      handlers.agentPreviewPopulate(id)
    }
    handlers.agentDeleted(id)
    setTimeout(() => handlers.agentRemoveDeleted(id), 2000)
    setTimeout(() => handlers.navigateToPath('/agents'), 0)
  })

// Delete form save
payloads$(actions.AGENT_DELETE_FORM_SAVE)
  .subscribe(async ({ agent, scrollToError }) => {
    if (!agent.id) return
    const state = store.getState()
    const selectedAgent = selectors.agentByIdSelector(state, { id: agent.id }) || {}
    if (!selectedAgent) return
    if (agent.name.value.toLowerCase() !== selectedAgent.name.toLowerCase()) {
      handlers.formErrorsSet('agentDelete', [{ key: 'name', value: 'errors.agentNameNotMatch' }])
      handlers.agentDeleteFormReady()
      return
    }
    handlers.agentDelete(agent.id)
    handlers.popupHide('agents-delete')
    setTimeout(() => { handlers.popupSet() }, 500)
  })

// Permissions changed
payloads$(actions.AGENT_PERMISSIONS_CHANGED)
  .subscribe(async () => {
    handlers.overlayMessageSet({
      message: t('agent.overlay.permissionsChangeMessage'),
      buttonLabel: t('agent.overlay.permissionsChangeMessage.buttonLabel')
    })

    setTimeout(() => {
      handlers.navigateToPath('/')
      window.location.reload(true)
    }, 4000)
  })

// Resend confirmation email
payloads$(actions.AGENT_PREVIEW_EMAIL_CONFIRMATION_SEND)
  .subscribe(async (email) => {
    const { company, router } = store.getState()
    const { id } = router.params || {}
    const { id: accountId } = company || {}
    await q('sendEmailConfirmationCode', { accountId, email })
    handlers.agentPreviewGet({ id })
    // TODO: display some message or something
  })
