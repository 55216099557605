import moment from 'moment'
import { validator, sortByOrderIndex, translateServerCode } from '../../../Utils'
import { DEFAULT_LOCALE_COUNTRY_CODE } from '../../../Settings'
import { store } from '../..'

const customersDefaults = {}

// LIST
export const customersListTransform = (customers, fieldsCategories) => {
  const fields = (fieldsCategories || []).reduce((acc, item) => ([...acc, ...item.customerFields]), [])
  if (!customers) return
  return (customers || []).map(customer => {
    const item = {
      id: customer.id,
      fullName: customer.fullName,
      avatarUrl: customer.avatarUrl,
      companyName: customer.companyName,
      phone: customer.phone,
      email: customer.email,
      totalBookings: customer.totalBookings,
      ts: customer.ts,
      internalId: customer.internalId,
      externalId: customer.externalId,
      isUpdatedLocally: customer.isUpdatedLocally,
      isGloballyDeleted: customer.isGloballyDeleted,
      hasBooker: customer.hasBooker,
      markedForDeletionByGDPR: customer.markedForDeletionByGDPR,
      isCreatedOnline: customer.isCreatedOnline,
      bookedCompanies: customer.bookedCompanies,
      allowedBranchIds: customer.allowedBranchIds,
      allowedBranchExternalIds: customer.allowedBranchExternalIds,
      tags: customer.tags,
      createdAt: customer.createdAt
    }
    if (customer.fields) {
      item.fields = customer.fields.sort(sortByOrderIndex)
        .map(item => {
          const currentField = fields.find(field => field.id === item.id) || {}
          return {
            ...item,
            label: item.label || currentField.label || currentField.internalId
          }
        })
    }
    return item
  })
}

export const customerPreviewBookingsErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}

// FORM

export const customerFormTransform = ({ customer, branches }) => {
  if (!customer) customer = customersDefaults
  const enterpriseAccount = store.getState().account
  const { enterprise } = enterpriseAccount || {}
  const { locale } = enterprise || {}
  const companyLocale = locale || ''
  const customerFields = (customer.fields || []).filter(item => item.isActive)

  const allowedBranchExternalIds = customer.allowedBranchExternalIds || []
  const allowedBranchIds = customer.allowedBranchIds || []
  const locationValues = allowedBranchIds.length > 0
    ? [...allowedBranchIds]
    : allowedBranchExternalIds.length > 0
      ? [...allowedBranchExternalIds]
      : ['all']

  const result = {
    externalId: {
      value: customer.externalId || ''
    },
    tags: {
      values: customer.tagIds || [],
      options: (customer.availableTags || []).map(item => ({
        label: item.name,
        value: item.id,
        tagColor: item.color
      }))
    },
    location: {
      disabled: false,
      options: branches.map(branch => ({
        label: branch.name, value: branch.id
      })),
      values: locationValues
    }
  }

  customerFields
    .filter(item => item.hasOverwrite)
    .forEach(item => {
      result[`customerFields${item.id}`] = {
        value: item.value || ''
      }
      if (item.type === 'CHECKBOX') {
        result[`customerFields${item.id}`].value = item.value + '' === 'true'
      }
      if (item.type === 'SELECT') {
        result[`customerFields${item.id}`].options = item.selectOptions
        if ((item.selectOptions || []).findIndex(option => option.value === item.value) === -1) result[`customerFields${item.id}`].value = ''
      }
      if (item.type === 'PHONE') {
        result[`customerFields${item.id}`] = {
          phone: (item.value && item.value.number) || '',
          phoneCountry: item.value
            ? item.value.country
            : companyLocale.length > 2
              ? (companyLocale.split('-')[1] || '').toUpperCase()
              : DEFAULT_LOCALE_COUNTRY_CODE
        }
      }
      if (item.type === 'FILE' && item.defaultId === 'avatar') {
        result[`customerFields${item.id}`] = {
          avatarUrl: { value: item.value || '' },
          value: ''
        }
      }
      if (item.type === 'ADDRESS') {
        const address = item.value || {}
        result[`customerFields${item.id}`].value = address.formatted
        result[`customerFields${item.id}`].data = ((address.formatted && address.formatted !== '') && {
          placeId: address.placeId || '',
          city: address.city || '',
          streetName: address.street || '',
          streetNumber: address.streetNumber || '',
          postalCode: address.zipCode || '',
          country: address.country || '',
          lat: address.latitude || '',
          lng: address.longitude || '',
          formattedAddress: address.formatted || '',
          details: address.details || ''
        }) || null
        result[`customerFields${item.id}Secondary`] = {
          value: address.details || ''
        }
      }
      result[`customerFields${item.id}`].type = item.type
      result[`customerFields${item.id}`].defaultId = item.defaultId
      result[`customerFields${item.id}`].isMandatory = item.isMandatoryOffline
      result[`customerFields${item.id}`].translationKey = item.translationKey
    })
  result.customerPrintUpcomingBookings = { value: false }
  result.customerPrintPastBookings = { value: false }
  if (customer.id) result.id = customer.id
  return result
}

export const customerFormServerErrorsTransform = error => {
  const errors = []
  if (error.code) {
    if (error.code === 'MissingOrInvalidPhone') {
      const splittedMesage = error.message.split(' ')
      const customerFieldId = splittedMesage[splittedMesage.length - 1]
      errors.push({ key: `customerFields${customerFieldId}`, value: translateServerCode(error.code) })
    } else if (error.code === 'ExternalIdConflicts') {
      errors.push({ key: 'externalId', value: translateServerCode(error.code) })
    } else if (error.code === 'GlobalCustomerSaveError') {
      error.data.localErrors.forEach((error) => {
        errors.push({
          key: `customerFields${error.globalCustomerFieldId}`,
          value: translateServerCode(error.code)
        })
      })
    } else {
      errors.push({ key: 'globalErrors', value: translateServerCode(error.code) })
    }
  } else {
    errors.push({ key: 'globalErrors', value: 'errors.somethingWentWrong' })
  }
  return errors
}

// VALIDATION

export const customerFormValidate = (customer, customerFields) => {
  if (!customer) return

  const combinedFields = Object.keys(customer).reduce((acc, key) => {
    if (key.indexOf('customerFields') !== -1) {
      acc.push({
        ...customer[key],
        name: key
      })
    }
    return acc
  }, [])

  let fieldErrors = []
  const rules = []
  const messages = {}
  const replaces = {}
  const atLeastOneIsRequired = ['firstName', 'lastName', 'company']
  const atLeastOneIsRequiredValues = []
  const atLeastOneIsRequiredFields = []

  let atLeastOneCheck = true

  combinedFields.forEach((field, index) => {
    const name = field.name
    const combinedField = (customerFields && customerFields.length > 0 && customerFields.find(item => `customerFields${item.id}` === name)) || {}
    const defaultId = (field.defaultId && field.defaultId) || ''
    const type = field.type && field.type
    const value = field.value && field.value
    const isMandatory = !!field.isMandatory
    const translation = field.translationKey || combinedField.label
    const translateReplace = !!field.translationKey
    const isAddressSet = customer[name] && customer[name].value && customer[name].value !== ''
    let addressErrorMessage = 'errors.address.required'

    switch (type) {
      case 'FILE':
        if (defaultId.toLowerCase() === 'avatar') {
          if (isMandatory) {
            const isAvatarSet = !!(field.avatarUrl && field.avatarUrl.value && field.avatarUrl.value !== '')
            rules.push({ [`${name}.avatarFile`]: [`requiredIf:${!isAvatarSet}`] })
          }
          messages[name] = {
            requiredIf: 'errors.required'
          }
          replaces[name] = {
            requiredIf: { key: 'FIELD_NAME', value: translation, translateReplace }
          }
        }
        break

      case 'TEXT':
        if (atLeastOneIsRequired.includes(defaultId) && !atLeastOneIsRequiredValues.find(item => item === value)) {
          if (value && value.trim() !== '') atLeastOneIsRequiredValues.push(value)
          if (isMandatory) atLeastOneCheck = false
        }
        if (['company', 'firstName', 'lastName'].includes(defaultId)) atLeastOneIsRequiredFields.push(name)

        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })

        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'TEXTAREA':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:3000'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 3000 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'SELECT':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'DATE':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'CHECKBOX':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:64'] })
        messages[name] = {
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 64 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'EMAIL':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['email', 'max:255'] })
        messages[name] = {
          email: 'errors.email.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 255 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'PHONE':
        if (isMandatory) rules.push({ [`${name}.phone`]: ['required'] })
        rules.push({ [`${name}.phone`]: ['phone', 'max:16'] })
        messages[name] = {
          phone: 'errors.phone.invalid',
          max: 'errors.invalidMaxLength',
          required: 'errors.required'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 16 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'ADDRESS':
        if (isMandatory) rules.push({ [`${name}.value`]: ['required', 'max:800'] })
        if (isAddressSet) {
          rules.push({ [`${name}.data.city`]: [`requiredIf:${isAddressSet}`] })
          rules.push({ [`${name}.data.streetName`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.streetNumber`]: [`requiredIf:${isAddressSet}`] })
          // rules.push({ [`${name}.data.postalCode`]: [`requiredIf:${isAddressSet}`] })
        }

        if (isAddressSet && customer[name] && customer[name].data) {
          if (!customer[name].data.city || customer[name].data.city === '') {
            addressErrorMessage = 'errors.address.fillCity'
          } else if (!customer[name].data.streetName || customer[name].data.streetName === '') {
            addressErrorMessage = 'errors.address.fillStreetName'
          }
          // else if (!customer[name].data.streetNumber || customer[name].data.streetNumber === '') {
          //   addressErrorMessage = 'errors.address.fillStreetNumber'
          // }
          // else if (!customer[name].data.postalCode || customer[name].data.postalCode === '') {
          //   addressErrorMessage = 'errors.address.fillZipCode'
          // }
        }

        messages[name] = {
          requiredIf: addressErrorMessage,
          max: 'errors.invalidMaxLength'
        }
        replaces[name] = {
          max: { key: 'MAX', value: 800 },
          required: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        break

      case 'FILES':
        if (isMandatory) rules.push({ [`${name}.values`]: [`requiredIf:${(field.selected || []).length === 0}`] })
        messages[name] = {
          requiredIf: 'errors.required'
        }
        replaces[name] = {
          requiredIf: { key: 'FIELD_NAME', value: translation, translateReplace }
        }
        fieldErrors = [...fieldErrors, ...(field.errors || [])]
        break

      default:
        if (isMandatory) rules.push({ [`${name}.value`]: ['required'] })
        rules.push({ [`${name}.value`]: ['max:255'] })

        messages[name] = {
          max: 'invalidMaxLength',
          required: 'errors.required'
        }
        break
    }
  })

  let errors = validator(customer, rules, messages, replaces)
  if (fieldErrors.length > 0) {
    fieldErrors = fieldErrors.filter(item => !(errors || []).find(error => error.key === item.key)) // Remove duplicated errors
    errors = [...errors, ...fieldErrors]
  }

  if (atLeastOneCheck && atLeastOneIsRequiredValues.length === 0) {
    atLeastOneIsRequiredFields.forEach(item => {
      errors.push({
        key: item,
        value: 'errors.requiredOne'
      })
    })
  }

  return errors.length && errors
}

// // SAVE

export const customerSaveTransform = customer => {
  const fields = {}
  Object
    .keys(customer)
    .filter(item => item.includes('customerFields'))
    .forEach(item => {
      const field = customer[item]
      const id = item.replace('customerFields', '')
      if (field.type === 'DATE' && field.value) {
        fields[id] = moment.utc(field.value, 'YYYY-MM-DD').format('YYYY-MM-DD')
      }
      if (field.type === 'PHONE' && field.phone) {
        fields[id] = JSON.stringify({
          number: field.phone,
          country: field.phoneCountry
        })
      }
      if (field.type === 'CHECKBOX') {
        fields[id] = field.value + ''
      }
      if (field.type === 'FILE' && field.defaultId === 'avatar') {
        if (field.value === null) fields[id] = null
        if (field.avatarFile) fields[id] = field.avatarFile
      }
      if (field.type === 'ADDRESS' && field.value) {
        const value = fields[id] ? JSON.parse(fields[id]) : null
        const data = field.data || null
        const newValue = (data && {
          placeId: data && data.placeId,
          latitude: data && data.lat,
          longitude: data && data.lng,
          street: data && data.streetName,
          streetNumber: data && data.streetNumber,
          city: data && data.city,
          country: data && data.country,
          zipCode: data && data.postalCode,
          formatted: data && data.formattedAddress
        }) || null
        // if secondary address come first, we have to pre-fill it
        if (newValue && value && value.details) newValue.details = value.details
        fields[id] = JSON.stringify(newValue)
      }
      if (id.includes('Secondary')) {
        const addressId = id.replace('Secondary', '')
        const newValue = fields[addressId] ? JSON.parse(fields[addressId]) : null
        if (newValue) {
          newValue.details = field.value
          fields[addressId] = JSON.stringify(newValue)
        }
      }
      if (!fields[id] && field.type !== 'FILE') fields[id] = field.value || null
    })

  const result = {
    customer: {
      id: customer.id,
      fields: Object
        .keys(fields)
        .filter(item => item.length === 24)
        .map(id => ({ id, value: fields[id] }))
    }
  }

  if (customer.location && customer.location.values && !customer.location.values.includes('all')) {
    result.globalFilterConfig = {
      allowedBranchIds: (customer.location && customer.location.values) || []
    }
  }

  return result
}

// UPDATE
export const customerTransform = (customer, skipTransform) => {
  if (!customer) return
  if (skipTransform) return customer
  const PARSE_TYPES = ['PHONE', 'ADDRESS']
  return {
    id: customer.id,
    externalId: customer.externalId,
    internalId: customer.internalId,
    isUpdatedLocally: customer.isUpdatedLocally,
    isGloballyDeleted: customer.isGloballyDeleted,
    fullName: customer.fullName,
    companyName: customer.companyName,
    phone: customer.phone,
    email: customer.email,
    totalBookings: customer.totalBookings,
    avatarUrl: customer.avatarUrl,
    tagIds: customer.tagIds,
    allowedBranchIds: customer.allowedBranchIds,
    allowedBranchExternalIds: customer.allowedBranchExternalIds,
    ts: customer.ts,
    fields: customer.fields && customer.fields
      .map(item => ({
        id: item.id,
        type: item.type,
        value: item.value && PARSE_TYPES.includes(item.type)
          ? JSON.parse(item.value)
          : item.value
      }))
      .sort(sortByOrderIndex)
  }
}

// FORM
export const customerEventFormTransform = (options) => {
  const result = {
    type: {
      value: 'UPCOMING',
      options: [
        {
          label: options.upcoming,
          value: 'UPCOMING'
        },
        {
          label: options.past,
          value: 'PAST'
        },
        {
          label: options.cancelled,
          value: 'DELETED'
        }
      ]
    }
  }
  return result
}

// PREVIEW

export const customerPreviewErrorsTransform = error => {
  if (!error) return
  const text = error.text
    ? error.text
    : translateServerCode(error.code) !== 'errors.somethingWentWrong'
      ? translateServerCode(error.code)
      : ''
  return {
    type: error.type || 'error',
    text
  }
}
