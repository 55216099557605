import {
  accountPopulateTransform,
  accountFormTransform,
  accountEditSaveTransform,
  accountPasswordChangeSaveTransform,
  accountEmailChangeSaveTransform,
  accountEmailChangeCancelSaveTransform,
  accountSecurityFormTransform
} from './utils'

const reducer = {}

reducer.initialState = {
  accounts: [],
  userAccounts: [],
  messagePreview: null,
  pendingForm: true,
  pendingPreview: false,
  pendingAccountSelect: false,
  pendingDeleteForm: false,
  pendingSecurityForm: false,
  settings: {},
  errorToShow: null
}

reducer.handlers = (dispatch, actions, handlers) => ({
  accountReset: () => dispatch(actions.ACCOUNT_RESET),
  accountSelect: id => dispatch(actions.ACCOUNT_SELECT, id),
  accountChanged: ({ account, showTwoFA }) => dispatch(actions.ACCOUNT_CHANGED, { account, showTwoFA }),
  accountPopulate: account => dispatch(actions.ACCOUNT_POPULATE, accountPopulateTransform(account)),
  accountPendingEmailPopulate: pendingEmail => dispatch(actions.ACCOUNT_PENDING_EMAIL_POPULATE, pendingEmail),
  accountDeleted: id => dispatch(actions.ACCOUNT_DELETED, id),
  accountRemoveDeleted: id => dispatch(actions.ACCOUNT_REMOVE_DELETED, id),
  accountEmailConfirmationSend: ({ accountId, email }) => dispatch(actions.ACCOUNT_EMAIL_CONFIRMATION_SEND, { accountId, email }),
  accountSubscriptionSet: ({ name, id, data, ts }) => dispatch(actions.ACCOUNT_SUBSCRIPTION_SET, { name, id, data, ts }),
  accountUnlink: ({ id, type }) => dispatch(actions.ACCOUNT_UNLINK, ({ id, type })),
  accountPreviewGet: () => dispatch(actions.ACCOUNT_PREVIEW_GET),
  accountPreviewPopulate: account => dispatch(actions.ACCOUNT_PREVIEW_POPULATE, account),
  accountPreviewMessageChange: ({ text, type = 'error' }) => dispatch(actions.ACCOUNT_PREVIEW_MESSAGE_CHANGE, { text, type }),
  accountPreviewMessageReset: () => dispatch(actions.ACCOUNT_PREVIEW_MESSAGE_RESET),
  accountCustomisationsApply: () => dispatch(actions.ACCOUNT_CUSTOMISATIONS_APPLY),
  accountFormGet: () => dispatch(actions.ACCOUNT_FORM_GET),
  accountFormPopulate: account => {
    handlers.formSet('account', accountFormTransform(account))
    handlers.accountFormReady()
  },
  accountFormReady: () => dispatch(actions.ACCOUNT_FORM_READY),
  accountFormEditSave: (account, scrollToError = () => { }) => dispatch(actions.ACCOUNT_FORM_EDIT_SAVE, { account: accountEditSaveTransform(account), scrollToError }),
  accountFormPasswordChangeSave: (account, scrollToError = () => { }) => dispatch(actions.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE, { account: accountPasswordChangeSaveTransform(account), scrollToError }),
  accountFormPasswordChangeRedirect: () => dispatch(actions.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT),
  accountFormEmailChangeSave: (account, scrollToError = () => { }) => dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_SAVE, { account: accountEmailChangeSaveTransform(account), scrollToError }),
  accountFormEmailChangeCancelSave: (account, scrollToError = () => { }) => dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE, { account: accountEmailChangeCancelSaveTransform(account), scrollToError }),
  accountFormEmailChangeCancelInvalidRequest: () => {
    handlers.formFieldsUpdate('account', { pendingEmail: null })
    handlers.accountFormEmailChangeCancelInvalidRequestReady()
  },
  accountFormEmailChangeCancelInvalidRequestReady: () => dispatch(actions.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_INVALID_REQUEST_READY),
  accountFormDelete: () => dispatch(actions.ACCOUNT_FORM_DELETE),
  accountDeleteFormSave: (form, scrollToError = () => { }) => dispatch(actions.ACCOUNT_DELETE_FORM_SAVE, { form, scrollToError }),
  accountDeleteFormReady: () => dispatch(actions.ACCOUNT_DELETE_FORM_READY),
  accountLastBookingExportSet: date => dispatch(actions.ACCOUNT_LAST_BOOKING_EXPORT_SET, date),
  accountSecurityFormGet: () => dispatch(actions.ACCOUNT_SECURITY_FORM_GET),
  accountSecurityFormPopulate: (user, errorToShow) => {
    handlers.formSet('accountSecurity', accountSecurityFormTransform(user))
    if (errorToShow) {
      handlers.formErrorsSet('accountSecurity', errorToShow)
      handlers.accountSecurityErrorToShow(null)
    }
    handlers.accountSecurityFormReady()
  },
  accountSecurityFormReady: () => dispatch(actions.ACCOUNT_SECURITY_FORM_READY),
  account2FADisable: ({ withLogout, code }, cb) => dispatch(actions.ACCOUNT_TWO_FA_DISABLE, { withLogout, code, cb }),
  account2FADisabled: () => dispatch(actions.ACCOUNT_TWO_FA_DISABLED),
  account2FAActivated: () => dispatch(actions.ACCOUNT_TW_FA_ACTIVATED),
  account2FAJwtActivated: () => dispatch(actions.ACCOUNT_TWO_FA_JWT_ACTIVATED),
  accountSecurityErrorToShow: error => dispatch(actions.ACCOUNT_SECURITY_ERROR_TO_SHOW, { error }),
  accountPendingSet: pending => dispatch(actions.ACCOUNT_PENDING_SET, pending),
  accountCompanySessionConfirm: (id) => dispatch(actions.ACCOUNT_COMPANY_SESSION_CONFIRM, { id }),
  accountSessionConfirm: ({ id, data }) => dispatch(actions.ACCOUNT_SESSION_CONFIRM, { id, data }),
  accountCallCentreSettingsPopulate: (data) => dispatch(actions.ACCOUNT_CALL_CENTRE_SETTINGS_POPULATE, data)
})

reducer.ACCOUNT_COMPANY_SESSION_CONFIRM = state => state

reducer.ACCOUNT_SESSION_CONFIRM = state => state

reducer.ACCOUNT_SECURITY_FORM_GET = state => ({
  ...state,
  pendingSecurityForm: true
})
reducer.ACCOUNT_SECURITY_FORM_READY = state => ({
  ...state,
  pendingSecurityForm: false
})

reducer.ACCOUNT_PENDING_SET = (state, pending) => ({
  ...state,
  pendingAccountSelect: pending
})

reducer.ACCOUNT_SECURITY_ERROR_TO_SHOW = (state, { error }) => ({
  ...state,
  errorToShow: error
})

reducer.ACCOUNT_RESET = () => reducer.initialState

reducer.ACCOUNT_SELECT = state => ({
  ...state,
  pendingAccountSelect: true
})

reducer.ACCOUNT_CHANGED = state => state

reducer.ACCOUNT_POPULATE = (state, account) => account ? ({
  ...state,
  ...account,
  pendingAccountSelect: false
}) : state

reducer.ACCOUNT_PENDING_EMAIL_POPULATE = (state, pendingEmail) => ({
  ...state,
  pendingEmail
})

reducer.ACCOUNT_SUBSCRIPTION_SET = state => state

reducer.ACCOUNT_UNLINK = state => state

reducer.__ACCOUNT_ADD = state => state

reducer.ACCOUNT_EMAIL_CONFIRMATION_SEND = (state, { accountId }) => {
  if (!accountId) return state
  const userAccounts = [...state.userAccounts]
  const index = userAccounts.findIndex(item => item.accountId === accountId)
  if (index < 0) return state
  userAccounts[index] = { ...userAccounts[index], isConfirmationEmailReSent: true }
  return {
    ...state,
    userAccounts
  }
}

reducer.ACCOUNT_DELETED = (state, id) => ({
  ...state,
  accounts: state.accounts.filter(item => item.id !== id),
  userAccounts: state.userAccounts.filter(item => item.accountId !== id)
})

reducer.ACCOUNT_PREVIEW_GET = state => ({
  ...state,
  messagePreview: null,
  pendingPreview: true
})

reducer.ACCOUNT_PREVIEW_POPULATE = (state, account = null) => ({
  ...state,
  pendingPreview: false,
  selected: account || null
})

reducer.ACCOUNT_PREVIEW_MESSAGE_CHANGE = (state, { text, type }) => text ? {
  ...state,
  messagePreview: { text, type }
} : state

reducer.ACCOUNT_PREVIEW_MESSAGE_RESET = state => ({
  ...state,
  messagePreview: null
})

reducer.ACCOUNT_CUSTOMISATIONS_APPLY = state => state

reducer.ACCOUNT_FORM_GET = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_READY = state => ({
  ...state,
  pendingForm: false
})

reducer.ACCOUNT_FORM_EDIT_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_PASSWORD_CHANGE_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_PASSWORD_CHANGE_REDIRECT = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_SAVE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_FORM_EMAIL_CHANGE_CANCEL_INVALID_REQUEST_READY = state => ({
  ...state,
  pendingForm: false,
  pendingEmail: null
})

reducer.ACCOUNT_FORM_DELETE = state => ({
  ...state,
  pendingForm: true
})

reducer.ACCOUNT_DELETE_FORM_SAVE = state => ({
  ...state,
  pendingDeleteForm: true
})

reducer.ACCOUNT_DELETE_FORM_READY = state => ({
  ...state,
  pendingDeleteForm: false
})

reducer.ACCOUNT_LAST_BOOKING_EXPORT_SET = (state, date) => ({
  ...state,
  settings: {
    ...state.settings,
    executedEventsExportAt: date || null
  }
})

reducer.ACCOUNT_TWO_FA_DISABLE = state => state

reducer.ACCOUNT_TWO_FA_DISABLED = (state) => ({
  ...state,
  auth2FAs: state.auth2FAs.map(account => ({ ...account, isActive: false })),
  jwtAuth2FAs: [{ type: 'OTP', isAuth: false }]
})

reducer.ACCOUNT_TW_FA_ACTIVATED = state => ({
  ...state,
  auth2FAs: [{ type: 'OTP', isActive: true }],
  jwtAuth2FAs: [{ type: 'OTP', isAuth: true }]
})

reducer.ACCOUNT_TWO_FA_JWT_ACTIVATED = state => ({
  ...state,
  jwtAuth2FAs: [{ type: 'OTP', isAuth: true }]
})

reducer.ACCOUNT_CALL_CENTRE_SETTINGS_POPULATE = (state, data) => (!data ? {} : {
  ...state,
  enterprise: {
    ...state.enterprise,
    callCentreSettings: data
  }
})

reducer.ACCOUNT_REMOVE_DELETED = (state, id) => ({
  ...state,
  list: state.list.filter(item => item.id !== id)
})

export default reducer
