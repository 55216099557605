import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactAvatarEditor from 'react-avatar-editor'
import Dropzone from 'react-dropzone'
import fetch from 'isomorphic-fetch'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  FormContext,
  FormGroup,
  Error,
  SingleSlider,
  FontAwesome5,
  Image,
  t
} from '../../../Common'

import './ImageInput.css'

export class ImageInput extends Component {
  constructor (props, context) {
    super(props)

    this.state = {
      focused: false,
      croppedImage: null
    }

    this.defaults = {
      defaultZoom: 0,
      defaultBorderRadius: 100,
      cropBorder: [0, 0],
      defaultRotation: 0,
      controls: {},
      form: { value: '' }
    }

    const { validation = {} } = this.props
    this.validation = {
      minSize: validation.minSize ? validation.minSize : 0,
      maxSize: validation.maxSize ? validation.maxSize : 5,
      extensions: validation.extensions ? validation.extensions : ['jpeg', 'jpg', 'png']
    }

    this.wrapperRef = React.createRef()
    this.editorRef = React.createRef()
    this.resultImageRef = React.createRef()

    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onDrop = this.onDrop.bind(this)
    this.onZoomChange = this.onZoomChange.bind(this)
    this.onCropRadiusChange = this.onCropRadiusChange.bind(this)
    this.onCrop = this.onCrop.bind(this)
    this.onClickDeleteImage = this.onClickDeleteImage.bind(this)
    this.onPositionChange = this.onPositionChange.bind(this)
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onDrop (dropped) {
    if (!dropped) return

    let {
      name,
      form,
      formName,
      onChangeAddon,
      onChange,
      defaultZoom,
      defaultBorderRadius
    } = this.props
    form = form || { value: '' }

    const value = dropped[0] || {}
    const lastElementIndex = Number(value.name.split('.').length) - 1
    const ext = (value.name && value.name.split('.')[lastElementIndex]) ? value.name.split('.')[lastElementIndex].toLowerCase() : ''
    const { maxSize, minSize, extensions } = this.validation
    const minSizeBytes = minSize * 1048576
    const maxSizeBytes = maxSize * 1048576
    const errors = []

    if (extensions.indexOf(ext) === -1) {
      errors.push({
        key: name,
        value: 'errors.extensionNotAllowed',
        replace: [{ key: 'EXTENSION', value: ext }]
      })
      handlers.formErrorsSet(formName, errors)
      return
    }

    if (value.size < minSizeBytes) {
      errors.push({
        key: name,
        value: 'errors.minAllowedSize',
        replace: [{ key: 'MIN_SIZE', value: maxSize }]
      })
      handlers.formErrorsSet(formName, errors)
      return
    }

    if (value.size > maxSizeBytes) {
      errors.push({
        key: name,
        value: 'errors.maxAllowedSize',
        replace: [{ key: 'MAX', value: maxSize }]
      })
      handlers.formErrorsSet(formName, errors)
      return
    }

    if (onChange) return onChange(name, value)
    this.onCrop()
    handlers.formFieldsUpdate(formName, {
      [name]: {
        ...form,
        value,
        rotation: 0,
        zoom: defaultZoom,
        cropRadius: {
          value: defaultBorderRadius
        }
      },
      [`${name}Zoom`]: {
        value: defaultZoom
      },
      [`${name}CropRadius`]: {
        value: defaultBorderRadius
      }
    })
    onChangeAddon && onChangeAddon(value, name)
  }

  onFocus () {
    this.setState({ focused: true })
  }

  onBlur () {
    this.setState({ focused: false })
  }

  onChange () {
    const value = this.ref.value || ''
    let { name, form, formName, onChange, onChangeAddon } = this.props
    form = form || { value: '' }
    if (onChange) return onChange(name, value)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, value } })
    onChangeAddon && onChangeAddon(value, name)
  }

  onZoomChange (zoomValue) {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    const zoom = { ...form.zoom, value: zoomValue }
    handlers.formFieldsUpdate(formName, { [name]: { ...form, zoom } })
    this.onCrop()
  }

  onCropRadiusChange (cropRadiusValue) {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    const cropRadius = { ...form.zoom, value: cropRadiusValue }
    handlers.formFieldsUpdate(formName, { [name]: { ...form, cropRadius } })
    this.onCrop()
  }

  rotate (degrees) {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    const { rotation } = form
    const newRotation = (!rotation && degrees) || (rotation + degrees)
    handlers.formFieldsUpdate(formName, { [name]: { ...form, rotation: newRotation } })
    this.onCrop()
  }

  onPositionChange () {
    this.onCrop()
  }

  onCrop () {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      let { name, form, formName } = this.props
      form = form || { value: '' }
      const canvas = this.editorRef && this.editorRef.current && this.editorRef.current.getImageScaledToCanvas().toDataURL()

      fetch(canvas)
        .then((res) => res.url)
        .then(imageURL => {
          this.setState({
            croppedImage: imageURL
          })
          handlers.formFieldsUpdate(formName, { [name]: { ...form, avatarFile: imageURL } })
        })
    }, 500)
  }

  onClickDeleteImage () {
    let { name, form, formName } = this.props
    form = form || { value: '' }
    const value = null

    clearTimeout(this.timeout)
    this.setState({
      croppedImage: null
    }, () => {
      handlers.formFieldsUpdate(formName, { [name]: { ...form, value, avatarUrl: null } })
    })
  }

  render () {
    const { focused } = this.state
    let {
      label,
      hintText,
      addon,
      name,
      mandatory,
      disabled,
      hideError,
      className,
      defaultZoom,
      defaultBorderRadius,
      cropBorder,
      defaultRotation,
      controls,
      form,
      preview,
      title,
      text
    } = this.props
    text = text || ''
    title = title || ''
    preview = !!preview
    form = form || { value: '' }
    defaultZoom = defaultZoom || 0
    cropBorder = cropBorder || [0, 0]
    defaultRotation = defaultRotation || 0
    defaultBorderRadius = defaultBorderRadius || 100

    const {
      value = null
    } = form

    const {
      zoom = {},
      cropRadius = {},
      rotation = 0,
      avatarUrl
    } = form

    const allControls = {
      rotateLeft: true,
      rotateRight: true,
      zoom: true,
      radius: true,
      ...controls
    }

    const maxRadius = cropBorder && (cropBorder[0] + cropBorder[1])
    const classNames = ['ta-image-input']
    const classNamesDeleteBtn = ['ta-btn ta-btn-grey-ghost']
    if (avatarUrl && avatarUrl.value) {
      classNamesDeleteBtn.push('ta-btn-full-width')
    }

    const controlDelete = (
      <div className={classNamesDeleteBtn.join(' ')} onClick={this.onClickDeleteImage}>
        <FontAwesome5 icon='trash' type='solid' />
        {(avatarUrl && avatarUrl.value) && <div className='ta-btn-full-width-label'>{t('global.delete')}</div>}
      </div>
    )
    const controlRotateLeft = (
      <div className='ta-btn ta-btn-grey-ghost ta-image-input__rotate-left' onClick={this.rotate.bind(this, -90)}>
        <FontAwesome5 icon='undo' type='solid' />
      </div>
    )
    const controlRotateRight = (
      <div className='ta-btn ta-btn-grey-ghost ta-image-input__rotate-right' onClick={this.rotate.bind(this, 90)}>
        <FontAwesome5 icon='redo' type='solid' />
      </div>
    )
    const controlZoomSlider = (
      <SingleSlider
        name={`${name}Zoom`}
        min={1}
        max={2}
        step={0.1}
        defaultValue={(form.zoom && form.zoom.value) || 1}
        // leftLabel={<FontAwesome5 icon='search-minus' type='solid' className='ta-image-input__slider__icon' />}
        // rightLabel={<FontAwesome5 icon='search-plus' type='solid' className='ta-image-input__slider__icon' />}
        onChange={this.onZoomChange}
        autoComplete='off'
      />
    )
    const controlRadiusSlider = (
      <SingleSlider
        name={`${name}CropRadius`}
        min={1}
        max={maxRadius}
        step={1}
        defaultValue={defaultBorderRadius}
        leftLabel={<FontAwesome5 icon='square' type='solid' className='ta-image-input__slider__icon' />}
        rightLabel={<FontAwesome5 icon='circle' type='solid' className='ta-image-input__slider__icon' />}
        onChange={this.onCropRadiusChange}
        autoComplete='off'
      />
    )
    const controlsPanel = (
      <>
        {(allControls && allControls.rotateLeft) && allControls.rotateLeft && controlRotateLeft}
        {allControls && controlDelete}
        {(allControls && allControls.rotateRight) && allControls.rotateRight && controlRotateRight}
      </>
    )
    const editor = (
      <>
        <ReactAvatarEditor
          color={[37, 64, 90, 0.6]}
          ref={this.editorRef}
          style={{ width: '100%', height: '100%' }}
          image={preview || value} // The image URL
          scale={zoom.value || defaultZoom} // Zoom in/out
          border={cropBorder} // Cropping border. [Horizontal x Vertical] from the edge of the photo.
          borderRadius={cropRadius.value || defaultBorderRadius} // Square/Circle cropping figure.
          rotate={rotation || defaultRotation} // The rotation of the image. 0 / 90 / 180
          onPositionChange={this.onPositionChange}
        />
        {allControls && (
          <div className='ta-image-input__controls'>
            <div className='ta-image-input__buttons'>
              {controlsPanel}
            </div>
            <div className='ta-image-input__sliders'>
              {(allControls.zoom || allControls.radius) &&
                <>
                  {allControls.zoom && controlZoomSlider}
                  {allControls.radius && controlRadiusSlider}
                </>
              }
            </div>
          </div>
        )}
      </>
    )

    const upload = (
      <Dropzone
        onDrop={this.onDrop}
        disableClick={!!value}
        className='ta-image-input__dropzone'
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className='ta-image-input-upload-area'>
            <input {...getInputProps()} />
            <FontAwesome5 icon='cloud-upload' type='solid' className='ta-image-input-upload-area__icon' />
            <div className='ta-image-input-upload-area__text'>
              {title}
            </div>
            <div className='ta-image-input-upload-area__formats'>{text}</div>
          </div>
        )}
      </Dropzone>
    )

    const currentImage = (avatarUrl && avatarUrl.value) && (
      <div className='ta-image-wrapper'>
        <Image height={100} width={100} src={avatarUrl.value} alt='' />
        <div className='ta-image-input__controls'>
          <div className={(avatarUrl && avatarUrl.value) ? 'ta-image-input__buttons center' : 'ta-image-input__buttons'}>
            {controlDelete}
          </div>
        </div>
      </div>
    )

    if (className) classNames.push(className)

    return (
      <div ref={this.wrapperRef} className={classNames.join(' ')} >
        <FormGroup
          focused={focused}
          filled={!!value || !!form.value}
          disabled={disabled || form.disabled}
          labelText={label || form.label}
          labelMandatory={mandatory || form.mandatory}
        >
          {(preview || (avatarUrl && avatarUrl.value))
            ? (preview && editor) || currentImage
            : value ? editor : upload
          }

          {addon && <div className='ta-form-control__addon'>{addon}</div>}
          {hintText && <div className='ta-form-control__hint'>{hintText}</div>}
          {!hideError && <Error name={name} />}
        </FormGroup>
      </div>
    )
  }
}

ImageInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  hintText: PropTypes.string,
  className: PropTypes.string,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  hideError: PropTypes.bool,
  defaultZoom: PropTypes.number,
  defaultBorderRadius: PropTypes.number,
  defaultRotation: PropTypes.number,
  cropBorder: PropTypes.arrayOf(PropTypes.number),
  form: PropTypes.object,
  controls: PropTypes.object
}

export const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(ImageInput), FormContext)
