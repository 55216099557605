import React from 'react'
import { connect, selectors } from '../../Store'
import { FontAwesome5 } from '../../Common'

import './Connection.css'

const Online = props => {
  const {
    connection,
    children,
    className,
    hideContent,
    disabled
  } = props
  const classNames = ['ta-offline']
  if (className) classNames.push(className)

  const handleOnclick = (event) => {
    event && event.stopPropagation()
    return null
  }

  if (disabled) return children

  const content = (
    <div onClick={handleOnclick} className={classNames.join(' ')} >
      <div className='ta-offline-icon'>
        <FontAwesome5 icon='wifi-slash' type='s' />
      </div>
      <div className='ta-offline-body'>
        {children}
      </div>
    </div>
  )

  return (connection === true)
    ? children
    : hideContent
      ? null
      : content
}

const maps = state => ({
  connection: !!selectors.appFieldSelector(state, { field: 'isConnected' })
})

export default connect(maps)(Online)
