import React from 'react'
import { FontAwesome5, Link, t } from '../../Common'
import './AccountsSwitch.css'

const AccountsSwitch = props => {
  const { isActive, companyName, negativeLevel, onClick } = props
  const classNames = ['ta-accounts-switch']
  const icon = negativeLevel ? 'angle-left' : 'angle-right'
  if (isActive) classNames.push('active')

  return (
    <Link onClick={() => onClick('/accounts')} className={classNames.join(' ')}>
      <div className='ta-accounts-switch__label'>
        <FontAwesome5 icon='store' type='solid' className='ta-accounts-switch__icon' />
        <div className='ta-accounts-switch__name_wrapper'>
          <span className='ta-accounts-switch__company_name'>{companyName}</span>
          <small className='ta-accounts-switch__subtitle'>{t('acountSwitcher.subtitle')}</small>
          <div className='ta-navigation__toggle-arrow__icon__ghost'>
            <FontAwesome5 icon={icon} />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default AccountsSwitch
