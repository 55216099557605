import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import momenttz from 'moment-timezone'
import {
  Spinner,
  CalendarWeekInput,
  format,
  t,
  FontAwesome5,
  Alert,
  Title,
  Button,
  getToday,
  Row,
  Col,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger
} from '../../../Common'
import { getSlotFootfall, isOverlapFootfall } from '../../../Utils'
import { FOOTFALL_COLORS, FOOTFALL_STATUS } from '../../../Settings'

class BookingFormSlot extends PureComponent {
  componentDidMount () {
    const { scrollTo, from } = this.props
    const topOffset = ReactDOM.findDOMNode(this.wrapper).offsetTop
    scrollTo && scrollTo(topOffset)
    const today = getToday()
    this.props.updateAvailability(from, today)
  }

  render () {
    const {
      pending,
      slots,
      timezone,
      onSlotClick,
      hasSlots,
      duration,
      nextAvailableDays,
      onClickNextAvailableDay,
      progress,
      footfallMapping,
      companyTimezone,
      onAvailabilityDateChange,
      onSlotSubmit
    } = this.props
    let { availableSlots, values } = slots || {}
    availableSlots = availableSlots || []
    values = values || []
    values.sort()

    return (
      <div
        className='ta-booking-form__widget-availability'
        ref={wrapper => { this.wrapper = wrapper }}
      >
        {progress <= 4 && (
          <div className='ta-booking-form__steps-separator' />
        )}
        <CalendarWeekInput
          name='availabilityDate'
          startWeekDateFormat='long'
          endWeekDateFormat='long'
          minDate={momenttz.tz(new Date(), timezone)}
          onChangeAddon={onAvailabilityDateChange}
        />
        {pending && (
          <div className='ta-booking-form__widget-availability__loader'>
            <Spinner />
          </div>
        )}
        {!pending && hasSlots && availableSlots.map(item => {
          let { day, times } = item
          times = times || []
          const isActive = values.reduce((acc, item) => {
            if (item.includes(day)) return true
            return acc
          }, false)
          const dayLabelClassNames = ['ta-booking-form__widget-availability__day__label']
          if (isActive) dayLabelClassNames.push('active')
          return (
            <div key={day} className='ta-booking-form__widget-availability__day'>
              <div className={dayLabelClassNames.join(' ')}>
                {format(day, 'longWithShortWeekDay', { isUTC: true, format: 'YYYY-MM-DD' })}
              </div>
              <div className='ta-booking-form__widget-availability__day__slots'>
                {times.length > 0 && times.map(time => {
                  const formattedTime = format(time.time, 'time', { isUTC: true, format: 'HH:mm' })
                  const slot = `${day} ${time.time}`
                  const slotClassNames = ['ta-booking-form__widget-availability__day__slots__slot']
                  if (values.includes(slot)) slotClassNames.push('active')
                  if (time.isDisabled && !slotClassNames.includes('active')) slotClassNames.push('disabled')

                  const footfall = getSlotFootfall(day, time.time, footfallMapping, { companyTimezone })
                  return footfall
                    ? (
                      <HoverPopup key={`minute-${time.minutes}`}>
                        <HoverPopupContent position='top' autoSize>
                          {t(FOOTFALL_STATUS[footfall])}
                        </HoverPopupContent>
                        <HoverPopupTrigger>
                          <div
                            key={`minute-${time.minutes}`}
                            className={slotClassNames.join(' ')}
                            onClick={() => onSlotClick(slot)}
                          >
                            {footfall && <div className='ta-booking-form__footfall-icon' style={{ backgroundColor: FOOTFALL_COLORS[footfall] }} />}
                            {formattedTime}
                          </div>
                        </HoverPopupTrigger>
                      </HoverPopup>
                    )
                    : (
                      <div
                        key={`minute-${time.minutes}`}
                        className={slotClassNames.join(' ')}
                        onClick={() => onSlotClick(slot)}
                      >
                        {formattedTime}
                      </div>
                    )
                })}
                {(times.length === 0 &&
                  <div className='ta-booking-form__widget-availability__day__slots__no-results'>
                    {t('calendar.form.widgetBooking.noAvailableSlots.text')}
                  </div>
                )}
              </div>
            </div>
          )
        })}
        {!pending && !hasSlots && nextAvailableDays.length === 0 && (
          <div className='ta-booking-form__widget-availability__day__slots__no-results'>
            {t('calendar.form.widgetBooking.noAvailableSlots.text')}
          </div>
        )}
        {!pending && !hasSlots && nextAvailableDays.length > 0 && (
          <>
            <Alert theme='alert'>
              {t('calendar.form.widgetBooking.noAvailableSlots.noWeekSlots.text')}
            </Alert>
            {nextAvailableDays.map(item => (
              <div
                key={`availableSlot-${item.cw}`}
                onClick={() => onClickNextAvailableDay(item.days[0].date)}
                className='ta-booking-form__widget-availability__next-available-day'
              >
                {t('global.cw')}{item.cw}, {format(item.days[0].date, 'long', { isUTC: true })} - {format(item.days[6].date, 'long', { isUTC: true })}
              </div>
            ))}
          </>
        )}
        {values.length > 0 && (
          <Row>
            <Col>
              <Title label={t('calendar.form.widgetBooking.selectedSlots.title')} isCompact />
              {values.map(item => (
                <div key={`selected-${item}`} className='ta-booking-form__widget-availability__day__slots__selected'>
                  {format(item, 'longWithShortWeekDay', { isUTC: true })} {format(item, 'time', { isUTC: true, format: 'YYYY-MM-DD HH:mm' })}
                  <div
                    className='ta-booking-form__widget-availability__day__slots__selected__clear'
                    onClick={() => onSlotClick(item)}
                  >
                    <FontAwesome5 icon='times' />
                  </div>
                </div>
              ))}
              {(isOverlapFootfall(values, duration, footfallMapping, { companyTimezone }) &&
                <Alert theme='alert'>
                  {t('calendar.form.widgetBooking.footfallWarning')}
                </Alert>
              )}
            </Col>
          </Row>
        )}
        {values && values.length > 0 && (
          <Button primary block onClick={onSlotSubmit}>{t('scheduleBooking.bookingslot.button.label')}</Button>
        )}
      </div>
    )
  }
}

export default BookingFormSlot
