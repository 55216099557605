import React from 'react'
import PropTypes from 'prop-types'
import { AccountsItemMap } from '../../Beauties'
import { FontAwesome5, t } from '../../Common'
import { dangerousHTML } from '../../Utils'

const OAuthAccountsItem = props => {
  const { account, onClickGrantAccess } = props
  const { company, oauthValidation } = account || {}
  const { id, name, address } = company || {}
  const { formatted } = address || {}
  const { isAppInstallAllowed } = oauthValidation || {}
  const classNames = ['ta-oauth__account-item']
  if (!formatted) classNames.push('no-address')
  if (!isAppInstallAllowed) classNames.push('auth-not-available')
  const buttonClassNames = ['ta-oauth__account-item__btn', 'ta-btn']
  buttonClassNames.push(isAppInstallAllowed ? 'ta-btn-primary' : 'ta-btn-secondary disabled')
  const onClick = id => {
    if (!isAppInstallAllowed) return
    onClickGrantAccess && onClickGrantAccess(id)
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-oauth__account-item__map'>
        <AccountsItemMap
          name={name}
          address={formatted}
        />
      </div>
      <div className='ta-oauth__account-item__name'>{name}</div>
      <div className='ta-oauth__account-item__address'>{formatted}</div>
      {(!isAppInstallAllowed &&
        <div className='ta-oauth__account-item__warning'>
          <FontAwesome5 icon='shield-alt' />
          {dangerousHTML(t('oauth.accounts.noAccess.note'))}
        </div>
      )}
      <div className={buttonClassNames.join(' ')} onClick={() => onClick(id)}>
        {t('global.authorize')}
      </div>
    </div>
  )
}

OAuthAccountsItem.propTypes = {
  account: PropTypes.object.isRequired
}

export default OAuthAccountsItem
