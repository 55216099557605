import React from 'react'
import { connect, handlers, selectors } from '../../Store'
import { SectionTitle, Link, t } from '../../Common'

const FormDiscardPopup = props => {
  let { isActive } = props
  isActive = !!isActive
  const classNames = ['ta-form__discard-popup']
  if (isActive) classNames.push('active')

  const onClickNo = () => {
    handlers.formDiscardPopupReset()
  }

  const onClickYes = () => {
    let { form, redirectUrl, onDiscard } = props
    form = form || null
    redirectUrl = redirectUrl || null
    handlers.formDiscardPopupReset(form)
    redirectUrl && handlers.navigateToPath(redirectUrl)
    onDiscard && onDiscard()
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='ta-form__discard-popup__overlay' />
      <div className='ta-form__discard-popup__content'>
        <SectionTitle label={t('global.discardChangesPopUp.title')} icon='save' ignoreTopOffset />
        <Link className='ta-btn ta-btn-secondary' onClick={onClickNo}>{t('buttons.cancel.label')}</Link>
        <Link className='ta-btn ta-btn-primary' onClick={onClickYes}>{t('global.discard')}</Link>
      </div>
    </div>
  )
}

const maps = state => ({
  isActive: selectors.formSelector(state, { formName: 'isDiscardPopupActive' }),
  redirectUrl: selectors.formSelector(state, { formName: 'discardRedirectUrl' }),
  form: selectors.formSelector(state, { formName: 'discardForm' }),
  onDiscard: state.forms.discardOnDiscard
})

export default connect(maps)(FormDiscardPopup)
