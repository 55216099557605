import React from 'react'
import PropTypes from 'prop-types'
import { handlers } from '../../../Store'

import './SimpleTabs.css'

const SimpleTabs = props => {
  const { multiple, children, scrollableContent, active } = props

  const onChange = (name) => {
    !multiple && handlers.addHashToCurrentRoute(name)
  }

  const classNames = ['ta-simple-tabs']
  if (scrollableContent) classNames.push('scrollable-content')
  const context = {
    active,
    onChange
  }

  return (
    <SimpleTabsContext.Provider value={context}>
      <div className={classNames.join(' ')} data-testid='simple-tabs'>
        {children}
      </div>
    </SimpleTabsContext.Provider>
  )
}

SimpleTabs.propTypes = {
  active: PropTypes.string,
  multiple: PropTypes.bool
}

export const SimpleTabsContext = React.createContext({
  active: false,
  onChange: () => {}
})

export default SimpleTabs
