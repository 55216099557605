import {
  createSelector,
  groupCustomerFieldsByCategory
} from '../../../Utils'

export const customerFieldsListSelector = state => state.customerFields.list || []

export const customerFieldsCategoriesSelector = state => state.customerFields.categoriesList || []

export const customerFieldsReorderListSelector = state => state.customerFields.reorderList

export const customerFieldsFilteredSelector = createSelector(
  customerFieldsListSelector,
  fields => fields.filter(item => item.hasOverwrite && item.isActive)
)

export const customerFieldsGroupedSelector = createSelector(
  customerFieldsFilteredSelector,
  customerFieldsCategoriesSelector,
  (filteredFields, categories) => groupCustomerFieldsByCategory(filteredFields || [], categories)
)

export const customerFieldsAvailableSelector = createSelector(
  customerFieldsFilteredSelector,
  filteredFields => (filteredFields || [])
    .filter(item => item.defaultId && item.isActive)
    .map(item => item.defaultId)
)

export const customerFieldsDefaultCategorySelector = createSelector(
  customerFieldsCategoriesSelector,
  (categories) => (categories || []).find(item => item.isDefault)
)

export const customerFieldsOptionsTransform = createSelector(
  customerFieldsListSelector,
  customerFieldsCategoriesSelector,
  (list, categoriesList) => categoriesList.map(({ translationKey, id }) => ({
    name: translationKey,
    items: list
      .filter(({ categoryId }) => categoryId === id)
      .map(({ id, translationKey }) => ({ value: id, label: translationKey }))
  }))
)

export const customerFieldsFieldSelector = createSelector(
  state => state.customerFields,
  (_, props) => props.field,
  (customerFields, field) => (customerFields || {})[field]
)
