import React from 'react'
import {
  BookingCustomerLinkWrapper,
  BookingCustomerLinkHeaderWrapper,
  BookingServiceAdhocWrapper,
  BookingServiceAdhocHeaderWrapper,
  BookingLocationWrapper,
  BookingLocationHeaderWrapper,
  BookingDataWrapper,
  BookingSlotPreferencesWrapper,
  BookingSlotPreferencesHeaderWrapper,
  BookingSlotWrapper,
  BookingSlotHeaderWrapper,
  BookingSuccessWrapper
} from '../../../Beasts'
import { BookingFormSection } from '../../../Beauties'
import {
  Title,
  Form,
  FormSection,
  Row,
  Error,
  Col,
  t,
  Blur,
  Loader
} from '../../../Common'
import './BookingForm.css'

const BookingRescheduleForm = ({
  customerStore,
  onSubmit,
  showHeader,
  setAccordionRef,
  bookingsReschedule,
  customersRead,
  customersWrite,
  selectedBookingForRescheduling,
  branches,
  pendingTransformation,
  ...props
}) => {
  const { progress, bookingCustomer, isCustomerEdit } = customerStore || {}

  return (
    <Blur extraClassName={bookingsReschedule ? 'inactive' : ''} active={!bookingsReschedule} icon='ban' label={t('global.accessDenied')}>
      <div className='ta-booking__title'>
        <Title label={t('booking.rescheduleBooking.header')} />
      </div>
      <Loader active={progress === 1 && pendingTransformation} />
      {progress >= 6
        ? (
          <div className='ta-booking'>
            <BookingSuccessWrapper isRescheduleForm />
          </div>
          )
        : (
          <div className='ta-booking'>
            {progress >= 0 && (
              <BookingFormSection
                title={t('customerFields.category.basic')}
                className='ta-booking__high-zindex-section'
                header={showHeader && (
                  <BookingCustomerLinkHeaderWrapper
                    key='customer-link-header'
                    noHover
                    stacked
                    customer={bookingCustomer}
                    noListButtons
                    selectedBookings={[selectedBookingForRescheduling]}
                    branches={branches}
                  />
                )}
                index={0}
                setAccordionRef={setAccordionRef}
                accordionProps={{
                  hideAccordionToggle: true
                }}
              >
                <BookingCustomerLinkWrapper key='customer-link' />
              </BookingFormSection>
            )}
            {!isCustomerEdit && (
              <Form
                name='booking'
                onSubmit={(e) => onSubmit()}
                scrollWrapper='ta-booking-form__scrollable-content'
              >
                {progress >= 1 && (
                  <BookingFormSection
                    title={t('booking.serviceAdhoc.header')}
                    header={progress > 1 && <BookingServiceAdhocHeaderWrapper key='service-adhoc-header' />}
                    setAccordionRef={setAccordionRef}
                    index={1}
                  >
                    <BookingServiceAdhocWrapper key='service-adhoc' isRescheduleForm />
                  </BookingFormSection>
                )}
                {progress >= 2 && (
                  <BookingFormSection
                    title={t('booking.location.label')}
                    header={progress > 2 && <BookingLocationHeaderWrapper key='location-header' />}
                    setAccordionRef={setAccordionRef}
                    index={2}
                  >
                    <BookingLocationWrapper key='location' isRescheduleForm />
                  </BookingFormSection>
                )}
                {progress >= 3 && (
                  <BookingFormSection
                    title={t('booking.slotPreferences.label')}
                    className='ta-booking__high-zindex-section'
                    header={progress > 3 && <BookingSlotPreferencesHeaderWrapper key='slot-preferences-header' />}
                    setAccordionRef={setAccordionRef}
                    index={3}
                  >
                    <BookingSlotPreferencesWrapper key='slot-preferences' />
                  </BookingFormSection>
                )}
                {progress >= 4 && (
                  <BookingFormSection
                    title={t('booking.bookingSlot.label')}
                    header={progress > 4 && <BookingSlotHeaderWrapper />}
                    setAccordionRef={setAccordionRef}
                    index={4}
                  >
                    <BookingSlotWrapper key='book-slot' isRescheduleForm />
                  </BookingFormSection>
                )}
                {progress >= 5 && (
                  <BookingFormSection
                    title={t('scheduleBooking.bookingData.title')}
                    setAccordionRef={setAccordionRef}
                    index={5}
                  >
                    <BookingDataWrapper key='booking-data' />
                  </BookingFormSection>
                )}
                <FormSection className='ta-booking-form-section_error'>
                  <Row>
                    <Col>
                      <Error name='globalErrors' noTopOffset />
                    </Col>
                  </Row>
                </FormSection>
              </Form>
            )}
          </div>
          )}
    </Blur>
  )
}

export default BookingRescheduleForm
