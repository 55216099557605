import { q } from '../../API'
import { payloads$, actions, handlers, globalActions, store } from '../../../Store'
import { settingsErrorsTransform, settingsFormSaveTransform, settingsFormTransform, settingsPopulateTransform } from './utils'

payloads$(actions.SETTINGS_FORM_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { account } = state || {}
    const { enterprise } = account || {}
    const { callCentreSettings: settings } = enterprise || {}

    await globalActions.populateCustomerFieldsAndCategories()
    handlers.accountCallCentreSettingsPopulate({ ...settingsPopulateTransform(settings), isFetched: true })
    handlers.formSet('settings', settingsFormTransform(settings))
  })

payloads$(actions.SETTINGS_FORM_SAVE)
  .subscribe(async (settings) => {
    if (!settings) return

    const result = await q('saveEnterpriseCallCentreSettings', { settings: settingsFormSaveTransform(settings) })
    const { error } = result || { error: { text: 'errors.api.unavailable' } }

    if (error) {
      handlers.settingsMessageSet(settingsErrorsTransform(error))
      return
    }

    handlers.accountCallCentreSettingsPopulate({ ...settingsPopulateTransform(result), isFetched: true })
    handlers.navigateToPath('/settings')
  })
