import {
  createSelector
} from '../../../Utils'

export const bookingsSelector = state => state.bookings

export const bookingsEventListSelector = state => state.bookings.eventsList

export const bookingsIntervalListSelector = state => state.bookings.intervalsList

export const bookingsOptionsSelector = state => state.bookings.options

export const bookingsHoverSelector = state => state.bookings.hover || {}

export const bookingsFieldSelector = createSelector(
  bookingsSelector,
  (_, props) => props.field,
  (bookings, field) => (bookings || {})[field]
)

export const bookingsEventByIdSelector = createSelector(
  bookingsEventListSelector,
  (_, props) => props.id,
  (events, id) => (events || []).find(item => item.id === id)
)

export const bookingsHoveredBookingSelector = createSelector(
  bookingsHoverSelector,
  bookingsIntervalListSelector,
  (hover, bookings) => (bookings || []).find(item => item.bookingId === hover.bookingId && item.day === hover.date)
)

export const bookingsHoveredBookingEventSelector = createSelector(
  bookingsHoveredBookingSelector,
  bookingsEventListSelector,
  (booking, events) => booking ? (events || []).find(item => item.id === booking.eventId) : null
)

export const bookingsHoveredEventBookingsSelector = createSelector(
  bookingsHoveredBookingEventSelector,
  bookingsIntervalListSelector,
  (event, bookings) => event ? (bookings || []).filter(item => item.eventId === event.id) : null
)
