import momenttz from 'moment-timezone'
import { getFirstDayOfWeek, sortWeekDays } from '../Utils'

export default ({ date, days }) => {
  const dates = []
  const firstDayOfWeek = getFirstDayOfWeek()
  let currentDayOfWeek = momenttz.utc(date, 'YYYY-MM-DD').day()
  if (currentDayOfWeek === 0) currentDayOfWeek = firstDayOfWeek * 7

  for (let i = firstDayOfWeek; i <= 6 + firstDayOfWeek; i++) {
    dates.push(momenttz.utc(date, 'YYYY-MM-DD').add(i - currentDayOfWeek, 'days').format('YYYY-MM-DD'))
  }

  // Filter only selected days from calendar settings
  // sortWeekDays(days) shift Sunday to be last in the list with index 7 instead of 1
  return sortWeekDays(days).map(key => {
    const index = firstDayOfWeek === 1 ? key - 1 : key
    return { date: dates[index], index }
  })
}
