import React, { Component } from 'react'
import moment from 'moment'
import { connect, handlers, selectors } from '../../../Store'
import { feedContextInProps } from '../../../Utils'
import {
  NUMBERS_VALIDATION_REGEX,
  FIVE_MINUTES_INTERVAL_TIMES,
  HOURS,
  DEFAULT_SERVICE_HEXCOLOR
} from '../../../Settings'
import {
  FormContext,
  FontAwesome5,
  StripedTitle,
  FormGroup,
  Row,
  Col,
  Error,
  Select,
  HoverPopup,
  HoverPopupContent,
  HoverPopupTrigger,
  t
} from '../../../Common'

import './BookingIntervalsInput.css'

class BookingIntervalsInput extends Component {
  constructor (props, context) {
    super(props)

    this.onDaysChange = this.onDaysChange.bind(this)
    this.onHoursChange = this.onHoursChange.bind(this)
    this.onMinutesChange = this.onMinutesChange.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.addInterval = this.addInterval.bind(this)
    this.deleteInterval = this.deleteInterval.bind(this)
    this.renderArrows = this.renderArrows.bind(this)
    this.state = {
      focusedIndex: null,
      focusedField: null,
      days: {},
      hours: {},
      minutes: {}
    }
  }

  componentDidMount () {
    const { addRef } = this.props
    addRef && addRef(this)
  }

  componentWillUnmount () {
    const { removeRef } = this.props
    removeRef && removeRef(this)
  }

  onFocus (index) {
    this.setState({ focusedIndex: index })
  }

  onBlur () {
    this.setState({ focusedIndex: null })
  }

  onDaysChange (index) {
    const { days, hours, minutes } = this.state
    let { name, formName, form } = this.props
    form = form || { values: [] }
    const value = this[`daysRef${index}`].value || ''
    const numbersRegex = new RegExp(NUMBERS_VALIDATION_REGEX)
    const newDays = value && numbersRegex.test(value) ? value : ''
    const stateDays = { ...days }
    stateDays[index] = newDays
    this.setState({ days: stateDays })
    const valueDuration = moment.duration(form.values[index] || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    const valueHours = form.value && valueDuration.hours()
    const valueMinutes = form.value && valueDuration.minutes()
    const duration =
      (parseInt(!stateDays[index] ? valueDays || 0 : stateDays[index] || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(!hours[index] ? valueHours || 0 : hours[index] || 0, 10) * 60) + // hours in minutes
      (parseInt(!minutes[index] ? valueMinutes || 0 : minutes[index] || 0, 10)) // minutes
    const values = [...form.values]
    values[index] = duration
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  onHoursChange (isGap, index, value, valueMins, lowerHourMinsLimit, upperLimitInMins, lowerLimitInMins) {
    const valueFromForm = value ? value.value : 0
    const minsAfterHourChange = valueFromForm * 60 + valueMins
    const { hours } = this.state
    let { name, formName, form, shouldEnforceServiceDurationLimit } = this.props
    form = form || { values: [] }
    const newHours = valueFromForm
    const stateHours = { ...hours }
    stateHours[index] = newHours.toString()
    this.setState({ hours: stateHours })
    const valueDuration = moment.duration(form.values[index] || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    let valueMinutes = valueMins
    if (!isGap && shouldEnforceServiceDurationLimit && minsAfterHourChange > upperLimitInMins) valueMinutes = 0
    if (!isGap && shouldEnforceServiceDurationLimit && minsAfterHourChange < lowerLimitInMins) valueMinutes = lowerHourMinsLimit
    const duration =
      (parseInt(valueDays || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(newHours, 10) * 60) + // hours in minutes
      (parseInt(valueMinutes || 0, 10)) // minutes
    const values = [...form.values]
    values[index] = duration
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  onMinutesChange (index, value, valueHours) {
    const { days, minutes } = this.state
    let { name, formName, form } = this.props
    form = form || { values: [] }
    const newMinutes = value ? value.value : 0
    const stateMinutes = { ...minutes }
    stateMinutes[index] = newMinutes.toString()
    this.setState({ minutes: stateMinutes })
    const valueDuration = moment.duration(form.values[index] || 0, 'minutes')
    const valueDays = form.value && parseInt(valueDuration.asDays(), 10)
    const duration =
      (parseInt(!days[index] ? valueDays || 0 : days[index] || 0, 10) * 24 * 60) + // days in minutes
      (parseInt(valueHours || 0, 10) * 60) + // hours in minutes
      parseInt(stateMinutes[index], 10) // minutes
    const values = [...form.values]
    values[index] = duration
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  addInterval () {
    let { name, formName, form } = this.props
    form = form || { values: [] }
    const values = [...form.values, 0, 0]
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  deleteInterval (index) {
    let { name, formName, form } = this.props
    form = form || { values: [] }
    const gapIndex = index
    const intervalIndex = gapIndex + 1
    const values = form.values.filter((item, index) => (index !== gapIndex && index !== intervalIndex))
    handlers.formFieldsUpdate(formName, { [name]: { ...form, values } })
  }

  renderArrows () {
    return (
      <FontAwesome5 icon='sort' type='solid' />
    )
  }

  render () {
    const {
      focusedIndex,
      focusedField,
      days,
      hours,
      minutes
    } = this.state
    let {
      color,
      limit,
      className,
      noDays,
      form,
      name,
      disabled,
      shouldEnforceServiceDurationLimit,
      enforceServiceDurationLimit,
      service
    } = this.props
    limit = limit || 5
    form = form || { values: [] }
    color = color || DEFAULT_SERVICE_HEXCOLOR
    const lastIndex = (limit * 2) - 1
    const classNames = ['ta-booking-intervals-input']
    if (className) classNames.push(className)

    return (
      <div className={classNames.join(' ')}>
        {form.values.map((value, index) => {
          const isInterval = index % 2 === 0
          const isGap = !isInterval
          const isFirst = index === 0
          const isLast = index === form.values.length - 1
          const hasDelete = isGap && index > 2 && !disabled
          const { durationsPattern = [] } = service || {}
          const initialIntervalDuration = durationsPattern[index]
          const duration = moment.duration(initialIntervalDuration || 0, 'minutes')
          const selectedDuration = moment.duration(value || 0, 'minutes')
          const selectedHour = moment.duration(value, 'minutes').hours()
          const valueDays = initialIntervalDuration && parseInt(duration.asDays(), 10)
          const valueHours = initialIntervalDuration && duration.hours()
          const valueMinutes = initialIntervalDuration
          const selectedValueDays = value && parseInt(selectedDuration.asDays(), 10)
          const selectedValueHours = value && selectedDuration.hours()
          const selectedValueMinutes = value && selectedDuration.minutes()
          const upperLimitInMins = valueMinutes + enforceServiceDurationLimit
          const lowerLimitInMins = valueMinutes - enforceServiceDurationLimit
          const lowerHourLimit = parseInt((valueMinutes - enforceServiceDurationLimit) / 60) + 0
          const upperHourLimit = parseInt((valueMinutes + enforceServiceDurationLimit) / 60)
          const valueHoursString = selectedValueHours.toString()
          const valueMinutesString = selectedValueMinutes.toString()
          const limitsAreInSameHour = (initialIntervalDuration % 60 + enforceServiceDurationLimit) < 60 && (initialIntervalDuration % 60 - enforceServiceDurationLimit) >= 0
          const upperHourMinsLimit = (valueMinutes + enforceServiceDurationLimit) % 60
          const lowerHourMinsLimit = (valueMinutes - enforceServiceDurationLimit) % 60 >= 0
            ? (valueMinutes - enforceServiceDurationLimit) % 60
            : valueHours === 0
              ? 0
              : 60 + (valueMinutes - enforceServiceDurationLimit % 60)
          const decideForSameHour = t => {
            return limitsAreInSameHour
              ? (upperHourMinsLimit >= t && t >= lowerHourMinsLimit)
              : selectedValueMinutes === 0 && (upperHourLimit === lowerHourLimit)
                ? t <= upperHourMinsLimit
                : upperHourLimit > selectedValueHours && selectedValueHours > lowerHourLimit
                  ? t < 60
                  : upperHourLimit === selectedValueHours
                    ? t <= upperHourMinsLimit
                    : (t >= lowerHourMinsLimit && t < 60)
          }
          const decideForDifferentHour = t => {
            return selectedHour > valueHours
              ? t <= upperHourMinsLimit
              : (t >= lowerHourMinsLimit && t < 60)
          }
          const minutesOptions = FIVE_MINUTES_INTERVAL_TIMES.filter(t => {
            return !shouldEnforceServiceDurationLimit || isGap
              ? t < 60
              : valueHours === selectedHour
                ? decideForSameHour(t)
                : decideForDifferentHour(t)
          }).map(time => ({
            label: time,
            value: time
          }))
          const hoursOptions = HOURS.filter(t => !shouldEnforceServiceDurationLimit || isGap
            ? t > -1
            : limitsAreInSameHour
              ? t === selectedValueHours
              : t <= upperHourLimit && t >= lowerHourLimit).map(hour => ({
            label: hour,
            value: hour
          }))
          const intervalClassName = [`ta-booking-intervals-input__${isInterval ? 'interval' : 'gap'}`]
          if (isFirst) intervalClassName.push('first')
          if (isLast) intervalClassName.push('last')
          if (hasDelete) intervalClassName.push('hasDelete')
          const styles = { background: color }
          const beforeStyles = { background: `linear-gradient(-45deg, ${color} 3px,  transparent 0), linear-gradient(45deg, ${color} 3px, transparent 0)` }
          const afterStyles = { background: `linear-gradient(-225deg, ${color} 3px,  transparent 0), linear-gradient(225deg, ${color} 3px, transparent 0)` }
          return (
            <div key={index} className={intervalClassName.join(' ')}>
              <StripedTitle label={t(isInterval ? 'global.interval' : 'global.gap')} />
              {hasDelete &&
                <div className={`ta-booking-intervals-input__delete-section ${shouldEnforceServiceDurationLimit ? 'disabled' : ''}`}>
                  <HoverPopup className='ta-booking-intervals-input__btn-delete--hover' disabled={!shouldEnforceServiceDurationLimit}>
                    <HoverPopupContent position='left'>
                      {t('bookings.form.duration.btnDisabled')}
                    </HoverPopupContent>
                    <HoverPopupTrigger>
                      <div className={`ta-booking-intervals-input__btn-delete ${shouldEnforceServiceDurationLimit ? 'disabled' : ''}`} onClick={shouldEnforceServiceDurationLimit ? null : () => this.deleteInterval(index)}>
                        <FontAwesome5 icon='trash' type='solid' />
                      </div>
                    </HoverPopupTrigger>
                  </HoverPopup>
                </div>
              }
              {isInterval &&
                <div className='ta-booking-intervals-input__box' style={styles}>
                  {!isFirst &&
                    <div className='ta-booking-intervals-input__box__before' style={beforeStyles} />
                  }
                  {!isLast &&
                    <div className='ta-booking-intervals-input__box__after' style={afterStyles} />
                  }
                </div>
              }
              <Row>
                {(!noDays || (value || 0) > 1440) &&
                  <Col size={30}>
                    <FormGroup
                      focused={focusedField === 'days' && focusedIndex === index}
                      filled={!!days[index] || !!valueDays}
                      labelText={t('global.days.label')}
                      labelMandatory
                    >
                      <input
                        className='ta-form-control'
                        type='text'
                        ref={daysRef => { this[`daysRef${index}`] = daysRef }}
                        value={selectedValueDays || ''}
                        onChange={() => this.onDaysChange(index)}
                        onFocus={() => this.onFocus(index, 'days')}
                        onBlur={this.onBlur}
                        placeholder={t('global.days.placeholder')}
                        autoComplete='off'
                        disabled={disabled || (shouldEnforceServiceDurationLimit && !isGap)}
                      />
                    </FormGroup>
                  </Col>
                }
                <Col size={30}>
                  <FormGroup
                    focused={focusedField === 'hours' && focusedIndex === index}
                    filled={!!hours[index] || !!valueHoursString}
                    labelText={t('global.hours.label')}
                    labelMandatory
                  >
                    <Select
                      className='ta-single-select'
                      noResultsText={t('global.noResults')}
                      value={valueHoursString || ''}
                      arrowRenderer={this.renderArrows}
                      onFocus={() => this.onFocus(index, 'hours')}
                      onBlur={this.onBlur}
                      onChange={(selectValue) => this.onHoursChange(isGap, index, selectValue, selectedValueMinutes, lowerHourMinsLimit, upperLimitInMins, lowerLimitInMins)}
                      options={hoursOptions}
                      searchable
                      placeholder={t('global.hours.placeholder')}
                      autoComplete='off'
                      disabled={disabled}
                    />
                  </FormGroup>
                </Col>
                <Col size={30}>
                  <FormGroup
                    focused={focusedField === 'minutes' && focusedIndex === index}
                    filled={!!minutes[index] || !!valueMinutesString}
                    labelText={t('global.minutes.label')}
                    labelMandatory
                  >
                    <Select
                      className='ta-single-select'
                      noResultsText={t('global.noResults')}
                      value={valueMinutesString || ''}
                      arrowRenderer={this.renderArrows}
                      onFocus={() => this.onFocus(index, 'minutes')}
                      onBlur={this.onBlur}
                      onChange={(selectValue) => this.onMinutesChange(index, selectValue, selectedValueHours)}
                      options={minutesOptions}
                      searchable
                      placeholder={t('global.minutes.placeholder')}
                      autoComplete='off'
                      disabled={disabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )
        })}
        {form.values.length < lastIndex && !disabled && (
          <HoverPopup className='ta-booking-intervals-add__popup' disabled={!shouldEnforceServiceDurationLimit}>
            <HoverPopupContent position='top' className='ta-booking-intervals-add__popup--text' >
              {t('bookings.form.duration.btnDisabled')}
            </HoverPopupContent>
            <HoverPopupTrigger>
              <div
                className={`ta-btn ta-btn-secondary ta-btn-block ta-booking-intervals-input__btn-add ${shouldEnforceServiceDurationLimit ? 'disabled' : ''}`}
                onClick={shouldEnforceServiceDurationLimit ? null : this.addInterval}
              >
                <FontAwesome5 icon='plus' type='regular' />
                {t('global.addInterval')}
              </div>
            </HoverPopupTrigger>
          </HoverPopup>
        )}
        <Error name={name} />
      </div>
    )
  }
}

const maps = (state, props) => ({
  form: selectors.formFieldSelector(state, { name: props.name, formName: props.formName })
})

export default feedContextInProps(connect(maps)(BookingIntervalsInput), FormContext)
