import moment from 'moment'
import { payloads$, actions, handlers, languageChange, store$ } from '../../../Store'
import { q } from '../../API'
import { fromEvent } from 'rxjs'
import { startWith, debounceTime, filter, distinctUntilChanged } from 'rxjs/operators'
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from '../../../Settings'
import { registerLocale } from 'react-datepicker'
import { formatDatePickerLocale } from '../../../Utils'

// Favicon
const favicon = document.getElementById('favicon')
let faviconUrl = null
if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) faviconUrl = '/favicon-stg.png'
if (favicon && faviconUrl) {
  favicon.href = faviconUrl
}

// First load
let isLoaded = false
store$
  .pipe(
    filter(state => state.app && !state.app.isReady),
    distinctUntilChanged()
  )
  .subscribe(async (state) => {
    handlers.appReady()
    if (isLoaded) return
    handlers.phraseLoad()
    // Interrupt default flow
    if (state.router.name === 'oAuth') return

    const {
      getAllCountries,
      getAllLocales,
      getAllTimezones
    } = await q('getStaticData') || {}

    handlers.staticDataPopulate({
      countries: getAllCountries || [],
      locales: getAllLocales || [],
      timezones: getAllTimezones || []
    })

    // Refetch account on first load after refresh
    if (state.auth.tokens && !state.router.data.token) {
      isLoaded = true
      const account = await q('getAccount')
      handlers.accountChanged({ account })
    }

    const hideNavigation = state.router.data['hide-navigation']
    hideNavigation && handlers.navigationPositionSet('collapsed')
  })

// Resize

fromEvent(window, 'resize')
  .pipe(
    startWith(null),
    debounceTime(100)
  )
  .subscribe(() => handlers.windowResize(window))

// Labguage change

payloads$(actions.LANGUAGE_CHANGE)
  .subscribe(locale => {
    locale = !SUPPORTED_LOCALES.includes(locale) ? DEFAULT_LOCALE : locale
    languageChange(locale, () => handlers.languageLoaded(locale))
    moment.locale(locale)
    // Register date picker locales
    // Temp workaround until react-datepicker fixes this issue
    // https://github.com/Hacker0x01/react-datepicker/issues/1871
    const formattedDatePickerLocale = formatDatePickerLocale(locale)
    const localeModule = require(`date-fns/locale/${formattedDatePickerLocale}`)
    registerLocale(locale, localeModule.default)
  })

// Internet connect

window.addEventListener('online', () => {
  handlers.connectionChange(true)
})

window.addEventListener('offline', () => {
  handlers.connectionChange(false)
})
