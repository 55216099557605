const popupDefaults = {
  reason: {
    value: 'noReason',
    options: [{
      label: 'I do not want to state a reason',
      value: 'noReason'
    }, {
      label: 'It\'s too complicated for me',
      value: 'tooComplicated'
    }, {
      label: 'I\'m missing features',
      value: 'missingFeatures'
    }, {
      label: 'One or more features are not working for me',
      value: 'featuresNotWorkingForMe'
    }, {
      label: 'It doesn\'t work for my business needs',
      value: 'notWorkingForMyBusiness'
    }, {
      label: 'I don\'t need online scheduling and a shared team calendar anymore',
      value: 'notNeededAnymore'
    }, {
      label: 'I found a better tool',
      value: 'foundBetterTool'
    }, {
      label: 'Other',
      value: 'other'
    }]
  },
  password: {
    value: '',
    type: 'password'
  },
  comment: {
    value: ''
  },
  hasNotifyMe: {
    value: false
  },
  deleteInterval: {
    value: 'all'
  },
  deleteRecurring: {
    value: 'all'
  },
  deleteChained: {
    value: 'all'
  }
}

export const popupFormTransform = form => {
  if (!form) return popupDefaults
  const result = {
    reason: form.reason || popupDefaults.reason,
    password: form.password || popupDefaults.password,
    comment: form.comment || popupDefaults.comment,
    hasNotifyMe: form.hasNotifyMe || popupDefaults.hasNotifyMe
  }
  return result
}
