import {
  serviceFields,
  serviceFieldsForList,
  serviceCategoryFields
} from './fields'

// Queries

export const getServicesAndCategories = `
  query getServicesAndCategories {
    getServices ${serviceFieldsForList}
    getServiceCategories ${serviceCategoryFields}
  }
`
export const getService = `
  query getService($id: ID!) {
    getService(id: $id) ${serviceFields}
  }
`
export const getServiceForList = `
  query getService($id: ID!) {
    getService(id: $id) ${serviceFieldsForList}
  }
`
export const getServices = `
  query getServices($filter: ServicesFilter!) {
    getServices(filter: $filter) ${serviceFields}
  }
`

export const getEnterpriseCallCentreBranchServices = `query getEnterpriseCallCentreBranchServices($companyId: ID!, $region: String!, $filter: ServicesFilter, $sort: ServicesSort, $pagination: Pagination) {
  getEnterpriseCallCentreBranchServices(companyId: $companyId, region: $region, filter: $filter, sort: $sort, pagination: $pagination) ${serviceFields}
}`

export const getServiceCategory = `
  query getServiceCategory($id: ID!) {
    getServiceCategory(id: $id) ${serviceCategoryFields}
  }
`

// Mutations

export const moveServicesAndCategories = `
  mutation moveServicesAndCategories($services: [MovePayload]!, $categories: [MovePayload]!) {
    moveServices(services: $services)
    moveServiceCategories(categories: $categories)
  }
`
export const saveService = `
  mutation saveService($service: ServicePayload!) {
    saveService(service: $service) ${serviceFields}
  }
`
export const deleteService = `
  mutation deleteService($id: ID!) {
    deleteService(id: $id)
  }
`
export const saveServiceCategory = `
  mutation saveServiceCategory($category: ServiceCategoryPayload!) {
    saveServiceCategory(category: $category) ${serviceCategoryFields}
  }
`
export const deleteServiceCategory = `
  mutation deleteServiceCategory($id: ID!) {
    deleteServiceCategory(id: $id)
  }
`
export const resetGlobalService = `
  mutation resetGlobalService($internalId: ID!) {
    resetGlobalService(internalId: $internalId) { internalId }
  }
`
export const resetGlobalServiceCategory = `
  mutation resetGlobalServiceCategory($internalId: ID!) {
    resetGlobalServiceCategory(internalId: $internalId) { internalId }
  }
`
