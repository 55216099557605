import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, format } from '../../Common'
import { t } from '../Translations/Translations'

import './UserPreview.css'

const UserPreview = props => {
  const { name, avatarUrl, color, children, abbreviation, lastAccountLoginAt } = props
  return (
    <div className='ta-user-preview'>
      <Avatar name={name} image={avatarUrl} color={color} abbreviation={abbreviation}>{children}</Avatar>
      <div className='ta-user-preview__name' data-testid='user-preview-name'>
        <div className='ta-user-preview__name_content'>{name}</div>
        <div>{t('global.agent')}</div>
        {lastAccountLoginAt && <div className='ta-user-preview__name_last_login'>{t('agents.preview.lastLogin')}: {format(lastAccountLoginAt, 'L')} </div>}
      </div>
    </div>
  )
}

UserPreview.propTypes = {
  name: PropTypes.string.isRequired
}

export default UserPreview
