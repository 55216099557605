import React, { PureComponent } from 'react'

import './Stepper.css'

class Stepper extends PureComponent {
  constructor (props, context) {
    super(props)

    this.getPrevAvailableStep = this.getPrevAvailableStep.bind(this)
    this.onClickPrev = this.onClickPrev.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.state = {
      isLoaded: false,
      prevStep: null,
      onStep: 1,
      activeStep: this.props.initialStep || 1,
      totalSteps: this.props.initialTotalSteps,
      skippedSteps: []
    }
  }

  componentDidMount () {
    this.timeout = setTimeout(() => this.setState({ isLoaded: true }), 1000)
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  getPrevAvailableStep (step) {
    const { skippedSteps } = this.state
    return skippedSteps.includes(step)
      ? this.getPrevAvailableStep(step - 1)
      : step
  }

  onClickPrev ({ step, totalSteps }) {
    const { activeStep, skippedSteps } = this.state
    if (!step && activeStep === 1) return

    this.setState(prevState => {
      const prevActiveStep = prevState.activeStep
      let nextSkippedSteps = skippedSteps
      let nextActiveStep = this.getPrevAvailableStep(prevActiveStep - 1)
      if (!step && prevActiveStep - nextActiveStep > 1) {
        const skipSteps = Array(prevActiveStep - nextActiveStep - 1).fill().map((item, index) => nextActiveStep + index + 1)
        nextSkippedSteps = skippedSteps.filter(item => !skipSteps.includes(item))
      }
      return {
        prevStep: prevActiveStep,
        onStep: prevState.onStep - 1 < 1 ? 1 : prevState.onStep - 1,
        activeStep: step || nextActiveStep,
        totalSteps: totalSteps || prevState.totalSteps,
        skippedSteps: nextSkippedSteps
      }
    })
  }

  onClickNext ({ step, totalSteps }) {
    const { activeStep } = this.state
    const { lastStep } = this.props
    if (!step && activeStep === lastStep) return

    this.setState(prevState => {
      const prevActiveStep = prevState.activeStep
      let skipSteps = []
      if ((step || activeStep) - prevActiveStep > 1) skipSteps = Array((step || activeStep) - prevActiveStep - 1).fill().map((item, index) => prevActiveStep + index + 1)
      return {
        prevStep: prevState.activeStep,
        onStep: prevState.onStep + 1 > totalSteps ? totalSteps : prevState.onStep + 1,
        activeStep: step || prevState.activeStep + 1,
        totalSteps: totalSteps || prevState.totalSteps,
        skippedSteps: prevState.skippedSteps.concat(skipSteps)
      }
    })
  }

  render () {
    const {
      isLoaded,
      activeStep,
      prevStep,
      totalSteps,
      onStep
    } = this.state
    const {
      children,
      lastStep,
      horizontal,
      vertical,
      fade
    } = this.props
    const context = {
      isLoading: !isLoaded,
      activeStep,
      prevStep,
      totalSteps,
      onStep,
      isLastStep: activeStep === lastStep,
      onClickNext: this.onClickNext,
      onClickPrev: this.onClickPrev
    }
    const classNames = ['ta-stepper']
    if (!isLoaded) classNames.push('loading')
    if (horizontal) classNames.push('horizontal')
    if (vertical) classNames.push('vertical')
    if (fade) classNames.push('fade')

    return (
      <StepperContext.Provider value={context}>
        <div className={classNames.join(' ')} data-testid='stepper'>
          {children}
        </div>
      </StepperContext.Provider>
    )
  }
}

export const StepperContext = React.createContext({
  isLoading: false,
  activeStep: 1,
  prevStep: null,
  isLastStep: 1,
  totalSteps: 1,
  onClickNext: () => {},
  onClickPrev: () => {}
})

export default Stepper
