import { payloads$, actions, store, handlers } from '../../../Store'
import { q } from '../../API'

// List
payloads$(actions.OAUTH_GET)
  .subscribe(async () => {
    handlers.oAuthReset()
    const state = store.getState()
    const { router, auth } = state || {}
    const { tokens } = auth || {}
    const { data: routerData } = router || {}
    const { client_id: oAuthClientId } = routerData || {}
    const result = await q('getAppPublicData', { id: oAuthClientId })
    const { error } = result || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.oAuthAccessDenied()
      return
    }
    handlers.oAuthPopulate({ app: result })
    tokens && handlers.oAuthAccountGet()
  })

// Get account
payloads$(actions.OAUTH_ACCOUNT_GET)
  .subscribe(async () => {
    const state = store.getState()
    const { router } = state || {}
    const { data: routerData } = router || {}
    const { client_id: oAuthClientId } = routerData || {}
    if (!oAuthClientId) return
    const account = await q('getAccountForOAuth', { appId: oAuthClientId })
    const { error } = account || { error: { text: 'errors.api.unavailable' } }
    if (error) {
      handlers.oAuthAccessDenied()
      return
    }
    const { accounts } = account || {}
    handlers.oAuthPopulate({ accounts })
    handlers.loginFormReady()
  })

// Access denied
payloads$(actions.OAUTH_ACCESS_GRANT)
  .subscribe(async id => {
    const state = store.getState()
    const { router } = state || {}
    const { data: routerData } = router || {}
    const {
      redirect_uri: oAuthRedirectUrl,
      client_id: oAuthClientId,
      response_type: oAuthResponseType,
      state: oAuthState
    } = routerData || {}
    const result = await q('getAppOAuthGrantCode', {
      accountId: id,
      appId: oAuthClientId,
      responseType: oAuthResponseType,
      redirectUri: oAuthRedirectUrl
    })
    const { error, code } = result || { error: {} }
    if (error) {
      handlers.oAuthAccessDenied(error)
      return
    }
    const params = [`code=${code}`]
    if (oAuthState) params.push(`state=${oAuthState}`)
    const url = `${oAuthRedirectUrl}?${params.join('&')}`
    window.location = url
  })

// Access denied
payloads$(actions.OAUTH_ACCESS_DENIED)
  .subscribe(async (error) => {
    const state = store.getState()
    const { router } = state || {}
    const { data: routerData } = router || {}
    const {
      redirect_uri: oAuthRedirectUrl,
      state: oAuthState
    } = routerData || {}
    const params = [`error=${error || 'access_denied'}`]
    if (oAuthState) params.push(`state=${oAuthState}`)
    const url = `${oAuthRedirectUrl}?${params.join('&')}`
    window.location = url
  })
