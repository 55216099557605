import React from 'react'
import { connect, selectors } from '../../../Store'
import { BookingFormServiceAdhocHeader } from '../../../Beauties'
import { hex2rgba } from '../../../Utils'
import { DEFAULT_SERVICE_HEXCOLOR } from '../../../Settings'

const BookingServiceAdhocHeaderWrapper = ({
  form,
  color,
  servicesList,
  selectedServiceId,
  transformedService,
  splitDurationInIntervals
}) => {
  servicesList = servicesList || []
  const bufferTimesStyles = { background: hex2rgba(color || DEFAULT_SERVICE_HEXCOLOR, 0.5) }
  const bufferTimesContentContentStyles = { background: hex2rgba(color || DEFAULT_SERVICE_HEXCOLOR, 1) }
  const service = servicesList.find(item => item.id === selectedServiceId) || {}

  return (
    <BookingFormServiceAdhocHeader
      form={form}
      bufferTimesStyles={bufferTimesStyles}
      bufferTimesContentContentStyles={bufferTimesContentContentStyles}
      service={service}
      transformedService={transformedService}
      splitDurationInIntervals={splitDurationInIntervals}
    />
  )
}

const maps = state => ({
  form: selectors.formSelector(state, { formName: 'booking' }),
  color: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'color', property: 'value' }),
  selectedServiceId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'selectedId' }),
  servicesList: selectors.servicesListSelector(state),
  transformedService: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'transformedService', property: 'value' }),
  splitDurationInIntervals: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'splitDurationInIntervals', property: 'value' })
})

export default connect(maps)(BookingServiceAdhocHeaderWrapper)
