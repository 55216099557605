import React from 'react'
import momenttz from 'moment-timezone'
import { connect, handlers, selectors } from '../../../Store'
import { BookingFormSlot } from '../../../Beauties'
import { getWeekDays } from '../../../Utils'

const dayMinutes = (day, firstDay) => {
  return momenttz
    .utc(day, 'YYYY-MM-DD')
    .diff(momenttz.utc(firstDay, 'YYYY-MM-DD'), 'minutes')
}

const generate5 = (minutes, { durationBefore, durationAfter, durationsPattern }) => {
  durationBefore = durationBefore || 0
  durationAfter = durationAfter || 0
  durationsPattern = durationsPattern || [0]
  const res = []
  const intervals = [-1, -1]
  let sum = 0
  let max = durationsPattern.reduce((acc, duration, index) => {
    sum += duration
    intervals.push(sum - 1)
    return acc + duration
  }, 0)
  intervals.push(max - 1)
  max += durationAfter
  intervals.push(max - 1)
  let i = -durationBefore
  while (i <= max) {
    const marginTop = i
    const isPause = !!(intervals.findIndex(minute => minute >= marginTop) % 2)
    if (!isPause) res.push(minutes + i)
    i += 5
  }
  return res
}

const slotColides = (minutes, slotMinutes, { durationBefore, durationAfter, durationsPattern }) => {
  const one = generate5(minutes, { durationBefore, durationAfter, durationsPattern })
  const two = generate5(slotMinutes, { durationBefore, durationAfter, durationsPattern })
  return one.some(o => two.includes(o))
}

const generateSlots = ({ slots, durationBefore, durationAfter, durationsPattern, selectedSlots, areMultipleBookingDisabled }) => {
  if (!slots[0] || !slots[0].day) return slots
  const firstDay = slots[0].day
  selectedSlots = selectedSlots.map(slot => {
    const slotDay = slot.substr(0, 10)
    const slotTime = slot.substr(11, 5)
    const slotMinutes = parseInt(slotTime.substr(0, 2)) * 60 + parseInt(slotTime.substr(3, 2)) || 0
    return dayMinutes(slotDay, firstDay) + slotMinutes
  })

  for (const { day, times } of slots) {
    for (const oneTime of times) {
      const { minutes } = oneTime
      oneTime.isDisabled = areMultipleBookingDisabled
        ? selectedSlots.length > 0
        : !!selectedSlots.some(slotMinutes => slotColides(dayMinutes(day, firstDay) + minutes, slotMinutes, { durationsPattern, durationBefore, durationAfter }))
    }
  }
  return slots
}

const BookingSlotWrapper = props => {
  let {
    pendingWidgetForm,
    slots,
    allowMultipleAppointmentsPerBooking,
    serviceId,
    isRescheduleForm,
    services,
    timezone,
    form,
    from,
    progress,
    servicesAllocation,
    companySettings,
    companyTimezone,
    isScheduleForm
  } = props

  slots = slots || {}
  services = services || []
  serviceId = serviceId || null
  form = form || {}
  companySettings = companySettings || {}
  const { duration, intervals } = form || {}
  const { value: durationValue } = duration || {}
  const { values: intervalsValues } = intervals || {}
  let { footfallMapping } = companySettings
  footfallMapping = footfallMapping || []
  pendingWidgetForm = !!pendingWidgetForm
  let { onDays, availableSlots } = slots
  availableSlots = availableSlots || []
  onDays = onDays || []
  const hasSlots = availableSlots.reduce((acc, item) => {
    if (item.times && item.times.length > 0) return true
    return acc
  }, false)
  const lastDay = availableSlots.length > 0 && availableSlots[availableSlots.length - 1].day
  let nextAvailableDays = onDays
    .filter(item => item > lastDay)
    .reduce((acc, item) => {
      if (Object.keys(acc).length === 3) return acc
      const cw = momenttz.tz(item, timezone).week()
      acc[cw] = {
        cw,
        days: getWeekDays({ date: item, days: [0, 1, 2, 3, 4, 5, 6] })
      }
      return acc
    }, {})
  nextAvailableDays = Object.keys(nextAvailableDays).map(item => nextAvailableDays[item])
  const onSlotClick = slot => {
    let { values, availableSlots } = slots || {}
    if (!availableSlots) return
    values = values || []
    if (values.includes(slot)) values = values.filter(item => item !== slot)
    else values.push(slot)
    const {
      splitDurationInIntervals,
      durationBefore,
      durationAfter,
      step
    } = form || {}
    const { value: stepValue } = step || {}
    const { value: durationBeforeValue } = durationBefore || {}
    const { value: durationAfterValue } = durationAfter || {}
    const { value: splitDurationInIntervalsValue } = splitDurationInIntervals || {}
    const durationsPattern = splitDurationInIntervalsValue
      ? intervalsValues
      : [durationValue]
    const selectedService = serviceId && services.find(item => item.id === serviceId)
    const { isCombination } = selectedService || {}

    handlers.formFieldsUpdate('booking', {
      availableSlots: generateSlots({
        slots: availableSlots,
        selectedSlots: values,
        durationBefore: durationBeforeValue,
        durationAfter: durationAfterValue,
        durationsPattern,
        areMultipleBookingDisabled: isRescheduleForm || !allowMultipleAppointmentsPerBooking || isCombination
      }),
      slots: { ...slots, availableSlots, values },
      step: { value: values.length === 0 ? 2 : stepValue }
    })
  }
  const onClickNextAvailableDay = date => {
    handlers.formFieldsUpdate('booking', { availabilityDate: { value: date } })
  }

  const onAvailabilityDateChange = () => {
    handlers.formFieldsUpdate('booking', {
      progress: { value: 4 },
      notes: { value: '' }
    })
  }

  const onSlotSubmit = () => {
    handlers.formFieldsUpdate('booking', {
      progress: { value: 4 }
    })
  }

  const updateAvailability = (from, today) => {
    handlers.formFieldsUpdate('booking', { availabilityDate: { value: from || today } })
    // setTimeout(() => handlers.bookingFormAvailabilityGet(), 0) // there's an observable for this field, which calls this handler
  }

  return (
    <BookingFormSlot
      updateAvailability={updateAvailability}
      slots={slots}
      from={from}
      progress={progress}
      pending={pendingWidgetForm}
      timezone={timezone}
      onSlotClick={onSlotClick}
      hasSlots={hasSlots}
      footfallMapping={footfallMapping}
      servicesAllocation={servicesAllocation}
      companyTimezone={companyTimezone}
      nextAvailableDays={nextAvailableDays}
      onClickNextAvailableDay={onClickNextAvailableDay}
      duration={durationValue || (intervalsValues && intervalsValues.reduce((sum, interval) => sum + interval, 0))}
      onAvailabilityDateChange={onAvailabilityDateChange}
      onSlotSubmit={onSlotSubmit}
    />
  )
}

const maps = state => ({
  pendingWidgetForm: state.bookings.pendingWidgetForm,
  slots: selectors.formFieldSelector(state, { formName: 'booking', name: 'slots' }),
  services: selectors.servicesListSelector(state),
  serviceId: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'service', property: 'selectedId' }),
  allowMultipleAppointmentsPerBooking: selectors.accountCallCentreSettingsPropertySelector(state, { property: 'allowMultipleAppointmentsPerBooking' }),
  timezone: state.branches.selected.timezone,
  from: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'from', property: 'value' }),
  form: state.forms.booking,
  progress: selectors.formFieldPropertySelector(state, { formName: 'booking', name: 'progress', property: 'value' }),
  companySettings: state.branches.selected.settings,
  companyTimezone: state.branches.selected.timezone,
  servicesAllocation: state.branches.selected.servicesAllocation
})

export default connect(maps)(BookingSlotWrapper)
