import React from 'react'
import { FontAwesome5, Input, t } from '../../Common'

const CustomersListFilterSearch = props => {
  return (
    <div className='ta-customers__filter-search'>
      <Input
        label={t('customers.fieldSearch.label')}
        placeholder={t('customers.fieldSearch.placeholder')}
        clearable
        type='text'
        name='search'
        addon={<FontAwesome5 icon='search' />}
      />
    </div>
  )
}

export default CustomersListFilterSearch
